import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';

import FilterType from '../../../types/FilterType';
import ActiveFilterItem from '../ActiveFilterItem';
import Icon from '../../common/Icon';

const messages = defineMessages({
  'filter.removeFilterButtonLabel': {
    id: 'filter.removeFilterButtonLabel',
    defaultMessage: 'Remove filter',
  },
});

@observer
export class ActiveFilterList extends Component {
  getTermFilterOptionName = (filter, activeFilterOptionValue) => {
    let optionName = '';
    if (filter.options && filter.options.length > 0) {
      const option = filter.options.find(
        (opt) => opt.value === activeFilterOptionValue
      );
      if (option) optionName = option.name;
    }
    return optionName;
  };

  getFilterValueAsText = (
    filter,
    activeFilterValue,
    activeFilterMin,
    activeFilterMax
  ) => {
    switch (filter.type) {
      case FilterType.SINGLETERM:
      case FilterType.TERM: {
        return this.getTermFilterOptionName(filter, activeFilterValue);
      }
      case FilterType.RANGE: {
        return filter.name + `: ${activeFilterMin} - ${activeFilterMax}`;
      }
      case FilterType.BOOLEAN: {
        return filter.name;
      }
      default: {
        return (
          activeFilterValue ||
          `${activeFilterMin || ''} - ${activeFilterMax || ''}`
        );
      }
    }
  };

  getActiveFilterItem = (key, value, removeFilter) => {
    return (
      <ActiveFilterItem
        key={key}
        value={value}
        removeFilter={removeFilter}
        iconMessage={this.props.intl.formatMessage(
          messages['filter.removeFilterButtonLabel']
        )}
      />
    );
  };

  render() {
    const { activeFilters } = this.props;
    if (activeFilters.length === 0) {
      return null;
    }

    return (
      <div
        className={classNames({
          ActiveFilterList: true,
          'ActiveFilterList--horizontal': this.props.horizontal,
        })}
      >
        <Button
          className="ActiveFilterList__remove-all-button"
          color="primary"
          size="sm"
          onClick={this.props.clearFilters}
        >
          <FormattedMessage
            id="filter.removeAllFiltersButton"
            defaultMessage="Clear filters"
          />
          <Icon name="times" />
        </Button>
        {activeFilters.map((activeFilter) => {
          const activeFilterArray = [];
          if (activeFilter && activeFilter.value) {
            Object.values(activeFilter.value).forEach((filterItem) => {
              activeFilterArray.push(filterItem);
            });
          }

          if (
            (activeFilter.filter.type === FilterType.TERM ||
              activeFilter.filter.type === FilterType.SINGLETERM) &&
            Array.isArray(activeFilterArray)
          ) {
            // If TERM filter, show own button for every option:
            return activeFilterArray
              .sort()
              .map((activeFilterValue) =>
                this.getActiveFilterItem(
                  activeFilterValue,
                  this.getFilterValueAsText(
                    activeFilter.filter,
                    activeFilterValue,
                    activeFilter.min,
                    activeFilter.max
                  ),
                  () => this.props.removeFilter(activeFilter, activeFilterValue)
                )
              );
          } else {
            return this.getActiveFilterItem(
              activeFilter.key,
              this.getFilterValueAsText(
                activeFilter.filter,
                activeFilter.value,
                activeFilter.min,
                activeFilter.max
              ),
              () => this.props.removeFilter(activeFilter)
            );
          }
        })}
      </div>
    );
  }
}

ActiveFilterList.propTypes = {
  activeFilters: PropTypes.array.isRequired,
  removeFilter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  horizontal: PropTypes.bool,
  intl: intlShape.isRequired,
};

ActiveFilterList.defaultProps = {
  horizontal: false,
};

export default injectIntl(ActiveFilterList);
