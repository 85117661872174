import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import classNames from 'classnames';

import NavigationEntity from '../../../types/NavigationEntity';
import MaybeExternalLink from '../../common/MaybeExternalLink';

const NavigationEntityLink = ({ className, entity, icon, ...rest }) => {
  const { active, label, image, path, type } = entity;

  return (
    <>
      <NavLink
        tag={MaybeExternalLink}
        className={classNames(className, {
          'NavigationEntityLink--no-children':
            !entity.children || entity.children?.length === 0,
        })}
        to={path}
        type={type}
        active={active}
        {...rest}
      >
        <div
          className={classNames(
            'NavigationEntityLink',
            'NavigationEntityLink__title',
            {
              'NavigationEntityLink__has-image': image,
            }
          )}
        >
          {image && <img src={image} alt={image} loading="lazy" />}
          {label}
        </div>
      </NavLink>
      {icon}
    </>
  );
};

NavigationEntityLink.propTypes = {
  entity: NavigationEntity.isRequired,
  icon: PropTypes.node,
  className: PropTypes.string,
};

export default NavigationEntityLink;
