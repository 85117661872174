import classNames from 'classnames';
import propTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Collapse } from 'reactstrap';

import Icon from '../../common/Icon';
import ClickOutside from '../../common/ClickOutside';

class FilterContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  handleOutsideClick = () => {
    const { onFilterClosedAutomatically } = this.props;
    const { open } = this.state;

    if (open) {
      if (onFilterClosedAutomatically) {
        onFilterClosedAutomatically();
      } else {
        this.toggleOpen();
      }
    }
  };

  toggleOpen = () => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const { children, filterInUse, headerText } = this.props;
    const { open } = this.state;
    let iconName = 'angle-down';
    if (open) {
      iconName = 'angle-up';
    } else if (filterInUse) {
      iconName = 'check';
    }
    return (
      <ClickOutside onClickOutside={this.handleOutsideClick}>
        <div
          className={classNames('FilterContainer', {
            'FilterContainer--open': open,
            'FilterContainer--closed': !open,
            'FilterContainer--filter-in-use': filterInUse
          })}
        >
          <Button
            onClick={this.toggleOpen}
            color="link"
            className="FilterContainer__toggle"
          >
            <span>{headerText}</span>
            <Icon name={iconName} />
          </Button>
          <Collapse className="FilterContainer__contents" isOpen={open}>
            <div className="FilterContainer__contents-children">{children}</div>
          </Collapse>
        </div>
      </ClickOutside>
    );
  }
}

FilterContainer.propTypes = {
  children: propTypes.node,
  filterInUse: propTypes.bool,
  headerText: propTypes.string,
  onFilterClosedAutomatically: propTypes.func
};

export default FilterContainer;
