import { types } from 'mobx-state-tree';

const ProductShippingClass = {
  PICKUP: 'PICKUP',
  DELIVERY: 'DELIVERY'
};

export default ProductShippingClass;

export const ProductShippingClassType = types.enumeration(
  'ProductShippingClass',
  Object.values(ProductShippingClass)
);
