import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Col } from 'reactstrap';

import NavigationEntity from '../../../types/NavigationEntity';
import NavigationEntityItem from '../NavigationEntityItem';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import UIStore from '../../../store/UIStore';

@observer
export class NavigationSecondLevelItem extends Component {
  renderSecondLevelItems = () => {
    const { children, className, configStore, entity, onClick } = this.props;

    const twoLevelMegaMenuEnabled =
      configStore.navigation.megaMenu.twoLayerMegaMenuEnabled;

    if (twoLevelMegaMenuEnabled) {
      return (
        <Col className={classNames('NavigationSecondLevelItem', className)}>
          <NavigationEntityItem entity={entity} onClick={onClick} />
        </Col>
      );
    }

    return (
      <ul className="NavigationMegaMenu__second-level-item">
        <li className="NavigationMegaMenu__second-level-item-name">
          <NavigationEntityItem
            entity={entity}
            onClick={onClick}
            type={entity.link_type}
          />
          {children}
        </li>
      </ul>
    );
  };

  render() {
    return this.renderSecondLevelItems();
  }
}

NavigationSecondLevelItem.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  onClick: PropTypes.func.isRequired,
  entity: NavigationEntity,
  className: PropTypes.string,
};

export default inject('configStore', 'uiStore')(NavigationSecondLevelItem);
