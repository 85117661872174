import { types } from 'mobx-state-tree';

const CartNewProductType = {
  NORMAL: 'NORMAL',
  BUNDLE: 'BUNDLE',
  ARRAY: 'ARRAY'
};

export default CartNewProductType;

export const CartNewProductTypeType = types.enumeration(
  'CartNewProductType',
  Object.values(CartNewProductType)
);
