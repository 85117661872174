import PropTypes from 'prop-types';
import React from 'react';

import ProductSign from '../ProductSign';
import Discount from '../Discount';
import DiscountText from '../DiscountText';

const ProductSignDiscount = ({
  discountText,
  percent,
  withUpTo,
  singlePriceLabel,
}) => {
  if (!percent && !discountText) {
    return null;
  }

  let discount = <DiscountText text={discountText} />;
  if (percent) {
    discount = (
      <Discount
        percentage={percent}
        withLabel={!withUpTo && singlePriceLabel}
        withUpTo={withUpTo}
      />
    );
  }

  return (
    <div className="ProductDiscountPercentageSign">
      <ProductSign>{discount}</ProductSign>
    </div>
  );
};

ProductSignDiscount.propTypes = {
  discountText: PropTypes.string,
  percent: PropTypes.number,
  withUpTo: PropTypes.bool,
  singlePriceLabel: PropTypes.bool,
};

ProductSignDiscount.defaultProps = {
  singlePriceLabel: true,
};

export default ProductSignDiscount;
