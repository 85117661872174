import { types } from 'mobx-state-tree';

const Price = types
  .model('Price', {
    with_tax: types.number,
    without_tax: types.number
  })
  .views((self) => {
    return {
      getPrice(withTax) {
        return withTax ? self.with_tax : self.without_tax;
      }
    };
  });

export default Price;
