import { types } from 'mobx-state-tree';

const AdditionalServiceType = {
  BASIC: 'BASIC',
  UNIQUE_BATCHES: 'UNIQUE_BATCHES'
};

export default AdditionalServiceType;

export const AdditionalServiceTypeType = types.enumeration(
  'AdditionalServiceType',
  Object.values(AdditionalServiceType)
);
