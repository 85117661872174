import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import { uniqueId } from 'lodash';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import FormField from '../../../models/FormField';
import FieldError from '../FieldError';

@observer
class FileField extends Component {
  constructor(props) {
    super(props);

    // In the normal use cases, the form and field names don't change, and
    // this only really affects the input/label binding, so not updating the id
    // afterwards if props change should still be fine.
    this.id = uniqueId(`${props.formName}__${props.fieldName}--`);
    this.fileFieldRef = React.createRef();
  }

  handleChange = (event) => {
    this.props.field.setValue(event.target.value);
    this.props.handleChange(
      this.props.fieldName,
      this.fileFieldRef.current.files[0]
    );
  };

  handleBlur = () => {
    if (this.props.field.value) {
      this.props.field.setShouldValidate(true);
    }
  };

  getFileNameToDisplay = () => {
    const value = this.props.field.value;
    if (!value) {
      return (
        <FormattedMessage
          id="form.noFileSelected"
          defaultMessage="No file selected"
        />
      );
    }

    return value.substr(
      Math.max(value.lastIndexOf('/'), value.lastIndexOf('\\')) + 1
    );
  };

  render() {
    const { label, field, hideLabel, required, disabled } = this.props;

    // TODO: ensure this remains suitably accessible
    return (
      <FormGroup className="FileField">
        <Label for={this.id} hidden={hideLabel}>
          {label}
          {required && ' *'}
        </Label>
        <div className="FileField__field">
          <Label
            className={classNames(
              'FileField__input-container btn btn-secondary',
              {
                disabled: disabled,
              }
            )}
          >
            <FormattedMessage
              id="form.selectFileSentence"
              defaultMessage="Select file..."
            />
            <input
              type="file"
              ref={this.fileFieldRef}
              onChange={this.handleChange}
              id={this.id}
              disabled={disabled}
            />
          </Label>
          {this.getFileNameToDisplay()}
        </div>
        <FieldError field={field} />
      </FormGroup>
    );
  }
}

FileField.propTypes = {
  field: modelOf(FormField).isRequired,
  label: PropTypes.node.isRequired,
  formName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  required: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
};

export default FileField;
