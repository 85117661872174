import { types } from 'mobx-state-tree';

const SiteType = {
  WEB_STORE: 'WEB_STORE',
  SHOPPING_CENTER: 'SHOPPING_CENTER',
  HOME_PAGE: 'HOME_PAGE',
  OFFER_REQUEST_SITE: 'OFFER_REQUEST_SITE',
  WEB_STORE_WITH_OFFER_REQUESTS: 'WEB_STORE_WITH_OFFER_REQUESTS',
};

export default SiteType;

export const SiteTypeType = types.enumeration('type', Object.values(SiteType));
