import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

@observer
export class Swogo extends Component {
  render() {
    return (
      <Row className="Swogo">
        <Col sm={12}>
          <div id="swogo-bundle-1" className="swogo-box" />
        </Col>
      </Row>
    );
  }
}

export default Swogo;
