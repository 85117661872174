import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Icon from '../../common/Icon';
import NavigationDropdownDrawerButton from '../NavigationDropdownDrawerButton';
import globalTranslations from '../../../i18n/globalTranslations';

const DEFAULT_CLOSE_BUTTON = NavigationDropdownDrawerButton({
  label: (
    <span>
      <Icon name="times" />{' '}
      <FormattedMessage {...globalTranslations.closeTitle} />
    </span>
  ),
  color: 'primary',
  onClick: (context) => context.close(),
  key: '_default-close'
});

class NavigationDropdownDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  render() {
    const { label, children, className, buttons, right, ...props } = this.props;

    const buttonContext = {
      close: () => {
        this.setState({ open: false });
      }
    };

    return (
      <Dropdown
        isOpen={this.state.open}
        toggle={() => this.setState({ open: !this.state.open })}
        nav
        className={classNames('NavigationDropdownDrawer', className)}
        {...props}
      >
        <DropdownToggle nav caret>
          {label}
        </DropdownToggle>
        <DropdownMenu right={right}>
          {children}
          <div className="NavigationDropdownDrawer__hanging-buttons">
            {buttons.map((button) => button(buttonContext))}
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

NavigationDropdownDrawer.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func
  ]),
  className: PropTypes.string,
  children: PropTypes.node,
  buttons: PropTypes.arrayOf(PropTypes.func),
  right: PropTypes.bool
};

NavigationDropdownDrawer.defaultProps = {
  label: '',
  className: '',
  children: [],
  buttons: [DEFAULT_CLOSE_BUTTON]
};

export default NavigationDropdownDrawer;
