import React, { Component } from 'react';
import { Alert, Button, Form } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import globalTranslations from '../../../i18n/globalTranslations';
import { createForm } from '../../../models/Form';
import UnexpectedError from '../../loader/UnexpectedError';
import { modelOf } from '../../../prop-types';
import FileField from '../../form/FileField';
import { createFormField } from '../../../models/FormField';
import { notBlank } from '../../../util/formValidators';
import FieldType from '../../../types/FieldType';
import Product from '../../../models/Product';
import Icon from '../../common/Icon';
import { createErrorModel } from '../../../util/error';
import ErrorHandler from '../../loader/ErrorHandler';

const UploadStatus = {
  NONE: 'NONE',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  SUCCESS: 'SUCCESS',
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
  UPLOADING: 'UPLOADING'
};

@observer
export class ProductFileUploadForm extends Component {
  form = null;
  state = {
    status: UploadStatus.NONE,
    error: null,
    formFiles: {}
  };

  constructor(props) {
    super(props);

    const fields = {
      file: createFormField({ type: FieldType.FILE }, [
        notBlank(<FormattedMessage {...globalTranslations.fileTitle} />)
      ])
    };

    this.form = createForm(fields);
  }

  handleSubmit = (e) => {
    const { product, onFileUploaded } = this.props;
    e.preventDefault();
    this.form.validate();
    if (this.form.valid) {
      this.setState(
        () => ({
          status: UploadStatus.UPLOADING,
          error: null
        }),
        () => {
          product
            .uploadFile(this.state.formFiles.file)
            .then(() => {
              this.setState(
                () => ({
                  status: UploadStatus.SUCCESS
                }),
                onFileUploaded
              );
            })
            .catch((e) => {
              const status = e.response ? e.response.status : 500;
              const errorMap = {
                400: UploadStatus.VALIDATION_ERROR,
                409: UploadStatus.VALIDATION_ERROR,
                500: UploadStatus.UNEXPECTED_ERROR
              };

              this.setState(() => ({
                status: errorMap[status],
                error: e
              }));
            });
        }
      );
    }
  };

  handleFileChange = (field, file) => {
    this.setState((state) => {
      return {
        formFiles: {
          ...state.formFiles,
          [field]: file
        }
      };
    });
  };

  getFileField = (name, message) => {
    const field = this.form.fields.get(name);

    return (
      <div
        className={`ProductFileUploadForm__field ProductFileUploadForm__field--${name}`}
      >
        <FileField
          label={<FormattedMessage {...message} />}
          formName="ProductFileUploadForm"
          type="file"
          fieldName={name}
          field={field}
          handleChange={this.handleFileChange}
          hideLabel
          disabled={this.state.status === UploadStatus.UPLOADING}
        />
      </div>
    );
  };

  getError = () => {
    const { status, error } = this.state;

    switch (status) {
      case UploadStatus.UNEXPECTED_ERROR:
        return <UnexpectedError />;
      case UploadStatus.VALIDATION_ERROR:
        const errorData = get(error, 'response.data.errors');

        if (!errorData) {
          return <ErrorHandler error={createErrorModel(error)} />;
        } else if (errorData.fileCount === false) {
          return (
            <Alert color="danger">
              <FormattedMessage
                id="product.fileUploadTooManyFileUploadsError"
                defaultMessage="You have reached the maximum amount of uploaded files."
              />
            </Alert>
          );
        } else {
          return <UnexpectedError />;
        }
      case UploadStatus.NONE:
      case UploadStatus.UPLOADING:
      case UploadStatus.SUCCESS:
      default:
        break;
    }
  };

  getForm = () => {
    const { status } = this.state;

    return (
      <Form noValidate onSubmit={this.handleSubmit}>
        {this.getFileField('file', globalTranslations.fileTitle)}
        <Button
          color="secondary"
          block
          disabled={
            this.form.valid === false || status === UploadStatus.UPLOADING
          }
        >
          {status === UploadStatus.UPLOADING ? (
            <span>
              <Icon name="circle-o-notch" spin />{' '}
              <FormattedMessage
                id="product.fileUploadWorking"
                defaultMessage="Uploading..."
              />
            </span>
          ) : (
            <FormattedMessage {...globalTranslations.uploadFileTitle} />
          )}
        </Button>
      </Form>
    );
  };

  render() {
    return (
      <div className="ProductFileUploadForm">
        {this.getError()}
        {this.getForm()}
      </div>
    );
  }
}

ProductFileUploadForm.propTypes = {
  product: modelOf(Product).isRequired,
  onFileUploaded: PropTypes.func
};

ProductFileUploadForm.defaultProps = {
  onFileUploaded: () => {}
};

export default ProductFileUploadForm;
