import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

const NavigationDropdownDrawerButton = (props) => {
  const { label, onClick, ...rest } = props;

  return (context) => (
    <Button
      className="NavigationDropdownDrawerButton"
      onClick={() => onClick(context)}
      {...rest}
    >
      {label}
    </Button>
  );
};

NavigationDropdownDrawerButton.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func
  ]).isRequired,
  onClick: PropTypes.func.isRequired
};

export default NavigationDropdownDrawerButton;
