import { types } from 'mobx-state-tree';

const SectionIntegrationType = {
  ZOOVU: 'ZOOVU',
  ARINET: 'ARINET'
};

export default SectionIntegrationType;

export const SectionIntegrationTypeType = types.enumeration(
  'SectionIntegrationType',
  Object.values(SectionIntegrationType)
);
