import { types } from 'mobx-state-tree';

const ExtraFieldType = {
  TEXTAREA: 'TEXTAREA',
};

export default ExtraFieldType;

export const ExtraFieldTypeType = types.enumeration(
  'ExtraFieldTypeType',
  Object.values(ExtraFieldType)
);
