import { types } from 'mobx-state-tree';

const BannerVideoType = {
  FILE: 'FILE',
  LINK: 'LINK',
};

export default BannerVideoType;

export const BannerVideoTypeType = types.enumeration(
  'BannerVideoTypeType',
  Object.values(BannerVideoType)
);
