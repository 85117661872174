import { types } from 'mobx-state-tree';
import AcceptableTermsType, {
  AcceptableTermsTypeType
} from '../types/AcceptableTermsType';
import Price from './Price';

const ProductMerchantInfo = types
  .model('ProductMerchantInfo', {
    acceptable_terms: types.optional(
      types.array(
        types.model({
          link: types.maybeNull(types.string),
          type: types.maybeNull(AcceptableTermsTypeType)
        })
      ),
      []
    ),
    buying_allowed: types.maybeNull(types.boolean),
    id: types.maybeNull(types.identifierNumber),
    merchant_name: types.maybeNull(types.string),
    shipping_starting_price: types.maybeNull(Price),
    site_description: types.maybeNull(types.string),
    site_logo: types.maybeNull(types.string),
    site_name: types.maybeNull(types.string),
    site_platform: types.maybeNull(types.string),
    site_url: types.maybeNull(types.string)
  })
  .views((self) => {
    return {
      get buyingAllowed() {
        return self.buying_allowed;
      },
      get deliveryTerms() {
        return (
          self.acceptable_terms.length > 0 &&
          self.acceptable_terms.find(
            (term) => term.type === AcceptableTermsType.DELIVERY_TERMS
          )
        );
      },
      get privacyStatement() {
        return (
          self.acceptable_terms.length > 0 &&
          self.acceptable_terms.find(
            (term) => term.type === AcceptableTermsType.PRIVACY_TERMS
          )
        );
      }
    };
  });

export default ProductMerchantInfo;
