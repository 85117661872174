import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import globalTranslations from '../../../i18n/globalTranslations';

class Spinner extends Component {
  render() {
    return (
      <div className="Spinner">
        <span className="sr-only" role="alert" aria-busy="true">
          <FormattedMessage {...globalTranslations.loading} />
        </span>
      </div>
    );
  }
}

export default Spinner;
