import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import classNames from 'classnames';
import RouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import NavigationEntity from '../../../types/NavigationEntity';
import {
  isNotHandledByReactRouter,
  stripHostIfMatches
} from '../../../util/url';

@observer
export class NavigationDropdownMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.toggleAccountMenu = this.toggleAccountMenu.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleAccountMenu = () => {
    const { disabled } = this.props;
    this.setState({ open: disabled ? false : !this.state.open });
  };

  toggleMenu = () => this.setState({ open: !this.state.open });

  openMenu = () => {
    const { entity } = this.props;
    this.props.setActiveId(entity.id);
    this.setState({
      open: true
    });
  };

  closeMenu = (e) => {
    const { entity } = this.props;
    const isClick = !!(e && e.type === 'click');

    if (isClick && (entity.path.length === 0 || entity.path === '#')) {
      return;
    }

    this.setState({
      open: false
    });
  };

  navigateTo = () => {
    const { history, host, entity } = this.props;
    const maybeRelativeUrl = stripHostIfMatches(entity.path, host);

    if (isNotHandledByReactRouter(maybeRelativeUrl)) {
      document.location = maybeRelativeUrl;
    } else {
      history.push(maybeRelativeUrl);
    }
  };

  render() {
    const {
      label,
      children,
      className,
      disabled,
      entity,
      isOpen,
      debouncer,
      isAccount,
      staticContext,
      ariaLabel,
      ...rest
    } = this.props;

    const image = entity ? entity.image : null;

    const accountMenuIsOpen = disabled ? false : this.state.open;
    const menuIsOpen = disabled ? false : !!(isOpen && this.state.open);

    return isAccount ? (
      <Dropdown
        isOpen={accountMenuIsOpen}
        toggle={this.toggleAccountMenu}
        nav
        className={classNames('NavigationDropdownMenu', className)}
        disabled={disabled}
        {...rest}
      >
        <DropdownToggle
          nav
          caret
          className={classNames({
            disabled
          })}
        >
          {label}
        </DropdownToggle>
        <DropdownMenu>{children}</DropdownMenu>
      </Dropdown>
    ) : (
      <Dropdown
        key={entity.id}
        isOpen={menuIsOpen}
        toggle={this.toggleMenu}
        className={classNames('NavigationDropdownMenu', className)}
        disabled={disabled}
        nav
      >
        <div
          className="NavigationDropdownMenu__hover"
          onClick={this.closeMenu}
          onMouseOver={() => debouncer(() => this.openMenu())}
          onMouseOut={() => debouncer(() => this.closeMenu())}
        >
          <DropdownToggle
            nav
            onClick={this.navigateTo}
            caret
            className={classNames({
              disabled
            })}
            aria-label={ariaLabel}
          >
            {image && <img src={image} alt={image} loading="lazy" />}
            {label}
          </DropdownToggle>
          <DropdownMenu
            modifiers={{
              preventOverflow: {
                boundariesElement: 'viewport'
              }
            }}
          >
            {children}
          </DropdownMenu>
        </div>
      </Dropdown>
    );
  }
}

NavigationDropdownMenu.propTypes = {
  history: RouterPropTypes.history.isRequired,
  host: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  debouncer: PropTypes.func,
  disabled: PropTypes.bool,
  entity: NavigationEntity,
  isAccount: PropTypes.bool,
  isOpen: PropTypes.bool,
  label: PropTypes.node,
  setActiveId: PropTypes.func,
  ariaLabel: PropTypes.string
};

NavigationDropdownMenu.defaultProps = {
  label: '',
  className: '',
  children: [],
  disabled: false
};

export default inject((stores) => ({
  host: stores.locationContext.host
}))(withRouter(NavigationDropdownMenu));
