import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import AccountStore from '../../../store/AccountStore';
import { modelOf } from '../../../prop-types';
import AccountModalTab from '../../../types/AccountModalTab';

@observer
export class AccountModalLink extends Component {
  toggle = () => {
    this.props.accountStore.accountModal.setTab(this.props.tab);
    this.props.accountStore.accountModal.toggle();
  };

  render() {
    return (
      <span className="AccountModalLink">
        <NavLink
          onClick={this.toggle}
          href="#"
          className={classNames(this.props.className)}
        >
          {this.props.children}
        </NavLink>
      </span>
    );
  }
}

AccountModalLink.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  tab: PropTypes.oneOf(Object.values(AccountModalTab)).isRequired,
  className: PropTypes.string
};

export default inject('accountStore')(AccountModalLink);
