import { cloneDeepWith } from 'lodash';
import React from 'react';

/**
 * Deep clones an entire object, except for React nodes, which are shallow
 * cloned. The internal state of a React node can contain objects that are
 * not cloneable, and using a clone of a React node in the DOM can confuse
 * React's internals (such as displaying unnecessary warnings about missing
 * key parameters).
 *
 * @param object The object to clone.
 * @returns object A clone of the given object, with all elements except for
 *                 valid React nodes deep cloned.
 */
const safeCloneDeep = (object) => {
  return cloneDeepWith(object, (value) => {
    if (React.isValidElement(value)) {
      return value;
    }
  });
};

export default safeCloneDeep;
