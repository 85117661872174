import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import GenericMeta from '../GenericMeta';

@observer
export class SEOTitle extends Component {
  outputTitle = (title, shouldTranslate = false) => {
    const { configStore, noSuffix } = this.props;
    const fullTitle = noSuffix
      ? title
      : `${title} - ${configStore.store.name} ${
          configStore.languageTexts.TEXT_SEO_STORE_NAME_ADD
        }`;
    return (
      <Fragment>
        {!shouldTranslate && (
          <GenericMeta
            name="twitter:title"
            property="og:title"
            content={fullTitle}
          />
        )}
        <Helmet>
          <title>{fullTitle}</title>
        </Helmet>
      </Fragment>
    );
  };

  render() {
    const { title } = this.props;
    const shouldTranslate = title && title.id;
    return (
      <Fragment>
        {shouldTranslate ? (
          <FormattedMessage {...title}>{this.outputTitle}</FormattedMessage>
        ) : (
          this.outputTitle(title, shouldTranslate)
        )}
      </Fragment>
    );
  }
}

SEOTitle.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  noSuffix: PropTypes.bool
};

export default inject('configStore')(SEOTitle);
