import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import MaybeExternalLink from '../../common/MaybeExternalLink';
import Analytics from '../../../analytics/Analytics';
import Ad from '../../../models/banner/Ad';
import WysiwygContent from '../../common/WysiwygContent';

@observer
export class ContentSlot extends Component {
  UNSAFE_componentWillMount() {
    this.sendView();
  }

  sendView = () => {
    const { analytics, bannerZone, ad } = this.props;

    if (!ad) {
      return;
    }

    analytics.promoView([
      {
        bannerZone,
        banner: ad,
      },
    ]);
  };

  render() {
    const { ad, className } = this.props;

    if (!ad) {
      return null;
    }

    if (ad.link_url) {
      return (
        <div
          className={classNames('ContentSlot', className, {
            ContentSlot__link: ad.link_url,
          })}
        >
          <MaybeExternalLink to={ad.link_url}>
            <WysiwygContent html={ad.content} />
          </MaybeExternalLink>
        </div>
      );
    }

    return (
      <div className={classNames('ContentSlot', className)}>
        <WysiwygContent html={ad.content} />
      </div>
    );
  }
}

ContentSlot.propTypes = {
  ad: modelOf(Ad).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  bannerZone: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default inject('analytics')(ContentSlot);
