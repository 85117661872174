import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import CouponStore from '../../../store/CouponStore';
import CouponActivationType from '../../../types/CouponActivationType';
import Coupon from '../../../models/Coupon';
import CouponDiscountType from '../../../types/CouponDiscountType';
import Discount from '../../product/Discount';
import Price from '../../product/Price';
import globalTranslations from '../../../i18n/globalTranslations';

@observer
export class CouponText extends Component {
  getOffer = () => {
    const { coupon } = this.props;
    switch (coupon.discount_type) {
      case CouponDiscountType.AMOUNT:
        return <Price price={coupon.amount} />;
      case CouponDiscountType.PERCENTAGE:
        return <Discount percentage={coupon.percentage} />;
      case CouponDiscountType.FREE_SHIPPING:
        return (
          <span className="CouponText__free-shipping">
            <FormattedMessage {...globalTranslations.freeDeliverySentence} />
          </span>
        );
      default:
        return '';
    }
  };

  getShortText = () => {
    const { coupon } = this.props;

    let text = '';
    if (coupon.discount_type === CouponDiscountType.FREE_SHIPPING) {
      text = (
        <FormattedMessage {...globalTranslations.couponFreeShippingSentence} />
      );
    } else {
      text = (
        <FormattedMessage
          {...globalTranslations.couponDiscountOfferSentence}
          values={{ offer: this.getOffer() }}
        />
      );
    }
    return text;
  };

  getLongText = () => {
    const { coupon } = this.props;
    let text = '';
    switch (coupon.activation_type) {
      case CouponActivationType.REGISTRATION:
        if (coupon.discount_type === CouponDiscountType.FREE_SHIPPING) {
          text = (
            <FormattedMessage
              {...globalTranslations.couponRegisterFreeDeliverySentence}
            />
          );
        } else {
          text = (
            <FormattedMessage
              {...globalTranslations.couponRegisterOfferSentence}
              values={{ offer: this.getOffer() }}
            />
          );
        }
        break;
      case CouponActivationType.LOGIN:
        if (coupon.discount_type === CouponDiscountType.FREE_SHIPPING) {
          text = (
            <FormattedMessage
              {...globalTranslations.couponLoginFreeDeliverySentence}
            />
          );
        } else {
          text = (
            <FormattedMessage
              {...globalTranslations.couponLoginOfferSentence}
              values={{ offer: this.getOffer() }}
            />
          );
        }
        break;
      case CouponActivationType.PRODUCT_REVIEW:
        text = (
          <FormattedMessage
            {...globalTranslations.couponReviewValueSentence}
            values={{
              value: this.getOffer()
            }}
          />
        );
        break;
      default:
    }
    return text;
  };

  render() {
    const { short } = this.props;

    return (
      <span className="CouponText">
        {short ? this.getShortText() : this.getLongText()}
      </span>
    );
  }
}

CouponText.propTypes = {
  couponStore: modelOf(CouponStore).isRequired,
  coupon: modelOf(Coupon).isRequired,
  short: PropTypes.bool
};

export default inject('couponStore')(CouponText);
