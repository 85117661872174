import { types } from 'mobx-state-tree';

const PurchaseProduct = types.model('PurchaseProduct', {
  id: types.string,
  name: types.string,
  total_qty: types.string,
  total_sum_with_tax: types.string,
  total_sum_without_tax: types.string,
  url: types.maybeNull(types.string)
});

export default PurchaseProduct;
