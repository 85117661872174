import { types } from 'mobx-state-tree';
import Price from './Price';

const QuantityDiscount = types
  .model('QuantityDiscount', {
    quantity: types.optional(types.number, 0.0),
    discount: types.optional(types.number, 0.0),
    price: types.optional(Price, {
      with_tax: 0.0,
      without_tax: 0.0,
    }),
  })
  .views((self) => {
    return {
      getSavings: (normalPrice, withTax) => {
        return normalPrice - self.price.getPrice(withTax);
      },
      getPrice(withTax) {
        return self.price.getPrice(withTax);
      },
    };
  });

export default QuantityDiscount;
