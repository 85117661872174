import React, { Component, Fragment } from 'react';

class VehicleInfo extends Component {
  renderList = () => {
    const { vehicleInfo } = this.props;

    return (
      <Fragment>
        <ul className="VehicleInfo__attribute-list">
          {vehicleInfo.brand && (
            <li>
              {' '}
              <strong>{vehicleInfo.brand}</strong>{' '}
            </li>
          )}
          {vehicleInfo.year &&
            vehicleInfo.use_date && (
              <li>
                {vehicleInfo.use_date}({vehicleInfo.year})
              </li>
            )}
          {vehicleInfo.commercial_name && (
            <li> {vehicleInfo.commercial_name}</li>
          )}
          {vehicleInfo.fuel_type && <li> {vehicleInfo.fuel_type} </li>}
          {vehicleInfo.cylinder_volume && (
            <li>
              {' '}
              {vehicleInfo.cylinder_volume} cm<sup>3</sup>
            </li>
          )}
          {vehicleInfo.net_power && <li> {vehicleInfo.net_power} kW</li>}
          {vehicleInfo.production_number && (
            <li> {vehicleInfo.production_number}</li>
          )}
          {vehicleInfo.engine_model && <li> {vehicleInfo.engine_model}</li>}
        </ul>
      </Fragment>
    );
  };

  render() {
    const { vehicleInfo } = this.props;
    if (!vehicleInfo) {
      return null;
    }

    return this.renderList();
  }
}

export default VehicleInfo;
