import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { inject, observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import CartStore from '../../../store/CartStore';
import ProposalStore from '../../../store/ProposalStore';
import globalTranslations from '../../../i18n/globalTranslations';
import NavigationCartPopover from '../NavigationCartPopover';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';
import ProductAddedToCardModalType from '../../../types/ProductAddedToCartModalStyle';
import RequestState from '../../../types/RequestState';
import Icon from '../../common/Icon';

const NavigationHybridCart = ({
  accountStore,
  configStore,
  cartStore,
  proposalStore,
  intl,
  navigationCart,
  navigationProposal,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const isLoading =
    cartStore.state === RequestState.LOADING ||
    proposalStore.state === RequestState.LOADING;

  const getDisabled = () => {
    if (isLoading) {
      return true;
    }

    return false;
  };

  const renderDropdownToggleContent = () => {
    if (isLoading) {
      return <Icon name="circle-o-notch" spin={isLoading} />;
    }

    return (
      <FormattedMessage
        id="checkout.hybridCartSentence"
        defaultMessage={'Cart / Quote'}
      />
    );
  };

  const cartIsPopover = () => {
    if (!accountStore.account) {
      return null;
    }

    const isRetailer = accountStore.account.is_retailer;
    const modalType = configStore.cart.type;

    return isRetailer || modalType === ProductAddedToCardModalType.POPOVER;
  };

  const ifDisabled = getDisabled();

  return (
    <div className="NavigationHybridCart">
      <ButtonDropdown
        isOpen={isOpen}
        toggle={toggle}
        className="NavigationHybridCart__dropdown-toggle"
      >
        <DropdownToggle
          caret={!isLoading}
          color="primary"
          disabled={ifDisabled}
          aria-label={intl.formatMessage({
            id: 'checkout.hybridCartSentence',
            defaultMessage: 'Cart / Quote',
          })}
        >
          {renderDropdownToggleContent()}
        </DropdownToggle>
        <DropdownMenu className="NavigationCart__wrapper-menu" right={true}>
          <DropdownItem
            aria-label={intl.formatMessage(
              globalTranslations.checkoutOpenCartSentence
            )}
            onClick={() => cartStore.cartModal.toggle(true)}
          >
            {navigationCart}
          </DropdownItem>
          <DropdownItem
            aria-label={intl.formatMessage(
              globalTranslations.checkoutOpenProposalOfferSentence
            )}
            onClick={() => proposalStore.proposalModal.toggle(true)}
          >
            {navigationProposal}
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
      {cartIsPopover() && <NavigationCartPopover />}
    </div>
  );
};

NavigationHybridCart.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  cartStore: modelOf(CartStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  proposalStore: modelOf(ProposalStore).isRequired,
  intl: intlShape.isRequired,
  navigationCart: PropTypes.object.isRequired,
  navigationProposal: PropTypes.object.isRequired,
};

export default injectIntl(
  inject(
    'accountStore',
    'cartStore',
    'configStore',
    'proposalStore'
  )(observer(NavigationHybridCart))
);
