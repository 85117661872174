import { types } from 'mobx-state-tree';

const CustomNavigationLinkLocation = {
  ABOVE: 'ABOVE',
  BELOW: 'BELOW'
};

export default CustomNavigationLinkLocation;

export const CustomNavigationLinkLocationType = types.enumeration(
  'CustomNavigationLinkLocation',
  Object.values(CustomNavigationLinkLocation)
);
