import { keyBy } from 'lodash';

import {
  CommonEventNames,
  FacebookPixelEventNames,
  GoogleAnalytics4EventNames,
  UniversalAnalyticsEventNames,
} from '../AnalyticsEventNames';
import ProductClass from '../../types/ProductClass';
import OrderTotalClass from '../../types/OrderTotalClass';
import ServerEvent from './Models/ServerEvent';

class FacebookEventHandler {
  #callback;
  #serverEvent;
  #withTax;

  constructor(accountStore, orderStore, countryStore, callback) {
    this.#callback = callback;
    this.#withTax = accountStore.showPricesWithTax;
    this.#serverEvent = new ServerEvent(accountStore, orderStore, countryStore);
    document.addEventListener('analytics', this.#handleEvents, true);
  }

  #handleEvents = (event) => {
    switch (event.detail.name) {
      case GoogleAnalytics4EventNames.addToCart:
      case UniversalAnalyticsEventNames.addToCart:
        this.#addToCart(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.addToWishlist:
        this.#addToWishlist(event.detail.payload);
        break;
      case FacebookPixelEventNames.customizeProduct:
        this.#customizeProduct();
        break;
      case GoogleAnalytics4EventNames.beginCheckout:
      case UniversalAnalyticsEventNames.checkout:
        this.#initiateCheckout(event.detail.payload);
        break;
      case CommonEventNames.setPage:
        this.pageView();
        break;
      case GoogleAnalytics4EventNames.purchase:
      case UniversalAnalyticsEventNames.purchase:
        this.#purchase(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.search:
      case UniversalAnalyticsEventNames.quickSearch:
        this.#search(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.viewItem:
      case UniversalAnalyticsEventNames.productDetail:
        this.#viewContent(event.detail.payload);
        break;
      default:
    }
  };

  /**
   * @param {Object} pixelEvent
   * @param {string} pixelEvent.currencyCode
   * @param {number} pixelEvent.value monetary value of the event
   * @param {Array.<Product>} pixelEvent.productList An array with objects in the format { product, activeProductId }.
   */
  #addToCart = ({ currencyCode, productList, value = 0.0 }) => {
    const action = FacebookPixelEventNames.addToCart;
    const contentIds = [];

    if (productList.length > 0) {
      const { product } = productList[0];

      const contents = productList.map((productData) => {
        contentIds.push(productData.product.id);

        return {
          id: productData.product.id,
          quantity: productData.quantity,
          item_price: productData.product.getPrice(
            this.#withTax,
            productData.activeProductId
          ),
        };
      });

      let data = {
        content_ids: contentIds,
        content_name: product.name,
        content_type: 'product',
        contents,
        currency: currencyCode,
        value: Number(value.toFixed(4)),
      };

      this.#pushEvent(action, data);
    }
  };

  /**
   * @param {Object} pixelEvent
   * @param {string} pixelEvent.currencyCode
   * @param {number} pixelEvent.value monetary value of the event
   * @param {Array.<Product>} pixelEvent.productList An array with objects in the format { product, activeProductId }.
   */
  #addToWishlist = ({ currencyCode, productList, value = 0.0 }) => {
    const action = FacebookPixelEventNames.addToWishlist;
    const contentIds = [];

    if (productList.length > 0) {
      const { product } = productList[0];

      const contents = productList.map((productData) => {
        contentIds.push(productData.product.id);

        return {
          id: productData.product.id,
          item_price: productData.product.getPrice(
            this.#withTax,
            productData.activeProductId
          ),
        };
      });

      let data = {
        content_ids: contentIds,
        content_name: product.name,
        contents,
        currency: currencyCode,
        value: Number(value.toFixed(4)),
      };

      this.#pushEvent(action, data);
    }
  };

  #customizeProduct = () => {
    const action = FacebookPixelEventNames.customizeProduct;
    this.#pushEvent(action);
  };

  /**
   * @param {Object} pixelEvent
   * @param {string} pixelEvent.currencyCode
   * @param {Array.<Product>} pixelEvent.productList An array with objects in the format { product, activeProductId }.
   * @param {number} pixelEvent.value monetary value of the event with failsafe
   */
  #initiateCheckout = ({ currencyCode, productList, value }) => {
    const action = FacebookPixelEventNames.initiateCheckout;
    const contentIds = [];

    if (productList.length > 0) {
      const contents = productList.map((product) => {
        contentIds.push(product.product_id);

        return {
          id: product.product_id,
          quantity: product.quantity,
          item_price: product.final_unit_price,
        };
      });

      let data = {
        content_ids: contentIds,
        contents,
        currency: currencyCode,
        num_items: productList.length,
        value: Number(value.toFixed(4)),
      };

      this.#pushEvent(action, data);
    }
  };

  /**
   * @param {Object} order Order data.
   * @param {CurrentOrder} order.currentOrder Data.
   */
  #purchase = ({ currentOrder }) => {
    const action = FacebookPixelEventNames.purchase;
    const contentIds = [];
    const orderTotalsByClass = keyBy(currentOrder.totals, 'class');

    const getTotalByClass = (totalClass) => {
      const total = orderTotalsByClass[totalClass];

      // The totals we use should always be there, but...
      return total ? total.value : null;
    };

    const orderProducts = currentOrder.products.map((product) => {
      contentIds.push(product.product_id);

      return {
        id: product.product_id,
        quantity: product.quantity,
        item_price: product.final_unit_price,
      };
    });

    let data = {
      content_ids: contentIds,
      content_type: 'product',
      contents: orderProducts,
      currency: currentOrder.currency,
      num_items: currentOrder.products.length,
      value: getTotalByClass(OrderTotalClass.TOTAL),
    };

    this.#pushEvent(action, data, currentOrder.id);
  };

  /**
   * @param {Object} search
   * @param {Object} search.searchTerm search text
   */
  #search = ({ searchTerm }) => {
    const action = FacebookPixelEventNames.search;

    let data = {
      search_string: searchTerm,
    };

    this.#pushEvent(action, data);
  };

  /**
   * @param {Object} pixelEvent
   * @param {string} pixelEvent.currencyCode
   * @param {number} pixelEvent.value monetary value of the event
   * @param {Array.<Product>} pixelEvent.productList An array with objects in the format { product, activeProductId }.
   */
  #viewContent = ({ currencyCode, productList, value = 0.0 }) => {
    const action = FacebookPixelEventNames.viewContent;
    const contentIds = [];

    if (productList.length > 0) {
      const { product } = productList[0];

      const productType =
        product.class === ProductClass.MULTI ||
        product.class === ProductClass.COLLECTION
          ? 'product_group'
          : 'product';

      const contents = productList.map((productData) => {
        contentIds.push(productData.product.id);

        return {
          id: productData.product.id,
          item_price: productData.product.getPrice(
            this.#withTax,
            productData.activeProductId
          ),
        };
      });

      let data = {
        content_ids: contentIds,
        content_name: product.name,
        content_type: productType,
        contents,
        currency: currencyCode,
        value: Number(value.toFixed(4)),
      };

      this.#pushEvent(action, data);
    }
  };

  pageView = () => {
    const action = FacebookPixelEventNames.pageView;
    this.#pushEvent(action);
  };

  /**
   * @param {string} action Event name.
   * @param {ServerEvent} data Object containing data for the event.
   * @param {string} eventID unique id for event deduplication.
   */
  #pushEvent = (action, data = {}, eventID = null) => {
    const bannedEvents = [
      FacebookPixelEventNames.pageView,
      FacebookPixelEventNames.addToWishlist,
      FacebookPixelEventNames.customizeProduct,
      FacebookPixelEventNames.search,
    ];

    eventID = eventID || this.#generateEventID();

    if (window.fbq) {
      window.fbq('track', action, data, { eventID });
    }

    if (this.#callback) {
      if (!bannedEvents.includes(action))
        this.#sendEvent(action, data, eventID);
    }
  };

  /**
   * @param {string} action Event name
   * @param {ServerEvent} data
   * @param {string} eventId unique id for event deduplication.
   */
  #sendEvent = (action, customData, eventID) => {
    const serverEvent = this.#serverEvent.getData({
      action,
      customData,
      eventID,
    });

    this.#callback([serverEvent]);
  };

  /**
   * @returns {String} Random stringified decimal between 0 and <1
   */
  #generateEventID = () => String(Math.random());
}

export default FacebookEventHandler;
