import { types } from 'mobx-state-tree';

const Year = types.model('Year', {
  start: types.maybeNull(types.string),
  end: types.maybeNull(types.string)
});

const CompatibleModelType = types.model('CompatibleModelType', {
  modelCode: types.maybeNull(types.string),
  manufacturingModel: types.maybeNull(types.string)
});

const Car = types.model('Car', {
  brand: types.string,
  model: types.string,
  motor: types.string
});

const compatibleModel = types.model('compatibleModel', {
  car: Car,
  year: Year,
  type: CompatibleModelType
});

const StartaxInfo = types.model('StartaxInfo', {
  compatibleModels: types.optional(types.array(compatibleModel), []),
  alternatives: types.optional(types.array(types.string), []),
  compatibleProducts: types.optional(types.array(types.string), []),
  spareParts: types.optional(types.array(types.string), [])
});

export default StartaxInfo;
