import { types } from 'mobx-state-tree';

const CreditAccountType = {
  DEBT: 'DEBT',
  PREPAID: 'PREPAID'
};

export default CreditAccountType;

export const CreditAccountTypeType = types.enumeration(
  'CreditAccountTypeType',
  Object.values(CreditAccountType)
);
