import React from 'react';
import classNames from 'classnames';

import LinkType from '../../../types/LinkType';

const ButtonLink = ({ className, children, onClick, type, ...rest }) => {
  const onLinkClick = (e) => {
    e.preventDefault();
    onClick(e);
  };

  const onKeyDown = (e) => {
    if (e.key === ' ' || e.key === 'Enter') {
      onClick(e);
    }
  };

  const getTarget = () => {
    if (!type) {
      return;
    }

    if (type === LinkType.EXTERNAL_LINK) {
      return '_blank';
    }

    return '_self';
  };

  return (
    <a
      tabIndex="0"
      role="button"
      className={classNames('ButtonLink', className)}
      onClick={onLinkClick}
      onKeyDown={onKeyDown}
      target={getTarget()}
      {...rest}
    >
      {children}
    </a>
  );
};

export default ButtonLink;
