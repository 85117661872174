import { types } from 'mobx-state-tree';

const Validation = types
  .model('Validation', {
    isValid: types.maybeNull(types.boolean),
  })
  .actions((self) => {
    return {
      validate(value) {
        if (value === null) {
          self.isValid = null;
          return;
        }
        self.isValid = !!value;
      },
    };
  });

export default Validation;
