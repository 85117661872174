import { types } from 'mobx-state-tree';

const Popover = types
  .model('Popover', {
    isOpen: types.optional(types.boolean, false),
    contentId: types.maybeNull(types.number),
  })
  .actions((self) => {
    return {
      setContentId(contentId) {
        self.contentId = contentId;
      },
      toggle() {
        self.isOpen = !self.isOpen;
      },
    };
  });

export default Popover;
