import { types } from 'mobx-state-tree';

const FormFieldId = {
  BILLING_COMPANY: 'BILLING_COMPANY',
  BILLING_COUNTRY: 'BILLING_COUNTRY',
  BILLING_CITY: 'BILLING_CITY',
  BILLING_EMAIL: 'BILLING_EMAIL',
  BILLING_IS_COMPANY: 'BILLING_IS_COMPANY',
  BILLING_NAME: 'BILLING_NAME',
  BILLING_PHONE: 'BILLING_PHONE',
  BILLING_POSTAL_CODE: 'BILLING_POSTAL_CODE',
  BILLING_STREET_ADDRESS: 'BILLING_STREET_ADDRESS',
  BILLING_VAT_NUMBER: 'BILLING_VAT_NUMBER',
  DELIVERY_ADDRESS_DROPDOWN: 'DELIVERY_ADDRESS_DROPDOWN',
  DELIVERY_CITY: 'DELIVERY_CITY',
  DELIVERY_COMPANY: 'DELIVERY_COMPANY',
  DELIVERY_COUNTRY: 'DELIVERY_COUNTRY',
  DELIVERY_IS_COMPANY: 'DELIVERY_IS_COMPANY',
  DELIVERY_NAME: 'DELIVERY_NAME',
  DELIVERY_POSTAL_CODE: 'DELIVERY_POSTAL_CODE',
  DELIVERY_STREET_ADDRESS: 'DELIVERY_STREET_ADDRESS',
  DELIVERY_PHONE: 'DELIVERY_PHONE',
  DELIVERY_VAT_NUMBER: 'DELIVERY_VAT_NUMBER',
  EXTRA_MESSAGE: 'EXTRA_MESSAGE',
  FIRSTNAME: 'FIRSTNAME',
  IS_COMPANY: 'IS_COMPANY',
  LASTNAME: 'LASTNAME',
  PRIVACY_STATEMENT: 'PRIVACY_STATEMENT',
  SEPARATE_DELIVERY_ADDRESS: 'SEPARATE_DELIVERY_ADDRESS',
};

export default FormFieldId;

export const FormFieldIdType = types.enumeration(
  'FormFieldIdType',
  Object.values(FormFieldId)
);
