import React, { Component } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class FilterAcceptButton extends Component {
  render() {
    const { children, className, onClick, ...rest } = this.props;

    return (
      <Button
        className={classNames('FilterAcceptButton', className)}
        onClick={onClick}
        color="primary"
        block
        {...rest}
      >
        {children}
      </Button>
    );
  }
}

FilterAcceptButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node
};

export default FilterAcceptButton;
