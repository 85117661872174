import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import { modelOf } from '../../../prop-types';
import LanguageStore from '../../../store/LanguageStore';
import CountryStore from '../../../store/CountryStore';
import ManufacturerIntegrationType from '../../../types/ManufacturerIntegrationType';
import Manufacturer from '../../../models/Manufacturer';

const LOADBEE_DEBUG = 'false';

@observer
export class LoadBeeProductContentWidget extends Component {
  getLocale = () => {
    const { countryStore, languageStore } = this.props;
    return (
      languageStore.activeLocale.toLowerCase() +
      '_' +
      countryStore.activeCountry.iso_code_2.toUpperCase()
    );
  };

  render() {
    const { gtin, manufacturer } = this.props;

    if (!gtin) {
      return null;
    }

    const integrations = manufacturer.integrations
      .filter(
        (integration) =>
          integration.type === ManufacturerIntegrationType.LOADBEE
      )
      .map((integration, index) => {
        return (
          <div
            key={`${gtin}-${index}`}
            className="LoadBeeProductContentWidget loadbeeTabContent"
            id="loadbeeTabContentId"
            data-loadbee-apikey={integration.api_key}
            data-loadbee-gtin={gtin}
            data-loadbee-locale={this.getLocale()}
            data-loadbee-debug={LOADBEE_DEBUG}
          />
        );
      });

    return (
      <Fragment>
        <Helmet>
          <script
            data-id={uniqueId('LoadBeeProductContentWidget-')}
            async
            src="https://cdn.loadbee.com/js/loadbee_integration.js"
          />
        </Helmet>
        {integrations}
      </Fragment>
    );
  }
}

LoadBeeProductContentWidget.propTypes = {
  countryStore: modelOf(CountryStore).isRequired,
  languageStore: modelOf(LanguageStore).isRequired,
  manufacturer: modelOf(Manufacturer).isRequired,
  gtin: PropTypes.string.isRequired,
};

export default inject(
  'countryStore',
  'languageStore'
)(LoadBeeProductContentWidget);
