import { types } from 'mobx-state-tree';

import StatefulStore from '../models/StatefulStore';
import LayoutBox from '../models/LayoutBox';

const LayoutBoxStore = StatefulStore.named('LayoutBoxStore')
  .props({
    layoutBoxes: types.optional(types.array(LayoutBox), [])
  })
  .actions((self) => ({
    setup: (layoutBoxes) => {
      self.layoutBoxes = layoutBoxes;
      self.setLoading(false);
    }
  }));

export default LayoutBoxStore;
