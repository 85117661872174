import React from 'react';
import ProductTabs from '../../product/ProductTabs';

const SingleRowProductTabs = ({ children, ...props }) => {
  return (
    <ProductTabs singleRowMode={true} {...props}>
      {children}
    </ProductTabs>
  );
};

export default SingleRowProductTabs;
