import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class Hamburger extends Component {
  render() {
    const { active, className } = this.props;
    return (
      <div
        className={classNames(
          'Hamburger',
          'Hamburger--spin',
          { 'is-active': active },
          className
        )}
      >
        <div className="Hamburger__box">
          <div className="Hamburger__inner" />
        </div>
      </div>
    );
  }
}

Hamburger.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool
};

Hamburger.defaultProps = {
  className: ''
};

export default Hamburger;
