import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../common/Icon/';

class Stars extends Component {
  render() {
    const { amount } = this.props;
    let backgroundStars = [];
    for (let i = 0; i < 5; i++) {
      backgroundStars.push(<Icon key={i} name="star" />);
    }
    let stars = [];

    for (let starsLeft = amount; starsLeft >= 0.25; starsLeft--) {
      let iconName = 'star';
      if (starsLeft < 0.75) {
        iconName = 'star-half';
      }
      stars.push(<Icon key={starsLeft} name={iconName} />);
    }
    return (
      <div className="Stars">
        <div className="Stars__background">{backgroundStars}</div>
        <div className="Stars__foreground">{stars}</div>
      </div>
    );
  }
}

Stars.propTypes = {
  amount: PropTypes.number.isRequired
};

export default Stars;
