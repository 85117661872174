import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { inject, observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';

@observer
export class StyleOverrides extends Component {
  render() {
    const { accountStore } = this.props;

    if (!accountStore.account || !accountStore.account.styles) {
      return null;
    }

    return (
      <Helmet
        style={[
          {
            type: 'text/css',
            cssText: `${accountStore.account.styles}`
          }
        ]}
      />
    );
  }
}

StyleOverrides.propTypes = {
  accountStore: modelOf(AccountStore).isRequired
};

export default inject('accountStore')(StyleOverrides);
