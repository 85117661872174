import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormGroup, Input, Label } from 'reactstrap';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape
} from 'react-intl';
import PropTypes from 'prop-types';

import RecurringOrderCycleType from '../../../types/RecurringOrderCycleType';

const messages = defineMessages({
  placeholderCycle: {
    id: 'recurringOrder.cyclePlaceholder',
    defaultMessage: 'Choose your cycle'
  },
  weekly: {
    id: 'recurringOrder.cycleWeekly',
    defaultMessage: 'Every week'
  },
  biWeekly: {
    id: 'recurringOrder.cycleBiWeekly',
    defaultMessage: 'Every other week'
  },
  monthly: {
    id: 'recurringOrder.cycleMonthly',
    defaultMessage: 'Every month'
  },
  biMonthly: {
    id: 'recurringOrder.cycleBiMonthly',
    defaultMessage: 'Every other month'
  },
  quarterly: {
    id: 'recurringOrder.cycleQuarterly',
    defaultMessage: 'Every three months'
  },
  custom: {
    id: 'recurringOrder.cycleCustom',
    defaultMessage: 'Choose your interval'
  }
});

@observer
export class RecurringCycleDropdown extends Component {
  getCycleTranslation = (cycle) => {
    const { intl } = this.props;

    switch (cycle) {
      case RecurringOrderCycleType.WEEKLY:
        return intl.formatMessage(messages.weekly);
      case RecurringOrderCycleType.BIWEEKLY:
        return intl.formatMessage(messages.biWeekly);
      case RecurringOrderCycleType.MONTHLY:
        return intl.formatMessage(messages.monthly);
      case RecurringOrderCycleType.BIMONTHLY:
        return intl.formatMessage(messages.biMonthly);
      case RecurringOrderCycleType.QUARTERLY:
        return intl.formatMessage(messages.quarterly);
      case RecurringOrderCycleType.CUSTOM:
        return intl.formatMessage(messages.custom);
      default:
        return null;
    }
  };

  render() {
    const { cycles, intl, onChange, selectedValue } = this.props;

    if (cycles.length === 0) {
      return null;
    }

    const options = cycles.map((cycle) => {
      return (
        <option key={cycle} value={cycle}>
          {this.getCycleTranslation(cycle)}
        </option>
      );
    });

    return (
      <FormGroup>
        <Label for="RecurringCycleDropdown__cycle-label">
          <FormattedMessage
            id="recurringOrder.selectCycle"
            defaultMessage="Choose cycle for your subscription"
          />
        </Label>
        <Input
          type="select"
          name="select"
          className="RecurringCycleDropdown__cycle-select"
          id="RecurringCycleDropdown__cycle-select"
          onChange={onChange}
          defaultValue={selectedValue}
        >
          <option value="">
            {intl.formatMessage(messages.placeholderCycle)}
          </option>
          {options}
        </Input>
      </FormGroup>
    );
  }
}

RecurringCycleDropdown.propTypes = {
  intl: intlShape.isRequired,
  cycles: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string
};

export default injectIntl(RecurringCycleDropdown);
