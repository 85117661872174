import { types } from 'mobx-state-tree';

const UserTokenType = {
  GIOSG: 'GIOSG'
};

export default UserTokenType;

export const UserTokenTypeType = types.enumeration(
  'UserTokenType',
  Object.values(UserTokenType)
);
