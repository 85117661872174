import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';

import Spinner from '../Spinner';

class UninitializedAppSpinner extends Component {
  render() {
    return (
      <IntlProvider locale="en" messages={{}}>
        <div className="UninitializedAppSpinner">
          <Spinner />
        </div>
      </IntlProvider>
    );
  }
}

export default UninitializedAppSpinner;
