import { forEach, isEqual, split, uniq } from 'lodash';
import { applyPatch, flow, getEnv, getRoot, types } from 'mobx-state-tree';

import Error from '../models/Error';
import Product from '../models/Product';
import ProductSearchPaginator from '../models/ProductSearchPaginator';
import StatefulStore from '../models/StatefulStore';
import ProductSortSelectorMap from '../types/ProductSortOrderByMap';
import RequestState, { RequestStateType } from '../types/RequestState';
import createMSTPatch from '../util/createMSTPatch';
import { createErrorModel } from '../util/error';
import { paramsToQueryIdentifier } from '../util/query';
import { stringify } from '../util/queryString';

const LAST_VISITED_PRODUCTS_KEY = 'nh_last_visited_products';
const MAXIMUM_STORED_QUERIES = 10;
const MAXIMUM_STORED_PRODUCTS = 1000;

const ProductStore = StatefulStore.named('ProductStore')
  .props({
    productListState: types.optional(RequestStateType, RequestState.NONE),
    productPdfQueryResults: types.optional(types.map(RequestStateType), {}),
    productQueryResults: types.optional(types.map(ProductSearchPaginator), {}),
    productQueryStates: types.optional(types.map(RequestStateType), {}),
    products: types.optional(types.map(Product), {}),
    productSortOptions: types.frozen(ProductSortSelectorMap),
    productStates: types.optional(types.map(RequestStateType), {}),
    productVariationColumn: types.maybeNull(types.string),
    productVariationRow: types.maybeNull(types.string),
    selectedProductColumn: types.maybeNull(types.string),
    selectedProductRow: types.maybeNull(types.string),
    uploadedFiles: types.optional(
      types.array(
        types.model({
          id: types.string,
          files: types.array(types.string),
        })
      ),
      []
    ),
    uploadedFilesError: types.maybeNull(Error),
    uploadedFilesState: types.optional(RequestStateType, RequestState.NONE),
  })
  .actions((self) => {
    const baseApi = (endpoint) =>
      ifShoppingCenter() ? `shopping-center/${endpoint}` : `${endpoint}`;

    const ifShoppingCenter = () =>
      getRoot(self).configStore.siteConfig.isShoppingCenter;

    const activeProductSortOptions = () =>
      split(
        getRoot(self).configStore.productList.productListSortSelectors,
        ','
      );

    const customerGroupId = () => getRoot(self).accountStore.getCustomerGroupId;

    const updateIndividualProducts = (products) => {
      products.forEach((product) => {
        let identifiersEqual = true;
        const oldProduct = self.products.get(product.id);
        if (oldProduct) {
          updateOldProduct(product, oldProduct, identifiersEqual);
        } else {
          self.products.set(product.id, product);
          self.filterByCustomerGroupVisibility(product.id);
        }
        self.productStates.set(product.id, RequestState.LOADED);
      });
    };

    const updateOldProduct = (product, oldProduct, identifiersEqual) => {
      self.filterByCustomerGroupVisibility(oldProduct.id);
      if (oldProduct.multi && product.multi && product.multi.children) {
        identifiersEqual = isEqual(
          oldProduct.multi.getChildIdentifiers(),
          product.multi.children.map((child) => child.id)
        );
      }

      if (identifiersEqual) {
        const patch = createMSTPatch(oldProduct, product);
        applyPatch(oldProduct, patch);
      }
    };

    return {
      afterCreate: () => {
        self.lastVisitedProducts = self.getLocalStorageLastVisitedIds();
      },
      gcProductQueries: () => {
        if (self.productQueryResults.size > MAXIMUM_STORED_QUERIES) {
          const queriesToDelete = [];

          // We use lodash forEach instead of for ... of for IE 11 compatibility
          forEach([...self.productQueryResults.keys()], (queryKey) => {
            queriesToDelete.push(queryKey);
            const queriesLeft =
              self.productQueryResults.size - queriesToDelete.length;
            if (queriesLeft <= MAXIMUM_STORED_QUERIES) {
              return false; //break
            }
          });
          self.deleteQueries(queriesToDelete);
        }
      },
      gcProducts: () => {
        if (self.products.size > MAXIMUM_STORED_PRODUCTS) {
          const referencedProducts = {};
          self.productQueryResults.forEach((productSearchPaginator) => {
            productSearchPaginator.data.forEach((product) => {
              referencedProducts[product.id] = 1;
            });
          });
          const productsToDelete = [];

          // We use lodash forEach instead of for ... of for IE 11 compatibility
          forEach([...self.products.keys()], (id) => {
            if (!referencedProducts[id]) {
              productsToDelete.push(id);
            }
            const productsLeft = self.products.size - productsToDelete.length;
            if (productsLeft <= MAXIMUM_STORED_PRODUCTS) {
              return false; //break
            }
          });
          self.deleteProducts(productsToDelete);
        }
      },
      activeProductSortList: () => {
        const activeSortList = activeProductSortOptions();
        return activeSortList
          ? self.productSortOptions.filter(
              (ss) => activeSortList.indexOf(ss.sortIndex.toString()) > -1
            )
          : self.productSortOptions[0];
      },
      deleteQueries: (keys) => {
        keys.forEach((key) => {
          self.productQueryResults.delete(key);
          self.productQueryStates.delete(key);
        });
      },
      deleteProducts: (ids) => {
        ids.forEach((id) => {
          self.productStates.delete(id);
          self.products.delete(id);
        });
      },
      filterByCustomerGroupVisibility(productId) {
        const product = self.products.get(productId);

        if (product && product.isMulti()) {
          const children = product.multi.children.filter((childProduct) =>
            childProduct.hasCustomerGroupVisibility(customerGroupId())
          );
          product.multi.setChildren(children);
        }
      },
      getLocalStorageLastVisitedIds: () => {
        return (
          JSON.parse(window.localStorage.getItem(LAST_VISITED_PRODUCTS_KEY)) ||
          []
        );
      },
      insertLastVisitedProduct: (productId) => {
        const localStorageIds = self.getLocalStorageLastVisitedIds();
        localStorageIds.unshift(productId);
        const uniqueIds = uniq(localStorageIds).slice(0, 20);

        window.localStorage.setItem(
          LAST_VISITED_PRODUCTS_KEY,
          JSON.stringify(uniqueIds)
        );
        self.lastVisitedProducts = uniqueIds;
      },
      loadProduct: flow(function* loadProduct(id, params = null) {
        // Backend is case insensitive
        // and in case of mix of lowercase / uppercase it will return product with new id.
        let fixedId = id;
        self.setLoading(true);
        self.productStates.set(id, RequestState.LOADING);

        try {
          const product = yield getEnv(self).apiWrapper.request(
            `${baseApi('products')}/${id}`,
            { params }
          );
          self.products.set(product.id, product);
          self.filterByCustomerGroupVisibility(product.id);

          if (!isEqual(product.id, id)) {
            fixedId = product.id;
          }
        } catch (e) {
          self.setError(e);
          self.productStates.set(id, RequestState.ERROR);
          throw e;
        }

        self.setLoading(false);
        self.productStates.set(fixedId, RequestState.LOADED);
        return fixedId;
      }),
      loadProducts: flow(function* loadProducts(
        searchParameters,
        allSections = false,
        overrideUrl = null
      ) {
        const queryIdentifier = paramsToQueryIdentifier(searchParameters);
        const url = overrideUrl ? overrideUrl : baseApi('products');
        // Skip the API call if we already have the data
        if (self.productQueryStates.get(queryIdentifier)) {
          return;
        }

        self.setLoading(true);
        self.productQueryStates.set(queryIdentifier, RequestState.LOADING);

        try {
          let productSearchPaginator;
          if (allSections) {
            productSearchPaginator = yield getEnv(self).apiWrapper.request(
              `${url}?${stringify(searchParameters)}`,
              {},
              { active_section: null }
            );
          } else {
            productSearchPaginator = yield getEnv(self).apiWrapper.request(
              `${url}?${stringify(searchParameters)}`
            );
          }
          const products = productSearchPaginator.data;

          // Update individual products and statuses
          updateIndividualProducts(products);

          // Set current query results
          self.productQueryResults.set(queryIdentifier, {
            ...productSearchPaginator,
            data: products.map((product) => product.id),
          });
        } catch (e) {
          self.setError(e);
          self.productQueryStates.set(queryIdentifier, RequestState.ERROR);
          throw e;
        }

        self.setLoading(false);
        self.productQueryStates.set(queryIdentifier, RequestState.LOADED);
      }),
      updateProduct: (product) => {
        self.products.set(product.id, product);
        self.filterByCustomerGroupVisibility(product.id);
        self.productStates.set(product.id, RequestState.LOADED);
      },
      loadUploadedFiles: flow(function* loadUploadedFiles() {
        self.uploadedFilesState = RequestState.LOADING;

        try {
          self.uploadedFiles = yield getEnv(self).apiWrapper.request(
            `product-file-uploads`,
            {},
            { active_section: null }
          );
        } catch (e) {
          self.uploadedFilesError = createErrorModel(e);
          self.uploadedFilesState = RequestState.ERROR;
          throw e;
        }

        self.uploadedFilesState = RequestState.LOADED;
      }),
      getProductList: flow(function* getProductList() {
        self.productListState = RequestState.LOADING;

        let productList = [];

        try {
          productList = yield getEnv(self).apiWrapper.request(
            `product-lists/products`
          );
        } catch (e) {
          self.productListState = RequestState.ERROR;
          throw e;
        }

        self.productListState = RequestState.LOADED;
        return productList;
      }),
      getProductByAccessToken: (params) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .get(`product-lists/products`, { params });
      },
      saveProduct: (product) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .post(`product-lists/add-product`, product);
      },
      deleteProduct: flow(function* deleteProduct(params) {
        self.productListState = RequestState.LOADING;
        try {
          yield getEnv(self)
            .apiWrapper.apiAxios()
            .delete(`product-lists/delete-product`, { params });
        } catch (e) {
          self.productListState = RequestState.ERROR;
          throw e;
        }
        self.productListState = RequestState.LOADED;
      }),
      sendProductListEmail: (productListEmail) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .post(`product-lists/send`, productListEmail);
      },
      getProductPdf: (id, params = null) => {
        const activeId = !!id ? `/${id}` : ``;

        return getEnv(self)
          .apiWrapper.apiAxios()
          .post(`product-pdf${activeId}`, params);
      },
      setProductVariationColumn: (column) => {
        self.productVariationColumn = column;
      },
      getProductVariationColumn: () => {
        return self.productVariationColumn;
      },
      setProductVariationRow: (row) => {
        self.productVariationRow = row;
      },
      getProductVariationRow: () => {
        return self.productVariationRow;
      },
      setSelectedColumn: (column) => {
        self.selectedProductColumn = column;
      },
      setSelectedRow: (row) => {
        self.selectedProductRow = row;
      },
    };
  })
  .views((self) => ({
    getProductById: (activeProductId, ifGetActualProduct = true) => {
      const product = self.products.get(activeProductId);
      if (product && ifGetActualProduct) {
        return product.getActualProduct(activeProductId);
      }

      return self.products.get(activeProductId);
    },
  }));

export default ProductStore;
