import { keyBy } from 'lodash';

import OrderTotalClass from '../../../types/OrderTotalClass';

class Purchase {
  coupon = '';
  currency = '';
  transaction_id = '';
  value = 0;
  postal_code = '';
  city = '';
  items = [];
  country = '';
  tax = 0;
  shipping = 0;

  constructor(item, currentOrder, activeCountry) {
    this.item = item;
    this.currentOrder = currentOrder;
    this.activeCountry = activeCountry;
  }

  createPurchaseEvent() {
    const { postalcode, city } = this.currentOrder.customerInfo;

    const orderTotalsByClass = keyBy(this.currentOrder.totals, 'class');

    const getTotalByClass = (totalClass) => {
      const total = orderTotalsByClass[totalClass];

      // The totals we use should always be there, but...
      return total ? Number(total.value.toFixed(4)) : null;
    };

    const orderProducts = this.currentOrder.products.map((product) => {
      return this.item.analyticsItemToItem(
        {
          product,
          quantity: product.quantity,
        },
        true
      );
    });

    const tax = getTotalByClass(OrderTotalClass.TAX_TOTAL);
    const shipping = getTotalByClass(OrderTotalClass.EXTRA_TOTAL);

    this.currency = this.currentOrder.currency;
    this.transaction_id = this.currentOrder.id_for_analytics;
    this.value = getTotalByClass(OrderTotalClass.TOTAL);
    this.postal_code = postalcode;
    this.city = city;
    this.items = orderProducts;

    if (this.currentOrder.campaign_code) {
      this.coupon = this.currentOrder.campaign_code;
    }

    if (this.activeCountry) {
      this.country = this.activeCountry.iso_code_2;
    }

    if (tax) {
      this.tax = tax;
    }

    if (shipping) {
      this.shipping = shipping;
    }
  }

  getPurchase() {
    const purchase = {
      currency: this.currency,
      transaction_id: this.transaction_id,
      value: this.value,
      postal_code: this.postal_code,
      city: this.city,
      items: this.items,
    };

    if (this.coupon) {
      purchase.coupon = this.coupon;
    }

    if (this.country) {
      purchase.country = this.country;
    }

    if (this.tax) {
      purchase.tax = this.tax;
    }

    if (this.shipping) {
      purchase.shipping = this.shipping;
    }

    return purchase;
  }
}

export default Purchase;
