import { types } from 'mobx-state-tree';

const ShippingSelectionFieldOption = types.model(
  'ShippingSelectionFieldOption',
  {
    id: types.identifierNumber,
    name: types.string
  }
);

export default ShippingSelectionFieldOption;
