import React, { Component } from 'react';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Paths from '../../../types/Paths';
import generatePath from '../../../util/generatePath';
import QueryParamRedirect from '../QueryParamRedirect';
import RouteService from '../../../services/RouteService';
import RouterPropTypes from 'react-router-prop-types';
import { parse } from '../../../util/queryString';

export class PasswordResetRedirect extends Component {
  getRedirectTarget = (param) => {
    return this.props.routeService.getPath(
      generatePath(Paths.SetNewPassword, {
        token: param
      })
    );
  };

  render() {
    const { location } = this.props;
    const { prcd } = parse(location.search);

    const param = prcd ? 'prcd' : 'renew_code';

    return <QueryParamRedirect paramName={param} to={this.getRedirectTarget} />;
  }
}

PasswordResetRedirect.propTypes = {
  location: RouterPropTypes.location.isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired
};

export default inject('routeService')(withRouter(PasswordResetRedirect));
