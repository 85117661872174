import {
  cloneDeep,
  get,
  has,
  head,
  isEqual,
  partition,
  set,
  setWith,
  unset,
} from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';
import { Col, Row } from 'reactstrap';

import { modelOf } from '../../../prop-types';
import RouteService from '../../../services/RouteService';
import ConfigStore from '../../../store/ConfigStore';
import ProductStore from '../../../store/ProductStore';
import UIStore from '../../../store/UIStore';
import FilterType from '../../../types/FilterType';
import ProductClass from '../../../types/ProductClass';
import ProductSortOrderBy from '../../../types/ProductSortOrderBy';
import { scrollToElementById } from '../../../util/dom';
import { paramsToQueryIdentifier } from '../../../util/query';
import { parse, stringify } from '../../../util/queryString';
import {
  escapeUrlForPaginatorTemplate,
  escapeUrlParametersForPaginatorTemplate,
} from '../../../util/url';
import ScrollableAnchor from '../../anchor/ScrollableAnchor';
import MobilePaginator from '../../common/MobilePaginator';
import Paginator from '../../common/Paginator';
import RobotsMeta from '../../head/RobotsMeta';
import SEOPagination from '../../head/SEOPagination';
import ContentForState from '../../loader/ContentForState';
import PageSkeleton from '../../skeleton/PageSkeleton';
import ActiveFilterList from '../ActiveFilterList';
import BooleanFilter from '../BooleanFilter';
import FilteringOptions from '../FilteringOptions';
import NoProductsFound from '../NoProductsFound';
import ProductList from '../ProductList';
import ProductListFilter from '../ProductListFilter';
import ProductListTools from '../ProductListTools';

const ProductSortParameters = {
  ORDER_BY: 'orderBy',
  ORDER: 'order',
};

// set treats numeric keys as arrays, we use setWith to force objects
const setObject = (object, path, value) => {
  setWith(object, path, value, Object);
};

export const ANCHOR_ID = 'mainProductList';
const IN_STOCK_FILTER_KEY = 'inStock';

@observer
export class MainProductList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAllFilters: false,
      searchTerm: '',
    };

    this.validateActiveSortParameters();
    this.loadProducts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(prevProps.fixedParams, this.props.fixedParams) ||
      !isEqual(prevState.searchParameters, this.getSearchParameters()) ||
      prevState.searchTerm !== this.state.searchTerm
    ) {
      this.loadProducts();
    }
    this.validateFilters();
  }

  getSearchParameters = () =>
    MainProductList.propsToSearchParameters(this.props);

  /**
   *  Check if current filters can all be found in the current search result.
   *  If not, remove them, because it means they are not longer valid.
   */
  validateFilters = () => {
    const searchParameters = this.getSearchParameters();
    const searchResult = this.getCurrentSearchResult();
    if (searchResult && searchResult.available_filters) {
      const validFilters = {};

      searchResult.available_filters.forEach((filter) => {
        const currentValue = get(searchParameters.filters, filter.key);
        if (currentValue) {
          setObject(validFilters, filter.key, currentValue);
        }
      });

      if (!isEqual(searchParameters.filters, validFilters)) {
        this.setSearchParameters({
          ...searchParameters,
          filters: validFilters,
        });
      }
    }
  };

  validateActiveSortParameters = () => {
    const { configStore, productStore } = this.props;
    const productListSelectors =
      configStore.productList.productListSortSelectors;
    const searchParameters = this.getSearchParameters();

    if (
      !productListSelectors ||
      !searchParameters.sort ||
      !Object.keys(searchParameters.sort).length
    ) {
      return;
    }

    const activeProductSortSelectors = productStore.activeProductSortList();

    const validSortParameter = activeProductSortSelectors.find(
      (ss) =>
        ss.sortKey === searchParameters.sort.orderBy &&
        ss.sortOrder === searchParameters.sort.order
    );

    if (!validSortParameter) {
      searchParameters.sort = {};
      this.setSearchParameters(searchParameters);
    }
  };

  getQueryIdentifier = () => {
    let productLoadParameters = this.getProductLoadParameters();

    productLoadParameters = this.addSearchTermToProductLoadParameters(
      productLoadParameters
    );
    return paramsToQueryIdentifier(productLoadParameters);
  };

  static propsToSearchParameters = (props) => {
    const {
      location: { search },
    } = props;

    const parsedSearchParameters = parse(search);

    return MainProductList.transformParameters(parsedSearchParameters);
  };

  setSearchParameters = (parameters) => {
    const { history } = this.props;
    history.push({
      search: stringify(
        MainProductList.transformParametersBeforeStringify({
          ...parameters,
          // Always reset page to 1 when filtering changes
          page: 1,
        })
      ),
    });
  };

  /**
   * Transform "f "and "s" to "filter" and "sort".
   */
  static transformParameters(parameters) {
    parameters.filters = parameters.f || {};
    delete parameters.f;
    parameters.sort = parameters.s || {};
    delete parameters.s;

    return parameters;
  }

  /**
   * Transform "filter" and "sort" to "f" and "s".
   */
  static transformParametersBeforeStringify(parameters) {
    parameters.f = parameters.filters;
    delete parameters.filters;
    parameters.s = parameters.sort;
    delete parameters.sort;
    return parameters;
  }

  getProductLoadParameters = () => {
    const { fixedParams, configStore, showCategoryFilter } = this.props;
    const searchParameters = this.getSearchParameters();

    const optimizer = {
      excludeContent: 1,
      optimizeResponse: 1,
    };
    const optimizedParams = { ...fixedParams, ...optimizer };

    if (!searchParameters) {
      return { ...optimizedParams };
    }

    if (
      showCategoryFilter &&
      this.categoryFilterActive(searchParameters) &&
      optimizedParams.cross_categories
    ) {
      delete optimizedParams.cross_categories;
    }

    return {
      inStock: configStore.inStockProductsOnly ? '1' : '0',
      page: searchParameters.page,
      ...searchParameters.filters,
      ...optimizedParams,
      ...searchParameters.sort,
    };
  };

  categoryFilterActive = (searchParameters) => {
    return (
      searchParameters.filters.cross_categories &&
      searchParameters.filters.cross_categories.length > 0
    );
  };

  loadProducts = () => {
    const { productStore, allSections, urlOverride, updateProductCount } =
      this.props;
    let productLoadParameters = this.getProductLoadParameters();
    productLoadParameters = this.addSearchTermToProductLoadParameters(
      productLoadParameters
    );
    const results = this.getCurrentSearchResult();

    if (!results && !this.getCurrentSearchState()) {
      productStore
        .loadProducts(productLoadParameters, allSections, urlOverride)
        .then(() => {
          const results = this.getCurrentSearchResult();
          if (results) {
            updateProductCount(results.total);
          }
        });
    } else if (results) {
      if (results.total > 0) {
        updateProductCount(results.total);
      }
    }
  };

  addSearchTermToProductLoadParameters = (productLoadParameters) => {
    if (
      !this.props.configStore.productList.filterProductsByText ||
      !this.state.searchTerm
    ) {
      return productLoadParameters;
    }

    productLoadParameters.text = this.state.searchTerm;
    return productLoadParameters;
  };

  onAcceptFilter = (filter, value) => {
    const searchParameters = cloneDeep(this.getSearchParameters());

    switch (filter.type) {
      case FilterType.BOOLEAN: {
        if (value) {
          setObject(searchParameters.filters, filter.key, 1);
        } else {
          unset(searchParameters.filters, filter.key);
        }
        break;
      }
      case FilterType.RANGE: {
        setObject(searchParameters.filters, filter.key, {
          min: Number(value[0]),
          max: Number(value[1]),
        });
        break;
      }
      case FilterType.SINGLETERM:
      case FilterType.TERM: {
        setObject(searchParameters.filters, filter.key, value);
        break;
      }
      default: {
        console.error('Unknown filter type ' + filter.type);
      }
    }

    this.setSearchParameters(searchParameters);
  };

  onInStockAccept = (filter, inStockFilterValue) => {
    const { configStore } = this.props;
    if (configStore.inStockProductsOnly !== inStockFilterValue) {
      configStore.setInStockProductsOnly(inStockFilterValue);
    }
    this.loadProducts();
  };

  removeFilter = (activeFilter, valueToRemove) => {
    const searchParameters = cloneDeep(this.getSearchParameters());

    switch (activeFilter.filter.type) {
      case FilterType.SINGLETERM:
      case FilterType.TERM: {
        let values = get(searchParameters.filters, activeFilter.key);
        let indexToRemove = values.indexOf(valueToRemove);
        if (indexToRemove !== -1) values.splice(indexToRemove, 1);
        break;
      }
      case FilterType.RANGE: {
        unset(searchParameters.filters, activeFilter.key + '[min]');
        unset(searchParameters.filters, activeFilter.key + '[max]');
        break;
      }
      default: {
        unset(searchParameters.filters, activeFilter.key);
      }
    }

    this.setSearchParameters(searchParameters);
  };

  clearFilters = () => {
    this.setSearchParameters({
      ...this.getSearchParameters(),
      filters: [],
    });
  };

  onSelectSortOrder = (sortOrderKey, ascDesc) => {
    const searchParameters = cloneDeep(this.getSearchParameters());
    if (sortOrderKey) {
      set(searchParameters.sort, ProductSortParameters.ORDER_BY, sortOrderKey);
    } else {
      unset(searchParameters.sort, ProductSortParameters.ORDER_BY);
    }

    if (ascDesc) {
      set(searchParameters.sort, ProductSortParameters.ORDER, ascDesc);
    } else {
      unset(searchParameters.sort, ProductSortParameters.ORDER);
    }

    this.setSearchParameters(searchParameters);
  };

  getSelectedSortOrder = () => {
    const searchParameters = this.getSearchParameters();
    return get(searchParameters.sort, ProductSortParameters.ORDER);
  };

  renderProductListFilter = () => {
    const { disableQuickSearch } = this.props;
    if (disableQuickSearch) {
      return null;
    }

    return (
      <Row>
        <Col
          lg={8}
          xl={6}
          className="MainProductList__active-filter--quicksearch mr-lg-auto"
        >
          <ProductListFilter
            onProductListFiltering={this.filterProductList}
            clearProductListFilter={this.clearProductListFilter}
            searchTerm={this.state.searchTerm}
          />
        </Col>
      </Row>
    );
  };

  filterProductList = (searchTerm) => {
    if (searchTerm) {
      this.setSearchParameters();
      this.setState({ searchTerm });
    }

    if (!searchTerm && this.state.searchTerm) {
      this.clearProductListFilter();
    }
  };

  clearProductListFilter = () => {
    if (this.state.searchTerm) {
      this.setState({ searchTerm: '' });
    }
  };

  getSEOPagination = () => {
    const { location } = this.props;
    const { last_page: lastPage, current_page: currentPage } =
      this.getCurrentSearchResult();

    return (
      <SEOPagination
        currentPage={currentPage}
        lastPage={lastPage}
        path={location.pathname}
      />
    );
  };

  getPaginator = () => {
    const { uiStore, location } = this.props;
    const { last_page: lastPage, current_page: currentPage } =
      this.getCurrentSearchResult();

    if (lastPage < 2) {
      return null;
    }

    let searchTemplate;
    const regex = /page=(\d+?)(?=(&|$))/;
    const pageParameter = `page=:page`;

    const urlEncodedParameters = escapeUrlParametersForPaginatorTemplate(
      location.search
    );

    if (location.search.charAt(0) !== '?') {
      searchTemplate = location.search + '?' + pageParameter;
    } else {
      searchTemplate = urlEncodedParameters.match(regex)
        ? urlEncodedParameters.replace(regex, pageParameter)
        : urlEncodedParameters + '&' + pageParameter;
    }
    const pathTemplate =
      escapeUrlForPaginatorTemplate(location.pathname) + searchTemplate;

    if (uiStore.isMobile) {
      return (
        <MobilePaginator
          pathTemplate={pathTemplate}
          currentPage={currentPage}
          onClick={this.scrollToTop}
          lastPage={lastPage}
        />
      );
    }

    return (
      <Paginator
        pathTemplate={pathTemplate}
        currentPage={currentPage}
        lastPage={lastPage}
        pagesToShow={5}
        onClick={this.scrollToTop}
      />
    );
  };

  scrollToTop = () => {
    scrollToElementById(ANCHOR_ID, { behavior: 'instant' });
  };

  toggleShowAll = () => {
    this.setState({ showAllFilters: !this.state.showAllFilters });
  };

  renderAdditionalContentRobotsMeta = () => {
    const { additionalContent } = this.props;
    const productSearchResults = this.getCurrentSearchResult();
    return (
      !productSearchResults.isIndexableByRobots && (
        <additionalContent.robotsMeta noindex nofollow />
      )
    );
  };

  getSelectedSortOrderBy = () => {
    const searchParameters = this.getSearchParameters();
    return get(searchParameters.sort, ProductSortParameters.ORDER_BY);
  };

  redirectToProduct = (product) => {
    const { routeService, location } = this.props;
    const queryParams = parse(location.search);
    const focusQuantity = queryParams.focusQuantity;

    const path =
      product.class === ProductClass.MULTI_CHILD
        ? product.pathWithActiveProductId(product.id)
        : product.path;

    const pathToNavigate = routeService.getProductPath(product, path);

    let to = {
      pathname: pathToNavigate,
    };

    if (focusQuantity) {
      to = {
        ...to,
        search: `?focusQuantity=${focusQuantity}`,
      };
    }

    return <Redirect to={to} />;
  };

  getActiveFilters = () => {
    const searchResult = this.getCurrentSearchResult();
    if (!searchResult || !searchResult.available_filters) {
      return [];
    }
    const searchParameters = cloneDeep(this.getSearchParameters());
    return searchResult.available_filters.reduce((activeFilters, filter) => {
      if (has(searchParameters.filters, filter.key)) {
        const filterValue = get(searchParameters.filters, filter.key);
        activeFilters.push({
          key: filter.key,
          filter,
          name: filter.name,
          value: filterValue.min || filterValue.max ? null : filterValue,
          min: filterValue.min ? Number(filterValue.min) : null,
          max: filterValue.max ? Number(filterValue.max) : null,
        });
      }
      return activeFilters;
    }, []);
  };

  getCurrentSearchResult = () =>
    this.props.productStore.productQueryResults.get(this.getQueryIdentifier());

  getCurrentSearchState = () =>
    this.props.productStore.productQueryStates.get(this.getQueryIdentifier());

  render() {
    const {
      configStore,
      productStore,
      fixedParams,
      listId,
      name,
      showSort,
      cardSettings,
      redirectOnSingleMatch,
      showCategoryFilter,
      productParams,
      showCategoryHeaders,
      advancedSearchPage,
      additionalContent,
      productListRef,
    } = this.props;

    const { showAllFilters } = this.state;
    if (!productStore || !fixedParams) {
      return;
    }

    return (
      <div className="MainProductList" ref={productListRef}>
        <ScrollableAnchor id={ANCHOR_ID} />
        <ContentForState
          state={this.getCurrentSearchState()}
          forPlaceHolder={<PageSkeleton />}
          forLoaded={() => {
            const searchResult = this.getCurrentSearchResult();
            const products = searchResult.data;
            const hasProducts = products && products.length > 0;

            const filters =
              searchResult && searchResult.available_filters
                ? searchResult.available_filters.filter((filter) => {
                    if (filter.key === 'cross_categories') {
                      return showCategoryFilter;
                    }
                    return !has(fixedParams, filter.key);
                  })
                : [];
            const countOfProducts = searchResult ? searchResult.total : 0;
            const activeFilters = this.getActiveFilters();

            const shouldRedirectToProduct =
              redirectOnSingleMatch &&
              products.length === 1 &&
              countOfProducts === 1 &&
              activeFilters.length === 0;

            if (shouldRedirectToProduct) {
              return this.redirectToProduct(head(products));
            }

            // In stock filter is shown separately
            const [inStockFilters, restOfFilters] = partition(filters, [
              'key',
              IN_STOCK_FILTER_KEY,
            ]);
            const inStockFilter = head(inStockFilters);
            const restOfActiveFilters = activeFilters.filter(
              (activeFilter) => activeFilter.key !== IN_STOCK_FILTER_KEY
            );

            const doShowHeaders =
              showCategoryHeaders ||
              this.getSelectedSortOrderBy() === ProductSortOrderBy.CATEGORY ||
              (!this.getSelectedSortOrderBy() &&
                !advancedSearchPage &&
                configStore.productList.defaultSortOption ===
                  ProductSortOrderBy.CATEGORY);

            return (
              <div className="MainProductList__content">
                {additionalContent?.robotsMeta &&
                  this.renderAdditionalContentRobotsMeta()}
                {activeFilters.length > 0 ? (
                  <RobotsMeta noindex />
                ) : (
                  this.getSEOPagination()
                )}
                {hasProducts && (
                  <FilteringOptions
                    filters={restOfFilters}
                    activeFilters={restOfActiveFilters}
                    onAcceptFilter={this.onAcceptFilter}
                    toggleShowAll={this.toggleShowAll}
                    showAllFilters={showAllFilters}
                    inGrid
                  />
                )}
                <ActiveFilterList
                  activeFilters={restOfActiveFilters}
                  removeFilter={this.removeFilter}
                  clearFilters={this.clearFilters}
                  horizontal
                />
                {configStore.productList.filterProductsByText &&
                  (hasProducts || this.state.searchTerm) &&
                  this.renderProductListFilter()}
                {!configStore.siteConfig.isHomePage && (
                  <div className="MainProductList__active-filter-row">
                    <ProductListTools
                      countOfProducts={countOfProducts}
                      onSelectSort={this.onSelectSortOrder}
                      selectedSortOrderBy={this.getSelectedSortOrderBy()}
                      selectedSortOrder={this.getSelectedSortOrder()}
                      showSort={showSort}
                    />
                    {!configStore.hideProductWithZeroQuantity &&
                      configStore.siteConfig.isWebStore && (
                        <BooleanFilter
                          onAccept={this.onInStockAccept}
                          filter={inStockFilter}
                          isSelected={configStore.inStockProductsOnly}
                        />
                      )}
                  </div>
                )}
                {hasProducts ? (
                  <ProductList
                    products={products}
                    listId={listId}
                    name={name}
                    cardSettings={cardSettings}
                    productParams={productParams}
                    showCategoryHeaders={doShowHeaders}
                  />
                ) : (
                  <NoProductsFound />
                )}
                {hasProducts && this.getPaginator()}
              </div>
            );
          }}
        />
      </div>
    );
  }
}

MainProductList.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  history: RouterPropTypes.history.isRequired,
  location: RouterPropTypes.location.isRequired,
  fixedParams: PropTypes.object.isRequired,
  listId: PropTypes.string,
  name: PropTypes.string,
  searchTerm: PropTypes.string,
  urlOverride: PropTypes.string,
  advancedSearchPage: PropTypes.bool,
  allSections: PropTypes.bool,
  disableQuickSearch: PropTypes.bool,
  redirectOnSingleMatch: PropTypes.bool,
  showCategoryFilter: PropTypes.bool,
  showCategoryHeaders: PropTypes.bool,
  showSort: PropTypes.bool,
  additionalContent: PropTypes.object,
  dynamicParams: PropTypes.object,
  productListRef: PropTypes.object,
  clearProductListFilter: PropTypes.func,
  productListFilterTerm: PropTypes.func,
  updateProductCount: PropTypes.func,
};

MainProductList.defaultProps = {
  showSort: true,
  allSections: false,
  redirectOnSingleMatch: false,
  urlOverride: null,
  disableQuickSearch: false,
  showCategoryHeaders: false,
  advancedSearchPage: false,
  updateProductCount: () => null,
};

export default withRouter(
  inject(
    'configStore',
    'productStore',
    'routeService',
    'uiStore'
  )(MainProductList)
);
