import { chunk, debounce } from 'lodash';

import {
  CustomEventNames,
  GoogleAnalytics4EventNames,
} from '../AnalyticsEventNames';
import Purchase from './Events/Purchase';
import Item from './Models/Item';
import Promotion from './Events/Promotion';

const IMPRESSION_MAX_BATCH_SIZE = 35;

const WEBSTORE_DIMENSION = {
  dimensionKey: 'siteType',
  dimensionValue: 'webStore',
};

const PROPOSAL_DIMENSION = {
  dimensionKey: 'siteType',
  dimensionValue: 'proposal',
};

export default class GoogleAnalytics4EventHandler {
  /**
   * Private fields
   */
  #configStore;
  #currency;
  #precision;
  #activeCountry;
  #viewItemListDataQueue;
  #requestViewItemListPushToDataLayer;
  #item;
  #promotion;

  constructor(
    accountStore,
    campaignCodeStore,
    configStore,
    currencyStore,
    sectionStore,
    countryStore
  ) {
    this.#configStore = configStore;
    this.#currency = currencyStore.currencyCode;
    this.#precision = 4;
    this.#activeCountry = countryStore.activeCountry;

    this.#item = new Item(
      accountStore,
      campaignCodeStore,
      configStore,
      currencyStore,
      sectionStore
    );

    this.#promotion = new Promotion(this.#item, currencyStore);

    // We use our own impressionDataQueue & promoViewDataQueue to hold the data in order to send it in batches
    this.#viewItemListDataQueue = [];

    // We don't want to spam Google Analytics, so we will always wait for 100ms for new data before sending impressions
    // and promoViews data out. If we have a constant flow of data, we will send data every 500 ms (maxWait).
    const wait = 100; // ms
    const maxWait = 500; // ms
    this.#requestViewItemListPushToDataLayer = debounce(
      this.#pushViewItemListToDataLayer,
      wait,
      {
        maxWait,
      }
    );
  }

  handleAnalyticsEvents = (event) => {
    /**
     * Official Google events should be at the top
     * Custom events are located after viewPromotion
     */
    switch (event.detail.name) {
      case GoogleAnalytics4EventNames.addToCart:
        this.#addToCart(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.addToProposal:
        this.#addToProposal(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.addToWishlist:
        this.#addToWishlist(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.beginCheckout:
        this.#beginCheckout(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.beginProposal:
        this.#beginProposal(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.generateLead:
        this.#generateLead(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.joinGroup:
        this.#joinGroup(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.login:
        this.#login(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.proposalSent:
        this.#proposalSent(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.purchase:
        this.#purchase(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.removeFromCart:
        this.#removeFromCart(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.removeFromProposal:
        this.#removeFromProposal(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.search:
        this.#search(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.selectItem:
        this.#selectItem(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.selectPromotion:
        this.#selectPromotion(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.share:
        this.#share(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.signup:
        this.#signup(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.viewCart:
        this.#viewCart(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.viewProposal:
        this.#viewProposal(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.viewItem:
        this.#viewItem(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.viewItemList:
        this.#viewItemList(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.viewPromotion:
        this.#viewPromotion(event.detail.payload);
        break;
      case CustomEventNames.sendGdprData:
        this.#sendGdprData(event.detail.payload);
        break;
      case CustomEventNames.updateDimension:
        this.#updateDimension(event.detail.payload);
        break;
      case CustomEventNames.pageNotFound:
        this.#sendPageNotFoundError(event.detail.payload);
        break;
      default:
    }
  };

  /**
   * @param {Object} cart
   * @param {string} cart.currencyCode
   * @param {number} cart.value
   * @param {Array.<AnalyticsItem>} cart.productList
   */
  #addToCart = ({ currencyCode, value, productList }) => {
    const data = {
      ecommerce: {
        currency: currencyCode,
        value,
        items: productList.map((item) => this.#item.analyticsItemToItem(item)),
      },
    };

    this.#pushEventToDataLayer(
      data,
      GoogleAnalytics4EventNames.addToCart,
      this.#updateSiteTypeDimension(WEBSTORE_DIMENSION)
    );
  };

  /**
   * @param {Object} cart
   * @param {string} cart.currencyCode
   * @param {number} cart.value
   * @param {Array.<AnalyticsItem>} cart.productList
   */
  #addToProposal = ({ currencyCode, value, productList }) => {
    const data = {
      ecommerce: {
        currency: currencyCode,
        value,
        items: productList.map((item) => this.#item.analyticsItemToItem(item)),
      },
    };

    this.#pushEventToDataLayer(
      data,
      GoogleAnalytics4EventNames.addToCart,
      this.#updateSiteTypeDimension(PROPOSAL_DIMENSION)
    );
  };

  /**
   * @param {Object} cart
   * @param {string} cart.currencyCode
   * @param {number} cart.value
   * @param {Array.<AnalyticsItem>} cart.productList
   */
  #addToWishlist = ({ currencyCode, value, productList }) => {
    const data = {
      ecommerce: {
        currency: currencyCode,
        value,
        items: productList.map((item) => this.#item.analyticsItemToItem(item)),
      },
    };
    this.#pushEventToDataLayer(data, GoogleAnalytics4EventNames.addToWishlist);
  };

  /**
   * @param {Object} checkout
   * @param {string} checkout.currencyCode
   * @param {Array.<AnalyticsItem>} checkout.productList
   * @param {number} checkout.value
   * @param {string} checkout.coupon
   */
  #beginCheckout = ({ currencyCode, productList, value, coupon }) => {
    const data = {
      ecommerce: {
        currency: currencyCode,
        value,
        items: productList.map((item) =>
          this.#item.analyticsItemToItem(this.createCartItemObject(item), true)
        ),
      },
    };

    if (coupon) {
      data.ecommerce.coupon = coupon;
    }

    this.#pushEventToDataLayer(
      data,
      GoogleAnalytics4EventNames.beginCheckout,
      this.#updateSiteTypeDimension(WEBSTORE_DIMENSION)
    );
  };

  /**
   * @param {Object} checkout
   * @param {string} checkout.currencyCode
   * @param {Array.<AnalyticsItem>} checkout.productList
   * @param {number} checkout.value
   */
  #beginProposal = ({ currencyCode, productList, value }) => {
    const data = {
      ecommerce: {
        currency: currencyCode,
        value,
        items: productList.map((item) =>
          this.#item.analyticsItemToItem(this.createCartItemObject(item))
        ),
      },
    };

    this.#pushEventToDataLayer(
      data,
      GoogleAnalytics4EventNames.beginCheckout,
      this.#updateSiteTypeDimension(PROPOSAL_DIMENSION)
    );
  };

  /**
   * @param {Object} generateLead
   * @param {string} generateLead.currencyCode
   * @param {number} generateLead.value
   */
  #generateLead = ({ currencyCode, value }) => {
    const data = {
      ecommerce: {
        currency: currencyCode,
        value: Number(value.toFixed(this.#precision)),
      },
    };

    this.#pushEventToDataLayer(data, GoogleAnalytics4EventNames.generateLead);
  };

  /**
   * @param {Object} joinGroup
   * @param {string} joinGroup.groupId The ID of the group
   */
  #joinGroup = ({ groupId }) => {
    const data = {
      group_id: groupId,
    };

    this.#pushEventToDataLayer(data, GoogleAnalytics4EventNames.joinGroup);
  };

  /**
   * @param {Object} login
   * @param {string} login.method The method used to login.
   */
  #login = ({ method }) => {
    const data = {
      method,
    };

    this.#pushEventToDataLayer(data, GoogleAnalytics4EventNames.login);
  };

  /**
   * @param {Object} purchase
   * @param {CurrentOrder} purchase.currentOrder Current order model
   */
  #purchase = ({ currentOrder }) => {
    this.#pushEventToDataLayer(
      this.getSuccessData(currentOrder),
      GoogleAnalytics4EventNames.purchase,
      this.#updateSiteTypeDimension(WEBSTORE_DIMENSION)
    );
  };

  /**
   * @param {Object} proposalSent
   * @param {CurrentOrder} proposalSent.currentOrder Current order model
   */
  #proposalSent = ({ currentOrder }) => {
    this.#pushEventToDataLayer(
      this.getSuccessData(currentOrder),
      GoogleAnalytics4EventNames.purchase,
      this.#updateSiteTypeDimension(PROPOSAL_DIMENSION)
    );
  };

  /**
   * @param {Object} removeFromCart
   * @param {string} removeFromCart.currencyCode
   * @param {Array.<AnalyticsItem>} removeFromCart.productList
   * @param {number} value a Monetary value of the event
   */
  #removeFromCart = ({ currencyCode, productList, value }) => {
    const data = {
      ecommerce: {
        currency: currencyCode,
        value,
        items: productList.map((item) =>
          this.#item.analyticsItemToItem(this.createCartItemObject(item), true)
        ),
      },
    };
    this.#pushEventToDataLayer(
      data,
      GoogleAnalytics4EventNames.removeFromCart,
      this.#updateSiteTypeDimension(WEBSTORE_DIMENSION)
    );
  };

  /**
   * @param {Object} removeFromCart
   * @param {string} removeFromCart.currencyCode
   * @param {Array.<CartProduct>} removeFromCart.productList
   */
  #removeFromProposal = ({ currencyCode, value, productList }) => {
    const data = {
      ecommerce: {
        currency: currencyCode,
        value,
        items: productList.map((item) =>
          this.#item.analyticsItemToItem(this.createCartItemObject(item))
        ),
      },
    };
    this.#pushEventToDataLayer(
      data,
      GoogleAnalytics4EventNames.removeFromCart,
      this.#updateSiteTypeDimension(PROPOSAL_DIMENSION)
    );
  };

  /**
   * @param {Object} searchTerm
   * @param {string} searchTerm.searchTerm
   */
  #search = ({ searchTerm }) => {
    const data = {
      search_term: searchTerm,
    };

    this.#pushEventToDataLayer(data, GoogleAnalytics4EventNames.search);
  };

  /**
   * @param {Object} gdprInfo
   * @param {Object.<string>} first_name
   * @param {Object.<string>} last_name
   * @param {Object.<string>} email
   * @param {Object.<string>} street_address
   * @param {Object.<string>} international_number
   * @param {Object.<boolean>} marketing_ban
   */
  #sendGdprData = ({
    first_name,
    last_name,
    email,
    international_number,
    street_address,
    marketing_ban,
  }) => {
    const data = {
      gdpr_data: {
        first_name,
        last_name,
        email,
        international_number,
        street_address,
        marketing_ban,
      },
    };

    this.#pushEventToDataLayer(data, CustomEventNames.sendGdprData);
  };

  /**
   * @param {Object} selectItem
   * @param {string} selectItem.listName
   * @param {Array.<AnalyticsItem>} selectItem.productList
   * @param {string} selectItem.listId
   */
  #selectItem = ({ listName, productList, listId }) => {
    const data = {
      ecommerce: {
        item_list_id: listId,
        item_list_name: listName,
        items: productList.map((item) => this.#item.analyticsItemToItem(item)),
      },
    };

    this.#pushEventToDataLayer(data, GoogleAnalytics4EventNames.selectItem);
  };

  /**
   * @param {Object} promotion
   * @param {Array.<AnalyticsItem>} promotion.bannerList An array with objects in the format { banner, bannerZone }.
   */
  #selectPromotion = ({ bannerList }) => {
    this.#promotion.sendSelectPromotionEvent(bannerList);
  };

  /**
   * @param {Object} content
   * @param {string} content.method
   * @param {string} content.contentType
   * @param {string} content.productId
   */
  #share = ({ content }) => {
    const data = {
      ...content,
    };

    this.#pushEventToDataLayer(data, GoogleAnalytics4EventNames.share);
  };

  /**
   * @param {Object} signup
   * @param {string} signup.method Method use to signup
   */
  #signup = ({ method }) => {
    const data = {
      method,
    };

    this.#pushEventToDataLayer(data, GoogleAnalytics4EventNames.signup);
  };

  /**
   * @param {Object} viewCart
   * @param {Array.<AnalyticsItem>} viewCart.productList
   * @param {number} viewCart.value
   */
  #viewCart = ({ productList, value }) => {
    const data = {
      ecommerce: {
        currency: this.#currency,
        value,
        items: productList.map((item) =>
          this.#item.analyticsItemToItem(this.createCartItemObject(item), true)
        ),
      },
    };
    this.#pushEventToDataLayer(
      data,
      GoogleAnalytics4EventNames.viewCart,
      this.#updateSiteTypeDimension(WEBSTORE_DIMENSION)
    );
  };

  /**
   * @param {Object} viewItem
   * @param {string} viewItem.currencyCode
   * @param {number} viewItem.value
   * @param {Array.<AnalyticsItem>} viewItem.productList
   */
  #viewItem = ({ currencyCode, value, productList }) => {
    const data = {
      ecommerce: {
        currency: currencyCode,
        value,
        items: productList.map((item) => this.#item.analyticsItemToItem(item)),
      },
    };
    this.#pushEventToDataLayer(data, GoogleAnalytics4EventNames.viewItem);
  };

  /**
   * @param {Object} viewItemList
   * @param {string} viewItemList.currencyCode
   * @param {Array.<AnalyticsItem>} viewItemList.productList
   * @param {string} viewItemList.listName
   * @param {string} viewItemList.listId
   */
  #viewItemList = ({ currencyCode, productList, listName, listId }) => {
    const items = productList.map((item) => {
      item.itemListName = listName;
      item.itemListId = listId;
      return this.#item.analyticsItemToItem(item);
    });

    this.#pushToViewItemListDataQueue(items, currencyCode);
  };

  /**
   * @param {Object} promotion
   * @param {Ad[]} promotion.bannerList
   */
  #viewPromotion = ({ bannerList }) => {
    this.#promotion.sendViewPromotionEvent(bannerList);
  };

  /**
   * @param {Object} viewProposal
   * @param {Array.<AnalyticsItem>} viewProposal.productList
   * @param {number} viewProposal.value
   */
  #viewProposal = ({ productList, value }) => {
    const data = {
      ecommerce: {
        currency: this.#currency,
        value,
        items: productList.map((item) =>
          this.#item.analyticsItemToItem(this.createCartItemObject(item))
        ),
      },
    };
    this.#pushEventToDataLayer(
      data,
      GoogleAnalytics4EventNames.viewCart,
      this.#updateSiteTypeDimension(PROPOSAL_DIMENSION)
    );
  };

  /**
   * @param {CartProduct} cartProduct
   * @returns
   */
  createCartItemObject = (cartProduct) => {
    const id = cartProduct.productId;
    const cartPrice = cartProduct.analytics.price;
    const quantity = cartProduct.analytics.quantity;
    const name = cartProduct.name;
    const discount = cartProduct.savings;
    const canonicalPath = cartProduct.canonicalPath;
    const variations = cartProduct.variations;

    let product = {
      id,
      name,
      discount,
      canonicalPath,
      variations,
      manufacturer: { name: cartProduct.manufacturer },
    };

    product.cartPrice = cartPrice || undefined;

    return { product, quantity, activeProductId: cartProduct.id };
  };

  #sendPageNotFoundError = ({ location, referrer }) => {
    const data = {
      pageNotFound: {
        address: location,
        referrer,
      },
    };
    this.#pushEventToDataLayer(data, CustomEventNames.pageNotFound);
  };

  getSuccessData = (currentOrder) => {
    const purchase = new Purchase(
      this.#item,
      currentOrder,
      this.#activeCountry
    );

    purchase.createPurchaseEvent();

    return {
      ecommerce: {
        ...purchase.getPurchase(),
      },
    };
  };

  /**
   * Impressions come from multiple components, so we queue them instead of sending them right away.
   * @param items
   */
  #pushToViewItemListDataQueue = (items) => {
    this.#viewItemListDataQueue = this.#viewItemListDataQueue.concat(items);
    this.#requestViewItemListPushToDataLayer();
  };

  #pushViewItemListToDataLayer = () => {
    // GA has a maximum payload size of 8KB. If we have too many impressions we need to split them into chunks.
    const chunks = chunk(
      this.#viewItemListDataQueue,
      IMPRESSION_MAX_BATCH_SIZE
    );
    this.#pushChunksToDataLayer(
      chunks,
      GoogleAnalytics4EventNames.viewItemList
    );
    this.#viewItemListDataQueue = [];
  };

  /**
   *
   * @param {Array} chunks
   * @param {string} event
   */
  #pushChunksToDataLayer = (chunks, event) => {
    chunks.forEach((chunk) =>
      this.#pushEventToDataLayer(
        {
          ecommerce: {
            currency: this.#currency,
            items: chunk,
          },
        },
        event
      )
    );
  };

  /**
   * @param {Object} dimension
   * @param {string} dimension.dimensionKey
   * @param {string} dimension.dimensionValue
   */
  #updateSiteTypeDimension = ({ dimensionKey, dimensionValue }) => {
    if (this.#configStore.siteConfig.isHybrid) {
      window.dataLayer.push({ [dimensionKey]: dimensionValue });
    }
  };

  /**
   * @param {Object} dimension
   * @param {string} dimension.dimensionKey
   * @param {string} dimension.dimensionValue
   */
  #updateDimension = ({ dimensionKey, dimensionValue }) => {
    window.dataLayer[0][dimensionKey] = dimensionValue;
  };

  /**
   *
   * @param {Object} data
   * @param {GoogleAnalytics4EventNames} action
   * @param {Function} updateDimensionCallback
   */
  #pushEventToDataLayer = (data, action, updateDimensionCallback) => {
    window.dataLayer.push({
      event: action,
      ...data,
    });
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    updateDimensionCallback && updateDimensionCallback();
  };
}
