import { types } from 'mobx-state-tree';

const LinkType = {
  EXTERNAL_LINK: 'EXTERNAL_LINK',
  INTERNAL_LINK: 'INTERNAL_LINK',
  PAGE_LINK: 'PAGE_LINK',
  MODAL_WINDOW: 'MODAL_WINDOW',
};

export default LinkType;

export const LinkTypeType = types.enumeration(
  'LinkType',
  Object.values(LinkType)
);
