import { types } from 'mobx-state-tree';

const SelectedService = types.model('SelectedService', {
  areaRequired: types.boolean,
  id: types.number,
  isSelected: types.boolean,
  options: types.model({
    radio: types.maybeNull(types.string),
    checkbox: types.maybeNull(types.array(types.string)),
    dropdown: types.maybeNull(types.string),
  }),
  productId: types.maybeNull(types.string),
  text: types.string,
  textMaxLength: types.maybeNull(types.number),
  textRequired: types.boolean,
  validPostalCode: types.boolean,
});

export default SelectedService;
