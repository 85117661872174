import { types } from 'mobx-state-tree';

const LoginStatus = {
  ACCOUNT_DISABLED: 'ACCOUNT_DISABLED',
  SHOP_LOGIN_DISABLED: 'SHOP_LOGIN_DISABLED',
  SUCCESS: 'SUCCESS',
  TOO_MANY_ATTEMPTS: 'TOO_MANY_ATTEMPTS',
  USER_ALREADY_LOGGED_IN: 'USER_ALREADY_LOGGED_IN',
  USERNAME_OR_PASSWORD_WRONG: 'USERNAME_OR_PASSWORD_WRONG',
};

export default LoginStatus;

export const LoginStatusType = types.enumeration(
  'LoginStatus',
  Object.values(LoginStatus)
);
