import ProductSortOrderBy from './ProductSortOrderBy';
import SortOrder from './SortOrder';
import { invert } from 'lodash';

const ProductTab = {
  RECOMMENDED_PRODUCTS: '1',
  CAMPAIGN_PRODUCTS: '2',
  NEW_PRODUCTS: '3',
  MOST_SOLD_PRODUCTS: '4',
};

export default ProductTab;

export const ProductTabName = invert(ProductTab);

export const ProductTabSearchParams = {};
ProductTabSearchParams[ProductTab.RECOMMENDED_PRODUCTS] = {
  orderBy: ProductSortOrderBy.RECOMMENDED_SORT_ORDER,
  order: SortOrder.ASC,
  includeChildProducts: 1,
  includeFilters: 0,
};
ProductTabSearchParams[ProductTab.CAMPAIGN_PRODUCTS] = {
  orderBy: ProductSortOrderBy.CAMPAIGN_DATE_ADDED,
  order: SortOrder.DESC,
  hasCampaign: 1,
  includeFilters: 0,
};
ProductTabSearchParams[ProductTab.NEW_PRODUCTS] = {
  orderBy: ProductSortOrderBy.DATE_ADDED,
  order: SortOrder.DESC,
  includeFilters: 0,
};
ProductTabSearchParams[ProductTab.MOST_SOLD_PRODUCTS] = {
  orderBy: ProductSortOrderBy.SALES,
  order: SortOrder.DESC,
  includeFilters: 0,
};
