import ProductSortOrderBy from './ProductSortOrderBy';
import SortOrder from './SortOrder';

const createSortSelection = (sortKey, sortOrder, sortIndex) => {
  return {
    sortKey,
    sortOrder,
    sortIndex,
  };
};

const ProductSortSelectorMap = [
  createSortSelection(ProductSortOrderBy.PRICE, SortOrder.ASC, 1),
  createSortSelection(ProductSortOrderBy.PRICE, SortOrder.DESC, 2),
  createSortSelection(ProductSortOrderBy.DATE_ADDED, SortOrder.DESC, 3),
  createSortSelection(ProductSortOrderBy.SALES, SortOrder.DESC, 4),
  createSortSelection(ProductSortOrderBy.REVIEWS_AVERAGE, SortOrder.DESC, 5),
  createSortSelection(ProductSortOrderBy.BRAND, SortOrder.ASC, 6),
  createSortSelection(ProductSortOrderBy.ID, SortOrder.ASC, 7),
  createSortSelection(ProductSortOrderBy.NAME, SortOrder.ASC, 8),
  createSortSelection(ProductSortOrderBy.CATEGORY, SortOrder.ASC, 9),
  createSortSelection(ProductSortOrderBy.DISCOUNT, SortOrder.DESC, 10),
  createSortSelection(ProductSortOrderBy.IN_STOCK_QUANTITY, SortOrder.DESC, 11),
];

export default ProductSortSelectorMap;
