import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import FormField from '../../../models/FormField';

@observer
class Field extends Component {
  handleChange = (event) => {
    this.props.field.setValue(event.target.value);
  };

  handleBlur = () => {
    if (this.props.field.value) {
      this.props.field.setShouldValidate(true);
    }
  };

  render() {
    const {
      component: Component,
      field,
      handleChange,
      value,
      innerRef,
      ...rest
    } = this.props;

    let fieldValue;
    if (
      ['radio', 'checkbox'].indexOf(rest.type) === -1 &&
      field.value !== undefined &&
      field.value !== null
    ) {
      fieldValue = field.value;
    } else if (value) {
      fieldValue = value;
    } else {
      fieldValue = '';
    }

    return (
      <Component
        valid={field.valid}
        invalid={field.errors.length > 0}
        value={fieldValue}
        onChange={handleChange || this.handleChange}
        onBlur={this.handleBlur}
        {...rest}
      />
    );
  }
}

Field.propTypes = {
  field: modelOf(FormField).isRequired,
  component: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
};

export default Field;
