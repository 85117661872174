import { types } from 'mobx-state-tree';
import ProductTypeClass, { ProductType } from '../types/ProductTypeClass';

const RecurringOrderProduct = types
  .model('RecurringOrderProduct', {
    id: types.identifier,
    product_id: types.string,
    name: types.string,
    quantity_ordered: types.number,
    quantity_delivered: types.number,
    package_size: types.maybeNull(types.number),
    stock_unit: types.string,
    starting_unit_price: types.maybeNull(types.string),
    final_unit_price: types.string,
    total_price: types.string,
    type: ProductType,
    reference: types.maybeNull(types.string),
    warranty_in_months: types.maybeNull(types.number),
    package_info: types.maybeNull(types.string),
    is_free_of_charge: types.boolean,
    variations: types.array(types.string),
    image_url: types.maybeNull(types.string),
    product_url: types.maybeNull(types.string),
    tax_rate: types.maybeNull(types.string),
    recurring_order_cycle_type: types.maybeNull(types.string),
    recurring_order_custom_cycle: types.maybeNull(types.number)
  })
  .views((self) => ({
    get isPhysicalProduct() {
      return self.type === ProductTypeClass.PHYSICAL;
    },
    get sellInPackage() {
      return (
        self.package_size && self.package_size > 0 && self.package_size !== 1
      );
    },
    getQuantityStep: (product) => {
      return product && self.sellInPackage ? self.package_size : 1;
    }
  }));

export default RecurringOrderProduct;
