import React, { Component, Fragment } from 'react';
import { Button, ButtonGroup, Col, Form, Input, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import globalTranslations from '../../../i18n/globalTranslations';
import Icon from '../../common/Icon';
import ProductPdfType from '../../../types/ProductPdfType';
import PriceFormat from '../../../types/PriceFormat';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';

const productCountPerPage = [2, 4, 6, 8];

@observer
export class ProductPdfForm extends Component {
  constructor(props) {
    super(props);
    const { accountStore } = this.props;

    let initEmail = '';

    if (accountStore.account && accountStore.account.personal_info) {
      initEmail = accountStore.account.personal_info.email;
    }

    this.state = {
      inclTax: '1',
      perPage: '2',
      priceFormat: PriceFormat.GROSS,
      email: initEmail,
    };
  }

  handleSubmit = (e) => {
    const { handleSubmit, requireEmail } = this.props;
    const { inclTax, perPage, priceFormat, email } = this.state;

    const pdfParams = { inclTax, perPage, priceFormat };

    e.preventDefault();
    requireEmail
      ? handleSubmit({ ...pdfParams, email })
      : handleSubmit(pdfParams);
  };

  renderForm = () => {
    const { loading, requireEmail } = this.props;

    return (
      <Fragment>
        <Form
          className="ProductPdfForm__form"
          noValidate
          onSubmit={this.handleSubmit}
        >
          {this.renderProductPerPageOptions()}
          {this.renderProductPriceFormatOptions()}
          {this.renderProductPriceTextOptions()}
          {requireEmail && this.renderEmailInput()}
          <Row>
            <Col sm={12}>
              <Button
                className="ProductPdfForm__title-button"
                color="primary"
                block
                disabled={loading}
              >
                {loading ? (
                  this.getProductButtonText()
                ) : (
                  <FormattedMessage {...globalTranslations.productPdfTitle} />
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </Fragment>
    );
  };

  getProductButtonText = () => {
    const { loading } = this.props;

    return (
      <div className="ProductPdfForm__create-pdf-button">
        <FormattedMessage
          id="productPdfTitle.creatingPdfTitle"
          defaultMessage="File is being created. Please wait..."
        />
        <Icon name="circle-o-notch" spin={loading} />
      </div>
    );
  };

  getPerPageButtons = () => {
    const { perPage } = this.state;

    return productCountPerPage.map((count) => {
      return (
        <Button
          key={'perPageButton' + count}
          outline={true}
          color="primary"
          onClick={() => this.setState({ perPage: count.toString() })}
          active={perPage === count.toString()}
        >
          <FormattedMessage
            {...globalTranslations.pdfPerPage}
            values={{
              products: count.toString(),
            }}
          />
        </Button>
      );
    });
  };

  renderProductPerPageOptions = () => {
    const { pdfType } = this.props;

    return (
      pdfType !== ProductPdfType.PRODUCT && (
        <div className="ProductPdfForm__group">
          <div className="ProductPdfForm__label">
            <FormattedMessage
              id="productPdfTitle.productsPerPage"
              defaultMessage="Number of products/page"
            />
          </div>
          <Row>
            <Col xs={12}>
              <ButtonGroup>{this.getPerPageButtons()}</ButtonGroup>
            </Col>
          </Row>
        </div>
      )
    );
  };

  renderProductPriceFormatOptions = () => {
    const { accountStore } = this.props;
    const { inclTax } = this.state;

    if (!accountStore.showPrices) {
      return null;
    }

    return (
      <div className="ProductPdfForm__group">
        <div className="ProductPdfForm__label">
          <FormattedMessage
            id="productPdfTitle.priceFormat"
            defaultMessage="Price"
          />
        </div>
        <Row>
          <Col xs={12}>
            <ButtonGroup>
              <Button
                outline={true}
                color="primary"
                onClick={() => this.setState({ inclTax: '1' })}
                active={inclTax === '1'}
              >
                <FormattedMessage
                  id="productPdfTitle.includeTax"
                  defaultMessage="Incl. Tax"
                />
              </Button>
              <Button
                outline={true}
                color="primary"
                onClick={() => this.setState({ inclTax: '0' })}
                active={inclTax === '0'}
              >
                <FormattedMessage
                  id="productPdfTitle.excludeTax"
                  defaultMessage="Excl. Tax"
                />
              </Button>
              <Button
                outline={true}
                color="primary"
                onClick={() =>
                  this.setState({
                    inclTax: '2',
                    priceFormat: PriceFormat.NONE,
                  })
                }
                active={inclTax === '2'}
              >
                <FormattedMessage
                  id="productPdfTitle.emptyPrice"
                  defaultMessage="Empty"
                />
              </Button>
              <Button
                outline={true}
                color="primary"
                onClick={() =>
                  this.setState({
                    inclTax: '3',
                    priceFormat: PriceFormat.NONE,
                  })
                }
                active={inclTax === '3'}
              >
                <FormattedMessage
                  id="productPdfTitle.noPrice"
                  defaultMessage="No price"
                />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </div>
    );
  };

  renderProductPriceTextOptions = () => {
    const { accountStore } = this.props;
    const { inclTax, priceFormat } = this.state;

    if (!accountStore.showPrices) {
      return null;
    }

    const disabled = inclTax === '2' || inclTax === '3';
    return (
      <div className="ProductPdfForm__group">
        <div className="ProductPdfForm__label">
          <FormattedMessage
            id="productPdfTitle.priceText"
            defaultMessage="Price calculation model"
          />
        </div>
        <Row>
          <Col xs={12}>
            <ButtonGroup>
              <Button
                disabled={disabled}
                outline={true}
                color="primary"
                onClick={() =>
                  this.setState({ priceFormat: PriceFormat.GROSS })
                }
                active={priceFormat === PriceFormat.GROSS}
              >
                <FormattedMessage
                  id="productPdfOptions.priceFormatGross"
                  defaultMessage="Gross"
                />
              </Button>
              <Button
                disabled={disabled}
                outline={true}
                color="primary"
                onClick={() => this.setState({ priceFormat: PriceFormat.NET })}
                active={priceFormat === PriceFormat.NET}
              >
                <FormattedMessage
                  id="productPdfOptions.priceFormatNet"
                  defaultMessage="Net"
                />
              </Button>
              <Button
                disabled={disabled}
                outline={true}
                color="primary"
                onClick={() => this.setState({ priceFormat: PriceFormat.NONE })}
                active={priceFormat === PriceFormat.NONE}
              >
                <FormattedMessage
                  id="productPdfOptions.priceFormatNone"
                  defaultMessage="Net (without text)"
                />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </div>
    );
  };

  setEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  renderEmailInput = () => {
    const { email } = this.state;

    return (
      <div className="ProductPdfForm__group">
        <div className="ProductPdfForm__label">
          <FormattedMessage
            id="productPdf.emailForPdfSentence"
            defaultMessage="Enter an email where the file will be sent"
          />
        </div>
        <Row>
          <Col>
            <Input value={email} onChange={this.setEmail} />
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return <div className="ProductPdfForm">{this.renderForm()}</div>;
  }
}

ProductPdfForm.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  activeId: PropTypes.string.isRequired,
  pdfType: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  requireEmail: PropTypes.bool.isRequired,
};

export default inject('accountStore')(ProductPdfForm);
