import SectionStatusType from '../types/SectionStatusType';

class RouteService {
  constructor(sectionStore, languageStore, configStore, categoryStore) {
    this.sectionStore = sectionStore;
    this.languageStore = languageStore;
    this.configStore = configStore;
    this.categoryStore = categoryStore;
  }

  findCanonicalSectionForCategory(category) {
    if (category) {
      // Return the first public section that includes this category
      return this.sectionStore.sections.find(
        (section) =>
          section.includesCategory(category) &&
          section.status === SectionStatusType.ACTIVE
      );
    }
    return null;
  }

  findCanonicalSection(sectionIds) {
    // Get the first section ID that has a matching section
    // In a multi-domain store there might be section IDs that do not match.
    const defaultSectionId = sectionIds.find((sectionId) =>
      this.sectionStore.findSectionById(sectionId)
    );
    return (
      defaultSectionId && this.sectionStore.findSectionById(defaultSectionId)
    );
  }

  transformBreadCrumbs(breadcrumbs) {
    let transformedBreadcrumbs = [];

    if (this.sectionStore.activeSection) {
      transformedBreadcrumbs.push({
        text: this.sectionStore.activeSection.display_name,
        url: this.getPath(this.sectionStore.activeSection.path)
      });
    }

    return transformedBreadcrumbs.concat(
      breadcrumbs.map((breadcrumb) => {
        const url =
          breadcrumb.url &&
          this.getPath(breadcrumb.url, this.sectionStore.activeSection);
        return {
          ...breadcrumb,
          url
        };
      })
    );
  }

  getCategoryPath(category, path) {
    let section;
    if (
      this.sectionStore.activeSection &&
      this.sectionStore.activeSection.includesCategory(category)
    ) {
      section = this.sectionStore.activeSection;
    } else {
      section = this.findCanonicalSectionForCategory(category);
    }
    return this.getPath(path || category.path, section);
  }

  getCanonicalCategoryPath(category, path) {
    let section = this.findCanonicalSectionForCategory(category);

    return this.getPath(path || category.path, section);
  }

  getCanonicalProductPath(product) {
    const hasCategory = !!product.canonicalCategoryId;
    let canonicalSection = null;

    if (hasCategory) {
      const category = this.categoryStore.findCategoryById(
        product.canonicalCategoryId
      );
      canonicalSection = this.findCanonicalSectionForCategory(category);
    }

    return this.getPath(product.path, canonicalSection);
  }

  getProductPath(product, path) {
    let section;
    if (
      this.sectionStore.activeSection &&
      product.belongsToSection(this.sectionStore.activeSection.id)
    ) {
      section = this.sectionStore.activeSection;
    } else {
      section = this.findCanonicalSection(product.section_ids);
    }
    return this.getPath(path || product.path, section);
  }

  getPath(path, section, locale) {
    let languagePrefix = '';
    if (this.languageStore.languages.length > 1) {
      const routeLocale = locale || this.languageStore.activeLocale;
      languagePrefix = '/' + routeLocale;
    }

    let sectionPrefix = '';
    if (section && this.configStore.activateSections) {
      sectionPrefix = '/' + section.slug;
    }

    return languagePrefix + sectionPrefix + path;
  }
}

export default RouteService;
