import { types, flow, getEnv } from 'mobx-state-tree';

import StatefulStore from '../models/StatefulStore';
import Survey from '../models/Survey';
import SurveyType from '../types/SurveyType';
import { storageKeys } from '../util/constants';
import { getStorageAndParse, stringifyAndSetStorage } from '../util/storage';
import cookies from 'js-cookie';

const SurveyStore = StatefulStore.named('SurveyStore')
  .props({
    surveys: types.array(Survey),
    activeSurvey: types.maybeNull(types.reference(Survey)),
    modalOpen: types.optional(types.boolean, true),
    userFields: types.frozen(),
    surveySubmitting: types.optional(types.boolean, false)
  })
  .views((self) => ({
    modalShouldOpen() {
      return self.activeSurvey && self.modalOpen;
    },
    getUserField(key) {
      if (self.userFields) {
        return self.userFields[key];
      }
      return null;
    }
  }))
  .volatile((self) => ({
    timer: types.function
  }))
  .actions((self) => ({
    loadSurveys: flow(function* loadSurveys() {
      self.setLoading(true);

      try {
        const response = yield getEnv(self).apiWrapper.request(`surveys`);
        self.surveys = response.surveys;
        self.userFields = response.userFields;
      } catch (e) {
        self.setError(e);
        throw e;
      }

      self.setLoading(false);
    }),
    activateTimedSurvey() {
      const alreadySeen = getStorageAndParse(storageKeys.surveysSeen);
      const survey = self.surveys.find((survey) => {
        if (alreadySeen && alreadySeen.includes(survey.id)) {
          return false;
        }

        return survey.type === SurveyType.TIMED_POPUP && survey.popup_time > 0;
      });

      if (survey) {
        self.timer = setTimeout(() => {
          self.loadSurvey(survey.id);
        }, survey.popup_time * 1000);
      }
      return survey;
    },
    previewSurvey: flow(function* previewSurvey(id) {
      self.setLoading(true);

      try {
        const survey = yield getEnv(self).apiWrapper.request(
          `surveys/preview/${id}`
        );
        self.surveys.push(survey[0]);
        self.activeSurvey = self.surveys.find(
          (survey) => survey.id === Number(id)
        );
      } catch (e) {
        self.setError(e);
        throw e;
      }

      self.setLoading(false);
      return true;
    }),
    loadSurvey(id) {
      clearTimeout(self.timer);

      const survey = self.surveys.find((survey) => survey.id === Number(id));
      if (survey) {
        self.activeSurvey = survey;
        self.modalOpen = true;
        self.setLoading(false);
        return true;
      }

      self.setLoading(false);
      return false;
    },
    setup(surveys) {
      if (!surveys) {
        return;
      }

      self.surveys = surveys.surveys;
      self.userFields = surveys.userFields;
    },
    toggleModal() {
      self.modalOpen = !self.modalOpen;
      self.setSurveySeen(self.activeSurvey.id);
    },
    submitSurvey: flow(function*(values) {
      if (self.surveySubmitting) {
        return;
      }

      self.setSurveySubmitting();
      try {
        const existingHash = cookies.get('customer_hash');
        const response = yield getEnv(self)
          .apiWrapper.apiAxios()
          .post(`surveys/submit`, {
            values: values,
            surveyId: self.activeSurvey.id
          });

        const data = response.data;
        if (data.survey_hash) {
          if (!existingHash && existingHash !== data.survey_hash) {
            cookies.set('customer_hash', data.survey_hash, { expires: 60 });
          }
        }

        // Set updated user fields from response.
        if (data.values) {
          self.userFields = data.values;
        }

        self.toggleModal();
        self.setSurveyNotSubmitting();
        return data;
      } catch (error) {
        self.setSurveyNotSubmitting();
        throw error;
      }
    }),
    setSurveySeen(id) {
      const alreadySeen = getStorageAndParse(storageKeys.surveysSeen);
      if (!alreadySeen) {
        return stringifyAndSetStorage(storageKeys.surveysSeen, [id]);
      }

      if (!alreadySeen.includes(id)) {
        alreadySeen.push(id);
        stringifyAndSetStorage(storageKeys.surveysSeen, alreadySeen);
      }
    },
    setSurveySubmitting() {
      self.surveySubmitting = true;
    },
    setSurveyNotSubmitting() {
      self.surveySubmitting = false;
    }
  }));

export default SurveyStore;
