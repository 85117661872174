import { Component } from 'react';
import { inject } from 'mobx-react';

import { getDocumentScrollTop } from '../../../util/dom';
import UIStore from '../../../store/UIStore';
import { modelOf } from '../../../prop-types';

export class ScrollTracker extends Component {
  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll, true);
  }

  handleScroll = () => {
    const { uiStore } = this.props;
    uiStore.setDocumentScrollTop(getDocumentScrollTop());
  };

  render() {
    return null;
  }
}

ScrollTracker.propTypes = {
  uiStore: modelOf(UIStore).isRequired
};

export default inject('uiStore')(ScrollTracker);
