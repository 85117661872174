import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import MobileNavigationButton from '../../header/MobileNavigationButton';
import MobileNavigationTab from '../../../types/MobileNavigationTab';
import Icon from '../Icon';
import globalTranslations from '../../../i18n/globalTranslations';

const NavigationAssistButton = ({ intl, tab }) => {
  return (
    <div className="NavigationAssistButton">
      <MobileNavigationButton
        tab={tab}
        ariaLabel={intl.formatMessage(globalTranslations.seeCategoriesNavi)}
      >
        <Icon name="bars" className="NavigationAssistButton__icon" />
        <FormattedMessage {...globalTranslations.seeCategoriesNavi} />
      </MobileNavigationButton>
    </div>
  );
};

NavigationAssistButton.propTypes = {
  intl: intlShape.isRequired,
  tab: PropTypes.oneOf(Object.values(MobileNavigationTab)),
};

export default injectIntl(observer(NavigationAssistButton));
