import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import Icon from '../../common/Icon';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import UIStore from '../../../store/UIStore';

const DesktopNavigationButton = ({ uiStore, intl, withBorder }) => {
  const navigation = uiStore.navigationOffCanvas;
  const toggle = () => {
    navigation.toggle();
  };

  return (
    <Button
      className={classNames('DesktopNavigationButton', {
        'DesktopNavigationButton--with-border': withBorder,
      })}
      color="plain"
      onClick={toggle}
      aria-label={intl.formatMessage(globalTranslations.productsTitle)}
    >
      <div className="DesktopNavigationButton__icon">
        <Icon name="bars" className="fa-2x" />
      </div>
      <div className="DesktopNavigationButton__text">
        <FormattedMessage {...globalTranslations.productsTitle} />
      </div>
    </Button>
  );
};

DesktopNavigationButton.propTypes = {
  uiStore: modelOf(UIStore).isRequired,
  intl: intlShape.isRequired,
  withBorder: PropTypes.bool,
};

DesktopNavigationButton.defaultProps = {
  withBorder: false,
};

export default inject('uiStore')(injectIntl(observer(DesktopNavigationButton)));
