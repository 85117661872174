import { types } from 'mobx-state-tree';

import Price from './Price';

const BundleProductInfo = types.model({
  product_id: types.string,
  price: Price
});

export default BundleProductInfo;
