import { types } from 'mobx-state-tree';

const ProductEnquiryType = {
  MESSAGE: 'MESSAGE',
  PROPOSAL_REQUEST: 'PROPOSAL_REQUEST'
};

export default ProductEnquiryType;

export const ProductEnquiryTypeType = types.enumeration(
  'ProductEnquiryType',
  Object.values(ProductEnquiryType)
);
