import { types } from 'mobx-state-tree';

const TrackingEventType = {
  CHECKOUT: 'CHECKOUT',
  CHECKOUT_SUCCESS: 'CHECKOUT_SUCCESS',
  PRODUCT: 'PRODUCT'
};

export default TrackingEventType;

export const TrackingEventTypeType = types.enumeration(
  'TrackingEventType',
  Object.values(TrackingEventType)
);
