import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import BooleanFilter from '../BooleanFilter';
import TermFilter from '../TermFilter';
import RangeFilter from '../RangeFilter';
import FilterType from '../../../types/FilterType';

const FILTER_COUNT_TO_SHOW_BY_DEFAULT = 9;

@observer
class FilteringOptions extends Component {
  constructor() {
    super();
    this.state = { showAllFilters: false };
  }

  getFilterComponent = (activeFilter, filter, onAcceptFilter) => {
    let component = null;
    const activeFilterArray = [];

    switch (filter.type) {
      case FilterType.SINGLETERM:
      case FilterType.TERM: {
        if (activeFilter && activeFilter.value) {
          Object.values(activeFilter.value).forEach((filterItem) => {
            activeFilterArray.push(filterItem);
          });
        }
        component = (
          <TermFilter
            contentFilter={filter}
            onAccept={onAcceptFilter}
            selectedValues={activeFilter ? activeFilterArray : []}
          />
        );
        break;
      }
      case FilterType.RANGE: {
        component = (
          <RangeFilter
            filter={filter}
            onAccept={onAcceptFilter}
            min={activeFilter ? activeFilter.min : filter.min}
            max={activeFilter ? activeFilter.max : filter.max}
            filterInUse={!!activeFilter}
          />
        );
        break;
      }
      case FilterType.BOOLEAN: {
        component = (
          <BooleanFilter
            onAccept={onAcceptFilter}
            filter={filter}
            isSelected={activeFilter ? activeFilter.value === '1' : false}
          />
        );
        break;
      }
      default: {
        break;
      }
    }
    return <div key={filter.key}>{component}</div>;
  };

  createFilterItem = (key, content) => {
    return (
      <Col
        key={key}
        className={
          `FilteringOptions__option-wrapper ` +
          `FilteringOptions__option-wrapper--${key}`
        }
        xs="12"
        sm="6"
        md="4"
        xl="3"
      >
        {content}
      </Col>
    );
  };

  render() {
    const {
      activeFilters,
      filters,
      onAcceptFilter,
      inGrid,
      toggleShowAll,
      showAllFilters,
    } = this.props;

    const filterItems = filters
      ? filters.map((filter) => {
          const activeFilter = activeFilters
            ? activeFilters.find((af) => af.key === filter.key)
            : [];

          return this.createFilterItem(
            filter.key,
            this.getFilterComponent(activeFilter, filter, onAcceptFilter)
          );
        })
      : [];
    const allElements = showAllFilters
      ? filterItems
      : filterItems.slice(0, FILTER_COUNT_TO_SHOW_BY_DEFAULT);
    if (
      !showAllFilters &&
      filterItems.length > FILTER_COUNT_TO_SHOW_BY_DEFAULT
    ) {
      allElements.push(
        this.createFilterItem(
          'more-link',
          <Button
            className="FilteringOptions__heading"
            onClick={toggleShowAll}
            color="link"
          >
            <FormattedMessage
              id="filter.showMore"
              defaultMessage="Show more filters"
            />
          </Button>
        )
      );
    }
    return (
      <div className="FilteringOptions">
        {inGrid ? (
          <Row>{allElements.map((element) => element)}</Row>
        ) : (
          allElements
        )}
      </div>
    );
  }
}

FilteringOptions.propTypes = {
  activeFilters: PropTypes.array,
  filters: PropTypes.array,
  onAcceptFilter: PropTypes.func.isRequired,
  toggleShowAll: PropTypes.func.isRequired,
  showAllFilters: PropTypes.bool,
  inGrid: PropTypes.bool,
};

export default FilteringOptions;
