import { getParentOfType, types } from 'mobx-state-tree';

import ProductCollectionProduct from './ProductCollectionProduct';
import ProductMatrix from './ProductMatrix';

const ProductCollectionItem = types
  .model('ProductCollectionItem', {
    column_id: types.number,
    row_id: types.number,
    product: ProductCollectionProduct
  })
  .views((self) => {
    return {
      getAvailableAmount() {
        return self.product.free_quantity;
      },
      canBeOrderedOutOfStock() {
        return self.product.can_be_ordered_out_of_stock;
      },
      getInStockQuantity() {
        return self.product.in_stock_quantity;
      },
      getIncomingQuantities() {
        return self.product.incoming_quantities;
      },
      get collection() {
        return getParentOfType(self, ProductMatrix);
      },
      get rowName() {
        if (!self.collection) {
          return '';
        }
        const element = self.collection.row.getElementWithId(self.row_id);
        return element ? element.name : '';
      },
      get columnName() {
        if (!self.collection) {
          return '';
        }
        const element = self.collection.column.getElementWithId(self.column_id);
        return element ? element.name : '';
      },
      get name() {
        return [self.rowName, self.columnName]
          .filter((name) => !!name)
          .join(', ');
      }
    };
  });

export default ProductCollectionItem;
