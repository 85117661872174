import { types } from 'mobx-state-tree';

const SurveyFieldValue = types.model('SurveyFieldValue', {
  key: types.string,
  text: types.string,
  value_id: types.maybeNull(types.number)
});

const SurveyField = types.model('SurveyField', {
  id: types.identifierNumber,
  name: types.string,
  key: types.string,
  is_public: types.boolean,
  type: types.string,
  values: types.array(SurveyFieldValue)
});

export default SurveyField;
