import { types } from 'mobx-state-tree';

const OrderTotalClass = {
  TOTAL: 'TOTAL',
  TAX_FREE_TOTAL: 'TAX_FREE_TOTAL',
  TAX_TOTAL: 'TAX_TOTAL',
  EXTRA_TOTAL: 'EXTRA_TOTAL',
  PRODUCTS_TOTAL: 'PRODUCTS_TOTAL',
};

export default OrderTotalClass;

export const OrderTotalType = types.enumeration(
  'OrderTotalClass',
  Object.values(OrderTotalClass)
);
