import React, { Fragment } from 'react';
import { NavItem, Container, Navbar, Nav } from 'reactstrap';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NavigationEntity from '../../../types/NavigationEntity';
import DropdownNavLink from '../../common/DropdownNavLink';
import NavigationEntityItem from '../../navigation/NavigationEntityItem';
import ConfigStore from '../../../store/ConfigStore';
import SectionStore from '../../../store/SectionStore';
import RouteService from '../../../services/RouteService';
import { modelOf } from '../../../prop-types';
import NavigationDropdownNavItem from '../../header/NavigationDropdownNavItem';
import LinkType from '../../../types/LinkType';
import CustomNavigationLinkLocationType from '../../../types/CustomNavigationLinkLocation';
import CustomNavigationLinkStore from '../../../store/CustomNavigationLinkStore';
import DesktopNavigationButton from '../../header/DesktopNavigationButton';

const NavigationSingleLevelMenuRow = ({
  items,
  configStore,
  sectionStore,
  routeService,
  customNavigationLinkStore,
  className,
}) => {
  const getNavItems = (items) => {
    return items.map((item) => {
      const { id, className, customNavLinks } = item;
      return (
        <Fragment key={id}>
          {customNavLinks &&
            customNavLinks.map(
              (customNavLink, index) =>
                customNavLink.location ===
                  CustomNavigationLinkLocationType.ABOVE &&
                customNavLink.parent_sibling_id === item.id &&
                getCustomNavItems(customNavLink, index)
            )}
          <NavItem
            className={classNames(
              'NavigationSingleLevelMenuRow__item',
              className
            )}
          >
            {getNavigationElement(item)}
          </NavItem>
          {customNavLinks &&
            customNavLinks.map(
              (customNavLink, index) =>
                customNavLink.location ===
                  CustomNavigationLinkLocationType.BELOW &&
                customNavLink.parent_sibling_id === item.id &&
                getCustomNavItems(customNavLink, index)
            )}
        </Fragment>
      );
    });
  };

  const getCustomNavItems = (customNavLink, index) => {
    const linkClassName = 'NavigationSingleLevelMenuRow__link';
    const subLinks = customNavigationLinkStore.findChildLinks(customNavLink.id);
    return (
      <Fragment key={index}>
        {subLinks &&
          subLinks.map(
            (subCustomNavLink, subIndex) =>
              subCustomNavLink.location ===
                CustomNavigationLinkLocationType.ABOVE &&
              getCustomNavItems(subCustomNavLink, subIndex)
          )}
        <NavItem key={index} className="NavigationSingleLevelMenuRow__item">
          <NavigationEntityItem
            entity={convertCustomNavigationLinkToEntityItem(customNavLink)}
            type={customNavLink.link_type}
            className={linkClassName}
          />
        </NavItem>
        {subLinks &&
          subLinks.map(
            (subCustomNavLink, subIndex) =>
              subCustomNavLink.location ===
                CustomNavigationLinkLocationType.BELOW &&
              getCustomNavItems(subCustomNavLink, subIndex)
          )}
      </Fragment>
    );
  };

  const convertCustomNavigationLinkToEntityItem = (customNavLink) => {
    return {
      id: customNavLink.id,
      label: <span>{customNavLink.name}</span>,
      path: getCategoryListPath(customNavLink),
      type: customNavLink.link_type,
    };
  };

  const getCategoryListPath = (customNavLink) => {
    if (customNavLink.link_type === LinkType.EXTERNAL_LINK) {
      return customNavLink.link;
    }

    if (customNavLink.link_type === LinkType.INTERNAL_LINK) {
      return routeService.getPath(customNavLink.link);
    }

    return routeService.getPath(customNavLink.link, sectionStore.activeSection);
  };

  const getNavigationElement = (item) => {
    const { path, active } = item;
    const linkClassName = 'NavigationSingleLevelMenuRow__link';

    if (item.dropdown) {
      return (
        <NavigationDropdownNavItem
          item={item}
          childLinks={item.children}
          className={linkClassName}
          active={active}
        />
      );
    }

    if (path) {
      return <NavigationEntityItem entity={item} className={linkClassName} />;
    }

    return (
      <DropdownNavLink item={item} className={linkClassName} active={active} />
    );
  };

  const ifActiveSections = configStore.activateSections;
  const navItems = getNavItems(items, ifActiveSections);
  return (
    <Navbar
      className={classNames('NavigationSingleLevelMenuRow', className)}
      expand="lg"
    >
      <Container
        className={classNames({
          NavigationSingleLevelMenuRow__container:
            configStore.topNavigation.center,
        })}
      >
        <Nav navbar className="NavigationSingleLevelMenuRow__nav">
          {configStore.navigation.common.enableProductsMenu && (
            <NavItem className="NavigationSingleLevelMenuRow__item">
              <DesktopNavigationButton />
            </NavItem>
          )}
          {navItems}
        </Nav>
      </Container>
    </Navbar>
  );
};

NavigationSingleLevelMenuRow.propTypes = {
  items: PropTypes.arrayOf(NavigationEntity).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  customNavigationLinkStore: modelOf(CustomNavigationLinkStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  className: PropTypes.string,
};

export default inject(
  'configStore',
  'routeService',
  'sectionStore',
  'customNavigationLinkStore'
)(NavigationSingleLevelMenuRow);
