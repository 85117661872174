/**
 * Universal Analytics events
 * After GA4 has been updated to all customer.
 * Almost all of these can removed expect setPage.
 * This event is shared between facebook pixel and criteo.
 * So until either one is removed, it shall remain here.
 */
export const UniversalAnalyticsEventNames = {
  addToCart: 'addToCart',
  analytics: 'analytics',
  checkout: 'checkout',
  checkoutOption: 'checkoutOption',
  productClick: 'productClick',
  productDetail: 'productDetail',
  productImpressions: 'productImpressions',
  promotionClick: 'promotionClick',
  promoView: 'promoView',
  purchase: 'purchase',
  quickSearch: 'quickSearch',
  refund: 'refund',
  removeFromCart: 'removeFromCart',
  reserveAndCollect: 'reserveAndCollect',
  suggestionSelection: 'suggestionSelection',
};

// Google Analytics 4 events
export const GoogleAnalytics4EventNames = {
  addPaymentInfo: 'add_payment_info',
  addShippingInfo: 'add_shipping_info',
  addToCart: 'add_to_cart',
  addToProposal: 'add_to_proposal',
  addToWishlist: 'add_to_wishlist',
  beginCheckout: 'begin_checkout',
  beginProposal: 'begin_proposal',
  generateLead: 'generate_lead',
  joinGroup: 'join_group',
  login: 'login',
  purchase: 'purchase',
  proposalSent: 'proposal_sent',
  refund: 'refund',
  removeFromCart: 'remove_from_cart',
  removeFromProposal: 'remove_from_proposal',
  search: 'search',
  selectItem: 'select_item',
  selectPromotion: 'select_promotion',
  share: 'share',
  signup: 'sign_up',
  viewCart: 'view_cart',
  viewProposal: 'view_proposal',
  viewItem: 'view_item',
  viewItemList: 'view_item_list',
  viewPromotion: 'view_promotion',
};

export const CommonEventNames = {
  setPage: 'setPage',
};

// Nethit custom analytic event
export const CustomEventNames = {
  sendGdprData: 'gdpr_data',
  pageNotFound: 'page_not_found',
  updateDimension: 'update_dimension',
};

// Facebook Pixel integration events
export const FacebookPixelEventNames = {
  addToCart: 'AddToCart',
  addToWishlist: 'AddToWishlist',
  customizeProduct: 'CustomizeProduct',
  initiateCheckout: 'InitiateCheckout',
  purchase: 'Purchase',
  search: 'Search',
  viewContent: 'ViewContent',
  pageView: 'PageView',
};

// Giosg integration events
export const GiosgEventNames = {
  cartStatus: 'cartStatus',
  clearCart: 'clearCart',
};
