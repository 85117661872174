import { types } from 'mobx-state-tree';

import { ShippingSelectionFieldTypeType } from '../types/ShippingSelectionFieldType';
import ShippingSelectionFieldOption from './ShippingSelectionFieldOption';

const ShippingOptionSelectionField = types
  .model('ShippingOptionSelectionField', {
    id: types.identifierNumber,
    text: types.string,
    type: ShippingSelectionFieldTypeType,
    options: types.optional(types.array(ShippingSelectionFieldOption), []),
  })
  .views((self) => ({
    findSelectionFieldOptionById(optionId) {
      return self.options.find((option) => option.id === optionId);
    },
  }));

export default ShippingOptionSelectionField;
