import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ReactAutosuggest from 'react-autosuggest';

@observer
export class Autosuggest extends Component {
  render() {
    return (
      <div className="Autosuggest">
        <ReactAutosuggest {...this.props} />
      </div>
    );
  }
}

export default Autosuggest;
