import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { injectIntl, intlShape } from 'react-intl';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';

import UnexpectedError from '../UnexpectedError';
import RouteService from '../../../services/RouteService';
import Paths from '../../../types/Paths';
import { modelOf } from '../../../prop-types';
import CategoryStore from '../../../store/CategoryStore';

export class ErrorHandler extends Component {
  render() {
    const { error, intl, routeService, categoryStore } = this.props;

    if (!error) {
      return <UnexpectedError />;
    }

    if (error.httpStatusCode === 404) {
      if (error?.fallbackCategoryId && error.fallbackCategoryId > 0) {
        const category = categoryStore.findCategoryById(
          error.fallbackCategoryId
        );

        if (category) {
          return <Redirect to={routeService.getCategoryPath(category)} />;
        }
      }
      return <Redirect to={routeService.getPath(Paths.NotFoundPage)} />;
    }

    if (error.publicMessage) {
      let text;
      if (error.publicMessage.id) {
        text = intl.formatMessage(error.publicMessage);
      } else {
        text = error.publicMessage;
      }

      return <Alert color="danger">{text}</Alert>;
    }

    return <UnexpectedError />;
  }
}

ErrorHandler.propTypes = {
  error: PropTypes.shape({
    httpStatusCode: PropTypes.number,
    publicMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string,
      }),
    ]),
  }),
  intl: intlShape.isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  categoryStore: modelOf(CategoryStore),
};

export default inject(
  'routeService',
  'categoryStore'
)(injectIntl(ErrorHandler));
