import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

import GoogleMapEmbed from '../GoogleMapEmbed';

class GoogleMapModal extends Component {
  render() {
    return (
      <Modal
        className="GoogleMapModal"
        size="lg"
        toggle={this.props.toggle}
        isOpen={this.props.isOpen}
      >
        <ModalHeader
          className="GoogleMapModal__header"
          toggle={this.props.toggle}
        >
          {this.props.title}
        </ModalHeader>
        <ModalBody>
          <GoogleMapEmbed
            location={this.props.location}
            locale={this.props.locale}
          />
        </ModalBody>
      </Modal>
    );
  }
}

GoogleMapModal.propTypes = {
  location: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  title: PropTypes.string,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool
};

export default GoogleMapModal;
