import { isEmail, minLength } from '../util/formValidators';

class FormValidator {
  constructor(configStore) {
    // We only need formValidation from configStore, but we pass in configStore to
    // keep up with possible changes.
    this.configStore = configStore;
  }

  config() {
    return this.configStore.formValidation;
  }

  firstName(label) {
    return [minLength(label, this.config().firstName.minLength)];
  }

  lastName(label) {
    return [minLength(label, this.config().lastName.minLength)];
  }

  fullName(label) {
    return [minLength(label, this.config().firstName.minLength)];
  }

  email(label) {
    return [minLength(label, this.config().email.minLength), isEmail(label)];
  }

  telephone(label) {
    return [minLength(label, this.config().telephone.minLength)];
  }

  streetAddress(label) {
    return [minLength(label, this.config().streetAddress.minLength)];
  }

  postalCode(label) {
    return [minLength(label, this.config().postalCode.minLength)];
  }

  city(label) {
    return [minLength(label, this.config().city.minLength)];
  }

  username(label) {
    return [minLength(label, this.config().username.minLength)];
  }

  password(label) {
    return [minLength(label, this.config().password.minLength)];
  }
}

export default FormValidator;
