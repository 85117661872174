import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';
import PriceWrapper from '../PriceWrapper';
import ProductPagePrice from '../ProductPagePrice';
import Product from '../../../models/Product';

@observer
export class PriceSecondary extends Component {
  render() {
    const {
      accountStore,
      product,
      isProductCard,
      isMultiProductPicker,
      className,
    } = this.props;

    const cssClasses = classNames('PriceSecondary', className, {
      'PriceSecondary--isMultiProductPicker': isMultiProductPicker,
    });

    const secondaryPriceWrapper = (
      <PriceWrapper
        product={product}
        withTax={!accountStore.showPricesWithTax}
        isSecondaryPrice
        isMultiProductPicker={isMultiProductPicker}
      />
    );

    const secondaryProductPagePrice = (
      <ProductPagePrice
        price={secondaryPriceWrapper}
        product={product}
        withTax={!accountStore.showPricesWithTax}
        isSecondaryPrice
      />
    );

    return (
      <div className={cssClasses}>
        {isProductCard ? secondaryPriceWrapper : secondaryProductPagePrice}
      </div>
    );
  }
}

PriceSecondary.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  product: PropTypes.oneOfType([modelOf(Product), PropTypes.object]).isRequired,
  isMultiProductPicker: PropTypes.bool,
  isProductCard: PropTypes.bool,
  className: PropTypes.string,
};

export default inject('accountStore', 'configStore')(PriceSecondary);
