import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ImageMagnifier = ({
  className,
  enlargedImageContainerClassName,
  smallImage,
  largeImage,
}) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  let ref = useRef(null);

  const handleMouseEnter = () => setIsZoomed(true);

  const handleMouseLeave = () => setIsZoomed(false);

  const handleMouseMove = (event) => {
    if (ref) {
      const { left, top, width, height } = ref.getBoundingClientRect();
      const x = ((event.clientX - left) / width) * 100;
      const y = ((event.clientY - top) / height) * 100;

      setMousePosition({ x, y });
    }
  };

  const setRef = (elem) => {
    ref = elem;
  };

  const smallImageStyles = () => {
    return isZoomed ? { opacity: 0 } : { opacity: 1 };
  };

  return (
    <div
      className={classNames('ImageMagnifier', className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      ref={setRef}
    >
      <img
        src={smallImage.src}
        alt={smallImage.alt}
        style={smallImageStyles()}
      />
      {isZoomed && (
        <div
          className={classNames(
            'ImageMagnifier__zoomed-image',
            enlargedImageContainerClassName
          )}
          style={{
            backgroundImage: `url(${largeImage.src})`,
            backgroundPosition: `${mousePosition.x}% ${mousePosition.y}%`,
            maxWidth: `${largeImage.width}px`,
            maxHeight: `${largeImage.height}px`,
            opacity: 1,
          }}
        />
      )}
    </div>
  );
};

ImageMagnifier.propTypes = {
  className: PropTypes.string,
  enlargedImageContainerClassName: PropTypes.string,
  smallImage: PropTypes.object,
  largeImage: PropTypes.object,
};

export default ImageMagnifier;
