import classNames from 'classnames';
import { isNil } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from 'reactstrap';

import globalTranslations from '../../../i18n/globalTranslations';
import QuantityDiscounts from '../../../models/QuantityDiscounts';
import { modelOf } from '../../../prop-types';
import Price from '../Price';

const ProductQuantityDiscounts = ({
  withTax,
  quantityDiscounts,
  stockUnit,
  className,
}) => {
  const discounts = quantityDiscounts.getLevels();

  if (!discounts) {
    return null;
  }

  const getCellValue = (value, center) => {
    return {
      value: value,
      center: center,
    };
  };

  const renderHeaderRow = (headers) => {
    return (
      <thead>
        <tr className="ProductQuantityDiscounts__discount-table-header">
          {headers.map((header, index) => {
            return (
              <th
                className={
                  header.center
                    ? 'ProductQuantityDiscounts__discount-table-header-center'
                    : ''
                }
                key={`${header.value}_${index}`}
              >
                {header.value}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const renderDiscountRow = () => {
    return (
      <tbody>
        {discounts.map((discount, index) => {
          const savings = discount.getSavings(
            quantityDiscounts.highest.getPrice(withTax),
            withTax
          );

          return (
            <tr
              className="ProductQuantityDiscounts__discount-table-row"
              key={index}
            >
              <td className="ProductQuantityDiscounts__discount-table-cell">
                <div className="ProductQuantityDiscounts__discount-quantity">
                  {discount.quantity}
                  {stockUnit && (
                    <span className="ProductQuantityDiscounts__discount-stock-unit">
                      {stockUnit}
                    </span>
                  )}
                </div>
              </td>
              <td className="ProductQuantityDiscounts__discount-table-cell">
                <Price
                  price={discount.price.getPrice(withTax)}
                  discount={discount.quantity !== 1}
                />
                {stockUnit && (
                  <span className="ProductQuantityDiscounts__discount-stock-unit">
                    {'/'} {stockUnit}
                  </span>
                )}
                {savings > 0 && (
                  <span className="ProductQuantityDiscounts__discount-savings">
                    {'-'}
                    <Price price={savings} discount={true} />
                    {stockUnit && (
                      <span className="ProductQuantityDiscounts__discount-stock-unit">
                        {'/'} {stockUnit}
                      </span>
                    )}
                  </span>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  const renderDiscountTable = () => {
    const showTaxExcludedInfo = !withTax;
    const headerRow = [];

    headerRow.push(
      getCellValue(
        <FormattedMessage {...globalTranslations.quantityTitle} />,
        false
      )
    );

    headerRow.push(
      getCellValue(
        <>
          <FormattedMessage {...globalTranslations.unitPriceTitle} />
          {showTaxExcludedInfo && (
            <>
              {' '}
              <FormattedMessage {...globalTranslations.taxExcluded} />
            </>
          )}
        </>,
        false
      )
    );

    return (
      <Table
        size="sm"
        borderless
        className="ProductQuantityDiscounts__discount-table"
      >
        {renderHeaderRow(headerRow)}
        {renderDiscountRow()}
      </Table>
    );
  };

  return (
    <div className={classNames('ProductQuantityDiscounts', className)}>
      <div className="ProductQuantityDiscounts__description">
        <FormattedMessage
          id="discount.quantityDescription"
          defaultMessage="Order more and save money!"
        />
      </div>
      <div className="ProductQuantityDiscounts__discount">
        {renderDiscountTable()}
      </div>
    </div>
  );
};

ProductQuantityDiscounts.propTypes = {
  quantityDiscounts: modelOf(QuantityDiscounts).isRequired,
  withTax: PropTypes.bool.isRequired,
  className: PropTypes.string,
  stockUnit: PropTypes.string,
};

export default inject((stores, props) => ({
  withTax: isNil(props.withTax)
    ? stores.accountStore.showPricesWithTax
    : props.withTax,
}))(observer(ProductQuantityDiscounts));
