import { DateTime } from 'luxon';
import UserData from './UserData';

class ServerEvent {
  #userData;
  #action_source = 'website';

  constructor(accountStore, orderStore, countryStore) {
    this.#userData = new UserData(accountStore, orderStore, countryStore);
  }

  /**
   * @param {object} eventData Event data object.
   * @param {string} eventData.action Event name.
   * @param {object} eventData.customData Event data.
   * @param {string} eventData.eventID Event id.
   */
  getData = ({ action, customData, eventID }) => {
    return {
      event_name: action,
      event_time: Number(DateTime.utc().toSeconds().toFixed(0)),
      user_data: this.#userData.getUserData(),
      custom_data: customData,
      event_source_url: window.location.href,
      event_id: eventID,
      action_source: this.#action_source,
    };
  };
}

export default ServerEvent;
