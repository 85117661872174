import { types } from 'mobx-state-tree';

const LocalStorageKey = {
  SITE_CHOSEN: 'nh_site_chosen'
};

export default LocalStorageKey;

export const LocalStorageKeyType = types.enumeration(
  'LocalStorageKey',
  Object.values(LocalStorageKey)
);
