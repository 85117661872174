import React, { Component, Fragment } from 'react';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import { PropTypes as MobxPropTypes, inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import Category from '../../../models/Category';
import RouteService from '../../../services/RouteService';
import SectionStore from '../../../store/SectionStore';
import CustomNavigationLinkStore from '../../../store/CustomNavigationLinkStore';
import globalTranslations from '../../../i18n/globalTranslations';
import LinkType from '../../../types/LinkType';
import { transformInternalLink } from '../../../util/url';
import MaybeExternalLink from '../../common/MaybeExternalLink';

@observer
export class CategoryGrid extends Component {
  getSubCategoryList = (subCategories) => {
    return (
      <ul className="CategoryGrid__subcategories">
        {subCategories.map((category) => {
          return (
            <li key={category.id} className="CategoryGrid__subcategory-item">
              <Link
                to={this.getCategoryPath(category)}
                className="CategoryGrid__subcategory-link"
              >
                {category.navigationName}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  };

  getCategoryCard = (category) => {
    const { sectionStore } = this.props;

    const children = sectionStore.activeSection
      ? category.getNonBlockedChildren(
          sectionStore.activeSection.blockedCategoriesDict
        )
      : category.children;

    return (
      <Card className="CategoryGrid__card">
        <CardBody>
          {category.image && (
            <img
              className="CategoryGrid__card-image"
              src={category.image}
              alt={category.name}
              title={category.name}
            />
          )}
          <Link
            className="CategoryGrid__card-heading-link"
            to={this.getCategoryPath(category)}
          >
            <CardTitle tag="h4">{category.navigationName}</CardTitle>
          </Link>
          {this.getSubCategoryList(children)}
          <Link to={this.getCategoryPath(category)}>
            <Button color="primary">
              <FormattedMessage
                {...globalTranslations.showAllProductsSentence}
              />
            </Button>
          </Link>
        </CardBody>
      </Card>
    );
  };

  getCustomNavigationLinks = (category) => {
    const { customNavigationLinkStore } = this.props;

    const customNavItems = customNavigationLinkStore.filterByCategoryId(
      category.id
    );

    if (!customNavItems.length) {
      return null;
    }

    return (
      <div>
        {customNavItems.map((customNavItem, i) =>
          this.getCustomNavigationCard(customNavItem, i)
        )}
      </div>
    );
  };

  getCustomNavigationCard = (customNavItem, i) => (
    <Card key={i} className="CategoryGrid__card">
      <CardBody>
        <MaybeExternalLink
          to={this.getCustomCategoryPath(customNavItem)}
          type={customNavItem.link_type}
        >
          <CardTitle tag="h4">{customNavItem.name}</CardTitle>
        </MaybeExternalLink>
      </CardBody>
    </Card>
  );

  getCategoryPath = (category) => {
    const { routeService, sectionStore } = this.props;
    return routeService.getPath(category.path, sectionStore.activeSection);
  };

  getCustomCategoryPath = (category) => {
    const { routeService, sectionStore } = this.props;

    if (category.link_type === LinkType.EXTERNAL_LINK) {
      return category.link;
    }

    if (category.link_type === LinkType.INTERNAL_LINK) {
      return routeService.getPath(transformInternalLink(category.link));
    }

    return routeService.getPath(category.link, sectionStore.activeSection);
  };

  render() {
    const { categories } = this.props;
    return (
      <div className="CategoryGrid">
        {categories.map((category) => (
          <Fragment key={category.id}>
            <div>{this.getCategoryCard(category)}</div>
            {this.getCustomNavigationLinks(category)}
          </Fragment>
        ))}
      </div>
    );
  }
}

CategoryGrid.propTypes = {
  customNavigationLinkStore: modelOf(CustomNavigationLinkStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  categories: PropTypes.oneOfType([
    MobxPropTypes.observableArrayOf(modelOf(Category)).isRequired,
    PropTypes.arrayOf(modelOf(Category)).isRequired
  ])
};

export default inject(
  'routeService',
  'sectionStore',
  'customNavigationLinkStore'
)(CategoryGrid);
