import { types } from 'mobx-state-tree';

const Currency = types.model('Currency', {
  code: types.identifier,
  symbol_left: types.string,
  symbol_right: types.string,
  symbol: types.string,
  decimal_point: types.string,
  thousands_point: types.string,
  decimal_places: types.number,
  precision: types.number
});

export default Currency;
