import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

import ProductSeason from '../../../models/ProductSeason';
import { modelOf } from '../../../prop-types';
import Icon from '../../common/Icon';
import Countdown from '../../common/Countdown';

@observer
class ProductSeasonButton extends Component {
  render() {
    const { season } = this.props;

    return (
      <Button className="ProductSeasonButton" color="primary" disabled>
        <Icon name="shopping-cart" />{' '}
        <FormattedMessage
          id="product.salesBeginAt"
          defaultMessage="Sales begin at {date}"
          values={{
            date:
              season.startDate < DateTime.utc().plus({ days: 1 }) ? (
                <Countdown
                  expiresOn={season.startDate}
                  onExpiry={() => window.location.reload()}
                />
              ) : (
                DateTime.fromISO(season.start_date).toLocaleString(
                  DateTime.DATETIME_SHORT
                )
              )
          }}
        />
      </Button>
    );
  }
}

ProductSeasonButton.propTypes = {
  season: modelOf(ProductSeason)
};

export default ProductSeasonButton;
