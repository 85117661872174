import { types } from 'mobx-state-tree';

const ProductClass = {
  SINGLE: 'SINGLE',
  MULTI: 'MULTI',
  COLLECTION: 'COLLECTION',
  MULTI_CHILD: 'MULTI_CHILD'
};

export default ProductClass;

export const ProductClassType = types.enumeration(
  'ProductClass',
  Object.values(ProductClass)
);
