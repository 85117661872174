import { types } from 'mobx-state-tree';

const ProductLicenceType = {
  NONE: 'NONE',
  ACQUISITION_PERMIT: 'ACQUISITION_PERMIT',
  POSSESSION_PERMIT: 'POSSESSION_PERMIT'
};

export default ProductLicenceType;

export const ProductLicenceTypeType = types.enumeration(
  'ProductLicenceType',
  Object.values(ProductLicenceType)
);
