import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet-async';
import { Button, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import ConfigStore from '../../../store/ConfigStore';
import { defineMessages, FormattedMessage } from 'react-intl';
import Icon from '../../../components/common/Icon';
import RequestState from '../../../types/RequestState';
import KlarnaInstantShoppingModal from './KlarnaInstantShoppingModal';
import InstantShoppingCartStore from '../../../store/InstantShoppingCartStore';
import UnexpectedError from '../../../components/loader/UnexpectedError';
import ProductClass from '../../../types/ProductClass';
import globalTranslations from '../../../i18n/globalTranslations';
import Analytics from '../../../analytics/Analytics';

const messages = defineMessages({
  selectProductButton: {
    id: 'klarnaInstantShopping.selectProduct',
    defaultMessage: 'Select product',
  },
  orderProductsInfo: {
    id: 'klarnaInstantShopping.orderProductsInfo',
    defaultMessage: 'Order these products or add other options',
  },
  emptyInstantShoppingCart: {
    id: 'klarnaInstantShopping.emptyInstantShoppingCart',
    defaultMessage: "You haven't selected any products.",
  },
});
const KLARNA_SCRIPT = 'https://x.klarnacdn.net/instantshopping/lib/v1/lib.js';
const INSTANT_SHOPPING_FLOW_CLOSED = 'instant_shopping_flow_closed';

@observer
export class KlarnaInstantShopping extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shippingDropdownOpen: false,
      pickupDropdownOpen: false,
      selectionFieldDropdownOpen: false,
    };

    props.instantShoppingCartStore.setup(props.merchantId);
    this.klarnaInstantShoppingFlowSubscription = null;
    this.confirmationButtonClickedSubscription = null;
  }

  componentDidMount() {
    this.loadProducts().catch((err) => console.error(err));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeProductId !== this.props.activeProductId) {
      this.loadProducts().catch((err) => console.error(err));
    }
  }

  componentWillUnmount() {
    this.klarnaInstantShoppingFlowSubscription &&
      window.Klarna.InstantShopping.off(INSTANT_SHOPPING_FLOW_CLOSED);
    this.props.instantShoppingCartStore.closeModal('orderModal');
  }

  loadProducts = async () => {
    const { merchantId, product, instantShoppingCartStore, activeProductId } =
      this.props;

    await instantShoppingCartStore.getInstantShoppingCartProducts(
      merchantId,
      product,
      activeProductId
    );
  };

  renderOrderProductsInfoRow = () => (
    <Row className="KlarnaInstantShopping__order-products-info-row">
      <Col sm={12}>
        <FormattedMessage {...messages.orderProductsInfo} />
      </Col>
    </Row>
  );

  renderOrderProductButtonRow = () => {
    const { instantShoppingCartStore } = this.props;

    const ifSelectedProducts =
      instantShoppingCartStore.selectedInstantShoppingProducts.length > 0;

    return (
      <Row className="KlarnaInstantShopping__product-buttons-row">
        <Col xs={6}>{this.renderAddProductButton()}</Col>
        {ifSelectedProducts && (
          <Col xs={6}>{this.renderOrderProductButton()}</Col>
        )}
      </Row>
    );
  };

  renderAddProductButton = () => {
    const { instantShoppingCartStore, disabled, product, activeProductId } =
      this.props;
    const extendedId = activeProductId || product.id;
    const productState =
      instantShoppingCartStore.productCartStates.get(extendedId);
    const loading = productState === RequestState.LOADING;

    return (
      <Button
        disabled={disabled}
        block
        color="primary"
        className="KlarnaInstantShopping__select-product-button"
        onClick={this.addProduct.bind(this, product)}
      >
        {this.setButtonLoadingState(messages.selectProductButton, loading)}
      </Button>
    );
  };

  addProduct = async () => {
    const { product, instantShoppingCartStore, quantity, activeProductId } =
      this.props;

    const merchantId = product.merchantId;

    const instanceShoppingInstanceId =
      instantShoppingCartStore.instanceShoppingInstanceId;

    await instantShoppingCartStore.addToInstantShoppingProductCart(
      product,
      quantity,
      activeProductId
    );

    instanceShoppingInstanceId &&
      (await instantShoppingCartStore.updateOrder(merchantId));
  };

  renderOrderProductButton = () => {
    const { instantShoppingCartStore, product, activeProductId } = this.props;
    const extendedId = activeProductId || product.id;
    const loading = instantShoppingCartStore.state === RequestState.LOADING;
    const ifError = instantShoppingCartStore.state === RequestState.ERROR;

    return (
      <Button
        id={extendedId}
        color="dark"
        disabled={loading || ifError}
        block
        className="KlarnaInstantShopping__klarna-express-checkout-button"
        onClick={this.openOrderProductModal.bind(this)}
      >
        {this.setButtonLoadingState(
          globalTranslations.klarnaExpressCheckout,
          loading
        )}
      </Button>
    );
  };

  sendAnalyticsCheckoutEvent = () => {
    const { configStore, instantShoppingCartStore, analytics } = this.props;

    if (!configStore.gtm.enabled) {
      return;
    }

    const cartProducts =
      instantShoppingCartStore.getAnalyticsProductCartProducts();

    const actionField = { step: 1, option: 'Move To Checkout' };
    analytics.checkout(cartProducts, actionField);
  };

  openOrderProductModal = async () => {
    const { instantShoppingCartStore } = this.props;
    const instanceShoppingInstanceId =
      instantShoppingCartStore.instanceShoppingInstanceId;
    instantShoppingCartStore.resetShippingOptions();
    instanceShoppingInstanceId &&
      instantShoppingCartStore.updateOrder().then(() => {
        instantShoppingCartStore.loadShippingOptions();
        this.sendAnalyticsCheckoutEvent();
      });
    !instanceShoppingInstanceId && this.createOrder();
    instantShoppingCartStore.state !== RequestState.ERROR && this.toggleModal();
  };

  toggleModal = () =>
    this.props.instantShoppingCartStore.toggleModal('orderModal');

  setButtonLoadingState = (formattedMessage, loading) => (
    <Fragment>
      {loading ? (
        <Icon name={'circle-o-notch'} spin={loading} />
      ) : (
        <FormattedMessage {...formattedMessage} />
      )}
    </Fragment>
  );

  renderModal = () => {
    const { product, activeProductId } = this.props;
    const {
      shippingDropdownOpen,
      pickupDropdownOpen,
      selectionFieldDropdownOpen,
    } = this.state;
    const merchantId = product.merchantId;
    const productId = activeProductId || product.id;
    const merchantInfo = product.merchantInfo;

    return (
      <KlarnaInstantShoppingModal
        merchantInfo={merchantInfo}
        extendedId={productId}
        merchantId={merchantId}
        shippingDropdownOpen={shippingDropdownOpen}
        pickupDropdownOpen={pickupDropdownOpen}
        selectionFieldDropdownOpen={selectionFieldDropdownOpen}
        toggleShippingDropdown={this.toggleShippingDropdown}
        togglePickupDropdown={this.togglePickupDropdown}
        toggleSelectionFieldDropdown={this.toggleSelectionFieldDropdown}
        onProductOrder={this.orderProducts}
        onOrderCancel={this.cancelOrderProducts}
      />
    );
  };

  orderProducts = async () => {
    const { instantShoppingCartStore } = this.props;

    instantShoppingCartStore
      .updateOrder()
      .then(() => {
        instantShoppingCartStore.resetPickupPoint();
        this.toggleModal();
        this.loadKlarnaInstantShoppingInstance();
      })
      .catch((err) => {
        console.error(err);
        this.createOrder();
      });
  };

  loadKlarnaInstantShoppingInstance = () => {
    const { instantShoppingCartStore } = this.props;
    const instanceShoppingInstanceId =
      instantShoppingCartStore.instanceShoppingInstanceId;

    instanceShoppingInstanceId &&
      instantShoppingCartStore
        .loadKlarnaInstantShoppingInstance()
        .then(() => {
          this.registerInstantShoppingFlowEventHandler();
          instantShoppingCartStore.openKlarnaInstantShoppingModal();
        })
        .catch((err) => console.error(err));
  };

  registerInstantShoppingFlowEventHandler = () => {
    try {
      if (!this.klarnaInstantShoppingFlowSubscription) {
        window.Klarna.InstantShopping.on(
          INSTANT_SHOPPING_FLOW_CLOSED,
          (data) => {
            this.klarnaInstantShoppingFlowSubscription = data;
            this.props.instantShoppingCartStore.resetPickupPoint();
          }
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  createOrder = () => {
    const { instantShoppingCartStore } = this.props;
    instantShoppingCartStore.hasCart &&
      instantShoppingCartStore.orderProducts().then(() => {
        instantShoppingCartStore.loadShippingOptions();
        this.sendAnalyticsCheckoutEvent();
      });
  };

  cancelOrderProducts = () => this.toggleModal();

  toggleShippingDropdown = () =>
    this.setState({
      shippingDropdownOpen: !this.state.shippingDropdownOpen,
      pickupDropdownOpen: false,
      selectionFieldDropdownOpen: false,
    });

  togglePickupDropdown = () =>
    this.setState({
      pickupDropdownOpen: !this.state.pickupDropdownOpen,
      shippingDropdownOpen: false,
      selectionFieldDropdownOpen: false,
    });

  toggleSelectionFieldDropdown = () =>
    this.setState({
      selectionFieldDropdownOpen: !this.state.selectionFieldDropdownOpen,
      pickupDropdownOpen: false,
      shippingDropdownOpen: false,
    });

  renderSelectedProducts = () => {
    const { instantShoppingCartStore } = this.props;
    const selectedProducts =
      instantShoppingCartStore &&
      instantShoppingCartStore.selectedInstantShoppingProducts;
    const loading = instantShoppingCartStore.state === RequestState.LOADING;

    return (
      <Fragment>
        {selectedProducts.map((product) => (
          <div
            key={uniqueId(product.id)}
            className="KlarnaInstantShopping__product-cart-product"
          >
            <p className="KlarnaInstantShopping__product">
              {product.name} x {product.quantity}
            </p>
            <span className="KlarnaInstantShopping__product-delete">
              <Button
                color="danger"
                disabled={loading}
                onClick={this.removeProduct.bind(this, product.id)}
              >
                <Icon name="trash" />
              </Button>
            </span>
          </div>
        ))}
        {!selectedProducts.length && (
          <div className="KlarnaInstantShopping__product-cart-empty">
            <p className="KlarnaInstantShopping__product-cart-empty-message">
              <FormattedMessage {...messages.emptyInstantShoppingCart} />
            </p>
          </div>
        )}
      </Fragment>
    );
  };

  removeProduct = async (productId) => {
    const { instantShoppingCartStore } = this.props;
    await instantShoppingCartStore.removeInstantShoppingCartProduct(productId);
  };

  render() {
    const { configStore, product, instantShoppingCartStore } = this.props;
    const instanceShoppingInstanceId =
      instantShoppingCartStore.instanceShoppingInstanceId;
    const ifWebStore = configStore.siteConfig.isWebStore;
    const singleProduct = product.class === ProductClass.SINGLE;

    const error = instantShoppingCartStore.state === RequestState.ERROR;

    if (!product && ifWebStore) {
      return null;
    }

    return (
      <div className="KlarnaInstantShopping">
        <Helmet>
          <script async src={KLARNA_SCRIPT} />
        </Helmet>
        {!singleProduct && this.renderOrderProductsInfoRow()}
        {this.renderOrderProductButtonRow()}
        <Row className="KlarnaInstantShopping__product-cart">
          <Col sm={12}>{this.renderSelectedProducts()}</Col>
        </Row>
        {error && (
          <Row className="KlarnaInstantShopping__unexpected-error">
            <Col sm={12}>
              <UnexpectedError />
            </Col>
          </Row>
        )}
        {instanceShoppingInstanceId && this.renderModal()}
      </div>
    );
  }
}

KlarnaInstantShopping.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  instantShoppingCartStore: modelOf(InstantShoppingCartStore).isRequired,
  product: PropTypes.oneOfType([modelOf(Product), PropTypes.object]).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  quantity: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  activeProductId: PropTypes.string,
  merchantId: PropTypes.number,
};
export default inject(
  'analytics',
  'configStore',
  'instantShoppingCartStore'
)(KlarnaInstantShopping);
