import React, { Component } from 'react';
import { Badge } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import Category from '../../../models/Category';
import globalTranslations from '../../../i18n/globalTranslations';

@observer
class CategoryBadges extends Component {
  render() {
    return (
      <span className="CategoryBadges">
        {this.props.category.discount > 0 && (
          <Badge pill className="CategoryBadges__discount-badge">
            –{Math.round(this.props.category.discount)}%
          </Badge>
        )}
        {this.props.category.is_new && (
          <Badge pill className="CategoryBadges__new-badge">
            <FormattedMessage {...globalTranslations.newTitle} />
          </Badge>
        )}
      </span>
    );
  }
}

CategoryBadges.propTypes = {
  category: modelOf(Category).isRequired
};

export default CategoryBadges;
