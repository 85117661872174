import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { parse } from '../../../util/queryString';

export class QueryParamRedirect extends Component {
  maybeRedirect = () => {
    const { location, paramName, paramValidator, to, onRedirect } = this.props;
    const query = parse(location.search);

    if (query[paramName] !== undefined && !!paramValidator(query[paramName])) {
      const target =
        typeof to === 'function' ? to(query[paramName], location) : to;

      onRedirect(target);
      return <Redirect to={target} />;
    }

    return null;
  };

  render() {
    return this.maybeRedirect();
  }
}

QueryParamRedirect.propTypes = {
  location: RouterPropTypes.location.isRequired,
  paramName: PropTypes.string.isRequired,
  paramValidator: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  onRedirect: PropTypes.func
};

QueryParamRedirect.defaultProps = {
  paramValidator: () => true,
  onRedirect: () => {}
};

export default withRouter(QueryParamRedirect);
