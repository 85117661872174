import React, { Component } from 'react';
import {
  Form,
  Input,
  Alert,
  Button,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import Icon from '../../common/Icon';
import globalTranslations from '../../../i18n/globalTranslations';
import { createForm } from '../../../models/Form';
import { createFormField } from '../../../models/FormField';
import { isEmail, notBlank } from '../../../util/formValidators';
import RequestState from '../../../types/RequestState';
import Field from '../../form/Field';
import FieldError from '../../form/FieldError';
import ApiWrapper from '../../../services/ApiWrapper';
import { modelOf } from '../../../prop-types';
import Analytics from '../../../analytics/Analytics';
import ConfigStore from '../../../store/ConfigStore';
import UIStore from '../../../store/UIStore';
import RecaptchaLegalBanner from '../../legal/RecaptchaLegalBanner';
import ErrorHandler from '../../loader/ErrorHandler';
import { createErrorModel } from '../../../util/error';
import { get } from 'lodash';

@observer
class ProductWatcherForm extends Component {
  constructor(props) {
    super(props);

    this.form = null;
    this.formName = 'ProductWatcherForm';
    this.state = {
      error: null,
      status: RequestState.NONE,
      // This is used to know, if we've made a new request so we can re-animate errors
      requestId: 0,
    };

    this.form = createForm({
      email: createFormField({}, [
        notBlank(<FormattedMessage {...globalTranslations.emailTitle} />),
        isEmail(<FormattedMessage {...globalTranslations.emailTitle} />),
      ]),
    });

    this.form.fields.get('email').setValue(this.props.initialEmail);
  }

  handleSubmit = async (e) => {
    const {
      configStore,
      uiStore,
      analytics,
      apiWrapper,
      activeProductId,
      price,
    } = this.props;

    e.preventDefault();
    this.form.validate();

    if (this.form.valid) {
      this.setState({ status: RequestState.LOADING });
      await uiStore.recaptchaValidation.getCaptchaToken();

      if (uiStore.recaptchaValidation.isValid) {
        const values = this.form.getFieldValues();
        values.recaptcha = uiStore.recaptchaValidation.token;

        apiWrapper
          .apiAxios()
          .post(`product-watchers`, {
            id: activeProductId,
            ...values,
          })
          .then(() => {
            this.setState({ status: RequestState.LOADED });
            configStore.analytics.ga4.enabled &&
              price &&
              analytics.generateLead(price);
          })
          .catch((error) => {
            this.setState({
              status: RequestState.ERROR,
              error,
              requestId: this.state.requestId + 1,
            });
          });
      }

      configStore.recaptcha.enabled && uiStore.recaptchaValidation.resetCaptchaToken();
    }
  };

  getErrorMessages = () => {
    const { error } = this.state;
    return get(error, 'response.data.messages');
  };

  render() {
    const { configStore, uiStore, intl } = this.props;
    const { status, error, requestId } = this.state;
    return (
      <div className="ProductWatcherForm">
        {status !== RequestState.LOADED && (
          <>
            {error && get(error, 'response.status') !== 422 && (
              <ErrorHandler error={createErrorModel(error)} />
            )}
            {this.getErrorMessages() && (
              <Alert color="danger" key={requestId}>
                {this.getErrorMessages().map((message) => (
                  <div key={message}>{message}</div>
                ))}
              </Alert>
            )}
            <Form
              className="ProductWatcherForm__form"
              noValidate
              onSubmit={this.handleSubmit}
              name={this.formName}
            >
              <InputGroup>
                <Field
                  component={Input}
                  field={this.form.fields.get('email')}
                  type="text"
                  name="email"
                  placeholder={intl.formatMessage(
                    globalTranslations.yourEmailSentence
                  )}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    color="primary"
                    onClick={this.handleSubmit}
                    disabled={
                      this.form.valid === false ||
                      uiStore.recaptchaValidation.isValid === false ||
                      status === RequestState.LOADING
                    }
                    type="submit"
                    aria-label={intl.formatMessage(
                      globalTranslations.sendTitle
                    )}
                  >
                    <Icon name="envelope" />{' '}
                    <FormattedMessage {...globalTranslations.sendTitle} />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              <FieldError field={this.form.fields.get('email')} />
              {configStore.recaptcha.enabled && (
                <RecaptchaLegalBanner />
              )}
            </Form>
          </>
        )}
        {status === RequestState.LOADED && (
          <Alert color="success">
            <FormattedMessage
              {...globalTranslations.newsletterSuccessSentence}
            />
          </Alert>
        )}
      </div>
    );
  }
}

ProductWatcherForm.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  apiWrapper: PropTypes.instanceOf(ApiWrapper).isRequired,
  intl: intlShape.isRequired,
  price: PropTypes.number,
  activeProductId: PropTypes.string,
  initialEmail: PropTypes.string,
};

ProductWatcherForm.defaultProps = {
  initialEmail: '',
};

export default inject(
  'configStore',
  'uiStore',
  'analytics',
  'apiWrapper'
)(injectIntl(ProductWatcherForm));
