import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { inject, observer } from 'mobx-react';

import { LocationContextPropType } from '../../../services/LocationContext';
import GenericMeta from '../GenericMeta';

@observer
export class SEOLink extends Component {
  render() {
    const { rel, path, addOpenGraphTag, locationContext } = this.props;
    const url = `${locationContext.protocol}//${locationContext.host}${path}`;
    return (
      <Fragment>
        {addOpenGraphTag && (
          <GenericMeta name="twitter:url" property="og:url" content={url} />
        )}
        <Helmet>
          <link rel={rel} href={url} />
        </Helmet>
      </Fragment>
    );
  }
}

SEOLink.propTypes = {
  addOpenGraphTag: PropTypes.bool,
  rel: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  locationContext: LocationContextPropType.isRequired
};

export default inject('locationContext')(SEOLink);
