import { types, getEnv, flow } from 'mobx-state-tree';

import Menu from '../models/Menu';
import StatefulStore from '../models/StatefulStore';
import RequestState, { RequestStateType } from '../types/RequestState';

const MenuStore = StatefulStore.named('MenuStore')
  .props({
    menus: types.optional(types.array(Menu), []),
    checkoutMenus: types.optional(types.array(Menu), []),
    checkoutMenuState: types.optional(RequestStateType, RequestState.NONE),
  })
  .actions((self) => {
    return {
      setup(menus) {
        self.menus = menus;
        self.setLoading(false);
      },
      loadMenus: flow(function* loadMenus() {
        self.setLoading(true);

        try {
          self.menus = yield getEnv(self).apiWrapper.request(`menus`);
        } catch (e) {
          self.setError(e);
          throw e;
        }
        self.setLoading(false);
      }),
      loadCheckoutMenus: flow(function* loadCheckoutMenus() {
        self.checkoutMenuState = RequestState.LOADING;

        try {
          self.checkoutMenus = yield getEnv(self).apiWrapper.request(`menus`, {
            params: {
              position: 'PROPOSAL_REQUEST',
            },
          });
        } catch (e) {
          self.checkoutMenuState = RequestState.ERROR;
          throw e;
        }

        self.checkoutMenuState = RequestState.LOADED;
      }),
    };
  });

export default MenuStore;
