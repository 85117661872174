import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx';

import { modelOf } from '../../../prop-types';
import CouponStore from '../../../store/CouponStore';
import AccountStore from '../../../store/AccountStore';

@observer
export class TimerCoupons extends Component {
  timerDict = {};
  currentActivation = null;

  componentDidMount() {
    const { couponStore } = this.props;
    autorun(() => {
      couponStore.timerCoupons
        .filter((coupon) => !this.timerDict[coupon.id])
        .forEach((coupon) => {
          const delay = Number(coupon.time_to_activation) * 1000;
          this.delayActivation(coupon.id, delay);
        });
    });
  }

  componentWillUnmount() {
    Object.keys(this.timerDict).forEach((key) => {
      const timeoutId = this.timerDict[key];
      clearTimeout(timeoutId);
    });
  }

  activateCoupon = (couponId) => {
    const { accountStore, couponStore } = this.props;

    // Only activate a single coupon at a time
    if (this.currentActivation) {
      this.delayActivation(couponId, 500);
      return;
    }

    this.currentActivation = couponStore
      .activateCoupon(couponId)
      .then(() => {
        accountStore.refreshCoupons();
        this.currentActivation = null;
      })
      .catch((e) => {
        console.error(e);
        this.currentActivation = null;
      });
  };

  delayActivation = (couponId, delay) => {
    this.timerDict[couponId] = window.setTimeout(() => {
      this.activateCoupon(couponId);
    }, delay);
  };

  render() {
    return null;
  }
}

TimerCoupons.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  couponStore: modelOf(CouponStore).isRequired
};

export default inject('accountStore', 'couponStore')(TimerCoupons);
