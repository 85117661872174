import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import QuickSearch from '../../search/QuickSearch';

/**
 * TODO: Refactor this component to be more generic list filter.
 * Component name
 * Component callback functions name
 */

const ProductListFilter = ({
  intl,
  className,
  clearProductListFilter,
  onProductListFiltering,
  onFilteringChange,
  searchTerm,
  hideButtonText,
  overridePlaceholder,
  hideSearchButton,
}) => {
  const messages = defineMessages({
    filterList: {
      id: 'productListFilter.filterList',
      defaultMessage: 'Filter list',
    },
  });

  const placeholder =
    overridePlaceholder || intl.formatMessage(messages.filterList);

  const productListFilterButtonText = (
    <FormattedMessage
      id="productListFilter.filter"
      defaultMessage="Filter by keyword"
    />
  );

  return (
    <div className="ProductListFilter">
      <QuickSearch
        className={className}
        handleFocus={() => null}
        handleBlur={() => null}
        toggleSearch={() => null}
        productListFilter
        onProductListFiltering={onProductListFiltering}
        clearProductListFilter={clearProductListFilter}
        onFilteringChange={onFilteringChange}
        searchTerm={searchTerm}
        placeholder={placeholder}
        buttonText={productListFilterButtonText}
        quickSearchId="ProductListFilter__Autosuggest"
        hideButtonText={hideButtonText}
        hideSearchButton={hideSearchButton}
      />
    </div>
  );
};

ProductListFilter.propTypes = {
  className: PropTypes.string,
  overridePlaceholder: PropTypes.string,
  searchTerm: PropTypes.string,
  hideButtonText: PropTypes.bool,
  hideSearchButton: PropTypes.bool,
  clearProductListFilter: PropTypes.func,
  onProductListFiltering: PropTypes.func,
  onFilteringChange: PropTypes.func,
};

ProductListFilter.defaultProps = {
  hideButtonText: false,
};

export default injectIntl(observer(ProductListFilter));
