import React from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import generatePath from '../../../util/generatePath';
import Paths from '../../../types/Paths';

const ProductOnlyInStore = ({ configStore }) => {
  const getInfoPageLink = () => {
    if (configStore.storeInfoPageId === 0) {
      return null;
    }

    const storeInfoPagePath = generatePath(Paths.InfoPage, {
      slug: '-',
      id: configStore.storeInfoPageId,
    });

    return (
      <div className="ProductOnlyInStore__link">
        <Link to={storeInfoPagePath}>
          <FormattedMessage
            id="product.onlyInStoreLink"
            defaultMessage="Please see contact information"
          />
        </Link>
      </div>
    );
  };

  return (
    <div className="ProductOnlyInStore">
      <FormattedMessage
        id="product.onlyInStore"
        defaultMessage="This product cannot be purchased from the online store. Please contact sales"
      />
      {getInfoPageLink()}
    </div>
  );
};

ProductOnlyInStore.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
};

export default inject('configStore')(observer(ProductOnlyInStore));
