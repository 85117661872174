import {
  types,
  getEnv,
  flow,
  resolveIdentifier,
  getRoot
} from 'mobx-state-tree';

import RequestState from '../types/RequestState';
import Storage from '../models/Storage';
import StatefulStore from '../models/StatefulStore';

const StorageStore = StatefulStore.named('StorageStore')
  .props({
    storages: types.optional(types.array(Storage), [])
  })
  .actions((self) => {
    const ifShoppingCenter = () =>
      getRoot(self).configStore.siteConfig.isShoppingCenter;

    const baseApi = (endpoint) =>
      ifShoppingCenter() ? `shopping-center/${endpoint}` : `${endpoint}`;

    return {
      makeSureStoragesLoaded: (merchantUrl) => {
        //Ensure shopping center always has fresh storage data. => ifShoppingCenter()
        if (self.state === RequestState.NONE || ifShoppingCenter()) {
          return self.loadStorages(merchantUrl);
        }
        return Promise.resolve();
      },
      loadStorages: flow(function* loadStorages(merchantUrl) {
        self.setLoading(true);
        try {
          self.storages = yield getEnv(self).apiWrapper.request(
            `${baseApi('storages')}`,
            { params: { merchantUrl } }
          );
        } catch (e) {
          self.setError(e);
          throw e;
        }

        self.setLoading(false);
      })
    };
  })
  .views((self) => {
    return {
      findStorageById(storageId) {
        return resolveIdentifier(Storage, self.storages, storageId);
      },
      get pickupStorages() {
        return self.storages.filter((storage) => storage.is_pickup_storage);
      },
      get listingStorages() {
        return self.storages.filter((storage) => storage.show_on_store_listing);
      }
    };
  });

export default StorageStore;
