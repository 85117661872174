import { types } from 'mobx-state-tree';

const CouponActivationType = {
  GIVEN_CODE: 'GIVEN_CODE',
  AUTO_GENERATION: 'AUTO_GENERATION',
  REGISTRATION: 'REGISTRATION',
  LOGIN: 'LOGIN',
  FIRST_PURCHASE: 'FIRST_PURCHASE',
  NEWSLETTER_SUBSCRIPTION: 'NEWSLETTER_SUBSCRIPTION',
  VISIT_TIME: 'VISIT_TIME',
  PRODUCT_REVIEW: 'PRODUCT_REVIEW'
};

export default CouponActivationType;

export const CouponActivationTypeType = types.enumeration(
  'CouponActivationType',
  Object.values(CouponActivationType)
);
