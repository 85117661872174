import { types } from 'mobx-state-tree';

const PurchasesTotalValue = types.model('PurchasesTotalValue', {
  currency: types.string,
  value: types.number
});

const PurchasesTotal = types.model('PurchasesTotal', {
  order_count: types.array(PurchasesTotalValue),
  with_tax: types.array(PurchasesTotalValue),
  without_tax: types.array(PurchasesTotalValue)
});

export default PurchasesTotal;
