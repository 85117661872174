import { getRoot, resolveIdentifier, types } from 'mobx-state-tree';
import { compact } from 'lodash';

import ReserveInStoreService from './ReserveInStoreService';
import StoragePhoneNumber from './StoragePhoneNumber';

const Storage = types
  .model('Storage', {
    city: types.string,
    emails: types.optional(types.array(types.string), []),
    id: types.identifierNumber,
    is_pickup_storage: types.boolean,
    lower_limit: types.number,
    map_link_search_text: types.maybeNull(types.string),
    name: types.string,
    opening_hours: types.string,
    phone_numbers: types.optional(types.array(StoragePhoneNumber), []),
    postcode: types.string,
    service_ids: types.optional(types.array(types.number), []),
    show_on_store_listing: types.boolean,
    stock_display_style: types.string,
    street_address: types.string,
    upper_limit: types.number
  })
  .views((self) => ({
    get hasInformation() {
      return self.map_link_search_text;
    },
    get hasAddress() {
      return self.city && self.street_address && self.postcode;
    },
    get services() {
      return compact(
        self.service_ids.map((service_id) => {
          return resolveIdentifier(
            ReserveInStoreService,
            getRoot(self),
            service_id
          );
        })
      );
    }
  }));

export default Storage;
