class DataLayer {
  /**
   *
   * @param {Object} data
   * @param {GoogleAnalytics4EventNames} action
   * @param {Function} updateDimensionCallback
   */
  pushEventToDataLayer = (data, action, updateDimensionCallback) => {
    window.dataLayer.push({
      event: action,
      ...data,
    });
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    updateDimensionCallback && updateDimensionCallback();
  };
}

export default DataLayer;
