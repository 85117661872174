import { types } from 'mobx-state-tree';
import BalancePayment from './BalancePayment';

const BalancePaymentHistory = types.model('BalancePaymentHistory', {
  current_page: types.number,
  data: types.maybeNull(types.array(BalancePayment)),
  from: types.maybeNull(types.number),
  last_page: types.number,
  next_page_url: types.maybeNull(types.string),
  path: types.string,
  per_page: types.number,
  prev_page_url: types.maybeNull(types.string),
  to: types.maybeNull(types.number),
  total: types.number
});

export default BalancePaymentHistory;
