function effectiveTypeOf(node) {
  const type = typeof node;

  if (type !== 'object') {
    return type;
  }

  if (node === null) {
    return 'null';
  }

  if (node instanceof Array) {
    return 'array';
  }

  return 'object';
}

function createLeafNodeOperation(op, path, value) {
  const newPath = path.join('/');
  const operation = {
    op,
    path: '/' + newPath
  };

  if (value !== undefined) {
    operation.value = value;
  }

  return [operation];
}

export default function createMSTPatch(
  mstNode,
  newValue,
  parentType = 'root',
  commonPath = []
) {
  // If the node doesn't have a toJSON method, it is not a MobX node, but a primitive node instead.
  const mstNodeJson = mstNode && mstNode.toJSON ? mstNode.toJSON() : mstNode;

  // Short circuit equal primitives right away.
  if (mstNodeJson === newValue) {
    return [];
  }

  const mstNodeType = effectiveTypeOf(mstNodeJson);
  const newValueType = effectiveTypeOf(newValue);

  if (mstNodeType !== newValueType) {
    if (parentType === 'object' && newValueType === 'undefined') {
      // In MST, an object doesn't support the "remove" operation; it would make no sense for an instance of a model
      // to lose any of its fields, anyway. If a field is not present in the new object, it means that that value
      // should just not be changed in our case.
      // (This deviation is what prevents us from using a generic JSON patch library!)
      return [];
    } else if (parentType === 'array' && mstNodeType === 'undefined') {
      return createLeafNodeOperation('add', commonPath, newValue);
    } else if (parentType === 'array' && newValueType === 'undefined') {
      return createLeafNodeOperation('remove', commonPath);
    } else {
      return createLeafNodeOperation('replace', commonPath, newValue);
    }
  }

  if (mstNodeType === 'array') {
    const longerArrayLength = Math.max(mstNode.length, newValue.length);
    const mstNodeLength = mstNode.length;
    let operations = [];
    for (let i = 0; i < longerArrayLength; i++) {
      // Check against array length to avoid MST throwing out of bounds access warnings.
      operations.unshift(
        ...createMSTPatch(
          mstNodeLength > i ? mstNode[i] : undefined,
          newValue[i],
          mstNodeType,
          commonPath.concat([i])
        )
      );
    }

    return operations;
  }

  if (mstNodeType === 'object') {
    let operations = [];
    // Only check the model's properties; the model doesn't care about the fields it has not been told to handle,
    // so adding them in the patch is pointless
    Object.getOwnPropertyNames(mstNodeJson).forEach((property) => {
      operations.unshift(
        ...createMSTPatch(
          mstNode[property],
          newValue[property],
          mstNodeType,
          commonPath.concat([property])
        )
      );
    });

    return operations;
  }

  // number -> number, string -> string, boolean -> boolean, (function -> function, symbol -> symbol)
  // Undefined and null only have one possible value so they don't end up here ever.
  return createLeafNodeOperation('replace', commonPath, newValue);
}
