import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { createForm } from '../../../../models/Form';
import Field from '../../../../components/form/Field';
import { createFormField } from '../../../../models/FormField';
import FieldError from '../../../../components/form/FieldError';
import { modelOf } from '../../../../prop-types';
import globalTranslations from '../../../../i18n/globalTranslations';
import FormValidator from '../../../../services/FormValidator';
import Icon from '../../../../components/common/Icon';
import CheckoutShippingOption from '../../../../models/checkout/CheckoutShippingOption';

export const formFieldNames = {
  postalCode: 'postalCode',
  streetAddress: 'streetAddress',
};

const labels = {
  postalCode: <FormattedMessage {...globalTranslations.zipCodeTitle} />,
  streetAddress: (
    <FormattedMessage {...globalTranslations.streetAddressTitle} />
  ),
};

@observer
export class KlarnaInstantShoppingForm extends Component {
  constructor(props) {
    super(props);

    const { formValidator } = props;

    this.form = createForm({
      postalCode: createFormField(
        {},
        formValidator.postalCode(labels.postalCode)
      ),
      streetAddress: createFormField(
        {},
        formValidator.streetAddress(labels.streetAddress)
      ),
    });
  }

  componentDidMount() {
    this.props.onFormInit && this.props.onFormInit(this.form);
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.props.onChange &&
      this.props.onChange({ form: this.form, name, value });
  };

  getFieldError = (field) => field && field.errors[0];

  renderFieldError = (field, labelFor, label, labelProp) => {
    const error = field && field.errors[0];
    return error ? (
      <div
        className={classNames('KlarnaInstantShoppingForm__error-row', {
          'invalid-field-background': error,
        })}
      >
        <Icon
          className="KlarnaInstantShoppingForm__error-icon"
          name="exclamation-circle"
        />
        <FieldError field={field} />
      </div>
    ) : (
      <Label
        className={classNames('KlarnaInstantShoppingForm__label', {
          active: labelProp,
        })}
        for={labelFor}
      >
        {label}
      </Label>
    );
  };

  render() {
    const { postalCode, streetAddress } = this.props;

    return (
      <Fragment>
        <Col sm={12}>
          <Label className="KlarnaInstantShoppingForm__form-label">
            <FormattedMessage
              id="klarnaInstantShoppingForm.fillPickupPointInformation"
              defaultMessage="Write your address"
            />
          </Label>
        </Col>
        <Col sm={12}>
          <Form className="KlarnaInstantShoppingForm" noValidate>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <div
                    className={classNames(
                      'KlarnaInstantShoppingForm__postalCode-input',
                      {
                        'invalid-field-input-background': this.getFieldError(
                          this.form.fields.get(formFieldNames.postalCode)
                        ),
                        'invalid-field-border': this.getFieldError(
                          this.form.fields.get(formFieldNames.postalCode)
                        ),
                      }
                    )}
                  >
                    {this.renderFieldError(
                      this.form.fields.get(formFieldNames.postalCode),
                      formFieldNames.postalCode,
                      labels.postalCode,
                      postalCode
                    )}
                    <Field
                      component={Input}
                      field={this.form.fields.get(formFieldNames.postalCode)}
                      type="number"
                      name={formFieldNames.postalCode}
                      id={formFieldNames.postalCode}
                      onChange={this.handleChange}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <div
                    className={classNames(
                      'KlarnaInstantShoppingForm__streetAddress-input',
                      {
                        'invalid-field-input-background': this.getFieldError(
                          this.form.fields.get(formFieldNames.streetAddress)
                        ),
                        'invalid-field-border': this.getFieldError(
                          this.form.fields.get(formFieldNames.streetAddress)
                        ),
                      }
                    )}
                  >
                    {this.renderFieldError(
                      this.form.fields.get(formFieldNames.streetAddress),
                      formFieldNames.streetAddress,
                      labels.streetAddress,
                      streetAddress
                    )}
                    <Field
                      component={Input}
                      field={this.form.fields.get(formFieldNames.streetAddress)}
                      type="text"
                      name={formFieldNames.streetAddress}
                      id={formFieldNames.streetAddress}
                      onChange={this.handleChange}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col sm={12}>
          <div className="KlarnaInstantShoppingForm__address-acknowledgement">
            <FormattedMessage
              id="klarnaInstantShoppingForm.addressAcknowledgement"
              defaultMessage="Given address must correspond with the shipping address given in the next step"
            />
          </div>
        </Col>
      </Fragment>
    );
  }
}

KlarnaInstantShoppingForm.propTypes = {
  formValidator: PropTypes.instanceOf(FormValidator).isRequired,
  onChange: PropTypes.func.isRequired,
  onFormInit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  shippingOption: modelOf(CheckoutShippingOption),
};

export default injectIntl(inject('formValidator')(KlarnaInstantShoppingForm));
