import { types } from 'mobx-state-tree';

import MegaMenu from '../models/MegaMenu';
import MessageEvent from '../models/MessageEvent';
import MobileNavigation from '../models/MobileNavigation';
import Modal from '../models/Modal';
import NavigationOffCanvas from '../models/NavigationOffCanvas';
import Popover from '../models/Popover';
import PlaceholderSkeleton from '../models/skeleton/PlaceholderSkeleton';
import ReCaptchaValidation from '../models/validation/ReCaptchaValidation';
import Validation from '../models/validation/Validation';
import ViewBreakpoint, {
  ViewBreakpointMinWidth,
  ViewBreakpointType,
} from '../types/ViewBreakpoint';

// TODO: Refactor modals under one modals "collection" model

const UIStore = types
  .model('UIStore', {
    activeBreakpoint: types.optional(ViewBreakpointType, ViewBreakpoint.LG),
    additionalServicesModal: types.optional(
      Modal.named('AdditionalServicesModal'),
      {
        isOpen: false,
      }
    ),
    addToCartRowIsVisible: types.optional(types.boolean, false),
    barcodeReaderModal: types.optional(Modal.named('BarcodeReaderModal'), {
      isOpen: false,
    }),
    currentPage: types.optional(types.string, ''),
    documentScrollTop: types.optional(types.number, 0),
    events: types.optional(types.array(MessageEvent), []),
    globalUserMessageData: types.optional(
      types.model('MessageData', {
        id: types.string,
        defaultMessage: types.string,
      }),
      {
        id: '',
        defaultMessage: '',
      }
    ),
    headerHeight: types.optional(types.number, 0),
    lastPathname: types.maybeNull(types.string),
    megaMenu: types.optional(MegaMenu, {
      isOpen: false,
    }),
    mobileNavigation: types.optional(MobileNavigation, {}),
    mobileRegionalSettingsModal: types.optional(
      Modal.named('MobileRegionalSettingsModal'),
      {}
    ),
    navigationOffCanvas: types.optional(NavigationOffCanvas, {}),
    navigationPopover: types.optional(Popover.named('NavigationPopover'), {
      contentId: null,
      isOpen: false,
    }),
    // Here we can define multiple extended models for placeholder loaders when necessary
    // with one base model to easily define shared base properties and separate models
    placeholders: PlaceholderSkeleton,
    popoverActive: types.optional(types.boolean, false),
    // TODO: Refactor recaptcha validation under one validations "collection" model
    recaptchaValidation: types.optional(
      types.union(ReCaptchaValidation, Validation),
      {
        isValid: null,
      }
    ),
    scrollingDown: types.optional(types.boolean, false),
    searchIsActive: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get currentPageIsSet() {
      return self.currentPage.length > 0;
    },
    get hasEvents() {
      return self.events.length > 0;
    },
    get isSmallest() {
      return [ViewBreakpoint.XS].includes(self.activeBreakpoint);
    },
    get isLG() {
      return [ViewBreakpoint.LG].includes(self.activeBreakpoint);
    },
    get isLargest() {
      return [ViewBreakpoint.XXL].includes(self.activeBreakpoint);
    },
    get isMobile() {
      return [ViewBreakpoint.XS, ViewBreakpoint.SM].includes(
        self.activeBreakpoint
      );
    },
    get isTablet() {
      return [ViewBreakpoint.MD].includes(self.activeBreakpoint);
    },
    get isDesktop() {
      return [
        ViewBreakpoint.LG,
        ViewBreakpoint.XL,
        ViewBreakpoint.XXL,
      ].includes(self.activeBreakpoint);
    },
    get isLandscape() {
      return window.matchMedia('(orientation:landscape)').matches;
    },
    get isPortrait() {
      return window.matchMedia('(orientation:portrait)').matches;
    },
  }))
  .actions((self) => {
    return {
      addEvent: (event) => {
        self.events.push(event);
      },
      processEvents: () => {
        while (self.hasEvents) {
          const event = self.events.shift();
          self.globalUserMessageData = event.data;
        }
      },
      resetEvent: () => {
        self.globalUserMessageData = {
          id: '',
          defaultMessage: '',
        };
      },
      resetNavigation() {
        self.mobileNavigation.close();
        self.navigationOffCanvas.close();
      },
      setCurrentPage: (pageName) => {
        self.currentPage = pageName;
      },
      setDocumentScrollTop: (scrollTop) => {
        self.scrollingDown = self.documentScrollTop < scrollTop;
        self.documentScrollTop = scrollTop;
      },
      setHeaderHeight(height) {
        self.headerHeight = height;
      },
      setLastPathname: (pathname) => {
        self.lastPathname = pathname;
      },
      setViewActiveBreakpoint: (px) => {
        let breakpoint = ViewBreakpoint.XXL;

        if (px < ViewBreakpointMinWidth[ViewBreakpoint.SM]) {
          breakpoint = ViewBreakpoint.XS;
        } else if (px < ViewBreakpointMinWidth[ViewBreakpoint.MD]) {
          breakpoint = ViewBreakpoint.SM;
        } else if (px < ViewBreakpointMinWidth[ViewBreakpoint.LG]) {
          breakpoint = ViewBreakpoint.MD;
        } else if (px < ViewBreakpointMinWidth[ViewBreakpoint.XL]) {
          breakpoint = ViewBreakpoint.LG;
        } else if (px < ViewBreakpointMinWidth[ViewBreakpoint.XXL]) {
          breakpoint = ViewBreakpoint.XL;
        }

        // Reset navigation when breakpoint launches
        if (self.activeBreakpoint !== breakpoint) self.resetNavigation();
        self.activeBreakpoint = breakpoint;
      },
      toggleAddToCartRow: (active) => {
        self.addToCartRowIsVisible = active;
      },
      togglePopover(active) {
        self.popoverActive = active;
      },
      toggleSearch: (active) => {
        self.searchIsActive = active;
      },
    };
  });

export default UIStore;
