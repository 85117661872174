import { types } from 'mobx-state-tree';

import ShippingOption from './ShippingOption';

const ShippingDetails = types.model('ShippingDetails', {
  cheapest_option: types.maybeNull(ShippingOption),
  options: types.array(ShippingOption),
  preferred_pickup_option: types.maybeNull(ShippingOption)
});

export default ShippingDetails;
