import React from 'react';
import { observer } from 'mobx-react';

import FooterCopyright from '../../footer/FooterCopyright';

export const CheckoutFooter = () => {
  return (
    <div className="CheckoutFooter">
      <FooterCopyright />
    </div>
  );
};

export default observer(CheckoutFooter);
