import React from 'react';
import { FormattedMessage } from 'react-intl';

import globalTranslations from '../i18n/globalTranslations';
import { roundWithPrecision } from './number';
import privacyStatementLink from '../components/privacy-statement/PrivacyStatementLink';

export function allowEmpty(actualValidator) {
  return (value) => (!!value ? actualValidator(value) : null);
}

export function notBlank(field) {
  return (value) => {
    return !value || !String(value).trim() ? (
      <FormattedMessage
        id="validation.required"
        defaultMessage="{field} cannot be blank."
        values={{ field: field }}
        key={`notBlank-${field}`}
      />
    ) : null;
  };
}

export function privacyStatementAccepted(field) {
  return (value) => {
    return !value ? (
      <FormattedMessage
        key="mustApprove"
        {...globalTranslations.privacyStatementMustApproveSentence}
      />
    ) : null;
  };
}

export function minLength(field, minLength) {
  return (value) => {
    return !value || value.trim().length < minLength ? (
      <FormattedMessage
        id="validation.minLength"
        defaultMessage="{field} must have at least {minLength} characters."
        values={{ field, minLength }}
        key={`minLength-${field}`}
      />
    ) : null;
  };
}

export function maxLength(field, maxLength) {
  return (value) => {
    return value && value.trim().length > maxLength ? (
      <FormattedMessage
        id="validation.maxLength"
        defaultMessage="{field} must not have more than {maxLength} characters."
        values={{ field, maxLength }}
        key={`maxLength-${field}`}
      />
    ) : null;
  };
}

export function isEmail(field) {
  return (value) => {
    return !value || !value.match(/.+@.+\..+/) ? (
      <FormattedMessage
        id="validation.isEmail"
        defaultMessage="{field} must be a valid email address."
        values={{ field }}
        key={`isEmail-${field}`}
      />
    ) : null;
  };
}

export function mustMatch(getMatch) {
  return (value) => {
    return value !== getMatch() ? (
      <FormattedMessage
        id="validation.mustMatch"
        defaultMessage="Field does not match."
        key={`mustMatch`}
      />
    ) : null;
  };
}

function calculateBusinessIdChecksum(idNumbers) {
  let multipliers = [7, 9, 10, 5, 8, 4, 2];
  let sum = 0;
  for (let i = 0; i < idNumbers.length; i++) {
    sum += parseInt(idNumbers[i], 10) * multipliers[i];
  }
  let remainder = sum % 11;
  if (remainder > 1) {
    remainder = 11 - remainder;
  }
  return remainder;
}

function isValidBusinessId(businessId) {
  if (!/^\d{7}-\d$/.test(businessId)) {
    return false;
  }
  const givenChecksum = parseInt(businessId.substring(8, 9), 10);
  const idNumbers = businessId.substring(0, 7);
  const calculatedChecksum = calculateBusinessIdChecksum(idNumbers);

  return calculatedChecksum === givenChecksum;
}

export function finnishBusinessId() {
  return (value) => {
    return !isValidBusinessId(value) ? (
      <FormattedMessage
        id="validation.finnishBusinessId"
        defaultMessage="Please check the business ID format."
        key={`finnishBusinessId`}
      />
    ) : null;
  };
}

export function numberBetween(field, min, max) {
  return (value) => {
    return !value || typeof value !== 'number' || value < min || value > max ? (
      <FormattedMessage
        id="validation.numberBetween"
        defaultMessage="{field} must be a number between {min} and {max}."
        values={{ field, min, max }}
        key={`numberBetween-${field}`}
      />
    ) : null;
  };
}

export function isDate(field, allowNull = false) {
  return (value) => {
    if (allowNull) {
      return null;
    }
    return !value ||
      typeof value !== 'string' ||
      !/^\d{4}-\d{1,2}-\d{1,2}$/.test(value) ? (
      <FormattedMessage
        id="validation.isDate"
        defaultMessage="{field} format is not allowed."
        values={{ field }}
        key={`isDate-${field}`}
      />
    ) : null;
  };
}

export function quantityValid(maxQuantity, step) {
  return (value) => {
    const numValue = Number(value);
    const maxValue = maxQuantity;

    return numValue < step || numValue > maxValue ? (
      <FormattedMessage
        {...globalTranslations.validationMessageInvalidQuantity}
        values={{
          min: String(step),
          max: String(maxValue),
        }}
      />
    ) : null;
  };
}

export function productCountValid(step, validationMessage) {
  const validationError = validationMessage
    ? validationMessage
    : globalTranslations.validationMessageInvalidFloor;
  return (value) => {
    const factor = 100;
    const precisionValue = roundWithPrecision(
      roundWithPrecision(Number(value), 2) * factor,
      0
    );
    const precisionStep = roundWithPrecision(
      roundWithPrecision(step, 2) * factor,
      0
    );
    return Math.floor(precisionValue / precisionStep) * precisionStep !==
      precisionValue ? (
      <FormattedMessage
        {...validationError}
        values={{
          size: String(precisionStep / factor),
        }}
      />
    ) : null;
  };
}
