import { types } from 'mobx-state-tree';

const ProductRecurringOrderType = {
  NOT_ALLOWED: 'NOT_ALLOWED',
  NORMAL_AND_RECURRING_ALLOWED: 'NORMAL_AND_RECURRING_ALLOWED',
  RECURRING_ORDERS_ONLY: 'RECURRING_ORDERS_ONLY'
};

export default ProductRecurringOrderType;

export const ProductRecurringOrderTypeType = types.enumeration(
  'ProductRecurringOrderType',
  Object.values(ProductRecurringOrderType)
);
