import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import globalTranslations from '../../../i18n/globalTranslations';
import ProductPdfForm from '../ProductPdfForm';

export class ProductPdfModal extends Component {
  render() {
    const { toggle, isOpen, message, ...rest } = this.props;
    return (
      <Modal
        className="ProductPdfModal"
        size="md"
        toggle={toggle}
        isOpen={isOpen}
      >
        <ModalHeader className="ProductPdfModal__header" toggle={toggle}>
          <FormattedMessage {...globalTranslations.productPdfTitle} />
        </ModalHeader>
        <ModalBody>
          <ProductPdfForm {...rest} />
          {message}
        </ModalBody>
      </Modal>
    );
  }
}

ProductPdfModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  activeId: PropTypes.string.isRequired,
  pdfType: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  requireEmail: PropTypes.bool,
  message: PropTypes.node,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default ProductPdfModal;
