import { types } from 'mobx-state-tree';
import Price from '../Price';

const CartOption = types.model('CartOption', {
  title: types.string,
  price: Price,
  info: types.string,
});

export default CartOption;
