import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import RouterPropTypes from 'react-router-prop-types';

import { modelOf } from '../../../prop-types';
import UIStore from '../../../store/UIStore';
import RequestState from '../../../types/RequestState';
import ConfigStore from '../../../store/ConfigStore';
import AccountStore from '../../../store/AccountStore';

/**
 * This component simply watches for location changes and stores them.
 */
@observer
export class RouteHistoryKeeper extends Component {
  unlisten = null;
  state = {
    loading: RequestState.NONE
  };

  componentDidMount() {
    const { history, uiStore, configStore } = this.props;
    let previousPathname = history.location.pathname;

    this.unlisten = history.listen((location, action) => {
      if (action !== 'REPLACE') {
        uiStore.setLastPathname(previousPathname);
        previousPathname = location.pathname;

        if (
          configStore.state === RequestState.LOADED &&
          configStore.store.loginRequired
        ) {
          this.checkLogin();
        }
      }
    });
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  checkLogin = () => {
    const { accountStore } = this.props;

    this.setState({
      loading: RequestState.LOADING
    });

    accountStore.loginCheck().then(() => {
      this.setState({
        loading: RequestState.NONE
      });
    });
  };

  render() {
    return null;
  }
}

RouteHistoryKeeper.propTypes = {
  history: RouterPropTypes.history.isRequired,
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore)
};

export default inject('uiStore', 'accountStore', 'configStore')(
  RouteHistoryKeeper
);
