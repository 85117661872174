import { types } from 'mobx-state-tree';

const CommonQueryParams = {
  LOGOUT: 'processLogout'
};

export default CommonQueryParams;

export const CommonQueryParamsType = types.enumeration(
  'CommonQueryParams',
  Object.values(CommonQueryParams)
);
