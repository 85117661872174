import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AdZones from '../../../types/AdZones';
import BannerSlider from '../BannerSlider/BannerSliderSlides';

const SidebarBanner = ({ className, searchParams }) => {
  return (
    <BannerSlider
      key={searchParams}
      searchParams={{
        ...searchParams,
      }}
      adZone={AdZones.SIDEBAR_BANNER}
      overrideSettings={{
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        variableWidth: false,
      }}
      className={classNames('SidebarBanner', className)}
      aspectRatio={1}
    />
  );
};

SidebarBanner.propTypes = {
  className: PropTypes.string,
  searchParams: PropTypes.object,
};

export default observer(SidebarBanner);
