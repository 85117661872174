import { types } from 'mobx-state-tree';

import Price from './Price';
import { ShippingCampaignTypeType } from '../types/ShippingCampaignType';

const ShippingCampaignInfo = types.model('ShippingCampaignInfo', {
  end_at: types.maybeNull(types.string),
  fixed_price: types.maybeNull(Price),
  free_shipping_price_limit: types.maybeNull(Price),
  free_shipping_quantity_limit: types.maybeNull(types.number),
  free_shipping_volume_limit: types.maybeNull(types.number),
  free_shipping_weight_limit: types.maybeNull(types.number),
  id: types.number,
  name: types.maybeNull(types.string),
  type: ShippingCampaignTypeType
});

export default ShippingCampaignInfo;
