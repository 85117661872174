import classNames from 'classnames';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';

import globalTranslations from '../../../i18n/globalTranslations';
import ProductImageModel from '../../../models/ProductImage';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import CartStore from '../../../store/CartStore';
import CurrencyStore from '../../../store/CurrencyStore';
import UIStore from '../../../store/UIStore';
import CartNewProductType from '../../../types/CartNewProductType';
import { roundWithPrecision } from '../../../util/number';
import Price from '../../product/Price';
import ProductImage from '../../product/ProductImage';

const POPOVER_DURATION = 3 * 1000;

const NavigationCartPopover = ({
  accountStore,
  cartStore,
  currencyStore,
  uiStore,
}) => {
  let timer = null;
  let reactionDisposer = null;

  useEffect(() => {
    reactionDisposer = reaction(
      () => cartStore.popoverModal.isOpen,
      handleNewNotification
    );

    return () => {
      reactionDisposer();
    };
  }, []);

  const handleNewNotification = () => {
    if (timer) {
      clearTimeout(timer);
    }

    uiStore.togglePopover(true);

    timer = setTimeout(() => {
      uiStore.togglePopover(false);
    }, POPOVER_DURATION);
  };

  const getProductPrice = () => {
    const { new_product_info } = cartStore.cart;

    if (
      new_product_info.type === CartNewProductType.ARRAY ||
      !new_product_info.price
    ) {
      return null;
    }

    const withTax = accountStore.showPricesWithTax;
    const showTaxExcludedInfo = !withTax;

    const totalPrice =
      new_product_info.quantity *
      roundWithPrecision(
        new_product_info.price.getPrice(withTax),
        currencyStore.activeCurrency
          ? currencyStore.activeCurrency.precision
          : 2
      );

    return (
      totalPrice && (
        <Price price={totalPrice} showTaxExcludedInfo={showTaxExcludedInfo} />
      )
    );
  };

  const renderCartTotal = () => {
    const withTax = accountStore.showPricesWithTax;

    const price = withTax
      ? cartStore.cart.total.with_tax
      : cartStore.cart.total.without_tax;

    return (
      <Row>
        <Col className="NavigationCartPopover__cart-details mt-2">
          <div className="d-block NavigationCartPopover__cart-items">
            <FormattedMessage
              {...globalTranslations.itemsInCart}
              values={{
                itemCount: (
                  <FormattedMessage
                    id="global.itemCount"
                    values={{
                      count: cartStore.cart.number_of_products,
                    }}
                  />
                ),
              }}
            />
          </div>
          <div className="d-block NavigationCartPopover__cart-total">
            <FormattedMessage {...globalTranslations.totalPriceSentence} />:{' '}
            <Price price={price} />
          </div>
        </Col>
      </Row>
    );
  };

  const createProductImage = (url) => {
    return url
      ? ProductImageModel.create({
          id: 0,
          product_id: '',
          sizes: {
            small: url,
          },
        })
      : null;
  };

  const renderContent = () => {
    let newProductInfo;

    if (cartStore.cart) {
      newProductInfo = cartStore.cart.new_product_info;
    }

    if (!newProductInfo) {
      return null;
    }

    const isBundle = newProductInfo.type === CartNewProductType.BUNDLE;
    const priceText = getProductPrice();

    return (
      <Container>
        <div className="NavigationCartPopover__title-row">
          <p className="NavigationCartPopover__title">
            {isBundle ? (
              <FormattedMessage
                {...globalTranslations.productBundleAddedToCartSentence}
              />
            ) : (
              <FormattedMessage
                {...globalTranslations.productAddedToCartSentence}
                values={{
                  quantity: newProductInfo.quantity,
                }}
              />
            )}
          </p>
        </div>
        {newProductInfo && newProductInfo.type !== CartNewProductType.ARRAY && (
          <Row className="no-gutters">
            <Col xs={3} className="NavigationCartPopover__productImage">
              <ProductImage
                forceAspectRatio={false}
                product={newProductInfo}
                productImage={createProductImage(newProductInfo.image)}
                size="small"
                lazyLoading={false}
              />
            </Col>
            <Col xs={9} className="NavigationCartPopover__productInfo">
              {!isBundle && (
                <span className="NavigationCartPopver__quantity">
                  {newProductInfo.quantity}x
                </span>
              )}
              <span
                className="NavigationCartPopver__productName"
                dangerouslySetInnerHTML={{ __html: newProductInfo.name }}
              />
              <span className="NavigationCartPopver__price">{priceText}</span>
            </Col>
          </Row>
        )}
        {uiStore.isMobile && renderCartTotal()}
      </Container>
    );
  };

  return (
    <div
      className={classNames('NavigationCartPopover', {
        popoverVisible: uiStore.popoverActive,
      })}
    >
      {renderContent()}
    </div>
  );
};

NavigationCartPopover.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  cartStore: modelOf(CartStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
};

export default inject(
  'accountStore',
  'cartStore',
  'currencyStore',
  'uiStore'
)(observer(NavigationCartPopover));
