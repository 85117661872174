import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'reactstrap';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { createForm } from '../../../models/Form';
import globalTranslations from '../../../i18n/globalTranslations';
import { isEmail, notBlank } from '../../../util/formValidators';
import FormGroupField from '../../form/FormGroupField';
import { createFormField } from '../../../models/FormField';

@observer
class ResetPasswordForm extends Component {
  form = null;

  constructor(props) {
    super(props);
    this.form = createForm({
      email: createFormField({}, [
        notBlank(<FormattedMessage {...globalTranslations.emailTitle} />),
        isEmail(<FormattedMessage {...globalTranslations.emailTitle} />)
      ])
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.form.validate();
    if (this.form.valid && this.props.onSubmit) {
      this.props.onSubmit(this.form.getFieldValues());
    }
  };

  render() {
    return (
      <Form
        className="ResetPasswordForm"
        noValidate
        onSubmit={this.handleSubmit}
      >
        <FormGroupField
          field={this.form.fields.get('email')}
          label={<FormattedMessage {...globalTranslations.emailTitle} />}
          formName="ResetPasswordForm"
          fieldName={'email'}
        />
        <Button
          color="primary"
          block
          disabled={this.form.valid === false || this.props.disableSubmit}
          onClick={this.handleSubmit}
        >
          <FormattedMessage
            id="account.resetPassword"
            defaultMessage="Reset password"
          />
        </Button>
      </Form>
    );
  }
}

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool
};

export default ResetPasswordForm;
