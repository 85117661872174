import { types } from 'mobx-state-tree';

const SurveyType = {
  TIMED_POPUP: 'TIMED_POPUP',
  NORMAL: 'NORMAL'
};

export default SurveyType;

export const SurveyTypeType = types.enumeration(
  'SurveyType',
  Object.values(SurveyType)
);
