const setResizeListener = (callback) => {
  let pending = false;

  let doResize = (width) => {
    if (!pending && !!width) {
      pending = true;
      window.requestAnimationFrame(() => {
        callback(width);
        pending = false;
      });
    }
  };

  window.addEventListener(
    'resize',
    (e) => {
      e.preventDefault();
      doResize(window.innerWidth);
    },
    true
  );

  // Initialize with current width
  doResize(window.innerWidth);
};

export default setResizeListener;
