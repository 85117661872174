import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import Manufacturer from '../../../models/Manufacturer';
import RouteService from '../../../services/RouteService';

@observer
export class ManufacturerLink extends Component {
  render() {
    const { routeService, manufacturer, className, children } = this.props;
    return (
      <Link
        className={classNames('ManufacturerLink', className)}
        to={routeService.getPath(manufacturer.path)}
      >
        {children || manufacturer.name}
      </Link>
    );
  }
}

ManufacturerLink.propTypes = {
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  manufacturer: modelOf(Manufacturer).isRequired,
  className: PropTypes.string
};

export default inject('routeService')(ManufacturerLink);
