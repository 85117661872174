import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

class Switch extends Component {
  uniqueId;

  constructor(props) {
    super(props);
    this.uniqueId = uniqueId('Switch--');
  }

  render() {
    const { children, size, ...rest } = this.props;
    return (
      <span className={classNames('Switch', { [`Switch--${size}`]: !!size })}>
        <input id={this.uniqueId} type="checkbox" {...rest} />
        <label htmlFor={this.uniqueId}>{children}</label>
      </span>
    );
  }
}

Switch.propTypes = {
  size: PropTypes.string
};

export default Switch;
