import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import classNames from 'classnames';

import Icon from '../../common/Icon';
import SortOrder from '../../../types/SortOrder';

export const createSortSelection = (message, sortKey, sortOrder, sortIndex) => {
  return {
    message,
    sortKey,
    sortOrder,
    sortIndex,
  };
};

export const createSortOrderSelection = (message, sortOrder) => {
  return { message, sortOrder };
};

const SortSelection = ({
  intl,
  onSelectSort,
  className,
  sortSelections,
  selectedSortKey,
  selectedSortOrder,
  onSelectSortOrder,
  sortOrderSelections,
}) => {
  const [open, setOpen] = useState(false);

  const getSelectedOrderBy = (sortKey, sortOrder) =>
    sortSelections.find(
      (ss) => ss.sortKey === sortKey && ss.sortOrder === sortOrder
    );

  const activeSortOrderBy =
    getSelectedOrderBy(selectedSortKey, selectedSortOrder) || sortSelections[0];

  const activeSort = activeSortOrderBy
    ? intl.formatMessage(activeSortOrderBy.message)
    : intl.formatMessage(sortSelections[0].message);

  const getActiveSortOrderIcon = () => {
    if (selectedSortOrder === SortOrder.ASC) {
      return (
        <Icon
          name="sort-amount-asc"
          className="SortSelection__dropdown-sort--ascending"
        />
      );
    }

    return (
      <Icon
        name="sort-amount-desc"
        className="SortSelection__dropdown-sort--descending"
      />
    );
  };

  const renderActiveIcon = () => {
    return (
      <span className="SortSelection__dropdown-item--active">
        <Icon name="check" />
      </span>
    );
  };

  const renderSortOrderSelections = () => {
    if (!sortOrderSelections.length) {
      return null;
    }

    return (
      <>
        <DropdownItem divider />
        {sortOrderSelections.map((sos) => (
          <DropdownItem
            key={sos.sortOrder}
            onClick={() => onSelectSortOrder(sos.sortOrder)}
            className="SortSelection__dropdown-item"
          >
            {sos.sortOrder === selectedSortOrder && renderActiveIcon()}
            {intl.formatMessage(sos.message)}
          </DropdownItem>
        ))}
      </>
    );
  };

  return (
    <div className={classNames('SortSelection', className)}>
      <FormattedMessage id="sort.sortLabel" defaultMessage="Sort:" />
      <Dropdown
        isOpen={open}
        toggle={() => setOpen(!open)}
        direction="down"
        className="SortSelection__dropdown"
      >
        <DropdownToggle
          data-toggle="dropdown"
          color="plain"
          aria-expanded={open}
          className="SortSelection__dropdown-toggle"
        >
          {sortOrderSelections.length > 0 && getActiveSortOrderIcon()}
          {activeSort}
          <Icon name="angle-down" className="SortSelection__toggle-icon" />
        </DropdownToggle>
        <DropdownMenu>
          {sortSelections.map((ss) => (
            <DropdownItem
              key={ss.sortKey + ss.sortOrder}
              onClick={() => onSelectSort(ss.sortKey, ss.sortOrder)}
              className="SortSelection__dropdown-item"
            >
              {ss === activeSortOrderBy && renderActiveIcon()}
              {intl.formatMessage(ss.message)}
            </DropdownItem>
          ))}
          {renderSortOrderSelections()}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

SortSelection.propTypes = {
  intl: intlShape.isRequired,
  onSelectSort: PropTypes.func.isRequired,
  sortSelections: PropTypes.array.isRequired,
  className: PropTypes.string,
  selectedSortKey: PropTypes.string,
  selectedSortOrder: PropTypes.string,
  onSelectSortOrder: PropTypes.func,
  sortOrderSelections: PropTypes.array,
};

SortSelection.defaultProps = {
  sortOrderSelections: [],
};

export default injectIntl(observer(SortSelection));
