import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ConfigStore from '../../../store/ConfigStore';
import { modelOf } from '../../../prop-types';

@observer
class YotpoReviewWidget extends Component {
  componentDidMount() {
    this.createScript();
  }

  createScript = () => {
    const { configStore } = this.props;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `//staticw2.yotpo.com/${configStore.integrations.yotpo.appKey}/widget.js`;
    script.onerror = (err) => {
      console.error(err);
    };
    document.head.appendChild(script);
  };

  render() {
    return <noscript>You need to enable javascript</noscript>;
  }
}

YotpoReviewWidget.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
};

export default inject('configStore')(YotpoReviewWidget);
