import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { createForm } from '../../../models/Form';
import { createFormField } from '../../../models/FormField';
import FieldError from '../../form/FieldError';
import QuantityFieldGroup from '../../common/QuantityFieldGroup';
import CartIcon from '../CartIcon';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import UIStore from '../../../store/UIStore';
import { productCountValid, quantityValid } from '../../../util/formValidators';

@observer
export class SimpleAddToCart extends Component {
  form = null;
  buttonElement = null;
  state = {
    buttonMounted: false,
  };

  constructor(props) {
    super(props);

    this.form = createForm({
      quantity: createFormField(
        {
          value: String(props.quantityStep),
        },
        [
          quantityValid(props.maxQuantity, props.quantityStep),
          productCountValid(props.quantityStep),
        ]
      ),
    });
    this.form.validate();
  }

  componentDidMount() {
    if (this.props.onUpdateProductQuantity) {
      this.props.onUpdateProductQuantity(this.getQuantity());
    }
  }

  handleAddToCartClick = () => {
    const { onAddToCartClick } = this.props;

    this.form.validate();
    if (this.form.valid && onAddToCartClick) {
      onAddToCartClick(this.getQuantity());
    }
  };

  handleAddToProposal = () => {
    const { onAddToProposalClick } = this.props;

    this.form.validate();
    if (this.form.valid && onAddToProposalClick) {
      onAddToProposalClick(this.getQuantity());
    }
  };

  getQuantity = () => {
    return Number(this.form.fields.get('quantity').value);
  };

  render() {
    const {
      tooltipMessage,
      maxQuantity,
      unit,
      loading,
      notAllowed,
      uiStore,
      quantityStep,
      quantity,
      minOrderQuantity,
      ifWebStore,
    } = this.props;
    const quantityField = this.form.fields.get('quantity');

    return (
      <div className="SimpleAddToCart">
        <div
          className={classNames('form-inline', {
            SimpleAddToCart__wrap: !uiStore.isLargest,
          })}
        >
          <QuantityFieldGroup
            className="SimpleAddToCart__quantity-group"
            disabled={false}
            field={quantityField}
            maxQuantity={maxQuantity}
            unit={unit}
            quantityStep={quantityStep}
            onUpdateProductQuantity={this.props.onUpdateProductQuantity}
          />
          {ifWebStore && (
            <div className="SimpleAddToCart__button">
              <CartIcon
                onClick={this.handleAddToCartClick}
                onProposalClick={this.handleAddToProposal}
                notAllowed={notAllowed}
                loading={loading}
                tooltipMessage={tooltipMessage}
                disabled={!this.form.valid || quantity < minOrderQuantity}
              />
            </div>
          )}
        </div>
        <FieldError field={this.form.fields.get('quantity')} />
      </div>
    );
  }
}

SimpleAddToCart.propTypes = {
  onAddToCartClick: PropTypes.func.isRequired,
  onAddToProposalClick: PropTypes.func.isRequired,
  maxQuantity: PropTypes.number.isRequired,
  quantityStep: PropTypes.number.isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  onUpdateProductQuantity: PropTypes.func,
  notAllowed: PropTypes.bool,
  loading: PropTypes.bool,
  unit: PropTypes.string,
  tooltipMessage: PropTypes.node,
  quantity: PropTypes.number,
  minOrderQuantity: PropTypes.number,
  ifWebStore: PropTypes.bool,
};

SimpleAddToCart.defaultProps = {
  ifWebStore: true,
};
export default inject('configStore', 'uiStore')(SimpleAddToCart);
