import { getRoot, resolveIdentifier, types } from 'mobx-state-tree';
import Country from './Country';

const Address = types
  .model('Address', {
    id: types.identifierNumber,
    is_primary: types.boolean,
    company: types.string,
    firstname: types.string,
    lastname: types.string,
    street_address: types.string,
    city: types.string,
    postal_code: types.string,
    telephone: types.string,
    country_id: types.number
  })
  .views((self) => ({
    get country() {
      return resolveIdentifier(Country, getRoot(self), self.country_id);
    }
  }));

export default Address;
