import { types } from 'mobx-state-tree';

import QuantityDiscount from './QuantityDiscount';

const QuantityDiscounts = types
  .model('QuantityDiscounts', {
    lowest: types.maybeNull(QuantityDiscount),
    levels: types.optional(types.array(QuantityDiscount), []),
    highest: types.maybeNull(QuantityDiscount),
  })
  .views((self) => ({
    getLevels: () => {
      return self.levels?.length > 0 && self.levels;
    },
  }));

export default QuantityDiscounts;
