import React, { Component, Fragment } from 'react';
import { Alert, Button } from 'reactstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import RouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router';
import { get } from 'lodash';

import ProductPdfModal from '../ProductPdfModal';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import AccountStore from '../../../store/AccountStore';
import RequestState from '../../../types/RequestState';
import ProductStore from '../../../store/ProductStore';
import ErrorHandler from '../../loader/ErrorHandler';
import { createErrorModel } from '../../../util/error';
import Icon from '../../common/Icon';
import LanguageStore from '../../../store/LanguageStore';

const PDFState = {
  OK: 'OK',
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  INVALID_EMAIL: 'INVALID_EMAIL',
};

const messages = defineMessages({
  productPdfOk: {
    id: 'productPdf.OK',
    defaultMessage:
      'Your PDF file is ready. If the file does not open please try {link}',
  },
  productPdfPending: {
    id: 'productPdf.PENDING',
    defaultMessage: 'You will receive the file via email.',
  },
  productPdfFailed: {
    id: 'productPdf.FAILED',
    defaultMessage: 'Failed to create the file. Please try again.',
  },
});

@observer
export class ProductPdf extends Component {
  state = {
    modal: false,
    error: null,
    // Re-animate errors after new request in case of errors.
    requestId: 0,
    pdfState: null,
    requestState: RequestState.NONE,
    pdfLink: null,
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
      requestState: this.state.modal
        ? RequestState.NONE
        : this.state.requestState,
    });
  };

  handleSubmit = (values) => {
    const {
      configStore,
      languageStore,
      productStore,
      activeId,
      pdfType,
      location,
    } = this.props;

    const filters = qs.parse(location.search, {
      arrayFormat: 'brackets',
    });

    const params = {
      ...values,
      pdfType,
      filters,
      lang: languageStore.activeLanguage.code,
      inStock: configStore.storedInStockProductsOnly === 'true' ? '1' : '0',
    };
    this.setState({ requestState: RequestState.LOADING });

    productStore
      .getProductPdf(activeId, params)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          let link = null;

          if (data.status === PDFState.OK && data.url) {
            link = data.url;
            window.open(response.data.url, '_blank', 'noopener');
          }

          this.setState({
            pdfState: data.status,
            requestState: RequestState.LOADED,
            pdfLink: link,
          });
        }
      })
      .catch((error) => {
        this.setState({
          requestState: RequestState.ERROR,
          error: error,
          requestId: this.state.requestId + 1,
        });
      });
  };

  createProductPdfButton = () => {
    return (
      <Button
        color="primary"
        className="ProductPdf__button"
        onClick={this.toggleModal}
      >
        <Icon name="file-pdf-o" />
        <FormattedMessage {...globalTranslations.productPdfTitle} />
      </Button>
    );
  };

  getErrorMessages = () => {
    const { error } = this.state;
    return get(error, 'response.data.messages', null);
  };

  getLink = () => {
    const { pdfLink } = this.state;
    return (
      <a href={pdfLink} target="_blank" rel="noopener">
        <FormattedMessage {...globalTranslations.hereSentence} />
      </a>
    );
  };

  getMessage = () => {
    const { requestId, error, requestState } = this.state;

    let message = null;
    switch (requestState) {
      case RequestState.ERROR:
        const errorMessages = this.getErrorMessages();

        if (errorMessages) {
          message = (
            <Fragment>
              {error && get(error, 'response.status') !== 422 && (
                <ErrorHandler error={createErrorModel(error)} />
              )}
              <Alert color="danger" key={requestId}>
                {this.getErrorMessages().map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </Alert>
            </Fragment>
          );
        } else {
          message = <ErrorHandler error={createErrorModel(error)} />;
        }
        break;
      case RequestState.LOADED:
        message = this.getPDFMessage();
        break;
      default:
        break;
    }

    if (!message) {
      return null;
    }

    return <div className="ProductPdfForm__message">{message}</div>;
  };

  getPDFMessage = () => {
    const { pdfState } = this.state;
    let message = null;

    switch (pdfState) {
      case PDFState.OK:
        message = (
          <Alert color="success">
            <FormattedMessage
              {...messages.productPdfOk}
              values={{
                link: this.getLink(),
              }}
            />
          </Alert>
        );
        break;
      case PDFState.FAILED:
        message = (
          <Alert color="danger">
            <FormattedMessage {...messages.productPdfFailed} />
          </Alert>
        );
        break;
      case PDFState.PENDING:
        message = (
          <Alert color="info">
            <FormattedMessage {...messages.productPdfPending} />
          </Alert>
        );
        break;
      case PDFState.INVALID_EMAIL:
        message = (
          <Alert color="danger">
            <FormattedMessage {...globalTranslations.formFailEmail} />
          </Alert>
        );
        break;
      default:
        break;
    }

    return message;
  };

  render() {
    const { requestState } = this.state;
    const { activeId, pdfType, requireEmail } = this.props;

    return (
      <div className="ProductPdf">
        <ProductPdfModal
          handleSubmit={this.handleSubmit}
          activeId={activeId}
          pdfType={pdfType}
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          message={this.getMessage()}
          loading={requestState === RequestState.LOADING}
          requireEmail={requireEmail}
        />
        {this.createProductPdfButton()}
      </div>
    );
  }
}

ProductPdf.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  languageStore: modelOf(LanguageStore).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  location: RouterPropTypes.location.isRequired,
  activeId: PropTypes.string.isRequired,
  pdfType: PropTypes.string.isRequired,
  requireEmail: PropTypes.bool,
};

ProductPdf.defaultProps = {
  requireEmail: false,
};

export default withRouter(
  inject(
    'accountStore',
    'configStore',
    'languageStore',
    'productStore'
  )(ProductPdf)
);
