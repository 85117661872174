import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Container } from 'reactstrap';

import Page from '../../components/layout/Page';
import GenericLayout from '../../components/layout/GenericLayout';
import SEOTitle from '../../components/head/SEOTitle';
import RobotsMeta from '../../components/head/RobotsMeta';
import MyAccountThankYouPageContent from '../../components/checkout/MyAccountThankYouPageContent';
import CommonPage from '../../types/CommonPage';
import globalTranslations from '../../i18n/globalTranslations';

@observer
class MyAccountThankYouPage extends Component {
  render() {
    return (
      <Page
        name={CommonPage.MY_ACCOUNT_THANK_YOU}
        className="MyAccountThankYouPage"
      >
        <Container className="MyAccountThankYouPage__container">
          <GenericLayout
            content={
              <Fragment>
                <SEOTitle
                  title={{ ...globalTranslations.successfulPurchasePageTitle }}
                />
                <RobotsMeta noindex />
                <MyAccountThankYouPageContent />
              </Fragment>
            }
          />
        </Container>
      </Page>
    );
  }
}

export default MyAccountThankYouPage;
