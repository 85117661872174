import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { inject } from 'mobx-react';

import BreadcrumbPath from '../../../types/BreadcrumbPath';
import Icon from '../Icon';
import globalTranslations from '../../../i18n/globalTranslations';
import SEOSchema from '../../head/SEOSchema';
import { LocationContextPropType } from '../../../services/LocationContext';

export class Breadcrumbs extends Component {
  getSchemaData = () => {
    const itemListSchema = this.getItemListSchema();

    return {
      '@context': 'https://schema.org/',
      '@type': 'BreadcrumbList',
      itemListElement: itemListSchema,
      numberOfItems: itemListSchema.length,
    };
  };

  getItemListSchema = () => {
    const { locationContext, path } = this.props;
    const baseUrlSchema = `${locationContext.protocol}//${locationContext.host}`;

    return path.map((item, index) => ({
      '@type': 'ListItem',
      name: item.text,
      item: `${baseUrlSchema}${item.url ? item.url : item.text}`,
      position: index + 1,
    }));
  };

  render() {
    const { path } = this.props;

    const items = path.map((item, index) => {
      const itemTextInner = <span>{item.text}</span>;
      const itemText = item.url ? (
        <Link to={item.url}>{itemTextInner}</Link>
      ) : (
        <span>{itemTextInner}</span>
      );

      return (
        <BreadcrumbItem
          key={`${index}_${item.text}`}
          active={index === path.length - 1}
        >
          {itemText}
        </BreadcrumbItem>
      );
    });

    return (
      <div className="Breadcrumbs">
        <SEOSchema data={this.getSchemaData()} />
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">
              <Icon name="home" />
              <span className="sr-only">
                <FormattedMessage {...globalTranslations.homeTitle} />
              </span>
            </Link>
          </BreadcrumbItem>
          {items}
        </Breadcrumb>
      </div>
    );
  }
}

Breadcrumbs.propTypes = {
  path: BreadcrumbPath.isRequired,
  intl: intlShape.isRequired,
  locationContext: LocationContextPropType.isRequired,
};

export default injectIntl(inject('locationContext')(Breadcrumbs));
