import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import Breadcrumbs from '../../common/Breadcrumbs';

@observer
export class CategoryHeader extends Component {
  render() {
    const { breadcrumb } = this.props;

    return (
      <div className="CategoryHeader">
        <Breadcrumbs path={breadcrumb} />
      </div>
    );
  }
}

CategoryHeader.propTypes = {
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.node.isRequired
    })
  ).isRequired
};

export default inject('productStore')(CategoryHeader);
