import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CommonSlider from '../../common/Slider';
import ProductImage from '../../product/ProductImage';
import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';

const MainImageSlider = forwardRef((props, ref) => {
  const { product, className, size, ...rest } = props;

  return (
    <CommonSlider
      ref={ref}
      className={classNames('MainImageSlider', className)}
      {...rest}
    >
      {product.images.map((productImage) => (
        <div
          key={`${productImage.id}__${productImage.product_id}__${productImage.sizes.full}`}
        >
          <ProductImage
            product={
              product.getActualProduct(productImage.product_id) || product
            }
            productImage={productImage}
            size={size}
            forceAspectRatio={true}
            lazyLoading={false}
          />
        </div>
      ))}
    </CommonSlider>
  );
});

MainImageSlider.propTypes = {
  product: PropTypes.object.isRequired,
  size: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default MainImageSlider;
