import { types } from 'mobx-state-tree';

const QuantityDiscountDisplayStyles = {
  LOWEST: 'LOWEST',
  NORMAL_AND_LOWEST: 'NORMAL_AND_LOWEST',
  RANGE: 'RANGE',
  NONE: 'NONE',
};

export default QuantityDiscountDisplayStyles;

export const QuantityDiscountDisplayStylesType = types.enumeration(
  'QuantityDiscountDisplayStyles',
  Object.values(QuantityDiscountDisplayStyles)
);
