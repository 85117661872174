import React, { Component, Fragment } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import { inject } from 'mobx-react';

import Icon from '../../common/Icon';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import SiteType from '../../../types/Site';

export class CartIcon extends Component {
  buttonElement = null;
  state = {
    buttonMounted: false,
  };

  setButtonElement = (ref) => {
    this.buttonElement = ref;
    this.setState({ buttonMounted: true });
  };

  renderButton = (config) => {
    const {
      intl,
      loading,
      onClick,
      onProposalClick,
      disabled,
      notAllowed,
      configStore,
    } = this.props;

    return (
      <Button
        color="primary"
        className={classNames('CartIcon', {
          'CartIcon--not-allowed': notAllowed,
          'CartIcon--push-right':
            config.isPurchase &&
            configStore.siteConfig.siteType ===
              SiteType.WEB_STORE_WITH_OFFER_REQUESTS,
        })}
        innerRef={this.setButtonElement}
        onClick={config.isPurchase ? onClick : onProposalClick}
        disabled={disabled}
        aria-label={intl.formatMessage(globalTranslations.addToCartButton)}
      >
        <Icon name={loading ? 'circle-o-notch' : config.icon} spin={loading} />
      </Button>
    );
  };

  render() {
    const { configStore, tooltipMessage } = this.props;

    return (
      <Fragment>
        {configStore.siteConfig.siteType !== SiteType.OFFER_REQUEST_SITE &&
          this.renderButton({
            isPurchase: true,
            icon: 'shopping-cart',
          })}
        {configStore.siteConfig.proposalEnabled &&
          this.renderButton({
            isPurchase: false,
            icon: 'envelope-open-o',
          })}
        {tooltipMessage && this.state.buttonMounted && (
          <UncontrolledTooltip placement="top" target={this.buttonElement}>
            {tooltipMessage}
          </UncontrolledTooltip>
        )}
      </Fragment>
    );
  }
}

CartIcon.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  intl: intlShape.isRequired,
  onClick: PropTypes.func.isRequired,
  onProposalClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  notAllowed: PropTypes.bool,
  tooltipMessage: PropTypes.node,
};

export default injectIntl(inject('configStore')(CartIcon));
