import { types } from 'mobx-state-tree';
import TranslatedImage from '../models/TranslatedImage';
import TranslatedImageType from '../types/TranslatedImageType';

const TranslationStore = types
  .model('TranslationStore', {
    messages: types.optional(types.frozen(), {}),
    translatedImages: types.array(TranslatedImage),
  })
  .actions((self) => ({
    setup: (messages, translatedImages) => {
      self.messages = messages;
      self.translatedImages = translatedImages;
    },
  }))
  .views((self) => {
    return {
      get mobileLogo() {
        return self.translatedImages.filter((image) => {
          return (
            image.image_key.indexOf(TranslatedImageType.STORE_MOBILE_LOGO) !==
            -1
          );
        });
      },
      get storeLogo() {
        return self.translatedImages.filter((image) => {
          return image.image_key.indexOf(TranslatedImageType.STORE_LOGO) !== -1;
        });
      },
    };
  });

export default TranslationStore;
