import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';

// This identifier is always the same and should not be changed.
const POST_AFFILIATE_IDENTIFIER = 'pap_x2s6df8d';

@observer
export class PostAffiliateWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };
  }

  componentDidMount() {
    this.createScript();
  }

  createScript = () => {
    if (this.state.loaded) {
      return null;
    }

    const { url } = this.props.configStore.integrations.postAffiliate;
    const script = document.createElement('script');
    script.src = `https://${url}.postaffiliatepro.com/scripts/trackjs.js`;
    script.id = POST_AFFILIATE_IDENTIFIER;
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => this.loadPostAffiliate();
    script.onerror = (err) => {
      console.error(err);
    };
    document.body.appendChild(script);
  };

  loadPostAffiliate = () => {
    if (!window.PostAffTracker || this.state.loaded) {
      return null;
    }

    const { accountId } = this.props.configStore.integrations.postAffiliate;
    window.PostAffTracker.setAccountId(accountId);

    try {
      window.PostAffTracker.track();
    } catch (err) {
      console.error(err);
    }

    this.setState(() => ({ loaded: true }));
  };

  render() {
    return <noscript>You need to enable javascript</noscript>;
  }
}

PostAffiliateWidget.propTypes = {
  configStore: modelOf(ConfigStore).isRequired
};

export default inject('configStore')(PostAffiliateWidget);
