import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const GenericLegalBanner = ({ children, className }) => {
  return (
    <div className={classNames('GenericLegalBanner', className)}>
      {children}
    </div>
  );
};

GenericLegalBanner.propTypes = {
  className: PropTypes.string.isRequired,
};

export default GenericLegalBanner;
