import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import InfoPageModal from '../../info-page/InfoPageModal';
import ButtonLink from '../../common/ButtonLink';

const TermsOfUseStatementLink = ({ configStore, children }) => {
  const [show, setShow] = useState(false);

  const toggle = () => {
    setShow(!show);
  };

  return (
    <>
      {children || (
        <FormattedMessage
          id="termsOfUseStatement.termsOfUse"
          defaultMessage="{termsOfUse}"
          values={{
            termsOfUse: (
              <ButtonLink className="TermsOfUseStatementLink" onClick={toggle}>
                <FormattedMessage
                  id="termsOfUseStatement.termsOfUseLink"
                  defaultMessage="terms of use"
                />
              </ButtonLink>
            ),
          }}
        />
      )}
      {show && (
        <InfoPageModal
          isOpen={show}
          toggle={toggle}
          pageId={configStore.termsOfUseStatement.infoPageId}
        />
      )}
    </>
  );
};

TermsOfUseStatementLink.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
};

export default inject('configStore')(observer(TermsOfUseStatementLink));
