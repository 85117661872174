import React from 'react';
import PropTypes from 'prop-types';

const ImagePreload = ({ src, onImageLoad }) => {
  return (
    <div className="ImagePreload">
      <img onLoad={onImageLoad} src={src} alt="" />
    </div>
  );
};

ImagePreload.propTypes = {
  src: PropTypes.string.isRequired,
  onImageLoad: PropTypes.func,
};

export default ImagePreload;
