import React, { Component } from 'react';
import { withRouter } from 'react-router';
import RouterPropTypes from 'react-router-prop-types';

import QueryParamRedirect from '../QueryParamRedirect';
import CommonQueryParams from '../../../types/CommonQueryParams';
import { parse, stringify } from '../../../util/queryString';

export class LogoutParamRedirect extends Component {
  getPathForRedirect = () => {
    const { location } = this.props;
    const type = CommonQueryParams.LOGOUT;

    let params = parse(location.search);
    delete params[type];

    params = stringify(params);
    if (params.length > 0) {
      params = '?' + params;
    }

    return location.pathname + params + location.hash;
  };

  render() {
    return (
      <QueryParamRedirect
        paramName={CommonQueryParams.LOGOUT}
        to={this.getPathForRedirect}
      />
    );
  }
}

LogoutParamRedirect.propTypes = {
  location: RouterPropTypes.location.isRequired
};

export default withRouter(LogoutParamRedirect);
