import { types } from 'mobx-state-tree';

const ProductFile = types.model('ProductFile', {
  name: types.string,
  link: types.string,
  description: types.string,
  type: types.string
});

export default ProductFile;
