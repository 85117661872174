import { types } from 'mobx-state-tree';

const PriceFormat = {
  GROSS: '0',
  NET: '1',
  NONE: '2'
};

export default PriceFormat;

export const PriceFormatType = types.enumeration(
  'PriceFormatType',
  Object.values(PriceFormat)
);
