import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class RangeSlider extends Component {
  handleChange = (value) => {
    const { onChange } = this.props;
    onChange(value);
  };

  render() {
    const { min, max, step, value } = this.props;
    return (
      <div className="RangeSlider">
        <Range
          allowCross={false}
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={this.handleChange}
          className="RangeSlider__slider"
        />
      </div>
    );
  }
}

RangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.number)
};

export default RangeSlider;
