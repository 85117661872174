import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Price from '../Price';

@observer
export class ProductDeposit extends Component {
  render() {
    const { product, showPrice } = this.props;

    if (!product.hasDeposit()) {
      return null;
    }

    const message =
      showPrice && product.bottle_deposit ? (
        <FormattedMessage
          id="product.deposit"
          defaultMessage="Product price will be included deposit of {deposit}"
          values={{
            deposit: <Price price={product.bottle_deposit.getPrice(true)} />
          }}
        />
      ) : (
        <FormattedMessage
          id="product.depositIncluded"
          defaultMessage="Includes deposit of {deposit}"
          values={{
            deposit: <Price price={product.bottle_deposit.getPrice(true)} />
          }}
        />
      );

    return <div className="ProductDeposit">{message}</div>;
  }
}

ProductDeposit.propTypes = {
  // Both Products and ChildProducts are allowed.
  product: PropTypes.object.isRequired,
  showPrice: PropTypes.bool
};

export default inject('configStore')(ProductDeposit);
