import { types } from 'mobx-state-tree';

const Token = types.model('Token', {
  id: types.number,
  title: types.string,
  is_expired: types.boolean,
  created_at: types.string
});

export default Token;
