import React, { Component } from 'react';
import { DropdownItem } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { modelOf } from '../../../prop-types';
import NavigationDropdownMenu from '../../common/NavigationDropdownMenu';
import Icon from '../../common/Icon';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';
import globalTranslations from '../../../i18n/globalTranslations';
import RouteService from '../../../services/RouteService';
import Paths from '../../../types/Paths';
import Analytics from '../../../analytics/Analytics';
import ClientLoginLink from '../../account/ClientLoginLink';

@observer
export class NavigationAccountMenu extends Component {
  getBalanceUserPaymentHistoryNavigation(routeService) {
    const { accountStore } = this.props;
    const accountType = accountStore.isBalanceBuyer
      ? routeService.getPath(Paths.MyAccountBalanceUserPaymentHistory)
      : routeService.getPath(Paths.MyAccountOrderList);
    const accountTypeTitle = accountStore.isBalanceBuyer
      ? globalTranslations.balanceUserAccountBalanceInfo
      : globalTranslations.myOrderHistoryTitle;

    return (
      <Link to={accountType}>
        <DropdownItem>
          <FormattedMessage {...accountTypeTitle} />
        </DropdownItem>
      </Link>
    );
  }

  render() {
    const { accountStore, routeService, analytics, configStore } = this.props;

    const enableMyAccount = configStore.account.enableMyAccount;
    const balanceBuyerPath = accountStore.isBalanceBuyer
      ? Paths.MyAccountBalanceUser
      : Paths.MyAccount;
    const userAccountTypePath = accountStore.isBalanceUser
      ? Paths.MyAccountBalanceSubUser
      : balanceBuyerPath;

    const accountTitle = !accountStore.isClient ? (
      <FormattedMessage {...globalTranslations.myAccountTitle} />
    ) : (
      <FormattedMessage
        {...globalTranslations.myAccountUserTitle}
        values={{
          user: accountStore.name
        }}
      />
    );

    return (
      <NavigationDropdownMenu
        className="NavigationAccountMenu"
        label={
          <span>
            <Icon name="user" />
            {accountTitle}
          </span>
        }
        isAccount={true}
      >
        {enableMyAccount && (
          <DropdownItem
            tag={Link}
            to={routeService.getPath(userAccountTypePath)}
          >
            <FormattedMessage {...globalTranslations.myAccountTitle} />
          </DropdownItem>
        )}
        {accountStore.isBalanceBuyer &&
          !accountStore.isBalanceUser &&
          enableMyAccount &&
          this.getBalanceUserPaymentHistoryNavigation(routeService)}
        {!accountStore.isBalanceBuyer && configStore.wishlist.enabled && (
          <Link to={this.props.routeService.getPath(Paths.WishList)}>
            <DropdownItem>
              <FormattedMessage {...globalTranslations.wishListTitle} />
            </DropdownItem>
          </Link>
        )}
        {accountStore.isClientLoginEnabled && (
          <Link to="#">
            <DropdownItem>
              <ClientLoginLink />
            </DropdownItem>
          </Link>
        )}
        <Link to="#">
          <DropdownItem
            className="NavigationDropdownMenu__highlighted-action-item"
            onClick={(e) => {
              e.preventDefault();
              analytics.clearCart();
              accountStore.logout();
            }}
          >
            <span>
              <Icon name="sign-out" />{' '}
              <FormattedMessage {...globalTranslations.accountLogOutSentence} />
            </span>
          </DropdownItem>
        </Link>
      </NavigationDropdownMenu>
    );
  }
}

NavigationAccountMenu.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  configStore: modelOf(ConfigStore).isRequired
};

export default inject(
  'accountStore',
  'routeService',
  'analytics',
  'configStore'
)(NavigationAccountMenu);
