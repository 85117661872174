import { types, getEnv, flow, applyPatch } from 'mobx-state-tree';

import StatefulStore from '../models/StatefulStore';
import RequestState, { RequestStateType } from '../types/RequestState';
import RecurringOrdersPaginator from '../models/RecurringOrdersPaginator';
import RecurringOrder from '../models/RecurringOrder';
import { paramsToQueryIdentifier } from '../util/query';
import { stringify } from '../util/queryString';
import createMSTPatch from '../util/createMSTPatch';
import ShippingOption from '../models/ShippingOption';

const RecurringOrderStore = StatefulStore.named('RecurringOrderStore')
  .props({
    orders: types.optional(types.map(RecurringOrder), {}),
    orderStates: types.optional(types.map(RequestStateType), {}),
    shippingOptions: types.optional(types.map(types.array(ShippingOption)), {}),
    shippingOptionStates: types.optional(types.map(RequestStateType), {}),
    orderQueryResults: types.optional(types.map(RecurringOrdersPaginator), {}),
    orderQueryStates: types.optional(types.map(RequestStateType), {}),
  })
  .views((self) => {
    return {
      get ordersArray() {
        return Array.from(self.orders, ([key, value]) => value);
      },
      get optionsArray() {
        return Array.from(self.shippingOptions, ([key, value]) => value);
      },
    };
  })
  .actions((self) => {
    return {
      loadOrders: flow(function* loadOrders(params) {
        const queryIdentifier = paramsToQueryIdentifier(params);
        self.setLoading(true);

        self.orderQueryStates.set(queryIdentifier, RequestState.LOADING);
        try {
          const orderPaginator = yield getEnv(self).apiWrapper.request(
            `recurring-orders`,
            {
              params,
            }
          );

          const recurringOrders = orderPaginator.data;
          // Update individual orders and statuses
          recurringOrders.forEach((order) => {
            const oldOrder = self.orders.get(order.id);
            if (oldOrder) {
              const patch = createMSTPatch(oldOrder, order);
              applyPatch(oldOrder, patch);
            } else {
              self.orders.set(order.id, order);
            }
            self.orderStates.set(order.id, RequestState.LOADED);
          });
          // Set current query results
          self.orderQueryResults.set(queryIdentifier, {
            ...orderPaginator,
            data: recurringOrders.map((order) => order.id),
          });
        } catch (e) {
          self.setError(e);
          self.orderQueryStates.set(queryIdentifier, RequestState.ERROR);
          throw e;
        }

        self.orderQueryStates.set(queryIdentifier, RequestState.LOADED);
        self.setLoading(false);
      }),
      loadOrder: flow(function* loadOrder(id) {
        self.orderStates.set(id, RequestState.LOADING);

        try {
          const page = yield getEnv(self).apiWrapper.request(
            `recurring-orders/${id}`
          );
          self.orders.set(id, page);
        } catch (e) {
          self.setError(e);
          self.orderStates.set(id, RequestState.ERROR);
          throw e;
        }

        self.orderStates.set(id, RequestState.LOADED);
      }),
      loadOrderShippingOptions: flow(function* loadOrderShippingOptions(id) {
        self.shippingOptionStates.set(id, RequestState.LOADING);

        try {
          const options = yield getEnv(self).apiWrapper.request(
            `recurring-orders/${id}/shipping-options`
          );
          self.shippingOptions.set(id, options);
        } catch (e) {
          self.setError(e);
          self.shippingOptionStates.set(id, RequestState.ERROR);
          throw e;
        }

        self.shippingOptionStates.set(id, RequestState.LOADED);
      }),
      removeOrder: (id) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .delete(`recurring-orders/${id}`);
      },
      updateOrder: (orderId, order) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .put(`recurring-orders/${orderId}`, order);
      },
      addProduct: (orderId, product) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .post(`recurring-orders/${orderId}/product`, product);
      },
      updateProduct: (orderId, params) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .put(`recurring-orders/${orderId}/product`, params);
      },
      removeProduct: (orderId, params) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .delete(`recurring-orders/${orderId}/product`, { params });
      },
    };
  });

export default RecurringOrderStore;
