import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import RouterPropTypes from 'react-router-prop-types';
import { size } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import ProductClass from '../../../types/ProductClass';
import RouteService from '../../../services/RouteService';
import { addParametersToPath } from '../../../util/queryString';
import ProductPropertyType from '../../../types/ProductPropertyType';
import ProductLink from '../ProductLink';
import ShowMoreContent from '../../common/ShowMoreContent';

const ProductCardPropertiesStatic = ({
  filterNotInStockElements,
  product,
  history,
  routeService,
  onClick,
}) => {
  const getFirstCollectionProperty = (product) => {
    return product.collection.shouldSwapCollectionOrder()
      ? product.collection.row
      : product.collection.column;
  };

  const getSecondCollectionProperty = (product) => {
    return product.collection.shouldSwapCollectionOrder()
      ? product.collection.column
      : product.collection.row;
  };

  const onCollectionPropertyElementClick = (
    propertyId,
    elementId,
    isFirstCollectionClicked
  ) => {
    const elementIds = getElementIds(elementId, isFirstCollectionClicked);

    history.push(
      routeService.getProductPath(
        product,
        addParametersToPath(product.path, elementIds)
      )
    );
  };

  const getElementIds = (elementId, isFirstCollection) => {
    const elementIds = {
      columnId: null,
      rowId: null,
    };

    // Check if collections are swapped
    let firstProperty = 'columnId';
    let secondProperty = 'rowId';
    if (product.collection.shouldSwapCollectionOrder()) {
      firstProperty = 'rowId';
      secondProperty = 'columnId';
    }

    if (isFirstCollection) {
      elementIds[firstProperty] = elementId;
    } else {
      elementIds[secondProperty] = elementId;
    }

    // If a property is empty, we know that the element ID is 0.
    if (product.collection.column.isEmpty()) {
      elementIds.columnId = 0;
    }
    if (product.collection.row.isEmpty()) {
      elementIds.rowId = 0;
    }

    return elementIds;
  };

  const getCollectionComponent = (
    collectionProperty,
    clickHandler,
    isFirstProperty
  ) => {
    const count = size(collectionProperty.elements);
    if (count <= 1) {
      return null;
    }

    const elements =
      filterNotInStockElements && !product.canBeOrderedOutOfStock
        ? collectionProperty.elements.filter((element) =>
            isElementAvailable(element.id, isFirstProperty)
          )
        : collectionProperty.elements;

    if (elements.length === 0) {
      return null;
    }

    const componentElements = elements.map((element) => {
      const content = <span>{element.name}</span>;
      return (
        <div
          key={element.id}
          className="ProductCardPropertiesStatic__item"
          onClick={() => clickHandler(collectionProperty.id, element.id)}
        >
          {content}
        </div>
      );
    });

    return (
      <ShowMoreContent
        className="ProductCardPropertiesStatic__items"
        collapsedHeight={1.5}
      >
        {componentElements}
      </ShowMoreContent>
    );
  };

  const getCollectionComponentForColor = (collectionProperty) => {
    const count = size(collectionProperty.elements);
    if (count <= 1) {
      return null;
    }

    return (
      <ProductLink product={product} onClick={onClick}>
        <div className="ProductCardPropertiesStatic__color">
          <div className="ProductCardPropertiesStatic__color-gradient" />
          <FormattedMessage
            id="product.propertyColourVariants"
            defaultMessage="{ count } different colours available"
            values={{
              count: size(collectionProperty.elements),
            }}
          />
        </div>
      </ProductLink>
    );
  };

  const isElementAvailable = (elementId, isFirstProperty) => {
    const { columnId, rowId } = getElementIds(elementId, isFirstProperty);
    let status;
    if (columnId !== null && rowId !== null) {
      status = product.collection.getItemStatus(columnId, rowId);
    } else if (columnId !== null) {
      status = product.collection.getColumnStatus(columnId);
    } else {
      status = product.collection.getRowStatus(rowId);
    }
    return status.available;
  };

  const componentForCollectionProperty = (
    collectionProperty,
    isFirstProperty
  ) => {
    if (collectionProperty.isEmpty()) {
      return null;
    }

    const clickHandler = (propertyId, elementId) =>
      onCollectionPropertyElementClick(propertyId, elementId, isFirstProperty);

    let propertyComponent;
    if (collectionProperty.type === ProductPropertyType.COLOR) {
      propertyComponent = getCollectionComponentForColor(collectionProperty);
    } else {
      propertyComponent = getCollectionComponent(
        collectionProperty,
        clickHandler,
        isFirstProperty
      );
    }

    return propertyComponent;
  };

  if (product.class !== ProductClass.COLLECTION) {
    return null;
  }

  return (
    <div className="ProductCardPropertiesStatic">
      {componentForCollectionProperty(
        getFirstCollectionProperty(product),
        true
      )}
      {componentForCollectionProperty(
        getSecondCollectionProperty(product),
        false
      )}
    </div>
  );
};

ProductCardPropertiesStatic.propTypes = {
  filterNotInStockElements: PropTypes.bool,
  product: modelOf(Product).isRequired,
  history: RouterPropTypes.history.isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  onClick: PropTypes.func,
};

export default withRouter(
  inject(({ configStore, routeService }) => ({
    filterNotInStockElements: configStore.inStockProductsOnly,
    routeService,
  }))(observer(ProductCardPropertiesStatic))
);
