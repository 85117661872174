import PropTypes from 'prop-types';

const lazyNavigationEntity = () => NavigationEntity;

const NavigationEntity = PropTypes.shape({
  accessible_text: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.arrayOf(lazyNavigationEntity),
  className: PropTypes.string,
  customNavLinks: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  image: PropTypes.string,
  position: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  page_id: PropTypes.number,
  path: PropTypes.string,
});

export default NavigationEntity;
