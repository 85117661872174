import React from 'react';
import { Route } from 'react-router-dom';

import Paths from '../../types/Paths';
import ProductPage from '../../pages/ProductPage';
import CategoryPage from '../../pages/CategoryPage';
import OnSalePage from '../../pages/OnSalePage';
import NewProductsPage from '../../pages/NewProductsPage';
import SoldProductsPage from '../../pages/SoldProductsPage';
import PopularProductsPage from '../../pages/PopularProductsPage';
import RecommendedProductsPage from '../../pages/RecommendedProductsPage';
import SectionAwareRoute from '../../components/route/SectionAwareRoute';
import VehiclePartSearchPage from '../../pages/VehiclePartSearchPage';

const createSectionRoute = (path, component) => {
  return {
    path,
    component,
  };
};

const sectionRoutes = [
  createSectionRoute(Paths.Product, <ProductPage />),
  createSectionRoute(Paths.MainProductGroup, <CategoryPage />),
  createSectionRoute(Paths.ProductGroup, <CategoryPage />),
  createSectionRoute(Paths.SubProductGroup, <CategoryPage />),
  createSectionRoute(Paths.OnSale, <OnSalePage />),
  createSectionRoute(Paths.NewProducts, <NewProductsPage />),
  createSectionRoute(Paths.SoldProducts, <SoldProductsPage />),
  createSectionRoute(Paths.PopularProducts, <PopularProductsPage />),
  createSectionRoute(Paths.RecommendedProducts, <RecommendedProductsPage />),
];

/**
 * @param {Object} props
 * @param {ConfigStore} props.configStore
 * @param {Section} props.section
 * @param {string} props.match
 * @param {ReactNode} props.overrideComponent
 * @returns {Route[]}
 */
const useSectionDependantRoutes = ({
  configStore,
  section,
  match,
  overrideComponent,
}) => {
  const getRoutesBehindConfig = () => {
    const routes = [];

    if (configStore.vehiclePartSearch.enabled) {
      routes.push(
        <Route
          key={Paths.VehiclePartSearch}
          exact
          path={match + Paths.VehiclePartSearch}
          children={() => overrideComponent || <VehiclePartSearchPage />}
        />
      );
    }

    return routes;
  };

  const getSectionDependantRoutes = () => {
    return sectionRoutes.map((sectionRoute) => {
      const path = `${match}${sectionRoute.path}`;

      return (
        <Route
          key={sectionRoute.path}
          exact
          path={path}
          children={() => (
            <SectionAwareRoute
              section={section}
              component={overrideComponent || sectionRoute.component}
            />
          )}
        />
      );
    });
  };

  return [...getRoutesBehindConfig(), ...getSectionDependantRoutes()];
};

export default useSectionDependantRoutes;
