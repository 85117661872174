import { types } from 'mobx-state-tree';

const ProductAvailabilityType = {
  NORMAL: 'NORMAL',
  CLOSEOUT: 'CLOSEOUT',
  ONLY_IN_SHOP: 'ONLY_IN_SHOP',
  ALLOW_BACKORDER: 'ALLOW_BACKORDER'
};

export default ProductAvailabilityType;

export const ProductAvailabilityTypeType = types.enumeration(
  'ProductAvailabilityType',
  Object.values(ProductAvailabilityType)
);
