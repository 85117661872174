import React from 'react';
import PropTypes from 'prop-types';

const ProductAvailabilityText = ({ availabilityHtml }) => {
  return (
    <span
      className="ProductAvailabilityText"
      dangerouslySetInnerHTML={{ __html: availabilityHtml }}
    />
  );
};

ProductAvailabilityText.propTypes = {
  availabilityHtml: PropTypes.string.isRequired,
};

export default ProductAvailabilityText;
