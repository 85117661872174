const AdZones = {
  MAIN_BANNER: 'MAIN_BANNER',
  PRODUCT_PAGE_BANNER_1: 'PRODUCT_PAGE_BANNER_1',
  PRODUCT_PAGE_BANNER_2: 'PRODUCT_PAGE_BANNER_2',
  PRODUCT_PAGE_BANNER_3: 'PRODUCT_PAGE_BANNER_3',
  PRODUCT_PAGE_BANNER_4: 'PRODUCT_PAGE_BANNER_4',
  SIDEBAR_BANNER: 'SIDEBAR_BANNER',
  FOOTER_BANNER: 'FOOTER_BANNER',
  INFO_PAGE_BANNER: 'INFO_PAGE_BANNER',
  POPUP_CONTENT: 'POPUP_CONTENT',
  PRODUCT_PAGE_CONTENT_1: 'PRODUCT_PAGE_CONTENT_1',
  PRODUCT_PAGE_CONTENT_2: 'PRODUCT_PAGE_CONTENT_2',
  PRODUCT_PAGE_CONTENT_3: 'PRODUCT_PAGE_CONTENT_3',
  PRODUCT_PAGE_CONTENT_4: 'PRODUCT_PAGE_CONTENT_4',
  HEADER_CONTENT: 'HEADER_CONTENT',
  ORDER_CONFIRMATION_CONTENT: 'ORDER_CONFIRMATION_CONTENT',
  NOT_FOUND_CONTENT: 'NOT_FOUND_CONTENT',
  MEGA_MENU_BANNER: 'MEGA_MENU_BANNER',
};

export default AdZones;
