import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';

import CategoryBadges from '../CategoryBadges';
import NavigationTree from '../../navigation/NavigationTree';
import globalTranslations from '../../../i18n/globalTranslations';
import Paths from '../../../types/Paths';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import SectionStore from '../../../store/SectionStore';
import CustomNavigationLinkStore from '../../../store/CustomNavigationLinkStore';
import RouteService from '../../../services/RouteService';
import Category from '../../../models/Category';
import LinkType from '../../../types/LinkType';
import { transformInternalLink } from '../../../util/url';

@observer
export class CategoryList extends Component {
  convertCategoriesToNavigationEntities = (categories, activeCategories) => {
    const { sectionStore } = this.props;
    if (!categories) {
      return [];
    }

    return categories.map((category) =>
      this.getCategoryListItem(category, activeCategories, sectionStore)
    );
  };

  getCategoryListItem = (category, activeCategories, sectionStore) => {
    return {
      id: category.id,
      label: (
        <span>
          <CategoryBadges category={category} />
          {category.navigationName}
        </span>
      ),
      active: activeCategories.indexOf(category) !== -1,
      path: this.getCategoryListPath(category),
      children: this.convertCategoriesToNavigationEntities(
        sectionStore.activeSection
          ? category.getNonBlockedChildren(
              sectionStore.activeSection.blockedCategoriesDict
            )
          : category.children,
        activeCategories
      ),
      type: category.link_type,
      customNavLinks: this.getCustomNavigationLinks(category),
    };
  };

  getCategoryListPath = (category) => {
    const { routeService, sectionStore } = this.props;

    if (category.link_type === LinkType.EXTERNAL_LINK) {
      return category.path;
    }

    if (category.link_type === LinkType.INTERNAL_LINK) {
      return routeService.getPath(category.path);
    }

    return routeService.getPath(category.path, sectionStore.activeSection);
  };

  getCustomNavigationLinks = (category) => {
    const { customNavigationLinkStore } = this.props;
    return this.convertCustomNavigationLinksToEntityItems(
      customNavigationLinkStore.filterByCategoryId(category.id)
    );
  };

  convertCustomNavigationLinksToEntityItems = (customNavLinks) => {
    const { customNavigationLinkStore } = this.props;
    return customNavLinks.map((customNavLink) => {
      return {
        id: customNavLink.id,
        label: customNavLink.name,
        path: this.getCustomCategoryListPath(customNavLink),
        link_type: customNavLink.link_type,
        location: customNavLink.location,
        parent_sibling_id: customNavLink.parent_sibling_id,
        customNavLinks: this.convertCustomNavigationLinksToEntityItems(
          customNavigationLinkStore.findChildLinks(customNavLink.id)
        ),
      };
    });
  };

  getCustomCategoryListPath = (customNavLink) => {
    const { routeService, sectionStore } = this.props;

    if (customNavLink.link_type === LinkType.EXTERNAL_LINK) {
      return customNavLink.link;
    }

    if (customNavLink.link_type === LinkType.INTERNAL_LINK) {
      return routeService.getPath(transformInternalLink(customNavLink.link));
    }

    return routeService.getPath(customNavLink.link, sectionStore.activeSection);
  };

  render() {
    const {
      configStore,
      sectionStore,
      routeService,
      categories,
      activeCategories,
    } = this.props;

    const navItems = this.convertCategoriesToNavigationEntities(
      categories,
      activeCategories
    );

    if (configStore.navigation.sidebar.showNewProducts) {
      navItems.push({
        id: 'new_products',
        label: <FormattedMessage {...globalTranslations.newProductsTitle} />,
        path: routeService.getPath(
          Paths.NewProducts,
          sectionStore.activeSection
        ),
      });
    }

    if (configStore.navigation.sidebar.showOnSale) {
      navItems.push({
        id: 'on_sale',
        label: <FormattedMessage {...globalTranslations.onSaleTitle} />,
        path: routeService.getPath(Paths.OnSale, sectionStore.activeSection),
      });
    }

    return <NavigationTree items={navItems} className="CategoryList" />;
  }
}

CategoryList.propTypes = {
  categories: PropTypes.oneOfType([
    MobxPropTypes.observableArrayOf(modelOf(Category)).isRequired,
    PropTypes.arrayOf(modelOf(Category)).isRequired,
  ]),
  activeCategories: PropTypes.array.isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  customNavigationLinkStore: modelOf(CustomNavigationLinkStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
};

export default inject(
  'configStore',
  'sectionStore',
  'routeService',
  'customNavigationLinkStore'
)(CategoryList);
