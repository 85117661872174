import { types } from 'mobx-state-tree';

import { ManufacturerIntegrationTypeType } from '../types/ManufacturerIntegrationType';

const ManufacturerIntegration = types.model('ManufacturerIntegration', {
  type: ManufacturerIntegrationTypeType,
  api_key: types.maybeNull(types.string)
});

export default ManufacturerIntegration;
