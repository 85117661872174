import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { some } from 'lodash';

class ClickOutside extends Component {
  isTouch = false;

  componentDidMount() {
    document.addEventListener('touchend', this.handle, true);
    document.addEventListener('click', this.handle, true);
  }

  componentWillUnmount() {
    document.removeEventListener('touchend', this.handle, true);
    document.removeEventListener('click', this.handle, true);
  }

  inExcluded = (target) => {
    const { excludedSelector } = this.props;

    return some(document.querySelectorAll(excludedSelector), (element) =>
      element.contains(target)
    );
  };

  handle = (e) => {
    if (e.type === 'touchend') {
      this.isTouch = true;
    }
    if (e.type === 'click' && this.isTouch) {
      return;
    }
    const { onClickOutside } = this.props;
    const el = this.container;
    if (!this.inExcluded(e.target) && el && !el.contains(e.target)) {
      onClickOutside(e);
    }
  };

  setContainer = (ref) => {
    this.container = ref;
  };

  render() {
    const { children, onClickOutside, excludedSelector, ...props } = this.props;
    return (
      <div {...props} ref={this.setContainer}>
        {children}
      </div>
    );
  }
}

ClickOutside.propTypes = {
  onClickOutside: PropTypes.func.isRequired,
  excludedSelector: PropTypes.string,
};

export default ClickOutside;
