import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { blockTestRender } from '../../../util/process';

export const SidebarSkeleton = () => {
  const getSkeleton = (height, count = 1) => {
    return blockTestRender() ? (
      <Skeleton count={count} height={height} />
    ) : null;
  };

  return (
    <div className="SidebarSkeleton">
      {getSkeleton(35, 10)}
      <div className="Skeleton__spacer" />
      {getSkeleton(200)}
      <div className="Skeleton__spacer" />
      {getSkeleton(200)}
    </div>
  );
};

export default SidebarSkeleton;
