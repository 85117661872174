import { types } from 'mobx-state-tree';

const BalanceUser = types.model('BalanceUser', {
  id: types.number,
  firstname: types.string,
  lastname: types.string,
  email: types.string,
  telephone: types.maybeNull(types.string),
  login_name: types.string,
  is_active: types.boolean,
  account_balance: types.number,
  account_currency: types.string,
  last_purchase: types.maybeNull(types.string),
  password: types.maybeNull(types.string)
});

export default BalanceUser;
