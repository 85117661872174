import { types } from 'mobx-state-tree';

const AccountModalTab = {
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  CHANGE_CLIENT: 'CHANGE_CLIENT'
};

export default AccountModalTab;

export const AccountModalTabType = types.enumeration(
  'AccountModalTab',
  Object.values(AccountModalTab)
);
