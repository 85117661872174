import React from 'react';
import { Container, NavItem } from 'reactstrap';
import PropTypes from 'prop-types';

import Icon from '../../common/Icon';

const NavigationScrollIndicator = ({ className }) => {
  return (
    <>
      <NavItem className={className}>
        <div className="NavigationScrollIndicator__spacer"></div>
      </NavItem>
      <NavItem className={className}>
        <div className="NavigationScrollIndicator">
          <Container className="NavigationScrollIndicator__container">
            <Icon
              name="angle-right"
              className="NavigationScrollIndicator__icon"
            />
          </Container>
        </div>
      </NavItem>
    </>
  );
};

NavigationScrollIndicator.propTypes = {
  className: PropTypes.string,
};

export default NavigationScrollIndicator;
