import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import globalTranslations from '../../../i18n/globalTranslations';

const ShowMoreContent = ({ collapsedHeight, autoExpandMargin, children }) => {
  const [contentStates, setStates] = useState({
    isCollapsed: true,
    hasOverflow: true,
    shouldAutoExpand: false,
  });
  let ref = useRef(null);
  let childRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      initStates();
    };
    initStates();

    window.addEventListener('resize', handleResize, { passive: true });

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [childRef]);

  const initStates = () => {
    if (ref.current && childRef.current) {
      const hasOverflow =
        childRef.current.clientHeight > ref.current.clientHeight;
      const shouldAutoExpand =
        childRef.current.clientHeight <
        ref.current.clientHeight + autoExpandMargin;

      setStates({
        isCollapsed: true,
        hasOverflow,
        shouldAutoExpand,
      });
    }
  };

  const renderShowMore = () => {
    if (!contentStates.hasOverflow) {
      return null;
    }

    const showMoreText = contentStates.isCollapsed ? (
      <FormattedMessage {...globalTranslations.showMoreText} />
    ) : (
      <FormattedMessage {...globalTranslations.showLessText} />
    );

    return (
      <div
        className="ShowMoreContent__indicator"
        onClick={() =>
          setStates({
            ...contentStates,
            isCollapsed: !contentStates.isCollapsed,
          })
        }
      >
        {showMoreText}
      </div>
    );
  };

  const style =
    (childRef.current &&
      contentStates.hasOverflow &&
      !contentStates.isCollapsed) ||
    contentStates.shouldAutoExpand
      ? { height: childRef.current.clientHeight + 'px' }
      : { height: collapsedHeight + 'rem' };

  return (
    <>
      <div
        className={classNames('ShowMoreContent', {
          'ShowMoreContent--overflow-hidden':
            contentStates.isCollapsed && contentStates.hasOverflow,
          'ShowMoreContent--overflow-visible': !contentStates.isCollapsed,
        })}
        style={style}
        ref={ref}
      >
        <div className="ShowMoreContent__children" ref={childRef}>
          {children}
        </div>
      </div>
      {!contentStates.shouldAutoExpand && renderShowMore()}
    </>
  );
};

ShowMoreContent.propTypes = {
  // rem. Height when collapse enabled.
  collapsedHeight: PropTypes.number.isRequired,
  // px. Threshold to prevent functionality.
  autoExpandMargin: PropTypes.number.isRequired,
};

ShowMoreContent.defaultProps = {
  collapsedHeight: 3.5,
  autoExpandMargin: 10,
};

export default ShowMoreContent;
