import React, { Component, Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { HelmetProvider } from 'react-helmet-async';

import Analytics from './analytics/Analytics';
import App from './App';
import RootStore from './store';
import ApiWrapper from './services/ApiWrapper';
import MaintenanceHandler from './components/maintenance/MaintenanceHandler';
import LocationContext from './services/LocationContext';
import RouteService from './services/RouteService';
import SentryErrorBoundary from './components/error/SentryErrorBoundary';
import FormValidator from './services/FormValidator';
import { parse } from './util/queryString';

const initialState = window.__INITIAL_STATE__ || {};
const pageLoadQueryParams = parse(window.location.search);

const apiWrapper = new ApiWrapper(
  process.env.REACT_APP_API_URL || window.location.hostname + '/backend/v1',
  {
    gclid: pageLoadQueryParams.gclid,
    cuad: pageLoadQueryParams.cuad,
  }
);
const store = RootStore.create(initialState, {
  apiWrapper,
});
const routeService = new RouteService(
  store.sectionStore,
  store.languageStore,
  store.configStore,
  store.categoryStore
);
const formValidator = new FormValidator(store.configStore);
const analytics = new Analytics(
  store.currencyStore,
  store.uiStore,
  store.configStore
);
const locationContext = new LocationContext(
  () => window.location.protocol,
  () => window.location.host,
  () => window.location.href
);

class BrowserApp extends Component {
  state = {
    maintenance: {
      active: false,
      message: '',
    },
  };

  constructor(props) {
    super(props);
    apiWrapper.registerMaintenanceCallback(this.setMaintenance);
  }

  setMaintenance = (active, message) => {
    this.setState({ maintenance: { active, message } });
  };

  render() {
    const ErrorBoundaryWrapper =
      process.env.NODE_ENV === 'production' ? SentryErrorBoundary : Fragment;

    return (
      <ErrorBoundaryWrapper>
        <MaintenanceHandler {...this.state.maintenance}>
          <Provider
            {...store}
            routeService={routeService}
            analytics={analytics}
            apiWrapper={apiWrapper}
            locationContext={locationContext}
            formValidator={formValidator}
          >
            <BrowserRouter>
              <HelmetProvider>
                <App store={store} />
              </HelmetProvider>
            </BrowserRouter>
          </Provider>
        </MaintenanceHandler>
      </ErrorBoundaryWrapper>
    );
  }
}

export default BrowserApp;
