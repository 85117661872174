import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';

import globalTranslations from '../../../i18n/globalTranslations';

const CouponExpiry = ({ date }) => {
  return (
    <span className="CouponExpiry">
      <FormattedMessage
        {...globalTranslations.couponValidUntilSentence}
        values={{
          offerExpiry: DateTime.fromISO(date).toLocaleString(),
        }}
      />
    </span>
  );
};

CouponExpiry.propTypes = {
  // ISO 8601 date
  date: PropTypes.string,
};

export default CouponExpiry;
