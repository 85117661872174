import { flow, types } from 'mobx-state-tree';
import Validation from './Validation';
import RequestState from '../../types/RequestState';

const ReCaptchaValidation = Validation.named('ReCaptchaValidation')
  .props({
    token: types.maybeNull(types.string),
    recaptchaError: RequestState.NONE,
  })
  .actions((self) => {
    let recaptchaRef = null;

    return {
      setRef(ref) {
        recaptchaRef = ref;
      },
      getRef() {
        return recaptchaRef;
      },
      onChange(value) {
        self.token = value;
        self.validate(value);
      },
      onError() {
        self.recaptchaError = RequestState.ERROR;
      },
      getCaptchaToken: flow(function* getCaptchaToken() {
        // To enable form submit buttons, if ReCaptcha verification is disabled
        if (!recaptchaRef) {
          self.isValid = true;
          return;
        }

        const token = yield recaptchaRef.executeAsync();
        self.token = token;
        self.validate(token);
      }),
      resetCaptchaToken() {
        if (recaptchaRef) recaptchaRef.reset();
        self.token = null;
        self.validate(null);
      },
    };
  })
  .views((self) => ({
    get hasError() {
      return self.recaptchaError === RequestState.ERROR;
    },
  }));

export default ReCaptchaValidation;
