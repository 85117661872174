import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet-async';

import ConfigStore from '../../store/ConfigStore';
import { modelOf } from '../../prop-types';
import GiosgEventHandler from './GiosgEventHandler';
import AccountStore from '../../store/AccountStore';

@observer
class Giosg extends Component {
  componentDidMount() {
    const { configStore, accountStore } = this.props;
    if (
      configStore.integrations.giosg.enabled &&
      configStore.integrations.giosg.merchantId.length > 0
    ) {
      window.giosgEventHandler = new GiosgEventHandler(accountStore);
    }
  }

  render() {
    const { configStore } = this.props;
    if (
      !configStore.integrations.giosg.enabled ||
      !configStore.integrations.giosg.merchantId.length > 0
    ) {
      return null;
    }

    return (
      <div>
        <Helmet>
          <script>
            {`(function(w, t, f) {
                var s='script',o='_giosg',h='https://service.giosg.com',e,n;
                e=t.createElement(s);
                e.async=1;
                e.src=h+'/live2/'+f;
                w[o]=w[o]||function(){(w[o]._e=w[o]._e||[]).push(arguments)};
                w[o]._c=f;
                w[o]._h=h;
                n=t.getElementsByTagName(s)[1];
                n.parentNode.insertBefore(e,n);
              })(window,document,"${configStore.integrations.giosg.merchantId}");`}
          </script>
        </Helmet>
      </div>
    );
  }
}

Giosg.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  accountStore: modelOf(AccountStore).isRequired,
};

export default inject('configStore', 'accountStore')(Giosg);
