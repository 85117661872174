import React from 'react';
import PropTypes from 'prop-types';

const DiscountText = ({ text }) => {
  return <span className="DiscountText">{text}</span>;
};

DiscountText.propTypes = {
  text: PropTypes.string,
};

export default DiscountText;
