import { types } from 'mobx-state-tree';

const ProductPdfType = {
  PRODUCT: 'PRODUCT',
  SECTION: 'SECTION',
  CATEGORY: 'CATEGORY',
  CART: 'CART'
};

export default ProductPdfType;

export const ProductPdfTypeType = types.enumeration(
  'ProductPdfType',
  Object.values(ProductPdfType)
);
