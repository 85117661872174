import { types } from 'mobx-state-tree';
import { CreditAccountTypeType } from '../types/CreditAccountType';

const CreditAccount = types.model('CreditAccount', {
  type: types.maybeNull(CreditAccountTypeType),
  limit: types.maybeNull(types.number),
  credit_used: types.maybeNull(types.number),
  date_updated: types.maybeNull(types.string),
  balance: types.maybeNull(types.number)
});

export default CreditAccount;
