import React, { Component } from 'react';
import PropTypes from 'prop-types';

import 'famfamfam-flags/dist/sprite/famfamfam-flags.css';

class FlagIcon extends Component {
  render() {
    return (
      <span className={`FlagIcon famfamfam-flags ${this.props.countryCode}`} />
    );
  }
}

FlagIcon.propTypes = {
  countryCode: PropTypes.string.isRequired
};

export default FlagIcon;
