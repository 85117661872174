import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';

import Page from '../../components/layout/Page';
import MainProductList from '../../components/product-list/MainProductList';
import HelperNavigation from '../../components/common/HelperNavigation';
import GenericLayout from '../../components/layout/GenericLayout';
import globalTranslations from '../../i18n/globalTranslations';
import PageTitle from '../../components/common/PageTitle';
import Paths from '../../types/Paths';
import SEOTitle from '../../components/head/SEOTitle';
import CanonicalLink from '../../components/head/CanonicalLink';
import MainBanner from '../../components/ad/MainBanner';
import ScrollReset from '../../components/common/ScrollReset';
import AdPages from '../../types/AdPages';
import CommonPage from '../../types/CommonPage';
import { modelOf } from '../../prop-types';
import ConfigStore from '../../store/ConfigStore';
import SectionStore from '../../store/SectionStore';
import RouteService from '../../services/RouteService';
import AdStore from '../../store/AdStore';
import AdZones from '../../types/AdZones';
import ContentPopups from '../../components/ad/ContentPopups';
import useShouldShowBanner from '../../hooks/banner/useShouldShowBanner';

const SoldProductsPage = ({
  adStore,
  configStore,
  sectionStore,
  intl,
  routeService,
}) => {
  useEffect(() => {
    if (useShouldShowBanner({ adStore, page: AdPages.SOLD_PRODUCTS })) {
      loadAds();
    }
  }, []);

  const loadAds = () => {
    adStore.loadAds(getSearchParams());
  };

  const getBreadcrumbsPath = () => {
    return routeService.transformBreadCrumbs([
      {
        text: intl.formatMessage(globalTranslations.soldProductsTitle),
        url: Paths.SoldProducts,
      },
    ]);
  };

  const getSearchParams = () => {
    return {
      bannerPage: AdPages.SOLD_PRODUCTS,
    };
  };

  const getConfigSettings = () => {
    const settings = [];

    if (configStore.soldProductsPage.showProductCards) {
      settings.disableLinks = false;
    } else {
      settings.disableLinks = true;
    }

    return settings;
  };

  const listId = 'sold_products_list';
  const listName = 'Sold Products List';
  const fixedParams = {
    purchaseHistory: 1,
    includeFilters: 1,
    inStock: 0,
  };

  const cardSettings = {
    hideAddToCartButton: true,
    hidePrice: true,
    hideQuantity: true,
    showAsSold: true,
    ...getConfigSettings(),
  };

  const productParams = {
    showOutOfStock: 1,
  };

  if (sectionStore.activeSection) {
    fixedParams.sections = [sectionStore.activeSection.id];
  }

  const mainBanner = useShouldShowBanner({
    adStore,
    page: AdPages.SOLD_PRODUCTS,
    zone: AdZones.MAIN_BANNER,
  }) ? (
    <MainBanner searchParams={getSearchParams()} />
  ) : null;
  const mainBannerWithinContent =
    configStore.banner.mainBanner.withinContent &&
    !configStore.banner.mainBanner.fullWidth;

  return (
    <Page name={CommonPage.SOLD_PRODUCTS} className="SoldProductsPage">
      <ScrollReset />
      <SEOTitle
        title={{
          ...globalTranslations.soldProductsTitle,
        }}
      />
      <CanonicalLink path={routeService.getPath(Paths.SoldProducts)} />

      {!mainBannerWithinContent && mainBanner}
      <Container className="SoldProductsPage__container">
        <GenericLayout
          bannerSearchParams={
            useShouldShowBanner({
              adStore,
              page: AdPages.SOLD_PRODUCTS,
              zone: AdZones.SIDEBAR_BANNER,
            })
              ? getSearchParams()
              : null
          }
          content={
            <>
              {mainBannerWithinContent && mainBanner}
              <HelperNavigation breadcrumbsPath={getBreadcrumbsPath()} />
              <PageTitle>
                <FormattedMessage {...globalTranslations.soldProductsTitle} />
              </PageTitle>
              <MainProductList
                fixedParams={fixedParams}
                listId={listId}
                name={listName}
                showSort={false}
                cardSettings={cardSettings}
                productParams={productParams}
              />
              {useShouldShowBanner({
                adStore,
                page: AdPages.SOLD_PRODUCTS,
                zone: AdZones.POPUP_CONTENT,
              }) && <ContentPopups />}
            </>
          }
        />
      </Container>
    </Page>
  );
};

SoldProductsPage.propTypes = {
  adStore: modelOf(AdStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  intl: intlShape.isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
};

export default injectIntl(
  inject(
    'adStore',
    'configStore',
    'sectionStore',
    'routeService'
  )(observer(SoldProductsPage))
);
