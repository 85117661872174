import { types } from 'mobx-state-tree';

import { LinkTypeType } from '../types/LinkType';
import { NavigationEntityPositionType } from '../types/NavigationEntityPosition';

const CustomNavigationLink = types.model('CustomNavigationLink', {
  id: types.identifierNumber,
  image: types.maybeNull(types.string),
  link: types.maybeNull(types.string),
  link_type: LinkTypeType,
  location: types.maybeNull(types.string),
  name: types.string,
  page_id: types.maybeNull(types.number),
  parent_sibling_id: types.maybeNull(types.number),
  position: types.maybeNull(NavigationEntityPositionType),
  tree_type: types.maybeNull(types.string),
});

export default CustomNavigationLink;
