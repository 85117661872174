import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { blockTestRender } from '../../../util/process';

const LayoutBoxSkeleton = ({}) => {
  return (
    <div className="LayoutBoxSkeleton">
      {blockTestRender() && <Skeleton height="100%" />}
    </div>
  );
};

export default LayoutBoxSkeleton;
