import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

const AdLoaded = ({ ads, children }) => {
  return children(ads);
};

AdLoaded.propTypes = {
  ads: PropTypes.array,
};

export default observer(AdLoaded);
