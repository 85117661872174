import { types } from 'mobx-state-tree';
import InstantShoppingOrder from './InstantShoppingOrder';

const InstantShoppingInstance = types
  .model('InstantShoppingInstance', {
    instant_shopping_id: types.string,
    integrationInfo: types.maybeNull(
      types.model({
        environment: types.maybeNull(types.string),
        integration_type: types.maybeNull(types.string),
        region: types.maybeNull(types.string),
        use_on_product_listings: types.maybeNull(types.boolean),
        use_on_product_page: types.maybeNull(types.boolean)
      })
    ),
    order: types.maybeNull(InstantShoppingOrder),
    success: types.maybeNull(types.boolean)
  })
  .views((self) => {
    return {
      get paymentModuleId() {
        return self.order && self.order.payment_module_id;
      }
    };
  });

export default InstantShoppingInstance;
