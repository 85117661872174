import React from 'react';
import { Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../../prop-types';
import CartStore from '../../../../store/CartStore';
import Price from '../../../product/Price';
import Icon from '../../../common/Icon';
import MarkerBadge from '../../../common/MarkerBadge';
import globalTranslations from '../../../../i18n/globalTranslations';
import RequestState from '../../../../types/RequestState';
import ConfigStore from '../../../../store/ConfigStore';
import SiteType from '../../../../types/Site';
import AccountStore from '../../../../store/AccountStore';

const NavigationCartButton = ({
  accountStore,
  cartStore,
  configStore,
  className,
  wrapperClassName,
}) => {
  const withTax = accountStore.showPricesWithTax;

  const getPrice = () => {
    return cartStore.cart && cartStore.cart.total.getPrice(withTax);
  };

  const getHidePriceOnMobile = () => getPrice() > 1000;

  const renderPrice = () => {
    const price = getPrice();
    return price !== null ? <Price price={price} /> : <Price price={0} />;
  };

  const renderCartText = () => {
    let text;

    switch (cartStore.state) {
      case RequestState.LOADED:
        if (!cartStore.cart || cartStore.cart.number_of_products === 0) {
          text = <FormattedMessage {...globalTranslations.cartEmptySentence} />;
        } else {
          text = (
            <span>
              <FormattedMessage
                {...globalTranslations.itemCount}
                values={{
                  count: cartStore.cart.number_of_products,
                }}
              />{' '}
              {renderPrice()}
            </span>
          );
        }
        break;
      case RequestState.LOADING:
      default:
        text = <FormattedMessage {...globalTranslations.loading} />;
        break;
    }

    return text;
  };

  return (
    <div className={classNames('NavigationCart', 'NavigationCartButton', className)}>
      <div
        className={classNames({
          'NavigationCart__cart-button-wrapper':
            configStore.siteConfig.siteType !==
            SiteType.WEB_STORE_WITH_OFFER_REQUESTS,
          'NavigationCart__combination-button-wrapper':
            configStore.siteConfig.isHybrid,
          wrapperClassName,
        })}
      >
        <Button
          block
          color="primary"
          onClick={() => cartStore.cartModal.toggle(true)}
          disabled={cartStore.state !== RequestState.LOADED}
          className="NavigationCart__cart-Button"
        >
          <Icon name="shopping-cart" />
          <span
            className={classNames('NavigationCart__cart-text', {
              'NavigationCart__cart-text--hide-on-mobile':
                getHidePriceOnMobile(),
            })}
          >
            <span className="NavigationCart__cart-text-desktop">
              {renderCartText()}
            </span>
            <span className="NavigationCart__cart-text-mobile">
              {renderPrice()}
            </span>
          </span>
        </Button>
        {cartStore.cart && cartStore.cart.number_of_products > 0 && (
          <MarkerBadge className="d-xs-inline d-md-none">
            {cartStore.cart.number_of_products}
          </MarkerBadge>
        )}
      </div>
    </div>
  );
};

NavigationCartButton.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  cartStore: modelOf(CartStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default inject(
  'accountStore',
  'cartStore',
  'configStore'
)(observer(NavigationCartButton));
