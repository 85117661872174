import { types } from 'mobx-state-tree';

const Stock = types.model('Stock', {
  storage_id: types.number,
  free_quantity: types.number,
  count_availability: types.boolean,
  is_pickup_storage: types.boolean,
  last_arrival_date: types.maybeNull(types.string),
  last_date_sold: types.maybeNull(types.string),
  last_modified: types.maybeNull(types.string),
  real_stock: types.number,
  reserved_quantity: types.number,
  shelf_location: types.maybeNull(types.string),
  stock_label: types.maybeNull(types.string)
});

export default Stock;
