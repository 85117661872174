import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Icon = ({ name, className, spin }) => {
  return (
    <span
      className={classNames(
        'Icon',
        'fa',
        `fa-${name}`,
        'icon',
        `icon-${name}`,
        className,
        { 'fa-spin': spin }
      )}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  spin: PropTypes.bool,
};

Icon.defaultProps = {
  className: '',
  spin: false,
};

export default Icon;
