import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ContentSlot from '../ContentSlot';
import AdLoader from '../AdLoader';
import SSRAwareLazyLoad from '../../common/SSRAwareLazyLoad';

const ContentSlots = ({ adZone, className, searchParams }) => {
  return (
    <SSRAwareLazyLoad once offset={50} height={25}>
      <AdLoader adZone={adZone} searchParams={searchParams}>
        {(ads) => {
          if (!ads || ads.length === 0) {
            return null;
          }

          return (
            <div className={classNames('ContentSlots', className)}>
              {ads.map((ad) => (
                <ContentSlot ad={ad} bannerZone={adZone} key={ad.id} />
              ))}
            </div>
          );
        }}
      </AdLoader>
    </SSRAwareLazyLoad>
  );
};

ContentSlots.propTypes = {
  searchParams: PropTypes.object.isRequired,
  adZone: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default observer(ContentSlots);
