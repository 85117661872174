import React from 'react';
import PropTypes from 'prop-types';

import GoBackLink from '../GoBackLink';
import Breadcrumbs from '../Breadcrumbs';
import BreadcrumbPath from '../../../types/BreadcrumbPath';

const HelperNavigation = ({ breadcrumbsPath, withGoBackLink, rightSide }) => {
  return (
    <div className="HelperNavigation">
      {rightSide}
      {withGoBackLink && (
        <span>
          <GoBackLink />
        </span>
      )}
      <Breadcrumbs path={breadcrumbsPath} />
    </div>
  );
};

HelperNavigation.propTypes = {
  breadcrumbsPath: BreadcrumbPath.isRequired,
  withGoBackLink: PropTypes.bool,
  rightSide: PropTypes.node,
};

export default HelperNavigation;
