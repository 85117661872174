import { types } from 'mobx-state-tree';
import Price from '../Price';
import CartOption from './CartOption';
import CartNewProduct from './CartNewProduct';
import CartProduct from './CartProduct';
import ProductTypeClass from '../../types/ProductTypeClass';
import CartNewProductType from '../../types/CartNewProductType';

const CartBasket = types.model('CartOption', {
  id: types.string,
  text: types.string,
});
const Cart = types
  .model('Cart', {
    baskets_and_templates: types.array(CartBasket),
    expires_at: types.maybeNull(types.string),
    new_product_info: types.maybeNull(CartNewProduct),
    number_of_products: types.number,
    options: types.optional(types.array(CartOption), []),
    payment_method_id: types.maybeNull(types.number),
    products: types.array(CartProduct),
    removed_products: types.array(CartProduct),
    selected_basket_id: types.maybeNull(types.string),
    total: types.maybeNull(Price),
    total_with_extra_costs: types.maybeNull(Price),
  })
  .views((self) => ({
    get getPhysicalProducts() {
      return self.products.filter(
        (product) => product.type === ProductTypeClass.PHYSICAL
      );
    },
    get ifRenderModal() {
      return self.new_product_info && self.new_product_info.quantity > 0;
    },
    get ifRenderErrorModal() {
      return (
        self.new_product_info &&
        self.new_product_info.quantity < 0 &&
        self.new_product_info.type === CartNewProductType.ARRAY
      );
    },
    getCartTotalValue(withTax) {
      return self.total && self.total.getPrice(withTax);
    },
  }));

export default Cart;
