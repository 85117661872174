import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';

import ProductSeason from '../../../models/ProductSeason';
import { modelOf } from '../../../prop-types';

@observer
class ProductSeasonInfo extends Component {
  render() {
    const { season } = this.props;
    let text = '';
    const startDate = DateTime.fromISO(season.start_date).toLocaleString(
      DateTime.DATETIME_SHORT
    );
    const endDate = DateTime.fromISO(season.end_date).toLocaleString(
      DateTime.DATETIME_SHORT
    );
    if (!season.hasStarted()) {
      text = (
        <FormattedMessage
          id="product.seasonStartsOn"
          defaultMessage="Product's sales start online on {startDate}."
          values={{ startDate }}
        />
      );
    } else if (season.hasStarted() && !season.hasEnded()) {
      text = (
        <FormattedMessage
          id="product.seasonIs"
          defaultMessage="Product sale period online is {startDate} - {endDate}."
          values={{ startDate, endDate }}
        />
      );
    } else {
      text = (
        <FormattedMessage
          id="product.seasonEndedOn"
          defaultMessage="Product's sales ended online on {endDate}."
          values={{ endDate }}
        />
      );
    }

    return (
      <div className={'ProductSeasonInfo'}>
        <span>{text}</span> <span>{season.description}</span>
      </div>
    );
  }
}

ProductSeasonInfo.propTypes = {
  season: modelOf(ProductSeason).isRequired
};

export default ProductSeasonInfo;
