import { types } from 'mobx-state-tree';

const ShippingSelectionFieldType = {
  DROPDOWN_MENU: 'DROPDOWN_MENU',
  TEXT_FIELD: 'TEXT_FIELD',
  RADIO_BUTTON: 'RADIO_BUTTON'
};

export default ShippingSelectionFieldType;

export const ShippingSelectionFieldTypeType = types.enumeration(
  'ShippingSelectionFieldTypeType',
  Object.values(ShippingSelectionFieldType)
);
