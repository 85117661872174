import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';

@observer
export class ProductImpressions extends Component {
  componentDidMount() {
    this.sendImpressions();
  }

  sendImpressions = () => {
    const { sendProductImpressions, listName, listId, products } = this.props;
    const productList = products.map((product, index) => ({
      product,
      position: index + 1,
    }));
    sendProductImpressions(listName, productList, listId);
  };

  render() {
    return null;
  }
}

ProductImpressions.propTypes = {
  products: PropTypes.arrayOf(modelOf(Product)),
  listId: PropTypes.string.isRequired,
  listName: PropTypes.string.isRequired,
  sendProductImpressions: PropTypes.func.isRequired,
};

export default inject((stores) => ({
  sendProductImpressions: (...args) =>
    stores.analytics.productImpressions(...args),
}))(ProductImpressions);
