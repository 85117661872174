import { types } from 'mobx-state-tree';

const ManufacturerIntegrationType = {
  LOADBEE: 'LOADBEE'
};

export default ManufacturerIntegrationType;

export const ManufacturerIntegrationTypeType = types.enumeration(
  'ManufacturerIntegrationType',
  Object.values(ManufacturerIntegrationType)
);
