import PropTypes from 'prop-types';
import React from 'react';

import FlagIcon from '../FlagIcon';

// key-value pairs ISO 639-1 => ISO 3166-1
const LOCALE_TO_COUNTRY_CODE_MAPPING = {
  en: 'gb',
  sv: 'se',
  da: 'dk',
  et: 'ee',
};

const countryCodeFromLocale = (locale) => {
  // If the country has no mapping set above, it is assumed that the
  // equivalent country code is the same as the locale.
  // (i.e. fi locale and fi country are a pair)
  return LOCALE_TO_COUNTRY_CODE_MAPPING[locale] || locale;
};

const LocaleFlagIcon = ({ locale }) => {
  return <FlagIcon countryCode={countryCodeFromLocale(locale)} />;
};

LocaleFlagIcon.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default LocaleFlagIcon;
