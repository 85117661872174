import React, { Component } from 'react';
import { DropdownItem } from 'reactstrap';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import Language from '../../../models/Language';
import RegionalSettingsLanguageDropdownItem from '../RegionalSettingsLanguageDropdownItem';
import DropdownSelect from '../DropdownSelect';
import globalTranslations from '../../../i18n/globalTranslations';

@observer
class RegionalSettingsLanguageDropdown extends Component {
  render() {
    return (
      <DropdownSelect
        label={
          <FormattedMessage
            {...globalTranslations.languageLabel}
            values={{
              language: (
                <RegionalSettingsLanguageDropdownItem
                  language={this.props.language}
                />
              )
            }}
          />
        }
      >
        {this.props.languages.map((language) => (
          <DropdownItem
            onClick={(e) => this.props.onSelect(language)}
            key={language.code}
          >
            <RegionalSettingsLanguageDropdownItem language={language} />
          </DropdownItem>
        ))}
      </DropdownSelect>
    );
  }
}

RegionalSettingsLanguageDropdown.propTypes = {
  language: modelOf(Language).isRequired,
  languages: MobxPropTypes.observableArrayOf(modelOf(Language)).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default RegionalSettingsLanguageDropdown;
