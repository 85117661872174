import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import ProductSlider from '../ProductSlider';
import SimpleProductList from '../../product-list/SimpleProductList';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class RecommendedProductsSlider extends Component {
  listId = 'recommended_products';
  listName = 'Recommended Products';

  render() {
    const { ids, configStore } = this.props;

    const searchParams = {
      ids,
      includeFilters: 0,
      includeChildProducts: '1',
      inStock: configStore.inStockProductsOnly ? '1' : '0',
      excludeContent: 1,
      optimizeResponse: 1,
    };

    return (
      <div className="RecommendedProductsSlider">
        <h3>
          <FormattedMessage {...globalTranslations.recommendedProductsTitle} />
        </h3>
        <SimpleProductList
          listId={this.listId}
          name={this.listName}
          searchParams={searchParams}
          listComponent={ProductSlider}
          allSections={true}
        />
      </div>
    );
  }
}

RecommendedProductsSlider.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default inject('configStore')(RecommendedProductsSlider);
