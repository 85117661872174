import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import Review from '../../review/Review';
import ReviewForm from '../../review/ReviewForm';
import globalTranslations from '../../../i18n/globalTranslations';
import ConfigStore from '../../../store/ConfigStore';
import AccountStore from '../../../store/AccountStore';
import AccountModalTab from '../../../types/AccountModalTab';
import AccountModalLink from '../../account/AccountModalLink';
import ReviewAggregation from '../../review/ReviewAggregation';
import Product from '../../../models/Product';
import ContentForState from '../../loader/ContentForState';
import ScrollableAnchor from '../../anchor/ScrollableAnchor';
import CouponStore from '../../../store/CouponStore';
import CouponText from '../../coupon/CouponText';
import CouponExpiry from '../../coupon/CouponExpiry';
import RequestState from '../../../types/RequestState';
import { scrollToElementById } from '../../../util/dom';

const DEFAULT_NUMBER_OF_REVIEWS_SHOWN = 6;

@observer
export class ProductReviews extends Component {
  state = {
    showAll: false,
  };

  constructor(props) {
    super(props);
    this.maybeLoadReviews();
  }

  componentDidUpdate() {
    this.maybeLoadReviews();
  }

  maybeLoadReviews = () => {
    const { product } = this.props;
    if (product.reviewsState === RequestState.NONE) {
      product.loadReviews().catch((e) => {
        if (e.response && e.response.status !== 404) {
          console.error(e);
        }
      });
    }
  };

  renderMoreReviewsButton = () => {
    const toggle = () => {
      this.setState({
        showAll: !this.state.showAll,
      });
    };

    if (this.props.product.reviews.length <= DEFAULT_NUMBER_OF_REVIEWS_SHOWN) {
      return null;
    }

    return (
      <Button
        color="secondary"
        onClick={toggle}
        className="ProductReviews__more-reviews-button"
      >
        {this.state.showAll ? (
          <FormattedMessage
            id="review.showDefaultTitle"
            defaultMessage="Show default number of reviews"
          />
        ) : (
          <FormattedMessage
            id="review.showAllTitle"
            defaultMessage="Show all reviews ({count})"
            values={{
              count: this.props.product.reviews.length,
            }}
          />
        )}
      </Button>
    );
  };

  renderCouponPromoBox = () => {
    const { couponStore } = this.props;
    const coupon = couponStore.reviewCoupon;

    if (!coupon) {
      return null;
    }

    return (
      <div className="ProductReviews__coupon-promo">
        <div className="ProductReviews__coupon-promo-title">
          <FormattedMessage
            id="review.reviewCouponTitle"
            defaultMessage="Review this product to receive a discount coupon!"
          />{' '}
          <span className="ProductReviews__coupon-promo-title-highlight">
            <CouponText coupon={coupon} />
          </span>
        </div>
        {coupon.minimum_order && (
          <FormattedMessage
            {...globalTranslations.couponMinimumOrderSentence}
            values={{
              minimumOrder: coupon.minimum_order + ' €',
            }}
          />
        )}{' '}
        {coupon.end_date && <CouponExpiry date={coupon.end_date} />}
      </div>
    );
  };

  render() {
    const { product, configStore, accountStore, couponStore } = this.props;
    const { reviews } = product;

    const shownReviews = this.state.showAll
      ? reviews
      : reviews.slice(0, DEFAULT_NUMBER_OF_REVIEWS_SHOWN);

    const coupon = couponStore.reviewCoupon;

    const formOrLogin =
      configStore.reviews.allowedForVisitors || accountStore.loggedIn ? (
        <ReviewForm couponsEnabled={!!coupon} product={product} />
      ) : (
        <AccountModalLink tab={AccountModalTab.LOGIN}>
          <Button tag="span" color="primary">
            <FormattedMessage {...globalTranslations.writeNewReview} />
          </Button>
        </AccountModalLink>
      );

    const isWebStore = configStore.siteConfig.isWebStore;

    return (
      <div className="ProductReviews">
        <ContentForState
          state={product.reviewsState}
          forLoaded={() => (
            <Fragment>
              {reviews.length > 0 && (
                <Row>
                  <Col md="6">
                    <ReviewAggregation reviews={reviews} />
                  </Col>
                  <Col md="6">
                    {this.renderCouponPromoBox()}
                    {isWebStore && (
                      <Button
                        color="primary"
                        onClick={() => scrollToElementById('newReviewForm')}
                      >
                        <FormattedMessage
                          {...globalTranslations.writeNewReview}
                        />
                      </Button>
                    )}
                    {this.renderMoreReviewsButton()}
                  </Col>
                </Row>
              )}
              <Row>
                {shownReviews.map((review) => (
                  <Col xs="12" lg="6" key={review.id}>
                    <Review review={review} />
                  </Col>
                ))}
              </Row>
              {this.renderMoreReviewsButton()}

              {isWebStore && (
                <div className="ProductReviews__new-review">
                  <ScrollableAnchor id="newReviewForm" offset={50} />
                  <h3>
                    <FormattedMessage
                      id="review.haveYouBoughtSentence"
                      defaultMessage="Have you purchased this product before?"
                    />
                  </h3>
                  {this.renderCouponPromoBox()}
                  <div>{formOrLogin}</div>
                </div>
              )}
            </Fragment>
          )}
        />
      </div>
    );
  }
}

ProductReviews.propTypes = {
  product: modelOf(Product).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  accountStore: modelOf(AccountStore).isRequired,
  couponStore: modelOf(CouponStore).isRequired,
};

export default inject(
  'configStore',
  'accountStore',
  'couponStore'
)(ProductReviews);
