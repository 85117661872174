import { types } from 'mobx-state-tree';

const FieldType = {
  NORMAL: 'NORMAL',
  FILE: 'FILE'
};

export default FieldType;

export const FieldTypeType = types.enumeration(
  'FieldTypeType',
  Object.values(FieldType)
);
