import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ProductAdditionalTabText extends Component {
  render() {
    let { additionalTabHtml } = this.props;
    return (
      <div
        className="ProductAdditionalTabText"
        dangerouslySetInnerHTML={{ __html: additionalTabHtml }}
      />
    );
  }
}

ProductAdditionalTabText.propTypes = {
  additionalTabHtml: PropTypes.string.isRequired
};

export default ProductAdditionalTabText;
