import { types, getEnv, getRoot, flow } from 'mobx-state-tree';
import axios, { CancelToken } from 'axios';

import RequestState, { RequestStateType } from '../types/RequestState';
import { stringify } from '../util/queryString';
import ProductSortOrderBy from '../types/ProductSortOrderBy';
import SortOder from '../types/SortOrder';

const SuggestionStore = types
  .model('SuggestionStore', {
    state: types.optional(RequestStateType, RequestState.LOADED)
  })
  .actions((self) => {
    let source;
    let env = getEnv(self);
    let suggestionResultsCache = [];
    let popularSearches = [];
    let popularSearchesLoaded = false;

    const addToCache = (text, products, suggestions, total) => {
      suggestionResultsCache.unshift({
        text,
        products,
        suggestions,
        total
      });
      suggestionResultsCache = suggestionResultsCache.slice(0, 20);
    };

    const findFromCache = (text) => {
      return suggestionResultsCache.find((result) => result.text === text);
    };

    const ifShoppingCenter = () =>
      getRoot(self).configStore.siteConfig.isShoppingCenter;

    const baseApi = (endpoint) =>
      ifShoppingCenter() ? `shopping-center/${endpoint}` : `${endpoint}`;

    return {
      suggest: flow(function* suggest(text, count = 3) {
        const { configStore } = getRoot(self);
        const inStock = configStore.inStockProductsOnly ? '1' : '0';
        const cacheResult = findFromCache(text);
        if (cacheResult && !ifShoppingCenter()) {
          return {
            suggestions: cacheResult.suggestions,
            products: cacheResult.products,
            total: cacheResult.total
          };
        }

        self.state = RequestState.LOADING;

        // If we already have a query running, cancel it.
        if (source) {
          source.cancel();
        }
        source = CancelToken.source();

        const defaultSection = getRoot(self).configStore.defaultSection;

        if (count <= 0) {
          try {
            const suggestions = yield env.apiWrapper.request(
              `${baseApi('auto-complete')}?${stringify({ q: text })}`,
              { cancelToken: source.token },
              { active_section: defaultSection }
            );

            addToCache(text, [], suggestions, 0);
            self.state = RequestState.LOADED;

            return {
              suggestions,
              products: [],
              total: 0
            };
          } catch (e) {
            if (!axios.isCancel(e)) {
              self.state = RequestState.ERROR;
            }
            throw e;
          }
        } else {
          try {
            const [productsResult, suggestions] = yield Promise.all([
              env.apiWrapper.request(
                `${baseApi('products')}?${stringify({
                  text: text,
                  inStock: inStock,
                  resultsPerPage: count,
                  includeFilters: 0,
                  orderBy: ProductSortOrderBy.RELEVANCE,
                  order: SortOder.DESC
                })}`,
                { cancelToken: source.token },
                { active_section: defaultSection }
              ),
              env.apiWrapper.request(
                `${baseApi('auto-complete')}?${stringify({ q: text })}`,
                { cancelToken: source.token },
                { active_section: defaultSection }
              )
            ]);
            const { productStore } = getRoot(self);
            const total = productsResult.total;
            const products = productsResult.data.map((product) => {
              productStore.updateProduct(product);
              return productStore.products.get(product.id);
            });
            addToCache(text, products, suggestions, total);

            self.state = RequestState.LOADED;
            return {
              suggestions,
              products,
              total
            };
          } catch (e) {
            if (!axios.isCancel(e)) {
              self.state = RequestState.ERROR;
            }
            throw e;
          }
        }
      }),
      getPopularSearches: flow(function* getPopularSearches() {
        if (popularSearchesLoaded) {
          return popularSearches;
        }

        self.state = RequestState.LOADING;

        try {
          const popularSearchesResult = yield Promise.resolve(
            env.apiWrapper.request(
              `popular-searches?${stringify({
                limit: 10
              })}`
            )
          );

          popularSearches = popularSearchesResult;
          popularSearchesLoaded = true;
          self.state = RequestState.LOADED;

          return popularSearches;
        } catch (e) {
          self.state = RequestState.ERROR;
          throw e;
        }
      })
    };
  });

export default SuggestionStore;
