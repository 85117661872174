import { types, getEnv, flow } from 'mobx-state-tree';

import RequestState, { RequestStateType } from '../types/RequestState';
import StatefulStore from '../models/StatefulStore';
import Script from '../models/Script';

const ScriptStore = StatefulStore.named()
  .props({
    scripts: types.optional(types.array(Script), []),
    scriptsState: types.optional(types.map(RequestStateType), {})
  })
  .actions((self) => {
    return {
      setup: (scripts) => {
        self.scripts = scripts;

        self.scripts.map((script) =>
          self.scriptsState.set(script.id, RequestState.NONE)
        );
      },
      getScripts: ({ pageType, location, status }) => {
        return self.scripts.filter((script) => {
          const ifScriptFound =
            script.location === location &&
            script.status === status &&
            script.pages.find((page) => page === pageType);

          if (self.scriptsState.get(script.id) === RequestState.LOADED) {
            return false;
          }

          if (ifScriptFound && !script.execute_on_page_view) {
            self.scriptsState.set(script.id, RequestState.LOADED);
          }

          return ifScriptFound;
        });
      },
      loadScripts: flow(function* loadScripts(pageType) {
        if (self.scriptsState.get(pageType) === RequestState.LOADED) {
          return;
        }

        self.scriptsState.set(pageType, RequestState.LOADING);
        self.setLoading(true);

        try {
          const scripts = yield getEnv(self).apiWrapper.request(`scripts`, {
            params: {
              pageType
            }
          });

          self.scripts.set(pageType, scripts);
          self.scriptsState.set(pageType, RequestState.LOADED);
          self.setLoading(false);
        } catch (e) {
          self.scriptsState.set(pageType, RequestState.ERROR);
          self.setError(e);
          throw e;
        }
      })
    };
  })
  .views((self) => {
    return {
      getScript: (pageType) => {
        return self.scripts.find(pageType);
      },
      getScriptState: (pageType) => {
        return self.scriptsState.get(pageType);
      }
    };
  });

export default ScriptStore;
