import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import ProductImageModel from '../../../models/ProductImage';
import ProductImage from '../ProductImage';

const ProductImageThumbnail = ({
  product,
  productImage,
  activeIndex,
  imageIndex,
  onMainImageIndexUpdate,
}) => {
  const onClickHandler = () => {
    if (onMainImageIndexUpdate) {
      onMainImageIndexUpdate(imageIndex);
    }

    return null;
  };

  const getProduct = () => {
    if (productImage) {
      return product.getActualProduct(productImage.product_id);
    }
    return product;
  };

  return (
    <div
      className={classNames(
        'ProductImageThumbnail',
        'ProductImages__thumbnail-slide'
      )}
      onClick={onClickHandler}
    >
      <div
        className={classNames('ProductImages__thumbnail', {
          'ProductImages__thumbnail--active': imageIndex === activeIndex,
        })}
      >
        <ProductImage
          product={getProduct()}
          productImage={productImage}
          size="small"
          lazyLoading={false}
          schema
        />
      </div>
    </div>
  );
};

ProductImageThumbnail.propTypes = {
  // Allows Product, ChildProduct and OrderProduct.
  product: PropTypes.object.isRequired,
  imageIndex: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
  productImage: modelOf(ProductImageModel),
};

export default observer(ProductImageThumbnail);
