import { types, getEnv } from 'mobx-state-tree';
import { keyBy } from 'lodash';

import StatefulStore from '../models/StatefulStore';
import Coupon from '../models/Coupon';
import CouponActivationType from '../types/CouponActivationType';

const CouponStore = StatefulStore.named('CouponStore')
  .props({
    coupons: types.optional(types.map(Coupon), {}),
  })
  .views((self) => ({
    get couponsArray() {
      return Array.from(self.coupons, ([key, value]) => value);
    },
    get timerCoupons() {
      return self.couponsArray.filter(
        (coupon) => coupon.activation_type === CouponActivationType.VISIT_TIME
      );
    },
    get loginCoupon() {
      return self.firstCouponOfActivationType(CouponActivationType.LOGIN);
    },
    get registrationCoupon() {
      return self.firstCouponOfActivationType(
        CouponActivationType.REGISTRATION
      );
    },
    get newsletterCoupon() {
      return self.firstCouponOfActivationType(
        CouponActivationType.NEWSLETTER_SUBSCRIPTION
      );
    },
    get reviewCoupon() {
      return self.firstCouponOfActivationType(
        CouponActivationType.PRODUCT_REVIEW
      );
    },
    firstCouponOfActivationType(type) {
      return self.couponsArray.find(
        (coupon) => coupon.activation_type === type
      );
    },
  }))
  .actions((self) => {
    return {
      setup: function (coupons) {
        self.coupons = keyBy(coupons, 'id');
        self.setLoading(false);
      },
      activateCoupon: (id) =>
        getEnv(self).apiWrapper.apiAxios().post(`coupons/${id}/activate`),
      sendCoupon: (email, couponCode) =>
        getEnv(self)
          .apiWrapper.apiAxios()
          .post(`coupons/send`, { email, couponCode }),
      setCoupon: (couponCode) =>
        getEnv(self).apiWrapper.apiAxios().post(`coupons/set`, { couponCode }),
      retrieveCoupon: (couponId, activationId, email) =>
        getEnv(self)
          .apiWrapper.apiAxios()
          .post(`coupons/retrieve`, { couponId, activationId, email }),
    };
  });

export default CouponStore;
