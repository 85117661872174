import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import NavigationEntity from '../../../types/NavigationEntity';
import NavigationFirstLevelItem from '../NavigationFirstLevelItem';
import NavigationMegaMenuTitle from '../NavigationMegaMenuTitle';
import MaybeExternalLink from '../../common/MaybeExternalLink';
import CustomNavigationLinkLocationType from '../../../types/CustomNavigationLinkLocation';

@observer
export class NavigationFirstLevelCategory extends Component {
  getFirstLevelCustomNavigationItem = (customNavLink) => {
    const { onMouseOver, onClick, hasManufacturers } = this.props;

    return (
      <Fragment key={customNavLink.id}>
        {this.getSubNavCustomLinks(
          customNavLink,
          CustomNavigationLinkLocationType.ABOVE
        )}
        <NavigationFirstLevelItem
          key={customNavLink.id}
          className={classNames('NavigationMegaMenu__first-level-item')}
          hasManufacturers={hasManufacturers}
        >
          <MaybeExternalLink
            to={customNavLink.path}
            onClick={onClick}
            onMouseOver={onMouseOver}
            type={customNavLink.link_type}
          >
            <NavigationMegaMenuTitle entity={customNavLink} />
          </MaybeExternalLink>
        </NavigationFirstLevelItem>
        {this.getSubNavCustomLinks(
          customNavLink,
          CustomNavigationLinkLocationType.BELOW
        )}
      </Fragment>
    );
  };

  getSubNavCustomLinks = (customNavLink, location) => {
    const { onSubEntityMouseOver, onClick, hasManufacturers } = this.props;

    return (
      customNavLink.customNavLinks &&
      customNavLink.customNavLinks.map(
        (subCustomNavLink) =>
          subCustomNavLink.location === location &&
          subCustomNavLink.parent_sibling_id === customNavLink.id && (
            <Fragment key={subCustomNavLink.id}>
              {this.getSubNavCustomLinks(
                subCustomNavLink,
                CustomNavigationLinkLocationType.ABOVE
              )}
              <NavigationFirstLevelItem
                key={subCustomNavLink.id}
                className="NavigationMegaMenu__first-level-item"
                hasManufacturers={hasManufacturers}
              >
                <MaybeExternalLink
                  to={subCustomNavLink.path}
                  onClick={onClick}
                  onMouseOver={onSubEntityMouseOver}
                  type={subCustomNavLink.link_type}
                >
                  <NavigationMegaMenuTitle entity={subCustomNavLink} />
                </MaybeExternalLink>
              </NavigationFirstLevelItem>
              {this.getSubNavCustomLinks(
                subCustomNavLink,
                CustomNavigationLinkLocationType.BELOW
              )}
            </Fragment>
          )
      )
    );
  };

  render() {
    const {
      children,
      entity,
      onMouseOver,
      onClick,
      entityIndex,
      hasManufacturers
    } = this.props;
    const { customNavLinks } = entity;

    return (
      <Fragment>
        {customNavLinks &&
          customNavLinks.map(
            (customNavLink) =>
              customNavLink.location ===
                CustomNavigationLinkLocationType.ABOVE &&
              customNavLink.parent_sibling_id === entity.id &&
              this.getFirstLevelCustomNavigationItem(customNavLink)
          )}
        <NavigationFirstLevelItem
          key={entity.id}
          className={classNames('NavigationMegaMenu__first-level-item', {
            'NavigationMegaMenu__first-level-item--open': entity.active
          })}
          hasManufacturers={hasManufacturers}
        >
          <MaybeExternalLink
            to={entity.path}
            onClick={onClick}
            onMouseOver={onMouseOver}
            type={entity.type}
          >
            <NavigationMegaMenuTitle
              entity={entity}
              entityIndex={entityIndex}
            />
          </MaybeExternalLink>
          {children}
        </NavigationFirstLevelItem>
        {customNavLinks &&
          customNavLinks.map(
            (customNavLink) =>
              customNavLink.location ===
                CustomNavigationLinkLocationType.BELOW &&
              customNavLink.parent_sibling_id === entity.id &&
              this.getFirstLevelCustomNavigationItem(customNavLink)
          )}
      </Fragment>
    );
  }
}

NavigationFirstLevelCategory.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  entity: NavigationEntity,
  entityIndex: PropTypes.number,
  onMouseOver: PropTypes.func,
  onSubEntityMouseOver: PropTypes.func,
  onClick: PropTypes.func,
  hasManufacturers: PropTypes.bool
};

export default inject('configStore')(NavigationFirstLevelCategory);
