import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { createForm } from '../../../models/Form';
import Field from '../../form/Field';
import globalTranslations from '../../../i18n/globalTranslations';
import { notBlank } from '../../../util/formValidators';
import { createFormField } from '../../../models/FormField';
import FieldError from '../../form/FieldError';

@observer
export class LoginForm extends Component {
  form = null;

  constructor(props) {
    super(props);
    this.form = createForm({
      username: createFormField({}, [
        notBlank(
          <FormattedMessage {...globalTranslations.emailOrUsernameTitle} />
        ),
      ]),
      password: createFormField({}, [
        notBlank(<FormattedMessage {...globalTranslations.passwordTitle} />),
      ]),
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.form.validate();
    if (this.form.valid && this.props.onSubmit) {
      this.props.onSubmit(this.form.getFieldValues());
    }
  };

  render() {
    return (
      <Form className="LoginForm" noValidate onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for="LoginForm__username">
            <FormattedMessage {...globalTranslations.emailOrUsernameTitle} />
          </Label>
          <Field
            component={Input}
            field={this.form.fields.get('username')}
            type="text"
            name="username"
            id="LoginForm__username"
            autoComplete="username"
            autoCorrect="off"
            autoCapitalize="none"
          />
          <FieldError field={this.form.fields.get('username')} />
        </FormGroup>
        <FormGroup>
          <Label for="LoginForm__password">
            <FormattedMessage {...globalTranslations.passwordTitle} />
          </Label>
          <Field
            component={Input}
            field={this.form.fields.get('password')}
            type="password"
            name="password"
            id="LoginForm__password"
            autoComplete="current-password"
          />
          <FieldError field={this.form.fields.get('password')} />
        </FormGroup>
        <Button
          color="primary"
          block
          disabled={this.form.valid === false || this.props.disableSubmit}
        >
          <FormattedMessage {...globalTranslations.logInSentence} />
        </Button>
      </Form>
    );
  }
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool,
};

export default LoginForm;
