import { getParent, getType, types } from 'mobx-state-tree';

import { LinkTypeType } from '../types/LinkType';

const Menu = types
  .model('Menu', {
    accessible_text: types.maybeNull(types.string),
    children: types.maybeNull(types.late(() => types.array(Menu))),
    id: types.identifier,
    image: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    type: LinkTypeType,
    url: types.string,
  })
  .views((self) => {
    return {
      get parent() {
        // First parent is the parent Menu's children array, the second the Menu itself.
        // Find such a parent that also has a URL, i.e. is not one of the top level dropdowns.
        // This way we will find the actual active path that can be used in the side menu navigation.
        // (Menus are used instead of info pages, since there is no guarantee we have the parent info page
        // loaded to the store at all times, which means we don't know the full parent_id chain either.)
        const parent = getParent(getParent(self));
        if (parent && parent.url && getType(parent) === Menu) {
          return parent;
        }

        return null;
      },
      get ancestors() {
        const ancestors = [];
        let currentMenu = self;
        while (currentMenu.parent) {
          const parent = currentMenu.parent;
          ancestors.push(parent);
          currentMenu = parent;
        }

        ancestors.reverse();
        return ancestors;
      },
    };
  });

export default Menu;
