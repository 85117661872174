import { types } from 'mobx-state-tree';

import Modal from './Modal';
import MobileNavigationTab, {
  MobileNavigationTabType,
} from '../types/MobileNavigationTab';
import { blockScroll, unblockScroll } from '../util/dom';

const MobileNavigation = Modal.named('MobileNavigation')
  .props({
    activeTab: types.optional(
      MobileNavigationTabType,
      MobileNavigationTab.PRODUCTS
    ),
  })
  .actions((self) => {
    return {
      selectTab(tab) {
        self.activeTab = tab;
      },
      close() {
        self.isOpen = false;
        unblockScroll();
      },
      open() {
        self.isOpen = true;
        blockScroll();
      },
    };
  });

export default MobileNavigation;
