import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import Currency from '../../../models/Currency';

@observer
class CurrencyLabel extends Component {
  render() {
    const { currency } = this.props;
    return (
      <span className="CurrencyLabel">
        <span
          className="CurrencyLabel__symbol"
          dangerouslySetInnerHTML={{ __html: currency.symbol }}
        />{' '}
        {currency.code}
      </span>
    );
  }
}

CurrencyLabel.propTypes = {
  currency: modelOf(Currency).isRequired
};

export default CurrencyLabel;
