import React, { Component } from 'react';
import { Col } from 'reactstrap';

/**
 * This is a custom column component that allows us to use an "xxl" breakpoint.
 * Cf. https://github.com/reactstrap/reactstrap/issues/297#issuecomment-273556116
 */
class CustomCol extends Component {
  render() {
    return (
      <Col
        widths={['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']}
        {...this.props}
      />
    );
  }
}

export default CustomCol;
