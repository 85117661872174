import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import { PropertyImageLabel } from '../../../models/PropertyImage';

@observer
class ProductFeatureImage extends Component {
  defaultBackground = '#d6dadb';
  defaultFontColor = '#333';

  getLabelComponent = () => {
    const { label, label_background, label_fontcolor } = this.props;

    if (!label) {
      return;
    }

    const styleObject = {};
    styleObject.backgroundColor = label_background
      ? label_background
      : this.defaultBackground;

    styleObject.color = label_fontcolor
      ? label_fontcolor
      : this.defaultFontColor;

    const label_value = label.label_value ? `: ${label.label_value}` : '';

    return (
      <div className="ProductFeatureImage__label" style={styleObject}>
        {label.label_text + label_value}
      </div>
    );
  };

  getImageComponent = () => {
    const { image } = this.props;

    if (!image) {
      return;
    }

    return (
      <img
        className="ProductFeatureImage__image"
        src={image}
        alt={''}
        loading="lazy"
      />
    );
  };

  render() {
    const { image, label, display_style } = this.props;

    if (!image && !label) {
      return null;
    }

    return (
      <div className={`ProductFeatureImage ${display_style}`}>
        {this.getImageComponent()}
        {this.getLabelComponent()}
      </div>
    );
  }
}

ProductFeatureImage.propTypes = {
  image: PropTypes.string,
  label_background: PropTypes.string,
  label: modelOf(PropertyImageLabel)
};

export default ProductFeatureImage;
