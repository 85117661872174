import { types } from 'mobx-state-tree';

import StatefulStore from '../models/StatefulStore';
import CustomNavigationLink from '../models/CustomNavigationLink';
import CustomNavigationLinkParentType from '../types/CustomNavigationLinkParentType';

const CustomNavigationLinkStore = StatefulStore.named(
  'CustomNavigationLinkStore'
)
  .props({
    customNavigationLinks: types.optional(
      types.array(CustomNavigationLink),
      []
    ),
  })
  .views((self) => {
    return {
      filterByCategoryId(id) {
        return self.customNavigationLinks.filter(
          (link) =>
            link.tree_type === CustomNavigationLinkParentType.CATEGORY &&
            link.parent_sibling_id === id
        );
      },
      filterBySectionId(id) {
        return self.customNavigationLinks.filter(
          (link) =>
            link.tree_type === CustomNavigationLinkParentType.SECTION &&
            link.parent_sibling_id === id
        );
      },
      findChildLinks(id) {
        return self.customNavigationLinks.filter(
          (link) =>
            link.tree_type ===
              CustomNavigationLinkParentType.CUSTOM_NAVIGATION_LINK &&
            link.parent_sibling_id === id
        );
      },
      getNavigationEntities() {
        if (self.customNavigationLinks.length) {
          return self.customNavigationLinks.filter(
            (navigationLink) => navigationLink.page_id
          );
        }

        return [];
      },
      getNavigationEntitiesByPosition(position) {
        if (self.customNavigationLinks.length) {
          return self.customNavigationLinks.filter(
            (navigationLink) =>
              navigationLink.page_id && navigationLink.position === position
          );
        }

        return [];
      },
    };
  })
  .actions((self) => {
    const setup = (customNavigationLinks) => {
      self.customNavigationLinks = customNavigationLinks;
      self.setLoading(false);
    };
    return {
      setup,
    };
  });
export default CustomNavigationLinkStore;
