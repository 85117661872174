import React from 'react';
import { inject, observer } from 'mobx-react';
import { head } from 'lodash';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import ProductImage from '../ProductImage';
import ProductReviewWidget from '../ProductReviewWidget';
import PriceWrapper from '../PriceWrapper';
import ConfigStore from '../../../store/ConfigStore';
import ProductDescription from '../ProductDescription';

const ProductQuickSearchResult = ({
  configStore,
  product,
  hideDescription,
}) => {
  const productImage = head(product.images);

  return (
    <article className="ProductQuickSearchResult">
      <div className="ProductQuickSearchResult__image">
        <ProductImage
          product={product}
          size="small"
          productImage={productImage}
          lazyLoading={false}
        />
      </div>
      <div>
        <h2>{product.multiproduct_title || product.name}</h2>
        {!hideDescription && configStore.productCard.showDescription && (
          <ProductDescription
            className="ProductQuickSearchDescription"
            product={product}
          />
        )}
        <PriceWrapper product={product} />
        <ProductReviewWidget product={product} compact />
      </div>
    </article>
  );
};

ProductQuickSearchResult.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  product: modelOf(Product).isRequired,
  hideDescription: PropTypes.bool,
};

export default inject('configStore')(observer(ProductQuickSearchResult));
