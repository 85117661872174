import { types } from 'mobx-state-tree';

const SectionStatusType = {
  ACTIVE: 'ACTIVE',
  TEST_MODE: 'TEST_MODE',
  NOT_IN_USE: 'NOT_IN_USE'
};

export default SectionStatusType;

export const SectionStatusTypeType = types.enumeration(
  'SectionStatusType',
  Object.values(SectionStatusType)
);
