import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import RouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router';

import { modelOf } from '../../../prop-types';
import ProductStore from '../../../store/ProductStore';

/**
 * This component simply watches for location changes and stores them.
 */
@observer
export class StoreGarbageCollector extends Component {
  unlisten = null;

  componentDidMount() {
    const { history, productStore } = this.props;
    this.unlisten = history.listen((location, action) => {
      if (action !== 'REPLACE') {
        productStore.gcProductQueries();
        productStore.gcProducts();
      }
    });
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  render() {
    return null;
  }
}

StoreGarbageCollector.propTypes = {
  history: RouterPropTypes.history.isRequired,
  productStore: modelOf(ProductStore).isRequired,
};

export default withRouter(inject('productStore')(StoreGarbageCollector));
