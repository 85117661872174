import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

import GenericMeta from '../GenericMeta';

export class SEODescription extends Component {
  outputTitle = (description) => {
    return (
      <Fragment>
        <Helmet>
          <meta name="description" content={description} />
        </Helmet>
        <GenericMeta
          name="twitter:description"
          property="og:description"
          content={description}
        />
      </Fragment>
    );
  };

  render() {
    const { content } = this.props;
    const shouldTranslate = content && content.id;
    return (
      <Fragment>
        {shouldTranslate ? (
          <FormattedMessage {...content}>{this.outputTitle}</FormattedMessage>
        ) : (
          this.outputTitle(content)
        )}
      </Fragment>
    );
  }
}

SEODescription.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};

export default SEODescription;
