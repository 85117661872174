import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import ProductStore from '../../../store/ProductStore';
import RequestState from '../../../types/RequestState';
import { paramsToQueryIdentifier } from '../../../util/query';
import ContentForState from '../../loader/ContentForState';
import ProductLink from '../../product/ProductLink';

const CampaignCodeProducts = ({ productIds, productStore }) => {
  useEffect(() => {
    loadProducts();
  }, []);

  const loadProducts = () => {
    if (getCurrentSearchState() !== RequestState.LOADED) {
      productStore.loadProducts(
        {
          ids: productIds,
        },
        true
      );
    }
  };

  const getQueryIdentifier = () => {
    return paramsToQueryIdentifier({
      ids: productIds,
    });
  };

  const getCurrentSearchState = () => {
    return productStore.productQueryStates.get(getQueryIdentifier());
  };

  const getProductLimitDescription = () => {
    return (
      <div className="CampaignCodeProducts__description">
        <FormattedMessage
          id="campaign.productLimitation"
          defaultMessage="Using this discount coupon, you will receive a discount for the following products:"
        />
      </div>
    );
  };

  const getProductList = () => {
    const productItems = productIds
      .map((productId) => {
        const product = productStore.products.get(productId);

        if (!product) {
          return null;
        }

        return (
          <li key={product.id}>
            <ProductLink requireCanonical product={product} />
          </li>
        );
      })
      .filter((item) => !!item);

    return (
      productItems && (
        <div className="CampaignCodeProducts">
          {getProductLimitDescription()}
          <div className="CampaignCodeProducts__list">
            <ul>{productItems}</ul>
          </div>
        </div>
      )
    );
  };

  return (
    <ContentForState
      state={getCurrentSearchState()}
      forLoaded={() => {
        return getProductList();
      }}
    />
  );
};

CampaignCodeProducts.propTypes = {
  productIds: PropTypes.array.isRequired,
  productStore: modelOf(ProductStore).isRequired,
};

export default inject('productStore')(observer(CampaignCodeProducts));
