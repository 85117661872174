import { useEffect } from 'react';

const useTopHeadScript = ({ url }) => {
  useEffect(() => {
    const elements = document.head.getElementsByTagName('meta');
    const script = document.createElement('script');
    script.src = url;

    document.head.insertBefore(script, elements[0]);

    return () => {
      document.head.removeChild(script);
    };
  }, [url]);
};

export default useTopHeadScript;
