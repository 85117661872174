import { types } from 'mobx-state-tree';
import { FormFieldIdType } from '../types/FormFieldId';
import { FormFieldTypeType } from '../types/FormFieldType';

const SelectOption = types.model('SelectOption', {
  text: types.string,
  value: types.number,
});

const CustomFormField = types.model('CustomFormField', {
  externalIndex: types.maybeNull(types.number),
  id: FormFieldIdType,
  max_length: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  options: types.array(SelectOption),
  required: types.boolean,
  type: FormFieldTypeType,
  value: types.maybeNull(
    types.union(
      types.string,
      types.number,
      types.array(types.string),
      types.array(types.number),
      types.boolean
    )
  ),
});

export default CustomFormField;
