import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu, Dropdown, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import Icon from '../Icon';
import { debounce } from 'lodash';

@observer
class NavigationDropdownSubmenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  debouncedHoverMenu = debounce((callback) => {
    if (callback) {
      callback();
    }
  }, 200);

  toggleMenu = () => this.setState({ open: !this.state.open });

  openMenu = () => this.setState({ open: true });

  closeMenu = (e) => {
    const { to } = this.props;
    const isClick = !!(e && e.type === 'click');

    if (isClick && (to.length === 0 || to === '#')) {
      return;
    }

    this.setState({
      open: false
    });
  };

  render() {
    const { children, onClick, labelText, to } = this.props;
    return (
      <Dropdown
        isOpen={this.state.open}
        toggle={this.toggleMenu}
        direction="right"
        className="NavigationDropdownSubmenu"
      >
        <div
          className="NavigationDropdownMenu__hover"
          onClick={this.closeMenu}
          onMouseOver={() => this.debouncedHoverMenu(() => this.openMenu())}
          onMouseOut={() => this.debouncedHoverMenu(() => this.closeMenu())}
        >
          <DropdownToggle
            className="NavigationDropdownSubmenu__label dropdown-item"
            onClick={onClick}
            tag={Link}
            to={to}
            data-toggle="dropdown"
            aria-expanded={this.state.open}
          >
            <span>{labelText}</span>
            <Icon name="angle-right" className="chevron" />
          </DropdownToggle>
          <DropdownMenu className="NavigationDropdownSubmenu_dropdown-menu">
            {children}
          </DropdownMenu>
        </div>
      </Dropdown>
    );
  }
}

NavigationDropdownSubmenu.propTypes = {
  labelText: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired
};

NavigationDropdownSubmenu.defaultProps = {
  labelText: {},
  onClick: () => {}
};

export default NavigationDropdownSubmenu;
