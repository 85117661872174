import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import Filter from '../../../models/Filter';
import Switch from '../../common/Switch';

@observer
class BooleanFilter extends Component {
  onChange = () => {
    const { filter, isSelected, onAccept } = this.props;
    onAccept(filter, !isSelected);
  };

  render() {
    const { filter, isSelected } = this.props;

    if (!filter) {
      return null;
    }

    return (
      <div className="BooleanFilter">
        <Switch checked={isSelected} onChange={this.onChange} size="xs">
          {filter.name}
        </Switch>
      </div>
    );
  }
}

BooleanFilter.propTypes = {
  filter: modelOf(Filter),
  isSelected: PropTypes.bool,
  onAccept: PropTypes.func.isRequired
};

export default BooleanFilter;
