import React from 'react';
import Spinner from '../Spinner';

const FallbackSpinner = () => {
  return (
    <div className="FallbackSpinner BlockSpinner">
      <Spinner />
    </div>
  );
};
export default FallbackSpinner;
