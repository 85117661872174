import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';

class SSRAwareLazyLoad extends Component {
  render() {
    const { children, ...props } = this.props;
    return window.isSSR ? children : <LazyLoad {...props}>{children}</LazyLoad>;
  }
}

export default SSRAwareLazyLoad;
