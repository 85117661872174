import React from 'react';
import PropTypes from 'prop-types';
import SortSelection, { createSortSelection } from '../../common/SortSelection';
import { inject, observer } from 'mobx-react';
import { defineMessages } from 'react-intl';

import ProductSortOrderBy from '../../../types/ProductSortOrderBy';
import SortOrder from '../../../types/SortOrder';
import ConfigStore from '../../../store/ConfigStore';
import ProductStore from '../../../store/ProductStore';
import { modelOf } from '../../../prop-types';

const sortNameMessages = defineMessages({
  sortDefault: {
    id: 'sort.default',
    defaultMessage: 'Default',
  },
  sortCheapest: {
    id: 'sort.cheapest',
    defaultMessage: 'Cheapest',
  },
  sortMostExpensive: {
    id: 'sort.mostExpensive',
    defaultMessage: 'Most expensive',
  },
  sortLatest: {
    id: 'sort.latest',
    defaultMessage: 'Latest',
  },
  sortMostPopular: {
    id: 'sort.mostPopular',
    defaultMessage: 'Most popular',
  },
  sortBestRated: {
    id: 'sort.bestRated',
    defaultMessage: 'Best rated',
  },
  sortBrand: {
    id: 'sort.brand',
    defaultMessage: 'Brand',
  },
  sortId: {
    id: 'sort.id',
    defaultMessage: 'Product number',
  },
  sortAlphabetical: {
    id: 'sort.alphabetical',
    defaultMessage: 'Alphabetical',
  },
  sortCategory: {
    id: 'sort.category',
    defaultMessage: 'Product category',
  },
  sortDiscount: {
    id: 'sort.discount',
    defaultMessage: 'Best discount',
  },
  sortInStockQuantity: {
    id: 'sort.inStockQuantity',
    defaultMessage: 'Most in stock',
  },
});

const sortSelections = [
  createSortSelection(sortNameMessages.sortDefault, null, null),
  createSortSelection(
    sortNameMessages.sortCheapest,
    ProductSortOrderBy.PRICE,
    SortOrder.ASC,
    1
  ),
  createSortSelection(
    sortNameMessages.sortMostExpensive,
    ProductSortOrderBy.PRICE,
    SortOrder.DESC,
    2
  ),
  createSortSelection(
    sortNameMessages.sortLatest,
    ProductSortOrderBy.DATE_ADDED,
    SortOrder.DESC,
    3
  ),
  createSortSelection(
    sortNameMessages.sortMostPopular,
    ProductSortOrderBy.SALES,
    SortOrder.DESC,
    4
  ),
  createSortSelection(
    sortNameMessages.sortBestRated,
    ProductSortOrderBy.REVIEWS_AVERAGE,
    SortOrder.DESC,
    5
  ),
  createSortSelection(
    sortNameMessages.sortBrand,
    ProductSortOrderBy.BRAND,
    SortOrder.ASC,
    6
  ),
  createSortSelection(
    sortNameMessages.sortId,
    ProductSortOrderBy.ID,
    SortOrder.ASC,
    7
  ),
  createSortSelection(
    sortNameMessages.sortAlphabetical,
    ProductSortOrderBy.NAME,
    SortOrder.ASC,
    8
  ),
  createSortSelection(
    sortNameMessages.sortCategory,
    ProductSortOrderBy.CATEGORY,
    SortOrder.ASC,
    9
  ),
  createSortSelection(
    sortNameMessages.sortDiscount,
    ProductSortOrderBy.DISCOUNT,
    SortOrder.DESC,
    10
  ),
  createSortSelection(
    sortNameMessages.sortInStockQuantity,
    ProductSortOrderBy.IN_STOCK_QUANTITY,
    SortOrder.DESC,
    11
  ),
];

export const ProductListSortSelection = ({
  configStore,
  productStore,
  onSelectSort,
  selectedSortKey,
  selectedSortOrder,
}) => {
  const filterActiveSortSelections = () => {
    const activeProductSortSelectors = productStore.activeProductSortList();
    const filteredActiveSortSelections = sortSelections.filter((ss) =>
      activeProductSortSelectors.find((sort) => sort.sortIndex === ss.sortIndex)
    );

    if (!filteredActiveSortSelections.length) {
      return;
    }

    filteredActiveSortSelections.splice(0, 0, sortSelections[0]);
    return filteredActiveSortSelections;
  };

  const filterPriceSortSelections = () => {
    if (!configStore.productList.priceSearchDisabled) {
      return activeSortSelections || sortSelections;
    }

    const priceIndexes = [1, 2, 10];

    const filteredActiveSortSelections = activeSortSelections
      ? activeSortSelections
      : sortSelections;

    return filteredActiveSortSelections.filter((ss) =>
      priceIndexes.every((priceIndex) => priceIndex !== ss.sortIndex)
    );
  };

  let activeSortSelections = filterActiveSortSelections();
  activeSortSelections = filterPriceSortSelections();

  return (
    <SortSelection
      className={'ProductListSortSelection'}
      sortSelections={activeSortSelections}
      onSelectSort={onSelectSort}
      selectedSortKey={selectedSortKey}
      selectedSortOrder={selectedSortOrder}
    />
  );
};

ProductListSortSelection.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  onSelectSort: PropTypes.func.isRequired,
  selectedSortKey: PropTypes.string,
  selectedSortOrder: PropTypes.string,
};

export default inject(
  'configStore',
  'productStore'
)(observer(ProductListSortSelection));
