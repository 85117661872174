import { types, getEnv, flow } from 'mobx-state-tree';

import PaymentModule from '../models/PaymentModule';
import StatefulStore from '../models/StatefulStore';
import PaymentWidgetQueryResult from '../models/PaymentWidgetQueryResult';
import RequestState, { RequestStateType } from '../types/RequestState';
import PaymentWidget from '../models/PaymentWidget';
import PaymentWidgetType from '../types/PaymentWidgetType';
import { stringify } from '../util/queryString';

const PaymentModuleStore = StatefulStore.named('PaymentModuleStore')
  .props({
    methods: types.array(PaymentModule),
    widgets: types.array(PaymentWidget),
    paymentWidgetQueryResults: types.map(PaymentWidgetQueryResult),
    paymentWidgetQueryStates: types.map(RequestStateType),
  })
  .actions((self) => {
    const setup = (paymentInfo) => {
      self.methods = paymentInfo.methods;
      self.widgets = paymentInfo.widgets;
    };

    const loadProductWidget = flow(function* loadProductWidget(params) {
      const identifier = stringify(params);
      // Skip the API call if we already have the data
      if (self.paymentWidgetQueryStates.get(identifier)) {
        return;
      }
      self.paymentWidgetQueryStates.set(identifier, RequestState.LOADING);

      try {
        const paymentWidget = yield getEnv(self).apiWrapper.request(
          `payment-info/product-widgets`,
          { params }
        );

        // Set current query results
        self.paymentWidgetQueryResults.set(identifier, {
          widgets: paymentWidget.map((widget) => widget),
        });
      } catch (e) {
        self.setError(e);
        self.paymentWidgetQueryStates.set(identifier, RequestState.ERROR);
        throw e;
      }

      self.paymentWidgetQueryStates.set(identifier, RequestState.LOADED);
    });

    return {
      setup,
      loadProductWidget,
    };
  })
  .views((self) => ({
    get isKlarnaEnabled() {
      return self.widgets.some(
        (widget) => widget.type === PaymentWidgetType.KLARNA
      );
    },
    get getKlarnaWidgets() {
      return (
        self.widgets &&
        self.widgets.length &&
        self.widgets.find((widget) => widget.type === PaymentWidgetType.KLARNA)
      );
    },
  }));

export default PaymentModuleStore;
