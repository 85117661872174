import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import { Button, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import ManufacturerStore from '../../../store/ManufacturerStore';
import SectionStore from '../../../store/SectionStore';
import Autosuggest from '../../common/Autosuggest';
import ManufacturerLink from '../../manufacturer/ManufacturerLink';
import ConfigStore from '../../../store/ConfigStore';
import RouteService from '../../../services/RouteService';
import Paths from '../../../types/Paths';
import Icon from '../../common/Icon';
import UIStore from '../../../store/UIStore';
import LayoutBoxSkeleton from '../../skeleton/LayoutBoxSkeleton';
import RequestState from '../../../types/RequestState';

const messages = defineMessages({
  'manufacturer.chooseManufacturer': {
    id: 'manufacturer.chooseManufacturerPlaceholder',
    defaultMessage: 'Choose brand...',
  },
});

const STYLES = {
  DROPDOWN: '0',
  LIST: '1',
};

const ManufacturerBox = ({
  configStore,
  manufacturerStore,
  routeService,
  sectionStore,
  uiStore,
  history,
  title,
  className,
  linkButtonClassName,
  intl,
  minManufacturersForDropdown,
  twoLayerMegaMenuEnabled,
  onLinkClick,
  onManufacturersUpdate,
}) => {
  const [value, setValue] = useState('');
  const [manufacturers, setManufacturers] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    maybeUpdateManufacturers();
  }, [manufacturerStore.hasManufacturers]);

  const maybeUpdateManufacturers = () => {
    if (!manufacturerStore.hasManufacturers) {
      return;
    }

    let manufacturers = manufacturerStore.manufacturersWithProducts;
    if (twoLayerMegaMenuEnabled) {
      manufacturers = getMegaMenuManufacturers(manufacturers);
    } else {
      manufacturers = getManufacturersByActiveSection(manufacturers);
    }

    setManufacturers(manufacturers);
    twoLayerMegaMenuEnabled && onManufacturersUpdates(manufacturers);
  };

  const getMegaMenuManufacturers = (manufacturers) => {
    if (
      configStore.activateSections &&
      configStore.manufacturerBox.limitByActiveSection &&
      uiStore.megaMenu.activeSection
    ) {
      return filterManufacturersByActiveSectionId();
    }

    return manufacturers;
  };

  const getManufacturersByActiveSection = (manufacturers) => {
    if (
      configStore.activateSections &&
      configStore.manufacturerBox.limitByActiveSection &&
      sectionStore.activeSection
    ) {
      return filterManufacturersByActiveSectionId();
    }

    return manufacturers;
  };

  const filterManufacturersByActiveSectionId = () => {
    return manufacturerStore.manufacturersArray.filter((manufacturer) =>
      manufacturer.belongsToSection(getActiveSectionId())
    );
  };

  const getActiveSectionId = () => {
    if (twoLayerMegaMenuEnabled) {
      return uiStore.megaMenu.activeSection.id;
    }

    return sectionStore.activeSection.id;
  };

  const onManufacturersUpdates = (manufacturers) => {
    onManufacturersUpdate && onManufacturersUpdate(manufacturers);
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const getManufacturers = (value) => {
    const searchValue = value.trim().toLowerCase();

    return searchValue === ''
      ? manufacturers
      : manufacturers.filter((manufacturer) =>
          manufacturer.name.toLowerCase().startsWith(searchValue)
        );
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getManufacturers(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions(getManufacturers(''));
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    history.push(routeService.getPath(suggestion.path));

    if (twoLayerMegaMenuEnabled) {
      onLinkClick();
    }
  };

  const renderManufacturerList = () => {
    if (twoLayerMegaMenuEnabled) {
      return renderMegaMenuList();
    }

    return renderList();
  };

  const renderList = () => {
    return (
      <ul className="ManufacturerBox__list">
        {manufacturers.map((manufacturer) => (
          <li key={manufacturer.id}>
            <ManufacturerLink manufacturer={manufacturer} />
          </li>
        ))}
      </ul>
    );
  };

  const renderMegaMenuList = () => {
    return (
      <Row className="ManufacturerBox__list-two-layer-megamenu">
        {manufacturers.map((manufacturer) => (
          <Col sm={12} key={manufacturer.id} onClick={onLinkClick}>
            <ManufacturerLink manufacturer={manufacturer} />
          </Col>
        ))}
      </Row>
    );
  };

  const renderDropdown = () => {
    const inputProps = {
      placeholder: intl.formatMessage(
        messages['manufacturer.chooseManufacturer']
      ),
      value,
      onChange,
      className: 'form-control',
    };

    return (
      <>
        <Autosuggest
          id="ManufacturerBox__Autosuggest"
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={(manufacturer) => manufacturer.id}
          shouldRenderSuggestions={() => true}
          renderSuggestion={(manufacturer) => <div>{manufacturer.name}</div>}
          inputProps={inputProps}
          renderInputComponent={renderInputComponent}
        />
        <div className="ManufacturerBox__all-link">{renderLinkButton()}</div>
      </>
    );
  };

  const renderLinkButton = () => {
    if (twoLayerMegaMenuEnabled) {
      return (
        <Link to={routeService.getPath(Paths.ManufacturerList)}>
          <Button
            className={linkButtonClassName}
            color="link"
            onClick={onLinkClick}
          >
            <FormattedMessage
              id="manufacturer.seeAllBrands"
              defaultMessage="See all brands"
            />
          </Button>
        </Link>
      );
    }

    return (
      <Link to={routeService.getPath(Paths.ManufacturerList)}>
        <FormattedMessage
          id="manufacturer.seeAllBrands"
          defaultMessage="See all brands"
        />
      </Link>
    );
  };

  const renderInputComponent = (inputProps) => {
    if (twoLayerMegaMenuEnabled) {
      return (
        <div className="ManufacturerBox__two-layer-megamenu-brand-dropdown">
          <input {...inputProps} value={value} />
          <Icon name="caret-down" />
        </div>
      );
    }

    return <input {...inputProps} value={value} />;
  };

  if (
    !manufacturerStore.manufacturersWithProducts ||
    !manufacturers ||
    manufacturers.length === 0
  ) {
    return manufacturerStore.state !== RequestState.LOADED ? (
      <LayoutBoxSkeleton />
    ) : null;
  }

  return (
    <div className={classNames('ManufacturerBox', className)}>
      <Card>
        <CardHeader>{title}</CardHeader>
        <CardBody>
          {manufacturers.length > minManufacturersForDropdown &&
          configStore.manufacturerBox.style === STYLES.DROPDOWN
            ? renderDropdown()
            : renderManufacturerList()}
        </CardBody>
      </Card>
    </div>
  );
};

ManufacturerBox.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  manufacturerStore: modelOf(ManufacturerStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  history: RouterPropTypes.history.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  linkButtonClassName: PropTypes.string,
  intl: intlShape.isRequired,
  minManufacturersForDropdown: PropTypes.number,
  twoLayerMegaMenuEnabled: PropTypes.bool,
  onLinkClick: PropTypes.func,
  onManufacturersUpdate: PropTypes.func,
};

ManufacturerBox.defaultProps = {
  minManufacturersForDropdown: 1,
  twoLayerMegaMenuEnabled: false,
};

export default inject(
  'configStore',
  'manufacturerStore',
  'routeService',
  'sectionStore',
  'uiStore'
)(withRouter(injectIntl(observer(ManufacturerBox))));
