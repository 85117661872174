import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import ContentForState from '../../loader/ContentForState';
import globalTranslations from '../../../i18n/globalTranslations';
import InfoPageStore from '../../../store/InfoPageStore';
import { modelOf } from '../../../prop-types';
import RouteService from '../../../services/RouteService';

@observer
export class InfoPageModal extends Component {
  componentDidMount() {
    const { infoPageStore, pageId } = this.props;
    const infoPage = this.getInfoPage();
    if (!infoPage || infoPage.content === null) {
      infoPageStore.loadPage(pageId).catch((e) => {
        console.error(e);
      });
    }
  }

  getInfoPage = () => {
    const { infoPageStore, pageId } = this.props;
    return infoPageStore.pages.get(pageId);
  };

  render() {
    const { infoPageStore, routeService, pageId, toggle, isOpen } = this.props;
    const infoPage = this.getInfoPage();

    return (
      <Modal
        className="InfoPageModal"
        size="lg"
        toggle={toggle}
        isOpen={isOpen}
      >
        <ModalHeader className="InfoPageModal__header" toggle={toggle}>
          {infoPage && (
            <NavLink to={routeService.getPath(infoPage.path)} onClick={toggle}>
              {infoPage.name}
            </NavLink>
          )}
        </ModalHeader>
        <ModalBody>
          <ContentForState
            state={infoPageStore.pageStates.get(pageId)}
            error={infoPageStore.lastError}
            forLoaded={() =>
              infoPage &&
              infoPage.content && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: infoPage.content
                  }}
                  className="InfoPageModal__contents"
                />
              )
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            <FormattedMessage {...globalTranslations.closeTitle} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

InfoPageModal.propTypes = {
  infoPageStore: modelOf(InfoPageStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  pageId: PropTypes.string.isRequired,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool
};

export default inject('infoPageStore', 'routeService')(InfoPageModal);
