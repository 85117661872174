import { types } from 'mobx-state-tree';

const ProductPropertyType = {
  COLOR: 'COLOR',
  OTHER: 'OTHER',
  SIZE: 'SIZE'
};

export default ProductPropertyType;

export const ProductPropertyTypeType = types.enumeration(
  'ProductProperty',
  Object.values(ProductPropertyType)
);
