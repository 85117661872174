import React from 'react';
import { inject, observer } from 'mobx-react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import Icon from '../../common/Icon';
import Product from '../../../models/Product';
import { modelOf } from '../../../prop-types';

const messages = defineMessages({
  downloadFile: {
    id: 'product.downloadFile',
    defaultMessage: 'Download'
  }
});

@observer
export class ProductFiles extends React.Component {
  getProductFileUrl = (name) => {
    const { configStore } = this.props;
    const isShoppingCenter = configStore.siteConfig.isShoppingCenter;

    return isShoppingCenter ? name : `/storage/product_files/${name}`;
  };

  render() {
    const { product, intl } = this.props;
    const downloadFileText = intl.formatMessage(messages.downloadFile);

    return (
      <div className="ProductFiles">
        {product.files.map((file, index) => (
          <div key={file.name} className="ProductFile">
            <a
              title={downloadFileText}
              className="ProductFile__link"
              target="_blank"
              rel="noopener"
              href={this.getProductFileUrl(file.name)}
            >
              <div className="ProductFile__name">{file.description}</div>
              <div className="ProductFile__download">
                <Icon className="ProductFile__icon" name="file" />{' '}
                <span className="ProductFile__download-text">
                  {downloadFileText}
                </span>
              </div>
            </a>
          </div>
        ))}
      </div>
    );
  }
}

ProductFiles.propTypes = {
  product: PropTypes.oneOfType([modelOf(Product), PropTypes.object]).isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(inject('configStore')(ProductFiles));
