import React, { Component } from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  Row,
  Container,
  Alert,
  Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import {
  FormattedMessage,
  injectIntl,
  intlShape,
  defineMessages,
} from 'react-intl';
import { keyBy, get, has } from 'lodash';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import ContentForState from '../../loader/ContentForState';
import ConfigStore from '../../../store/ConfigStore';
import StorageStore from '../../../store/StorageStore';
import RequestState from '../../../types/RequestState';
import { createForm } from '../../../models/Form';
import { createFormField } from '../../../models/FormField';
import { isEmail, minLength } from '../../../util/formValidators';
import globalTranslations from '../../../i18n/globalTranslations';
import ProductImage from '../ProductImage';
import QuantityFieldGroup from '../../common/QuantityFieldGroup';
import FormGroupField from '../../form/FormGroupField';
import ProductPrice from '../ProductPrice';
import CheckboxField from '../../form/CheckboxField';
import Price from '../Price';
import ReserveInStoreStore from '../../../store/ReserveInStoreStore';
import ApiWrapper from '../../../services/ApiWrapper';
import Icon from '../../common/Icon';
import ErrorHandler from '../../loader/ErrorHandler';
import { createErrorModel } from '../../../util/error';
import ProductClass from '../../../types/ProductClass';
import AccountStore from '../../../store/AccountStore';
import Storage from '../../../models/Storage';
import Analytics from '../../../analytics/Analytics';
import ProductListFilter from '../../product-list/ProductListFilter';
import ButtonLink from '../../common/ButtonLink';
import UIStore from '../../../store/UIStore';
import RecaptchaLegalBanner from '../../legal/RecaptchaLegalBanner';

const messages = defineMessages({
  storageFilterPlaceholder: {
    id: 'reserveInStore.storageFilterPlaceholder',
    defaultMessage: 'Post code or city',
  },
});
const MAX_STORAGES_SHOWN_BY_DEFAULT = 5;

const labels = {
  firstName: <FormattedMessage {...globalTranslations.firstNameTitle} />,
  lastName: <FormattedMessage {...globalTranslations.lastNameTitle} />,
  email: <FormattedMessage {...globalTranslations.emailTitle} />,
  phoneNumber: <FormattedMessage {...globalTranslations.telephoneTitle} />,
};

const ProductReserveInStoreModalPage = {
  STORAGE: 'STORAGE',
  PERSONAL_DETAILS: 'PERSONAL_DETAILS',
  CONFIRMATION: 'CONFIRMATION',
};

@observer
class ProductReserveInStoreModal extends Component {
  constructor(props) {
    super(props);
    const { reserveInStoreStore } = props;

    this.state = {
      requestState: RequestState.NONE,
      requestId: 0,
      storeFilterString: '',
      showAllStorages: false,
      error: null,
      reservationID: null,
    };

    this.form = null;
    this.formName = 'reserveInStoreModalForm';

    reserveInStoreStore.makeSureServicesLoaded();
    this.initializeForm();
  }

  componentDidUpdate = (oldProps) => {
    if (this.props.activeProductId !== oldProps.activeProductId) {
      this.loadStocks();
    }
  };

  componentWillUnmount() {
    this.initializeForm();
  }

  initializeForm = () => {
    const { configStore, accountStore } = this.props;

    this.form = createForm({
      quantity: createFormField({
        value: '1',
      }),
      serviceIds: createFormField({
        value: [],
      }),
      firstName: createFormField(
        {
          value: accountStore.loggedIn
            ? accountStore.account.personal_info.firstname
            : '',
        },
        [
          minLength(
            labels.firstName,
            configStore.formValidation.firstName.minLength
          ),
        ]
      ),
      lastName: createFormField(
        {
          value: accountStore.loggedIn
            ? accountStore.account.personal_info.lastname
            : '',
        },
        [
          minLength(
            labels.lastName,
            configStore.formValidation.lastName.minLength
          ),
        ]
      ),
      email: createFormField(
        {
          value: accountStore.loggedIn
            ? accountStore.account.personal_info.email
            : '',
        },
        [
          minLength(labels.email, configStore.formValidation.email.minLength),
          isEmail(labels.email),
        ]
      ),
      phoneNumber: createFormField(
        {
          value: accountStore.loggedIn
            ? accountStore.account.personal_info.telephone
            : '',
        },
        [
          minLength(
            labels.phoneNumber,
            configStore.formValidation.telephone.minLength
          ),
        ]
      ),
    });
  };

  field = (fieldName, props) => {
    const field = this.form.fields.get(fieldName);
    return (
      field.active && (
        <FormGroupField
          field={field}
          formName={this.formName}
          fieldName={fieldName}
          label={labels[fieldName]}
          {...props}
        />
      )
    );
  };

  checkbox = (fieldName, props) => {
    const field = this.form.fields.get(fieldName);
    return (
      field.active && (
        <CheckboxField
          field={field}
          formName={this.formName}
          fieldName={fieldName}
          label={labels[fieldName]}
          {...props}
        />
      )
    );
  };

  loadStocks = () => {
    const { product, activeProductId } = this.props;

    if (product.stockStates.get(activeProductId) !== RequestState.LOADED) {
      product.loadStocks(activeProductId);
    }
  };

  getMatchingStorages = () => {
    const { reserveInStoreStore, storageStore } = this.props;
    const { storeFilterString } = this.state;

    const { activeProductId, product } = reserveInStoreStore.modal;

    const minimumRequiredStock = Math.max(
      1,
      Number(this.form.fields.get('quantity').value)
    );

    const stocksByStorageId = keyBy(
      product.stocks
        .get(activeProductId)
        .filter((stock) => stock.free_quantity >= minimumRequiredStock),
      'storage_id'
    );

    const storageMatchesSearch = (storage) => {
      return (
        storage.postcode.includes(storeFilterString) ||
        storage.city.toLowerCase().includes(storeFilterString.toLowerCase())
      );
    };

    return storageStore.pickupStorages.filter((storage) => {
      const storageHasStock = !!stocksByStorageId[String(storage.id)];
      return (
        storageHasStock && (!storeFilterString || storageMatchesSearch(storage))
      );
    });
  };

  getMaximumAvailableStock = (storages) => {
    const { activeProductId, product } = this.props.reserveInStoreStore.modal;
    const storageIds = storages.map((storage) => storage.id);

    let stocks = product.stocks
      .get(activeProductId)
      .filter((stock) => storageIds.includes(stock.storage_id));

    if (!stocks) {
      return 0;
    }

    return stocks.reduce(
      (maxStock, stock) => Math.max(maxStock, stock.free_quantity),
      0
    );
  };

  productInStock = () => {
    const { product, activeProductId } = this.props.reserveInStoreStore.modal;
    const { storageStore, storage } = this.props;

    const pickupStorageIds = storage
      ? [storage.id]
      : storageStore.pickupStorages.map((storage) => storage.id);
    const stocks = product.stocks.get(activeProductId);

    if (!stocks) {
      return false;
    }

    return stocks.some(
      (stock) =>
        stock.real_stock > 0 && pickupStorageIds.includes(stock.storage_id)
    );
  };

  getTotalPrice = () => {
    const { product, activeProductId } = this.props.reserveInStoreStore.modal;
    const withTax = this.props.accountStore.showPricesWithTax;

    return (
      product.getPrice(withTax, activeProductId) *
      this.form.fields.get('quantity').value
    );
  };

  setStoreFilterString = (newString) => {
    this.setState(() => ({
      storeFilterString: newString,
    }));
  };

  toggleShowAllStorages = () => {
    this.setState((state) => ({
      showAllStorages: !state.showAllStorages,
    }));
  };

  setStorage = (storage) => {
    const storageId = storage ? storage.id : null;

    this.props.reserveInStoreStore.modal.setStorage(storageId);
    this.form.fields.get('serviceIds').setValue([]);
  };

  handleSubmit = async (e) => {
    const {
      apiWrapper,
      accountStore,
      configStore,
      reserveInStoreStore,
      uiStore,
    } = this.props;
    const { requestId } = this.state;
    const { activeProductId, storage } = reserveInStoreStore.modal;

    e.preventDefault();
    this.form.validate();

    if (this.form.valid) {
      this.setState({
        requestState: RequestState.LOADING,
        error: null,
      });

      await uiStore.recaptchaValidation.getCaptchaToken();

      if (uiStore.recaptchaValidation.isValid) {
        const { quantity, ...fieldValues } = this.form.getFieldValues();
        const recaptcha = uiStore.recaptchaValidation.token;

        apiWrapper
          .apiAxios()
          .post('reserve-in-store', {
            recaptcha,
            quantities: [quantity],
            productIds: [activeProductId],
            storageId: storage.id,
            ...fieldValues,
          })
          .then((reservation) => {
            this.setState(
              {
                requestState: RequestState.LOADED,
                reservationID: reservation.data.reservation_id,
              },
              () => this.sendAnalyticsEvents()
            );
          })
          .catch((error) => {
            this.setState(() => ({
              requestState: RequestState.ERROR,
              error: error,
              requestId: requestId + 1,
            }));
          });
      }

      configStore.recaptcha.enabled && uiStore.recaptchaValidation.resetCaptchaToken();
    }
  };

  sendAnalyticsEvents = () => {
    const { configStore } = this.props;
    const { analytics } = configStore;
    !analytics.ga4.enabled && this.sendReserveAndCollectAnalyticsEvent();
    analytics.ga4.enabled && this.sendGenerateLeadEvent();
  };

  sendReserveAndCollectAnalyticsEvent = () => {
    const { analytics, reserveInStoreStore } = this.props;
    const { requestState } = this.state;

    if (requestState === RequestState.LOADED) {
      const { modal } = reserveInStoreStore;
      const { quantity } = this.form.getFieldValues();

      const storage = modal.storage.name;
      const productId = modal.activeProductId;
      const productName = modal.product.name;
      const totalSum = this.getTotalPrice();

      const data = {
        reserveAndCollect_storage: storage,
        reserveAndCollect_product_id: productId,
        reserveAndCollect_product_name: productName,
        reserveAndCollect_quantity: quantity,
        // We have to round to two nearest decimals with Number.EPSILON to round cases like 289.0000000000000007 correctly.
        reserveAndCollect_totalSum:
          Math.round((totalSum + Number.EPSILON) * 100) / 100,
      };

      analytics.reserveAndCollect(data);
    }
  };

  sendGenerateLeadEvent = () => {
    const { analytics } = this.props;
    analytics.generateLead(this.getTotalPrice());
  };

  getErrorMessages = () => {
    const { error } = this.state;
    return get(error, 'response.data.messages');
  };

  getCollectionImage = () => {
    const { reserveInStoreStore } = this.props;
    const { product, activeProductId } = reserveInStoreStore.modal;

    const productImageDict = keyBy(product.images, 'for_color_id');
    const collectionItem =
      product.collection.getItemWithProductId(activeProductId);
    const columnElement = product.collection.column.getElementWithId(
      collectionItem.column_id
    );
    const rowElement = product.collection.row.getElementWithId(
      collectionItem.row_id
    );

    let productImageForElement = product.getMainImage(activeProductId);

    if (has(productImageDict, columnElement.id)) {
      productImageForElement = productImageDict[columnElement.id];
    } else if (has(productImageDict, rowElement.id)) {
      productImageForElement = productImageDict[rowElement.id];
    }

    return productImageForElement;
  };

  getImage = () => {
    const { reserveInStoreStore } = this.props;
    const { product, activeProductId } = reserveInStoreStore.modal;

    const productImage =
      product.class === ProductClass.COLLECTION
        ? this.getCollectionImage()
        : product.getMainImage(activeProductId);

    return (
      <ProductImage
        product={product}
        productImage={productImage}
        size="medium"
      />
    );
  };

  renderHowItWorksBlock = () => {
    const { configStore } = this.props;

    return (
      <div className="ProductReserveInStoreModal__section">
        <FormattedMessage
          id="reserveInStore.howItWorks"
          defaultMessage="Reserve the product now and pick it up from the store later! The reservation is valid for {expiryHours} hours, after which it will be removed. Please note that discount coupons cannot be used with in-store reservations."
          values={{
            expiryHours: configStore.reserveInStore.expireTimeHours,
          }}
        />
      </div>
    );
  };

  renderProductQuantity = (isEditable, maxQuantity) => {
    const { reserveInStoreStore } = this.props;

    const { product } = reserveInStoreStore.modal;

    if (isEditable) {
      return (
        <div className="ProductReserveInStoreModal__product-quantity">
          <QuantityFieldGroup
            maxQuantity={maxQuantity}
            unit={product.unitName}
            field={this.form.fields.get('quantity')}
          />
        </div>
      );
    }

    return (
      <>
        <div className="ProductReserveInStoreModal__product-quantity">
          {this.form.fields.get('quantity').value}
          {product.price_info && (
            <span className="ProductReserveInStoreModal__product-quantity-times">
              x
            </span>
          )}
        </div>
        {product.price_info && (
          <div className="ProductReserveInStoreModal__product-price">
            <ProductPrice product={product} priceInfo={product.price_info} />
          </div>
        )}
      </>
    );
  };

  renderProductSummary = (isEditable, maxQuantity) => {
    const { reserveInStoreStore, configStore } = this.props;
    const { product, activeProductId } = reserveInStoreStore.modal;

    let productName = product.name;
    if (product.class === ProductClass.COLLECTION) {
      const item = product.collection.getItemWithProductId(activeProductId);
      productName =
        productName +
        (product.class === ProductClass.COLLECTION &&
          ' (' + item.columnName + ', ' + item.rowName + ')');
    }

    return (
      <div className="ProductReserveInStoreModal__section">
        <Container className="ProductReserveInStoreModal__product-summary">
          <Row>
            <Col>{this.getImage()}</Col>
            <Col xs="auto" className="ProductReserveInStoreModal__product-info">
              {product.manufacturer && (
                <div className="ProductReserveInStoreModal__product-manufacturer">
                  {product.manufacturer.name}
                </div>
              )}
              <div className="ProductReserveInStoreModal__product-name">
                {productName}
              </div>
              {configStore.product.showModel && product.model && (
                <div className="ProductReserveInStoreModal__product-model">
                  {product.model}
                </div>
              )}
              {isEditable && product.price_info && (
                <div className="ProductReserveInStoreModal__product-price">
                  <ProductPrice
                    product={product}
                    priceInfo={product.price_info}
                  />
                </div>
              )}
              {this.renderProductQuantity(isEditable, maxQuantity)}
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  renderStorageSummary = (storage) => {
    if (!storage) {
      return null;
    }

    return (
      <div className="ProductReserveInStoreModal__storage-summary">
        {storage.name && (
          <div className="ProductReserveInStoreModal__storage-item-name">
            {storage.name}
          </div>
        )}
        <div className="ProductReserveInStoreModal__storage-item-address">
          {storage.street_address}
          {', '}
          {storage.postcode} {storage.city}
        </div>
        {storage.opening_hours && (
          <div className="ProductReserveInStoreModal__storage-item-opening-hours">
            {storage.opening_hours}
          </div>
        )}
      </div>
    );
  };

  renderStorageServicesCheckboxes = (storage) => {
    if (!storage) {
      return null;
    }

    const services = storage.services;

    if (services.length === 0) {
      return null;
    }

    return (
      <Row>
        <Col xs="12">
          <FormattedMessage
            id="reserveInStore.availableServicesForStorage"
            defaultMessage="The following services are available in the store you selected:"
          />
          {this.checkbox('serviceIds', {
            options: services.map((service) => ({
              value: String(service.id),
              label: service.description,
            })),
          })}
        </Col>
      </Row>
    );
  };

  renderModalStoragePage = () => {
    const { reserveInStoreStore, intl } = this.props;
    const { product, activeProductId } = reserveInStoreStore.modal;

    return (
      <>
        {this.renderHowItWorksBlock()}
        <ContentForState
          state={product.stockStates.get(activeProductId)}
          forLoaded={() => {
            const storages = this.getMatchingStorages();
            const visibleStorages = this.state.showAllStorages
              ? storages
              : storages.slice(0, MAX_STORAGES_SHOWN_BY_DEFAULT);

            return (
              <>
                {this.renderProductSummary(
                  true,
                  this.getMaximumAvailableStock(storages)
                )}
                <div className="ProductReserveInStoreModal__section">
                  <Row className="ProductReserveInStoreModal__available-storages-filter">
                    <Col>
                      <ProductListFilter
                        className="ProductReserveInStoreModal__available-storages-filter-input"
                        searchTerm={this.state.storeFilterString}
                        clearProductListFilter={() =>
                          this.setStoreFilterString('')
                        }
                        onFilteringChange={(e) =>
                          this.setStoreFilterString(e.target.value)
                        }
                        overridePlaceholder={intl.formatMessage(
                          messages.storageFilterPlaceholder
                        )}
                        hideSearchButton
                      />
                    </Col>
                  </Row>
                  <Row className="ProductReserveInStoreModal__available-storages-hint">
                    <Col xs="12">
                      <FormattedMessage
                        id="reserveInStore.availableStoragesHint"
                        defaultMessage="The following stores have the selected quantity of this item in stock."
                      />
                    </Col>
                  </Row>
                  {visibleStorages.map((storage) => (
                    <Row
                      key={storage.id}
                      className={classNames(
                        'ProductReserveInStoreModal__storage-item',
                        {
                          'ProductReserveInStoreModal__storage-list-item':
                            !reserveInStoreStore.modal.storage,
                        }
                      )}
                      onClick={() => this.setStorage(storage)}
                    >
                      <Row className="ProductReserveInStoreModal__storage-item-data-wrapper">
                        <Col
                          xs={'auto'}
                          className="ProductReserveInStoreModal__storage-item-data"
                        >
                          {this.renderStorageSummary(storage)}
                        </Col>
                        <Col
                          xs={'auto'}
                          className="ProductReserveInStoreModal__storage-item-button"
                        >
                          <Button
                            onClick={() => this.setStorage(storage)}
                            color="primary"
                            className="ProductReserveInStoreModal__select-storage-button"
                            outline
                          >
                            <Icon name="chevron-right" />
                          </Button>
                        </Col>
                      </Row>
                    </Row>
                  ))}
                </div>
                {storages.length > visibleStorages.length && (
                  <div className="ProductReserveInStoreModal__section ProductReserveInStoreModal__show-all-storages-section">
                    <Button
                      onClick={this.toggleShowAllStorages}
                      color="primary"
                    >
                      <FormattedMessage
                        id="reserveInStore.showAllStoragesButton"
                        defaultMessage="Show all stores"
                      />
                    </Button>
                  </div>
                )}
              </>
            );
          }}
        />
      </>
    );
  };

  renderModalDetailsPage = () => {
    const { accountStore, configStore, reserveInStoreStore, uiStore, intl } =
      this.props;
    const { requestId, requestState, error } = this.state;

    const { storage } = reserveInStoreStore.modal;
    const withTax = accountStore.showPricesWithTax;
    const showTaxExcludedInfo = !withTax;
    const price = this.getTotalPrice();
    const isLoading = requestState === RequestState.LOADING;

    return (
      <Form
        name={this.formName}
        noValidate
        onSubmit={this.handleSubmit}
        className="ProductReserveInStoreModal__form"
      >
        {this.renderHowItWorksBlock()}
        {this.renderProductSummary(
          true,
          this.getMaximumAvailableStock([storage])
        )}
        <div className="ProductReserveInStoreModal__section">
          <Row className="ProductReserveInStoreModal__storage-item">
            <Col className="ProductReserveInStoreModal__storage-item-data">
              {this.renderStorageSummary(storage)}
            </Col>
          </Row>
          <Row className="ProductReserveInStoreModal__switch-storage">
            <ButtonLink
              onClick={() => this.setStorage(null)}
              color="primary"
              className="ProductReserveInStoreModal__switch-storage-button"
            >
              <FormattedMessage
                id="reserveInStore.unselectStorageButton"
                defaultMessage="Switch store"
              />
            </ButtonLink>
          </Row>
          {this.renderStorageServicesCheckboxes(storage)}
          <Row>
            <Col xs="6">{this.field('firstName')}</Col>
            <Col xs="6">{this.field('lastName')}</Col>
          </Row>
          <Row>
            <Col xs="12">{this.field('email')}</Col>
          </Row>
          <Row>
            <Col xs="12">{this.field('phoneNumber')}</Col>
          </Row>
          <Row>
            {price > 0 && (
              <Col className="ProductReserveInStoreModal__total-price">
                <span className="ProductReserveInStoreModal__total-price-label">
                  <FormattedMessage
                    {...globalTranslations.totalPriceSentence}
                  />
                </span>
                <span className="ProductReserveInStoreModal__total-price-value">
                  <Price
                    price={price}
                    showTaxExcludedInfo={showTaxExcludedInfo}
                  />
                </span>
              </Col>
            )}
          </Row>
        </div>
        {error && !this.getErrorMessages() && (
          <ErrorHandler error={createErrorModel(error)} />
        )}
        {this.getErrorMessages() && (
          <Alert color="danger" key={requestId}>
            {this.getErrorMessages().map((message) => (
              <div key={message}>{message}</div>
            ))}
          </Alert>
        )}
        <Button
          block
          size="lg"
          color="primary"
          className="ProductReserveInStoreModal__reserve-button"
          disabled={
            this.form.valid === false ||
            uiStore.recaptchaValidation.isValid === false ||
            isLoading
          }
          type="submit"
          aria-label={intl.formatMessage({
            id: 'reserveInStore.confirmReservation',
            defaultMessage: 'Reserve now',
          })}
        >
          {requestState === RequestState.LOADING && (
            <Icon name="circle-o-notch" spin />
          )}
          <FormattedMessage
            id="reserveInStore.confirmReservation"
            defaultMessage="Reserve now"
          />
        </Button>
        {configStore.recaptcha.enabled && (
          <RecaptchaLegalBanner />
        )}
      </Form>
    );
  };

  renderModalConfirmationPage = () => {
    const { reserveInStoreStore, accountStore } = this.props;
    const { modal } = reserveInStoreStore;

    const selectedServiceIds = this.form.fields.get('serviceIds').value;
    const selectedServices = reserveInStoreStore.services.filter((service) =>
      selectedServiceIds.includes(String(service.id))
    );

    const withTax = accountStore.showPricesWithTax;
    const showTaxExcludedInfo = !withTax;
    const price = this.getTotalPrice();

    return (
      <>
        <div className="ProductReserveInStoreModal__section">
          <p>
            <FormattedMessage
              id="reserveInStore.reservationSuccessfulMessage"
              defaultMessage="Thank you for your reservation! A confirmation message has been sent to the email address {email}."
              values={{
                email: <strong>{this.form.fields.get('email').value}</strong>,
              }}
            />
          </p>
          <p>
            <FormattedMessage
              id="reserveInStore.cancellationInfo"
              defaultMessage="You can cancel your reservation by calling the store."
            />
          </p>
        </div>
        {this.renderProductSummary(false)}
        <div className="ProductReserveInStoreModal__section">
          <Row className="ProductReserveInStoreModal__storage-item">
            <Col
              xs="12"
              className="ProductReserveInStoreModal__storage-item-data"
            >
              {this.renderStorageSummary(modal.storage)}
            </Col>
          </Row>
          {selectedServices.length > 0 && (
            <div>
              <FormattedMessage
                id="reserveInStore.selectedServicesSentence"
                defaultMessage="With the following selected services:"
              />
              <ul>
                {selectedServices.map((service) => (
                  <li key={service.id}>{service.description}</li>
                ))}
              </ul>
            </div>
          )}
          {price > 0 && (
            <span className="ProductReserveInStoreModal__total-price">
              <span className="ProductReserveInStoreModal__total-price-label">
                <FormattedMessage {...globalTranslations.totalPriceSentence} />
              </span>
              <span className="ProductReserveInStoreModal__total-price-value">
                <Price
                  price={price}
                  showTaxExcludedInfo={showTaxExcludedInfo}
                />
              </span>
            </span>
          )}
        </div>
      </>
    );
  };

  getCurrentPage = () => {
    if (!this.props.reserveInStoreStore.modal.storage) {
      return ProductReserveInStoreModalPage.STORAGE;
    } else if (this.state.requestState !== RequestState.LOADED) {
      return ProductReserveInStoreModalPage.PERSONAL_DETAILS;
    } else {
      return ProductReserveInStoreModalPage.CONFIRMATION;
    }
  };

  renderModalTitle = (page) => {
    const message =
      page === ProductReserveInStoreModalPage.CONFIRMATION ? (
        <FormattedMessage
          id="reserveInStore.reservationConfirmationTitle"
          defaultMessage="Reservation #{reservationID}"
          values={{ reservationID: this.state.reservationID }}
        />
      ) : (
        <FormattedMessage {...globalTranslations.reservationTitle} />
      );

    return (
      <div
        className={classNames(
          'ProductReserveInStoreModal__section',
          'ProductReserveInStoreModal__section-title'
        )}
      >
        <span className="ProductReserveInStoreModal__title">{message}</span>
        <Button
          className={classNames(
            'ProductReserveInStoreModal__section-title-button',
            'close'
          )}
          onClick={this.props.reserveInStoreStore.modal.toggle}
        >
          <span aria-hidden>&times;</span>
          <span className="sr-only">
            <FormattedMessage {...globalTranslations.closeTitle} />
          </span>
        </Button>
      </div>
    );
  };

  renderModalBody = (page) => {
    if (!this.form) {
      return null;
    }

    switch (page) {
      case ProductReserveInStoreModalPage.PERSONAL_DETAILS:
        return this.renderModalDetailsPage();
      case ProductReserveInStoreModalPage.CONFIRMATION:
        return this.renderModalConfirmationPage();
      case ProductReserveInStoreModalPage.STORAGE:
      default:
        return this.renderModalStoragePage();
    }
  };

  render() {
    const { reserveInStoreStore } = this.props;
    const { modal } = reserveInStoreStore;

    if (!modal.product || !modal.activeProductId) {
      return null;
    }

    const stockLoaded = !!modal.product.stocks.get(modal.activeProductId);
    const inStock = stockLoaded && this.productInStock();

    if (!stockLoaded || !inStock) {
      return null;
    }

    const currentPage = this.getCurrentPage();

    return (
      <Modal
        isOpen={modal.isOpen}
        toggle={modal.toggle}
        className="ProductReserveInStoreModal"
      >
        <ModalBody>
          {this.renderModalTitle(currentPage)}
          {this.renderModalBody(currentPage)}
        </ModalBody>
      </Modal>
    );
  }
}

ProductReserveInStoreModal.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  reserveInStoreStore: modelOf(ReserveInStoreStore).isRequired,
  storageStore: modelOf(StorageStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  apiWrapper: PropTypes.instanceOf(ApiWrapper).isRequired,
  intl: intlShape.isRequired,
  storage: modelOf(Storage),
};

export default injectIntl(
  inject(
    'accountStore',
    'analytics',
    'configStore',
    'storageStore',
    'reserveInStoreStore',
    'uiStore',
    'apiWrapper'
  )(ProductReserveInStoreModal)
);
