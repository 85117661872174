import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import CommonSlider from '../../common/Slider';
import { modelOf } from '../../../prop-types';
import ProductRotatingImage from '../../product/ProductRotatingImage';
import ProductClass from '../../../types/ProductClass';
import ProductImageThumbnail from '../../product/ProductImageThumbnail';
import ConfigStore from '../../../store/ConfigStore';

const ThumbnailImageSlider = forwardRef((props, ref) => {
  const {
    configStore,
    product,
    totalSlidesCount,
    className,
    activeImageIndex,
    onMainImageIndexUpdate,
    ...rest
  } = props;

  const renderThumbnailSlide = (index, productImage) => {
    return (
      <ProductImageThumbnail
        key={`${productImage.id}__${productImage.product_id}__${productImage.sizes.full}`}
        imageIndex={index}
        activeIndex={activeImageIndex}
        productImage={productImage}
        product={product}
        onMainImageIndexUpdate={onMainImageIndexUpdate}
      />
    );
  };

  const getProductThumbnails = (hasProductImages) => {
    return (
      (hasProductImages &&
        product.images.map((productImage, index) => {
          if (productImage.for_color_id) {
            return null;
          }

          return renderThumbnailSlide(index, productImage);
        })) ||
      []
    );
  };

  const getParentProductImages = (productImageProductId) =>
    productImageProductId === product.id;

  const getProductMultiThumbnails = (hasProductImages) => {
    return (
      hasProductImages &&
      product.images.map((productImage, index) => {
        const parentImage = getParentProductImages(productImage.product_id);

        if (!parentImage) {
          return null;
        }

        return renderThumbnailSlide(index, productImage);
      })
    );
  };

  const ifFilterDuplicateImages = () => {
    return (
      product.class === ProductClass.MULTI &&
      (configStore.product.multiproductAsDropdown ||
        !configStore.product.hideMultiProductSelectionImage)
    );
  };

  const getRotatingImagesThumbnails = () => {
    if (
      !product ||
      !product.rotating_images ||
      product.rotating_images.length === 0
    ) {
      return [];
    }

    return product.rotating_images.map((filePath) => (
      <div key={filePath} className="ProductImages__thumbnail-slide">
        <div className="ProductImages__thumbnail">
          <ProductRotatingImage product={product} filePath={filePath} />
        </div>
      </div>
    ));
  };

  const renderThumbnailSlides = () => {
    if (!product || totalSlidesCount === 0) {
      return null;
    }

    const rotatingImages = getRotatingImagesThumbnails();

    const hasProductImages = product.images && product.images.length;

    const thumbnails = ifFilterDuplicateImages()
      ? getProductMultiThumbnails(hasProductImages)
      : getProductThumbnails(hasProductImages);

    return [...rotatingImages, ...thumbnails];
  };

  const thumbnailSlides = useMemo(
    () => renderThumbnailSlides(),
    [activeImageIndex]
  );

  return (
    <CommonSlider
      className={classNames('ThumbnailImageSlider', className)}
      ref={ref}
      isThumbnailSlider
      slides={thumbnailSlides}
      {...rest}
    />
  );
});

ThumbnailImageSlider.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  // Allows Product, ChildProduct and OrderProduct.
  product: PropTypes.object.isRequired,
  onMainImageIndexUpdate: PropTypes.func.isRequired,
  activeImageIndex: PropTypes.number,
  totalSlidesCount: PropTypes.number,
  className: PropTypes.string,
};

export default inject('configStore')(observer(ThumbnailImageSlider));
