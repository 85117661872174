import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import NavigationEntity from '../../../types/NavigationEntity';
import Icon from '../../common/Icon';

@observer
export class NavigationMegaMenuTitle extends Component {
  renderMegaMenuItemIcon = () => {
    const { configStore, entity, entityIndex } = this.props;
    const twoLevelMegaMenuEnabled =
      configStore.navigation.megaMenu.twoLayerMegaMenuEnabled;

    if (twoLevelMegaMenuEnabled) {
      return null;
    }

    return (
      <Fragment>
        {entity.children && entity.children.length > 0 && (
          <Icon name="angle-right" />
        )}
        {!entity.children && entityIndex === 0 && (
          <Icon name="angle-double-right" />
        )}
      </Fragment>
    );
  };

  render() {
    const { configStore, className, entity } = this.props;
    const twoLevelMegaMenuEnabled =
      configStore.navigation.megaMenu.twoLayerMegaMenuEnabled;

    if (!entity) {
      return null;
    }

    return (
      <span
        className={classNames('NavigationMegaMenuTitle', className, {
          'NavigationMegaMenuTitle__two-level-megamenu':
            twoLevelMegaMenuEnabled,
        })}
      >
        {entity.label}
        {this.renderMegaMenuItemIcon()}
      </span>
    );
  }
}

NavigationMegaMenuTitle.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  entity: NavigationEntity,
  entityIndex: PropTypes.number,
  className: PropTypes.string,
};

export default inject('configStore')(NavigationMegaMenuTitle);
