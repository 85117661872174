import { types, getEnv, flow, getRoot } from 'mobx-state-tree';
import { uniqueId } from 'lodash';
import qs from 'qs';

import RequestState, { RequestStateType } from '../types/RequestState';
import StatefulStore from '../models/StatefulStore';
import InstantShoppingCartProduct from '../models/InstantShoppingCartProduct';
import InstantShoppingPickupOption from '../models/InstantShoppingPickupOption';
import InstantShoppingInstance from '../models/InstantShoppingInstance';
import ShippingOptionSelectionField from '../models/ShippingOptionSelectionField';
import ShippingSelectionFieldOption from '../models/ShippingSelectionFieldOption';
import CheckoutShippingOption from '../models/checkout/CheckoutShippingOption';

const InstantShoppingCartStore = StatefulStore.named('InstantShoppingCartStore')
  .props({
    selectedShippingOption: types.maybeNull(
      types.reference(CheckoutShippingOption)
    ),
    selectedPickupOption: types.maybeNull(
      types.reference(InstantShoppingPickupOption, {
        /**
         * We might have issues in mst models referencing to same data that exists in multiple trees.
         * By default types.reference() seems to try resolving matching model in any mst tree.
         * "Custom" resolver seems to make reference types to resolve only under their parent tree.
         * https://mobx-state-tree.js.org/concepts/references#customizable-references
         */
        get(identifier) {
          return identifier;
        },
        set(value) {
          return value;
        },
      })
    ),
    selectedSelectionFieldOption: types.maybeNull(
      types.reference(ShippingSelectionFieldOption, {
        get(identifier, parent) {
          let selectedSelectionFieldOption = null;

          parent.shippingOptions.find((shippingOption) => {
            const selectionFieldOption =
              shippingOption.findSelectionFieldOptionReference(identifier.id);
            if (selectionFieldOption) {
              selectedSelectionFieldOption = selectionFieldOption;
            }
          });

          return selectedSelectionFieldOption;
        },
        set(value) {
          return value;
        },
      })
    ),
    instantShoppingInstance: types.optional(
      types.map(InstantShoppingInstance),
      {}
    ),
    productCart: types.optional(
      types.model({
        cart: types.optional(types.array(InstantShoppingCartProduct), []),
        success: types.optional(types.maybeNull(types.boolean), false),
      }),
      {}
    ),
    productCartStates: types.optional(types.map(RequestStateType), {}),
    shippingOptions: types.optional(types.array(CheckoutShippingOption), []),
    shippingSelectionFieldOptions: types.maybeNull(
      types.reference(ShippingOptionSelectionField)
    ),
    pickupPointOptions: types.optional(
      types.map(types.array(InstantShoppingPickupOption)),
      {}
    ),
    shippingOptionState: types.optional(types.map(RequestStateType), {}),
    pickupPointStates: types.optional(types.map(RequestStateType), {}),
    instantShoppingOrderForm: types.optional(
      types.model({
        postalCode: types.string,
        streetAddress: types.string,
      }),
      {
        postalCode: '',
        streetAddress: '',
      }
    ),
    klarnaEnvironmentLoaded: types.optional(types.boolean, false),
    merchantId: types.maybeNull(types.number),
    isOpen: types.optional(
      types.model({
        orderModal: types.boolean,
      }),
      {
        orderModal: false,
      }
    ),
    instanceId: types.maybeNull(types.string),
  })
  .actions((self) => {
    const getProductStore = () => getRoot(self).productStore;

    const getLang = () => getRoot(self).languageStore.activeLanguage.code;

    const ifShoppingCenter = () =>
      getRoot(self).configStore.siteConfig.isShoppingCenter;

    const baseApi = (endpoint) =>
      ifShoppingCenter() ? `shopping-center/${endpoint}` : `${endpoint}`;

    const setInstantShoppingProducts = (response, productId) => {
      self.productCart.cart = response;
      self.productCart.success = true;
      self.productCartStates.set(productId, RequestState.LOADED);
    };

    const setInstantShoppingProductCart = (response, extendedId) => {
      self.productCart = response;
      self.productCartStates.set(extendedId, RequestState.LOADED);
    };

    const setProductCollectionVariations = () => {
      const productStore = getProductStore();
      if (
        !productStore.selectedProductRow &&
        !productStore.selectedProductColumn
      ) {
        return null;
      }

      const trimmedRowName = productStore.selectedProductRow
        .replace(/&nbsp;/g, ' ')
        .trim();
      const trimmedColumnName = productStore.selectedProductColumn
        .replace(/&nbsp;/g, ' ')
        .trim();
      return [trimmedRowName, trimmedColumnName];
    };

    const createInstantShoppingOrderEntity = () => {
      const lang = getLang();
      const products = getProducts();
      const merchantId = self.merchantId;
      const orderEntity = {
        lang,
        merchantId,
        products,
      };

      if (self.selectedShippingOption) {
        orderEntity.shippingModuleId = self.selectedShippingOption.id;
      }

      if (
        self.selectedShippingOption &&
        self.selectedPickupOption &&
        self.selectedShippingOption.requires_pickup_point
      ) {
        orderEntity.pickupPointInfo = selectedPickupPoint();
      }

      if (
        self.selectedShippingOption &&
        self.selectedShippingOption.selection_fields &&
        self.selectedShippingOption.selection_fields.length > 0
      ) {
        orderEntity.shippingSelectionFields = selectedShippingSelectionField();
      }

      if (self.instantShoppingInstance.get(self.merchantId)) {
        orderEntity.instantShoppingId = self.instantShoppingInstance.get(
          self.merchantId
        ).instant_shopping_id;
      }

      if (
        self.instantShoppingOrderForm &&
        self.instantShoppingOrderForm.postalCode &&
        self.instantShoppingOrderForm.streetAddress &&
        self.selectedShippingOption &&
        self.selectedShippingOption.requires_pickup_point
      ) {
        orderEntity.pickupPointAddressInfo = {
          postCode: self.instantShoppingOrderForm.postalCode,
          streetAddress: self.instantShoppingOrderForm.streetAddress,
        };
      }

      return orderEntity;
    };

    const createPickupPointEntity = (shippingOption) => {
      const lang = getLang();
      const products = getProducts();
      const merchantId = self.merchantId;

      return {
        lang,
        merchantId,
        products,
        paymentModuleId:
          getInstantShoppingFlowProcessByMerchant().paymentModuleId,
        shippingModuleId: shippingOption.id,
        integration: shippingOption.pickup_point_integration,
        postCode: self.instantShoppingOrderForm.postalCode,
        streetAddress: self.instantShoppingOrderForm.streetAddress,
      };
    };

    const getProducts = () =>
      self.productCart.cart.map((product) => ({
        extendedId: product.id,
        quantity: product.quantity,
        reference: product.reference,
      }));

    const getAnalyticsProducts = () =>
      self.productCart.cart.map((product) => ({
        product_id: product.id,
        quantity: product.quantity,
        name: product.name,
      }));

    const selectedPickupPoint = () => ({
      id: self.selectedPickupOption.unique_id,
      integration: self.selectedShippingOption.pickup_point_integration,
      name: self.selectedPickupOption.name,
      address: self.selectedPickupOption.address,
      openingHours: self.selectedPickupOption.opening_hours,
      externalInfo: self.selectedPickupOption.external_info
        ? getExternalInfo()
        : null,
    });

    const getExternalInfo = () => {
      let externalInfo = {};
      const externalInfoKeyValuePairs =
        self.selectedPickupOption.external_info.map((info) => ({
          [info.key]: info.value,
        }));

      externalInfoKeyValuePairs.forEach((keyValuePair) => {
        externalInfo = {
          ...externalInfo,
          ...keyValuePair,
        };
      });
      return externalInfo;
    };

    const selectedShippingSelectionField = () => [
      {
        fieldId: self.selectedShippingOption.selection_fields[0].id,
        valueId: self.selectedSelectionFieldOption.id,
      },
    ];

    const createInstanceId = () => {
      self.instanceId = !self.instanceId
        ? `buttonkey-${uniqueId(self.merchantId)}`
        : self.instanceId;
    };

    const getInstantShoppingFlowProcessByMerchant = () => {
      return self.instantShoppingInstance.get(self.merchantId.toString());
    };

    return {
      setup: (merchantId) => {
        self.merchantId = merchantId;
        createInstanceId();
      },
      getAnalyticsProductCartProducts: () => {
        return getAnalyticsProducts();
      },
      loadKlarnaInstantShoppingInstance: flow(
        function* loadKlarnaInstantShoppingInstance() {
          const instantShoppingId =
            getInstantShoppingFlowProcessByMerchant().instant_shopping_id;
          const instantShoppingEnvironment =
            getInstantShoppingFlowProcessByMerchant().integrationInfo
              .environment;
          const instantShoppingRegion =
            getInstantShoppingFlowProcessByMerchant().integrationInfo.region;

          try {
            yield window.Klarna.InstantShopping.load({
              setup: {
                instance_id: self.instanceId,
                key: instantShoppingId,
                environment:
                  instantShoppingEnvironment.toLowerCase() === 'development'
                    ? 'playground'
                    : 'production',
                region: instantShoppingRegion,
              },
            });
            self.klarnaEnvironmentLoaded = true;
          } catch (error) {
            console.error(error);
            self.setError(error);
            throw error;
          }
        }
      ),
      openKlarnaInstantShoppingModal: flow(
        function* openKlarnaInstantShoppingInstance() {
          const instantShoppingId = getInstantShoppingFlowProcessByMerchant();
          try {
            instantShoppingId &&
              (yield window.Klarna.InstantShopping.open({
                instance_id: self.instanceId,
              }));
          } catch (error) {
            console.error(error);
            self.setError(error);
            throw error;
          }
        }
      ),
      addToInstantShoppingProductCart: flow(
        function* addToInstantShoppingProductCart(
          product,
          quantity,
          activeProductId
        ) {
          const extendedId = activeProductId || product.id;
          const variations = setProductCollectionVariations();

          self.productCartStates.set(extendedId, RequestState.LOADING);

          try {
            const response = yield getEnv(self)
              .apiWrapper.apiAxios()
              .post(`${baseApi('instant-shopping/cart/products')}`, {
                extendedId,
                quantity,
                packageSize: product.package_size || 1,
                lang: getLang(),
                variations,
              });

            setInstantShoppingProductCart(response.data, extendedId);
          } catch (error) {
            console.error(error);
            self.setError(error);
            self.productCartStates.set(product.id, RequestState.ERROR);
            throw error;
          }
        }
      ),
      removeInstantShoppingCartProduct: flow(
        function* removeInstantShoppingCartProduct(id) {
          self.setLoading(true);
          try {
            const response = yield getEnv(self)
              .apiWrapper.apiAxios()
              .delete(`${baseApi(`instant-shopping/cart/products/${id}`)}`);
            if (response.status === 204) {
              const products = self.productCart.cart.filter(
                (product) => product.id !== id
              );
              self.productCart.cart = products;
              self.setLoading(false);
            }
          } catch (error) {
            self.setLoading(false);
            console.error(error);
            self.setError(error);
            self.productCartStates.set(id, RequestState.ERROR);
            throw error;
          }
        }
      ),
      getInstantShoppingCartProducts: flow(
        function* getInstantShoppingCartProducts(
          merchantId,
          product,
          activeProductId
        ) {
          self.setLoading(true);
          const productId = activeProductId || product.id;
          try {
            const response = yield getEnv(self).apiWrapper.request(
              `${baseApi('instant-shopping/cart/products')}`,
              {
                params: {
                  merchantId,
                },
              },
              { active_section: null }
            );
            setInstantShoppingProducts(response, productId);
            self.setLoading(false);
          } catch (error) {
            self.setError(error);
            self.productCartStates.set(productId, RequestState.ERROR);
            self.setLoading(false);
            throw error;
          }
        }
      ),
      loadShippingOptions: flow(function* loadShippingOptions() {
        if (!self.productCart.cart || self.productCart.cart.length === 0) {
          return;
        }

        self.shippingOptionState.set(self.merchantId, RequestState.LOADING);
        const orderEntity = createInstantShoppingOrderEntity();

        if (getInstantShoppingFlowProcessByMerchant()) {
          orderEntity.paymentModuleId =
            getInstantShoppingFlowProcessByMerchant().paymentModuleId;
        }

        try {
          const shippingOptions = yield getEnv(self).apiWrapper.request(
            `${baseApi(
              'instant-shopping/order/shipping-options'
            )}?${qs.stringify(orderEntity)}`
          );
          self.shippingOptions = shippingOptions;
          self.selectedShippingOption =
            shippingOptions.length > 0
              ? CheckoutShippingOption.create(shippingOptions[0])
              : null;
          self.selectedSelectionFieldOption =
            shippingOptions.length > 0
              ? self.selectedShippingOption.getDefaultSelectionField()
              : null;

          self.shippingOptionState.set(self.merchantId, RequestState.LOADED);
        } catch (error) {
          self.shippingOptionState.set(self.merchantId, RequestState.ERROR);
          self.setError(error);
          throw error;
        }
      }),
      loadPickupPointOptions: flow(function* loadPickupPointOptions(
        shippingOption
      ) {
        const customerAddress = `${self.instantShoppingOrderForm.postalCode},${self.instantShoppingOrderForm.streetAddress}`;

        const ifAlreadyLoading =
          self.pickupPointStates.get(customerAddress) === RequestState.LOADING;

        // Skip Api call if we are already fetching the data
        if (
          ifAlreadyLoading ||
          (!self.instantShoppingOrderForm.postalCode &&
            !self.instantShoppingOrderForm.streetAddress)
        ) {
          return;
        }

        self.pickupPointStates.set(customerAddress, RequestState.LOADING);

        try {
          const pickupPointEntity = createPickupPointEntity(shippingOption);

          const pickupPointOptions = yield getEnv(self).apiWrapper.request(
            `${baseApi('instant-shopping/order/pickup-points')}?${qs.stringify(
              pickupPointEntity
            )}`
          );

          self.pickupPointOptions.set(customerAddress, pickupPointOptions);

          self.selectedPickupOption =
            pickupPointOptions.length > 0
              ? InstantShoppingPickupOption.create(pickupPointOptions[0])
              : null;

          self.pickupPointStates.set(customerAddress, RequestState.LOADED);
        } catch (error) {
          self.setError(error);
          self.pickupPointStates.set(customerAddress, RequestState.ERROR);
          throw error;
        }
      }),
      orderProducts: flow(function* orderProducts() {
        const orderEntity = createInstantShoppingOrderEntity();
        self.setLoading(true);
        try {
          const order = yield getEnv(self)
            .apiWrapper.apiAxios()
            .post(`${baseApi('instant-shopping/order')}`, orderEntity);

          self.instantShoppingInstance.set(self.merchantId, order.data);
          self.setLoading(false);
        } catch (error) {
          self.setLoading(false);
          self.setError(error);
          throw error;
        }
      }),
      resetShippingOptions: () => {
        self.selectedSelectionFieldOption = null;
        self.selectedShippingOption = null;
        self.shippingOptions.clear();
        self.shippingOptionState.clear();
      },
      resetPickupPoint: () => {
        self.selectedPickupOption = null;
        self.instantShoppingOrderForm.postalCode = '';
        self.instantShoppingOrderForm.streetAddress = '';
        self.pickupPointOptions.clear();
        self.pickupPointStates.clear();
      },
      resetInstantShoppingInstance: () => {
        self.instantShoppingInstance.clear();
        self.klarnaEnvironmentLoaded = false;
      },
      resetStore: () => {
        self.resetShippingOptions();
        self.resetPickupPoint();
        self.resetInstantShoppingInstance();
      },
      toggleModal: (modal) => {
        self.isOpen[modal] = !self.isOpen[modal];
      },
      closeModal: (modal) => {
        self.isOpen[modal] = false;
      },
      updateOrder: flow(function* updateOrder() {
        const orderEntity = createInstantShoppingOrderEntity();
        self.setLoading(true);
        try {
          const order = yield getEnv(self)
            .apiWrapper.apiAxios()
            .put(
              `${baseApi('instant-shopping/order')}`,
              qs.stringify(orderEntity)
            );

          const instantShoppingInstance = self.instantShoppingInstance.get(
            self.merchantId
          );
          instantShoppingInstance.order = order.data.order;
          instantShoppingInstance.success = order.data.success;
          self.setLoading(false);
        } catch (error) {
          self.setLoading(false);
          self.setError(error);
          throw error;
        }
      }),
      updateShippingOption: (shippingOption) =>
        (self.selectedShippingOption = shippingOption),
      updatePickupOption: (pickupOption) =>
        (self.selectedPickupOption = pickupOption),
      updateSelectionFieldOption: (selectionFieldOption) =>
        (self.selectedSelectionFieldOption = selectionFieldOption),
      updateInstantShoppingOrderForm: (name, value) =>
        (self.instantShoppingOrderForm[name] = value),
    };
  })
  .views((self) => {
    return {
      get hasCart() {
        return (
          self.productCart &&
          self.productCart.cart &&
          self.productCart.cart.length > 0
        );
      },
      get instanceShoppingInstanceId() {
        return self.instantShoppingInstance.get(self.merchantId.toString())
          ? self.instantShoppingInstance.get(self.merchantId.toString())
              .instant_shopping_id
          : null;
      },
      get selectedInstantShoppingProducts() {
        return self.productCart &&
          self.productCart.cart &&
          self.productCart.cart.length
          ? self.productCart.cart
          : [];
      },
      getShippingOptions() {
        return self.shippingOptions && self.shippingOptions.length > 0
          ? self.shippingOptions
          : null;
      },
      get defaultPickupPoint() {
        const option = self.pickupPointOptions.get(
          this.postalCode + ',' + this.streetAddress
        );
        return option && option.length > 0 ? option[0] : null;
      },
      get selectedShippingOptionName() {
        return self.selectedShippingOption && self.selectedShippingOption.name;
      },
      get selectedPickupOptionName() {
        return self.selectedPickupOption && self.selectedPickupOption.name;
      },
      get postalCode() {
        return (
          self.instantShoppingOrderForm &&
          self.instantShoppingOrderForm.postalCode &&
          self.instantShoppingOrderForm.postalCode
        );
      },
      get streetAddress() {
        return (
          self.instantShoppingOrderForm &&
          self.instantShoppingOrderForm.streetAddress &&
          self.instantShoppingOrderForm.streetAddress
        );
      },
      get formValues() {
        return {
          postalCode: this.postalCode,
          streetAddress: this.streetAddress,
        };
      },
      getPickupPointsOptions(customerAddress) {
        return (
          customerAddress &&
          self.pickupPointOptions &&
          self.pickupPointOptions.length &&
          self.pickupPointOptions.get(customerAddress).slice()
        );
      },
    };
  });

export default InstantShoppingCartStore;
