import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import UIStore from '../../../store/UIStore';
import CartStore from '../../../store/CartStore';
import CartProductRow from '../CartProductRow';
import globalTranslations from '../../../i18n/globalTranslations';
import ProductTypeClass from '../../../types/ProductTypeClass';

const CartProductListing = ({
  cartStore,
  uiStore,
  productListHeight,
  activeCoupons,
  showProductListingColumn,
}) => {
  const { cart } = cartStore;

  const getFilteredProducts = () => {
    let filteredProducts = [];

    filteredProducts = cart.products.filter(
      (cartProduct) => cartProduct.type !== ProductTypeClass.DISCOUNT
    );

    return filteredProducts;
  };

  return (
    <Col
      xs="12"
      md="6"
      className={classNames(
        'CartProductListing',
        'CartModal__column CartModal__left-column',
        {
          'CartModal__left-column-with-coupons-compensation': activeCoupons,
          'order-2': showProductListingColumn && uiStore.isMobile,
        }
      )}
    >
      <h4 className="CartModal__title">
        <FormattedMessage {...globalTranslations.productsTitle} />
      </h4>
      <div className="CartModal__column-inner--small-padding">
        <div
          className="CartModal__product-list--with-images"
          style={{ maxHeight: productListHeight }}
        >
          {getFilteredProducts().map((product, index) => (
            <CartProductRow key={`${product.id}_${index}`} product={product} />
          ))}
        </div>
      </div>
    </Col>
  );
};

CartProductListing.propTypes = {
  cartStore: modelOf(CartStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  productListHeight: PropTypes.string,
  activeCoupons: PropTypes.bool,
  showProductListingColumn: PropTypes.bool,
};

export default inject('cartStore', 'uiStore')(observer(CartProductListing));
