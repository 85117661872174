import { uniqueId } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';

import ProductMulti from '../../../models/ProductMulti';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';
import UIStore from '../../../store/UIStore';
import ProductMatrixCell from '../ProductMatrixCell';
import ProductMatrixTooltip from '../ProductMatrixTooltip';
import ProductMultiPropertyList from '../ProductMultiPropertyList';
import ProductQuantityDiscounts from '../ProductQuantityDiscounts';

const LEVEL_FOUR = 3;
const LEVEL_THREE = 2;
const LEVEL_TWO = 1;
const LEVEL_ONE = 0;

const createPropertyElement = (property, element) => {
  return { [property]: element };
};

const ProductMultiMatrix = ({
  accountStore,
  configStore,
  uiStore,
  onHandleProductQuantity,
  multi,
}) => {
  const withTax = accountStore.withTax;

  const handleProductQuantity = (id, qty) => {
    onHandleProductQuantity(id, qty);
  };

  const renderTooltip = (tooltipIsOpen, childProduct) => {
    if (childProduct.getQuantityDiscounts().hasDiscounts) {
      return (
        <ProductMatrixTooltip
          className="ProductMultiMatrix__tooltip"
          product={childProduct}
          isOpen={tooltipIsOpen}
          renderQuantityDiscounts={
            <ProductQuantityDiscounts
              quantityDiscounts={childProduct.getQuantityDiscounts().discounts}
              withTax={withTax}
              stockUnit={childProduct.stock_unit}
            />
          }
        />
      );
    }

    return (
      <ProductMatrixTooltip product={childProduct} isOpen={tooltipIsOpen} />
    );
  };

  const getProductCellRow = (
    currentPropertyElement,
    propertyElements,
    cellCount
  ) => {
    const cells = propertyElements.map((propertyElement) => {
      const activePropertyElementMap = {
        ...currentPropertyElement,
        ...propertyElement,
      };

      const childProduct = multi.findChildWithProperties(
        activePropertyElementMap
      );

      return (
        <ProductMatrixCell
          key={uniqueId('Cell-')}
          product={childProduct}
          onHandleProductQuantity={handleProductQuantity}
          cellCount={cellCount}
          onRenderTooltip={(tooltipIsOpen) =>
            renderTooltip(tooltipIsOpen, childProduct)
          }
        />
      );
    });

    return <div className="ProductMultiMatrix__product-cells">{cells}</div>;
  };

  const getCellStyle = (cellCount) => {
    let width = 100;

    if ((cellCount > 5 && !uiStore.isDesktop) || cellCount > 10) {
      width = 78;
    }

    return {
      width,
    };
  };

  const renderProductPropertyList = (accountedProperties) => {
    const property = accountedProperties[LEVEL_ONE];

    return (
      <ProductMultiPropertyList
        property={property}
        multi={multi}
        onHandleProductQuantity={handleProductQuantity}
      />
    );
  };

  const renderTwoDimensions = (
    accountedProperties,
    currentPropertyElement = {}
  ) => {
    const property_one = accountedProperties[LEVEL_ONE];
    const property_two = accountedProperties[LEVEL_TWO];
    const cellElements = [];
    const cellCount = property_two.elements.length;

    const headers = property_two.elements.map((element) => {
      cellElements.push(createPropertyElement(property_two.id, element.id));
      return (
        <div
          key={element.id}
          className="ProductMultiMatrix__second-level-header"
          style={getCellStyle(cellCount)}
        >
          {property_two.name}: {element.name}
        </div>
      );
    });

    const elements = property_one.elements.map((element) => {
      const propertyElement = createPropertyElement(
        property_one.id,
        element.id
      );

      return (
        <Row key={element.id} className="ProductMultiMatrix__first-level">
          <Col md={2} className="ProductMultiMatrix__first-level-header">
            <div>
              {property_one.name}: {element.name}
            </div>
          </Col>
          <Col md={10} className="ProductMultiMatrix__cells">
            {getProductCellRow(
              {
                ...propertyElement,
                ...currentPropertyElement,
              },
              cellElements,
              cellCount
            )}
          </Col>
        </Row>
      );
    });

    return (
      <>
        <Row className="ProductMultiMatrix__second-level">
          <Col md={2} className="ProductMultiMatrix__second-level" />
          <Col md={10} className="ProductMultiMatrix__second-level">
            <div className="ProductMultiMatrix__second-level-headers">
              {headers}
            </div>
          </Col>
        </Row>
        {elements}
      </>
    );
  };

  const renderThreeDimensions = (
    accountedProperties,
    currentPropertyElement = {}
  ) => {
    const property = accountedProperties[LEVEL_THREE];

    return property.elements.map((element) => {
      const propertyElement = createPropertyElement(property.id, element.id);
      return (
        <Row key={element.id} className="ProductMultiMatrix__third-level">
          <Col md={12} className="ProductMultiMatrix__third-level-header">
            {property.name}: {element.name}
          </Col>
          <Col md={12} className="ProductMultiMatrix__two-dimensions">
            {renderTwoDimensions(accountedProperties, {
              ...propertyElement,
              ...currentPropertyElement,
            })}
          </Col>
        </Row>
      );
    });
  };

  const renderFourDimensions = (accountedProperties) => {
    const property = accountedProperties[LEVEL_FOUR];

    return property.elements.map((element) => {
      return (
        <Fragment key={element.id}>
          <Row key={element.id} className="ProductMultiMatrix__fourth-level">
            <Col className="ProductMultiMatrix__fourth-level-header">
              {property.name}: {element.name}
            </Col>
          </Row>
          {renderThreeDimensions(
            accountedProperties,
            createPropertyElement(property.id, element.id)
          )}
        </Fragment>
      );
    });
  };

  let matrix = null;
  let accountedProperties = multi.getMultiElementProperties();

  if (configStore.productPage.matrixDimensionSorting) {
    accountedProperties = accountedProperties.sort(
      (a, b) => b.elements.length - a.elements.length
    );
  }

  switch (accountedProperties.length) {
    case 4:
      matrix = renderFourDimensions(accountedProperties);
      break;
    case 3:
      matrix = renderThreeDimensions(accountedProperties);
      break;
    case 2:
      matrix = renderTwoDimensions(accountedProperties);
      break;
    default:
      matrix = renderProductPropertyList(accountedProperties);
      break;
  }

  return (
    <Row className="ProductMultiMatrix">
      <Col>
        <Container className="ProductMultiMatrix__container">
          {matrix}
        </Container>
      </Col>
    </Row>
  );
};

ProductMultiMatrix.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  onHandleProductQuantity: PropTypes.func.isRequired,
  multi: modelOf(ProductMulti).isRequired,
};

export default inject(
  'accountStore',
  'configStore',
  'uiStore'
)(observer(ProductMultiMatrix));
