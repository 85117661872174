import {
  CommonEventNames,
  CustomEventNames,
  FacebookPixelEventNames,
  GiosgEventNames,
  UniversalAnalyticsEventNames,
} from '../AnalyticsEventNames';

const notImplementedError = new Error('Analytics method not implemented');

export default class UniversalAnalyticEvents {
  constructor(currencyStore, uiStore) {
    this.currencyStore = currencyStore;
    this.uiStore = uiStore;
  }

  /**
   * Dispatch analytics events for integrations to use.
   *
   * @param {string} name
   * @param {Object} payload
   */
  dispatchEvent(name, payload) {
    const detail = {
      detail: {
        name,
        payload: {
          currencyCode: this.currencyStore.currencyCode,
          ...payload,
        },
      },
    };

    // Dispatch a specific event for integrations that only need specific events.
    this.dispatchSpecificEvent(name, detail);
    // Dispatch a catch-all event for integrations that use all events.
    this.dispatchAnalyticsEvent(detail);
  }

  /**
   *
   * @param {string} name
   * @param {Object} detail
   */
  dispatchSpecificEvent(name, detail) {
    const event = new CustomEvent(`analytics:${name}`, detail);
    document.dispatchEvent(event);
  }

  /**
   *
   * @param {Object} detail
   */
  dispatchAnalyticsEvent(detail) {
    const universalAnalyticsEvent = new CustomEvent('analytics', detail);
    document.dispatchEvent(universalAnalyticsEvent);
  }

  /**
   * @param {Array.<Product>} productList
   */
  addToCart(productList, value) {
    this.dispatchEvent(UniversalAnalyticsEventNames.addToCart, {
      productList,
      value,
    });
  }

  addToProposal() {
    throw notImplementedError;
  }

  beginProposal() {
    throw notImplementedError;
  }

  /**
   * @param {string} pageName
   */
  setPage(pageName) {
    this.dispatchEvent(CommonEventNames.setPage, {
      pageName,
    });
  }

  /**
   * @param {string} listName
   * @param {Array} productList An array with objects in the format { position, product }.
   */
  productClick(listName, productList) {
    this.dispatchEvent(UniversalAnalyticsEventNames.productClick, {
      listName,
      productList,
    });
  }

  /**
   * @param {string} listName
   * @param {Array} productList An array with objects in the format { position, product }.
   */
  productImpressions(listName, productList) {
    this.dispatchEvent(UniversalAnalyticsEventNames.productImpressions, {
      listName,
      productList,
      pageName: this.uiStore.currentPage,
    });
  }

  /**
   * @param {Array} productList An array with objects in the format { product }.
   */
  productDetail({ value, productList }) {
    this.dispatchEvent(UniversalAnalyticsEventNames.productDetail, {
      value,
      productList,
    });
  }

  proposalSent() {
    throw notImplementedError;
  }

  /**
   * @param {Object} data
   * @param {Object.Array<CartProduct>} productList List of removed products
   */
  removeFromCart({ productList }) {
    this.dispatchEvent(UniversalAnalyticsEventNames.removeFromCart, {
      productList,
    });
  }

  removeFromProposal() {
    throw notImplementedError;
  }

  /**
   * Send all products from the cart.
   */
  cartStatus(cart) {
    this.dispatchEvent(GiosgEventNames.cartStatus, {
      cart,
    });
  }

  /**
   * Clear all products from the cart.
   */
  clearCart() {
    this.dispatchEvent(GiosgEventNames.clearCart, {});
  }

  /**
   * @param {Array} bannerList An array with objects in the format { bannerZone, banner }.
   */
  promoView(bannerList) {
    this.dispatchEvent(UniversalAnalyticsEventNames.promoView, {
      bannerList,
    });
  }

  /**
   * @param {Array} bannerList An array with objects in the format { bannerZone, banner }.
   */
  promotionClick(bannerList) {
    this.dispatchEvent(UniversalAnalyticsEventNames.promotionClick, {
      bannerList,
    });
  }

  /**
   * @param searchText
   * @param resultCounts
   */
  quickSearch(searchTerm, resultCounts) {
    this.dispatchEvent(UniversalAnalyticsEventNames.quickSearch, {
      searchTerm,
      resultCounts,
    });
  }

  /**
   * When quick search suggestions are selected, the information is sent through this function.
   * Can be used to train suggestions.
   *
   * @param searchText
   * @param suggestionType
   * @param suggestionValue
   */
  suggestionSelection(searchText, suggestionType, suggestionValue) {
    this.dispatchEvent(UniversalAnalyticsEventNames.suggestionSelection, {
      searchText,
      suggestionType,
      suggestionValue,
    });
  }

  checkout(productList, actionField) {
    this.dispatchEvent(UniversalAnalyticsEventNames.checkout, {
      productList,
      actionField,
    });
  }

  checkoutOption() {
    throw notImplementedError;
  }

  /**
   * @param {CurrentOrder} currentOrder Current order model.
   */
  purchase(currentOrder) {
    this.dispatchEvent(UniversalAnalyticsEventNames.purchase, {
      currentOrder,
    });
  }

  refund() {
    throw notImplementedError;
  }

  viewProposal() {
    throw notImplementedError;
  }

  updateDimension(dimensionKey, dimensionValue) {
    this.dispatchEvent(CustomEventNames.updateDimension, {
      dimensionKey,
      dimensionValue,
    });
  }

  reserveAndCollect(data) {
    this.dispatchEvent(UniversalAnalyticsEventNames.reserveAndCollect, data);
  }

  pageNotFound(payload) {
    this.dispatchEvent(CustomEventNames.pageNotFound, payload);
  }

  /**
   * @param {Object} gdprInfo
   * @param {Object.<string>} first_name
   * @param {Object.<string>} last_name
   * @param {Object.<string>} email
   * @param {Object.<string>} street_address
   * @param {Object.<string>} international_number
   * @param {Object.<boolean>} marketing_ban
   */
  sendGdprData(gdprInfo) {
    this.dispatchEvent(CustomEventNames.sendGdprData, gdprInfo);
  }

  /**
   * @param {Array.<AnalyticsItem>} productList
   * @param {number} value monetary value of the event
   */
  customizeProduct(productList, value) {
    this.dispatchEvent(FacebookPixelEventNames.customizeProduct, {
      productList,
      value,
    });
  }
}
