import React, { Component, Fragment } from 'react';
import { Button, DropdownItem, Input, InputGroup } from 'reactstrap';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import {
  defineMessages,
  FormattedMessage,
  intlShape,
  injectIntl
} from 'react-intl';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import DropdownSelect from '../../common/DropdownSelect';
import globalTranslations from '../../../i18n/globalTranslations';
import Client from '../../../models/Client';
import ContentForState from '../../loader/ContentForState';

const messages = defineMessages({
  clientFilterButton: {
    id: 'account.clientSearchButton',
    defaultMessage: 'Search for clients'
  },
  clientFilterPlaceholder: {
    id: 'account.clientSearchPlaceholder',
    defaultMessage: 'New search with Enter or search button'
  }
});

@observer
export class AccountModalClientDropdown extends Component {
  getClientName = (client) => {
    const name = client.lastname
      ? client.firstname
        ? client.lastname + ' ' + client.firstname
        : client.lastname
      : client.firstname
        ? client.firstname
        : '';

    const fullName = client.company && name.length > 0
      ? client.company + ', ' + name
      : client.company
        ? client.company
        : name;
    return fullName + ' ('+ client.id +')';
  };

  getLabel = () => {
    const { client } = this.props;
    return client ? (
      <FormattedMessage
        {...globalTranslations.selectedTitleWithValue}
        values={{
          value: <span>{this.getClientName(client)}</span>
        }}
      />
    ) : (
      <FormattedMessage {...globalTranslations.selectTitle} />
    );
  };

  handleKeyPress = (e) => {
    const { searchClients } = this.props;
    if (e.key === 'Enter') {
      searchClients();
    }
  };

  renderItems = () => {
    const { clients, onSelect } = this.props;

    return (
      <Fragment>
        {clients.length > 0 ? (
          clients.map((client) => (
            <DropdownItem onClick={(e) => onSelect(client)} key={client.id}>
              <span>{this.getClientName(client)}</span>
            </DropdownItem>
          ))
        ) : (
          <div className="AccountModalClientDropdown__filter-no-result">
            <FormattedMessage {...globalTranslations.searchNoResultsSentence} />
          </div>
        )}
      </Fragment>
    );
  };

  render() {
    const {
      intl,
      setClientFilter,
      clientFilter,
      searchClients,
      loading
    } = this.props;

    return (
      <div className="AccountModalClientDropdown">
        <DropdownSelect label={this.getLabel()} isLarge={true}>
          <InputGroup className="AccountModalClientDropdown__filter btn-group">
            <Input
              value={clientFilter}
              onChange={(e) => setClientFilter(e.target.value)}
              onKeyPress={this.handleKeyPress}
              placeholder={intl.formatMessage(messages.clientFilterPlaceholder)}
            />
            <Button
              onClick={searchClients}
              className="AccountModalClientDropdown__filter-button"
              block
            >
              {intl.formatMessage(messages.clientFilterButton)}
            </Button>
          </InputGroup>
          <ContentForState state={loading} forLoaded={this.renderItems} />
        </DropdownSelect>
      </div>
    );
  }
}

AccountModalClientDropdown.propTypes = {
  client: modelOf(Client),
  clients: PropTypes.oneOfType([
    MobxPropTypes.observableArrayOf(modelOf(Client)),
    PropTypes.arrayOf(modelOf(Client))
  ]),
  clientFilter: PropTypes.string.isRequired,
  setClientFilter: PropTypes.func.isRequired,
  searchClients: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(AccountModalClientDropdown);
