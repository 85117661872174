import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const ProductSizeGuideModal = ({ guide, toggle, isOpen }) => {
  return (
    <Modal
      className="ProductSizeGuideModal"
      size="lg"
      toggle={toggle}
      isOpen={isOpen}
    >
      <ModalHeader toggle={toggle}>
        <div className="ProductSizeGuideModal__header">{guide.name}</div>
      </ModalHeader>
      <ModalBody>
        <div
          key={guide.name}
          className="ProductSizeGuideModal__content"
          dangerouslySetInnerHTML={{
            __html: guide.contents,
          }}
        />
      </ModalBody>
    </Modal>
  );
};

ProductSizeGuideModal.propTypes = {
  guide: PropTypes.object.isRequired,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default inject('configStore')(ProductSizeGuideModal);
