import { maxBy, minBy } from 'lodash';
import { types } from 'mobx-state-tree';

import ChildProduct from './ChildProduct';
import ProductProperty from './product/ProductProperty';

const ProductMulti = types
  .model('ProductMulti', {
    properties: types.array(ProductProperty),
    children: types.array(types.late(ChildProduct)),
  })
  .actions((self) => {
    return {
      setChildren(children) {
        self.children = children;
      },
    };
  })
  .views((self) => {
    return {
      canUseMatrixLayout() {
        const count = self.getMultiElementProperties().length;
        return count >= 1 && count < 5;
      },
      childrenIncludesId: (id) => {
        return self.children.includes(id);
      },
      findChild(activeProductId) {
        return self.children.find((child) => child.id === activeProductId);
      },
      findChildWithProperties(propertyElementMap) {
        return self.children.find((childProduct) =>
          childProduct.hasProperties(propertyElementMap)
        );
      },
      getChildIdentifiers() {
        return self.children.map((child) => child.id);
      },
      getChildWithBestDiscount() {
        return maxBy(self.children, 'price_info.discount_percentage');
      },
      getMinMaxPrice(withTax) {
        const getPrice = (childProduct) => {
          if (!childProduct.price_info) {
            return;
          }

          return childProduct.price_info.getPrice(withTax);
        };

        const minProduct = minBy(self.children, getPrice);
        const maxProduct = maxBy(self.children, getPrice);

        return {
          min: minProduct ? minProduct.price_info : 0.0,
          max: maxProduct ? maxProduct.price_info : 0.0,
        };
      },
      getMultiElementProperties() {
        return self.properties.filter(
          (property) => property.elements.length > 1
        );
      },
      getPropertyElementsById(elementId) {
        return self.properties.find((property) => property.id === elementId);
      },
      getPropertyElementStatus(propertyElementMap) {
        let status = {
          exists: false,
          available: false,
        };

        // propertyElementMap contains all the properties user has chosen
        const matchingChildren = self.children.filter((currentChild) =>
          currentChild.hasProperties(propertyElementMap)
        );

        if (matchingChildren.length > 0) {
          status.exists = true;
        }

        status.available = matchingChildren.some(
          (currentChild) => currentChild.free_quantity > 0
        );

        return status;
      },
      getSecondaryPriceRange(withTax) {
        return self.getMinMaxPrice(withTax);
      },
      hasPriceRange(withTax) {
        const priceRange = self.getMinMaxPrice(withTax);

        if (priceRange && priceRange.min && priceRange.max) {
          return priceRange.min !== priceRange.max;
        }

        return false;
      },
      get priceRange() {
        return self.getMinMaxPrice();
      },
    };
  });

export default ProductMulti;
