import React from 'react';
import { inject, observer } from 'mobx-react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import UIStore from '../../../store/UIStore';
import ConfigStore from '../../../store/ConfigStore';

const TimerMessage = ({ configStore, uiStore }) => {
  const isOpen = !!uiStore.globalUserMessageData.defaultMessage;

  const getContent = () => {
    return (
      <Toast className="App__toast alert-danger" isOpen={isOpen}>
        <ToastHeader>{configStore.store.name}</ToastHeader>
        <ToastBody>
          {isOpen && <FormattedMessage {...uiStore.globalUserMessageData} />}
        </ToastBody>
      </Toast>
    );
  };

  return getContent();
};

TimerMessage.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
};

export default inject('configStore', 'uiStore')(observer(TimerMessage));
