import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import LayoutBoxStore from '../../../store/LayoutBoxStore';
import UIStore from '../../../store/UIStore';
import SidebarNavigation from '../../common/SidebarNavigation';
import ManufacturerBox from '../../layout-box/ManufacturerBox';
import SidebarBanner from '../../ad/SidebarBanner';
import CampaignCodeBox from '../../layout-box/CampaignCodeBox';

const LayoutBoxComponents = {
  categories_new: SidebarNavigation,
  categories_minimized: SidebarNavigation,
  manufacturers: ManufacturerBox,
  campaign_code: CampaignCodeBox,
};

const DefaultSidebar = ({ layoutBoxStore, bannerSearchParams, uiStore }) => {
  const getLayoutBox = (layoutBox) => {
    if (LayoutBoxComponents[layoutBox.key]) {
      const Component = LayoutBoxComponents[layoutBox.key];
      return <Component title={layoutBox.title} />;
    }
  };

  if (uiStore.isMobile) {
    return null;
  }

  return (
    <div className="DefaultSidebar">
      {layoutBoxStore.layoutBoxes.map((layoutBox) => (
        <div className="DefaultSidebar__box" key={layoutBox.key}>
          {getLayoutBox(layoutBox)}
        </div>
      ))}
      {bannerSearchParams && (
        <SidebarBanner searchParams={bannerSearchParams} />
      )}
    </div>
  );
};

DefaultSidebar.propTypes = {
  layoutBoxStore: modelOf(LayoutBoxStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  bannerSearchParams: PropTypes.object,
};

export default inject('layoutBoxStore', 'uiStore')(observer(DefaultSidebar));
