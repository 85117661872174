import React from 'react';
import Skeleton from 'react-loading-skeleton';

const MobilePageSkeleton = () => {
  return (
    <div className="MobilePageSkeleton">
      <Skeleton height={40} />
      <div className="Skeleton__spacer" />
      <Skeleton height={20} />
      <div className="Skeleton__spacer" />
      <Skeleton count={1.6} />
      <div className="Skeleton__spacer" />
      <Skeleton height={window.innerWidth * 1.3} />
    </div>
  );
};

export default MobilePageSkeleton;
