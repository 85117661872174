import React from 'react';
import { observer } from 'mobx-react';
import { Nav, NavItem } from 'reactstrap';
import PropTypes from 'prop-types';

import NavigationEntity from '../../../types/NavigationEntity';
import NavigationEntityItem from '../../navigation/NavigationEntityItem';

const MenuItemList = ({ items }) => {
  const navItems = items.map((item, index) => {
    const { id, children, active } = item;

    return (
      <NavItem key={`${id}_${index}`}>
        <NavigationEntityItem bottomNavigation entity={item} />
        {active && children && children.length > 0 && (
          <MenuItemList items={children} />
        )}
      </NavItem>
    );
  });
  return (
    <Nav className="MenuItemList flex-column" vertical>
      {navItems}
    </Nav>
  );
};

MenuItemList.propTypes = {
  items: PropTypes.arrayOf(NavigationEntity).isRequired,
};

export default observer(MenuItemList);
