import React from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, useRouteMatch, Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import SectionPage from '../../../pages/SectionPage';
import Paths from '../../../types/Paths';
import Section from '../../../models/Section';
import useSectionDependantRoutes from '../../../hooks/routes/useSectionDependantRoutes';
import ConfigStore from '../../../store/ConfigStore';
import SectionAwareRoute from '../SectionAwareRoute';

const SectionRoute = ({ configStore, section, overrideComponent }) => {
  const match = useRouteMatch();
  const sectionDependantRoutes = useSectionDependantRoutes({
    configStore,
    section,
    match: match.path,
    overrideComponent,
  });

  return (
    <Switch>
      <Route
        key={section.id}
        exact
        path={match.path}
        children={() => (
          <SectionAwareRoute
            section={section}
            component={overrideComponent || <SectionPage section={section} />}
          />
        )}
      />
      {sectionDependantRoutes}
      <Route key={Paths.FrontPage} children={() => <Redirect to="/" />} />
    </Switch>
  );
};

SectionRoute.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  section: modelOf(Section).isRequired,
  overrideComponent: PropTypes.node,
};

export default inject('configStore')(observer(SectionRoute));
