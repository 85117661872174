import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';

import globalTranslations from '../../../i18n/globalTranslations';
import Icon from '../../common/Icon';
import ProductListStyle from '../../../types/ProductListStyle';
import ProductListSortSelection from '../ProductListSortSelection';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';

const ProductListTools = ({
  configStore,
  countOfProducts,
  onSelectSort,
  selectedSortOrderBy,
  selectedSortOrder,
  showSort,
}) => {
  const setStyleSelect = (style) => {
    configStore.setProductListStyle(style);
  };

  const getStyleSelect = (icon, selectedStyle) => {
    const style = configStore.productList.style;

    return (
      <span
        onClick={() => setStyleSelect(selectedStyle)}
        className="ProductListTools__style-select"
      >
        <Icon className={style === selectedStyle ? 'active' : ''} name={icon} />
      </span>
    );
  };

  return (
    <div className="ProductListTools">
      <div className="ProductListTools__product-count">
        <FormattedMessage
          {...globalTranslations.productCount}
          values={{ count: countOfProducts }}
        />
      </div>
      {showSort && (
        <ProductListSortSelection
          onSelectSort={onSelectSort}
          selectedSortKey={selectedSortOrderBy}
          selectedSortOrder={selectedSortOrder}
        />
      )}
      <div className="ProductListTools__style-selects">
        {getStyleSelect('th', ProductListStyle.CARD)}
        {getStyleSelect('th-large', ProductListStyle.CARD_LARGE)}
      </div>
    </div>
  );
};

ProductListTools.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  onSelectSort: PropTypes.func,
  selectedSortOrderBy: PropTypes.string,
  selectedSortOrder: PropTypes.string,
  countOfProducts: PropTypes.number,
  showSort: PropTypes.bool,
};

ProductListTools.defaultProps = {
  showSort: true,
  onSelectSort: () => {},
};

export default inject('configStore')(observer(ProductListTools));
