import { types } from 'mobx-state-tree';

const NavigationEntityPlacement = {
  TOP_NAVIGATION: 'TOP_NAVIGATION',
  SEARCH_BAR: 'SEARCH_BAR',
};

export default NavigationEntityPlacement;

export const NavigationEntityPlacementType = types.enumeration(
  'NavigationEntityPlacement',
  Object.values(NavigationEntityPlacement)
);
