import { types } from 'mobx-state-tree';

const ServiceOptionType = {
  RADIO: 'RADIO',
  CHECKBOX: 'CHECKBOX',
  DROPDOWN: 'DROPDOWN'
};

export default ServiceOptionType;

export const ServiceOptionTypeType = types.enumeration(
  'ServiceOptionType',
  Object.values(ServiceOptionType)
);
