import React from 'react';
import PropTypes from 'prop-types';
import NavigationCartButton from './NavigationCartButton';
import NavigationCartText from './NavigationCartText';

/*
* Spread rest to conveniently pass props to components without
* prop drilling
*/
const NavigationCart = ({ isText, ...rest }) => {
  if (isText) {
    return <NavigationCartText {...rest} />;
  }

  return <NavigationCartButton {...rest} />;
};

NavigationCart.propTypes = {
  isText: PropTypes.bool,
};

export default NavigationCart;
