import { getEnv, getParent, types } from 'mobx-state-tree';

import StatefulStore from '../models/StatefulStore';
import PageView from '../models/analytics/PageView';

const TrackingStore = StatefulStore.named('TrackingStore')
  .props({
    pageView: types.optional(PageView, {}),
  })
  .actions((self) => {
    const baseApi = 'tracking/';

    const setStoreLoadingState = (loading) => self.setLoading(loading);

    const getLang = () => {
      return getParent(self).languageStore.activeLanguage.code;
    };

    const setPageView = (pageView) => {
      const lang = getLang();
      self.pageView = {
        ...pageView,
        lang,
      };
    };

    const postPageView = () => {
      setStoreLoadingState(true);
      if (!self.pageView.lang || !self.pageView.visitedUrl) {
        return null;
      }

      try {
        getEnv(self)
          .apiWrapper.apiAxios()
          .post(`${baseApi}page-view`, self.pageView);
      } catch (error) {
        self.setError(error);
        throw error;
      }

      setStoreLoadingState(false);
    };

    const postGraphAPIServerEvent = (data) => {
      try {
        getEnv(self).apiWrapper.apiAxios().post(`${baseApi}fb`, {
          data,
        });
      } catch (error) {
        self.setError(error);
        throw error;
      }
    };

    return {
      setPageView,
      postPageView,
      postGraphAPIServerEvent,
    };
  });

export default TrackingStore;
