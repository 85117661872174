import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CustomCol from '../../bootstrap/CustomCol';

const gridPropType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.object,
]);

const defaultGridProps = {
  xs: { size: 12 },
  md: { size: 3 },
  xl: { size: 2 },
};

const FooterColumn = ({ title, children, className, ...rest }) => {
  return (
    <CustomCol
      {...defaultGridProps}
      className={classNames('FooterColumn', className)}
      {...rest}
    >
      {title && <h5>{title}</h5>}
      {children}
    </CustomCol>
  );
};

FooterColumn.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  xs: gridPropType,
  sm: gridPropType,
  md: gridPropType,
  lg: gridPropType,
  xl: gridPropType,
  xxl: gridPropType,
  xxxl: gridPropType,
};

export default FooterColumn;
