import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

@observer
export class RecurringDaysDropdown extends Component {
  render() {
    const { onChange, minDays, selectedValue } = this.props;

    return (
      <FormGroup>
        <Label for="RecurringDaysDropdown__days-label">
          <FormattedMessage
            id="recurringOrder.selectDays"
            defaultMessage="Interval. (Minimum of {days} days)"
            values={{
              days: minDays
            }}
          />
        </Label>
        <div className="RecurringDaysDropdown__text-box">
          <InputGroup>
            <Input
              type="number"
              value={selectedValue}
              onChange={onChange}
              min={0}
              className="RecurringDaysDropdown__text-box-input--with-addon"
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <FormattedMessage
                  id="recurringOrder.days"
                  defaultMessage="days"
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
      </FormGroup>
    );
  }
}

RecurringDaysDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  minDays: PropTypes.number.isRequired,
  selectedValue: PropTypes.number
};

RecurringDaysDropdown.defaultProps = {};

export default RecurringDaysDropdown;
