import { autorun } from 'mobx';

import GoogleAnalytics4EventHandler from './GoogleAnalytics4/GoogleAnalytics4EventHandler';
import GoogleUniversalAnalyticsEventHandler from './GoogleUniversalAnalytics/GoogleUniversalAnalyticsEventHandler';

const dimension1 = {
  VISITOR: 'Vierailija-asiakas',
  LOGGED_IN_USER: 'Kirjautunut asiakas',
};

const dimension2 = {
  RETAILER: 'Tukkuasiakas',
  CONSUMER: 'Kuluttaja-asiakas',
};

export default class GoogleAnalyticsGTM {
  /**
   * Private Fields
   */
  #accountStore;
  #campaignCodeStore;
  #configStore;
  #currencyStore;
  #sectionStore;
  #countryStore;

  constructor(
    accountStore,
    campaignCodeStore,
    configStore,
    currencyStore,
    sectionStore,
    countryStore
  ) {
    this.#accountStore = accountStore;
    this.#campaignCodeStore = campaignCodeStore;
    this.#configStore = configStore;
    this.#currencyStore = currencyStore;
    this.#sectionStore = sectionStore;
    this.#countryStore = countryStore;

    this.#initializeDataLayer();

    autorun(() => {
      const initialDataLayer = {
        loggedInStatus: accountStore.loggedIn
          ? dimension1.LOGGED_IN_USER
          : dimension1.VISITOR,
        customerType: accountStore.isRetailer
          ? dimension2.RETAILER
          : dimension2.CONSUMER,
        customerGroup: accountStore.account.group_name,
      };

      window.dataLayer.push(initialDataLayer);
    });
  }
  setup = () => {
    !this.#configStore.analytics.ga4.enabled &&
      this.#addUniversalAnalyticsEventListener();

    this.#configStore.analytics.ga4.enabled &&
      this.#addGoogleAnalytics4EventListener();
  };

  #initializeDataLayer = () => {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
  };

  #addUniversalAnalyticsEventListener = () => {
    const analyticsEventHandler = new GoogleUniversalAnalyticsEventHandler(
      this.#configStore,
      this.#sectionStore,
      this.#countryStore
    );
    this.#addEventListener(analyticsEventHandler);
  };

  #addGoogleAnalytics4EventListener = () => {
    const analyticsEventHandler = new GoogleAnalytics4EventHandler(
      this.#accountStore,
      this.#campaignCodeStore,
      this.#configStore,
      this.#currencyStore,
      this.#sectionStore,
      this.#countryStore
    );
    this.#addEventListener(analyticsEventHandler);
  };

  /**
   *
   * @param {GoogleUniversalAnalyticsEventHandler|GoogleAnalytics4EventHandler} analyticsEventHandler
   */
  #addEventListener = (analyticsEventHandler) => {
    document.addEventListener(
      'analytics',
      analyticsEventHandler.handleAnalyticsEvents,
      true
    );
  };
}
