import React, { Component } from 'react';

import LocaleFlagIcon from '../LocaleFlagIcon';
import { modelOf } from '../../../prop-types';
import Language from '../../../models/Language';

class RegionalSettingsLanguageDropdownItem extends Component {
  render() {
    const { language } = this.props;
    return (
      <span>
        <LocaleFlagIcon locale={language.code} /> {language.name}
      </span>
    );
  }
}

RegionalSettingsLanguageDropdownItem.propTypes = {
  language: modelOf(Language).isRequired
};

export default RegionalSettingsLanguageDropdownItem;
