import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';

import NavigationCart from '../../cart/NavigationCart/NavigationCartButton';
import CartStore from '../../../store/CartStore';
import { modelOf } from '../../../prop-types';
import Countdown from '../../common/Countdown';

@observer
export class FooterCartExpirationBar extends Component {
  state = {
    expired: false,
  };

  render() {
    return (
      <Container className="FooterCartExpirationBar">
        <Row>
          <Col className="col-xs-12">
            {this.state.expired ? (
              <FormattedMessage
                id="footer.cartExpirationExpired"
                defaultMessage="Reservation for the items in your cart has expired."
              />
            ) : (
              <span>
                <FormattedMessage
                  id="footer.cartExpirationPending"
                  defaultMessage="Reservation for the items in your cart expires in"
                />{' '}
                <Countdown
                  expiresOn={new Date(this.props.cartStore.cart.expires_at)}
                  onExpiry={() => this.setState({ expired: true })}
                />
              </span>
            )}
            <NavigationCart />
          </Col>
        </Row>
      </Container>
    );
  }
}

FooterCartExpirationBar.propTypes = {
  cartStore: modelOf(CartStore).isRequired,
};

export default inject('cartStore')(FooterCartExpirationBar);
