import { DateTime } from 'luxon';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Container } from 'reactstrap';

import ContentPopups from '../../components/ad/ContentPopups';
import MainBanner from '../../components/ad/MainBanner';
import HelperNavigation from '../../components/common/HelperNavigation';
import PageTitle from '../../components/common/PageTitle';
import ScrollReset from '../../components/common/ScrollReset';
import CanonicalLink from '../../components/head/CanonicalLink';
import RobotsMeta from '../../components/head/RobotsMeta';
import SEOTitle from '../../components/head/SEOTitle';
import GenericLayout from '../../components/layout/GenericLayout';
import Page from '../../components/layout/Page';
import MainProductList from '../../components/product-list/MainProductList';
import useShouldShowBanner from '../../hooks/banner/useShouldShowBanner';
import globalTranslations from '../../i18n/globalTranslations';
import { modelOf } from '../../prop-types';
import RouteService from '../../services/RouteService';
import AdStore from '../../store/AdStore';
import ConfigStore from '../../store/ConfigStore';
import SectionStore from '../../store/SectionStore';
import AdPages from '../../types/AdPages';
import AdZones from '../../types/AdZones';
import CommonPage from '../../types/CommonPage';
import Paths from '../../types/Paths';
import ProductSortOrderBy from '../../types/ProductSortOrderBy';
import SortOrder from '../../types/SortOrder';

const NewProductsPage = ({
  adStore,
  configStore,
  sectionStore,
  intl,
  routeService,
}) => {
  useEffect(() => {
    if (useShouldShowBanner({ adStore, page: AdPages.NEW_PRODUCTS })) {
      loadAds();
    }
  }, []);

  const loadAds = () => {
    adStore.loadAds(getSearchParams());
  };

  const getBreadcrumbsPath = () => {
    return routeService.transformBreadCrumbs([
      {
        text: intl.formatMessage(globalTranslations.newProductsTitle),
        url: Paths.NewProducts,
      },
    ]);
  };

  const getSearchParams = () => {
    return {
      bannerPage: AdPages.NEW_PRODUCTS,
    };
  };

  const listId = 'new_products_list';
  const listName = 'New Products List';
  const fixedParams = {
    orderBy: ProductSortOrderBy.DATE_ADDED,
    order: SortOrder.DESC,
    dateAdded: {
      // We set second and millisecond to 0 in order for the query key not to
      // change every (milli)second
      min: DateTime.fromObject({
        second: 0,
        millisecond: 0,
      })
        .minus({ seconds: configStore.product.isNewForSeconds })
        .toISO(),
    },
  };

  if (sectionStore.activeSection) {
    fixedParams.sections = [sectionStore.activeSection.id];
  }

  const getAdditionalContent = () => {
    return { robotsMeta: RobotsMeta };
  };

  const mainBanner = useShouldShowBanner({
    adStore,
    page: AdPages.NEW_PRODUCTS,
    zone: AdZones.MAIN_BANNER,
  }) ? (
    <MainBanner searchParams={getSearchParams()} />
  ) : null;

  const mainBannerWithinContent =
    configStore.banner.mainBanner.withinContent &&
    !configStore.banner.mainBanner.fullWidth;

  return (
    <Page name={CommonPage.NEW_PRODUCTS} className="NewProductsPage">
      <ScrollReset />
      <SEOTitle
        title={{
          ...globalTranslations.newProductsTitle,
        }}
      />
      <CanonicalLink path={routeService.getPath(Paths.NewProducts)} />

      {!mainBannerWithinContent && mainBanner}
      <Container className="NewProductsPage__container">
        <GenericLayout
          bannerSearchParams={
            useShouldShowBanner({
              adStore,
              page: AdPages.NEW_PRODUCTS,
              zone: AdZones.SIDEBAR_BANNER,
            })
              ? getSearchParams()
              : null
          }
          content={
            <>
              {mainBannerWithinContent && mainBanner}
              <HelperNavigation breadcrumbsPath={getBreadcrumbsPath()} />
              <PageTitle>
                <FormattedMessage {...globalTranslations.newProductsTitle} />
              </PageTitle>
              <MainProductList
                fixedParams={fixedParams}
                listId={listId}
                name={listName}
                showSort={false}
                additionalContent={getAdditionalContent()}
              />
              {useShouldShowBanner({
                adStore,
                page: AdPages.NEW_PRODUCTS,
                zone: AdZones.POPUP_CONTENT,
              }) && <ContentPopups searchParams={getSearchParams()} />}
            </>
          }
        />
      </Container>
    </Page>
  );
};

NewProductsPage.propTypes = {
  adStore: modelOf(AdStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  intl: intlShape.isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
};

export default injectIntl(
  inject(
    'adStore',
    'configStore',
    'sectionStore',
    'routeService'
  )(observer(NewProductsPage))
);
