import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { modelOf } from '../../../../prop-types';
import ProductImageModel from '../../../../models/ProductImage';
import useImageMagnifier from '../../../../hooks/image/useImageMagnifier';
import ProductImage from '../../../product/ProductImage';
import { REDUCER_ACTIONS } from '../lightboxReducer';

export const initialImageOrigin = { x: 50, y: 50 };

const ImageLightboxProductImage = ({
  product,
  image,
  imageScale,
  imageFocus,
  dispatch,
}) => {
  const [updateImagePosition, resetImagePosition, imageOrigin] =
    useImageMagnifier();

  const toggleFocus = useCallback(
    (event) => {
      if (imageFocus) {
        unfocus();
        return;
      }

      dispatch({
        type: REDUCER_ACTIONS.IMAGE_FOCUSED,
      });
      updateImagePosition(event);
    },
    [imageFocus]
  );

  const unfocus = useCallback(() => {
    dispatch({
      type: REDUCER_ACTIONS.IMAGE_UNFOCUSED,
    });
    resetImagePosition();
  }, [imageFocus]);

  const imageStyles = {
    transition: `all 0ms ease 0ms`,
    transform: `scale(${imageScale})`,
    transformOrigin: imageFocus
      ? `${imageOrigin.x}% ${imageOrigin.y}%`
      : `50% 50%`,
    cursor: imageFocus ? 'move' : 'pointer',
  };

  const imageSize = () => {
    return (image?.sizes?.full || image?.sizes?.large) ?? '';
  };

  return (
    <ProductImage
      key={image.id}
      onMouseMove={updateImagePosition}
      product={product.getActualProduct(image.product_id) || product}
      productImage={image}
      size={imageSize()}
      lazyLoading={false}
      forceAspectRatio={false}
      imageStyles={imageStyles}
      onClick={toggleFocus}
      draggable={false}
    />
  );
};

ImageLightboxProductImage.propTypes = {
  product: PropTypes.object.isRequired,
  image: modelOf(ProductImageModel).isRequired,
  imageFocus: PropTypes.bool.isRequired,
  imageScale: PropTypes.number.isRequired,
};

export default ImageLightboxProductImage;
