import React from 'react';
import { inject, observer } from 'mobx-react';
import ReCAPTCHA from 'react-google-recaptcha';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import UIStore from '../../../store/UIStore';
import LanguageStore from '../../../store/LanguageStore';

const RecaptchaField = ({ configStore, languageStore, uiStore, ...rest }) => {
  const size = 'invisible';

  return (
    <ReCAPTCHA
      className={classNames('RecaptchaField', {
        'RecaptchaField--error': uiStore.recaptchaValidation.hasError,
      })}
      ref={uiStore.recaptchaValidation.setRef}
      sitekey={configStore.recaptcha.sitekey}
      size={size}
      onChange={uiStore.recaptchaValidation.onChange}
      onErrored={uiStore.recaptchaValidation.onError}
      hl={languageStore.activeLanguage?.code || null}
      {...rest}
    />
  );
};

RecaptchaField.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  languageStore: modelOf(LanguageStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
};

export default inject(
  'configStore',
  'languageStore',
  'uiStore'
)(observer(RecaptchaField));
