import { range } from 'lodash';

const usePaginator = ({ lastPage, pagesToShow, currentPage, pagesToHide }) => {
  const firstPage = 1;
  const actualPagesToShow = Math.min(lastPage, pagesToShow);
  const maxItemsOnRight = lastPage - currentPage;
  const pagesToLeft = Math.max(
    Math.floor(actualPagesToShow / pagesToHide),
    actualPagesToShow - maxItemsOnRight - 1
  );
  const leftMostPage = Math.max(currentPage - pagesToLeft, 1);
  const firstMiddlePage = leftMostPage + 1;

  let middlePages = [];
  const numberOfMiddlePages = actualPagesToShow - pagesToHide;

  if (numberOfMiddlePages >= 1) {
    middlePages = range(firstMiddlePage, firstMiddlePage + numberOfMiddlePages);
  }

  const lastMiddlePage = lastPage - (middlePages[middlePages.length - 1] ?? 0);

  const previousPage =
    currentPage > lastPage
      ? lastPage
      : currentPage <= 1
      ? currentPage
      : currentPage - 1;
  const nextPage = currentPage >= lastPage ? lastPage : currentPage + 1;
  const leftMorePages = firstMiddlePage - firstPage > pagesToHide;
  const rightMorePages = lastMiddlePage > pagesToHide;

  return {
    previousPage,
    nextPage,
    leftMorePages,
    rightMorePages,
    middlePages,
    lastMiddlePage,
  };
};

export default usePaginator;
