import { getRoot, resolveIdentifier, types } from 'mobx-state-tree';
import Currency from './Currency';

// TODO: current values for "name" should be in some kind of short code field instead and a proper name field be added.
// If we also want to show flags in the menu, a third field for that (2-letter code) would be needed as well.
const Country = types
  .model('Country', {
    id: types.identifierNumber,
    name: types.string,
    iso_code_2: types.string,
    currency_code: types.maybeNull(types.string)
  })
  .views((self) => ({
    get currency() {
      if (!self.currency_code) {
        return null;
      }

      return resolveIdentifier(Currency, getRoot(self), self.currency_code);
    }
  }));

export default Country;
