import { types } from 'mobx-state-tree';

import RequestState, { RequestStateType } from '../types/RequestState';
import { createErrorModel } from '../util/error';
import Error from './Error';

const StatefulStore = types
  .model('StatefulStore', {
    state: types.optional(RequestStateType, RequestState.NONE),
    lastError: types.maybeNull(Error)
  })
  .actions((self) => {
    return {
      setError(error) {
        self.lastError = createErrorModel(error);
        self.state = RequestState.ERROR;
      },
      setLoading(loading) {
        self.lastError = null;
        self.state = loading ? RequestState.LOADING : RequestState.LOADED;
      }
    };
  });

export default StatefulStore;
