import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import classNames from 'classnames';
import { inject } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import CustomCol from '../../bootstrap/CustomCol';
import ConfigStore from '../../../store/ConfigStore';

const SidebarLayout = ({
  configStore,
  sidebar,
  content,
  className,
  forceSidebar,
  sidebarPlaceHolder,
  contentPlaceHolder,
}) => {
  const renderCustomCol = (node, sizes, placeholder = null) => {
    return <CustomCol {...sizes}>{node || placeholder}</CustomCol>;
  };

  const fullWidthPage =
    configStore.navigation.sidebar.showFullWidthPageContent && !forceSidebar;

  return (
    <Row className={classNames('SidebarLayout', className)}>
      {!fullWidthPage &&
        renderCustomCol(
          sidebar,
          {
            md: 12,
            lg: 3,
            xxl: 3,
            className: 'SidebarLayout__left',
          },
          sidebarPlaceHolder
        )}
      {fullWidthPage
        ? renderCustomCol(content, { md: 12 })
        : renderCustomCol(
            content,
            {
              md: 12,
              lg: 9,
              xxl: 9,
              className: 'SidebarLayout__right',
            },
            contentPlaceHolder
          )}
    </Row>
  );
};

SidebarLayout.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  sidebar: PropTypes.node,
  content: PropTypes.node,
  className: PropTypes.string,
  // Ignores full width configuration and forces sidebar to be used (ex. MyAccountPage)
  forceSidebar: PropTypes.bool,
  sidebarPlaceHolder: PropTypes.node,
  contentPlaceHolder: PropTypes.node,
};

export default inject('configStore')(SidebarLayout);
