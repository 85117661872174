import { types } from 'mobx-state-tree';

const ProductImageSizes = types.model('ProductImageSizes', {
  small: types.maybeNull(types.string),
  medium: types.maybeNull(types.string),
  large: types.maybeNull(types.string),
  full: types.maybeNull(types.string),
});

const ProductImage = types.model('ProductImage', {
  id: types.number,
  product_id: types.string,
  sizes: ProductImageSizes,
  description: types.optional(types.string, ''),
  for_color_id: types.maybeNull(types.number),
});

export default ProductImage;
