import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label
} from 'reactstrap';
import { modelOf } from '../../../../../prop-types';
import InstantShoppingCartStore from '../../../../../store/InstantShoppingCartStore';
import ShippingOptionSelectionField from '../../../../../models/ShippingOptionSelectionField';

@observer
export class KlarnaInstantShoppingDropdownMenu extends Component {
  updateSelectionFieldOption = (option) => {
    const { onUpdateSelectionFieldOption } = this.props;

    onUpdateSelectionFieldOption && onUpdateSelectionFieldOption(option);
  };

  render() {
    const {
      instantShoppingCartStore,
      selectionFieldDropdownOpen,
      toggleSelectionFieldDropdown,
      selectionField
    } = this.props;
    const selectedSelectionFieldOption =
      instantShoppingCartStore.selectedSelectionFieldOption;

    if (!selectedSelectionFieldOption) {
      return null;
    }

    return (
      <Fragment>
        <Label className="KlarnaInstantShoppingModal__selection-field-label">
          {selectionField.text}
          {':'}
        </Label>
        <Dropdown
          isOpen={selectionFieldDropdownOpen}
          toggle={toggleSelectionFieldDropdown}
        >
          <Fragment>
            <DropdownToggle
              className="KlarnaInstantShoppingModal__selection-field-dropdown-toggle"
              caret
            >
              {selectedSelectionFieldOption.name}
            </DropdownToggle>
            <DropdownMenu className="KlarnaInstantShoppingModal__selection-field-options-dropdown-menu">
              {selectionField.options.map(
                (option) =>
                  option && (
                    <DropdownItem
                      key={option.id}
                      onClick={this.updateSelectionFieldOption.bind(
                        this,
                        option
                      )}
                    >
                      <div className="KlarnaInstantShoppingModal__selection-field-option">
                        {option.name}
                      </div>
                    </DropdownItem>
                  )
              )}
            </DropdownMenu>
          </Fragment>
        </Dropdown>
      </Fragment>
    );
  }
}

KlarnaInstantShoppingDropdownMenu.propTypes = {
  instantShoppingCartStore: modelOf(InstantShoppingCartStore).isRequired,
  selectionField: modelOf(ShippingOptionSelectionField).isRequired,
  selectionFieldDropdownOpen: PropTypes.bool.isRequired,
  toggleSelectionFieldDropdown: PropTypes.func.isRequired,
  onUpdateSelectionFieldOption: PropTypes.func.isRequired
};

export default inject('instantShoppingCartStore')(
  KlarnaInstantShoppingDropdownMenu
);
