let ANONYMOUS = '<<anonymous>>';

// Based on: https://github.com/facebook/prop-types/blob/534a07a3a52d2447e2a65ebf9f7635d858682ee0/factoryWithTypeCheckers.js
export default function createChainableTypeChecker(validate) {
  function checkType(
    isRequired,
    props,
    propName,
    componentName,
    location,
    propFullName
  ) {
    componentName = componentName || ANONYMOUS;
    propFullName = propFullName || propName;

    if (props[propName] == null) {
      if (isRequired) {
        if (props[propName] === null) {
          return new Error(
            `The ${location} "${propFullName}" is marked as required in "${componentName}", but its value is "null".'`
          );
        }
        return new Error(
          `The ${location} "${propFullName}" is marked as required in "${componentName}", but its value is "undefined".`
        );
      }
      return null;
    } else {
      return validate(props, propName, componentName, location, propFullName);
    }
  }

  var chainedCheckType = checkType.bind(null, false);
  chainedCheckType.isRequired = checkType.bind(null, true);

  return chainedCheckType;
}
