import { types } from 'mobx-state-tree';

const PhoneNumber = {
  NORMAL: 'NORMAL',
  WHATSAPP: 'WHATSAPP'
};

export default PhoneNumber;

export const PhoneNumberType = types.enumeration(
  'PhoneNumber',
  Object.values(PhoneNumber)
);
