import { types } from 'mobx-state-tree';

const BannerType = {
  CATEGORY: 'CATEGORY',
  CONTENT: 'CONTENT',
  PRODUCT: 'PRODUCT',
  NORMAL: 'NORMAL',
  TEXT: 'TEXT',
  VIDEO: 'VIDEO',
};

export default BannerType;

export const BannerTypeType = types.enumeration(
  'BannerTypeType',
  Object.values(BannerType)
);
