import classNames from 'classnames';
import { debounce } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { matchPath, withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';
import Sticky from 'react-sticky-el';
import { Container, Navbar } from 'reactstrap';

import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import RouteService from '../../../services/RouteService';
import AccountStore from '../../../store/AccountStore';
import CategoryStore from '../../../store/CategoryStore';
import ConfigStore from '../../../store/ConfigStore';
import CustomNavigationLinkStore from '../../../store/CustomNavigationLinkStore';
import MenuStore from '../../../store/MenuStore';
import SectionStore from '../../../store/SectionStore';
import UIStore from '../../../store/UIStore';
import LinkType from '../../../types/LinkType';
import MobileNavigationTab from '../../../types/MobileNavigationTab';
import Paths from '../../../types/Paths';
import RequestState from '../../../types/RequestState';
import { createErrorModel } from '../../../util/error';
import Icon from '../../common/Icon';
import ContentForState from '../../loader/ContentForState';
import NavigationMenuRow from '../../navigation/NavigationMenuRow';

@observer
export class CheckoutNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuStoreError: null,
      isSticky: false,
      collapse: false,
      nonStickyHeaderHeight: null,
      showSearchOnMobile: false,
      showSearchOnDesktop: false,
      inputHasFocus: false,
    };

    this.stickyElement = null;

    this.maybeLoadMenu();
  }

  componentDidMount() {
    const { uiStore } = this.props;

    // Attach resize event listener to window since ReactResizeDetector component reports resize event too early from landscape to portrait.
    !uiStore.isDesktop && !window.isSSR && this.attachResizeEventListener();
  }

  componentWillUnmount() {
    const { uiStore } = this.props;

    !uiStore.isDesktop &&
      !window.isSSR &&
      window.removeEventListener('resize', this.deviceOrientationHandler);
  }

  maybeLoadMenu = () => {
    const { menuStore } = this.props;

    if (menuStore && menuStore.checkoutMenuState === RequestState.NONE) {
      menuStore.loadCheckoutMenus().catch((error) => {
        this.setState(() => ({
          menuStoreError: createErrorModel(error),
        }));
      });
    }
  };

  attachResizeEventListener = () => {
    window.addEventListener('resize', this.deviceOrientationHandler, false);
  };

  shouldShowMobileMenu = () => {
    const { uiStore } = this.props;
    return !uiStore.isDesktop && uiStore.mobileNavigation.isOpen;
  };

  convertMenuToNavigationEntity = (menu) => {
    let label;

    if (typeof menu.title === 'string') {
      label = (
        <div
          className="CheckoutNavigation__html-content"
          dangerouslySetInnerHTML={{
            __html: menu.title,
          }}
        />
      );
    } else if (menu.title != null) {
      label = menu.title;
    } else {
      label = '';
    }

    return {
      id: menu.id,
      path: menu.url,
      type: menu.type,
      image: menu.image,
      label,
      children: menu.children.map(this.convertMenuToNavigationEntity),
      accessible_text: menu.accessible_text,
    };
  };

  getMenuBarLinks = () => {
    const { menuStore, routeService } = this.props;
    let items = [];

    if (menuStore.checkoutMenuState === RequestState.LOADED) {
      items = menuStore.checkoutMenus.map(this.convertMenuToNavigationEntity);
    }

    items.unshift({
      id: '_top',
      path: routeService.getPath(Paths.FrontPage),
      type: LinkType.INTERNAL_LINK,
      image: null,
      label: (
        <Fragment>
          <Icon name="long-arrow-left" />
          <FormattedMessage {...globalTranslations.backToStore} />
        </Fragment>
      ),
    });

    return items;
  };

  getDefaultMobileMenuTab = () => {
    const { location } = this.props;

    if (
      matchPath(location.pathname, {
        path: '/:locale?' + Paths.InfoPage,
      })
    ) {
      return MobileNavigationTab.INFO;
    }

    return MobileNavigationTab.PRODUCTS;
  };

  // This debounced function allows us to have a single timeout for all hover
  // (onMouseOut and onMouseOver) callbacks so that only the last one is called.
  //
  // This allows us to
  // 1) prevent showing MegaMenu when the user hovers over the menubar quickly
  // 2) prevent hiding MegaMenu when the user moves the mouse from the menubar
  //    to the actual MegaMenu. This is achieved by having a separate onMouseOver
  //    in the MegaMenu that overrides the last onMouseOut callback.
  //
  debouncedHover = debounce((callback) => {
    if (callback) {
      callback();
    }
  }, 200);

  renderNavigationTop = () => {
    const { menuStore } = this.props;

    return (
      <Navbar className="CheckoutNavigation__top d-none d-lg-block">
        <Container className="CheckoutNavigation__top-container">
          <div className="CheckoutNavigation__top-inner">
            <ContentForState
              state={menuStore.checkoutMenuState}
              forLoaded={() => (
                <NavigationMenuRow items={this.getMenuBarLinks()} />
              )}
            />
          </div>
        </Container>
      </Navbar>
    );
  };

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { configStore, menuStore } = this.props;

    return (
      <div>
        <Sticky
          className={classNames('CheckoutNavigation', {
            'CheckoutNavigation--mobile-navigation-open':
              this.shouldShowMobileMenu(),
            'CheckoutNavigation--forced-static': !window.isSSR,
          })}
          name="top"
          ref={(ref) => (this.stickyElement = ref)}
        >
          {!configStore.siteConfig.isHomePage && (
            <Navbar className="CheckoutNavigation__top d-block">
              <Container className="CheckoutNavigation__top-container">
                <div className="CheckoutNavigation__top-inner">
                  <ContentForState
                    state={menuStore.checkoutMenuState}
                    forLoaded={() => {
                      return (
                        <NavigationMenuRow items={this.getMenuBarLinks()} />
                      );
                    }}
                  />
                </div>
              </Container>
            </Navbar>
          )}
        </Sticky>
      </div>
    );
  }
}

CheckoutNavigation.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  categoryStore: modelOf(CategoryStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  menuStore: modelOf(MenuStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  customNavigationLinkStore: modelOf(CustomNavigationLinkStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  location: RouterPropTypes.location.isRequired,
};

export default inject(
  'accountStore',
  'categoryStore',
  'configStore',
  'menuStore',
  'sectionStore',
  'uiStore',
  'customNavigationLinkStore',
  'routeService'
)(withRouter(CheckoutNavigation));
