import { types } from 'mobx-state-tree';

const CustomNavigationLinkParentType = {
  CATEGORY: 'CATEGORY',
  CUSTOM_NAVIGATION_LINK: 'CUSTOM_NAVIGATION_LINK',
  SECTION: 'SECTION'
};

export default CustomNavigationLinkParentType;

export const CustomNavigationLinkParentTypeType = types.enumeration(
  'CustomNavigationLinkParentType',
  Object.values(CustomNavigationLinkParentType)
);
