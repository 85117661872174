import React, { useState } from 'react';
import { Col, Collapse, Row, Container } from 'reactstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import MenuItemList from '../MenuItemList';
import Icon from '../../common/Icon';
import NavigationEntity from '../../../types/NavigationEntity';
import NavigationEntityItem from '../../navigation/NavigationEntityItem';

/* NOTE: this component shows only one level menus under the menu title */
const CollapsibleMenu = ({ item, icon }) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <div
      className={classNames('CollapsibleMenu', {
        'CollapsibleMenu--has-children':
          !!item.children && item.children?.length > 0,
      })}
    >
      <Container className="CollapsibleMenu__container">
        <Row>
          <Col
            className="col-12 CollapsibleMenu__menu-col"
            onClick={() => setCollapse(!collapse)}
          >
            <NavigationEntityItem bottomNavigation entity={item} icon={icon} />
            {item.children && item.children.length > 0 && (
              <Icon name={collapse ? 'minus' : 'plus'} />
            )}
          </Col>
        </Row>
        {item.children?.length > 0 && (
          <Row>
            <Col className="col-12 CollapsibleMenu__menu-col-children">
              <Collapse isOpen={collapse}>
                <MenuItemList items={item.children} key={item.id} />
              </Collapse>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

CollapsibleMenu.propTypes = {
  item: NavigationEntity.isRequired,
  icon: PropTypes.node,
};

export default CollapsibleMenu;
