import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { modelOf } from '../../../prop-types';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import LanguageStore from '../../../store/LanguageStore';
import CurrencyStore from '../../../store/CurrencyStore';
import CountryStore from '../../../store/CountryStore';
import AccountStore from '../../../store/AccountStore';

@observer
export class RegionalSettings extends Component {
  constructor(props) {
    super(props);

    const { languageStore, currencyStore, countryStore } = props;

    this.state = {
      country: countryStore.activeCountry,
      language: languageStore.activeLanguage,
      currency: currencyStore.activeCurrency,
      effectiveCurrency: currencyStore.activeCurrency,
      isSaving: false
    };
  }

  save = () => {
    const { languageStore, accountStore } = this.props;

    this.setState(() => ({
      isSaving: true
    }));
    return languageStore
      .getRedirectedUrl(this.state.language.code)
      .then((url) => {
        return accountStore
          .updateSessionParams({
            currency: this.state.effectiveCurrency.code,
            country: this.state.country.id,
            lang: this.state.language.code
          })
          .then(() => {
            if (window.location.href !== url.href) {
              window.location = url;
            } else {
              window.location.reload();
            }
          });
      });
  };

  setLanguage = (language) => {
    this.setState({
      language
    });
  };

  setCountry = (country) => {
    let effectiveCurrency = this.state.currency;
    if (country.currency) {
      effectiveCurrency = country.currency;
    }

    this.setState({
      country,
      effectiveCurrency
    });
  };

  setCurrency = (currency) => {
    this.setState({
      currency,
      effectiveCurrency: currency
    });
  };

  onChange = (field, value) => {
    switch (field) {
      case 'language':
        this.setLanguage(value);
        break;
      case 'country':
        this.setCountry(value);
        break;
      case 'currency':
        this.setCurrency(value);
        break;
      default:
        break;
    }
  };

  render() {
    const { isSaving } = this.state;

    const effectiveState = {
      country: this.state.country,
      language: this.state.language,
      currency: this.state.effectiveCurrency
    };

    return (
      <Fragment>
        {this.props.children(
          this.save,
          this.onChange,
          effectiveState,
          isSaving
        )}
      </Fragment>
    );
  }
}

RegionalSettings.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  languageStore: modelOf(LanguageStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  countryStore: modelOf(CountryStore).isRequired,
  children: PropTypes.func.isRequired
};

export default inject(
  'accountStore',
  'countryStore',
  'currencyStore',
  'languageStore'
)(withRouter(RegionalSettings));
