import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import ConfigStore from '../../../store/ConfigStore';
import AccountStore from '../../../store/AccountStore';
import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import ProductAddToCart from '../../product/ProductAddToCart';
import CartStore from '../../../store/CartStore';
import { Col, Container, Row } from 'reactstrap';
import ProductStore from '../../../store/ProductStore';
import Icon from '../../common/Icon';
import UIStore from '../../../store/UIStore';

const NavigationAddToCartRow = ({
  accountStore,
  cartStore,
  configStore,
  productStore,
  uiStore,
  product,
  activeProductId,
}) => {
  const { addToCartRowIsVisible, isDesktop } = uiStore;
  const { mobile: mobileActive, desktop: desktopActive } =
    configStore.topNavigation.addToCartRow;
  const hideAddToCart = accountStore.isViewOnly;
  const addToCartProduct = product.getActualProduct(activeProductId) || product;
  const quantity = cartStore.productQuantity;

  if (
    !addToCartProduct ||
    !addToCartRowIsVisible ||
    hideAddToCart ||
    (isDesktop && !desktopActive) ||
    (!isDesktop && !mobileActive)
  ) {
    return null;
  }

  const getElementTopPosition = () => {
    if (!uiStore.isDesktop && uiStore.scrollingDown) {
      return 0;
    } else {
      return uiStore.isDesktop &&
        configStore.topNavigation.hideHeaderOnScrollingDown &&
        uiStore.scrollingDown
        ? 0
        : uiStore.headerHeight;
    }
  };

  const pickedCollection =
    productStore.selectedProductColumn && productStore.selectedProductRow
      ? productStore.selectedProductColumn +
        ' ' +
        productStore.selectedProductRow
      : null;

  return (
    <Container
      fluid
      className={classNames(
        'NavigationAddToCartRow',
        'NavigationAddToCartRow__container'
      )}
      style={{ top: getElementTopPosition() }}
    >
      <Row className="NavigationAddToCartRow__row">
        <Col xs={12} className="NavigationAddToCartRow__column">
          <div className="NavigationAddToCartRow__product">
            <span className="NavigationAddToCartRow__product-title">
              {product.name}
            </span>
            {quantity > 0 && (
              <div className="NavigationAddToCartRow__product-quantity">
                {pickedCollection && pickedCollection + ' '}
                {quantity + ' ' + addToCartProduct.stock_unit}
              </div>
            )}
          </div>
          <div className="NavigationAddToCartRow__cart-wrapper">
            <ProductAddToCart
              product={product}
              addToCartProduct={addToCartProduct}
              activeProductId={activeProductId}
              withQuantityPicker={false}
              withServices={false}
              quantity={quantity}
            />
          </div>
        </Col>
        {!activeProductId && (
          <Col xs={12} className="NavigationAddToCartRow__not-active">
            <div className="NavigationAddToCartRow__notifier">
              <Icon name="arrow-up" />
              <FormattedMessage
                id="addToCart.selectProductVariant"
                defaultMessage="Please choose the product variant"
              />
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
};

NavigationAddToCartRow.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  cartStore: modelOf(CartStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  product: modelOf(Product).isRequired,
  activeProductId: PropTypes.string,
};

export default inject(
  'accountStore',
  'cartStore',
  'configStore',
  'productStore',
  'uiStore'
)(observer(NavigationAddToCartRow));
