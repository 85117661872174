import React, { Component } from 'react';

import ProductImageSignDiscount from '../ProductSignDiscount';
import ProductImageSignNew from '../ProductSignNew';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class ProductSigns extends Component {
  render() {
    const { dateAdded, hasDiscount, discountProps, configStore } = this.props;

    const isNew =
      new Date() - new Date(dateAdded) <
      configStore.product.isNewForSeconds * 1000;

    return (
      <div className="ProductSigns">
        {hasDiscount && <ProductImageSignDiscount {...discountProps} />}
        {isNew && <ProductImageSignNew />}
      </div>
    );
  }
}

ProductSigns.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  dateAdded: PropTypes.string,
  hasDiscount: PropTypes.bool,
  discountProps: PropTypes.object
};

export default inject('configStore')(ProductSigns);
