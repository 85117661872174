import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet-async';

import ConfigStore from '../../store/ConfigStore';
import AccountStore from '../../store/AccountStore';
import { modelOf } from '../../prop-types';
import CriteoEventHandler from './CriteoEventHandler';

@observer
class CriteoAnalytics extends Component {
  componentDidMount() {
    const { configStore } = this.props;
    if (configStore.integrations.criteo.enabled) {
      new CriteoEventHandler(
        configStore.integrations.criteo.containerId,
        this.props.accountStore
      );
    }
  }

  render() {
    const { configStore } = this.props;
    if (!configStore.integrations.criteo.enabled) {
      return null;
    }
    return (
      <Helmet>
        <script
          type="text/javascript"
          src="//static.criteo.net/js/ld/ld.js"
          async="true"
        />
      </Helmet>
    );
  }
}

CriteoAnalytics.propTypes = {
  configStore: modelOf(ConfigStore),
  accountStore: modelOf(AccountStore),
};

export default inject('configStore', 'accountStore')(CriteoAnalytics);
