import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Button, Form, Alert, Row, Col } from 'reactstrap';
import { get, flatten } from 'lodash';

import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import { createForm } from '../../../models/Form';
import { createFormField } from '../../../models/FormField';
import { mustMatch } from '../../../util/formValidators';
import globalTranslations from '../../../i18n/globalTranslations';
import FormGroupField from '../../form/FormGroupField';
import FormValidator from '../../../services/FormValidator';
import RequestState from '../../../types/RequestState';
import UnexpectedError from '../../loader/UnexpectedError';
import AccountModalLink from '../AccountModalLink';
import AccountModalTab from '../../../types/AccountModalTab';

const labels = {
  newPassword: <FormattedMessage {...globalTranslations.newPasswordTitle} />,
  confirmPassword: (
    <FormattedMessage {...globalTranslations.confirmPasswordTitle} />
  )
};

export class SetNewPasswordForm extends Component {
  formName = 'SetNewPasswordForm';

  state = {
    requestState: RequestState.NONE,
    error: null
  };

  constructor(props) {
    super(props);

    const { formValidator } = props;

    this.form = createForm({
      newPassword: createFormField(
        {},
        formValidator.password(labels.newPassword)
      ),
      confirmPassword: createFormField({}, [
        mustMatch(() => this.form.fields.get('newPassword').value)
      ])
    });
  }

  handleSubmit = (e) => {
    const { accountStore, passwordResetToken } = this.props;

    e.preventDefault();

    this.form.validate();
    if (this.form.valid) {
      this.setState(() => ({
        requestState: RequestState.LOADING,
        error: null
      }));
      accountStore
        .setPasswordWithToken(
          passwordResetToken,
          this.form.fields.get('newPassword').value,
          this.form.fields.get('confirmPassword').value
        )
        .then(() => {
          this.setState(() => ({
            requestState: RequestState.LOADED
          }));
        })
        .catch((e) => {
          this.setState(() => ({
            requestState: RequestState.ERROR,
            error: e
          }));
        });
    }
  };

  field = (fieldName, props) => {
    const field = this.form.fields.get(fieldName);
    return (
      field.active && (
        <FormGroupField
          field={field}
          formName={this.formName}
          fieldName={fieldName}
          label={labels[fieldName]}
          {...props}
        />
      )
    );
  };

  renderError = () => {
    const { error, requestId } = this.state;
    const status = get(error, 'response.status');
    return [409, 422].includes(status) ? (
      <Alert
        className="SetNewPasswordForm__errors"
        color="danger"
        key={requestId}
      >
        {flatten(
          Object.values(error.response.data.errors || error.response.data)
        ).map((message) => <div key={message}>{message}</div>)}
      </Alert>
    ) : (
      <UnexpectedError />
    );
  };

  renderForm = () => {
    const { requestState, error } = this.state;

    return (
      <Form className={this.formName} noValidate onSubmit={this.handleSubmit}>
        {this.field('newPassword', {
          type: 'password',
          autoComplete: 'new-password'
        })}
        {this.field('confirmPassword', {
          type: 'password',
          autoComplete: 'new-password'
        })}
        {error && this.renderError()}
        <Button
          color="primary"
          block
          disabled={
            this.form.valid === false || requestState === RequestState.LOADING
          }
        >
          <FormattedMessage
            id="account.setNewPassword"
            defaultMessage="Set new password"
          />
        </Button>
      </Form>
    );
  };

  renderSuccess = () => (
    <Fragment>
      <Alert color="success">
        <FormattedMessage
          id="account.setNewPasswordSuccessful"
          defaultMessage="Your password was changed successfully. You may now log in."
        />
      </Alert>
      <Row className="SetNewPasswordForm__row-login">
        <Col xs="12" className="SetNewPasswordForm__col-login">
          <AccountModalLink
            tab={AccountModalTab.LOGIN}
            className={'SetNewPasswordForm__login-button'}
          >
            <Button tag="span" color="primary">
              <FormattedMessage {...globalTranslations.logInSentence} />
            </Button>
          </AccountModalLink>
        </Col>
      </Row>
    </Fragment>
  );

  render() {
    const { requestState } = this.state;

    return requestState === RequestState.LOADED
      ? this.renderSuccess()
      : this.renderForm();
  }
}

SetNewPasswordForm.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  passwordResetToken: PropTypes.string.isRequired,
  formValidator: PropTypes.instanceOf(FormValidator).isRequired
};

export default inject('accountStore', 'formValidator')(SetNewPasswordForm);
