import { types, getEnv, flow } from 'mobx-state-tree';

import StatefulStore from '../models/StatefulStore';
import CurrentOrder from '../models/cart/CurrentOrder';
import Modal from '../models/Modal';
import RequestState, { RequestStateType } from '../types/RequestState';
import { createErrorModel } from '../util/error';
import Error from '../models/Error';
import Cart from '../models/cart/Cart';

const ProposalStore = StatefulStore.named('ProposalStore')
  .props({
    cart: types.maybeNull(Cart),
    proposal: types.maybeNull(CurrentOrder),
    proposalState: types.optional(RequestStateType, RequestState.NONE),
    successState: types.optional(RequestStateType, RequestState.NONE),
    lastCurrentOrderError: types.maybeNull(Error),
    proposalModal: types.optional(
      Modal.named('ProposalModal')
        .props({
          openedManually: types.optional(types.boolean, true),
        })
        .actions((self) => ({
          toggle: (wasOpenedManually = true) => {
            self.isOpen = !self.isOpen;
            // Only set the manual open state when the modal opens. If it is updated from
            // automatic -> manual during the close toggle, the manual UI will flash quickly
            // during the fade animation.
            if (self.isOpen) {
              self.openedManually = !!wasOpenedManually;
            }
          },
        })),
      {}
    ),
  })
  .views((self) => {
    return {
      getProposalProducts: () => {
        return self.proposal?.products;
      },
      getProposalRemovedProducts: () => {
        return self.proposal?.removed_products;
      },
      getCartTotalValue(withTax) {
        return self.cart.total?.getPrice(withTax);
      },
    };
  })
  .actions((self) => {
    return {
      toggleModal: () => {
        self.proposalModal.toggle(false);
      },
      // Add single product to proposal.
      addToProposal: flow(function* addToProposal(payload) {
        self.setLoading(true);

        try {
          const request = yield getEnv(self)
            .apiWrapper.apiAxios()
            .post(`proposal-requests/cart/add`, payload);

          self.cart = request.data;
        } catch (error) {
          self.setError(error);
          throw error;
        }

        self.setLoading(false);
        self.toggleModal();
      }),
      // Confirm the proposal.
      confirmProposal: flow(function* confirmProposal(payload) {
        try {
          const request = yield getEnv(self)
            .apiWrapper.apiAxios()
            .post(`proposal-requests/confirm`, payload);

          self.proposal = request.data;
        } catch (error) {
          throw error;
        }
      }),
      loadCurrentOrderSuccess: flow(function* loadCurrentOrderSuccess(params) {
        self.successState = RequestState.LOADING;

        try {
          self.proposal = yield getEnv(self).apiWrapper.request(
            `proposal-requests/success`,
            { params }
          );
        } catch (error) {
          self.lastCurrentOrderError = createErrorModel(error);
          self.successState = RequestState.ERROR;
          throw error;
        }

        self.successState = RequestState.LOADED;
      }),
      loadCart: flow(function* loadCart() {
        self.setLoading(true);

        try {
          self.cart = yield getEnv(self).apiWrapper.request(
            `proposal-requests/cart`
          );
        } catch (error) {
          self.setError(error);
          throw error;
        }

        self.setLoading(false);
      }),
      loadProposal: flow(function* loadProposal() {
        self.proposalState = RequestState.LOADING;

        try {
          self.proposal = yield getEnv(self).apiWrapper.request(
            `proposal-requests`
          );
        } catch (error) {
          self.lastCurrentOrderError = createErrorModel(error);
          self.proposalState = RequestState.ERROR;
          throw error;
        }

        self.proposalState = RequestState.LOADED;
      }),
      // Remove single product from proposal.
      removeProduct: flow(function* removeProduct(params) {
        try {
          const response = yield getEnv(self)
            .apiWrapper.apiAxios()
            .delete(`proposal-requests/products/remove`, { params });
          self.proposal = response.data;
        } catch (error) {
          throw error;
        }
      }),
      updateProposal: flow(function* updateProposal(payload) {
        try {
          const response = yield getEnv(self)
            .apiWrapper.apiAxios()
            .put(`proposal-requests`, payload);

          self.proposal = response.data;
        } catch (error) {
          self.setError(error);
          throw error;
        }
      }),
    };
  });

export default ProposalStore;
