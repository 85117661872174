import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import cookies from 'js-cookie';

import { modelOf } from '../../../prop-types';
import Analytics from '../../../analytics/Analytics';
import AdStore from '../../../store/AdStore';
import AdZones from '../../../types/AdZones';
import ContentPopup from '../ContentPopup';

const POPUP_COOKIE_NAME = 'nhcontentpop';

const ContentPopups = ({ adStore, analytics, searchParams }) => {
  const [seenAds, setSeenAds] = useState([]);

  useEffect(() => {
    const seenAdsCookie = cookies.getJSON(POPUP_COOKIE_NAME);
    if (seenAdsCookie?.length > 0) {
      setSeenAds(seenAdsCookie);
    }
  }, []);

  const getUnseenAds = () => {
    const ads = adStore.getAdsByAdZone(searchParams, AdZones.POPUP_CONTENT);

    if (!ads) {
      return [];
    }

    return ads.filter((ad) => seenAds.indexOf(ad.id) === -1);
  };

  const unseenAds = getUnseenAds();
  const firstAd = unseenAds[0];

  if (!firstAd || window.isSSR) {
    return null;
  }

  const sendView = (ad) => {
    if (!ad) {
      return;
    }

    analytics.promoView([
      {
        bannerZone: AdZones.POPUP_CONTENT,
        banner: ad,
      },
    ]);
  };

  const setAdSeen = (ad) => {
    const newSeenAd = seenAds.concat([ad.id]);

    cookies.set(POPUP_COOKIE_NAME, newSeenAd);
    setSeenAds(newSeenAd);

    sendView();
  };

  return <ContentPopup setAdSeen={setAdSeen} ad={firstAd} />;
};

ContentPopups.propTypes = {
  adStore: modelOf(AdStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  searchParams: PropTypes.object,
};

export default inject('analytics', 'adStore')(observer(ContentPopups));
