import React from 'react';

/**
 * It's an antipattern for hooks to return JSX but it might be okay here
 * since this doesn't straight return React elements.
 */

/**
 *
 * @param {Object} entityObject
 * @property {Array.<NavigationEntity || CustomNavigationLink || Menu>} entityObject.entities
 * @property {boolean} entityObject.chevron
 * @returns {Array.<NavigationEntity>}
 */

const useNavigationEntityConverter = ({ entities }) => {
  const getlabel = (entity) => {
    let label;

    if (typeof entity.title === 'string') {
      label = (
        <div
          className="NavigationEntity__html-content"
          dangerouslySetInnerHTML={{
            __html: entity.title,
          }}
        />
      );
    } else if (entity.title != null) {
      label = <span className="NavigationEntity__title">{entity.title}</span>;
    } else {
      label = '';
    }

    return label;
  };

  const convertEntityToNavigationEntity = (entity) => {
    let label = getlabel(entity);

    return {
      id: entity.id,
      path: entity.url || entity.link,
      type: entity.type || entity.link_type,
      image: entity.image,
      label: label || entity.name,
      children: entity.children
        ? entity.children.map(convertEntityToNavigationEntity)
        : [],
      accessible_text: entity.accessible_text || entity.name,
      position: entity.position,
      page_id: entity.page_id,
    };
  };

  return entities.map(convertEntityToNavigationEntity);
};

export default useNavigationEntityConverter;
