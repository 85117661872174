import React, { Component } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown
} from 'reactstrap';
import { modelOf } from '../../../prop-types';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import SectionStore from '../../../store/SectionStore';
import CategoryStore from '../../../store/CategoryStore';
import ConfigStore from '../../../store/ConfigStore';
import { inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import globalTranslations from '../../../i18n/globalTranslations';
import RouteService from '../../../services/RouteService';

export class TopLevelNavigationDropdown extends Component {
  makeDropdownItem = (id, path, name) => {
    const { routeService } = this.props;

    return (
      <DropdownItem key={id} tag={Link} to={routeService.getPath(path)}>
        {name}
      </DropdownItem>
    );
  };

  getItems = () => {
    const { sectionStore, categoryStore, configStore } = this.props;

    if (configStore.activateSections) {
      return sectionStore.sections.map((section) =>
        this.makeDropdownItem(section.id, section.path, section.display_name)
      );
    } else {
      return categoryStore.categories.map((category) =>
        this.makeDropdownItem(
          category.id,
          category.path,
          category.navigationName
        )
      );
    }
  };

  render() {
    return (
      <UncontrolledButtonDropdown className="TopLevelNavigationDropdown">
        <DropdownToggle
          block
          caret
          /* DropdownToggle has a default color definition that cannot be easily
               removed. We style the button with its own color scheme in our style
               separately, so we don't want any of the existing color schemes to be
               applied here. */
          color="top-level-nav"
          className="TopLevelNavigationDropdown__toggle"
        >
          <span>
            <FormattedMessage {...globalTranslations.categoriesTitle} />
          </span>
        </DropdownToggle>
        <DropdownMenu>{this.getItems()}</DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  }
}

TopLevelNavigationDropdown.propTypes = {
  sectionStore: modelOf(SectionStore).isRequired,
  categoryStore: modelOf(CategoryStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired
};

export default inject(
  'sectionStore',
  'categoryStore',
  'configStore',
  'routeService'
)(TopLevelNavigationDropdown);
