import { types, getEnv, flow } from 'mobx-state-tree';
import { keyBy } from 'lodash';

import InfoPage from '../models/InfoPage';
import StatefulStore from '../models/StatefulStore';
import RequestState, { RequestStateType } from '../types/RequestState';
import Error from '../models/Error';
import { createErrorModel } from '../util/error';

const InfoPageStore = StatefulStore.named('InfoPageStore')
  .props({
    pages: types.optional(types.map(InfoPage), {}),
    pageStates: types.optional(types.map(RequestStateType), {}),
    lastSinglePageError: types.maybeNull(Error),
  })
  .views((self) => {
    return {
      get pagesArray() {
        return Array.from(self.pages, ([key, value]) => value);
      },
    };
  })
  .actions((self) => {
    return {
      loadPage: flow(function* loadPage(id) {
        self.pageStates.set(id, RequestState.LOADING);
        self.lastSinglePageError = null;

        try {
          const page = yield getEnv(self).apiWrapper.request(`pages/${id}`);
          self.pages.set(id, page);
        } catch (e) {
          self.setError(e);
          self.lastSinglePageError = createErrorModel(e);
          self.pageStates.set(id, RequestState.ERROR);
          throw e;
        }

        self.pageStates.set(id, RequestState.LOADED);
      }),
      loadPages: flow(function* loadPages() {
        self.setLoading(true);

        try {
          const pages = yield getEnv(self).apiWrapper.request(`pages`);
          self.pages.merge(keyBy(pages, 'id'));
        } catch (e) {
          self.setError(e);
          throw e;
        }

        self.setLoading(false);
      }),
    };
  });

export default InfoPageStore;
