import { types } from 'mobx-state-tree';

const CouponDiscountType = {
  AMOUNT: 'AMOUNT',
  PERCENTAGE: 'PERCENTAGE',
  FREE_SHIPPING: 'FREE_SHIPPING'
};

export default CouponDiscountType;

export const CouponDiscountTypeType = types.enumeration(
  'CouponDiscountType',
  Object.values(CouponDiscountType)
);
