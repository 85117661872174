import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';

import ScrollableAnchor from '../../anchor/ScrollableAnchor';
import Icon from '../Icon';

export class AccordionTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visitedTabs: new Set([this.props.activeTabId])
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      visitedTabs: new Set([...prevState.visitedTabs, nextProps.activeTabId])
    };
  }

  render() {
    const { tabs, toggle, openedTabs } = this.props;
    const { visitedTabs } = this.state;

    return (
      <div className="AccordionTabs">
        {tabs.map((tab) => (
          <div
            className={classNames('AccordionTabs__tab', {
              'AccordionTabs__tab--open': openedTabs.has(tab.id)
            })}
            key={tab.id}
          >
            <div
              className="AccordionTabs__tab-toggle"
              onClick={() => toggle(tab.id)}
            >
              <ScrollableAnchor
                id={tab.id}
                offset={10}
                className="AccordionTabs__tab-anchor"
                scrollIfInHash={false}
              />
              {tab.title}
              <Icon
                className="AccordionTabs__tab-toggle-icon"
                name="angle-up"
              />
            </div>
            <Collapse isOpen={openedTabs.has(tab.id)}>
              <div className="AccordionTabs__tab-content">
                {tab.getContent({
                  preloadOnly: !visitedTabs.has(tab.id)
                })}
              </div>
            </Collapse>
          </div>
        ))}
      </div>
    );
  }
}

AccordionTabs.propTypes = {
  activeTabId: PropTypes.string,
  openedTabs: PropTypes.object.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.node.isRequired,
      getContent: PropTypes.func.isRequired
    })
  ).isRequired,
  toggle: PropTypes.func.isRequired
};

export default AccordionTabs;
