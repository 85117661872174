import { getRoot, getParent, resolveIdentifier, types } from 'mobx-state-tree';
import Paths from '../types/Paths';
import generatePath from '../util/generatePath';

const InfoPage = types
  .model('InfoPage', {
    content: types.maybeNull(types.string),
    id: types.identifierNumber,
    name: types.string,
    parent_id: types.maybeNull(types.number),
    SEO_description: types.maybeNull(types.string),
    SEO_title: types.maybeNull(types.string),
    show_in_listing: types.boolean,
    slug: types.string,
    sort_order: types.number
  })
  .views((self) => {
    return {
      get path() {
        return generatePath(Paths.InfoPage, {
          slug: self.slug,
          id: self.id
        });
      },
      get children() {
        const infoPageStore = getParent(getParent(self));
        return infoPageStore && infoPageStore.pagesArray
          ? infoPageStore.pagesArray.filter(
              (page) => page.parent_id === self.id
            )
          : [];
      },
      get parent() {
        const parent =
          self.parent_id &&
          resolveIdentifier(InfoPage, getRoot(self), self.parent_id);

        return parent || null;
      },
      get ancestors() {
        const ancestors = [];
        let currentPage = self;
        while (currentPage.parent) {
          const parent = currentPage.parent;
          ancestors.push(parent);
          currentPage = parent;
        }

        ancestors.reverse();

        return ancestors;
      },
      get breadcrumbs() {
        const ancestors = self.ancestors;

        let breadcrumbs = ancestors.map((ancestor) => {
          return {
            text: ancestor.name,
            url: ancestor.path
          };
        });

        breadcrumbs.push({
          text: self.name,
          url: self.path
        });

        return breadcrumbs;
      }
    };
  });

export default InfoPage;
