import GoogleAnalytics4Events from './GoogleAnalytics4/GoogleAnalytics4Events';
import UniversalAnalyticEvents from './GoogleUniversalAnalytics/UniversalAnalyticEvents';

const notImplementedError = new Error('Analytics method not implemented');

/**
 * @namespace Analytics
 */

/**
 * @typedef Item
 * @type {Object}
 * @property {string} item_id
 * @property {string} item_name
 * @property {string} affiliation
 * @property {string} coupon
 * @property {string} currency
 * @property {number} discount
 * @property {number} index
 * @property {string} item_brand
 * @property {string} item_category
 * @property {string} item_list_id
 * @property {string} item_list_name
 * @property {string} item_variant
 * @property {string} location_id
 * @property {number} price
 * @property {string} quantity
 */

export default class Analytics {
  constructor(currencyStore, uiStore, configStore) {
    this.currencyStore = currencyStore;
    this.uiStore = uiStore;
    this.configStore = configStore;
    this.analyticsInstance = null;

    this.analyticsInstance = new UniversalAnalyticEvents(
      currencyStore,
      uiStore
    );
  }

  reInitializeWithGA4() {
    this.analyticsInstance = new GoogleAnalytics4Events(
      this.currencyStore,
      this.uiStore
    );
  }

  /**
   * @param {Array.<Item>} productList
   * @param {number} value monetary value of the event
   */
  addToCart(productList, value) {
    this.analyticsInstance.addToCart(productList, value);
  }

  /**
   * @param {Array.<Item>} productList
   * @param {number} value monetary value of the event
   */
  addToProposal(productList, value) {
    this.analyticsInstance.addToProposal(productList, value);
  }

  /**
   * @param {Array.<Item>} productList
   * @param {number} value monetary value of the event
   */
  addToWishlist(productList, value) {
    this.analyticsInstance.addToWishlist(productList, value);
  }

  /**
   * @param {Array.<Item>} productList
   * @param {Object} actionField
   * @param {number} actionField.step
   * @param {string} actionField.option
   * @param {number} value monetary value of the event
   */
  beginProposal(productList, actionField, value) {
    this.analyticsInstance.beginProposal(productList, actionField, value);
  }

  /**
   * Send all products from the cart.
   */
  cartStatus(cart) {
    this.analyticsInstance.cartStatus(cart);
  }

  /**
   * @param {Array.<Item>} productList
   * @param {Object} actionField
   * @param {number} actionField.step
   * @param {string} actionField.option
   * @param {number} value monetary value of the event
   * @param {string} coupon
   */
  checkout(productList, actionField, value, coupon) {
    this.analyticsInstance.checkout(productList, actionField, value, coupon);
  }

  checkoutOption() {
    throw notImplementedError;
  }

  /**
   * Clear all products from the cart.
   */
  clearCart() {
    this.analyticsInstance.clearCart();
  }

  /**
   * FacebookPixel specific event
   * @param {Array.<Product>} productList
   * @param {number} value monetary value of the event
   */
  customizeProduct(productList, value) {
    this.analyticsInstance.customizeProduct(productList, value);
  }

  /**
   * @param {number} value monetary value of the event
   */
  generateLead(value) {
    this.analyticsInstance.generateLead(value);
  }

  /**
   * @param {string} groupId The ID of the group
   */
  joinGroup(groupId) {
    this.analyticsInstance.joinGroup(groupId);
  }

  /**
   * @param {string} method The method used to login.
   */
  login(method) {
    this.analyticsInstance.login(method);
  }

  pageNotFound(payload) {
    this.analyticsInstance.pageNotFound(payload);
  }

  /**
   * @param {Object} productClick
   * @param {Object.<Array>} productList An array with objects
   * @param {Object.<string>} listName
   * @param {Object.<string>} listId
   */
  productClick({ productList, listName = null, listId = null }) {
    this.analyticsInstance.productClick(listName, productList, listId);
  }

  /**
   * @param {string} listName
   * @param {Array} productList An array with objects in the format { position, product }.
   */
  productImpressions(listName, productList, listId) {
    this.analyticsInstance.productImpressions(listName, productList, listId);
  }

  /**
   * @param {Array} productDetail An array with objects in the format { product }.
   */
  productDetail(productDetail) {
    this.analyticsInstance.productDetail(productDetail);
  }

  /**
   * @param {Array} bannerList An array with objects in the format { bannerZone, banner }.
   */
  promoView(bannerList) {
    this.analyticsInstance.promoView(bannerList);
  }

  /**
   * @param {Array} bannerList An array with objects in the format { bannerZone, banner }.
   */
  promotionClick(bannerList) {
    this.analyticsInstance.promotionClick(bannerList);
  }

  /**
   * @param {CurrentOrder} currentOrder Current order model.
   */
  proposalSent(currentOrder) {
    this.analyticsInstance.proposalSent(currentOrder);
  }

  /**
   * @param {CurrentOrder} currentOrder Current order model.
   */
  purchase(currentOrder) {
    this.analyticsInstance.purchase(currentOrder);
  }

  /**
   * @param searchText
   * @param resultCounts
   */
  quickSearch(searchText, resultCounts) {
    this.analyticsInstance.quickSearch(searchText, resultCounts);
  }

  refund() {
    throw notImplementedError;
  }

  /**
   * @param {Object} data
   * @param {Object.Array<CartProduct>} productList List of removed products
   * @param {Object.<number>} value a Monetary value of the event
   */
  removeFromCart(data) {
    this.analyticsInstance.removeFromCart(data);
  }

  removeFromProposal(data) {
    this.analyticsInstance.removeFromProposal(data);
  }

  reserveAndCollect(data) {
    this.analyticsInstance.reserveAndCollect(data);
  }

  /**
   * @param {string} searchTerm
   */
  search(searchTerm) {
    this.analyticsInstance.search(searchTerm);
  }

  /**
   * @param {Object} gdprInfo
   * @param {Object.<string>} first_name
   * @param {Object.<string>} last_name
   * @param {Object.<string>} email
   * @param {Object.<string>} street_address
   * @param {Object.<string>} international_number
   * @param {Object.<boolean>} marketing_ban
   */
  sendGdprData(gdprInfo) {
    this.analyticsInstance.sendGdprData(gdprInfo);
  }

  /**
   * @param {string} pageName
   */
  setPage(pageName) {
    this.analyticsInstance.setPage(pageName);
  }
  /**
   * @param {Object} content The method used to login.
   * @param {string} content.method
   * @param {string} content.content_type
   * @param {string} content.item_id
   */
  share(content) {
    this.analyticsInstance.share(content);
  }

  /**
   * @param {string} method The method used to login.
   */
  signup(method) {
    this.analyticsInstance.signup(method);
  }

  /**
   * When quick search suggestions are selected, the information is sent through this function.
   * Can be used to train suggestions.
   *
   * @param searchText
   * @param suggestionType
   * @param suggestionValue
   */
  suggestionSelection(searchText, suggestionType, suggestionValue) {
    this.analyticsInstance.suggestionSelection(
      searchText,
      suggestionType,
      suggestionValue
    );
  }

  updateDimension(dimensionKey, dimensionValue) {
    this.analyticsInstance.updateDimension(dimensionKey, dimensionValue);
  }

  /**
   * @param {number} value monetary value of the event
   * @param {Array.<Item>} productList An array with objects in the format { Item }.
   */
  viewCart(value, productList) {
    this.analyticsInstance.viewCart(value, productList);
  }

  /**
   * @param {number} value monetary value of the event
   * @param {Array.<Item>} productList An array with objects in the format { Item }.
   */
  viewProposal(value, productList) {
    this.analyticsInstance.viewProposal(value, productList);
  }
}
