import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import RequestState from '../../../types/RequestState';
import BlockSpinner from '../BlockSpinner';
import Error from '../../../models/Error';
import { modelOf } from '../../../prop-types';
import ErrorHandler from '../ErrorHandler';

const ContentForState = ({
  state,
  forLoaded,
  forPlaceHolder,
  error,
  errorHandler: ErrorHandler,
}) => {
  switch (state) {
    case RequestState.LOADED:
      return forLoaded && forLoaded();
    case RequestState.LOADING:
      return forPlaceHolder || <BlockSpinner />;
    case RequestState.ERROR:
      return <ErrorHandler error={error} />;
    default:
      return forPlaceHolder || null;
  }
};

ContentForState.propTypes = {
  state: PropTypes.string,
  forPlaceHolder: PropTypes.node,
  forLoaded: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([modelOf(Error), PropTypes.object]),
  /**
   * Function-type is used in the cases, where custom error handler has been provided to the component.
   */
  errorHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

ContentForState.defaultProps = {
  errorHandler: ErrorHandler,
};

export default observer(ContentForState);
