import { types, flow, getEnv } from 'mobx-state-tree';

import Ad from '../models/banner/Ad';
import Error from '../models/Error';
import StatefulStore from '../models/StatefulStore';
import TextBanner from '../models/banner/TextBanner';
import RequestState, { RequestStateType } from '../types/RequestState';
import { createErrorModel } from '../util/error';
import { paramsToQueryIdentifier } from '../util/query';
import BannerPages from '../models/banner/BannerPages';

const AdStore = StatefulStore.named('AdStore')
  .props({
    adQueries: types.optional(types.map(types.array(Ad)), {}),
    adQueryStates: types.optional(types.map(RequestStateType), {}),
    adQueryError: types.maybeNull(Error),
    bannerZones: types.maybeNull(BannerPages),
    globalBanners: types.optional(types.array(Ad), []),
    textBanners: types.optional(types.array(TextBanner), []),
  })
  .actions((self) => {
    return {
      setup: (banners) => {
        self.setBanners(banners);
      },
      setBanners: (banners) => {
        if (banners) {
          self.bannerZones = banners.bannerZones;
          self.globalBanners = banners.globalBanners;
          self.textBanners = banners.textBanners;
        }
      },
      loadAds: flow(function* loadAds(searchParameters, path = '') {
        const queryIdentifier = paramsToQueryIdentifier(searchParameters);

        // Skip the API call if we already have the data
        if (self.adQueries.get(queryIdentifier)) {
          return;
        }

        self.setLoading(true);
        self.adQueryStates.set(queryIdentifier, RequestState.LOADING);

        try {
          const ads = yield getEnv(self).apiWrapper.request(
            `banners${path}`,
            { params: searchParameters },
            { active_section: null }
          );
          self.adQueries.set(queryIdentifier, ads);
        } catch (error) {
          self.setError(error);
          self.adQueryError = createErrorModel(error);
          self.adQueryStates.set(queryIdentifier, RequestState.ERROR);
          throw error;
        }

        self.setLoading(false);
        self.adQueryStates.set(queryIdentifier, RequestState.LOADED);
      }),
    };
  })
  .views((self) => {
    return {
      get campaignProductsAds() {
        return self.bannerZones?.CAMPAIGN_PRODUCTS.zones.slice() || [];
      },
      get categoryPageAds() {
        return self.bannerZones?.CATEGORY_PAGE.zones.slice() || [];
      },
      get customerProductsAds() {
        return self.bannerZones?.CUSTOMER_PRODUCTS.zones.slice() || [];
      },
      get frontPageAds() {
        return {
          hasMobile: self.bannerZones?.FRONT_PAGE.has_mobile || false,
          hasNonMobile: self.bannerZones?.FRONT_PAGE.has_non_mobile || false,
          zones: self.bannerZones?.FRONT_PAGE.zones.slice() || [],
        };
      },
      get manufacturerPageAds() {
        return self.bannerZones?.MANUFACTURER_PAGE.zones.slice() || [];
      },
      get newProductsAds() {
        return self.bannerZones?.NEW_PRODUCTS.zones.slice() || [];
      },
      get popularProductsAds() {
        return self.bannerZones?.POPULAR_PRODUCTS.zones.slice() || [];
      },
      get productPageAds() {
        return self.bannerZones?.PRODUCT_PAGE.zones.slice() || [];
      },
      get recommendedProductsAds() {
        return self.bannerZones?.RECOMMENDED_PRODUCTS.zones.slice() || [];
      },
      get sectionPageAds() {
        return {
          hasMobile: self.bannerZones?.SECTION_FRONT_PAGE.has_mobile || false,
          hasNonMobile:
            self.bannerZones?.SECTION_FRONT_PAGE.has_non_mobile || false,
          zones: self.bannerZones?.SECTION_FRONT_PAGE.zones.slice() || [],
        };
      },
      get soldProductsAds() {
        return self.bannerZones?.SOLD_PRODUCTS.zones.slice() || [];
      },
      getGlobalAdsByAdZone(adZone) {
        const ads = self.globalBanners;
        let adsByZone = null;
        if (ads) {
          adsByZone = ads.filter((ad) => {
            return ad.zones.indexOf(adZone) !== -1;
          });
        }
        return adsByZone;
      },
      getAdsByAdZone(searchParameters, adZone) {
        const queryIdentifier = paramsToQueryIdentifier(searchParameters);
        const ads = self.adQueries.get(queryIdentifier);
        let adsByZone = null;
        if (ads) {
          adsByZone = ads.filter((ad) => {
            return ad.zones.indexOf(adZone) !== -1;
          });
        }
        return adsByZone;
      },
      getAdsBySearchParameters(searchParameters) {
        const queryIdentifier = paramsToQueryIdentifier(searchParameters);
        return self.adQueries.get(queryIdentifier);
      },
      getAdQueryStateBySearchParameters(searchParameters) {
        const queryIdentifier = paramsToQueryIdentifier(searchParameters);
        return self.adQueryStates.get(queryIdentifier);
      },
    };
  });

export default AdStore;
