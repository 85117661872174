import { types } from 'mobx-state-tree';

import StatefulStore from '../models/StatefulStore';
import SearchRedirection from '../models/SearchRedirection';

const SearchRedirectionStore = StatefulStore.named('SearchRedirectionStore')
  .props({
    redirections: types.optional(types.array(SearchRedirection), [])
  })
  .actions((self) => ({
    setup: (redirections) => {
      self.redirections = redirections;
    },
    getRedirection(searchTerm) {
      for (const redirection of self.redirections) {
        // For some reason, .find doesn't work with MSTArray.
        const maybeResult = redirection.search_terms
          .map((term) => term.toLowerCase())
          .includes(searchTerm.toLowerCase());
        if (maybeResult) {
          return redirection;
        }
      }

      return null;
    }
  }));

export default SearchRedirectionStore;
