import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';
import { uniqueId } from 'lodash';

import { modelOf } from '../../../prop-types';
import FormField from '../../../models/FormField';
import Field from '../Field';
import FieldError from '../FieldError';
import Icon from '../../common/Icon';

@observer
class RadioField extends Component {
  constructor(props) {
    super(props);

    // In the normal use cases, the form and field names don't change, and
    // this only really affects the input/label binding, so not updating the id
    // afterwards if props change should still be fine.
    this.id = uniqueId(`${props.formName}__${props.fieldName}--`);
  }

  getTooltip = (text) => {
    return (
      <>
        <Icon name="info" className={`tooltip-${this.id}`} />
        <UncontrolledTooltip target={`.tooltip-${this.id}`}>
          {text}
        </UncontrolledTooltip>
      </>
    );
  };

  createUniqueKey = (option, index) => {
    return uniqueId(`${option}--${index}`);
  };

  render() {
    const {
      label,
      fieldName,
      field,
      options,
      tooltip,
      className,
      inline,
      // unused, but no reason to pass to Field with rest
      formName,
      ...rest
    } = this.props;
    return (
      <FormGroup
        className={classNames('RadioField', className)}
        tag="fieldset"
        id={this.id}
      >
        {label && (
          <div className="RadioField__label">
            {label} {tooltip && this.getTooltip(tooltip)}
          </div>
        )}
        {options.map((option, index) => (
          <FormGroup
            className="RadioFieldSet__radio-field"
            check={true}
            inline={this.props.inline}
            key={this.createUniqueKey(option.value, index)}
          >
            <Label check={true}>
              <Field
                aria-invalid={field.value === option.value}
                component={Input}
                field={field}
                type="radio"
                name={fieldName}
                checked={field.value === option.value}
                value={option.value}
                {...rest}
              />
              {option.label}
            </Label>
          </FormGroup>
        ))}
        <FieldError field={field} />
      </FormGroup>
    );
  }
}

RadioField.propTypes = {
  field: modelOf(FormField).isRequired,
  formName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.node,
    })
  ).isRequired,
};

RadioField.defaultProps = {
  inline: true,
};

export default RadioField;
