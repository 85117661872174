import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'reactstrap';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class NavigationFirstLevelItem extends Component {
  getNavigationMegaMenuItemType = () => {
    const { children, configStore, className, hasManufacturers } = this.props;
    const twoLevelMegaMenuEnabled =
      configStore.navigation.megaMenu.twoLayerMegaMenuEnabled;
    const showManufacturers = configStore.navigation.megaMenu.showManufacturers;

    if (twoLevelMegaMenuEnabled) {
      return (
        <div
          className={classNames('NavigationFirstLevelItem', className, {
            'NavigationFirstLevelItem--manufacturer-box-enabled':
              showManufacturers && hasManufacturers,
          })}
        >
          <div className={classNames('NavigationFirstLevelItem__children')}>
            {children}
          </div>
        </div>
      );
    }

    return <ListGroupItem className={className}>{children}</ListGroupItem>;
  };

  render() {
    return this.getNavigationMegaMenuItemType();
  }
}

NavigationFirstLevelItem.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  className: PropTypes.string,
  hasManufacturers: PropTypes.bool,
};

export default inject('configStore')(NavigationFirstLevelItem);
