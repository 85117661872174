import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import Icon from '../../common/Icon';
import { modelOf } from '../../../prop-types';
import UIStore from '../../../store/UIStore';

const SHORT_BUTTON_THRESHOLD_PX = 400;

@observer
export class FooterBackToTopButton extends Component {
  shouldHide() {
    const { uiStore } = this.props;
    if (window.isSSR) {
      return true;
    }

    return (
      uiStore.isMobile &&
      (uiStore.scrollingDown ||
        uiStore.documentScrollTop < SHORT_BUTTON_THRESHOLD_PX)
    );
  }

  render() {
    const { uiStore } = this.props;

    return (
      <div
        className={classNames('FooterBackToTopButton', {
          'FooterBackToTopButton--hidden': this.shouldHide()
        })}
        id="CommonFooter__BackToTop"
      >
        <Container className="FooterBackToTop__container">
          <Row>
            <Col xs="12">
              <Button
                color="link"
                onClick={() => window.scrollTo(0, 0)}
                className="text-light FooterBackToTopButton__button"
              >
                {uiStore.isMobile ? (
                  <FormattedMessage
                    id="footer.backToTopShort"
                    defaultMessage="Top"
                  />
                ) : (
                  <FormattedMessage
                    id="footer.backToTop"
                    defaultMessage="Back to top"
                  />
                )}
                <Icon
                  name="long-arrow-up"
                  className="FooterBackToTopButton__icon"
                />
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

FooterBackToTopButton.propTypes = {
  uiStore: modelOf(UIStore).isRequired
};

export default inject('uiStore')(FooterBackToTopButton);
