import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { modelOf } from '../../../prop-types';

import SectionStore from '../../../store/SectionStore';
import Section from '../../../models/Section';

/**
 * TODO: Rename component into something more fitting when section activation have been refactored somewhere else.
 *
 * Using classic class component instead of modern functional component here to update active section.
 * Otherwise we would be performing MST action during render or, a state update.
 * State updates during render causes a pretty common "error-warning" in the console and might produce unexpected results.
 * "Warning: Cannot update during an existing state transition (such as within `render`). Render methods should be a pure function of props and state."
 * After react-router v6 update, we might be able to do side-effects upon navigation in "Route"-components since it introduces "Data router".
 */

class SectionAwareRoute extends Component {
  constructor(props) {
    super(props);
    props.sectionStore.setActiveSection(props.section || null);
  }

  render() {
    const { component, children } = this.props;
    return children ? children : component;
  }
}

SectionAwareRoute.propTypes = {
  sectionStore: modelOf(SectionStore).isRequired,
  section: modelOf(Section),
};

export default inject('sectionStore')(observer(SectionAwareRoute));
