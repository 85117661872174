import { types } from 'mobx-state-tree';

import { ProductClassType } from '../../types/ProductClass';
import ProductTypeClass, { ProductType } from '../../types/ProductTypeClass';
import ProductPriceInfo from '../ProductPriceInfo';

/* TODO Needs to be only one object with CartProduct */
const CurrentOrderProduct = types
  .model('OrderProduct', {
    actual_code: types.string,
    canonical_path: types.optional(types.array(types.string), []),
    class: ProductClassType,
    final_unit_price: types.maybeNull(types.number),
    id: types.string,
    image_url: types.maybeNull(types.string),
    main_category_id: types.maybeNull(types.number),
    main_section_id: types.maybeNull(types.number),
    manufacturers_name: types.maybeNull(types.string),
    multiproduct_title: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    package_size: types.maybeNull(types.number),
    price_info: types.maybeNull(ProductPriceInfo),
    product_id: types.string,
    quantity: types.number,
    starting_unit_price: types.maybeNull(types.number),
    stock_unit: types.optional(types.string, ''),
    total_price: types.maybeNull(types.number),
    type: ProductType,
    url: types.maybeNull(types.string),
    variations: types.optional(types.array(types.string), []),
  })
  .preProcessSnapshot((self) => {
    return {
      ...self,
      quantity: Number(self.quantity),
    };
  })
  .views((self) => ({
    get analytics() {
      const getAnalyticsPrice = () => {
        return self.final_unit_price * self.getQuantityStep();
      };

      return {
        quantity: self.quantity || 1,
        price: self.final_unit_price ? getAnalyticsPrice() : 0.0,
      };
    },
    get canonicalPath() {
      return self.canonical_path;
    },
    get finalUnitPrice() {
      return self.final_unit_price;
    },
    get isPhysicalProduct() {
      return self.type === ProductTypeClass.PHYSICAL;
    },
    get manufacturer() {
      return self.manufacturers_name;
    },
    get normalPrice() {
      return self.starting_unit_price;
    },
    get productCode() {
      return self.actual_code;
    },
    get productId() {
      return self.product_id;
    },
    get sellInPackage() {
      return (
        self.package_size && self.package_size > 0 && self.package_size !== 1
      );
    },
    get unitName() {
      const unit = self.sellInPackage
        ? self.package_unit_name
        : self.stock_unit;
      return unit ?? self.stock_unit;
    },
    get savings() {
      return Number(
        (self.starting_unit_price - self.final_unit_price).toFixed(2)
      );
    },
    get totalPrice() {
      return self.total_price;
    },
    get totalQuantity() {
      return self.package_size && self.package_size !== 0
        ? self.quantity * self.package_size
        : self.quantity;
    },
    getQuantityStep: (product) => {
      return product && self.sellInPackage ? self.package_size : 1;
    },
  }));

export default CurrentOrderProduct;
