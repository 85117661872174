import { types } from 'mobx-state-tree';

import Price from '../Price';
import ServiceOptionType, {
  ServiceOptionTypeType,
} from '../../types/ServiceOptionType';

const AdditionalServiceOption = types
  .model('AdditionalServiceOption', {
    selection_text: types.string,
    product_id: types.string,
    price: types.maybeNull(Price),
    type: ServiceOptionTypeType,
    image: types.maybeNull(types.string),
  })
  .views((self) => ({
    getSelection() {
      if (
        self.type === ServiceOptionType.RADIO ||
        self.type === ServiceOptionType.DROPDOWN
      ) {
        return {
          [self.type.toLowerCase()]: self.product_id,
        };
      }

      return {};
    },
  }));

export default AdditionalServiceOption;
