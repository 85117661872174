import { types } from 'mobx-state-tree';

export const PropertyImageLabel = types.model('PropertyImageLabel', {
  label_text: types.maybeNull(types.string),
  label_value: types.maybeNull(types.string)
});

const PropertyImage = types.model('PropertyImage', {
  name: types.string,
  image: types.maybeNull(types.string),
  display_style: types.string,
  placement: types.string,
  property_id: types.maybeNull(types.integer),
  property_value_id: types.maybeNull(types.integer),
  position: types.string,
  label_background: types.maybeNull(types.string),
  label_fontcolor: types.maybeNull(types.string),
  show_on_product_listings: types.boolean,
  show_on_product_card: types.boolean,
  label: types.maybeNull(PropertyImageLabel)
});

export default PropertyImage;
