import { types } from 'mobx-state-tree';

const SurveyFieldValueType = {
  BOOLEAN: 'BOOLEAN',
  MENU: 'MENU',
  TEXT: 'TEXT'
};

export default SurveyFieldValueType;

export const SurveyFieldValueTypeType = types.enumeration(
  'SurveyFieldValueType',
  Object.values(SurveyFieldValueType)
);
