import { isNil } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { roundWithPrecision } from '../../../util/number';
import Price from '../../product/Price';

const ProductPackageSize = ({
  currencyStore,
  product,
  withTax,
  withSimpleText,
}) => {
  const showTaxExcludedInfo = !withTax;
  const price = roundWithPrecision(
    product.package_size *
      roundWithPrecision(
        product.getPrice(withTax),
        currencyStore.activeCurrency.precision
      ),
    currencyStore.activeCurrency.precision
  );

  const renderPrice = () => (
    <Price price={price} showTaxExcludedInfo={showTaxExcludedInfo} />
  );

  const renderPackageSizeText = () => {
    return (
      <FormattedMessage
        id="product.packageSizeDescription"
        defaultMessage="{packageSizeSentence}: 1 {packageUnitName} = {packageSize} {stockUnit} ({packagePrice} / {packageUnitName})"
        values={{
          packageSizeSentence: (
            <FormattedMessage
              id="product.packageSizeSentence"
              defaultMessage="Package size"
            />
          ),
          packageUnitName: product.package_unit_name,
          packageSize: product.package_size,
          stockUnit: product.stock_unit,
          packagePrice: renderPrice(),
        }}
      />
    );
  };

  const renderContent = () => {
    if (withSimpleText) {
      return (
        <FormattedMessage
          id="product.packageSizeSimpleDescription"
          defaultMessage="1 {packageUnitName} = {packageSize} {stockUnit}"
          values={{
            packageUnitName: product.package_unit_name,
            stockUnit: product.stock_unit,
            packageSize: product.package_size,
          }}
        />
      );
    }

    return renderPackageSizeText();
  };

  return <div className="ProductPackageSize">{renderContent()}</div>;
};

ProductPackageSize.propTypes = {
  product: PropTypes.object.isRequired,
  withTax: PropTypes.bool.isRequired,
  withSimpleText: PropTypes.bool,
};

export default inject((stores, props) => ({
  currencyStore: stores.currencyStore,
  withTax: isNil(props.withTax)
    ? stores.accountStore.showPricesWithTax
    : props.withTax,
}))(observer(ProductPackageSize));
