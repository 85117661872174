import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MaintenanceHandler extends Component {
  render() {
    const { active, message, children } = this.props;
    return active ? (
      <div className="MaintenanceHandler">
        <div
          className="MaintenanceHandler__message"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </div>
    ) : (
      children
    );
  }
}

MaintenanceHandler.propTypes = {
  message: PropTypes.string,
  active: PropTypes.bool
};

export default MaintenanceHandler;
