import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import RegionalSettingsDropdowns from '../RegionalSettingsDropdowns';
import Icon from '../Icon';
import globalTranslations from '../../../i18n/globalTranslations';
import RegionalSettings from '../RegionalSettings';

@observer
export class MobileRegionalSettingsModal extends Component {
  render() {
    const modal = this.props.uiStore.mobileRegionalSettingsModal;

    return (
      <RegionalSettings>
        {(save, onChange, currentValues, isSaving) => (
          <Modal isOpen={modal.isOpen} className="MobileRegionalSettingsModal">
            <ModalHeader
              className="MobileRegionalSettingsModal__header"
              toggle={modal.toggle}
            >
              <FormattedMessage {...globalTranslations.regionalSettingsTitle} />
            </ModalHeader>
            <ModalBody>
              <RegionalSettingsDropdowns
                {...currentValues}
                onChange={onChange}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  save().then(
                    this.props.uiStore.mobileRegionalSettingsModal.toggle
                  );
                }}
                color="primary"
                disabled={isSaving}
              >
                <span>
                  <Icon
                    name={isSaving ? 'circle-o-notch' : 'check'}
                    spin={isSaving}
                  />{' '}
                  <FormattedMessage {...globalTranslations.saveTitle} />
                </span>
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </RegionalSettings>
    );
  }
}

export default inject('uiStore')(MobileRegionalSettingsModal);
