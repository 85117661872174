import { types } from 'mobx-state-tree';

import AccountModalTab, { AccountModalTabType } from '../types/AccountModalTab';
import Modal from './Modal';

const AccountModal = Modal.named('AccountModal')
  .props({
    tab: types.optional(AccountModalTabType, AccountModalTab.LOGIN),
    resetPasswordVisible: types.optional(types.boolean, false)
  })
  .actions((self) => {
    return {
      setTab(tab) {
        self.tab = tab;
        self.resetPasswordVisible = false;
      },
      toggleResetPasswordVisible() {
        self.resetPasswordVisible = !self.resetPasswordVisible;

        if (self.resetPasswordVisible) {
          // The element might not be present in the DOM at the time this is
          // called; schedule it to be done right after the React lifecycle
          // methods (incl. a new render) have finished.
          setImmediate(() => {
            const element = document.querySelector(
              '.AccountModal .ResetPasswordForm input[name=email]'
            );

            if (element) {
              element.focus();
            }
          });
        }
      }
    };
  });

export default AccountModal;
