import { types } from 'mobx-state-tree';

const FormFieldType = {
  BOOLEAN: 'BOOLEAN',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  SELECT: 'SELECT',
  TEXT: 'TEXT',
  TEXTAREA: 'TEXTAREA',
};

export default FormFieldType;

export const FormFieldTypeType = types.enumeration(
  'FormFieldTypeType',
  Object.values(FormFieldType)
);
