import { types } from 'mobx-state-tree';

const OrderType = {
  PURCHASE: 'PURCHASE',
  PROPOSAL_REQUEST: 'PROPOSAL_REQUEST',
};

export default OrderType;

export const OrderTypeType = types.enumeration(
  'OrderTypeType',
  Object.values(OrderType)
);
