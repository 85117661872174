import { types } from 'mobx-state-tree';
import Purchase from './Purchase';
import PurchasesTotal from './PurchasesTotal';

const CustomerUserPurchases = types.model('CustomerUserPurchases', {
  purchases: types.optional(types.array(Purchase), []),
  total: types.optional(PurchasesTotal, {})
});

export default CustomerUserPurchases;
