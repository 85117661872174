import React from 'react';
import { FormattedMessage } from 'react-intl';

import globalTranslations from '../i18n/globalTranslations';

export const fieldTypeLabel = {
  CITY: 'city',
  BILLING_IS_COMPANY: 'billingIsCompany',
  COMPANY: 'company',
  COUNTRY: 'country',
  EMAIL: 'email',
  EXTRA_MESSAGE: 'message',
  NAME: 'name',
  FIRSTNAME: 'firstName',
  LASTNAME: 'lastName',
  POSTAL_CODE: 'postalCode',
  PHONE: 'telephone',
  PRIVACY_STATEMENT: 'privacyStatement',
  SEPARATE_DELIVERY_ADDRESS: 'useDeliveryAddress',
  DELIVERY_ADDRESS_DROPDOWN: 'deliveryAddressId',
  STREET_ADDRESS: 'streetAddress',
  VAT_NUMBER: 'businessId',
  DELIVERY_CITY: 'deliveryCity',
  DELIVERY_IS_COMPANY: 'deliveryIsCompany',
  DELIVERY_COMPANY: 'deliveryCompany',
  DELIVERY_COUNTRY: 'deliveryCountry',
  DELIVERY_NAME: 'deliveryName',
  DELIVERY_POSTAL_CODE: 'deliveryPostalCode',
  DELIVERY_PHONE: 'deliveryTelephone',
  DELIVERY_STREET_ADDRESS: 'deliveryStreetAddress',
  DELIVERY_VAT_NUMBER: 'deliveryBusinessId',
};

export const labels = {
  allowUnderDelivery: (
    <FormattedMessage {...globalTranslations.allowPartialDeliverySentence} />
  ),
  allowShippingMethodChange: (
    <FormattedMessage {...globalTranslations.allowShippingChangeSentence} />
  ),
  billingIsCompany: (
    <FormattedMessage {...globalTranslations.isCorporateCustomerSentence} />
  ),
  businessId: <FormattedMessage {...globalTranslations.businessIdTitle} />,
  city: <FormattedMessage {...globalTranslations.cityTitle} />,
  company: <FormattedMessage {...globalTranslations.companyTitle} />,
  companyFirstName: (
    <FormattedMessage {...globalTranslations.contactPersonFirstNameTitle} />
  ),
  companyLastName: (
    <FormattedMessage {...globalTranslations.contactPersonLastNameTitle} />
  ),
  confirmPassword: (
    <FormattedMessage {...globalTranslations.confirmPasswordTitle} />
  ),
  country: <FormattedMessage {...globalTranslations.countryTitle} />,
  deliveryAddressId: (
    <FormattedMessage
      id="recurringOrder.deliveryAddressSentence"
      defaultMessage="Delivery address"
    />
  ),
  deliveryBusinessId: (
    <FormattedMessage {...globalTranslations.businessIdTitle} />
  ),
  deliveryCity: <FormattedMessage {...globalTranslations.cityTitle} />,
  deliveryCompany: <FormattedMessage {...globalTranslations.companyTitle} />,
  deliveryCountry: <FormattedMessage {...globalTranslations.countryTitle} />,
  deliveryIsCompany: (
    <FormattedMessage {...globalTranslations.isCorporateCustomerSentence} />
  ),
  deliveryName: <FormattedMessage {...globalTranslations.fullNameTitle} />,
  deliveryPostalCode: <FormattedMessage {...globalTranslations.zipCodeTitle} />,
  deliveryStreetAddress: (
    <FormattedMessage {...globalTranslations.streetAddressTitle} />
  ),
  deliveryTelephone: (
    <FormattedMessage {...globalTranslations.telephoneTitle} />
  ),
  email: <FormattedMessage {...globalTranslations.emailTitle} />,
  firstName: <FormattedMessage {...globalTranslations.firstNameTitle} />,
  isCompany: (
    <FormattedMessage {...globalTranslations.isCorporateCustomerSentence} />
  ),
  lastName: <FormattedMessage {...globalTranslations.lastNameTitle} />,
  name: <FormattedMessage {...globalTranslations.fullNameTitle} />,
  newsletter: (
    <FormattedMessage {...globalTranslations.newsletterSubscriptionSentence} />
  ),
  no: <FormattedMessage {...globalTranslations.noTitle} />,
  onHoldUntil: (
    <FormattedMessage
      id="recurringOrder.onHoldTitle"
      defaultMessage="Set subscription on hold until (Use dropdown or calendar icon)"
    />
  ),
  onHoldUntilPlaceHolder: (
    <FormattedMessage
      id="recurringOrder.onHoldPlaceholder"
      defaultMessage="Choose date from calendar"
    />
  ),
  password: <FormattedMessage {...globalTranslations.passwordTitle} />,
  postalCode: <FormattedMessage {...globalTranslations.zipCodeTitle} />,
  privacyStatement: (
    <FormattedMessage {...globalTranslations.privacyStatementAcceptSentence} />
  ),
  shippingMethodId: (
    <FormattedMessage
      id="recurringOrder.shippingMethodSentence"
      defaultMessage="Subscription shipping method"
    />
  ),
  status: (
    <FormattedMessage id="global.active" defaultMessage="Active subscription" />
  ),
  streetAddress: (
    <FormattedMessage {...globalTranslations.streetAddressTitle} />
  ),
  telephone: <FormattedMessage {...globalTranslations.telephoneTitle} />,
  title: (
    <FormattedMessage
      id="recurringOrder.titleSentence"
      defaultMessage="Give descriptive title"
    />
  ),
  useDeliveryAddress: (
    <FormattedMessage {...globalTranslations.useDeliveryAddressTitle} />
  ),
  username: <FormattedMessage {...globalTranslations.emailOrUsernameTitle} />,
  yes: <FormattedMessage {...globalTranslations.yesTitle} />,
};
