import React, { Component } from 'react';
import ProductSlider from '../../slider/ProductSlider';
import MissingProductLoader from '../../product/MissingProductLoader';

class VehiclePartSlider extends Component {
  listId = 'vehicle_parts';
  listName = 'Vehicle Parts';

  renderContent = (products) => {
    if (products.length === 0) {
      return null;
    }

    const { title } = this.props;

    return (
      <div className="VehiclePartAlternativesSlider">
        <h3>{title}</h3>
        <ProductSlider
          listId={this.listId}
          name={this.listName}
          products={products}
        />
      </div>
    );
  };

  render() {
    const { productIds } = this.props;

    return (
      <MissingProductLoader ids={productIds}>
        {(products) => this.renderContent(products)}
      </MissingProductLoader>
    );
  }
}

export default VehiclePartSlider;
