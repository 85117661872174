import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Button } from 'reactstrap';
import { omitBy, isNull } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { createForm } from '../../../models/Form';
import { createFormField } from '../../../models/FormField';
import FormGroupField from '../../form/FormGroupField';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import RadioField from '../../form/RadioField';
import SurveyStore from '../../../store/SurveyStore';
import SelectField from '../../form/SelectField';
import SurveyFieldValueType from '../../../types/SurveyFieldValueType';
import AccountStore from '../../../store/AccountStore';

function createOption(value, label) {
  return {
    value,
    label
  };
}

@observer
class SurveyForm extends Component {
  form = null;
  formName = 'SurveyForm';

  constructor(props) {
    super(props);
    const { surveyStore } = this.props;
    const survey = surveyStore.activeSurvey;

    this.state = {
      submitError: false
    };

    if (survey) {
      const formFields = {};
      survey.questions.forEach((question) => {
        const field = surveyStore.getUserField(question.field.key);
        const value = field ? field.value_id || field.value || null : null;
        formFields[question.field.key] = createFormField({ value: value });
      });

      this.form = createForm({
        ...formFields
      });
    }
  }

  handleSubmit = (e) => {
    const { surveyStore } = this.props;
    const { surveySubmitting } = surveyStore;

    if (surveySubmitting) {
      return;
    }

    e.preventDefault();
    this.form.validate();
    if (this.form.valid) {
      let values = this.form.getFieldValues();
      values = omitBy(values, isNull);
      values = omitBy(values, (value) => value === '');

      surveyStore
        .submitSurvey(values)
        .then(() => {
          this.setupGiosg(values);
        })
        .then(() => {
          this.setState({
            submitError: false
          });
        })
        .catch((error) => {
          this.setState({
            submitError: true
          });
          console.error(error);
        });
    }
  };

  setupGiosg = (values) => {
    const giosg = window.giosgEventHandler;
    if (giosg) {
      giosg.maybePushVisitorInfo(this.props.accountStore);
      giosg.pushEvent('visitor', 'submit', values);
      giosg.setVisitorPriority(10);
    }

    return null;
  };

  getQuestions = () => {
    const { surveyStore } = this.props;
    const survey = surveyStore.activeSurvey;

    return (
      <Fragment>
        {survey.questions.map((question) => {
          let field;
          switch (question.field.type) {
            case SurveyFieldValueType.BOOLEAN:
              field = this.radioBool(question);
              break;
            case SurveyFieldValueType.MENU:
              field = this.select(question);
              break;
            case SurveyFieldValueType.TEXT:
              field = this.field(question);
              break;
            default:
              field = this.field(question);
              break;
          }

          return <Fragment key={question.id}>{field}</Fragment>;
        })}
      </Fragment>
    );
  };

  radioBool = (question) => {
    const field = this.form.fields.get(question.field.key);
    const values = [
      createOption('1', <FormattedMessage {...globalTranslations.yesTitle} />),
      createOption('0', <FormattedMessage {...globalTranslations.noTitle} />)
    ];

    return (
      <Fragment>
        <RadioField
          field={field}
          formName={this.formName}
          fieldName={question.field.key}
          options={values}
          label={question.text}
          required={question.is_required}
          tooltip={question.hint}
        />
      </Fragment>
    );
  };

  select = (question) => {
    const field = this.form.fields.get(question.field.key);
    const values = question.field.values.map((value) =>
      createOption(value.value_id.toString(), value.text)
    );

    values.unshift({
      value: '',
      label: (
        <FormattedMessage
          id="survey.selectOption"
          defaultMessage="'-- Select option'"
          children={(formattedMessage) => (
            <Fragment>{formattedMessage}</Fragment>
          )}
        />
      )
    });

    return (
      <Fragment>
        <SelectField
          field={field}
          formName={this.formName}
          fieldName={question.field.key}
          options={values}
          label={question.text}
          required={question.is_required}
          tooltip={question.hint}
        />
      </Fragment>
    );
  };

  field = (question) => {
    const field = this.form.fields.get(question.field.key);

    return (
      <Fragment>
        <FormGroupField
          field={field}
          formName={this.formName}
          fieldName={question.field.key}
          label={question.text}
          required={question.is_required}
          tooltip={question.hint}
        />
      </Fragment>
    );
  };

  render() {
    const { surveyStore } = this.props;
    const { surveySubmitting } = surveyStore;
    const { submitError } = this.state;
    const survey = surveyStore.activeSurvey;

    if (!survey) {
      return null;
    }

    const buttonColor = submitError ? 'warning' : 'primary';

    return (
      <Form className={this.formName} onSubmit={this.handleSubmit}>
        {this.getQuestions()}
        <Button
          color={buttonColor}
          block
          disabled={this.form.valid === false || surveySubmitting}
        >
          <FormattedMessage {...globalTranslations.submitSurvey} />
        </Button>
        {submitError && (
          <span className={'text-danger'}>
            <FormattedMessage {...globalTranslations.surveySubmitError} />
          </span>
        )}
      </Form>
    );
  }
}

SurveyForm.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  surveyStore: modelOf(SurveyStore).isRequired
};

export default inject('accountStore', 'surveyStore')(SurveyForm);
