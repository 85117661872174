import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import globalTranslations from '../../../i18n/globalTranslations';

const ContentPopup = ({ ad, setAdSeen }) => {
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    /* Initialize countdown */
    if (ad && countdown === null) {
      setCountdown(ad.popup_delay !== null ? ad.popup_delay : 0);
    }

    if (countdown > 0) {
      const interval = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countdown]);

  if (!ad || countdown !== 0 || window.isSSR) {
    return null;
  }

  const toggle = () => {
    setCountdown(null);
    setAdSeen(ad);
  };

  return (
    <Modal
      className={classNames('ContentPopup', `ContentPopup_${ad.id}`)}
      size="lg"
      toggle={toggle}
      isOpen={true}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <div dangerouslySetInnerHTML={{ __html: ad.content }} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          <FormattedMessage {...globalTranslations.closeTitle} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ContentPopup.propTypes = {
  ad: PropTypes.object,
  setAdSeen: PropTypes.func,
};

export default observer(ContentPopup);
