import { types } from 'mobx-state-tree';

const InstantShoppingCartProduct = types.model('InstantShoppingCartProduct', {
  id: types.string,
  merchant_info: types.model({
    id: types.identifierNumber,
    name: types.string,
    sku: types.string
  }),
  name: types.maybeNull(types.string),
  quantity: types.number,
  reference: types.maybeNull(types.string)
});

export default InstantShoppingCartProduct;
