import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { addParametersToPath } from '../../../util/queryString';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import RouteService from '../../../services/RouteService';
import ProductClass from '../../../types/ProductClass';

@observer
export class ProductLink extends Component {
  render() {
    const {
      product,
      requireCanonical,
      params,
      children,
      routeService,
      ...rest
    } = this.props;

    const path =
      product.class === ProductClass.MULTI_CHILD
        ? product.pathWithActiveProductId(product.id)
        : product.path;
    const pathToNavigate = requireCanonical
      ? routeService.getCanonicalProductPath(product)
      : routeService.getProductPath(product, addParametersToPath(path, params));
    return (
      <Link className="ProductLink" to={pathToNavigate} {...rest}>
        {children || product.name}
      </Link>
    );
  }
}

ProductLink.propTypes = {
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  product: modelOf(Product).isRequired,
  requireCanonical: PropTypes.bool,
  params: PropTypes.shape({
    columnId: PropTypes.number,
    rowId: PropTypes.number
  })
};

export default inject('routeService')(ProductLink);
