import React from 'react';
import { observer, PropTypes as MobxPropTypes, inject } from 'mobx-react';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import ProductCard from '../../product/ProductCard';
import { ViewBreakpointMinWidth } from '../../../types/ViewBreakpoint';
import ProductImpressions from '../../product/ProductImpressions';
import CommonSlider from '../../common/Slider';
import UIStore from '../../../store/UIStore';

const ProductSlider = ({
  uiStore,
  products,
  name,
  listId,
  settings,
  cardSettings,
  disableImpressions,
  ...rest
}) => {
  const getSliderSettings = () => {
    const sliderSettings = {
      draggable: true,
      arrows: uiStore.isDesktop,
      infinite: false,
      slidesToShow: 6,
      slidesToScroll: 6,
      dots: uiStore.isDesktop,
      responsive: [
        {
          breakpoint: ViewBreakpointMinWidth.XXL,
          settings: {
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: ViewBreakpointMinWidth.XL,
          settings: {
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: ViewBreakpointMinWidth.MD,
          settings: {
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: ViewBreakpointMinWidth.SM,
          settings: {
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };

    return settings ? { ...sliderSettings, ...settings } : sliderSettings;
  };

  const slides = products.map((product, index) => {
    const productCardSettings = {
      product,
      productListView: true,
      position: index + 1,
      listId,
      listName: name,
      hideDescription: false,
      modelMargin: true,
      ...cardSettings,
    };

    return (
      <div key={product.id}>
        <ProductCard {...productCardSettings} />
      </div>
    );
  });

  return (
    <div className="ProductSlider">
      <CommonSlider
        key={products.map((product) => product.id).join(',')}
        className="ProductSlider"
        {...getSliderSettings()}
        {...rest}
      >
        {slides}
      </CommonSlider>
      {!disableImpressions && (
        <ProductImpressions
          listId={listId}
          listName={name}
          products={products.slice()}
        />
      )}
    </div>
  );
};

ProductSlider.propTypes = {
  uiStore: modelOf(UIStore).isRequired,
  products: PropTypes.oneOfType([
    MobxPropTypes.observableArrayOf(modelOf(Product)),
    PropTypes.arrayOf(modelOf(Product)),
  ]).isRequired,
  cardSettings: PropTypes.object,
  disableImpressions: PropTypes.bool,
  listId: PropTypes.string,
  name: PropTypes.string,
  settings: PropTypes.object,
};

export default inject('uiStore')(observer(ProductSlider));
