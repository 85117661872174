import { types } from 'mobx-state-tree';

const ProductSeason = types
  .model('ProductSeason', {
    start_date: types.maybeNull(types.string),
    end_date: types.maybeNull(types.string),
    description: types.string
  })
  .views((self) => {
    return {
      get startDate() {
        return self.start_date && new Date(self.start_date);
      },
      get endDate() {
        return self.end_date && new Date(self.end_date);
      },
      hasStarted() {
        return !self.startDate || self.startDate <= Date.now();
      },
      hasEnded() {
        return self.endDate && self.endDate < Date.now();
      },
      isActive() {
        return self.hasStarted() && !self.hasEnded();
      }
    };
  });

export default ProductSeason;
