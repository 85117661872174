import { types } from 'mobx-state-tree';

const TranslatedImageType = {
  STORE_LOGO: 'STORE_LOGO',
  STORE_MOBILE_LOGO: 'STORE_MOBILE_LOGO',
  STORE_SCROLL_LOGO: 'STORE_SCROLL_LOGO',
  IMAGE_NOT_FOUND_NAME: 'IMAGE_NOT_FOUND_NAME',
  EMAIL_LOGO: 'EMAIL_LOGO',
  PDF_LOGO: 'PDF_LOGO',
};

export default TranslatedImageType;

export const TranslatedImageTypeType = types.enumeration(
  'TranslatedImageType',
  Object.values(TranslatedImageType)
);
