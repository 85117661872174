import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import ProductPriceInfo from '../../../models/ProductPriceInfo';
import ProductClass from '../../../types/ProductClass';
import globalTranslations from '../../../i18n/globalTranslations';
import Price from '../Price';
import { isNil } from 'lodash';

@observer
export class ProductSingularPrice extends Component {
  render() {
    const { product, withTax, hideSingular } = this.props;

    if (
      !product.singular_price_unit ||
      product.singular_price_divider <= 0 ||
      hideSingular
    ) {
      return null;
    }

    const showTaxExcludedInfo = !withTax;
    const isMulti = product.class === ProductClass.MULTI;
    let price = product.getPrice(withTax);
    if (isMulti && product.priceRange) {
      price = product.priceRange.min.getPrice(withTax);
    }
    const priceSuffix = ` / ${product.singular_price_unit}`;
    return (
      <div className="ProductSingularPrice">
        {isMulti && <FormattedMessage {...globalTranslations.priceFrom} />}
        <Price
          price={price / product.singular_price_divider}
          suffix={priceSuffix}
          showTaxExcludedInfo={showTaxExcludedInfo}
        />
      </div>
    );
  }
}

ProductSingularPrice.propTypes = {
  product: PropTypes.shape({
    price_info: modelOf(ProductPriceInfo),
    package_size: PropTypes.number,
    stock_unit: PropTypes.string,
    singular_price_unit: PropTypes.string,
    singular_price_divider: PropTypes.number
  }).isRequired,
  withTax: PropTypes.bool.isRequired,
  hideSingular: PropTypes.bool
};

export default inject((stores, props) => ({
  withTax: isNil(props.withTax)
    ? stores.accountStore.showPricesWithTax
    : props.withTax
}))(ProductSingularPrice);
