import { types } from 'mobx-state-tree';

import { ShippingDeliveryTimeTypeType } from '../types/ShippingDeliveryTimeType';

const DeliveryTime = types.model('DeliveryTime', {
  type: ShippingDeliveryTimeTypeType,
  min: types.number,
  max: types.number,
});

export default DeliveryTime;
