import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import SEOLink from '../SEOLink';

class SEOPagination extends Component {
  getPathForPage = (page) => {
    const { path } = this.props;

    return page === 1 ? path : `${path}?page=${page}`;
  };

  render() {
    const { currentPage, lastPage } = this.props;
    return (
      <Fragment>
        {currentPage > 1 && (
          <SEOLink rel="prev" path={this.getPathForPage(currentPage - 1)} />
        )}
        {currentPage < lastPage && (
          <SEOLink rel="next" path={this.getPathForPage(currentPage + 1)} />
        )}
      </Fragment>
    );
  }
}

SEOPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired
};

export default SEOPagination;
