import { types } from 'mobx-state-tree';

const CommonPage = {
  ADVANCED_SEARCH: 'ADVANCED_SEARCH',
  CATEGORY: 'CATEGORY',
  CHECKOUT: 'CHECKOUT',
  CHECKOUT_SUCCESS: 'CHECKOUT_SUCCESS',
  CUSTOMER_PRODUCTS: 'CUSTOMER_PRODUCTS',
  HOME: 'HOME',
  INFO: 'INFO',
  KLARNA_INSTANT_SHOPPING_THANK_YOU: 'KLARNA_INSTANT_SHOPPING_THANK_YOU',
  MANUFACTURER: 'MANUFACTURER',
  MANUFACTURER_LIST: 'MANUFACTURER_LIST',
  MY_ACCOUNT: 'MY_ACCOUNT',
  MY_ACCOUNT_THANK_YOU: 'MY_ACCOUNT_THANK_YOU',
  NEW_PRODUCTS: 'NEW_PRODUCTS',
  NOT_FOUND: 'NOT_FOUND',
  ON_SALE: 'ON_SALE',
  POPULAR_PRODUCTS: 'POPULAR_PRODUCTS',
  PRODUCT: 'PRODUCT',
  PROPOSAL_REQUEST: 'PROPOSAL_REQUEST',
  PROPOSAL_REQUEST_SUCCESS: 'PROPOSAL_REQUEST_SUCCESS',
  RECOMMENDED_PRODUCTS: 'RECOMMENDED_PRODUCTS',
  SECTION: 'SECTION',
  SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
  SOLD_PRODUCTS: 'SOLD_PRODUCTS',
  VEHICLE_PART_SEARCH: 'VEHICLE_PART_SEARCH',
  WISH_LIST: 'WISH_LIST',
};

export default CommonPage;

export const CommonPageType = types.enumeration(
  'CommonPage',
  Object.values(CommonPage)
);
