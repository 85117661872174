import React, { Component, Fragment } from 'react';
import { Alert, Button, Form, Col, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { createForm } from '../../../models/Form';
import globalTranslations from '../../../i18n/globalTranslations';
import FormGroupField from '../../form/FormGroupField';
import { notBlank } from '../../../util/formValidators';
import { createFormField } from '../../../models/FormField';
import RequestState from '../../../types/RequestState';
import ErrorHandler from '../../loader/ErrorHandler';
import { createErrorModel } from '../../../util/error';
import ApiWrapper from '../../../services/ApiWrapper';
import Icon from '../../common/Icon';
import FormValidator from '../../../services/FormValidator';
import ProductEnquiryType from '../../../types/ProductEnquiryType';
import Analytics from '../../../analytics/Analytics';
import ConfigStore from '../../../store/ConfigStore';
import { modelOf } from '../../../prop-types';
import UIStore from '../../../store/UIStore';
import RecaptchaLegalBanner from '../../legal/RecaptchaLegalBanner';

const labels = {
  customerName: <FormattedMessage {...globalTranslations.fullNameTitle} />,
  email: <FormattedMessage {...globalTranslations.emailTitle} />,
  message: <FormattedMessage {...globalTranslations.messageTitle} />,
};

const FORM_MESSAGE_ROWS = 7;

@observer
class ProductEnquiryForm extends Component {
  constructor(props) {
    super(props);
    const { formValidator } = this.props;

    this.form = null;
    this.formName = 'ProductEnquiryForm';
    this.state = {
      error: null,
      requestState: RequestState.NONE,
      // This is used to know, if we've made a new request so we can re-animate errors
      requestId: 0,
    };

    this.form = createForm({
      customerName: createFormField(
        {},
        formValidator.fullName(labels.customerName)
      ),
      email: createFormField({}, formValidator.email(labels.email)),
      message: createFormField({}, [notBlank(labels.message)]),
    });
  }

  handleSubmit = async (e) => {
    const {
      configStore,
      uiStore,
      analytics,
      apiWrapper,
      id,
      price,
      type,
    } = this.props;

    e.preventDefault();
    this.form.validate();

    if (this.form.valid) {
      this.setState({ requestState: RequestState.LOADING });
      await uiStore.recaptchaValidation.getCaptchaToken();

      if (uiStore.recaptchaValidation.isValid) {
        const values = this.form.getFieldValues();
        values.recaptcha = uiStore.recaptchaValidation.token;

        apiWrapper
          .apiAxios()
          .post(`product-enquiries/${id}/by-email`, {
            ...values,
            enquiryType: type,
          })
          .then(() => {
            this.setState({ requestState: RequestState.LOADED });
            configStore.analytics.ga4.enabled &&
              price &&
              analytics.generateLead(price);
          })
          .catch((error) => {
            this.setState({
              requestState: RequestState.ERROR,
              error,
              requestId: this.state.requestId + 1,
            });
          });
      }

      configStore.recaptcha.enabled && uiStore.recaptchaValidation.resetCaptchaToken();
    }
  };

  field = (fieldName, props) => {
    const field = this.form.fields.get(fieldName);
    return (
      field.active && (
        <FormGroupField
          required={field.hasValidators()}
          field={field}
          formName={this.formName}
          fieldName={fieldName}
          label={labels[fieldName]}
          {...props}
        />
      )
    );
  };

  getErrorMessages = () => {
    const { error } = this.state;
    return get(error, 'response.data.messages');
  };

  renderIcon = () => {
    const { requestState } = this.state;
    const loading = requestState === RequestState.LOADING;

    if (!loading) {
      return null;
    }

    return <Icon name="circle-o-notch" spin={loading} />;
  };

  renderForm = () => {
    const { configStore, uiStore, intl } = this.props;
    const { requestState, requestId, error } = this.state;

    const isLoading = requestState === RequestState.LOADING;

    return (
      <Form
        className="ProductEnquiryForm__form"
        noValidate
        onSubmit={this.handleSubmit}
        name={this.formName}
      >
        <Row>
          <Col md="12">
            {this.field('customerName', {
              autoComplete: 'name',
            })}
            {this.field('email', { type: 'email', autoComplete: 'email' })}
            {this.field('message', {
              type: 'textarea',
              rows: FORM_MESSAGE_ROWS,
            })}
            {error && get(error, 'response.status') !== 422 && (
              <ErrorHandler error={createErrorModel(error)} />
            )}
            {this.getErrorMessages() && (
              <Alert color="danger" key={requestId}>
                {this.getErrorMessages().map((message) => (
                  <div key={message}>{message}</div>
                ))}
              </Alert>
            )}
            {configStore.recaptcha.enabled && (
              <RecaptchaLegalBanner />
            )}
            <Button
              color="primary"
              block
              disabled={
                this.form.valid === false ||
                uiStore.recaptchaValidation.isValid === false ||
                isLoading
              }
              type="submit"
              className="ProductEnquiryForm__submit-button"
              aria-label={intl.formatMessage(globalTranslations.sendTitle)}
            >
              <span className="ProductEnquiryForm__submit-button-icon">
                {this.renderIcon()}
              </span>
              <FormattedMessage {...globalTranslations.sendTitle} />
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  render() {
    const { requestState } = this.state;

    return (
      <div className="ProductEnquiryForm">
        {requestState === RequestState.LOADED ? (
          <Alert color="success">
            <FormattedMessage
              id="product.enquirySuccess"
              defaultMessage="Your message was sent to our customer service successfully. We aim to handle the message as soon as possible."
            />
          </Alert>
        ) : (
          this.renderForm()
        )}
      </div>
    );
  }
}

ProductEnquiryForm.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  apiWrapper: PropTypes.instanceOf(ApiWrapper).isRequired,
  formValidator: PropTypes.instanceOf(FormValidator).isRequired,
  intl: intlShape.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(ProductEnquiryType)),
  price: PropTypes.number,
};

export default injectIntl(
  inject(
    'configStore',
    'uiStore',
    'analytics',
    'apiWrapper',
    'formValidator'
  )(ProductEnquiryForm)
);
