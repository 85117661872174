import {
  getStorageAndParse,
  stringifyAndSetStorage,
} from '../../../util/storage';
import BaseSkeleton from '../BaseSkeleton';

const SKELETON_STORAGE_NAME = 'productListHeights';
const ProductListSkeleton = BaseSkeleton.named('ProductListSkeleton')
  .actions((self) => {
    return {
      setHeight: (page) => {
        const currentListHeights = getStorageAndParse(SKELETON_STORAGE_NAME);
        if (!currentListHeights) {
          stringifyAndSetStorage(SKELETON_STORAGE_NAME, {
            [page]: self.height,
          });
        }

        return;
      },
      updateHeight: ({ page, height }) => {
        const currentListHeights = getStorageAndParse(SKELETON_STORAGE_NAME);
        if (currentListHeights) {
          stringifyAndSetStorage(SKELETON_STORAGE_NAME, {
            ...currentListHeights,
            [page]: height,
          });
        }
      },
    };
  })
  .views((self) => {
    return {
      getHeight: (page) => {
        const currentListHeights = getStorageAndParse(SKELETON_STORAGE_NAME);
        if (currentListHeights && currentListHeights[page]) {
          return currentListHeights[page];
        }

        return self.height;
      },
    };
  });

export default ProductListSkeleton;
