import { types } from 'mobx-state-tree';

const AcceptableTermsType = {
  DELIVERY_TERMS: 'DELIVERY_TERMS',
  PRIVACY_TERMS: 'PRIVACY_TERMS'
};

export default AcceptableTermsType;

export const AcceptableTermsTypeType = types.enumeration(
  'AcceptableTermsType',
  Object.values(AcceptableTermsType)
);
