import { types } from 'mobx-state-tree';

const PickUpPointInfo = types.model('PickUpPointInfo', {
  id: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  address: types.optional(types.string, ''),
  contact_info: types.optional(types.string, '')
});

export default PickUpPointInfo;
