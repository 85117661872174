import { types } from 'mobx-state-tree';

import OrderMethod from './OrderMethod';
import OrderAddress from './OrderAddress';

const OrderPayment = types.model({
  address: types.optional(OrderAddress, {}),
  method: types.maybeNull(types.optional(OrderMethod, {})),
});

export default OrderPayment;
