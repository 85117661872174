import { types } from 'mobx-state-tree';

import { CouponActivationTypeType } from '../types/CouponActivationType';
import { CouponDiscountTypeType } from '../types/CouponDiscountType';

const Coupon = types.model('Coupon', {
  id: types.identifierNumber,
  name: types.string,
  description: types.maybeNull(types.string),
  activation_type: CouponActivationTypeType,
  discount_type: CouponDiscountTypeType,
  amount: types.maybeNull(types.number),
  percentage: types.maybeNull(types.number),
  minimum_order: types.maybeNull(types.number),
  time_to_activation: types.maybeNull(types.number),
  end_date: types.maybeNull(types.string)
});

export default Coupon;
