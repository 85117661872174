import { types } from 'mobx-state-tree';

const CampaignCodeDiscountType = {
  AMOUNT: 'AMOUNT',
  PERCENTAGE: 'PERCENTAGE',
  FREE_SHIPPING: 'FREE_SHIPPING'
};

export default CampaignCodeDiscountType;

export const CampaignCodeDiscountTypeType = types.enumeration(
  'CampaignCodeDiscountType',
  Object.values(CampaignCodeDiscountType)
);
