import { types } from 'mobx-state-tree';

const ProductTypeClass = {
  BOTTLE_DEPOSIT: 'BOTTLE_DEPOSIT',
  DISCOUNT: 'DISCOUNT',
  GIFT_VOUCHER: 'GIFT_VOUCHER',
  LOW_ORDER_FEE: 'LOW_ORDER_FEE',
  PAYMENT_COST: 'PAYMENT_COST',
  PHYSICAL: 'PHYSICAL',
  SERVICE: 'SERVICE',
  SHIPPING_COST: 'SHIPPING_COST'
};

export default ProductTypeClass;

export const ProductType = types.enumeration(
  'ProductTypeClass',
  Object.values(ProductTypeClass)
);
