import { types } from 'mobx-state-tree';

const MobileNavigationTab = {
  PRODUCTS: 'PRODUCTS',
  INFO: 'INFO',
  ACCOUNT: 'ACCOUNT',
  WISHLIST: 'WISHLIST'
};

export default MobileNavigationTab;

export const MobileNavigationTabType = types.enumeration(
  'MobileNavigationTab',
  Object.values(MobileNavigationTab)
);
