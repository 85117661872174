import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { blockTestRender } from '../../../util/process';

const AdLoaderSkeleton = () => {
  return (
    <div className="AdLoaderSkeleton">
      {blockTestRender() && <Skeleton height="100%" width="100%" />}
    </div>
  );
};

export default AdLoaderSkeleton;
