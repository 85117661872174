import { initialImageOrigin } from './ImageLightboxProductImage';

export const REDUCER_ACTIONS = {
  IMAGE_CHANGED: 'image_changed',
  IMAGE_FOCUSED: 'image_focused',
  IMAGE_UNFOCUSED: 'image_unfocused',
  IMAGE_ZOOM_IN: 'image_zoom_in',
  IMAGE_ZOOM_OUT: 'image_zoom_out',
};

export function lightboxReducer(state, action) {
  switch (action.type) {
    case REDUCER_ACTIONS.IMAGE_CHANGED: {
      const updatedImage = action.selectedImage;
      return {
        ...state,
        selectedImage: updatedImage,
        [state.selectedImage]: {
          ...state[state.selectedImage],
          focus: false,
          scale: 1,
        },
      };
    }
    case REDUCER_ACTIONS.IMAGE_FOCUSED: {
      return {
        ...state,
        [state.selectedImage]: {
          ...state[state.selectedImage],
          focus: true,
          scale: 2,
        },
      };
    }
    case REDUCER_ACTIONS.IMAGE_UNFOCUSED: {
      return {
        ...state,
        [state.selectedImage]: {
          ...state[state.selectedImage],
          focus: false,
          scale: 1,
        },
      };
    }
    case REDUCER_ACTIONS.IMAGE_ZOOM_IN: {
      return {
        ...state,
        [state.selectedImage]: {
          ...state[state.selectedImage],
          scale: state[state.selectedImage].scale + 0.25,
          origin: state[state.selectedImage].focus
            ? initialImageOrigin
            : state[state.selectedImage].origin,
          focus: state[state.selectedImage].focus
            ? false
            : state[state.selectedImage].focus,
        },
      };
    }
    case REDUCER_ACTIONS.IMAGE_ZOOM_OUT: {
      return {
        ...state,
        [state.selectedImage]: {
          ...state[state.selectedImage],
          scale: state[state.selectedImage].scale - 0.25,
          origin: state[state.selectedImage].focus
            ? initialImageOrigin
            : state[state.selectedImage].origin,
          focus: state[state.selectedImage].focus
            ? false
            : state[state.selectedImage].focus,
        },
      };
    }
    default: {
      throw Error(action.type);
    }
  }
}
