import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const SEOSchema = ({ data }) => {
  const schema = JSON.stringify(data);

  return (
    <Helmet>
      <script type="application/ld+json">{schema}</script>
    </Helmet>
  );
};

SEOSchema.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SEOSchema;
