import { camelCase } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import Ad from '../../../../models/banner/Ad';
import { modelOf } from '../../../../prop-types';
import UIStore from '../../../../store/UIStore';
import ConfigStore from '../../../../store/ConfigStore';

// Mobile Aspect ratio 1:1. e.g. 250x250px or as close as possible.
const MOBILE_ASPECT_RATIO = 1;
const VIDEO_ASPECT_RATIO = 16 / 9;
const SCENT_HEIGHT = 50;

/**
 *
 * @param {number} crossAxisSize
 * @param {number} localAspectRatio
 * @returns {number} main axis size
 */
export const countMainAxisSize = (crossAxisSize, localAspectRatio) =>
  crossAxisSize / localAspectRatio;

/**
 *
 * @param {number} localMaximumCrossAxisSize
 * @param {number} axisSize
 * @returns {number} min cross axis size
 */
export const countMinCrossAxisSize = (localMaximumCrossAxisSize, axisSize) =>
  Math.min(localMaximumCrossAxisSize, axisSize);

const BannerSliderStyles = ({
  configStore,
  uiStore,
  bannerSliderId,
  bannerSlider,
  elementWidth,
  slides,
  aspectRatio,
  maximumCrossAxisSize,
  ifVertical,
  slidesToShow,
}) => {
  const getBannerMobileCssClasses = () => {
    const ifMoreThanOneMobileImages = filterVisibleMobileBanners.length >= 1;

    const bannerWrapper = ifMoreThanOneMobileImages
      ? '.Banner__wrapper-mobile'
      : '.Banner__wrapper';

    const bannerBackgroundImage = ifMoreThanOneMobileImages
      ? '.Banner__background-mobile-image'
      : '.Banner__background-image';

    return { bannerWrapper, bannerBackgroundImage };
  };

  const getAspectRatio = () =>
    filterVisibleMobileBanners.length >= 1 ? MOBILE_ASPECT_RATIO : aspectRatio;

  const getMaximumHeight = () =>
    filterVisibleMobileBanners.length >= 1
      ? elementWidth
      : maximumCrossAxisSize;

  const shouldAddScentHeight = () => {
    return (
      !!configStore.banner.scent.pattern &&
      !(uiStore.isMobile && !configStore.banner.scent.shouldShowMobile) &&
      !configStore.banner.scent.onTop
    );
  };

  const shouldRemoveScentHeight = () => {
    return (
      !!configStore.banner.scent.pattern &&
      !(uiStore.isMobile && !configStore.banner.scent.shouldShowMobile) &&
      configStore.banner.scent.onTop
    );
  };

  const getScentHeight = () => {
    return shouldAddScentHeight() ? SCENT_HEIGHT : 0;
  };

  const getContentScentHeight = () => {
    return shouldRemoveScentHeight() ? SCENT_HEIGHT : 0;
  };

  const getMainAxis = () => (ifVertical ? 'height' : 'width');

  const getCrossAxis = () => (ifVertical ? 'width' : 'height');

  const getCrossAxisSize = () =>
    bannerSlider && bannerSlider[camelCase('client ' + getCrossAxis())];

  const countMainAxisDefaultSize = useCallback(
    (crossAxisSize, localAspectRatio) => crossAxisSize * localAspectRatio,
    [slides]
  );

  const filterVisibleMobileBanners = useMemo(() => {
    return [...slides].filter(
      (banner) => uiStore.isSmallest && banner.mobile_image
    );
  }, [slides]);

  /**
   * Generates a CSS style for the slider that restricts the maximum size
   * it may take at the current window size.
   *
   * The banner slider areas each have a preferred aspect ratio and a maximum
   * height/width (depending on the orientation). The general behavior is that
   * the slider area scales in the preferred aspect ratio until it hits the
   * maximum height or width, then only scales in the unrestricted direction
   * if it has more room. E.g. a horizontal banner slider with 2:1 aspect
   * ratio and 200 px maximum cross axis (height) size will behave as follows:
   *
   * Usable width -> Enforced size
   *    100 px    -> 100 px wide, 50 px tall (restricted by aspect ratio)
   *    400 px    -> 400 px wide, 200 px tall (restricted by aspect ratio)
   *    401 px    -> 401 px wide, 200 px tall (restricted by height)
   *    800 px    -> 800 px wide, 200 px tall (restricted by height)
   */

  const getCSS = useMemo(() => {
    const localAspectRatio = getAspectRatio();
    const localMaximumCrossAxisSize = getMaximumHeight();
    const scentHeight = getScentHeight();
    const contentScentHeight = getScentHeight();
    const mainAxis = getMainAxis();
    const crossAxis = getCrossAxis();

    if (mainAxis === 'width') {
      const mainAxisSize = countMainAxisSize(elementWidth, localAspectRatio);
      const crossAxisSize = countMinCrossAxisSize(
        localMaximumCrossAxisSize,
        mainAxisSize
      );
      const mainAxisDefaultSize = countMainAxisDefaultSize(
        crossAxisSize,
        localAspectRatio
      );

      const videoMainAxisSize = countMainAxisDefaultSize(
        crossAxisSize,
        VIDEO_ASPECT_RATIO
      );

      const { bannerWrapper, bannerBackgroundImage } =
        getBannerMobileCssClasses();

      return `
        #${bannerSliderId} .BannerSlider__slide ${bannerWrapper},
        #${bannerSliderId} .BannerSlider__slide ${bannerBackgroundImage} {
          ${crossAxis}: ${crossAxisSize}px;
          min-${mainAxis}: ${crossAxisSize}px;
        }
        #${bannerSliderId} .BannerSlider__slide .Banner__overlay {
          ${crossAxis}: ${crossAxisSize - getContentScentHeight()}px;
          min-${mainAxis}: ${crossAxisSize}px;
        }
        #${bannerSliderId} .BannerSlider__slide .Banner__video-container {
          ${crossAxis}: ${crossAxisSize}px;
          ${mainAxis}: ${videoMainAxisSize}px;
        }
        #${bannerSliderId} .slick-slider,
        #${bannerSliderId} .slick-list,
        #${bannerSliderId} .slick-slide,
        #${bannerSliderId} .BannerSlider__slide,
        #${bannerSliderId} .Banner,
        #${bannerSliderId} .slick-track {
          max-${crossAxis}: ${crossAxisSize + scentHeight}px;
        }
        #${bannerSliderId} .BannerSlider__slide {
          max-${mainAxis}: ${elementWidth}px;
        }
        #${bannerSliderId} .BannerSlider__slide .Banner--default-width {
          ${mainAxis}: ${mainAxisDefaultSize}px;
        }
        `;
    } else {
      const crossAxisSize = countMinCrossAxisSize(
        localMaximumCrossAxisSize,
        getCrossAxisSize()
      );
      const mainAxisSize = countMainAxisSize(crossAxisSize, localAspectRatio);
      const sliderSizeByMainAxis = mainAxisSize * slidesToShow;

      return `
        #${bannerSliderId} .BannerSlider__slide img {
          max-${crossAxis}: ${crossAxisSize}px;
          max-${mainAxis}: ${mainAxisSize}px;
        }
        #${bannerSliderId} .BannerSlider__slide,
        #${bannerSliderId} .slick-slide {
          ${mainAxis}: ${mainAxisSize}px;
        }

        #${bannerSliderId} .slick-slider,
        #${bannerSliderId} .slick-list,
        #${bannerSliderId} .slick-track {
          ${mainAxis}: ${sliderSizeByMainAxis}px !important;
        }
        `;
      // Upper slick-block is a fix for react-slider slider height bug, if there's equal or less amount of images that are set in slidesToShow
      // slidesToScroll amount seems to also affect somehow to the height calculation
    }
  }, [slides, bannerSlider]);

  return <style>{getCSS}</style>;
};

BannerSliderStyles.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  aspectRatio: PropTypes.number.isRequired,
  maximumCrossAxisSize: PropTypes.number.isRequired,
  slides: PropTypes.arrayOf(modelOf(Ad)),
  bannerSliderId: PropTypes.string,
  elementWidth: PropTypes.number,
  ifVertical: PropTypes.bool,
  slidesToShow: PropTypes.number,
  bannerSlider: PropTypes.object,
};

export default inject('configStore', 'uiStore')(observer(BannerSliderStyles));
