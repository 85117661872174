import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button
} from 'reactstrap';
import qs from 'query-string';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import globalTranslations from '../../../i18n/globalTranslations';
import SurveyStore from '../../../store/SurveyStore';
import SurveyForm from '../SurveyForm';
import WysiwygContent from '../../common/WysiwygContent';
import RouterPropTypes from 'react-router-prop-types';
import { modelOf } from '../../../prop-types';
import RequestState from '../../../types/RequestState';
import QuestionsPlacement from '../../../types/QuestionsPlacementType';

const QUERYPARAMS = ['preview_survey', 'survey'];
const DEFAULT_COLUMN_WIDTH = 50;
const DEFAULT_MODAL_WIDTH = 800;

@observer
export class SurveyModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pre_question_answered: false
    };

    const checkParams = this.handleParams();
    if (!checkParams) {
      this.props.surveyStore.activateTimedSurvey();
    }

    this.props.history.listen((location) => {
      const match = QUERYPARAMS.some((param) =>
        location.search.includes(param)
      );
      if (match) {
        this.handleParams(location);
      }
    });

    if (!window.isSSR) {
      this.initApi();
    }
  }

  initApi = () => {
    window.SurveysApi = {
      Load: (id) => {
        this.props.surveyStore.loadSurvey(id);
        this.setState({ pre_question_answered: false });
      }
    };
  };

  closePreQuestion = () => {
    this.setState({
      pre_question_answered: true
    });
  };

  handleOpen = () => {
    if (typeof document === 'undefined') {
      return null;
    }
    document.body.classList.add('modal-open');
  };

  handleParams = (location = null) => {
    const { surveyStore } = this.props;
    const search = location ? location.search : this.props.location.search;
    const params = qs.parse(search);

    if (params.preview_survey) {
      this.setState({ pre_question_answered: false });
      return surveyStore.previewSurvey(params.preview_survey);
    }

    if (params.survey) {
      this.setState({ pre_question_answered: false });
      return surveyStore.loadSurvey(params.survey);
    }

    return false;
  };

  getModalContentBody = () => {
    const { surveyStore } = this.props;
    const { activeSurvey } = surveyStore;

    switch (activeSurvey.questions_placement) {
      case QuestionsPlacement.TOP:
        return (
          <Col xs={12}>
            <WysiwygContent html={activeSurvey.body} />
            <SurveyForm />
          </Col>
        );
      case QuestionsPlacement.BOTTOM:
        return (
          <Col xs={12}>
            <SurveyForm />
            <WysiwygContent html={activeSurvey.body} />
          </Col>
        );
      case QuestionsPlacement.LEFT:
        return (
          <React.Fragment>
            <Col
              className="SurveyModal__AnswersColumn"
              style={{ width: this.getColumnWidth('answers') + '%' }}
            >
              <SurveyForm />
            </Col>
            <Col
              className="SurveyModal__BodyColumn"
              style={{ width: this.getColumnWidth('body') + '%' }}
            >
              <WysiwygContent html={activeSurvey.body} />
            </Col>
          </React.Fragment>
        );
      case QuestionsPlacement.RIGHT:
        return (
          <React.Fragment>
            <Col
              className="SurveyModal__BodyColumn"
              style={{ width: this.getColumnWidth('body') + '%' }}
            >
              <WysiwygContent html={activeSurvey.body} />
            </Col>
            <Col
              className="SurveyModal__AnswersColumn"
              style={{ width: this.getColumnWidth('answers') + '%' }}
            >
              <SurveyForm />
            </Col>
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  getColumnWidth(name) {
    const { surveyStore } = this.props;
    const { activeSurvey } = surveyStore;

    switch (name) {
      case 'answers':
        return activeSurvey.answers_column_width || DEFAULT_COLUMN_WIDTH;
      case 'body':
        return activeSurvey.body_column_width || DEFAULT_COLUMN_WIDTH;
      default:
        return DEFAULT_COLUMN_WIDTH;
    }
  }

  render() {
    const { surveyStore } = this.props;
    const { pre_question_answered } = this.state;
    const survey = surveyStore.activeSurvey;

    if (window.isSSR || !survey || surveyStore.state === RequestState.NONE) {
      return null;
    }

    if (survey.has_pre_question && !pre_question_answered) {
      return (
        <Modal
          backdrop={'static'}
          toggle={() => {
            surveyStore.toggleModal();
          }}
          style={{
            maxWidth: survey.survey_modal_width || DEFAULT_MODAL_WIDTH
          }}
          className="SurveyModal"
          size="lg"
          onOpened={this.handleOpen}
          isOpen={surveyStore.modalShouldOpen()}
        >
          <style>{survey.styles}</style>
          <ModalHeader
            className="SurveyModal__header"
            toggle={() => surveyStore.toggleModal()}
          >
            {survey.title}
          </ModalHeader>
          <ModalBody>
            <WysiwygContent html={survey.pre_question_description} />
            <Row>
              <Col md={3}>
                <Button
                  onClick={() => {
                    this.closePreQuestion();
                  }}
                  color="primary"
                  block
                >
                  <FormattedMessage {...globalTranslations.yesTitle} />
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  onClick={() => {
                    surveyStore.toggleModal();
                  }}
                  color="primary"
                  block
                >
                  <FormattedMessage {...globalTranslations.noTitle} />
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      );
    }

    return (
      <Modal
        backdrop={'static'}
        toggle={surveyStore.toggleModal}
        className="SurveyModal"
        size="lg"
        onOpened={this.handleOpen}
        isOpen={surveyStore.modalShouldOpen()}
      >
        <style>{survey.styles}</style>
        <ModalHeader
          className="SurveyModal__header"
          toggle={surveyStore.toggleModal}
        >
          {survey.title}
          <WysiwygContent html={survey.header_text} />
        </ModalHeader>
        <ModalBody>
          <Row>{this.getModalContentBody()}</Row>
        </ModalBody>
        <ModalFooter>
          <WysiwygContent html={survey.footer_text} />
        </ModalFooter>
      </Modal>
    );
  }
}

SurveyModal.propTypes = {
  surveyStore: modelOf(SurveyStore).isRequired,
  location: RouterPropTypes.location.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(inject('surveyStore')(SurveyModal));
