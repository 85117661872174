import { inject } from 'mobx-react';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { blockTestRender } from '../../../util/process';

const PageSkeleton = ({ categoryStore, uiStore }) => {
  const { placeholders } = uiStore;

  const productListHeight = categoryStore.activeCategory?.name
    ? placeholders.productList.getHeight(categoryStore.activeCategory.name)
    : placeholders.productList.height;
  return (
    <div className="PageSkeleton">
      {blockTestRender() && <Skeleton height={40} />}
      <div className="Skeleton__spacer" />
      {blockTestRender() && <Skeleton height={20} />}
      <div className="Skeleton__spacer" />
      {blockTestRender() && <Skeleton count={1.6} />}
      <div className="Skeleton__spacer" />
      {blockTestRender() && (
        <Skeleton
          style={{
            lineHeight: 1,
          }}
          height={productListHeight}
        />
      )}
    </div>
  );
};

export default inject('categoryStore', 'uiStore')(PageSkeleton);
