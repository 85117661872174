import React, { Component } from 'react';
import { DropdownItem } from 'reactstrap';
import { observer } from 'mobx-react';

import NavigationDropdownSubmenu from '../../common/NavigationDropdownSubmenu';
import NavigationEntity from '../../../types/NavigationEntity';
import MaybeExternalLink from '../../common/MaybeExternalLink';

@observer
class NavigationMenuRowDropdownItem extends Component {
  render() {
    const { item } = this.props;
    const { image, label, type, path } = item;

    if (item.children && item.children.length > 0) {
      return (
        <NavigationDropdownSubmenu labelText={item.label} to={item.path}>
          {item.children.map((subItem) => (
            <NavigationMenuRowDropdownItem item={subItem} key={subItem.id} />
          ))}
        </NavigationDropdownSubmenu>
      );
    } else {
      return (
        <DropdownItem
          key={item.id}
          tag={MaybeExternalLink}
          to={path}
          type={type}
          className="NavigationMenuRowDropdownItem"
          aria-label={item.accessible_text}
        >
          {image && <img src={image} alt={image} loading="lazy" />}
          {label}
        </DropdownItem>
      );
    }
  }
}

NavigationMenuRowDropdownItem.propTypes = {
  item: NavigationEntity.isRequired
};

export default NavigationMenuRowDropdownItem;
