import React from 'react';
import { FormattedMessage } from 'react-intl';

import globalTranslations from '../../../i18n/globalTranslations';

const FooterCopyright = () => {
  return (
    <div className="FooterCopyright">
      <a
        href="https://confirma.fi/ratkaisut/verkkokauppa/?utm_source=verkkokauppa&utm_medium=footer&utm_campaign=e-com"
        target="_blank"
        rel="noopener"
      >
        <FormattedMessage {...globalTranslations.copyrightContent} />
      </a>
    </div>
  );
};

export default FooterCopyright;
