import { types } from 'mobx-state-tree';

const OrderStatusClass = {
  UNFINISHED: 'UNFINISHED',
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  READY: 'READY',
  CANCELED: 'CANCELED',
  ACTIVE: 'ACTIVE',
  DEACTIVATED: 'DEACTIVATED',
  ON_HOLD: 'ON_HOLD'
};

export default OrderStatusClass;

export const OrderStatusType = types.enumeration(
  'OrderStatusClass',
  Object.values(OrderStatusClass)
);
