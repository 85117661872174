import { types } from 'mobx-state-tree';

const Client = types.model('Client', {
  id: types.number,
  firstname: types.string,
  lastname: types.string,
  company: types.optional(types.string, '')
});

export default Client;
