import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const ORIGINAL_TEXT_KEY = 'textOriginal';
const SWAP_TEXT_KEY = 'textSwap';

class WysiwygContent extends Component {
  container;
  key = 0;

  componentDidMount() {
    if (this.container) {
      this.container.addEventListener('click', this.handleClick, true);
    }
  }

  componentWillUnmount() {
    if (this.container) {
      this.container.removeEventListener('click', this.handleClick, true);
    }
  }

  /**
   * Handle elements with data-toggle="collapse"
   *
   * @param element
   */
  toggleCollapse = (element) => {
    const collapseSelector = element.getAttribute('href');
    const collapseElement = this.container.querySelector(collapseSelector);
    if (collapseElement) {
      collapseElement.classList.toggle('show');

      // Save original text, if not set.
      if (!element.dataset[ORIGINAL_TEXT_KEY]) {
        element.dataset[ORIGINAL_TEXT_KEY] = element.innerHTML;
      }

      if (!element.dataset[SWAP_TEXT_KEY]) {
        element.dataset[SWAP_TEXT_KEY] = element.dataset[ORIGINAL_TEXT_KEY];
      }

      element.innerHTML =
        element.innerHTML === element.dataset[ORIGINAL_TEXT_KEY]
          ? element.dataset[SWAP_TEXT_KEY]
          : element.dataset[ORIGINAL_TEXT_KEY];
    }
  };

  handleClick = (e) => {
    // We need to support some old Bootstrap "data-toggle" attributes.
    if (e.target.dataset && e.target.dataset.toggle) {
      e.preventDefault();
      switch (e.target.dataset.toggle) {
        case 'collapse':
          this.toggleCollapse(e.target);
          break;
        default:
      }
    }
  };

  getElementAttributes = (element) => {
    const attributes = { key: this.key++ };
    Array.from(element.attributes).forEach((attr) => {
      if (attr.name === 'id') {
        return;
      }
      attributes[attr.name] = attr.value;
    });

    return attributes;
  };

  getParsedScripts = () => {
    const { html } = this.props;

    // Bail if we are in SSR.
    if (typeof document === 'undefined') {
      return null;
    }

    const doc = document.implementation.createHTMLDocument('');
    doc.body.innerHTML = html;

    return [].map.call(doc.getElementsByTagName('script'), (el) => {
      return (
        <script {...this.getElementAttributes(el)}>{el.textContent}</script>
      );
    });
  };

  render() {
    const { html } = this.props;

    return (
      <Fragment>
        <Helmet>{this.getParsedScripts()}</Helmet>
        <div
          className="WysiwygContent"
          ref={(ref) => (this.container = ref)}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Fragment>
    );
  }
}

WysiwygContent.propTypes = {
  html: PropTypes.string
};

export default WysiwygContent;
