import React from 'react';
import { Alert, Container } from 'reactstrap';
import { inject } from 'mobx-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';

import Page from '../../components/layout/Page';
import RouteService from '../../services/RouteService';
import MainProductList from '../../components/product-list/MainProductList';
import HelperNavigation from '../../components/common/HelperNavigation';
import GenericLayout from '../../components/layout/GenericLayout';
import globalTranslations from '../../i18n/globalTranslations';
import { parse } from '../../util/queryString';
import SEOTitle from '../../components/head/SEOTitle';
import CanonicalLink from '../../components/head/CanonicalLink';
import PageTitle from '../../components/common/PageTitle';
import Paths from '../../types/Paths';
import ProductSortOrderBy from '../../types/ProductSortOrderBy';
import SortOder from '../../types/SortOrder';
import ScrollReset from '../../components/common/ScrollReset';
import RobotsMeta from '../../components/head/RobotsMeta';
import CommonPage from '../../types/CommonPage';
import ContentPopups from '../../components/ad/ContentPopups';

const AdvancedSearchPage = ({ location, intl, routeService }) => {
  const queryParams = parse(location.search);
  const searchText = queryParams.keywords;

  const getBreadcrumbsPath = () => {
    return routeService.transformBreadCrumbs([
      {
        text: intl.formatMessage(globalTranslations.searchResultsTitle),
        url: Paths.AdvancedSearch,
      },
    ]);
  };

  const renderSearchContent = (searchText) => {
    const listId = 'advanced_search_list';
    const listName = 'Advanced Search List';
    const fixedParams = {
      text: searchText,
      orderBy: ProductSortOrderBy.RELEVANCE,
      order: SortOder.DESC,
    };

    return (
      <MainProductList
        fixedParams={fixedParams}
        listId={listId}
        name={listName}
        showSort={false}
        allSections={true}
        redirectOnSingleMatch
        disableQuickSearch
        advancedSearchPage
      />
    );
  };

  return (
    <Page name={CommonPage.ADVANCED_SEARCH} className="AdvancedSearchPage">
      <ScrollReset key={searchText} />
      <SEOTitle
        title={{
          values: { term: searchText },
          ...globalTranslations.searchResultsForTitle,
        }}
      />
      <CanonicalLink path={routeService.getPath(Paths.AdvancedSearch)} />
      <RobotsMeta noindex />
      <Container className="AdvancedSearchPage__container">
        <GenericLayout
          content={
            <>
              <HelperNavigation breadcrumbsPath={getBreadcrumbsPath()} />
              <PageTitle>
                <FormattedMessage
                  {...globalTranslations.searchResultsForTermTitle}
                  values={{ term: searchText }}
                />
              </PageTitle>
              {searchText ? (
                renderSearchContent(searchText)
              ) : (
                <Alert color="danger">
                  <FormattedMessage
                    id="search.termTooShort"
                    defaultMessage="Search term is too short."
                  />
                </Alert>
              )}
            </>
          }
        />
      </Container>
      <ContentPopups />
    </Page>
  );
};

AdvancedSearchPage.propTypes = {
  intl: intlShape.isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  location: RouterPropTypes.location.isRequired,
};

export default injectIntl(
  withRouter(inject('routeService')(AdvancedSearchPage))
);
