import React, { Component } from 'react';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import { isObservableArray } from 'mobx';
import classNames from 'classnames';
import { uniqueId } from 'lodash';

import { modelOf } from '../../../prop-types';
import FormField from '../../../models/FormField';
import Field from '../Field';
import FieldError from '../FieldError';
import CheckboxFieldItem from '../../../types/CheckboxFieldItem';

@observer
class CheckboxField extends Component {
  constructor(props) {
    super(props);

    // In the normal use cases, the form and field names don't change, and
    // this only really affects the input/label binding, so not updating the id
    // afterwards if props change should still be fine.
    this.id = uniqueId(`${props.formName}__${props.fieldName}--`);
  }

  handleChange = (event) => {
    const { field, updateExternalValue } = this.props;
    const isChecked = event.target.checked;
    const value = String(event.target.value);

    if (this.hasMultipleOptions()) {
      if (isChecked) {
        field.setValue([...field.value, value]);
      } else {
        this.removeValue(value);
      }
    } else {
      if (isChecked) {
        field.setValue(value);
      } else {
        field.setValue(null);
      }
    }

    // Pass value for external components in user interaction.
    if (updateExternalValue) {
      updateExternalValue(field);
    }
  };

  hasMultipleOptions = () => {
    const { options } = this.props;
    return Array.isArray(options) || isObservableArray(options);
  };

  removeValue = (value) => {
    const { field } = this.props;
    const newValue = [...field.value];
    const valueIndex = newValue.findIndex((item) => item === value);
    if (valueIndex !== -1) {
      newValue.splice(valueIndex, 1);
    }
    field.setValue(newValue);
  };

  isChecked = (value) => {
    const { field } = this.props;
    if (!field.value) {
      return false;
    }
    if (Array.isArray(field.value) || isObservableArray(field.value)) {
      return field.value.indexOf(String(value)) !== -1;
    }
    return value === field.value;
  };

  getOptionArray() {
    const { options } = this.props;
    if (this.hasMultipleOptions()) {
      return options;
    } else {
      return [options];
    }
  }

  createUniqueKey = (option, index) => {
    return uniqueId(`${option}--${index}`);
  };

  render() {
    const {
      label,
      fieldName,
      field,
      required,
      className,
      // unused here, but no reason to pass to Field with rest
      options,
      formName,
      updateExternalValue,
      labelStyle,
      ...rest
    } = this.props;

    return (
      <FormGroup
        className={classNames('CheckboxField', className)}
        tag="fieldset"
        id={this.id}
      >
        {label && (
          <div className="CheckboxField__label">
            {label}
            {required && ' *'}
          </div>
        )}
        {this.getOptionArray().map((option, index) => {
          const optionId = `${this.id}-${index}`;
          return (
            <FormGroup
              className="CheckboxFieldSet__checkbox-field"
              check
              key={this.createUniqueKey(option.value, index)}
            >
              <Field
                component={Input}
                field={field}
                type="checkbox"
                name={fieldName}
                id={optionId}
                checked={this.isChecked(option.value)}
                value={option.value}
                handleChange={this.handleChange}
                invalid={field.errors.length > 0}
                required={required}
                {...rest}
              />
              <Label for={optionId} style={labelStyle} check>
                {option.label}
              </Label>
              <FieldError field={field} />
            </FormGroup>
          );
        })}
      </FormGroup>
    );
  }
}

CheckboxField.propTypes = {
  field: modelOf(FormField).isRequired,
  label: PropTypes.node,
  labelStyle: PropTypes.object,
  formName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,

  // Options should be:
  // - An array(-like), if the field value is expected to be an array of
  //   primitives, even with a single option
  // - A bare checkbox field shape, if the field value is expected to be
  //   a primitive
  options: PropTypes.oneOfType([
    CheckboxFieldItem,
    PropTypes.arrayOf(CheckboxFieldItem),
    MobxPropTypes.observableArrayOf(CheckboxFieldItem),
  ]).isRequired,
  className: PropTypes.string,
  updateExternalValue: PropTypes.func,
};

export default CheckboxField;
