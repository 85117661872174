import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormFeedback } from 'reactstrap';

import { modelOf } from '../../../prop-types';
import FormField from '../../../models/FormField';

@observer
class FieldError extends Component {
  render() {
    const { field } = this.props;

    if (!field.errors || field.errors.length === 0) {
      return null;
    }

    return (
      <FormFeedback className="FieldError">{field.errors[0]}</FormFeedback>
    );
  }
}

FieldError.propTypes = {
  field: modelOf(FormField).isRequired
};

export default FieldError;
