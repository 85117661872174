import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import CategoryBadges from '../CategoryBadges';
import NavigationTree from '../../navigation/NavigationTree';
import globalTranslations from '../../../i18n/globalTranslations';
import Paths from '../../../types/Paths';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import SectionStore from '../../../store/SectionStore';
import RouteService from '../../../services/RouteService';
import CategoryStore from '../../../store/CategoryStore';
import CustomNavigationLinkStore from '../../../store/CustomNavigationLinkStore';
import LinkType from '../../../types/LinkType';
import { transformInternalLink } from '../../../util/url';
import CustomNavigationLinkParentType from '../../../types/CustomNavigationLinkParentType';

@observer
export class TopLevelNavigationList extends Component {
  convertCategoriesToNavigationEntities = (
    categories,
    activeCategories,
    section
  ) => {
    const { routeService } = this.props;

    if (!categories || categories.length === 0) {
      return [];
    }

    return categories
      .filter((category) => !!category)
      .map((category) => {
        return {
          id: category.id,
          label: (
            <span>
              <CategoryBadges category={category} />
              {category.navigationName}
            </span>
          ),
          active: activeCategories.indexOf(category) !== -1,
          path: routeService.getPath(category.path, section),
          children: this.convertCategoriesToNavigationEntities(
            section
              ? category.getNonBlockedChildren(section.blockedCategoriesDict)
              : category.children,
            activeCategories,
            section
          ),
          customNavLinks: this.getCustomNavigationLinks(
            category,
            CustomNavigationLinkParentType.CATEGORY
          ),
        };
      });
  };

  convertSectionsToNavigationEntities = (sections, activeCategories) => {
    const { sectionStore, routeService } = this.props;

    return sections.map((section) => ({
      id: section.id,
      label: section.navigationName,
      active: sectionStore.activeSection === section,
      path: routeService.getPath(section.path),
      children: this.convertCategoriesToNavigationEntities(
        section.mainCategories,
        activeCategories,
        section
      ),
      customNavLinks: this.getCustomNavigationLinks(
        section,
        CustomNavigationLinkParentType.SECTION
      ),
    }));
  };

  getCustomNavigationLinks = (categoryOrSection, type) => {
    const { customNavigationLinkStore } = this.props;

    if (type === CustomNavigationLinkParentType.CATEGORY) {
      return this.convertCustomNavigationLinksToEntityItems(
        customNavigationLinkStore.filterByCategoryId(categoryOrSection.id)
      );
    }

    if (type === CustomNavigationLinkParentType.SECTION) {
      return this.convertCustomNavigationLinksToEntityItems(
        customNavigationLinkStore.filterBySectionId(categoryOrSection.id)
      );
    }
  };

  convertCustomNavigationLinksToEntityItems = (customNavLinks) => {
    const { customNavigationLinkStore } = this.props;
    return customNavLinks.map((customNavLink) => {
      return {
        id: customNavLink.id,
        label: customNavLink.name,
        path: this.getCustomCategoryListPath(customNavLink),
        link_type: customNavLink.link_type,
        location: customNavLink.location,
        parent_sibling_id: customNavLink.parent_sibling_id,
        customNavLinks: this.convertCustomNavigationLinksToEntityItems(
          customNavigationLinkStore.findChildLinks(customNavLink.id)
        ),
      };
    });
  };

  getCustomCategoryListPath = (customNavLink) => {
    const { routeService, sectionStore } = this.props;

    if (customNavLink.link_type === LinkType.EXTERNAL_LINK) {
      return customNavLink.link;
    }

    if (customNavLink.link_type === LinkType.INTERNAL_LINK) {
      return routeService.getPath(transformInternalLink(customNavLink.link));
    }

    return routeService.getPath(customNavLink.link, sectionStore.activeSection);
  };

  render() {
    const {
      configStore,
      sectionStore,
      categoryStore,
      routeService,
      activeCategories,
    } = this.props;

    const navItems = configStore.activateSections
      ? this.convertSectionsToNavigationEntities(
          sectionStore.sections,
          activeCategories
        )
      : this.convertCategoriesToNavigationEntities(
          categoryStore.categories,
          activeCategories,
          null
        );

    if (configStore.navigation.sidebar.showNewProducts) {
      navItems.push({
        id: 'new_products',
        label: <FormattedMessage {...globalTranslations.newProductsTitle} />,
        path: routeService.getPath(Paths.NewProducts),
      });
    }

    if (configStore.navigation.sidebar.showOnSale) {
      navItems.push({
        id: 'on_sale',
        label: <FormattedMessage {...globalTranslations.onSaleTitle} />,
        path: routeService.getPath(Paths.OnSale),
      });
    }

    return (
      <NavigationTree items={navItems} className="TopLevelNavigationList" />
    );
  }
}

TopLevelNavigationList.propTypes = {
  activeCategories: PropTypes.array.isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  categoryStore: modelOf(CategoryStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  customNavigationLinkStore: modelOf(CustomNavigationLinkStore).isRequired,
};

export default inject(
  'configStore',
  'sectionStore',
  'categoryStore',
  'routeService',
  'customNavigationLinkStore'
)(TopLevelNavigationList);
