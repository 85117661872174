import { types } from 'mobx-state-tree';

const PageView = types.model('PageView', {
  categoryId: types.maybeNull(types.number),
  categoryName: types.maybeNull(types.string),
  lang: types.optional(types.string, ''),
  page: types.maybeNull(types.string),
  parentCategoryId: types.maybeNull(types.number),
  productActive: types.maybeNull(types.boolean),
  productId: types.maybeNull(types.string),
  productName: types.maybeNull(types.string),
  refererUrl: types.maybeNull(types.string),
  visitedUrl: types.optional(types.string, '')
});

export default PageView;
