import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'reactstrap';

import RouteService from '../../../services/RouteService';
import InfoPageStore from '../../../store/InfoPageStore';
import { modelOf } from '../../../prop-types';

@observer
export class InfoPageLink extends Component {
  componentDidMount() {
    const { infoPageStore, pageId } = this.props;

    if (!pageId) {
      return null;
    }

    const infoPage = this.getInfoPage();
    if (!infoPage || infoPage.content === null) {
      infoPageStore.loadPage(pageId).catch((e) => {
        console.error(e);
      });
    }
  }

  getInfoPage = () => {
    const { infoPageStore, pageId } = this.props;

    return infoPageStore.pages.get(pageId);
  };

  render() {
    const { linkText, routeService, className, navLinkClassName } = this.props;
    const infoPage = this.getInfoPage();

    if (!infoPage) {
      return null;
    }

    return (
      <div className={classNames('InfoPageLink', className)}>
        <NavLink
          className={classNames('InfoPageLink__nav-link', navLinkClassName)}
          href={routeService.getPath(infoPage.path)}
          target="_blank"
          rel="noopener"
        >
          {linkText || infoPage.name}
        </NavLink>
      </div>
    );
  }
}

InfoPageLink.propTypes = {
  infoPageStore: modelOf(InfoPageStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  pageId: PropTypes.number,
  className: PropTypes.string,
  navLinkClassName: PropTypes.string,
  linkText: PropTypes.node
};

export default inject('infoPageStore', 'routeService')(InfoPageLink);
