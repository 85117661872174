import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import ProductSign from '../ProductSign';
import globalTranslations from '../../../i18n/globalTranslations';

class ProductSignNew extends Component {
  render() {
    return (
      <div className="ProductSignNew">
        <ProductSign>
          <FormattedMessage {...globalTranslations.newTitle} />
        </ProductSign>
      </div>
    );
  }
}

export default ProductSignNew;
