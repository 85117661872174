import { types } from 'mobx-state-tree';

const ActivatedCoupon = types.model('ActivatedCoupon', {
  coupon_id: types.number,
  html: types.string,
  code: types.maybeNull(types.string),
  activation_id: types.maybeNull(types.number)
});

export default ActivatedCoupon;
