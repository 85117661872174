import { types } from 'mobx-state-tree';
import { TranslatedImageTypeType } from '../types/TranslatedImageType';

const TranslatedImage = types.model('TranslatedImage', {
  height: types.number,
  id: types.identifierNumber,
  image_key: TranslatedImageTypeType,
  image_path: types.string,
  width: types.number,
});

export default TranslatedImage;
