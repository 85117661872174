import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import classNames from 'classnames';

import NavigationEntity from '../../../types/NavigationEntity';
import { modelOf } from '../../../prop-types';
import UIStore from '../../../store/UIStore';
import InfoPageStore from '../../../store/InfoPageStore';
import NavigationEntityPlacement from '../../../types/NavigationEntityPlacement';

const NavigationEntityItemButton = ({
  infoPageStore,
  uiStore,
  entity,
  buttonContentClassName,
}) => {
  const { accessible_text, image, label, page_id } = entity;

  const renderEntityComponentContent = () => {
    return (
      <>
        {image && <img src={image} alt={accessible_text} loading="lazy" />}
        {uiStore.isDesktop && (
          <span className={buttonContentClassName}>{label}</span>
        )}
      </>
    );
  };

  const getInfoPage = () => infoPageStore.pages.get(page_id);

  const setNavigationPopoverInfoPageContent = () => {
    const infoPage = getInfoPage();
    if (
      (!infoPage || infoPage.content === null) &&
      !infoPageStore.pageStates.get(page_id)
    ) {
      infoPageStore.loadPage(page_id).catch((e) => {
        console.error(e);
      });
    }

    uiStore.navigationPopover.setContentId(page_id);
  };

  const onClickHandler = () => {
    page_id && setNavigationPopoverInfoPageContent();
    uiStore.navigationPopover.toggle();
  };

  const getEntityComponent = () => {
    if (entity.placement === NavigationEntityPlacement.TOP_NAVIGATION) {
      return (
        <div
          className={classNames('NavigationEntityItemButton', 'nav-link', {
            'NavigationEntityItemButton__has-image': image,
            active:
              uiStore.navigationPopover.isOpen &&
              entity.page_id === uiStore.navigationPopover.contentId,
          })}
          onClick={onClickHandler}
        >
          {renderEntityComponentContent()}
        </div>
      );
    }

    return (
      <Button
        className={classNames('NavigationEntityItemButton', {
          'NavigationEntityItemButton__has-image': image,
          'NavigationEntityItemButton__mobile-button': !uiStore.isDesktop,
          active:
            uiStore.navigationPopover.isOpen &&
            entity.page_id === uiStore.navigationPopover.contentId,
        })}
        color={!uiStore.isDesktop ? 'plain' : 'primary'}
        onClick={onClickHandler}
      >
        {renderEntityComponentContent()}
      </Button>
    );
  };

  return getEntityComponent();
};

NavigationEntityItemButton.propTypes = {
  infoPageStore: modelOf(InfoPageStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  entity: NavigationEntity.isRequired,
  buttonContentClassName: PropTypes.string,
};

export default inject(
  'infoPageStore',
  'uiStore'
)(observer(NavigationEntityItemButton));
