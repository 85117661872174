import React from 'react';
import { Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import CartStore from '../../../store/CartStore';
import Icon from '../../common/Icon';
import MarkerBadge from '../../common/MarkerBadge';
import RequestState from '../../../types/RequestState';

const MobileNavigationCart = ({ cartStore, className, wrapperClassName }) => {
  const hasCountOverflow = () => cartStore.cart.number_of_products >= 100;

  const getProductCount = () => {
    return hasCountOverflow() ? '99+' : cartStore.cart.number_of_products;
  };

  const getMarkerStyles = () => {
    return { fontSize: hasCountOverflow() ? '0.7rem' : null };
  };

  return (
    <div className={classNames('MobileNavigationCart', className)}>
      <div
        className={classNames(
          'MobileNavigationCart__cart-button-wrapper',
          wrapperClassName
        )}
      >
        <Button
          color="plain"
          className="CommonNavigation__icons"
          onClick={() => cartStore.cartModal.toggle(true)}
          disabled={cartStore.state !== RequestState.LOADED}
        >
          <Icon name="shopping-cart" />
          {cartStore.cart && (
            <MarkerBadge
              className="d-xs-inline d-md-none"
              style={getMarkerStyles()}
            >
              {getProductCount()}
            </MarkerBadge>
          )}
        </Button>
      </div>
    </div>
  );
};

MobileNavigationCart.propTypes = {
  cartStore: modelOf(CartStore).isRequired,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

MobileNavigationCart.defaultProps = {
  className: '',
};

export default inject((stores) => ({
  cartStore: stores.cartStore,
}))(observer(MobileNavigationCart));
