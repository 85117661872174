import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';
import { uniqueId } from 'lodash';

import { modelOf } from '../../../prop-types';
import FormField from '../../../models/FormField';
import Field from '../Field';
import FieldError from '../FieldError';
import Icon from '../../common/Icon';

@observer
class SelectField extends Component {
  id = null;

  getFieldId = () => {
    if (!this.id) {
      // In the normal use cases, the form and field names don't change, and
      // this only really affects the input/label binding, so not updating the id
      // afterwards if props change should still be fine.
      this.id = uniqueId(`${this.props.formName}__${this.props.fieldName}--`);
    }
    return this.id;
  };

  getTooltip = (text) => {
    return (
      <React.Fragment>
        <Icon name="info" className={`tooltip-${this.id}`} />
        <UncontrolledTooltip target={`.tooltip-${this.id}`}>
          {text}
        </UncontrolledTooltip>
      </React.Fragment>
    );
  };

  render() {
    const {
      label,
      fieldName,
      field,
      options,
      required,
      tooltip,
      // unused, but no reason to pass to Field with rest
      formName,
      ...rest
    } = this.props;
    return (
      <FormGroup className="SelectField" tag="fieldset" id={this.getFieldId()}>
        {label && (
          <Label className="SelectField__label">
            {label}
            {required && ' * '}
            {tooltip && this.getTooltip(tooltip)}
          </Label>
        )}
        <Field
          component={Input}
          field={field}
          type="select"
          name={fieldName}
          id={this.getFieldId()}
          {...rest}
        >
          {options.map((option) => (
            <option key={uniqueId(option.value)} value={option.value}>
              {option.label}
            </option>
          ))}
        </Field>
        <FieldError field={field} />
      </FormGroup>
    );
  }
}

SelectField.propTypes = {
  field: modelOf(FormField).isRequired,
  label: PropTypes.node,
  formName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.node,
    })
  ).isRequired,
  required: PropTypes.bool,
};

export default SelectField;
