import { types, getEnv, flow } from 'mobx-state-tree';

import StatefulStore from '../models/StatefulStore';
import PaymentTokens from '../models/PaymentTokens';

const PaymentTokenStore = StatefulStore.named('PaymentTokenStore')
  .props({
    paymentTokens: types.optional(PaymentTokens, {})
  })
  .views((self) => ({
    get newTokenUrl() {
      return self.paymentTokens && self.paymentTokens.creation_url;
    },
    get tokens() {
      return self.paymentTokens && self.paymentTokens.tokens;
    }
  }))
  .actions((self) => {
    return {
      setup: function(activeTokens) {
        self.paymentTokens = activeTokens;
      },
      loadTokens: flow(function* loadTokens() {
        self.setLoading(true);

        try {
          self.paymentTokens = yield getEnv(self).apiWrapper.request(
            `payment-tokens`,
            {},
            { active_section: null }
          );
        } catch (error) {
          self.setError(error);
          throw error;
        }

        self.setLoading(false);
      }),
      removeToken: (id) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .delete(`payment-tokens/${id}`);
      }
    };
  });

export default PaymentTokenStore;
