import { types } from 'mobx-state-tree';

const RecurringOrderCycleType = {
  WEEKLY: 'WEEKLY',
  BIWEEKLY: 'BIWEEKLY',
  MONTHLY: 'MONTHLY',
  BIMONTHLY: 'BIMONTHLY',
  QUARTERLY: 'QUARTERLY',
  CUSTOM: 'CUSTOM'
};

export default RecurringOrderCycleType;

export const RecurringOrderCycleTypeType = types.enumeration(
  'RecurringOrderCycleType',
  Object.values(RecurringOrderCycleType)
);
