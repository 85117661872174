import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import PaymentModuleStore from '../../../store/PaymentModuleStore';
import LanguageStore from '../../../store/LanguageStore';
import CountryStore from '../../../store/CountryStore';
import ProductPriceInfo from '../../../models/ProductPriceInfo';
import AccountStore from '../../../store/AccountStore';
import { convertLocaleToFinlandIfAland } from '../../../util/locale';

@observer
export class KlarnaPromotionWidget extends Component {
  key = 0;

  componentDidMount() {
    this.refreshKlarnaWidgetPlacements();
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.priceInfo !== this.props.priceInfo ||
      prevProps.activeProductId !== this.props.activeProductId
    ) {
      this.refreshKlarnaWidgetPlacements();
    }
  }

  isVisible = () => {
    const { accountStore, countryStore, languageStore } = this.props;

    return !!(
      countryStore.activeCountry &&
      languageStore.activeLocale &&
      !accountStore.isRetailer
    );
  };

  getLocale = () => {
    const { countryStore, languageStore } = this.props;

    return (
      languageStore.activeLocale +
      '-' +
      convertLocaleToFinlandIfAland(countryStore.activeCountry.iso_code_2)
    );
  };

  getPrice = () => {
    const { priceInfo, accountStore } = this.props;
    if (!priceInfo) {
      return null;
    }
    const withTax = accountStore.showPricesWithTax;
    return priceInfo.getPrice(withTax);
  };

  getKlarnaScriptById = () => {
    const { paymentModuleStore, paymentModuleQueryResultKey } = this.props;
    const modules = paymentModuleStore.paymentWidgetQueryResults.get(
      paymentModuleQueryResultKey
    );
    if (!modules) {
      return [];
    }
    const widgets = modules.widgets;
    return widgets.map((widgets) => widgets.script);
  };

  createDummyHtmlDocument = (klarnaWidget) => {
    const doc = document.implementation.createHTMLDocument('');
    doc.body.innerHTML = klarnaWidget;

    return [].map.call(doc.getElementsByTagName('script'), (el) => (
      <script
        key={this.key++}
        async
        src={el.src}
        data-client-id={el.getAttribute('data-client-id')}
      />
    ));
  };

  renderHtmlWidget = (price) => {
    const { widgetType, externalKlarnaScript } = this.props;
    let klarnaScript = this.getKlarnaScriptById();

    if (externalKlarnaScript) {
      klarnaScript = externalKlarnaScript;
    }
    // Don't try rendering if we don't have any scripts or are in SSR context
    if (!klarnaScript.length || typeof document === 'undefined') {
      return null;
    }
    const scripts = this.createDummyHtmlDocument(klarnaScript);
    const amount = price > 0 ? price : null;
    const locale = this.getLocale();
    return (
      <>
        <Helmet>{scripts}</Helmet>
        <klarna-placement
          data-key={widgetType}
          data-locale={locale}
          data-purchase-amount={amount}
        />
      </>
    );
  };

  refreshKlarnaWidgetPlacements = () => {
    if (window.Klarna && window.Klarna.OnsiteMessaging) {
      window.Klarna.OnsiteMessaging.refresh();
    }
  };

  render() {
    const { footer } = this.props;

    if (!this.isVisible() || !this.props.ifSupportedCountryWithCurrency) {
      return null;
    }
    const price = Math.floor(this.getPrice() * 100);

    return (
      <div
        className={classNames('KlarnaPromotionWidget', {
          KlarnaPromotionWidget__width: !footer,
        })}
      >
        {this.renderHtmlWidget(price)}
      </div>
    );
  }
}

KlarnaPromotionWidget.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  countryStore: modelOf(CountryStore).isRequired,
  languageStore: modelOf(LanguageStore).isRequired,
  paymentModuleStore: modelOf(PaymentModuleStore).isRequired,
  accountStore: modelOf(AccountStore).isRequired,
  widgetType: PropTypes.string.isRequired,
  paymentModuleQueryResultKey: PropTypes.string.isRequired,
  priceInfo: modelOf(ProductPriceInfo),
  ifSupportedCountryWithCurrency: PropTypes.bool,
  externalKlarnaScript: PropTypes.string,
  footer: PropTypes.bool,
};

KlarnaPromotionWidget.defaultProps = {
  ifSupportedCountryWithCurrency: true,
};

export default inject(
  'configStore',
  'countryStore',
  'languageStore',
  'paymentModuleStore',
  'accountStore'
)(KlarnaPromotionWidget);
