import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const ProductCollectionMatrixInformation = ({ onClick }) => {
  return (
    <div
      className={classNames(
        'ProductCollectionMatrixInformation',
        'ProductPageContent__cart-matrix-product-information'
      )}
      onClick={onClick}
    >
      <Row className="ProductPageContent__cart-matrix-product-information-row">
        <Col
          md={12}
          className="ProductPageContent__cart-matrix-product-information-column"
        >
          <p className="ProductPageContent__cart-matrix-product-information-text">
            <FormattedMessage
              id="product.cartMatrixProductInformation"
              defaultMessage="Select the products to order from the table below."
            />
          </p>
        </Col>
      </Row>
    </div>
  );
};

ProductCollectionMatrixInformation.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ProductCollectionMatrixInformation;
