import React from 'react';
import PropTypes from 'prop-types';

import NavigationProposalText from './NavigationProposalText';
import NavigationProposalButton from './NavigationProposalButton';

const NavigationProposal = ({ isText, ...rest }) => {
  if (isText) {
    return <NavigationProposalText {...rest} />;
  }

  return <NavigationProposalButton {...rest} />;
};

NavigationProposal.propTypes = {
  isText: PropTypes.bool,
};

export default NavigationProposal;
