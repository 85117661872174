import React from 'react';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import ProductImage from '../ProductImage';
import Product from '../../../models/Product';

export const ProductMultiProductPickerPropertySelect = ({
  configStore,
  product,
  productImages,
  onProductChange,
  activeProductId,
  hidden,
}) => {
  const showImages = !configStore.product.hideMultiProductSelectionImage;
  const multiproductAsDropdown = configStore.product.multiproductAsDropdown;
  const productImagesLength = productImages.length <= 1;

  if (!showImages || !multiproductAsDropdown || productImagesLength) {
    return null;
  }

  const renderSelectionImages = () => {
    if (!productImages || hidden) {
      return null;
    }

    const images = product.multi.children.map((childProduct) => {
      const productImage = childProduct.getImage(childProduct.id)
        ? childProduct.getImage(childProduct.id)
        : product.getImage(childProduct.id);

      const isActive = activeProductId && activeProductId === childProduct.id;

      const notAvailable = !childProduct.available_somewhere;

      return (
        <div
          key={childProduct.id}
          className={classNames(
            'ProductMultiProductPickerPropertySelect__image',
            {
              'ProductMultiProductPickerPropertySelect__image--active':
                isActive,
              'ProductMultiProductPickerPropertySelect__image--not-available':
                notAvailable,
              'ProductMultiProductPickerPropertySelect__image--empty-image':
                !productImage,
            }
          )}
          onClick={() => {
            onProductChange(childProduct.id, childProduct.extraPropertiesMap);
          }}
        >
          <ProductImage
            product={product}
            productImage={productImage}
            size={'small'}
            lazyLoading={false}
          />
        </div>
      );
    });

    return (
      <div className="ProductMultiProductPickerPropertySelect__images">
        {images}
      </div>
    );
  };

  return (
    <div className="ProductMultiProductPickerPropertySelect">
      {renderSelectionImages()}
    </div>
  );
};

ProductMultiProductPickerPropertySelect.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  product: modelOf(Product).isRequired,
  productImages: MobxPropTypes.observableArray.isRequired,
  onProductChange: PropTypes.func.isRequired,
  activeProductId: PropTypes.string,
  hidden: PropTypes.bool,
};

export default inject('configStore')(
  observer(ProductMultiProductPickerPropertySelect)
);
