import { types } from 'mobx-state-tree';

const Review = types.model('Review', {
  id: types.identifier,
  reviews_rating: types.number,
  service_rating: types.number,
  customers_name: types.string,
  date_added: types.string,
  description: types.model({
    reviews_text: types.string,
    admin_reply: types.maybeNull(types.string)
  })
});

export default Review;
