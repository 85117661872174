import { types } from 'mobx-state-tree';

import Price from './Price';

const ProductPriceInfo = types
  .model('ProductPriceInfo', {
    price: Price,
    normal_price: Price,
    valid_until_html: types.string,
    valid_until: types.maybeNull(types.string),
    is_discount: types.boolean,
    discount_percentage: types.maybeNull(types.number)
  })
  .views((self) => {
    return {
      getSavings(withTax) {
        return self.getNormalPrice(withTax) - self.getPrice(withTax);
      },
      getPrice(withTax) {
        return self.price.getPrice(withTax);
      },
      getNormalPrice(withTax) {
        return self.normal_price.getPrice(withTax);
      }
    };
  });

export default ProductPriceInfo;
