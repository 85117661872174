import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import { parseHash } from '../../../util/url';
import { scrollToElement } from '../../../util/dom';
import UIStore from '../../../store/UIStore';

@observer
export class ScrollableAnchor extends Component {
  container = null;

  componentDidMount() {
    this.checkIfInHash();
  }

  checkIfInHash() {
    const { location, id, scrollIfInHash } = this.props;
    if (scrollIfInHash && location.hash) {
      const idInHash = parseHash(location.hash);
      if (idInHash === id) {
        scrollToElement(this.container);
      }
    }
  }

  render() {
    const { id, uiStore, offset, className } = this.props;

    return (
      <div
        ref={(ref) => (this.container = ref)}
        className={classNames('ScrollableAnchor', className)}
        id={id}
        style={{ top: -(uiStore.headerHeight + offset) }}
      />
    );
  }
}

ScrollableAnchor.propTypes = {
  uiStore: modelOf(UIStore).isRequired,
  location: RouterPropTypes.location.isRequired,
  id: PropTypes.string.isRequired,
  offset: PropTypes.number,
  className: PropTypes.string,
  scrollIfInHash: PropTypes.bool
};

ScrollableAnchor.defaultProps = {
  offset: 0,
  scrollIfInHash: true
};

export default inject('uiStore')(withRouter(ScrollableAnchor));
