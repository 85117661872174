import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';

import Page from '../../components/layout/Page';
import MainProductList from '../../components/product-list/MainProductList';
import HelperNavigation from '../../components/common/HelperNavigation';
import GenericLayout from '../../components/layout/GenericLayout';
import ProductSortOrderBy from '../../types/ProductSortOrderBy';
import SortOrder from '../../types/SortOrder';
import globalTranslations from '../../i18n/globalTranslations';
import PageTitle from '../../components/common/PageTitle';
import Paths from '../../types/Paths';
import SEOTitle from '../../components/head/SEOTitle';
import CanonicalLink from '../../components/head/CanonicalLink';
import ProductRecommendedPosition from '../../types/ProductRecommendedPosition';
import MainBanner from '../../components/ad/MainBanner';
import CategoryStore from '../../store/CategoryStore';
import ConfigStore from '../../store/ConfigStore';
import SectionStore from '../../store/SectionStore';
import RouteService from '../../services/RouteService';
import { modelOf } from '../../prop-types';
import ScrollReset from '../../components/common/ScrollReset';
import AdPages from '../../types/AdPages';
import CommonPage from '../../types/CommonPage';
import AdStore from '../../store/AdStore';
import ContentPopups from '../../components/ad/ContentPopups';
import useShouldShowBanner from '../../hooks/banner/useShouldShowBanner';
import AdZones from '../../types/AdZones';

const RecommendedProductsPage = ({
  adStore,
  categoryStore,
  configStore,
  sectionStore,
  intl,
  routeService,
}) => {
  useEffect(() => {
    if (useShouldShowBanner({ adStore, page: AdPages.RECOMMENDED_PRODUCTS })) {
      loadAds();
    }
  }, []);

  const loadAds = () => {
    adStore.loadAds(getSearchParams());
  };

  const getBreadcrumbsPath = () => {
    return routeService.transformBreadCrumbs([
      {
        text: intl.formatMessage(globalTranslations.weRecommendTitle),
        url: Paths.RecommendedProducts,
      },
    ]);
  };

  const getSearchParams = () => ({
    bannerPage: AdPages.RECOMMENDED_PRODUCTS,
  });

  const listId = 'recommended_products_list';
  const listName = 'Recommended Products List';

  const fixedParams = {
    orderBy: ProductSortOrderBy.RECOMMENDED_SORT_ORDER,
    order: SortOrder.ASC,
    recommendedPositions: [ProductRecommendedPosition.EVERYWHERE],
    includeChildProducts: 1,
    includeFilters: 0,
  };

  if (categoryStore.activeCategory) {
    fixedParams.categories = [categoryStore.activeCategory.id];
    fixedParams.recommendedPositions.push(ProductRecommendedPosition.CATEGORY);
  } else {
    fixedParams.recommendedPositions.push(ProductRecommendedPosition.FRONTPAGE);

    if (sectionStore.activeSection) {
      fixedParams.sections = [sectionStore.activeSection.id];
    }
  }

  const mainBanner = useShouldShowBanner({
    adStore,
    page: AdPages.RECOMMENDED_PRODUCTS,
    zone: AdZones.MAIN_BANNER,
  }) ? (
    <MainBanner searchParams={getSearchParams()} />
  ) : null;
  const mainBannerWithinContent =
    configStore.banner.mainBanner.withinContent &&
    !configStore.banner.mainBanner.fullWidth;

  return (
    <Page
      name={CommonPage.RECOMMENDED_PRODUCTS}
      className="RecommendedProductsPage"
    >
      <ScrollReset />
      <SEOTitle
        title={{
          ...globalTranslations.weRecommendTitle,
        }}
      />
      <CanonicalLink path={routeService.getPath(Paths.RecommendedProducts)} />
      {!mainBannerWithinContent && mainBanner}
      <Container className="RecommendedProductsPage__container">
        <GenericLayout
          bannerSearchParams={
            useShouldShowBanner({
              adStore,
              page: AdPages.RECOMMENDED_PRODUCTS,
              zone: AdZones.SIDEBAR_BANNER,
            })
              ? getSearchParams()
              : null
          }
          content={
            <>
              {mainBannerWithinContent && mainBanner}
              <HelperNavigation breadcrumbsPath={getBreadcrumbsPath()} />
              <PageTitle>
                <FormattedMessage {...globalTranslations.weRecommendTitle} />
              </PageTitle>
              <MainProductList
                fixedParams={fixedParams}
                listId={listId}
                name={listName}
                showSort={false}
              />
              {useShouldShowBanner({
                adStore,
                page: AdPages.RECOMMENDED_PRODUCTS,
                zone: AdZones.POPUP_CONTENT,
              }) && <ContentPopups />}
            </>
          }
        />
      </Container>
    </Page>
  );
};

RecommendedProductsPage.propTypes = {
  adStore: modelOf(AdStore).isRequired,
  categoryStore: modelOf(CategoryStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  intl: intlShape.isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
};

export default injectIntl(
  inject(
    'adStore',
    'categoryStore',
    'configStore',
    'sectionStore',
    'routeService'
  )(RecommendedProductsPage)
);
