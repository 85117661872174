import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import loadable from '@loadable/component';
import classNames from 'classnames';

import ProductImage from '../ProductImage';
import ViewBreakpoint from '../../../types/ViewBreakpoint';
import ProductClass from '../../../types/ProductClass';
import Product from '../../../models/Product';
import { modelOf } from '../../../prop-types';
import UIStore from '../../../store/UIStore';
import AccountStore from '../../../store/AccountStore';
import ThumbnailImageSlider from '../../slider/ThumbnailImageSlider';
import MainImageSlider from '../../slider/MainImageSlider';
import { initialImageOrigin } from '../../common/ImageLightbox/ImageLightboxProductImage';
import ConfigStore from '../../../store/ConfigStore';

const ImageLightbox = loadable(() =>
  import(
    /* webpackChunkName: "common" */ '../../common/ImageLightbox/ImageLightboxModal'
  )
);

const ProductImages = ({
  accountStore,
  configStore,
  uiStore,
  size,
  product,
  mainImageOverlay,
  activeProductId,
  activeElementIds,
}) => {
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

  let mainImageSliderRef = useRef(null);
  let thumbnailSliderRef = useRef(null);

  let lightboxInitialState = {};

  useCallback(
    product.images.forEach((_, index) => {
      lightboxInitialState = {
        ...lightboxInitialState,
        [index]: {
          focus: false,
          scale: 1,
          origin: initialImageOrigin,
        },
      };
    }),
    []
  );

  /* TODO
   *   Lots of unnecessary activity and mess of a sort. Different product type should
   *   be handled by ProductImagesWrapper (or similar) that outputs ProductImages.
   * */

  useEffect(() => {
    const isMulti = product.class === ProductClass.MULTI;

    if (thumbnailSliderRef && thumbnailSliderRef.current && isMulti) {
      let imageIndex = findMultiProductImageIndex();
      setActiveImageIndex(imageIndex);
      thumbnailSliderRef.current.slickGoTo(imageIndex);
    }
  }, [activeProductId]);

  useEffect(() => {
    const isCollection = product.class === ProductClass.COLLECTION;
    if (thumbnailSliderRef && thumbnailSliderRef.current && isCollection) {
      let imageIndex = findCollectionImageIndex();
      setActiveImageIndex(imageIndex);
      thumbnailSliderRef.current.slickGoTo(imageIndex);
    }
  }, [activeElementIds]);

  useEffect(() => {
    if (uiStore.isMobile && mainImageSliderRef?.current) {
      mainImageSliderRef.current.slickGoTo(activeImageIndex);
    }
  }, [activeImageIndex]);

  const shouldShowThumbnailsBelow = () => {
    return ![ViewBreakpoint.XL, ViewBreakpoint.XXL].includes(
      uiStore.activeBreakpoint
    );
  };

  const findMultiProductImageIndex = () => {
    const activeProduct = product.getActualProduct(activeProductId) || product;

    const index = activeProduct.images.findIndex(
      (image) => activeProductId === image.product_id
    );

    if (index >= 0) {
      return index;
    }

    return 0;
  };

  const findCollectionImageIndex = () => {
    if (activeElementIds && activeElementIds.length) {
      const index = product.images.findIndex(
        (image) => activeElementIds.indexOf(image.for_color_id) !== -1
      );

      if (index >= 0) {
        return index;
      }

      return 0;
    }

    return activeImageIndex ?? 0;
  };

  const getInitialIndex = () => {
    const isMulti = product.class === ProductClass.MULTI;
    if (isMulti && activeProductId) {
      return findMultiProductImageIndex();
    }

    const isCollection = product.class === ProductClass.COLLECTION;
    if (isCollection) {
      return findCollectionImageIndex();
    }

    return 0;
  };

  const getTotalSlidesCount = () => {
    const activeProduct = product.getActualProduct(activeProductId) || product;

    const imagesCount = activeProduct?.images?.length ?? 0;
    const rotatingImagesCount = activeProduct?.rotating_images?.length ?? 0;

    return imagesCount + rotatingImagesCount;
  };

  const getThumbnailSliderSlidesToShow = () => {
    const aspectRatio = Number(configStore.product.imageAspectRatio);

    switch (uiStore.activeBreakpoint) {
      case ViewBreakpoint.SM:
        return 5;
      case ViewBreakpoint.XS:
      case ViewBreakpoint.MD:
      case ViewBreakpoint.LG:
      case ViewBreakpoint.XXL:
        return aspectRatio > 115 ? 4 : 5;
      case ViewBreakpoint.XL:
        return aspectRatio > 115 ? 3 : 4;
      default:
        return 3;
    }
  };

  const getThumbnailSliderSettings = () => {
    // Fix for react-slider slider height bug, if there's equal or less amount of images that are set in slidesToShow
    // slidesToScroll amount seems to also affect somehow to the height calculation

    let commonSettings = {
      draggable: true,
      swipeToSlide: true,
      infinite: false,
      totalSlidesCount: getTotalSlidesCount(),
      initialSlide: getInitialIndex(),
    };

    if (uiStore.isMobile) {
      commonSettings = {
        ...commonSettings,
        asNavFor: mainImageSliderRef.current,
      };
    }

    if (shouldShowThumbnailsBelow()) {
      const slidesToShow = getThumbnailSliderSlidesToShow();

      return {
        ...commonSettings,
        slidesToShow,
        slidesToScroll: slidesToShow,
        arrows: getTotalSlidesCount() > slidesToShow,
      };
    } else {
      const slidesToShow = Math.min(
        getTotalSlidesCount(),
        getThumbnailSliderSlidesToShow()
      );

      return {
        ...commonSettings,
        slidesToShow,
        slidesToScroll: slidesToShow,
        vertical: true,
        verticalSwiping: true,
        arrows: getTotalSlidesCount() > slidesToShow,
      };
    }
  };

  const onMainImageIndexUpdate = (updatedIndex) => {
    // A bit "hacky" way to observe if the slider is animating
    // Slider might have a bug since on window resize, beforeChange hook runs
    // but never afterChange so we cannot "manually" set animating states
    // to stop user clicking thumbnails too soon.
    if (thumbnailSliderRef?.current?.innerSlider.state.animating) {
      return null;
    }

    setActiveImageIndex(updatedIndex);
  };

  const renderThumbnails = () => {
    const activeProduct = product.getActualProduct(activeProductId) || product;

    if (!activeProduct || getTotalSlidesCount() === 0) {
      return null;
    }

    const sliderSettings = getThumbnailSliderSettings();

    return (
      <ThumbnailImageSlider
        key={activeProductId}
        className="ProductImages__thumbnails"
        ref={thumbnailSliderRef}
        product={activeProduct}
        activeImageIndex={activeImageIndex ?? sliderSettings.initialSlide}
        onMainImageIndexUpdate={onMainImageIndexUpdate}
        {...sliderSettings}
      />
    );
  };

  const renderMainImage = () => {
    let mainImage;
    const activeProduct = product.getActualProduct(activeProductId) || product;

    if (activeProduct.images.length > 0) {
      if (uiStore.isMobile) {
        const sliderSettings = {
          asNavFor: thumbnailSliderRef.current,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          initialSlide: activeImageIndex ?? getInitialIndex(),
          afterChange: setActiveImageIndex,
        };

        mainImage = (
          <MainImageSlider
            ref={mainImageSliderRef}
            className="ProductImages__main-image-slider"
            product={activeProduct}
            size={size}
            {...sliderSettings}
          />
        );
      } else {
        const productImage =
          activeProduct.images[activeImageIndex] ??
          activeProduct.images[getInitialIndex()];

        mainImage = (
          <ProductImage
            product={
              product.getActualProduct(productImage.product_id) || product
            }
            productImage={productImage}
            size={size}
            lazyLoading={false}
            magnify
          />
        );
      }
    } else {
      mainImage = (
        <ProductImage product={product} size={size} lazyLoading={false} />
      );
    }

    return (
      <div className="ProductImages__main-image">
        {mainImageOverlay}
        <div
          className="ProductImages__main-image-lightbox"
          onClick={() => setLightboxIsOpen(!lightboxIsOpen)}
        >
          {mainImage}
        </div>
      </div>
    );
  };

  const renderLightbox = () => {
    const imageIndex = activeImageIndex ?? getInitialIndex();
    const cartMatrixEnabled =
      product.class === ProductClass.COLLECTION && accountStore.showCartMatrix;
    const activeProduct = product.getActualProduct(activeProductId) || product;

    if (
      imageIndex === null ||
      activeProduct.images.length === 0 ||
      !lightboxIsOpen ||
      cartMatrixEnabled
    ) {
      return null;
    }

    lightboxInitialState.selectedImage = imageIndex;

    return (
      <ImageLightbox
        initialState={lightboxInitialState}
        images={activeProduct.images}
        mainImageIndex={imageIndex}
        lightboxIsOpen={lightboxIsOpen}
        product={activeProduct}
        onClick={() => setLightboxIsOpen(!lightboxIsOpen)}
      />
    );
  };

  return (
    <div
      className={classNames('ProductImages', {
        'ProductImages--thumbnails-below': shouldShowThumbnailsBelow(),
        'ProductImages--thumbnails-vertical':
          getThumbnailSliderSettings().vertical,
      })}
    >
      {renderThumbnails()}
      {renderLightbox()}
      {renderMainImage()}
    </div>
  );
};

ProductImages.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  size: PropTypes.string.isRequired,
  product: modelOf(Product),
  mainImageOverlay: PropTypes.node,
  activeProductId: PropTypes.string,
  activeElementIds: PropTypes.arrayOf(PropTypes.number),
};

export default inject(
  'accountStore',
  'configStore',
  'uiStore'
)(observer(ProductImages));
