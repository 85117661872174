import { types } from 'mobx-state-tree';

const Purchase = types.model('Purchase', {
  user_id: types.number,
  name: types.string,
  order_count: types.number,
  currency: types.string,
  total_with_tax: types.string,
  total_without_tax: types.string
});

export default Purchase;
