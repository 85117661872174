import React, { Component, Fragment } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NavigationEntity from '../../../types/NavigationEntity';
import NavigationEntityItem from '../../navigation/NavigationEntityItem';
import CustomNavigationLinkLocationType from '../../../types/CustomNavigationLinkLocation';

@observer
class NavigationTree extends Component {
  getNavigationTree = (items) => {
    return items.map((item) => {
      const { id, children, active, customNavLinks } = item;
      return (
        <Fragment key={id}>
          {customNavLinks &&
            customNavLinks.map(
              (customNavLink, i) =>
                customNavLink.location ===
                  CustomNavigationLinkLocationType.ABOVE &&
                customNavLink.parent_sibling_id === item.id &&
                this.getCustomNavItem(customNavLink, i)
            )}
          <NavItem
            className={classNames('NavigationTree__item', {
              'NavigationTree__item--has-children':
                children && children.length > 0
            })}
          >
            <NavigationEntityItem
              entity={item}
              className="NavigationTree__link"
            />
            {active &&
              children && (
                <NavigationTree
                  items={children}
                  className="NavigationTree__subtree"
                />
              )}
          </NavItem>
          {customNavLinks &&
            customNavLinks.map(
              (customNavLink, i) =>
                customNavLink.location ===
                  CustomNavigationLinkLocationType.BELOW &&
                customNavLink.parent_sibling_id === item.id &&
                this.getCustomNavItem(customNavLink, i)
            )}
        </Fragment>
      );
    });
  };

  getCustomNavItem = (customNavLink, i) => {
    const linkClassName = 'NavigationTree__link';
    return (
      <Fragment key={i}>
        {this.getSubNavCustomLinks(
          customNavLink,
          CustomNavigationLinkLocationType.ABOVE,
          linkClassName
        )}
        <NavItem className="NavigationTree__item">
          <NavigationEntityItem
            entity={customNavLink}
            type={customNavLink.link_type}
            className="NavigationTree__link"
          />
        </NavItem>
        {this.getSubNavCustomLinks(
          customNavLink,
          CustomNavigationLinkLocationType.BELOW,
          linkClassName
        )}
      </Fragment>
    );
  };

  getSubNavCustomLinks = (customNavlink, location, linkClassName) =>
    customNavlink.customNavLinks &&
    customNavlink.customNavLinks.map((subCustomNavLink, subIndex) => {
      return (
        subCustomNavLink.location === location && (
          <Fragment key={subIndex}>
            {this.getSubNavCustomLinks(
              subCustomNavLink,
              CustomNavigationLinkLocationType.ABOVE,
              linkClassName
            )}
            <NavItem className="NavigationTree__item">
              <NavigationEntityItem
                entity={subCustomNavLink}
                type={subCustomNavLink.link_type}
                className={linkClassName}
              />
            </NavItem>
            {this.getSubNavCustomLinks(
              subCustomNavLink,
              CustomNavigationLinkLocationType.BELOW,
              linkClassName
            )}
          </Fragment>
        )
      );
    });

  render() {
    const { items, className } = this.props;
    const navItems = this.getNavigationTree(items);
    return (
      <Nav
        className={classNames('NavigationTree', 'flex-column', className)}
        vertical
      >
        {navItems}
      </Nav>
    );
  }
}

NavigationTree.propTypes = {
  items: PropTypes.arrayOf(NavigationEntity).isRequired,
  className: PropTypes.string
};

export default NavigationTree;
