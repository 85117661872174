import React from 'react';
import { FormattedMessage } from 'react-intl';

import GenericLegalBanner from '../GenericLegalBanner';
import globalTranslations from '../../../i18n/globalTranslations';

const RecaptchaLegalBanner = (_) => {
  return (
    <GenericLegalBanner className="RecaptchaLegalBanner">
      <p className="RecaptchaLegalBanner__text">
        <FormattedMessage
          id="legal.recaptchaBanner"
          defaultMessage="This site is protected by reCAPTCHA and the Google {privacyLink} and {termsLink} apply."
          values={{
            privacyLink: (
              <a
                className="RecaptchaLegalBanner__privacy-statement"
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener"
              >
                <FormattedMessage
                  {...globalTranslations.privacyPolicySentence}
                />
              </a>
            ),
            termsLink: (
              <a
                className="RecaptchaLegalBanner__terms-statement"
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noopener"
              >
                <FormattedMessage {...globalTranslations.termsSentence} />
              </a>
            ),
          }}
        />
      </p>
    </GenericLegalBanner>
  );
};

export default RecaptchaLegalBanner;
