import { types } from 'mobx-state-tree';
import SectionStatusType, {
  SectionStatusTypeType,
} from '../types/SectionStatusType';
import Filter from './Filter';
import Product from './Product';

const ProductSearchPaginator = types
  .model('ProductSearchPaginator', {
    available_filters: types.maybeNull(types.array(Filter)),
    current_page: types.number,
    data: types.optional(types.array(types.reference(Product)), []),
    from: types.maybeNull(types.number),
    last_page: types.number,
    path: types.string,
    per_page: types.number,
    section: types.maybeNull(
      types.model({
        status: SectionStatusTypeType,
        visible_to_crawlers: types.maybeNull(types.boolean),
      })
    ),
    to: types.maybeNull(types.number),
    total: types.number,
  })
  .views((self) => ({
    get isIndexableByRobots() {
      // By default we want pages to be indexable.
      // Applicable for ACTIVATE_SECTIONS = false stores.
      if (!self.section) {
        return true;
      }

      if (self.section.status !== SectionStatusType.TEST_MODE) {
        return true;
      }

      return (
        self.section.status === SectionStatusType.TEST_MODE &&
        self.section.visible_to_crawlers
      );
    },
  }));

export default ProductSearchPaginator;
