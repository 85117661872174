import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Alert,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import IntlWrapper from '../../../i18n/IntlWrapper';
import daCountries from '../../../i18n/static-messages/da-countries.json';
import deCountries from '../../../i18n/static-messages/de-countries.json';
import enCountries from '../../../i18n/static-messages/en-countries.json';
import esCountries from '../../../i18n/static-messages/es-countries.json';
import etCountries from '../../../i18n/static-messages/et-countries.json';
import fiCountries from '../../../i18n/static-messages/fi-countries.json';
import frCountries from '../../../i18n/static-messages/fr-countries.json';
import itCountries from '../../../i18n/static-messages/it-countries.json';
import nlCountries from '../../../i18n/static-messages/nl-countries.json';
import noCountries from '../../../i18n/static-messages/no-countries.json';
import ptCountries from '../../../i18n/static-messages/pt-countries.json';
import ruCountries from '../../../i18n/static-messages/ru-countries.json';
import svCountries from '../../../i18n/static-messages/sv-countries.json';
import { modelOf } from '../../../prop-types';
import LanguageStore from '../../../store/LanguageStore';
import LocalizationStore from '../../../store/LocalizationStore';
import TranslationStore from '../../../store/TranslationStore';
import { browserLanguage } from '../../../util/browser';
import { blockScroll, unblockScroll } from '../../../util/dom';
import {
  domainMatchesUrl,
  transformDomainToUrl,
  transformExternalLink,
} from '../../../util/url';

const SITE_CHOSEN_PARAMETER = '?siteChosen=1';

@observer
export class RedirectModal extends Component {
  constructor(props) {
    super(props);

    if (this.siteSuggestionEnabled()) {
      props.localizationStore.localizationModal.toggle();
      blockScroll();

      this.translations = {
        da: daCountries,
        de: deCountries,
        en: enCountries,
        es: esCountries,
        et: etCountries,
        fi: fiCountries,
        fr: frCountries,
        it: itCountries,
        nl: nlCountries,
        no: noCountries,
        pt: ptCountries,
        ru: ruCountries,
        sv: svCountries,
      };

      this.translation = this.getTranslation();
    }
  }

  componentDidUpdate() {
    if (this.siteSuggestionEnabled()) {
      unblockScroll();
    }
  }

  componentWillUnmount() {
    unblockScroll();
  }

  getTranslation = () => {
    const { localizationStore, intl } = this.props;
    const navigatorLanguage = browserLanguage();
    let language = intl.defaultLocale;

    if (
      navigatorLanguage?.language &&
      Object.keys(this.translations).includes(
        navigatorLanguage.language.toLowerCase()
      )
    ) {
      language = navigatorLanguage.language;
    }

    const translationsByCountry = this.translations[language];
    return translationsByCountry[localizationStore.countryCode];
  };

  siteSuggestionEnabled = () => {
    const { localizationStore } = this.props;

    // Enabled when localStorage has no key set and backend has sent data.
    return (
      !localizationStore.getLocalStorage() &&
      localizationStore.suggestionEnabled
    );
  };

  hasShipping = () => {
    const { sites } = this.props.localizationStore;
    let hasShipping = false;
    sites.forEach((site) => {
      if (site.shipsToCountry === true) {
        hasShipping = true;
      }
    });
    return hasShipping;
  };

  handleSuggestionIgnore = () => {
    const { localizationStore } = this.props;

    localizationStore.setLocalStorage();
    localizationStore.localizationModal.toggle();
    unblockScroll();
  };

  redirect = (url) => {
    this.handleSuggestionIgnore();
    window.location.assign(transformExternalLink(url + SITE_CHOSEN_PARAMETER));
  };

  getSites = () => {
    const { sites } = this.props.localizationStore;
    const currentSite = window && window.location.origin + '/';

    if (sites.length === 0) {
      return null;
    }

    return sites.map((site) => {
      const isCurrent = domainMatchesUrl(site.domain, currentSite);

      return (
        <Alert
          key={site.domain}
          className={classNames('RedirectModal__frame', {
            'RedirectModal__frame-suggested': site.recommendedForCountry,
          })}
        >
          <Row key={site.domain}>
            <Col key={site.domain} className="RedirectModal__site" xs={12}>
              <div className="RedirectModal__site-name">{site.domain}</div>
            </Col>
            <Col xs={12} sm={7}>
              {site.recommendedForCountry && (
                <div className="RedirectModal__site-suggested">
                  <FormattedMessage
                    id="localization.isSuggestedSite"
                    defaultMessage="We recommend using this online store."
                  />
                </div>
              )}
              {site.shipsToCountry && (
                <div className="RedirectModal__site-shippingPossible">
                  <FormattedMessage
                    id="localization.shippingPossible"
                    defaultMessage="Shipping is possible to your country."
                  />
                </div>
              )}
            </Col>
            <Col xs={12} sm={5} className="RedirectModal__button">
              <div className="RedirectModal__button-div">
                <Button
                  color={site.recommendedForCountry ? 'primary' : 'secondary'}
                  block
                  className="RedirectModal__button--accept"
                  role="link"
                  onClick={() =>
                    this.redirect(transformDomainToUrl(site.domain))
                  }
                >
                  <div className="RedirectModal__accept-text">
                    {isCurrent ? (
                      <FormattedMessage
                        id="localization.doNotRedirect"
                        defaultMessage="Stay on this online store"
                      />
                    ) : (
                      <FormattedMessage
                        id="localization.moveToSite"
                        defaultMessage="Go to the online store"
                      />
                    )}
                  </div>
                </Button>
              </div>
            </Col>
          </Row>
        </Alert>
      );
    });
  };

  render() {
    const { languageStore, localizationStore, translationStore } = this.props;

    if (!this.siteSuggestionEnabled()) {
      return null;
    }

    const modal = localizationStore.localizationModal;
    const sites = this.getSites();

    const navigatorLanguage = browserLanguage();
    const overrideLocale = navigatorLanguage && navigatorLanguage.language;

    return (
      <IntlWrapper
        languageStore={languageStore}
        translationStore={translationStore}
        loadableMessages={this.translations}
        overrideLocale={overrideLocale}
      >
        <Modal
          className="RedirectModal"
          size="md"
          isOpen={modal.isOpen}
          toggle={this.handleSuggestionIgnore}
          backdrop="static"
          keyboard={false}
        >
          <ModalHeader toggle={this.handleSuggestionIgnore}>
            {localizationStore.countryCode && (
              <Row className="RedirectModal__localization-header">
                <Col xs={12} className="RedirectModal__localization-phrase">
                  <div className="RedirectModal__localization-phrase-country">
                    <FormattedMessage
                      id="localization.yourLocation"
                      defaultMessage="We detected you are in {country}"
                      values={{
                        country: this.translation,
                      }}
                    />
                  </div>
                </Col>
              </Row>
            )}
            {!localizationStore.countryCode && (
              <FormattedMessage
                id="localization.noSuggestion"
                defaultMessage="We do not recognize where you are. Please check out our available online stores."
              />
            )}
          </ModalHeader>
          {!!sites && (
            <ModalBody>
              <div className="RedirectModal__body">
                {!this.hasShipping() && !!localizationStore.countryCode && (
                  <div className="RedirectModal__noShipping">
                    <FormattedMessage
                      id="localization.noShipping"
                      defaultMessage="Please check out our available online stores as unfortunately we do not deliver to the detected country."
                    />
                  </div>
                )}
                <div className="RedirectModal__sites">
                  <div className="RedirectModal__sites-row">{sites}</div>
                </div>
              </div>
            </ModalBody>
          )}
        </Modal>
      </IntlWrapper>
    );
  }
}

RedirectModal.propTypes = {
  LocalizationStore: modelOf(LocalizationStore),
  languageStore: modelOf(LanguageStore),
  translationStore: modelOf(TranslationStore),
};

export default injectIntl(
  inject(
    'localizationStore',
    'languageStore',
    'translationStore'
  )(RedirectModal)
);
