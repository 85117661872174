import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import CouponStore from '../../../store/CouponStore';
import Icon from '../../common/Icon';
import CouponText from '../../coupon/CouponText';
import NewsletterSubscriptionForm from '../../newsletter/NewsletterSubscriptionForm';
import NavigationDropdownDrawer from '../NavigationDropdownDrawer';

const NavigationNewsletterDropdown = ({ configStore, couponStore }) => {
  if (!configStore.newsletter.enabled) {
    return null;
  }

  return (
    <NavigationDropdownDrawer
      className="NavigationNewsletterDropdown"
      label={
        <span className="NavigationNewsletterDropdown__content">
          <Icon name="envelope" />
          <FormattedMessage
            {...globalTranslations.newsletterSubscriptionSentence}
          />{' '}
          {couponStore.newsletterCoupon && (
            <span>
              – <CouponText coupon={couponStore.newsletterCoupon} short />
            </span>
          )}
        </span>
      }
      right
    >
      <div className="NewsletterDropdown__inner">
        <p className="NewsletterDropdown__title">
          <FormattedMessage
            id="newsletter.popdownTitleWithoutDiscount"
            defaultMessage="Subscribe to our free newsletter!"
          />
        </p>
        {couponStore.newsletterCoupon && (
          <p className="NewsletterDropdown__coupon-incentive-box">
            <CouponText coupon={couponStore.newsletterCoupon} short />
          </p>
        )}
        <NewsletterSubscriptionForm />
      </div>
    </NavigationDropdownDrawer>
  );
};

NavigationNewsletterDropdown.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  couponStore: modelOf(CouponStore).isRequired,
};

export default inject(
  'configStore',
  'couponStore'
)(observer(NavigationNewsletterDropdown));
