import { types } from 'mobx-state-tree';

import Price from '../Price';
import AdditionalServiceOption from './AdditionalServiceOption';
import { AdditionalServiceTypeType } from '../../types/AdditionalServiceType';
import { findLast } from 'lodash';

const AdditionalService = types
  .model('AdditionalService', {
    description: types.string,
    enable_text_input: types.boolean,
    header_html: types.string,
    id: types.number,
    is_required: types.boolean,
    options: types.array(AdditionalServiceOption),
    price: types.maybeNull(Price),
    product_id: types.maybeNull(types.string),
    requires_area_validation: types.boolean,
    selected_by_default: types.boolean,
    selection_text: types.string,
    service_type: AdditionalServiceTypeType,
    text_input_info: types.string,
    text_max_length: types.maybeNull(types.number),
    text_required: types.boolean,
    valid_postal_code: types.boolean,
  })
  .views((self) => {
    const getDefaultOptions = () => {
      if (self.options.length > 0) {
        let options = {};

        // To get the first option selected by default on services.
        // Customer can mix option types.
        findLast(self.options, (option) => {
          options = {
            ...options,
            ...option.getSelection(),
          };
        });

        return options;
      }

      return {};
    };

    return {
      get isRequired() {
        return self.is_required;
      },
      get isSelected() {
        return (
          (!self.requires_area_validation &&
            (self.is_required || self.selected_by_default)) ||
          (self.requires_area_validation &&
            self.valid_postal_code &&
            (self.is_required || self.selected_by_default))
        );
      },
      createSelectedService() {
        return {
          areaRequired: self.requires_area_validation,
          id: self.id,
          isSelected: self.isSelected,
          options: getDefaultOptions(),
          productId: self.product_id,
          text: '',
          textMaxLength: self.text_max_length,
          textRequired: self.text_required,
          validPostalCode: self.valid_postal_code,
        };
      },
    };
  });

export default AdditionalService;
