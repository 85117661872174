import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { debounce, get, set } from 'lodash';
import { produce } from 'immer';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import ClickOutside from '../../common/ClickOutside';
import NavigationEntityItem from '../../navigation/NavigationEntityItem';
import NavigationEntity from '../../../types/NavigationEntity';
import safeCloneDeep from '../../../util/safeCloneDeep';
import CustomNavigationLinkLocationType from '../../../types/CustomNavigationLinkLocation';
import NavigationSidePanelMegaMenu from '../NavigationSidePanelMegaMenu';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import NavigationTwoLevelMegaMenu from '../NavigationTwoLevelMegaMenu';
import NavigationSecondLevelCategory from '../NavigationSecondLevelCategory';
import NavigationFirstLevelCategory from '../NavigationFirstLevelCategory';

@observer
class NavigationMegaMenu extends Component {
  constructor(props) {
    super(props);

    this.resizeListener = null;

    this.state = {
      entityTree: [],
      hideBottomLevelItems: false,
      marginOffset: null,
      availableWidth: null,
    };
    this.state.entityTree = safeCloneDeep(this.props.entities);
  }

  componentDidMount() {
    if (this.props.centered) {
      this.resizeListener = window.addEventListener(
        'resize',
        this.getMarginOffset
      );
      this.getMarginOffset();
    }
  }

  componentWillUnmount() {
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
    }
  }

  renderMegaMenu = () => {
    const { close, configStore, centered } = this.props;
    const { entityTree, hideBottomLevelItems } = this.state;

    const panelItems = this.getFirstLevelItems();
    const activeEntity = entityTree.find((item) => item.active);
    const panelContent = this.getSecondLevelCategories(activeEntity, close);

    const twoLevelMegaMenuEnabled =
      configStore.navigation.megaMenu.twoLayerMegaMenuEnabled;

    if (twoLevelMegaMenuEnabled) {
      return (
        <NavigationTwoLevelMegaMenu entityTree={entityTree} onClick={close} />
      );
    }

    return (
      <NavigationSidePanelMegaMenu
        leftPanelItems={panelItems}
        rightPanelRef={this.getChildRef}
        onOverflow={this.updateOverflowState}
        activeLeftPanelEntity={activeEntity}
        centered={centered}
        hideBottomLevelItems={hideBottomLevelItems}
        onCenteredMegamenu={this.getCenteredStyles}
        rightPanelContent={panelContent}
      />
    );
  };

  getFirstLevelItems = () => {
    const { configStore, close } = this.props;
    const { entityTree } = this.state;
    const twoLevelMegaMenuEnabled =
      configStore.navigation.megaMenu.twoLayerMegaMenuEnabled;

    return entityTree.map((item, index) => (
      <NavigationFirstLevelCategory
        key={item.id}
        entityIndex={index}
        entity={item}
        onClick={close}
        onMouseOver={
          !twoLevelMegaMenuEnabled
            ? this.activateLeftPanelItem.bind(this, index)
            : () => null
        }
        onSubEntityMouseOver={
          !twoLevelMegaMenuEnabled
            ? this.activateLeftPanelItem.bind(this, index + 1)
            : () => null
        }
      />
    ));
  };

  getSecondLevelCategories = (activeLeftPanelEntity, close) => {
    const { configStore } = this.props;

    const twoLevelMegaMenuEnabled =
      configStore.navigation.megaMenu.twoLayerMegaMenuEnabled;

    return activeLeftPanelEntity &&
      activeLeftPanelEntity.children &&
      activeLeftPanelEntity.children.length > 0
      ? activeLeftPanelEntity.children.map((item) => (
          <NavigationSecondLevelCategory
            key={item.id}
            entity={item}
            onClick={close}
          >
            {!twoLevelMegaMenuEnabled &&
              this.getThirdLevelCategories(item, close)}
          </NavigationSecondLevelCategory>
        ))
      : null;
  };

  getThirdLevelCategories = (item, close) =>
    item.children &&
    item.children.length > 0 && (
      <ul className="NavigationMegaMenu__third-level-items">
        {item.children.map((subItem, i) => (
          <Fragment key={i}>
            {subItem.customNavLinks &&
              subItem.customNavLinks.map(
                (customNavLink, index) =>
                  customNavLink.location ===
                    CustomNavigationLinkLocationType.ABOVE &&
                  this.getThirdLevelCustomNavigationItem(
                    customNavLink,
                    index,
                    close
                  )
              )}
            <li key={i}>
              <NavigationEntityItem entity={subItem} onClick={close} />
            </li>
            {subItem.customNavLinks &&
              subItem.customNavLinks.map(
                (customNavLink, index) =>
                  customNavLink.location ===
                    CustomNavigationLinkLocationType.BELOW &&
                  this.getThirdLevelCustomNavigationItem(
                    customNavLink,
                    index,
                    close
                  )
              )}
          </Fragment>
        ))}
      </ul>
    );

  getThirdLevelCustomNavigationItem = (customNavLink, index, close) => (
    <Fragment key={index}>
      {customNavLink.customNavLinks &&
        customNavLink.customNavLinks.map(
          (subCustomNavLink, subIndex) =>
            subCustomNavLink.location ===
              CustomNavigationLinkLocationType.ABOVE &&
            subCustomNavLink.parent_sibling_id === customNavLink.id && (
              <li key={subIndex}>
                <NavigationEntityItem
                  entity={subCustomNavLink}
                  onClick={close}
                  type={subCustomNavLink.link_type}
                />
              </li>
            )
        )}
      <li key={index}>
        <NavigationEntityItem
          entity={customNavLink}
          onClick={close}
          type={customNavLink.link_type}
        />
      </li>
      {customNavLink.customNavLinks &&
        customNavLink.customNavLinks.map(
          (subCustomNavLink, subIndex) =>
            subCustomNavLink.location ===
              CustomNavigationLinkLocationType.BELOW &&
            subCustomNavLink.parent_sibling_id === customNavLink.id && (
              <li key={subIndex}>
                <NavigationEntityItem
                  entity={subCustomNavLink}
                  onClick={close}
                  type={subCustomNavLink.link_type}
                />
              </li>
            )
        )}
    </Fragment>
  );

  getMarginOffset = () => {
    const { centered } = this.props;
    if (!centered) {
      return;
    }

    const domElements = document.getElementsByClassName(
      'NavigationSingleLevelMenuRow__nav'
    );
    const mainNav = domElements[0];

    if (mainNav) {
      const marginOffset = mainNav.getBoundingClientRect().x;
      // 37.5 is second level paddings.
      const availableWidth = window.innerWidth - marginOffset - 37.5;
      this.setState({
        marginOffset,
        availableWidth,
      });
    }
  };

  getCenteredStyles = () => {
    if (!this.props.centered) {
      return null;
    }

    const { availableWidth, marginOffset } = this.state;

    return {
      marginLeft: marginOffset,
      maxWidth: availableWidth,
    };
  };

  getChildRef = (measurementElement) =>
    (this.measurementElement = measurementElement);

  updateOverflowState = () => {
    if (this.measurementElement) {
      let isOverflowed =
        this.measurementElement.scrollHeight >
          this.measurementElement.clientHeight ||
        this.measurementElement.scrollWidth >
          this.measurementElement.clientWidth;

      // Only trigger a state change if there is actually a need to do so.
      // Otherwise we will get stuck in a loop with componentDidUpdate triggering over and over.
      // The measured element's dimensions should be guaranteed to not change after the re-render,
      // since the overflow state doesn't alter that element in any way.
      if (isOverflowed !== this.state.hideBottomLevelItems) {
        this.setState({ hideBottomLevelItems: isOverflowed });
      }
    }
  };

  activateLeftPanelItem = debounce((currentIndex) => {
    if (get(this.state, ['entityTree', currentIndex, 'active'])) {
      return;
    }

    const nextState = produce(this.state, (draftState) => {
      const siblings = get(draftState, 'entityTree');
      siblings.forEach((sibling, index) => {
        set(
          draftState,
          ['entityTree', index, 'active'],
          index === currentIndex
        );
      });
    });
    this.setState(nextState);
  }, 100);

  render() {
    const { configStore, close, onMouseOut, onMouseOver } = this.props;

    const twoLevelMegaMenuEnabled =
      configStore.navigation.megaMenu.twoLayerMegaMenuEnabled;

    return (
      <ClickOutside
        onClickOutside={close}
        excludedSelector=".CommonNavigation__bottom .DropdownNavLink"
      >
        <div
          className={classNames('NavigationMegaMenu', {
            'NavigationMegaMenu__two-level-megamenu': twoLevelMegaMenuEnabled,
            container: twoLevelMegaMenuEnabled,
          })}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        >
          {this.renderMegaMenu()}
        </div>
      </ClickOutside>
    );
  }
}

NavigationMegaMenu.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  entities: PropTypes.arrayOf(NavigationEntity).isRequired,
  close: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  centered: PropTypes.bool,
};

export default inject('configStore')(NavigationMegaMenu);
