import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * @param {Object} props
 * @param {Object.<string>} className Reserved for slick-slide, must be included in the props list, cannot be overridden.
 */
const SliderPreviousArrow = ({
  className,
  elementClassName,
  style,
  onClick,
  currentSlide,
  slideCount,
  infinite,
  parentRef,
  isThumbnailSlider,
}) => {
  const clickHandler = () => {
    if (onClick) {
      onClick();
      return;
    }

    if (!infinite && !isThumbnailSlider && currentSlide === 0) {
      parentRef.current.slickGoTo(slideCount - 1);
      return;
    }

    return null;
  };

  return (
    <div
      className={classNames('SliderPreviousArrow', className, elementClassName)}
      style={{ ...style }}
      onClick={clickHandler}
    />
  );
};

SliderPreviousArrow.propTypes = {
  // Start React-slick props
  className: PropTypes.string,
  slideCount: PropTypes.number,
  style: PropTypes.object,
  onClick: PropTypes.func,
  // End React-slick props
  elementClassName: PropTypes.string,
  isThumbnailSlider: PropTypes.bool,
  parentRef: PropTypes.object,
};

export default SliderPreviousArrow;
