import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SEOLink from '../SEOLink';

class CanonicalLink extends Component {
  render() {
    const { path } = this.props;
    return <SEOLink rel="canonical" path={path} addOpenGraphTag />;
  }
}

CanonicalLink.propTypes = {
  path: PropTypes.string.isRequired
};

export default CanonicalLink;
