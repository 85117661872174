import React from 'react';
import PropTypes from 'prop-types';

import NavigationEntity from '../../../types/NavigationEntity';
import NavigationEntityItemButton from '../NavigationEntityItemButton';
import NavigationEntityLink from '../NavigationEntityLink';
import NavigationEntityTextItem from '../NavigationEntityTextItem';

const NavigationEntityItem = ({ entity, bottomNavigation, icon, ...rest }) => {
  if (!entity) {
    return null;
  }

  const { accessible_text, path, page_id } = entity;

  const getAccessibilityProps = () => {
    let accessibilityText = accessible_text;
    if (bottomNavigation) {
      accessibilityText = `${accessibilityText}--footer-link`;
    }

    return accessibilityText.replace(/\s/g, '-').toLowerCase();
  };

  const getAccessibilityAttributes = () => {
    if (!accessible_text) {
      return;
    }

    let accessibilityProps = {};

    const accessibilityId = getAccessibilityProps();
    const accessibilityLabel = getAccessibilityProps();

    if (accessible_text) {
      accessibilityProps = {
        ...accessibilityProps,
        id: accessibilityId,
        'aria-labelledby': accessibilityLabel,
      };
    }

    return accessibilityProps;
  };

  const getEntityComponent = () => {
    if (page_id) {
      return NavigationEntityItemButton;
    }

    if (path) {
      return NavigationEntityLink;
    }

    return NavigationEntityTextItem;
  };

  const Component = getEntityComponent();

  return (
    <>
      <Component
        {...getAccessibilityAttributes()}
        entity={entity}
        icon={icon}
        {...rest}
      />
    </>
  );
};

NavigationEntityItem.propTypes = {
  entity: NavigationEntity.isRequired,
  bottomNavigation: PropTypes.bool,
  icon: PropTypes.node,
};

export default NavigationEntityItem;
