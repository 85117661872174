import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';

import ProductFeatureImage from '../ProductFeatureImage';
import RequestState from '../../../types/RequestState';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class ProductFeatureImages extends Component {
  constructor(props) {
    super(props);
    this.maybeLoadFeatureImages();
  }

  componentDidUpdate() {
    this.maybeLoadFeatureImages();
  }

  maybeLoadFeatureImages = () => {
    const { product } = this.props;

    if (product.propertyImagesState === RequestState.NONE) {
      product.loadPropertyImages();
    }
  };

  getFeatureImageContainers = (featureImages) => {
    return groupBy(featureImages, 'position');
  };

  productListLabelFilter = (label) =>
    label.show_on_product_listings && label.placement === 'product_image';
  productCardLabelFilter = (label) =>
    label.show_on_product_card && label.placement === 'product_image';

  render() {
    const { product, productListView, position } = this.props;

    if (!product.labels || !product.labels.length) {
      return null;
    }

    if (position === 'product_image') {
      const labelFilter = productListView
        ? this.productListLabelFilter
        : this.productCardLabelFilter;
      const propertyImages = product.labels.filter(labelFilter);

      const featureImages = this.getFeatureImageContainers(propertyImages);
      let top_element = null;

      if (featureImages.top_left || featureImages.top_right) {
        top_element = (
          <div className="ProductFeatureImages__wrapper top">
            <div className="ProductFeatureImages__container top-left">
              {featureImages.top_left
                ? featureImages.top_left.map((image, index) => (
                    <ProductFeatureImage key={index} {...image} />
                  ))
                : null}
            </div>
            <div className="ProductFeatureImages__container top-right">
              {featureImages.top_right
                ? featureImages.top_right.map((image, index) => (
                    <ProductFeatureImage key={index} {...image} />
                  ))
                : null}
            </div>
          </div>
        );
      }

      let bottom_element = null;

      if (featureImages.bottom_left || featureImages.bottom_right) {
        bottom_element = (
          <div className="ProductFeatureImages__wrapper bottom">
            <div className="ProductFeatureImages__container bottom-left">
              {featureImages.bottom_left
                ? featureImages.bottom_left.map((image, index) => (
                    <ProductFeatureImage key={index} {...image} />
                  ))
                : null}
            </div>
            <div className="ProductFeatureImages__container bottom-right">
              {featureImages.bottom_right
                ? featureImages.bottom_right.map((image, index) => (
                    <ProductFeatureImage key={index} {...image} />
                  ))
                : null}
            </div>
          </div>
        );
      }

      return top_element || bottom_element ? (
        <Fragment>
          {top_element}
          {bottom_element}
        </Fragment>
      ) : null;
    } else {
      const propertyImages = product.labels.filter(
        (image) =>
          image.placement === 'product_properties' && image.show_on_product_card
      );
      return (
        <div className="ProductFeatureImages__container properties">
          {propertyImages.map((image, index) => (
            <ProductFeatureImage key={index} {...image} />
          ))}
        </div>
      );
    }
  }
}

ProductFeatureImages.propTypes = {
  // Both Products and ChildProducts are allowed.
  product: PropTypes.object.isRequired,
  position: PropTypes.string,
  productListView: PropTypes.bool,
  configStore: modelOf(ConfigStore).isRequired
};

export default inject('configStore')(ProductFeatureImages);
