import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { isNil } from 'lodash';

import { modelOf } from '../../../prop-types';
import ProductPriceInfo from '../../../models/ProductPriceInfo';
import Price from '../Price';
import QuantityDiscount from '../../../models/QuantityDiscount';

@observer
export class ProductPriceRange extends Component {
  render() {
    const { range, withTax, showTaxIncludedInfo, isSecondaryPrice } =
      this.props;

    const showTaxExcludedInfo = !withTax;
    const showMax = range.min !== range.max;

    return (
      <div className="ProductPrice ProductPriceRange">
        <Price
          price={range.min.getPrice(withTax)}
          showTaxExcludedInfo={showTaxExcludedInfo && !showMax}
          showTaxIncludedInfo={showTaxIncludedInfo && !showMax}
          fontSize={isSecondaryPrice}
        />
        {showMax && isSecondaryPrice ? (
          <div className="PriceRangeRange__price-hyphen"> - </div>
        ) : (
          ' - '
        )}
        {showMax && (
          <Price
            price={range.max.getPrice(withTax)}
            showTaxExcludedInfo={showTaxExcludedInfo}
            showTaxIncludedInfo={showTaxIncludedInfo}
            fontSize={isSecondaryPrice}
          />
        )}
      </div>
    );
  }
}

ProductPriceRange.propTypes = {
  range: PropTypes.shape({
    min: PropTypes.oneOfType([
      modelOf(ProductPriceInfo),
      modelOf(QuantityDiscount),
    ]).isRequired,
    max: PropTypes.oneOfType([
      modelOf(ProductPriceInfo),
      modelOf(QuantityDiscount),
    ]).isRequired,
  }).isRequired,
  withTax: PropTypes.bool.isRequired,
  showTaxIncludedInfo: PropTypes.bool,
  isSecondaryPrice: PropTypes.bool,
};

export default inject((stores, props) => ({
  withTax: isNil(props.withTax)
    ? stores.accountStore.showPricesWithTax
    : props.withTax,
}))(ProductPriceRange);
