import React, { Component, createRef, Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import SectionStore from '../../../store/SectionStore';

@observer
export class ArinetWidget extends Component {
  constructor(props) {
    super(props);

    this.arinetRef = createRef();
  }

  componentDidMount() {
    !window.isSSR && this.loadScript();
  }

  loadScript() {
    const { sectionStore } = this.props;
    const script = `<script type="text/javascript" async id="aripartstream" src="${sectionStore.activeSection.integration_feed_url}?appKey=${sectionStore.activeSection.integration_api_key
      }"></script>`;

    window.postscribe(this.arinetRef.current, script);
  }

  componentWillUnmount() {
    this.arinetRef = null;
  }

  render() {
    if (window.isSSR) {
      return null;
    }

    return (
      <Fragment>
        <div id="arinetWidget" className="ArinetWidget" ref={this.arinetRef} />
      </Fragment>
    );
  }
}

ArinetWidget.propTypes = {
  sectionStore: modelOf(SectionStore).isRequired
};

export default inject('sectionStore')(ArinetWidget);
