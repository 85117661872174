import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import RouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';

import Paths from '../../../types/Paths';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import {
  getStorageAndParse,
  stringifyAndSetStorage,
} from '../../../util/storage';
import { storageKeys } from '../../../util/constants';
import ButtonLink from '../../common/ButtonLink';
import ProductStore from '../../../store/ProductStore';
import ProductClass from '../../../types/ProductClass';
import { calculateNearestProductQuantityWithQuantityStep } from '../../../util/number';
import RouteService from '../../../services/RouteService';
import Analytics from '../../../analytics/Analytics';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class ProductAddToWishList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productAddedBanner: false,
      alertVisible: false,
    };

    this.storage = [];
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.onAlertDismiss = this.onAlertDismiss.bind(this);
  }

  componentDidMount() {
    this.storage = getStorageAndParse(storageKeys.wishlist);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ alertVisible: false });
    }
  }

  addProductToWishlist = () => {
    const { product, quantity } = this.props;
    const newQuantity = calculateNearestProductQuantityWithQuantityStep(
      quantity,
      this.getQuantityStep(product)
    );
    this.findProduct(product, Math.ceil(newQuantity));
    stringifyAndSetStorage(storageKeys.wishlist, this.storage);
    this.showProductAddedToWishListBanner();
  };

  findProduct = (product, newQuantity) => {
    const productId = this.getProductIdByProductClass(product);
    const wishListProduct = this.storage.find((p) => p.id === productId);
    if (wishListProduct) {
      const productListProduct = this.initializeProductListProduct(
        product,
        newQuantity
      );
      this.updateProduct(wishListProduct, newQuantity, productListProduct);
      this.resetProductVariationChoices();
    } else {
      this.addProduct(product, newQuantity);
      this.resetProductVariationChoices();
    }
  };

  updateProduct = (productFound, newQuantity, productListProduct) => {
    const { analytics, accountStore, configStore, product } = this.props;
    const productIndex = this.storage.findIndex(
      (p) => p.id === productFound.id
    );

    if (newQuantity === 1) {
      productFound = {
        ...productFound,
        ...productListProduct,
        quantity: productFound.quantity + newQuantity,
      };
    } else {
      productFound = {
        ...productFound,
        ...productListProduct,
        quantity: newQuantity,
      };
    }
    this.storage.splice(productIndex, 1, productFound);
    const totalValue =
      product.getPrice(accountStore.showPricesWithTax) * newQuantity;

    configStore.analytics.ga4.enabled &&
      analytics.addToWishlist(
        [
          {
            product,
            quantity: newQuantity,
            activeProductId: productFound.id,
          },
        ],
        Number(totalValue.toFixed(2))
      );
  };

  addProduct = (wishListProduct, newQuantity) => {
    const { analytics, accountStore, configStore, product } = this.props;
    const productListProduct = this.initializeProductListProduct(
      wishListProduct,
      newQuantity
    );
    const totalValue =
      product.getPrice(accountStore.showPricesWithTax) * newQuantity;

    if (accountStore.account.is_logged) {
      this.ifLoggedInSaveToDatabase(productListProduct, newQuantity);
    } else {
      this.storage.push(productListProduct);
    }

    configStore.analytics.ga4.enabled &&
      analytics.addToWishlist(
        [
          {
            product,
            quantity: newQuantity,
            activeProductId: productListProduct.id,
          },
        ],
        Number(totalValue.toFixed(2))
      );
  };

  initializeProductListProduct = (product, quantity) => {
    const { productStore, routeService } = this.props;
    this.setVariationIfMultiProduct(productStore, product);
    const price = product.price_info ? product.price_info.price : 0.0;

    return {
      actual_code: product.productCode,
      availability_html: product.availability_html,
      available_for_purchase: product.can_be_ordered_out_of_stock,
      hierarchy: product.mainCategory?.hierarchy,
      class: product.class,
      collection: product.collection,
      id: this.getProductIdByProductClass(product),
      image:
        product.image || this.getSmallProductImageOrDefault(product.images),
      link: routeService.getCanonicalProductPath(product),
      manufacturer: product.manufacturer,
      main_category_id: product.main_category_id,
      main_section_id: product.main_section_id,
      multi: product.multi,
      multiproduct_id: product.multiproduct_id,
      multiproduct_title: product.multiproduct_title,
      name: product.name,
      package_size: product.package_size,
      price_info: product.price_info,
      product_id: product.id,
      quantity: Number(quantity),
      recurring_order_type: product.recurring_order_type,
      stock_unit: product.stock_unit,
      total_price: price,
      variations: this.resetVariationsIfProductIsNormal(productStore, product),
    };
  };

  getProductIdByProductClass = (product) => {
    return product.class === ProductClass.COLLECTION ||
      product.class === ProductClass.MULTI
      ? this.props.activeProductId
      : product.id;
  };

  setVariationIfMultiProduct = (productStore, product) => {
    if (product.class === ProductClass.MULTI_CHILD) {
      productStore.setProductVariationColumn(`${product.model}`);
    }
  };

  getSmallProductImageOrDefault = (images) => {
    return images[0] ? images[0].sizes.small : null;
  };

  resetVariationsIfProductIsNormal = (productStore, product) => {
    if (product.class === ProductClass.SINGLE) {
      this.resetProductVariationChoices();
      return this.getProductVariations(productStore);
    } else {
      return this.getProductVariations(productStore);
    }
  };

  resetProductVariationChoices = () => {
    const { productStore } = this.props;
    productStore.setProductVariationColumn(null);
    productStore.setProductVariationRow(null);
  };

  getProductVariations = (productStore) => {
    const variations = [];
    const productVariationColumn = productStore.getProductVariationColumn();
    productVariationColumn && variations.push(productVariationColumn);
    const productVariationRow = productStore.getProductVariationRow();
    productVariationRow && variations.push(productVariationRow);
    return variations;
  };

  ifLoggedInSaveToDatabase = (productListProduct, newQuantity) => {
    const { productStore } = this.props;
    const newProduct = {
      extendedId: productListProduct.id,
      quantity: newQuantity,
    };
    productStore
      .saveProduct(newProduct)
      .then((response) => {
        if (response.status === 200) {
          this.showProductAddedToWishListBanner();
        }
      })
      .catch((err) => console.error(err));
  };

  getQuantityStep = (product) => {
    return product && product.sellInPackage ? product.package_size : 1;
  };

  showProductAddedToWishListBanner = () => {
    this.setState({
      productAddedBanner: true,
      alertVisible: true,
    });
  };

  onAlertDismiss = () => {
    this.setState({ alertVisible: false });
  };

  handleButtonClick = (e) => {
    if (this.props.disabled) {
      e.preventDefault();
      return null;
    }

    this.addProductToWishlist();
  };

  addToWishListButton = () => {
    return (
      <ButtonLink
        color="primary"
        className={classNames('ProductAddToWishList__button', {
          'ProductAddToWishList__button--disabled': this.props.disabled,
        })}
        onClick={this.handleButtonClick.bind(this)}
      >
        <FormattedMessage
          id="product.AddWishListTitle"
          defaultMessage="Add to wishlist"
        />
      </ButtonLink>
    );
  };

  productAddedBanner = () => {
    if (this.state.productAddedBanner) {
      return (
        <Alert
          className="ProductAddToWishList__product-added-banner"
          color="secondary"
          isOpen={this.state.alertVisible}
        >
          <FormattedMessage
            id="product.ProductAddedToWishlist"
            defaultMessage={`Product added to wishlist. You can view it {link}.`}
            values={{
              link: (
                <ButtonLink onClick={this.navigateToWishList}>
                  <FormattedMessage
                    id="product.ProductAddedToWishlist_here"
                    defaultMessage="here"
                  />
                </ButtonLink>
              ),
            }}
          />
        </Alert>
      );
    }
  };

  navigateToWishList = () => {
    this.props.history.push(Paths.WishList);
  };

  render() {
    return (
      <div className="ProductAddToWishList">
        {this.addToWishListButton()}
        {this.productAddedBanner()}
      </div>
    );
  }
}

ProductAddToWishList.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  history: RouterPropTypes.history.isRequired,
  product: PropTypes.object.isRequired,
  activeProductId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default withRouter(
  inject(
    'accountStore',
    'configStore',
    'productStore',
    'routeService',
    'analytics'
  )(ProductAddToWishList)
);
