import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';

import { modelOf } from '../../../prop-types';
import CategoryStore from '../../../store/CategoryStore';
import CategoryList from '../CategoryList';
import SectionStore from '../../../store/SectionStore';
import ConfigStore from '../../../store/ConfigStore';
import TopLevelNavigationList from '../TopLevelNavigationList';

@observer
export class SidebarNavigation extends Component {
  getActiveCategories = () => {
    const { categoryStore } = this.props;
    return categoryStore.activeCategory
      ? categoryStore.activeCategory.hierarchy
      : [];
  };

  getCategories = () => {
    const { categoryStore, sectionStore } = this.props;

    if (sectionStore.activeSection) {
      return sectionStore.activeSection.mainCategories;
    } else if (categoryStore.activeCategory) {
      return get(categoryStore.activeCategory, 'hierarchy[0].children', []);
    }
    return categoryStore.categories;
  };

  render() {
    const { configStore } = this.props;

    return (
      <div className="SidebarNavigation">
        {configStore.navigation.sidebar.enabled ? (
          <TopLevelNavigationList
            activeCategories={this.getActiveCategories()}
          />
        ) : (
          <CategoryList
            activeCategories={this.getActiveCategories()}
            categories={this.getCategories()}
          />
        )}
      </div>
    );
  }
}

SidebarNavigation.propTypes = {
  categoryStore: modelOf(CategoryStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
};

export default inject(
  'categoryStore',
  'sectionStore',
  'configStore'
)(SidebarNavigation);
