import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';

import { modelOf } from '../../../prop-types';
import InfoPage from '../../../models/InfoPage';
import NavigationTree from '../../navigation/NavigationTree';
import RouteService from '../../../services/RouteService';

@observer
export class InfoPageList extends Component {
  convertInfoPagesToNavigationEntities = (infoPages, activeInfoPages) => {
    const { routeService } = this.props;
    let orderedPages = infoPages;

    if (infoPages.length > 1) {
      orderedPages = orderBy(infoPages, ['sort_order']);
    }

    return orderedPages
      .filter((page) => page.show_in_listing)
      .map((page) => ({
        id: page.id,
        sort_order: page.sort_order,
        label: page.name,
        active: activeInfoPages.indexOf(page) !== -1,
        path: routeService.getPath(page.path),
        children: this.convertInfoPagesToNavigationEntities(
          page.children,
          activeInfoPages
        ),
      }));
  };

  render() {
    const { infoPages, activeInfoPages } = this.props;

    const navigationEntities = this.convertInfoPagesToNavigationEntities(
      infoPages,
      activeInfoPages
    );

    return (
      <NavigationTree items={navigationEntities} className="InfoPageList" />
    );
  }
}

InfoPageList.propTypes = {
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  infoPages: PropTypes.arrayOf(modelOf(InfoPage)).isRequired,
  activeInfoPages: PropTypes.arrayOf(modelOf(InfoPage)).isRequired,
};

export default inject('routeService')(InfoPageList);
