import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes, observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import Category from '../../../models/Category';
import NavigationCategoryButton from '../NavigationCategoryButton';
import ConfigStore from '../../../store/ConfigStore';
import NavigationCategoryImage from '../NavigationCategoryImage';

const NavigationCategorySlider = ({ configStore, categories }) => {
  const getCategoryItems = () => {
    return categories.map((category) => {
      return configStore.navigation.common.useCategorySliderImages ? (
        <NavigationCategoryImage key={category.id} category={category} />
      ) : (
        <NavigationCategoryButton key={category.id} category={category} />
      );
    });
  };

  return (
    <div className="NavigationCategorySlider">
      <div className="NavigationCategorySlider__header">
        <FormattedMessage
          id="navigation.browseCategories"
          defaultMessage="Browse categories"
        />
      </div>
      <div className="NavigationCategorySlider__items">
        {getCategoryItems()}
      </div>
    </div>
  );
};

NavigationCategorySlider.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  categories: PropTypes.oneOfType([
    MobxPropTypes.observableArrayOf(modelOf(Category)).isRequired,
    PropTypes.arrayOf(modelOf(Category)).isRequired,
  ]),
};

export default inject('configStore')(observer(NavigationCategorySlider));
