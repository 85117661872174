import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import InfoPageModal from '../../info-page/InfoPageModal';
import ButtonLink from '../../common/ButtonLink';

@observer
export class RecurringInfoLink extends Component {
  state = {
    show: false
  };

  toggle = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    const { configStore, children } = this.props;
    const hasInfoPage = configStore.recurringOrder.infoPage.length >= 1;

    return (
      <Fragment>
        {children}
        {hasInfoPage && (
          <div>
            <ButtonLink className="RecurringInfoLink" onClick={this.toggle}>
              <FormattedMessage
                id="recurringOrder.readLinkSentence"
                defaultMessage="Read more about subscriptions."
              />
            </ButtonLink>
          </div>
        )}
        {this.state.show &&
          hasInfoPage && (
            <InfoPageModal
              isOpen={this.state.show}
              toggle={this.toggle}
              pageId={configStore.recurringOrder.infoPage}
            />
          )}
      </Fragment>
    );
  }
}

RecurringInfoLink.propTypes = {
  configStore: modelOf(ConfigStore).isRequired
};

export default inject('configStore')(RecurringInfoLink);
