import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import globalTranslations from '../../../i18n/globalTranslations';

class NoProductsFound extends Component {
  render() {
    return (
      <div className="NoProductsFound">
        <FormattedMessage {...globalTranslations.noProductsFoundSentence} />
      </div>
    );
  }
}

export default NoProductsFound;
