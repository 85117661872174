import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';

const GenericMeta = ({ name, property, content }) => {
  const inputProps = {};

  if (name) {
    inputProps.name = name;
  }

  if (property) {
    inputProps.property = property;
  }

  if (content) {
    inputProps.content = content;
  }

  return (
    <Helmet>
      <meta {...inputProps} />
    </Helmet>
  );
};

GenericMeta.propTypes = {
  content: PropTypes.string,
  name: PropTypes.string,
  property: PropTypes.string,
};

export default observer(GenericMeta);
