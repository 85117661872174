import React from 'react';
import { Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../../prop-types';
import Price from '../../../product/Price';
import Icon from '../../../common/Icon';
import MarkerBadge from '../../../common/MarkerBadge';
import globalTranslations from '../../../../i18n/globalTranslations';
import RequestState from '../../../../types/RequestState';
import AccountStore from '../../../../store/AccountStore';
import ProposalStore from '../../../../store/ProposalStore';

const NavigationProposalButton = ({
  accountStore,
  proposalStore,
  withTax,
  className,
  wrapperClassName,
}) => {
  const getTotalPrice = () => {
    const { cart } = proposalStore;

    if (!cart) {
      return null;
    }
    const priceTotal = cart?.total && cart.total.getPrice(withTax);

    return priceTotal || null;
  };

  const getHidePriceOnMobile = () => {
    const price = getTotalPrice();
    return price > 1000 || price === null;
  };

  const renderPrice = () => {
    const price = getTotalPrice();
    return price !== null ? <Price price={price} /> : null;
  };

  const renderProposalText = () => {
    const productCount =
      (proposalStore.cart && proposalStore.cart.number_of_products) || 0;
    let text;

    switch (proposalStore.state) {
      case RequestState.LOADED:
        if (productCount === 0) {
          text = (
            <FormattedMessage {...globalTranslations.proposalEmptySentence} />
          );
        } else {
          text = (
            <span>
              <FormattedMessage
                {...globalTranslations.itemCount}
                values={{
                  count: productCount,
                }}
              />{' '}
              {renderPrice()}
            </span>
          );
        }
        break;
      case RequestState.LOADING:
      default:
        text = <FormattedMessage {...globalTranslations.loading} />;
        break;
    }

    return text;
  };

  const ifProductCatalog = accountStore.isViewOnly;
  if (ifProductCatalog) {
    return null;
  }

  const proposalCount =
    proposalStore.cart && proposalStore.cart.number_of_products;

  const getContent = () => {
    return (
      <Button
        block
        color="primary"
        className="NavigationProposal__proposal-button"
        onClick={() => proposalStore.proposalModal.toggle(true)}
        disabled={proposalStore.state !== RequestState.LOADED}
      >
        <Icon name="list" />
        <span
          className={classNames('NavigationProposal__proposal-text', {
            'NavigationProposal__proposal-text--hide-on-mobile':
              getHidePriceOnMobile(),
          })}
        >
          <span className="NavigationProposal__proposal-text-desktop">
            {renderProposalText()}
          </span>
          <span className="NavigationProposal__proposal-text-mobile">
            {renderPrice()}
          </span>
        </span>
      </Button>
    );
  };

  return (
    <div className={classNames('NavigationProposal', 'NavigationProposalButton', className)}>
      <div
        className={classNames(
          'NavigationProposal__cart-button-wrapper',
          wrapperClassName
        )}
      >
        {getContent()}
        {proposalCount > 0 && (
          <MarkerBadge className="d-xs-inline d-sm-none">
            {proposalCount}
          </MarkerBadge>
        )}
      </div>
    </div>
  );
};

NavigationProposalButton.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  proposalStore: modelOf(ProposalStore).isRequired,
  withTax: PropTypes.bool.isRequired,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default inject((stores) => ({
  accountStore: stores.accountStore,
  proposalStore: stores.proposalStore,
  withTax: stores.accountStore.showPricesWithTax,
}))(observer(NavigationProposalButton));
