import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import { modelOf } from '../../../prop-types';

import { paramsToQueryIdentifier } from '../../../util/query';
import ProductStore from '../../../store/ProductStore';
import ContentForState from '../../loader/ContentForState';
import RequestState from '../../../types/RequestState';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class MissingProductLoader extends Component {
  state = {
    missingIds: null,
  };

  componentDidMount() {
    this.setMissingIds();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.ids, this.props.ids)) {
      this.setMissingIds();
    }
    if (!isEqual(prevState.missingIds, this.state.missingIds)) {
      this.loadProducts();
    }
  }

  loadProducts = () => {
    const { productStore, onLoadSuccess } = this.props;
    const { missingIds } = this.state;
    if (missingIds && missingIds.length > 0) {
      productStore
        .loadProducts(this.getQueryParams(), true)
        .then(
          () => onLoadSuccess && onLoadSuccess(this.getCurrentSearchProducts())
        );
    }
  };

  setMissingIds = () => {
    const { productStore, ids } = this.props;
    const missingIds = ids.filter((productId) => {
      return !productStore.products.has(productId);
    });
    this.setState({ missingIds });
  };

  getQueryParams = () => {
    return {
      ids: this.state.missingIds,
      includeFilters: 0,
      excludeContent: 1,
      optimizeResponse: 1,
    };
  };

  getQueryIdentifier = () => {
    return paramsToQueryIdentifier(this.getQueryParams());
  };

  getCurrentSearchProducts = () => {
    const { productStore, ids } = this.props;

    // Skip products that were not found.
    return ids
      .map((productId) => productStore.products.get(productId))
      .filter((product) => !!product);
  };

  getCurrentSearchState() {
    const { productStore } = this.props;
    const { missingIds } = this.state;

    if (!missingIds) {
      return RequestState.LOADING;
    }
    if (missingIds.length === 0) {
      return RequestState.LOADED;
    }
    return productStore.productQueryStates.get(this.getQueryIdentifier());
  }

  render() {
    const { productStore, ids, children, ...rest } = this.props;

    if (!productStore) {
      return null;
    }

    return (
      <ContentForState
        state={this.getCurrentSearchState()}
        error={productStore.lastError}
        forLoaded={() => children(this.getCurrentSearchProducts())}
        {...rest}
      />
    );
  }
}

MissingProductLoader.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  ids: PropTypes.array.isRequired,
  onLoadSuccess: PropTypes.func,
};

export default inject('productStore', 'configStore')(MissingProductLoader);
