import {
  flow,
  getEnv,
  getRoot,
  resolveIdentifier,
  types
} from 'mobx-state-tree';
import { Settings } from 'luxon';
import { autorun } from 'mobx';

import Language from '../models/Language';
import StatefulStore from '../models/StatefulStore';
import { parseLanguageCode } from '../util/url';

const LanguageStore = StatefulStore.named('LanguageStore')
  .props({
    languages: types.optional(types.array(Language), []),
    locale: types.maybeNull(types.string),
    hasBeenSetup: types.optional(types.boolean, false)
  })
  .actions((self) => {
    const setupLocale = (newLocale) => {
      if (!newLocale) {
        return;
      }
      self.locale = newLocale;
      self.hasBeenSetup = true;
    };

    const getRedirectedUrl = flow(function* getRedirectedUrl(newLocale) {
      const actualLocale =
        self.activeLocale !== self.locale ? self.locale : self.activeLocale;

      if (actualLocale !== newLocale) {
        const { sectionStore } = getRoot(self);

        let currentSectionSlug = '';
        let sectionSlug = '';
        if (sectionStore.activeSection) {
          currentSectionSlug = sectionStore.activeSection.slug;

          const otherLanguageSectionData = yield sectionStore.getSectionDataForLanguage(
            sectionStore.activeSection.id,
            newLocale
          );
          sectionSlug = otherLanguageSectionData.slug;
        }

        const currentLanguageCode = parseLanguageCode(window.location.pathname);
        if (currentLanguageCode) {
          // We're on a page that has a language in the URL
          return (
            window.location.pathname.replace(
              `/${currentLanguageCode}/` +
                (currentSectionSlug ? `${currentSectionSlug}/` : ''),
              `/${newLocale}/` + (sectionSlug ? `${sectionSlug}/` : '')
            ) +
            window.location.search +
            window.location.hash
          );
        } else if (self.languages.length > 1) {
          // We are on the front page
          return `/${newLocale}/`;
        } else {
          return window.location;
        }
      }

      return window.location;
    });

    const setup = (languages) => {
      self.languages = languages;
      self.setLoading(false);
    };

    const loadLanguages = flow(function* loadLanguages() {
      self.setLoading(true);

      try {
        self.languages = yield getEnv(self).apiWrapper.request(`languages`);
      } catch (e) {
        self.setError(e);
        throw e;
      }

      self.setLoading(false);
    });

    return {
      setupLocale,
      getRedirectedUrl,
      setup,
      loadLanguages
    };
  })
  .views((self) => {
    return {
      afterAttach: () => {
        function setLuxonDefaultLocale() {
          // Set locale for luxon
          Settings.defaultLocale = self.activeLocale;
        }
        // Update Luxon locale every time it changes
        autorun(() => {
          setLuxonDefaultLocale();
        });
        setLuxonDefaultLocale();
      },
      get activeLocale() {
        if (!self.hasBeenSetup) {
          return null;
        }

        let activeLocale = self.locale;
        if (
          !self.locale ||
          self.languages
            .map((language) => language.code)
            .indexOf(self.locale) === -1
        ) {
          activeLocale = getRoot(self).configStore.defaultLocale;
        }

        return activeLocale;
      },
      get activeLanguage() {
        return self.activeLocale && self.findLanguage(self.activeLocale);
      },
      findLanguage(locale) {
        return resolveIdentifier(Language, getRoot(self.languages), locale);
      }
    };
  });

export default LanguageStore;
