import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Icon from '../../common/Icon';
import { createForm } from '../../../models/Form';
import { createFormField } from '../../../models/FormField';
import FieldError from '../../form/FieldError';
import QuantityFieldGroup from '../../common/QuantityFieldGroup';
import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import { productCountValid, quantityValid } from '../../../util/formValidators';
import globalTranslations from '../../../i18n/globalTranslations';
import CartStore from '../../../store/CartStore';
import CategoryStore from '../../../store/CategoryStore';
import RequestState from '../../../types/RequestState';
import AddToCartServices from '../AddToCartServices';
import UIStore from '../../../store/UIStore';
import ConfigStore from '../../../store/ConfigStore';
import ProductTypeClass from '../../../types/ProductTypeClass';

@observer
class AddToCart extends Component {
  constructor(props) {
    super(props);

    this.form = null;
    this.formName = 'AddToCartForm';
    this.buttonElement = null;

    this.state = {
      buttonMounted: false,
      ifValidPostalCode: true,
    };

    this.initializeForm();
  }

  componentDidMount() {
    const { onUpdateProductQuantity } = this.props;

    if (onUpdateProductQuantity) {
      onUpdateProductQuantity(this.getQuantity());
    }
  }

  componentDidUpdate(prevProps) {
    const { categoryStore, maxQuantity } = this.props;

    if (prevProps.maxQuantity !== maxQuantity) {
      this.initializeForm();
    }

    if (categoryStore.activeCategory) {
      this.maybeLoadAdditionalServices();
    }
  }

  initializeForm = () => {
    const { quantity, maxQuantity, quantityStep, withQuantityPicker } =
      this.props;

    this.form = null;
    this.formFields = {};

    this.formFields.quantity = createFormField(
      {
        value: withQuantityPicker ? String(quantityStep) : quantity,
      },
      [
        quantityValid(maxQuantity, quantityStep),
        productCountValid(quantityStep),
      ]
    );

    this.form = createForm(this.formFields);
  };

  maybeLoadAdditionalServices = () => {
    const { product, categoryStore } = this.props;

    if (
      categoryStore.activeCategory &&
      product.additionalServicesState === RequestState.NONE
    ) {
      product
        .loadAdditionalServices(categoryStore.activeCategory.id)
        .catch((e) => {
          if (e.response && e.response.status !== 404) {
            console.error(e);
          }
        });
    }
  };

  validText = (service) => {
    if (service.textRequired === true && service.text.length === 0) {
      return false;
    }

    return !(
      service.textMaxLength && service.text.length > service.textMaxLength
    );
  };

  servicesAreValid = () => {
    const { product } = this.props;
    let valid = true;

    const requiredServiceIds = product.additionalServices
      .filter((service) => service.is_required)
      .map((service) => service.id);

    const selectedServices = product.selectedServices.filter(
      (service) => service.isSelected
    );

    // Make sure required services are selected.
    const selectedServiceIds = selectedServices.map((service) => service.id);
    valid = requiredServiceIds.every((id) => selectedServiceIds.includes(id));

    selectedServices.forEach((service) => {
      if (!this.validText(service)) {
        valid = false;
      }
    });

    return valid;
  };

  handleAddToCartClick = (e) => {
    const { disabled, product, configStore, uiStore, loading } = this.props;

    const ifServices =
      product.additionalServices && product.additionalServices.length;
    const ifAdditionalServicesModalEnabled =
      configStore.productPage.enableAdditionalServicesModal;
    const servicesAreLoading =
      product.additionalServicesState === RequestState.LOADING;

    const toggleServicesModal = uiStore.additionalServicesModal.toggle;

    if ((ifAdditionalServicesModalEnabled && servicesAreLoading) || loading) {
      return;
    }

    if (!disabled) {
      e.preventDefault();
      ifAdditionalServicesModalEnabled && ifServices
        ? toggleServicesModal()
        : this.addProduct();
    }
  };

  handleAddToProposalClick = (e) => {
    const { disabled, loading } = this.props;

    if (loading) {
      return;
    }

    if (!disabled) {
      e.preventDefault();
      this.addProduct();
    }
  };

  handleModalAddToCartClick = (e) => {
    const { uiStore, disabled } = this.props;
    const toggleServicesModal = uiStore.additionalServicesModal.toggle;

    if (!disabled) {
      e.preventDefault();
      toggleServicesModal();
      this.addProduct();
    }
  };

  addProduct = () => {
    const { isProposal } = this.props;

    if (isProposal) {
      this.addProposalToCart();
    }

    if (!isProposal) {
      this.addProductToCart();
    }
  };

  addProposalToCart = () => {
    const { onAddToProposalClick } = this.props;

    if (onAddToProposalClick && this.getQuantity()) {
      onAddToProposalClick(this.getQuantity());
    }
  };

  addProductToCart = () => {
    const { onAddToCartClick } = this.props;

    this.form.validate();
    if (
      this.form.valid &&
      this.servicesAreValid() &&
      onAddToCartClick &&
      this.getQuantity()
    ) {
      onAddToCartClick(this.getQuantity(), this.getServices());
    }
  };

  getQuantity = () => Number(this.form.fields.get('quantity').value);

  getOptionProductIds = (options) => {
    const optionsArray = [];

    if (options.checkbox) {
      options.checkbox.forEach((value) => {
        optionsArray.push(value);
      });
    }

    if (options.radio) {
      optionsArray.push(options.radio);
    }

    if (options.dropdown) {
      optionsArray.push(options.dropdown);
    }

    return optionsArray;
  };

  getProductIds = (service) => {
    const ids = [];

    if (service.productId) {
      ids.push({
        service_id: service.id,
        id: service.productId,
        addText: true,
      });
    }

    if (service.options) {
      let optionIds = this.getOptionProductIds(service.options);

      optionIds.forEach((id) => {
        ids.push({
          id: id,
          addText: false,
        });
      });
    }

    return ids;
  };

  getServices = () => {
    const { product } = this.props;
    const selectedServices = product.selectedServices;
    let servicesToCart = [];

    const services = selectedServices.filter((service) => service.isSelected);

    services.forEach((service) => {
      const productIds = this.getProductIds(service);

      productIds.map((id) =>
        servicesToCart.push({
          service_id: id.service_id,
          products_id: id.id,
          text: id.addText ? service.text : '',
        })
      );
    });

    return servicesToCart;
  };

  setButtonElement = (ref) => {
    this.buttonElement = ref;
    this.setState({ buttonMounted: true });
  };

  onUpdateProductQuantity = (quantity) => {
    const { cartStore, onUpdateProductQuantity } = this.props;
    if (onUpdateProductQuantity) {
      cartStore.updateProductQuantity(quantity);
      onUpdateProductQuantity(quantity);
    }
  };

  ifRenderServices = () => {
    const { disabled, product, withServices } = this.props;
    const isGiftCard = product.product_type === ProductTypeClass.GIFT_VOUCHER;
    return (
      !disabled &&
      !isGiftCard &&
      withServices &&
      product.selectedServices?.length > 0
    );
  };

  updatePostalCodeValidState = (ifValidPostalCode) => {
    ifValidPostalCode &&
      this.setState({
        ifValidPostalCode,
      });
  };

  ifOrderDisabled = () => {
    const { product, quantity, minOrderQuantity } = this.props;

    return (
      (product.additionalServices?.length > 0 &&
        (this.form.valid === false || this.servicesAreValid() === false)) ||
      quantity < minOrderQuantity
    );
  };

  renderButton = () => {
    const { configStore, product, isProposal, loading, disabled, notAllowed } =
      this.props;

    const hasServices = product.additionalServices?.length > 0;
    const addToCartButtonClassNames = classNames(
      {
        // We add disabled as a className instead of a prop to handle disabled button onClick -events
        disabled,
        'AddToCart__button--not-allowed': notAllowed,
      },
      'AddToCart__button'
    );
    const ifAdditionalServicesModalEnabled =
      configStore.productPage.enableAdditionalServicesModal;

    const { ifValidPostalCode } = this.state;

    const servicesAreLoading =
      product.additionalServicesState === RequestState.LOADING;

    const ifRequiredPostalCode =
      product.ifServiceRequiresPostalCodeValidation() &&
      product.ifServiceIsRequiredAndRequiresPostalCodeValidation();

    return (
      <Button
        color="primary"
        className={addToCartButtonClassNames}
        disabled={
          loading ||
          (hasServices &&
            (servicesAreLoading ||
              (!ifAdditionalServicesModalEnabled &&
                ifRequiredPostalCode &&
                !ifValidPostalCode))) ||
          this.ifCartButtonDisabled()
        }
        onClick={
          isProposal ? this.handleAddToProposalClick : this.handleAddToCartClick
        }
        innerRef={this.setButtonElement}
      >
        <Icon
          name={
            loading
              ? 'circle-o-notch'
              : isProposal
              ? 'envelope-open-o'
              : 'shopping-cart'
          }
          spin={loading}
        />
        {isProposal ? (
          <FormattedMessage {...globalTranslations.addToProposalButton} />
        ) : (
          <FormattedMessage {...globalTranslations.addToCartButton} />
        )}
      </Button>
    );
  };

  ifCartButtonDisabled = () => {
    const { product, configStore, quantity, minOrderQuantity } = this.props;
    const ifAdditionalServicesModalEnabled =
      configStore.productPage.enableAdditionalServicesModal;

    if (
      product.additionalServices?.length > 0 &&
      ifAdditionalServicesModalEnabled
    ) {
      return this.form.valid === false || quantity < minOrderQuantity;
    }

    return this.ifOrderDisabled();
  };

  render() {
    const {
      configStore,
      activeProductId,
      disabled,
      maxQuantity,
      product,
      quantityStep,
      scrollToServices,
      productPageContent,
      loading,
      tooltipMessage,
      unit,
      withQuantityPicker,
      ifValidPostalCode,
    } = this.props;

    const quantityField = this.form.fields.get('quantity');

    const ifAdditionalServicesModalEnabled =
      configStore.productPage.enableAdditionalServicesModal;

    const servicesAreLoading =
      product.additionalServicesState === RequestState.LOADING;

    const ifRequiredPostalCode =
      product.ifServiceRequiresPostalCodeValidation() &&
      product.ifServiceIsRequiredAndRequiresPostalCodeValidation();

    return (
      <div className="AddToCart">
        <div className="form-inline">
          {this.ifRenderServices() && (
            <AddToCartServices
              product={product}
              onAddToCart={this.handleModalAddToCartClick}
              onPostalCodeValidation={this.updatePostalCodeValidState}
              activeProductId={activeProductId}
              modalDisabled={
                loading ||
                servicesAreLoading ||
                (ifAdditionalServicesModalEnabled &&
                  ifRequiredPostalCode &&
                  !ifValidPostalCode) ||
                this.ifOrderDisabled()
              }
              scrollToServices={scrollToServices}
            />
          )}
          {withQuantityPicker && (
            <QuantityFieldGroup
              key={product.id}
              className="AddToCart__quantity-group"
              disabled={disabled || servicesAreLoading}
              field={quantityField}
              maxQuantity={maxQuantity}
              quantityStep={quantityStep}
              unit={unit}
              onUpdateProductQuantity={this.onUpdateProductQuantity}
              productPageContent={productPageContent}
            />
          )}
          {configStore.siteConfig.isWebStore && this.renderButton()}
          <FieldError field={quantityField} />
        </div>
        {tooltipMessage && this.state.buttonMounted && (
          <UncontrolledTooltip placement="top" target={this.buttonElement}>
            {tooltipMessage}
          </UncontrolledTooltip>
        )}
      </div>
    );
  }
}

AddToCart.propTypes = {
  cartStore: modelOf(CartStore).isRequired,
  categoryStore: modelOf(CategoryStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  product: modelOf(Product).isRequired,
  maxQuantity: PropTypes.number.isRequired,
  quantityStep: PropTypes.number.isRequired,
  onAddToCartClick: PropTypes.func.isRequired,
  onAddToProposalClick: PropTypes.func.isRequired,
  activeProductId: PropTypes.string,
  unit: PropTypes.string,
  minOrderQuantity: PropTypes.number,
  quantity: PropTypes.number,
  disabled: PropTypes.bool,
  isProposal: PropTypes.bool,
  loading: PropTypes.bool,
  notAllowed: PropTypes.bool,
  productPageContent: PropTypes.bool,
  withQuantityPicker: PropTypes.bool,
  withServices: PropTypes.bool,
  withTax: PropTypes.bool,
  onUpdateProductQuantity: PropTypes.func,
  scrollToServices: PropTypes.func,
  tooltipMessage: PropTypes.node,
};

AddToCart.defaultProps = {
  isProposal: false,
  withQuantityPicker: true,
};

export default inject(
  'cartStore',
  'categoryStore',
  'configStore',
  'uiStore'
)(AddToCart);
