import React, { Component } from 'react';
import PropTypes from 'prop-types';

class GoogleMapEmbed extends Component {
  render() {
    const { location, locale } = this.props;
    const src = `//maps.google.fi/maps?&q=${location}&z=14&hl=${locale}&output=embed`;

    return (
      <div>
        <iframe
          title="Google map"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          width="100%"
          height="320"
          src={src}
        />
      </div>
    );
  }
}

GoogleMapEmbed.propTypes = {
  location: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
};

export default GoogleMapEmbed;
