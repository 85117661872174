import React, { Component } from 'react';
import { Alert, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

class UnexpectedError extends Component {
  render() {
    return (
      <Alert className="UnexpectedError__alert" color="danger">
        <FormattedMessage
          id="state.unexpectedError"
          defaultMessage="An unexpected error happened. Please try to reload the page. If the error persists try again later."
        />
        <Button
          color="primary"
          className="UnexpectedError__button"
          onClick={() => window.location.reload()}
        >
          <FormattedMessage id="site.reloadPage" defaultMessage="Reload page" />
        </Button>
      </Alert>
    );
  }
}

export default UnexpectedError;
