import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { countBy } from 'lodash';
import { PropTypes as MobxPropTypes } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import Review from '../../../models/Review';
import globalTranslations from '../../../i18n/globalTranslations';
import Stars from '../Stars';

class ReviewAggregation extends Component {
  cumulativeAverage = (key) => (previous, nextReview, index) =>
    (nextReview[key] + index * previous) / (index + 1);

  getAggregateRatings = () => {
    const { reviews } = this.props;

    const ratingBuckets = countBy(reviews, (review) =>
      Math.round((review.service_rating + review.reviews_rating) / 2)
    );

    const aggregates = [];
    const totalCount = reviews.length;
    for (let i = 5; i >= 1; --i) {
      aggregates.push({
        stars: i,
        count: ratingBuckets[i] || 0,
        percentage: ((ratingBuckets[i] || 0) / totalCount) * 100,
      });
    }

    return aggregates;
  };

  render() {
    const { reviews } = this.props;

    const aggregateRatings = this.getAggregateRatings();
    const productAverageRating = reviews.reduce(
      this.cumulativeAverage('reviews_rating'),
      0
    );
    const serviceAverageRating = reviews.reduce(
      this.cumulativeAverage('service_rating'),
      0
    );

    return (
      <Row className="ReviewAggregation">
        <Col xs="6" className="ReviewAggregation__rating-buckets">
          {aggregateRatings.map(({ stars, count, percentage }) => (
            <div className="ReviewAggregation__rating-bucket" key={stars}>
              <div className="ReviewAggregation__rating-bucket-top-row">
                <Stars amount={stars} />
                <span className="ReviewAggregation__rating-bucket-count">
                  ({count})
                </span>
              </div>
              <div className="ReviewAggregation__rating-bucket-percentage-bar">
                <div
                  className="ReviewAggregation__rating-bucket-percentage-bar-fill"
                  style={{
                    width: percentage + '%',
                  }}
                />
              </div>
            </div>
          ))}
        </Col>
        <Col xs="6">
          <div className="ReviewAggregation__average-rating">
            <div>
              <FormattedMessage
                {...globalTranslations.reviewProductRatingTitle}
              />
            </div>
            <div>{productAverageRating.toFixed(1)} / 5.0</div>
            <div>
              <Stars amount={productAverageRating} />
            </div>
          </div>
          <div className="ReviewAggregation__average-rating">
            <div>
              <FormattedMessage
                {...globalTranslations.reviewServiceRatingTitle}
              />
            </div>
            <div>{serviceAverageRating.toFixed(1)} / 5.0</div>
            <div>
              <Stars amount={serviceAverageRating} />
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

ReviewAggregation.propTypes = {
  reviews: MobxPropTypes.observableArrayOf(modelOf(Review)),
};

export default ReviewAggregation;
