import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import AdStore from '../../../store/AdStore';
import RequestState from '../../../types/RequestState';

const AdLoader = ({ adStore, searchParams, placeHolder, adZone, children }) => {
  const getAdsState = () => {
    return adStore.getAdQueryStateBySearchParameters(searchParams);
  };
  if (
    !adStore ||
    (getAdsState() !== RequestState.LOADED &&
      getAdsState() !== RequestState.ERROR)
  ) {
    return placeHolder || null;
  }

  const ads = adStore.getAdsByAdZone(searchParams, adZone);

  return children(ads);
};

AdLoader.propTypes = {
  searchParams: PropTypes.object.isRequired,
  adStore: modelOf(AdStore).isRequired,
  adZone: PropTypes.string.isRequired,
  placeHolder: PropTypes.node,
};

export default inject('adStore')(observer(AdLoader));
