import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';
import { Container } from 'reactstrap';

import BannerSlider from '../../components/ad/BannerSlider/BannerSliderSlides';
import SidebarBanner from '../../components/ad/SidebarBanner';
import HelperNavigation from '../../components/common/HelperNavigation';
import PageTitle from '../../components/common/PageTitle';
import ScrollReset from '../../components/common/ScrollReset';
import WysiwygOrBlockContent from '../../components/common/WysiwygOrBlockContent';
import CanonicalLink from '../../components/head/CanonicalLink';
import SEODescription from '../../components/head/SEODescription';
import SEOTitle from '../../components/head/SEOTitle';
import InfoPageNavigation from '../../components/info-page/InfoPageNavigation';
import Page from '../../components/layout/Page';
import SidebarLayout from '../../components/layout/SidebarLayout';
import ContentForState from '../../components/loader/ContentForState';
import PageSkeleton from '../../components/skeleton/PageSkeleton';
import SidebarSkeleton from '../../components/skeleton/SidebarSkeleton';
import { modelOf } from '../../prop-types';
import RouteService from '../../services/RouteService';
import AdStore from '../../store/AdStore';
import ConfigStore from '../../store/ConfigStore';
import InfoPageStore from '../../store/InfoPageStore';
import UIStore from '../../store/UIStore';
import AdZones from '../../types/AdZones';
import CommonPage from '../../types/CommonPage';
import RequestState from '../../types/RequestState';
import { pathFragmentMatchesLocation } from '../../util/url';

@observer
class InfoPage extends Component {
  constructor(props) {
    super(props);

    const { infoPageStore } = props;

    this.yotpoTimeout = null;
    // We load all pages without content first for hierarchy
    if (infoPageStore.state === RequestState.NONE) {
      infoPageStore.loadPages().then(() => this.maybeLoadPage());
    } else {
      this.maybeLoadPage();
    }

    this.maybeLoadSidebarBanners();
  }

  componentDidMount() {
    const { configStore } = this.props;

    if (configStore.integrations.yotpo.enabled && window && window.yotpo) {
      this.yotpoTimeout = setTimeout(() => window.yotpo.refreshWidgets(), 800);
    }
  }

  componentDidUpdate(prevProps) {
    const { match, history, routeService } = this.props;

    if (prevProps.match.params.id !== match.params.id) {
      this.maybeLoadPage();
    }

    // If we have a link, where the slug is '-', update it.
    // Used when linking based only on page ID.
    if (match.params.slug === '-') {
      const infoPage = this.getActiveInfoPage();
      if (infoPage) {
        history.replace(
          `${routeService.getPath(infoPage.path)}${history.location.search}${
            history.location.hash
          }`
        );
      }
    }
  }

  componentWillUnmount() {
    this.yotpoTimeout && clearTimeout(this.yotpoTimeout);
  }

  maybeLoadPage = () => {
    const { infoPageStore, configStore, match } = this.props;
    const pageState = this.getActiveInfoPageState();

    if (!pageState || pageState !== RequestState.LOADED) {
      infoPageStore
        .loadPage(match.params.id)
        .then(() => {
          if (
            configStore.integrations.yotpo.enabled &&
            window &&
            window.yotpo
          ) {
            this.yotpoTimeout = setTimeout(
              () => window.yotpo.refreshWidgets(),
              800
            );
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  maybeLoadSidebarBanners = () => {
    const { adStore } = this.props;

    if (
      adStore.getAdQueryStateBySearchParameters(
        this.getSidebarBannerParameters()
      ) !== RequestState.LOADED
    ) {
      adStore
        .loadAds(this.getSidebarBannerParameters())
        .catch((e) => console.error(e));
    }
  };

  getActiveInfoPageState = () => {
    const { match, infoPageStore } = this.props;
    const activePageId = match.params.id;
    return infoPageStore.pageStates.get(activePageId);
  };

  getActiveInfoPage = () => {
    const { match, infoPageStore } = this.props;
    const activePageId = match.params.id;
    return infoPageStore.pages.get(activePageId);
  };

  getSidebarBannerParameters = () => ({
    bannerZones: [AdZones.SIDEBAR_BANNER],
  });

  getBanners = () => {
    const { adStore } = this.props;
    const ads = adStore.getGlobalAdsByAdZone(AdZones.INFO_PAGE_BANNER);

    if (!ads || ads.length === 0) {
      return null;
    }

    return (
      <BannerSlider
        adZone={AdZones.INFO_PAGE_BANNER}
        searchParams={{
          bannerZone: AdZones.INFO_PAGE_BANNER,
        }}
        preLoadedAds={ads}
        aspectRatio={2.85}
      />
    );
  };

  render() {
    const { match, location, infoPageStore, uiStore, routeService } =
      this.props;

    const infoPage = this.getActiveInfoPage();

    if (infoPage) {
      if (!pathFragmentMatchesLocation(infoPage.path, location.pathname)) {
        const url = `${routeService.getPath(infoPage.path)}${location.search}${
          location.hash
        }`;
        return <Redirect to={url} />;
      }
    }

    return (
      <Page name={CommonPage.INFO} className="InfoPage">
        <ScrollReset key={match.params.id} />
        {infoPage && (
          <CanonicalLink path={routeService.getPath(infoPage.path)} />
        )}
        <Container className="InfoPage__container">
          <SidebarLayout
            sidebar={
              !uiStore.isMobile &&
              infoPage && (
                <>
                  <InfoPageNavigation
                    currentInfoPage={infoPage}
                    key={infoPage ? infoPage.id : -1}
                  />
                  <SidebarBanner
                    searchParams={this.getSidebarBannerParameters()}
                  />
                </>
              )
            }
            sidebarPlaceHolder={!uiStore.isMobile ? <SidebarSkeleton /> : null}
            content={
              <ContentForState
                state={this.getActiveInfoPageState()}
                error={infoPageStore.lastSinglePageError}
                forPlaceHolder={<PageSkeleton />}
                forLoaded={() => {
                  return (
                    <>
                      <HelperNavigation
                        breadcrumbsPath={routeService.transformBreadCrumbs(
                          infoPage.breadcrumbs
                        )}
                      />
                      <PageTitle>{infoPage.name}</PageTitle>
                      {infoPage.content !== null && (
                        <>
                          <SEOTitle
                            title={infoPage.SEO_title || infoPage.name}
                            noSuffix={!!infoPage.SEO_title}
                          />
                          {infoPage.SEO_description && (
                            <SEODescription
                              content={infoPage.SEO_description}
                            />
                          )}
                          <div className="InfoPage__contents">
                            <WysiwygOrBlockContent content={infoPage.content} />
                          </div>
                          {this.getBanners()}
                        </>
                      )}
                    </>
                  );
                }}
              />
            }
          />
        </Container>
      </Page>
    );
  }
}

InfoPage.propTypes = {
  adStore: modelOf(AdStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  infoPageStore: modelOf(InfoPageStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  match: RouterPropTypes.match.isRequired,
  location: RouterPropTypes.location.isRequired,
};

export default withRouter(
  inject(
    'adStore',
    'configStore',
    'infoPageStore',
    'uiStore',
    'routeService'
  )(InfoPage)
);
