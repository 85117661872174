import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from 'reactstrap';

const getModelYearString = (start, end) => {
  if (end === '9999') {
    if (start === '0') {
      return '';
    } else {
      return `${start} -`;
    }
  }

  return `${start} - ${end}`;
};

const getModelString = (model, type) => {
  let modelString = model;

  if (type) {
    if (type.manufacturerModel) {
      modelString += ` ${type.manufacturerModel}`;
    }

    if (type.modelCode) {
      modelString += ` ${type.modelCode}`;
    }
  }

  return modelString;
};

const CompatibleVehicleModels = (props) => {
  const { product } = props;

  if (!product.startaxInfo.compatibleModels) {
    return null;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="vehicle.brand" defaultMessage="Brand" />
          </th>
          <th>
            <FormattedMessage id="vehicle.model" defaultMessage="Model" />
          </th>
          <th>
            <FormattedMessage id="vehicle.motor" defaultMessage="Motor" />
          </th>
          <th>
            <FormattedMessage id="vehicle.years" defaultMessage="Years" />
          </th>
        </tr>
      </thead>
      {product.startaxInfo.compatibleModels.map((model, index) => (
        <tr key={index}>
          <td>{model.car.brand}</td>
          <td>{getModelString(model.car.model, model.type)}</td>
          <td>{model.car.motor}</td>
          <td>{getModelYearString(model.year.start, model.year.end)}</td>
        </tr>
      ))}
    </Table>
  );
};

export default CompatibleVehicleModels;
