import { types } from 'mobx-state-tree';
import { ProductType } from '../../types/ProductTypeClass';

const CartProduct = types
  .model('CartProduct', {
    actual_code: types.maybeNull(types.string),
    bundle_id: types.maybeNull(types.string),
    bundle_main_product: types.maybeNull(types.boolean),
    canonical_path: types.optional(types.array(types.string), []),
    category_name: types.maybeNull(types.string),
    final_unit_price: types.maybeNull(types.number),
    id: types.string,
    image: types.maybeNull(types.string),
    main_category_id: types.maybeNull(types.number),
    main_section_id: types.maybeNull(types.number),
    manufacturers_name: types.maybeNull(types.string),
    name: types.string,
    package_size: types.maybeNull(types.number),
    product_id: types.string,
    quantity: types.number,
    short_description: types.maybeNull(types.string),
    starting_unit_price: types.maybeNull(types.number),
    stock_unit: types.maybeNull(types.string),
    total_price: types.maybeNull(types.number),
    type: ProductType,
    variations: types.maybeNull(types.array(types.string)),
  })
  .preProcessSnapshot(({ quantity, ...rest }) => ({
    quantity: Number(quantity),
    ...rest,
  }))
  .views((self) => ({
    getQuantityStep: () => {
      return self.package_size ? self.package_size : 1;
    },
    get analytics() {
      const getAnalyticsPrice = () => {
        return self.final_unit_price * self.getQuantityStep();
      };

      return {
        quantity: self.quantity || 1,
        price: self.final_unit_price ? getAnalyticsPrice() : 0.0,
      };
    },
    get canonicalPath() {
      return self.canonical_path;
    },
    get finalUnitPrice() {
      return self.final_unit_price;
    },
    get manufacturer() {
      return self.manufacturers_name;
    },
    get normalPrice() {
      return self.starting_unit_price;
    },
    get productCode() {
      return self.actual_code;
    },
    get productId() {
      return self.product_id;
    },
    get savings() {
      return Number(
        (self.starting_unit_price - self.final_unit_price).toFixed(2)
      );
    },
    get totalPrice() {
      return self.total_price;
    },
    get totalQuantity() {
      return self.package_size && self.package_size !== 0
        ? self.quantity * self.package_size
        : self.quantity;
    },
  }));

export default CartProduct;
