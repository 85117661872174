import React, { Component } from 'react';
import Spinner from '../Spinner';

class BlockSpinner extends Component {
  render() {
    return (
      <div className="BlockSpinner">
        <Spinner />
      </div>
    );
  }
}
export default BlockSpinner;
