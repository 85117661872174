import React, { Component } from 'react';
import propTypes from 'prop-types';

class Countdown extends Component {
  state = {
    secondsRemaining: 0
  };

  componentDidMount() {
    this.updateInterval = setInterval(this.updateRemainingTime, 1000);
    this.updateRemainingTime();
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  updateInterval = null;

  clearInterval = () => {
    if (this.updateInterval !== null) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }
  };

  updateRemainingTime = () => {
    const { expiresOn, onExpiry } = this.props;

    const secondsRemaining =
      (expiresOn.valueOf() - new Date().valueOf()) / 1000;

    if (secondsRemaining < 0) {
      if (onExpiry) {
        onExpiry();
      }
      this.clearInterval();
    }

    this.setState({
      secondsRemaining
    });
  };

  padPart = (part, toLength = 2, padChar = '0') => {
    part = part.toString();
    if (part.length >= toLength) {
      return part;
    }

    return padChar.repeat(toLength - part.length) + part;
  };

  format = () => {
    const { secondsRemaining } = this.state;

    const parts = {
      h: this.padPart(Math.floor(secondsRemaining / 3600)),
      m: this.padPart(Math.floor((secondsRemaining / 60) % 60)),
      s: this.padPart(Math.floor(secondsRemaining % 60))
    };

    return `${parts.h}:${parts.m}:${parts.s}`;
  };

  render() {
    return <span className="Countdown">{this.format()}</span>;
  }
}

Countdown.propTypes = {
  expiresOn: propTypes.instanceOf(Date).isRequired,
  onExpiry: propTypes.func
};

export default Countdown;
