import React, { Component } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import ContentForState from '../../loader/ContentForState';
import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import RequestState from '../../../types/RequestState';
import UnexpectedError from '../../loader/UnexpectedError';
import Icon from '../../common/Icon';
import globalTranslations from '../../../i18n/globalTranslations';

@observer
export class ProductRotatingImageModal extends Component {
  bodyEl = null;
  isTouch = false;
  updateInterval = false;
  state = {
    dragging: false,
    imageIndex: 0,
    dragStartIndex: 0
  };

  constructor(props) {
    super(props);
    this.maybeLoadRotatingImagesList();
  }

  componentDidUpdate(prevProps) {
    this.maybeLoadRotatingImagesList();
    if (this.props.isOpen !== prevProps.isOpen) {
      this.props.isOpen ? this.attachListeners() : this.detachListeners();
    }
  }

  clearRotateInterval = () => {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
      this.updateInterval = false;
    }
  };

  attachListeners = () => {
    if (this.bodyEl) {
      this.bodyEl.addEventListener('touchmove', this.handleMouseMove, true);
      this.bodyEl.addEventListener('mousemove', this.handleMouseMove, true);
    }
    window.addEventListener('touchend', this.handleMouseUp, true);
    window.addEventListener('mouseup', this.handleMouseUp, true);

    this.clearRotateInterval();
    this.updateInterval = setInterval(this.maybeGetNextFrame, 350);
  };

  detachListeners = () => {
    if (this.bodyEl) {
      this.bodyEl.removeEventListener('touchmove', this.handleMouseMove, true);
      this.bodyEl.removeEventListener('mousemove', this.handleMouseMove, true);
    }

    window.removeEventListener('touchend', this.handleMouseUp, true);
    window.removeEventListener('mouseup', this.handleMouseUp, true);
    this.clearRotateInterval();
  };

  maybeLoadRotatingImagesList = () => {
    const { product, filePath, isOpen } = this.props;

    if (
      product &&
      isOpen &&
      product.rotatingImagesListStates &&
      product.rotatingImagesListStates.get(filePath) === undefined &&
      product.rotatingImagesListStates.get(filePath) !== RequestState.LOADING
    ) {
      product.loadRotatingImagesList(filePath);
    }
  };

  maybeGetNextFrame = () => {
    const { dragging } = this.state;
    const { product, filePath, isOpen } = this.props;

    if (
      product &&
      product.rotatingImagesListStates &&
      product.rotatingImagesListStates.get(filePath) === RequestState.LOADED &&
      isOpen &&
      !dragging
    ) {
      this.getNextFrame();
    }
  };

  getNextFrame = () => {
    const { imageIndex } = this.state;
    const { product } = this.props;

    if (!product) {
      return;
    }

    let nextIndex = (imageIndex + 1) % this.getImageCount();
    this.setState({
      imageIndex: nextIndex
    });
  };

  handleMouseDown = (e) => {
    if (e.type === 'touchstart') {
      this.isTouch = true;
    }
    if (e.type === 'mousedown' && this.isTouch) {
      return;
    }
    if (!this.isTouch) {
      e.preventDefault();
    }

    const { product, filePath } = this.props;

    if (product) {
      this.setState({
        dragging: true,
        dragStart: this.getScreenX(e),
        imageIndex: this.state.imageIndex,
        dragStartIndex: this.state.imageIndex,
        framePixelTarget: 360 / product.rotatingImagesList.get(filePath).length
      });
    }
  };

  handleMouseUp = (e) => {
    if (e.type === 'touchend') {
      this.isTouch = true;
    }
    if (e.type === 'mouseup' && this.isTouch) {
      return;
    }
    if (!this.isTouch) {
      e.preventDefault();
    }

    this.setState({
      dragging: false
    });
  };

  getScreenX = (e) => {
    return e.type === 'touchmove' || e.type === 'touchstart'
      ? e.touches[0].screenX
      : e.screenX;
  };

  handleMouseMove = (e) => {
    const { dragging, dragStart, framePixelTarget } = this.state;

    if (!this.isTouch) {
      e.preventDefault();
    }
    if (!dragging) {
      return;
    }

    const screenX = this.getScreenX(e);
    const dx = (screenX - dragStart) / framePixelTarget;
    const index = Math.floor(dx) % this.getImageCount();
    if (dragging && index !== 0) {
      window.requestAnimationFrame(() => {
        this.updateIndexDragging(index);
      });
    }
  };

  getImageCount = () => {
    const { product, filePath } = this.props;
    return product.rotatingImagesList.get(filePath).length;
  };

  updateIndexDragging = (indexChange) => {
    const { imageIndex, dragStartIndex } = this.state;
    const imageCount = this.getImageCount();
    let index = indexChange;

    if (index < 0) {
      index = imageCount + index;
    }
    index = (index + dragStartIndex) % imageCount;

    if (index !== imageIndex) {
      this.setState({ imageIndex: index });
    }
  };

  getImagePath = () => {
    const { product, filePath } = this.props;
    const { imageIndex } = this.state;
    return product.rotatingImagesList.get(filePath)[imageIndex] !== undefined
      ? product.rotatingImagesList.get(filePath)[imageIndex]
      : null;
  };

  renderImage = () => {
    const imagePath = this.getImagePath();
    return imagePath ? (
      <div className="ProductRotatingImageModal__image">
        <img src={imagePath} alt="360" loading="lazy" />
      </div>
    ) : (
      <UnexpectedError />
    );
  };

  render() {
    const { product, filePath, toggle, isOpen } = this.props;

    return (
      <Modal
        className="ProductRotatingImageModal"
        size="lg"
        toggle={toggle}
        isOpen={isOpen}
      >
        <div className="ProductRotatingImageModal__hanging-buttons">
          <Button onClick={() => toggle()} color="primary">
            <span>
              <Icon name="times" />{' '}
              <FormattedMessage {...globalTranslations.closeTitle} />
            </span>
          </Button>
        </div>
        <ModalBody>
          <div ref={(el) => (this.bodyEl = el)}>
            <ContentForState
              state={product.rotatingImagesListStates.get(filePath)}
              forLoaded={() => {
                return (
                  <div
                    className="ProductRotatingImageModal__rotating-images"
                    onMouseDown={this.handleMouseDown}
                    onTouchStart={this.handleMouseDown}
                  >
                    {this.renderImage()}
                  </div>
                );
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

ProductRotatingImageModal.propTypes = {
  product: modelOf(Product).isRequired,
  filePath: PropTypes.string.isRequired,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool
};

export default ProductRotatingImageModal;
