import { types } from 'mobx-state-tree';
import BalancePaymentOrderUser from './BalancePaymentOrderUser';

const BalancePayment = types.model('BalancePayment', {
  orders_id: types.identifierNumber,
  amount_with_tax: types.string,
  amount_without_tax: types.string,
  date_purchased: types.string,
  users: types.array(BalancePaymentOrderUser)
});

export default BalancePayment;
