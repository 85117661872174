import { types } from 'mobx-state-tree';
import { AcceptableTermsTypeType } from '../types/AcceptableTermsType';

const InstantShoppingOrder = types.model('InstantShoppingOrder', {
  acceptable_terms: types.optional(
    types.array(
      types.model({
        link: types.maybeNull(types.string),
        type: types.maybeNull(AcceptableTermsTypeType)
      })
    ),
    []
  ),
  country: types.maybeNull(types.string),
  currency: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  id_for_analytics: types.maybeNull(types.string),
  language: types.maybeNull(types.number),
  payment_module_id: types.maybeNull(types.number),
  pickup_point_id: types.maybeNull(types.string),
  products: types.optional(
    types.array(
      types.model({
        name: types.maybeNull(types.string),
        quantity: types.maybeNull(types.number)
      })
    ),
    []
  ),
  shipping_module_id: types.maybeNull(types.number)
});

export default InstantShoppingOrder;
