import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import CustomNavigationLinkLocationType from '../../../types/CustomNavigationLinkLocation';
import NavigationSecondLevelItem from '../NavigationSecondLevelItem';
import NavigationEntity from '../../../types/NavigationEntity';

@observer
export class NavigationSecondLevelCategory extends Component {
  getSecondLevelCustomNavigationItem = (customNavLink, index) => {
    const { onClick } = this.props;

    return (
      <Fragment key={index}>
        {customNavLink.customNavLinks &&
          customNavLink.customNavLinks.map(
            (subCustomNavLink) =>
              subCustomNavLink.location ===
                CustomNavigationLinkLocationType.ABOVE &&
              subCustomNavLink.parent_sibling_id === customNavLink.id && (
                <NavigationSecondLevelItem
                  key={subCustomNavLink.id}
                  className="NavigationSecondLevelItem__custom-second-level-item"
                  entity={subCustomNavLink}
                  onClick={onClick}
                />
              )
          )}
        <NavigationSecondLevelItem
          key={customNavLink.id}
          entity={customNavLink}
          onClick={onClick}
          className="NavigationSecondLevelItem__custom-second-level-item"
        />
        {customNavLink.customNavLinks &&
          customNavLink.customNavLinks.map(
            (subCustomNavLink) =>
              subCustomNavLink.location ===
                CustomNavigationLinkLocationType.BELOW &&
              subCustomNavLink.parent_sibling_id === customNavLink.id && (
                <NavigationSecondLevelItem
                  key={subCustomNavLink.id}
                  className="NavigationSecondLevelItem__custom-second-level-item"
                  entity={subCustomNavLink}
                  onClick={onClick}
                />
              )
          )}
      </Fragment>
    );
  };

  render() {
    const { children, entity, onClick } = this.props;
    return (
      <Fragment>
        {entity.customNavLinks &&
          entity.customNavLinks.map(
            (customNavLink, index) =>
              customNavLink.location ===
                CustomNavigationLinkLocationType.ABOVE &&
              customNavLink.parent_sibling_id === entity.id &&
              this.getSecondLevelCustomNavigationItem(customNavLink, index)
          )}
        <NavigationSecondLevelItem
          key={entity.id}
          entity={entity}
          onClick={onClick}
        >
          {children}
        </NavigationSecondLevelItem>
        {entity.customNavLinks &&
          entity.customNavLinks.map(
            (customNavLink, index) =>
              customNavLink.location ===
                CustomNavigationLinkLocationType.BELOW &&
              customNavLink.parent_sibling_id === entity.id &&
              this.getSecondLevelCustomNavigationItem(customNavLink, index)
          )}
      </Fragment>
    );
  }
}

NavigationSecondLevelCategory.propTypes = {
  entity: NavigationEntity,
  onClick: PropTypes.func
};

export default NavigationSecondLevelCategory;
