import React, { Component } from 'react';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import Filter from '../../../models/Filter';
import FilterContainer from '../FilterContainer';
import RangeSlider from '../../common/RangeSlider';
import globalTranslations from '../../../i18n/globalTranslations';
import FilterAcceptButton from '../FilterAcceptButton';

@observer
class RangeFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      min: props.min,
      max: props.max,
    };
  }

  UNSAFE_componentWillReceiveProps = (props) => {
    if (props.min !== this.state.min || props.max !== this.state.max) {
      this.setState({ min: props.min, max: props.max });
    }
  };

  onValueChanged = (value) => {
    if (isNaN(value[0]) || isNaN(value[1])) return;
    this.setState({ min: value[0], max: value[1] });
  };

  onMinValueChanged = (value) => {
    if (isNaN(value)) return;
    this.setState({ min: value });
  };

  onMaxValueChanged = (value) => {
    if (isNaN(value)) return;
    this.setState({ max: value });
  };

  isValueChanged = () => {
    return (
      this.props.min !== this.state.min || this.props.max !== this.state.max
    );
  };

  isValidRange = () => {
    const { min, max } = this.state;

    return min <= max;
  };

  validateAndMaybeAccept = () => {
    const { filter, onAccept } = this.props;
    const { min, max } = this.state;

    if (this.isValidRange()) {
      onAccept(filter, [min, max]);
    } else {
      this.setState((state) => {
        state.min = this.props.min;
        state.max = this.props.max;

        return state;
      });
    }
  };

  onAcceptRange = () => {
    const { filterInUse } = this.props;

    if (this.isValueChanged() || !filterInUse) {
      this.validateAndMaybeAccept();
    }
    this.filterContainer.toggleOpen();
  };

  // This method is called when user clicks outside open filter.
  // Call onAccept and close filter container.
  onFilterClosedAutomatically = () => {
    if (this.isValueChanged()) {
      this.validateAndMaybeAccept();
    }

    if (this.filterContainer) {
      this.filterContainer.toggleOpen();
    }
  };

  render() {
    const { filter, filterInUse } = this.props;
    const { min, max } = this.state;
    return (
      <div className="RangeFilter">
        <FilterContainer
          headerText={filter.name}
          filterInUse={filterInUse}
          onFilterClosedAutomatically={this.onFilterClosedAutomatically}
          ref={(ref) => (this.filterContainer = ref)}
        >
          <RangeSlider
            allowCross={false}
            min={Number(filter.min)}
            max={Number(filter.max)}
            value={[min, max]}
            step={filter.step}
            onChange={this.onValueChanged}
          />
          <div className="RangeFilter__text-inputs">
            <div className="RangeFilter__text-box">
              <Input
                type="text"
                value={min}
                onChange={(event) =>
                  this.onMinValueChanged(Number(event.target.value))
                }
                bsSize="sm"
                className="RangeFilter__text-box-input"
              />
            </div>
            <label className="RangeFilter__between-text-boxes">–</label>
            <div className="RangeFilter__text-box">
              <InputGroup size="sm">
                <Input
                  type="text"
                  value={max}
                  onChange={(event) =>
                    this.onMaxValueChanged(Number(event.target.value))
                  }
                  className="RangeFilter__text-box-input RangeFilter__text-box-input--with-addon"
                />
                <InputGroupAddon addonType="append">
                  {filter.unit}
                </InputGroupAddon>
              </InputGroup>
            </div>
          </div>
          <FilterAcceptButton
            className="RangeFilter__accept-button"
            onClick={this.onAcceptRange}
            disabled={!this.isValidRange()}
          >
            <FormattedMessage {...globalTranslations.saveTitle} />
          </FilterAcceptButton>
        </FilterContainer>
      </div>
    );
  }
}

RangeFilter.propTypes = {
  filter: modelOf(Filter),
  filterInUse: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default RangeFilter;
