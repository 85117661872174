import React, { Component } from 'react';
import { DropdownToggle, DropdownMenu, UncontrolledDropdown } from 'reactstrap';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

@observer
class DropdownSelect extends Component {
  render() {
    const { label, children, disabled, isLarge, ...props } = this.props;
    const styles = isLarge
      ? {
          overflowY: 'scroll',
          maxHeight: '500px'
        }
      : {};

    return (
      <UncontrolledDropdown
        className="DropdownSelect"
        disabled={disabled}
        {...props}
      >
        <DropdownToggle caret disabled={disabled}>
          {label}
        </DropdownToggle>
        <DropdownMenu style={styles}>{children}</DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

DropdownSelect.propTypes = {
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  isLarge: PropTypes.bool
};

DropdownSelect.defaultTypes = {
  isLarge: false
};

export default DropdownSelect;
