import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import RouterPropTypes from 'react-router-prop-types';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import { addParametersToPath } from '../../../util/queryString';
import ProductPropertyType from '../../../types/ProductPropertyType';
import ProductCardPropertySelect from '../ProductCardPropertySelect';
import RouteService from '../../../services/RouteService';

@observer
export class ProductCardProperties extends Component {
  getFirstCollectionProperty = (product) => {
    return product.collection.shouldSwapCollectionOrder()
      ? product.collection.row
      : product.collection.column;
  };

  getSecondCollectionProperty = (product) => {
    return product.collection.shouldSwapCollectionOrder()
      ? product.collection.column
      : product.collection.row;
  };

  onCollectionPropertyElementClick = (
    propertyId,
    elementId,
    isFirstCollectionClicked
  ) => {
    const { history, product, selectedElementId, routeService } = this.props;
    const elementIds = product.collection.getElementIds(
      elementId,
      selectedElementId,
      isFirstCollectionClicked
    );

    // If we know both element IDs, link directly to active product
    let path;
    if (elementIds.columnId !== null && elementIds.rowId !== null) {
      const item = product.collection.getItem(
        elementIds.columnId,
        elementIds.rowId
      );
      path = product.pathWithActiveProductId(item.product.id);
    } else {
      path = addParametersToPath(product.path, elementIds);
    }
    history.push(routeService.getProductPath(product, path));
  };

  onCollectionElementHover = (elementId, isFirstCollection) => {
    const { onFirstCollectionElementHovered } = this.props;
    if (isFirstCollection) {
      onFirstCollectionElementHovered(elementId);
    }
  };

  componentForCollectionProperty = (collectionProperty, isFirstProperty) => {
    const { product, filterNotInStockElements } = this.props;

    if (collectionProperty.isEmpty()) {
      return null;
    }

    const { selectedElementId } = this.props;

    const elements =
      filterNotInStockElements && !product.canBeOrderedOutOfStock
        ? collectionProperty.elements.filter((element) =>
            product.collection.isElementAvailable(
              element.id,
              selectedElementId,
              isFirstProperty
            )
          )
        : collectionProperty.elements;

    if (elements.length === 0) {
      return null;
    }

    let selectedElementIdForComponent = null;

    selectedElementIdForComponent = selectedElementId || elements[0].id;
    if (!isFirstProperty) {
      const firstCollectionProperty = this.getFirstCollectionProperty(product);

      const firstElements =
        filterNotInStockElements && !product.canBeOrderedOutOfStock
          ? firstCollectionProperty.elements.filter((element) =>
              product.collection.isElementAvailable(
                element.id,
                selectedElementId,
                isFirstProperty
              )
            )
          : firstCollectionProperty.elements;
      if (firstElements.length > 0) {
        selectedElementIdForComponent =
          selectedElementId || firstElements[0].id;
      }
    }

    return (
      <ProductCardPropertySelect
        product={product}
        propertyId={collectionProperty.id}
        elements={elements}
        isFirstProperty={isFirstProperty}
        onElementClick={(propertyId, elementId) =>
          this.clickHandler(propertyId, elementId, isFirstProperty)
        }
        onElementHover={(elementId) =>
          this.hoverHandler(elementId, isFirstProperty)
        }
        selectedElementId={selectedElementIdForComponent}
        asThumbnails={collectionProperty.type === ProductPropertyType.COLOR}
        filterNotInStockElements={filterNotInStockElements}
      />
    );
  };

  clickHandler = (propertyId, elementId, isFirstProperty) =>
    this.onCollectionPropertyElementClick(
      propertyId,
      elementId,
      isFirstProperty
    );

  hoverHandler = (elementId, isFirstProperty) =>
    this.onCollectionElementHover(elementId, isFirstProperty);

  render() {
    const { product } = this.props;

    return (
      <div className="ProductCardProperties">
        {this.componentForCollectionProperty(
          this.getFirstCollectionProperty(product),
          true
        )}
        {this.componentForCollectionProperty(
          this.getSecondCollectionProperty(product),
          false
        )}
      </div>
    );
  }
}

ProductCardProperties.propTypes = {
  filterNotInStockElements: PropTypes.bool,
  product: modelOf(Product).isRequired,
  history: RouterPropTypes.history.isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  onFirstCollectionElementHovered: PropTypes.func.isRequired,
  selectedElementId: PropTypes.number,
};

export default withRouter(inject('routeService')(ProductCardProperties));
