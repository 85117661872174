import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ListGroup } from 'reactstrap';
import classNames from 'classnames';
import ReactResizeDetector from 'react-resize-detector';
import { observer } from 'mobx-react';

@observer
class NavigationSidePanelMegaMenu extends Component {
  getCenteredStyles = () => {
    const { onCenteredMegamenu } = this.props;
    if (onCenteredMegamenu) {
      return onCenteredMegamenu();
    }
  };

  updateOverflowState = () => {
    const { onOverflow } = this.props;
    onOverflow && onOverflow();
  };

  render() {
    const {
      centered,
      leftPanelItems,
      rightPanelContent,
      rightPanelRef,
      hideBottomLevelItems,
      activeLeftPanelEntity
    } = this.props;

    return (
      <div
        className={classNames('NavigationMegaMenu__inner', {
          'NavigationMegaMenu__inner--centered': centered
        })}
        style={this.getCenteredStyles()}
      >
        <Row>
          <Col xs="4" xl="3">
            <ListGroup className="NavigationMegaMenu__first-level-items">
              {leftPanelItems}
            </ListGroup>
          </Col>
          {rightPanelContent && [
            <Col
              xs="8"
              xl="9"
              className={classNames('NavigationMegaMenu__second-level-items', {
                'NavigationMegaMenu__hidden-bottom-level-items': hideBottomLevelItems
              })}
              key={'real_' + activeLeftPanelEntity.id}
            >
              <div>{rightPanelContent}</div>
            </Col>,
            <Col
              xs="9"
              className="NavigationMegaMenu__second-level-items NavigationMegaMenu__measurement-helper"
              key={'shadow_' + activeLeftPanelEntity.id}
            >
              <div
                ref={(measurementElement) => rightPanelRef(measurementElement)}
              >
                {rightPanelContent}
                <ReactResizeDetector
                  handleWidth
                  handleHeight
                  onResize={this.updateOverflowState}
                  refreshMode="throttle"
                  refreshRate={1000}
                />
              </div>
            </Col>
          ]}
        </Row>
      </div>
    );
  }
}

NavigationSidePanelMegaMenu.propTypes = {
  leftPanelItems: PropTypes.arrayOf(PropTypes.node).isRequired,
  rightPanelRef: PropTypes.func.isRequired,
  onOverflow: PropTypes.func.isRequired,
  activeLeftPanelEntity: PropTypes.object,
  centered: PropTypes.bool,
  hideBottomLevelItems: PropTypes.bool,
  onCenteredMegamenu: PropTypes.func,
  rightPanelContent: PropTypes.arrayOf(PropTypes.node)
};

export default NavigationSidePanelMegaMenu;
