import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import globalTranslations from '../../../../i18n/globalTranslations';
import Price from '../../../product/Price';
import AccountStore from '../../../../store/AccountStore';
import CartStore from '../../../../store/CartStore';
import { modelOf } from '../../../../prop-types';
import Icon from '../../../common/Icon';
import RequestState from '../../../../types/RequestState';

const NavigationCartText = ({
  accountStore,
  cartStore,
  className,
}) => {
  const withTax = accountStore.showPricesWithTax;

  const getPrice = () => {
    const { cart } = cartStore;

    if (!cart) {
      return null;
    }

    const price = cart?.total && cart.total.getPrice(withTax);

    return price || null;
  };

  const renderPrice = () => {
    const price = getPrice();
    return price !== null ? <Price price={price} /> : null;
  };

  const renderCartText = () => {
    let text;

    switch (cartStore.state) {
      case RequestState.LOADED:
        if (!cartStore.cart || cartStore.cart.number_of_products === 0) {
          text = <FormattedMessage {...globalTranslations.cartEmptySentence} />;
        } else {
          text = (
            <span>
              <FormattedMessage
                {...globalTranslations.itemCount}
                values={{
                  count: cartStore.cart.number_of_products,
                }}
              />{' '}
              {renderPrice()}
            </span>
          );
        }
        break;
      case RequestState.LOADING:
      default:
        text = <FormattedMessage {...globalTranslations.loading} />;
        break;
    }

    return text;
  };

  return (
    <div className={classNames("NavigationCart", "NavigationCartText", className)}>
      <Icon name="shopping-cart" />
      <span className={classNames('NavigationCart__cart-text')}>
        <span className="NavigationCart__cart-text-desktop">
          {renderCartText()}
        </span>
        <span className="NavigationCart__cart-text-mobile">
          {renderPrice()}
        </span>
      </span>
    </div>
  );
};

NavigationCartText.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  cartStore: modelOf(CartStore).isRequired,
  className: PropTypes.string,
};

export default inject(
  'accountStore',
  'cartStore',
)(observer(NavigationCartText));
