import { types } from 'mobx-state-tree';

const GdprInfo = types.model('GdprInfo', {
  first_name: types.maybeNull(types.string),
  last_name: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  street_address: types.maybeNull(types.string),
  marketing_ban: types.boolean,
  international_number: types.maybeNull(types.string),
});

export default GdprInfo;
