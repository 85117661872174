import React from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

import ProductClass from '../../../types/ProductClass';
import ProductPriceRange from '../ProductPriceRange';
import ProductPrice from '../ProductPrice';
import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';

const PriceWrapper = ({
  accountStore,
  configStore,
  product,
  withTax,
  isSecondaryPrice,
  isMultiProductPicker,
}) => {
  const showTaxIncludedInfo =
    withTax &&
    (configStore.product.showSecondaryTaxPrice || accountStore.isRetailer);

  let price = null;
  if (product.class === ProductClass.MULTI) {
    const priceRange = withTax
      ? product.multi.getSecondaryPriceRange(withTax)
      : product.multi.priceRange;
    if (priceRange && priceRange.min && priceRange.max) {
      price =
        priceRange.min !== priceRange.max ? (
          <ProductPriceRange
            range={priceRange}
            withTax={withTax}
            showTaxIncludedInfo={showTaxIncludedInfo}
            isSecondaryPrice={isSecondaryPrice}
          />
        ) : (
          <ProductPrice
            product={product}
            priceInfo={priceRange.min}
            withTax={withTax}
            showTaxIncludedInfo={showTaxIncludedInfo}
            isSecondaryPrice={isSecondaryPrice}
          />
        );
    }
  } else {
    if (product.price_info) {
      price = (
        <ProductPrice
          product={product}
          priceInfo={product.price_info}
          withTax={withTax}
          showTaxIncludedInfo={showTaxIncludedInfo}
          isSecondaryPrice={isSecondaryPrice}
          isMultiProductPicker={isMultiProductPicker}
        />
      );
    }
  }
  return price;
};

PriceWrapper.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  product: PropTypes.oneOfType([modelOf(Product), PropTypes.object]).isRequired,
  withTax: PropTypes.bool.isRequired,
  isSecondaryPrice: PropTypes.bool,
  isMultiProductPicker: PropTypes.bool,
};

export default inject((stores, props) => ({
  accountStore: stores.accountStore,
  configStore: stores.configStore,
  withTax: isNil(props.withTax)
    ? stores.accountStore.showPricesWithTax
    : props.withTax,
}))(observer(PriceWrapper));
