import React, { Component } from 'react';
import {
  Button,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import Icon from '../../common/Icon';
import globalTranslations from '../../../i18n/globalTranslations';
import ProductWatcherForm from '../ProductWatcherForm';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class ProductWatcherWidget extends Component {
  state = {
    dropdownOpen: false,
  };

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  render() {
    const { accountStore, configStore, activeProductId, productName, price } =
      this.props;

    if (!configStore.product.enableProductWatcher) {
      return null;
    }

    return (
      <div className="ProductWatcherWidget">
        <ButtonDropdown
          className="ProductWatcherWidget__dropdown-wrapper"
          isOpen={this.state.dropdownOpen}
          toggle={this.toggle}
        >
          <DropdownToggle
            className="ProductWatcherWidget__button"
            color="secondary"
            caret
          >
            <Icon name="envelope" />{' '}
            <FormattedMessage
              id="product.watcherButton"
              defaultMessage="Inform me when product is in stock"
            />
          </DropdownToggle>
          <DropdownMenu className="ProductWatcherWidget__dropdown" flip={false}>
            <h5>
              <FormattedMessage
                id="product.watcherHeading"
                defaultMessage="I want to receive a notification when this product is available"
              />
            </h5>
            <h5>{productName}</h5>
            <p>
              <FormattedMessage
                id="product.watcherInfo"
                defaultMessage="Please enter your email address and we will send you a notification when the product is in stock again. After this your email will be removed from our list."
              />
            </p>
            <ProductWatcherForm
              activeProductId={activeProductId}
              price={price}
              initialEmail={get(accountStore, 'account.personal_info.email')}
            />
            <div className="ProductWatcherWidget__hanging-buttons">
              <Button onClick={this.toggle} color="primary">
                <span>
                  <Icon name="times" />{' '}
                  <FormattedMessage {...globalTranslations.closeTitle} />
                </span>
              </Button>
            </div>
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    );
  }
}

ProductWatcherWidget.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  intl: intlShape.isRequired,
  price: PropTypes.number,
  productName: PropTypes.string,
  activeProductId: PropTypes.string,
};

export default inject(
  'accountStore',
  'configStore'
)(injectIntl(ProductWatcherWidget));
