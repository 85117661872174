import { types } from 'mobx-state-tree';

import SurveyField from './SurveyField';

const Question = types.model('Question', {
  id: types.identifierNumber,
  is_required: types.boolean,
  text: types.maybeNull(types.string),
  hint: types.maybeNull(types.string),
  field: types.maybeNull(SurveyField)
});

export default Question;
