import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { Helmet } from 'react-helmet-async';
import PageTitle from '../../common/PageTitle';
import RequestState from '../../../types/RequestState';
import ContentForState from '../../loader/ContentForState';
import { modelOf } from '../../../prop-types';
import ErrorHandler from '../../loader/ErrorHandler';
import Redirect302 from '../../route/Redirect302';
import CustomerUserStore from '../../../store/CustomerUserStore';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class MyAccountThankYouPageContent extends Component {
  state = {
    productStoreError: null,
    renderTrackingPixels: false
  };

  componentDidMount() {
    this.loadWidget();
  }

  loadWidget = () => {
    this.props.customerUserStore.loadBalancePostPayment();
  };

  getCurrentlyRunningRequestState = () => {
    const { customerUserStore } = this.props;
    const currentRequestState = customerUserStore.paymentSuccessState;

    return currentRequestState || RequestState.LOADED;
  };

  getError = () => {
    const { customerUserStore } = this.props;

    const error = customerUserStore.balancePaymentError;
    return error || null;
  };

  renderHtmlWidget = (widgetHtml) => {
    const doc = document.implementation.createHTMLDocument('');
    doc.body.innerHTML = widgetHtml;

    return (
      <Fragment>
        <Helmet>
          {[].map.call(doc.getElementsByTagName('script'), (el) => (
            <script>{el.textContent}</script>
          ))}
        </Helmet>
        <div dangerouslySetInnerHTML={{ __html: widgetHtml }} />
      </Fragment>
    );
  };

  renderErrorHandler = (props) => {
    const { configStore } = this.props;
    const { checkout } = configStore;
    switch (props.error.httpStatusCode) {
      case 401:
        return <Redirect302 to="/" />;
      case 404:
        return <Redirect302 to={checkout.checkoutPath} />;
      default:
        return <ErrorHandler {...props} />;
    }
  };

  renderSuccessPage = () => {
    const { paymentSuccess } = this.props.customerUserStore;
    return (
      <div className="MyAccountThankYouPageContent">
        <PageTitle>
          <FormattedMessage
            id="checkout.successfulTransferPageHeading"
            defaultMessage="Money transfer confirmation"
          />
        </PageTitle>
        <Row className="MyAccountThankYouPageContent__klarna-wrapper-container">
          <Col className="MyAccountThankYouPageContent__klarna-container">
            {this.renderHtmlWidget(paymentSuccess.html_widget)}
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <div className="MyAccountThankYouPage">
        <ContentForState
          state={this.getCurrentlyRunningRequestState()}
          forLoaded={this.renderSuccessPage}
          error={this.getError()}
          errorHandler={this.renderErrorHandler}
        />
      </div>
    );
  }
}

MyAccountThankYouPageContent.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  customerUserStore: modelOf(CustomerUserStore).isRequired
};

export default inject('configStore', 'customerUserStore')(
  MyAccountThankYouPageContent
);
