import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Popover, PopoverBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import ShareButtons from '../ShareButtons';
import Icon from '../Icon';
import { modelOf } from '../../../prop-types';
import UIStore from '../../../store/UIStore';

const SharePopover = ({ uiStore, ...rest }) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className="SharePopover"
      tabIndex={-1}
      onFocus={() => {
        setOpen(true);
      }}
      onBlur={() => {
        setOpen(false);
      }}
    >
      <div id="SharePopover" className="SharePopover__label">
        <Icon name="share-alt" />
        <FormattedMessage
          id="product.shareProduct"
          defaultMessage="Share product"
        />
      </div>
      <div id="SharePopover__container" className="SharePopover__container" />
      <Popover
        className="SharePopover__popover"
        target="SharePopover"
        container="SharePopover__container"
        tabIndex="0"
        isOpen={open}
        fade={false}
        boundariesElement="viewport"
        placement={uiStore.isMobile ? 'bottom' : 'left-start'}
      >
        <PopoverBody>
          <ShareButtons {...rest} />
        </PopoverBody>
      </Popover>
    </div>
  );
};

SharePopover.propTypes = {
  uiStore: modelOf(UIStore),
};

export default inject('uiStore')(observer(SharePopover));
