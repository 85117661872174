import { useEffect } from 'react';

/**
 * This is simple component that can be used to reset the scroll position.
 * Use the key to re-render the component. <ScrollReset key={resetScrollWhenThisChanges} />
 */
const ScrollReset = () => {
  useEffect(() => {
    resetScroll();
  }, []);

  const resetScroll = () => {
    window.scrollTo(0, 0);
  };

  return null;
};

export default ScrollReset;
