import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ProductImage from '../../../models/ProductImage';
import { modelOf } from '../../../prop-types';

@observer
export class YotpoMainWidget extends Component {
  componentDidMount() {
    if (this.props.refresher) {
      this.props.refresher();
    }
  }

  render() {
    const {
      currency,
      productImage,
      productPrice,
      productId,
      productName,
      productPath,
      className
    } = this.props;
    return (
      <div key={productId} className={classNames(className)}>
        <div
          className="yotpo yotpo-main-widget"
          data-product-id={productId}
          data-price={productPrice}
          data-currency={currency}
          data-name={productName}
          data-url={productPath}
          data-image-url={productImage}
        />
      </div>
    );
  }
}

YotpoMainWidget.propTypes = {
  currency: PropTypes.string.isRequired,
  productImage: modelOf(ProductImage).isRequired,
  productPrice: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  productPath: PropTypes.string.isRequired,
  className: PropTypes.string,
  refresher: PropTypes.func
};

export default YotpoMainWidget;
