import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import ManufacturerBox from '../../layout-box/ManufacturerBox';
import { modelOf } from '../../../prop-types';
import LayoutBoxStore from '../../../store/LayoutBoxStore';
import UIStore from '../../../store/UIStore';
import globalTranslations from '../../../i18n/globalTranslations';

@observer
export class NavigationManufacturerBox extends Component {
  getManufacturerBoxTitle = () => {
    const { intl, layoutBoxStore } = this.props;
    const layoutBoxes = layoutBoxStore.layoutBoxes;
    if (layoutBoxes && layoutBoxes.length) {
      const manufacturerBox = layoutBoxes.find(
        (layoutBox) => layoutBox.key === 'manufacturers'
      );

      if (!manufacturerBox) {
        return intl.formatMessage(globalTranslations.ourBrandsTitle);
      }

      return manufacturerBox && manufacturerBox.title;
    }
  };

  closeMegaMenu = () => {
    const { uiStore } = this.props;

    return uiStore.megaMenu.close();
  };

  getManufacturers = (manufacturers) => {
    const { onManufacturersUpdate } = this.props;
    onManufacturersUpdate(manufacturers);
  };

  render() {
    const title = this.getManufacturerBoxTitle();

    return (
      <ManufacturerBox
        className="ManufacturerBox__two-level-megamenu"
        twoLayerMegaMenuEnabled
        title={title}
        minManufacturersForDropdown={5}
        onLinkClick={this.closeMegaMenu}
        linkButtonClassName="NavigationManufacturerBox__link-button"
        onManufacturersUpdate={this.getManufacturers}
      />
    );
  }
}

NavigationManufacturerBox.propTypes = {
  layoutBoxStore: modelOf(LayoutBoxStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  onManufacturersUpdate: PropTypes.func.isRequired,
};

export default injectIntl(
  inject('layoutBoxStore', 'uiStore')(NavigationManufacturerBox)
);
