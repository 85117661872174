import { defineMessages } from 'react-intl';

export const errors = defineMessages({
  networkError: {
    id: 'state.networkError',
    defaultMessage:
      'Connection failure. Please ensure you are connected to the Internet.',
  },
});

export const createErrorModel = (originalError) => {
  return {
    httpStatusCode: getHttpStatusCode(originalError),
    publicMessage: getPublicMessage(originalError),
    fallbackCategoryId: getRedirectCategoryId(originalError),
  };
};

const getHttpStatusCode = (originalError) => {
  if (originalError?.response?.status >= 400) {
    return originalError.response.status;
  }

  return null;
};

const getPublicMessage = (originalError) => {
  // Handle network errors
  if (
    (originalError?.request && !originalError?.response) ||
    originalError?.message === 'Network Error'
  ) {
    return errors.networkError;
  }

  return null;
};

const getRedirectCategoryId = (originalError) => {
  if (
    originalError?.response?.data?.fallback_category_id &&
    originalError?.response?.status === 404
  ) {
    return originalError.response.data.fallback_category_id;
  }

  return null;
};
