import React from 'react';
import { inject, observer } from 'mobx-react';

import AdZones from '../../../types/AdZones';
import BannerSlider from '../../ad/BannerSlider/BannerSliderSlides';
import { modelOf } from '../../../prop-types';
import AdStore from '../../../store/AdStore';

const NavigationMegaMenuBanner = ({ adStore }) => {
  const ads = adStore.getGlobalAdsByAdZone(AdZones.MEGA_MENU_BANNER);

  if (!ads || ads.length === 0) {
    return null;
  }

  return (
    <BannerSlider
      searchParams={{
        bannerZone: AdZones.MEGA_MENU_BANNER,
      }}
      adZone={AdZones.MEGA_MENU_BANNER}
      className="NavigationMegaMenuBanner"
      preLoadedAds={ads}
      aspectRatio={6.85}
      maximumCrossAxisSize={200}
      overrideSettings={{
        centerMode: true,
      }}
    />
  );
};

NavigationMegaMenuBanner.propTypes = {
  adStore: modelOf(AdStore).isRequired,
};

export default inject('adStore')(observer(NavigationMegaMenuBanner));
