import React, { Component, createRef } from 'react';
import { Dropdown, DropdownMenu } from 'reactstrap';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import Icon from '../../common/Icon';
import PropTypes from 'prop-types';

class NavigationDropdownNavItem extends Component {
  state = {
    isOpen: false
  };

  containerRef = null;
  timer = null;

  constructor(props) {
    super(props);
    this.containerRef = createRef();
  }

  isOutside() {
    if (!this.containerRef.current || this.containerRef.current === null) {
      return false;
    }

    // If right + some arbitary safe margin is bigger than innerWidth, we want to
    // align dropdown to right.
    return (
      this.containerRef.current.getBoundingClientRect().right + 80 >
      window.innerWidth
    );
  }

  closeMenu = () => {
    this.timer = setTimeout(() => {
      this.setState({
        isOpen: false
      });
    }, 50);
  };

  updateOpenState = (bool) => {
    if (bool) {
      clearTimeout(this.timer);
    }

    this.setState({
      isOpen: bool
    });
  };

  renderNavLink = (elem) => {
    return (
      <NavLink
        key={elem.id}
        className={classNames('nav-link', 'NavigationDropdownNavItem_NavLink')}
        to={elem.path}
        {...elem}
      >
        {elem.label}
      </NavLink>
    );
  };

  render() {
    const { className, item, childLinks } = this.props;

    return (
      <Dropdown
        inNavbar={true}
        className="NavigationDropdownNavItem__container nav-item"
        toggle={() => this.updateOpenState(!this.state.isOpen)}
        isOpen={this.state.isOpen}
        onMouseOver={() => this.updateOpenState(true)}
        onMouseLeave={() => this.closeMenu()}
      >
        <NavLink
          className={classNames(
            'NavigationDropdownNavItem',
            className,
            'nav-link'
          )}
          to="#"
        >
          <span>
            {item.label} <Icon name="caret-down" />
          </span>
        </NavLink>
        <div ref={this.containerRef}>
          <DropdownMenu
            right={this.isOutside()}
            className="NavigationDropdownNavItem__list"
          >
            {childLinks.map(this.renderNavLink)}
          </DropdownMenu>
        </div>
      </Dropdown>
    );
  }
}

NavigationDropdownNavItem.propTypes = {
  childLinks: PropTypes.array
};

export default NavigationDropdownNavItem;
