import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape
} from 'react-intl';

import Page from '../../components/layout/Page';
import GenericLayout from '../../components/layout/GenericLayout';
import CanonicalLink from '../../components/head/CanonicalLink';
import Paths from '../../types/Paths';
import RouteService from '../../services/RouteService';
import SetNewPasswordForm from '../../components/account/SetNewPasswordForm';
import PageTitle from '../../components/common/PageTitle';
import HelperNavigation from '../../components/common/HelperNavigation';
import SEOTitle from '../../components/head/SEOTitle';
import AccountStore from '../../store/AccountStore';
import Redirect302 from '../../components/route/Redirect302';
import { modelOf } from '../../prop-types';
import CommonPage from '../../types/CommonPage';

const messages = defineMessages({
  setNewPasswordTitle: {
    id: 'account.setNewPasswordTitle',
    defaultMessage: 'Choose a new password'
  }
});

@observer
class ResetPasswordPage extends Component {
  getBreadcrumbsPath = () => {
    const { intl, routeService } = this.props;
    return routeService.transformBreadCrumbs([
      {
        text: intl.formatMessage(messages.setNewPasswordTitle)
      }
    ]);
  };

  render() {
    const { accountStore, routeService, match } = this.props;
    const { token } = match.params;

    if (accountStore.loggedIn) {
      return <Redirect302 to="/" />;
    }

    return (
      <Page name={CommonPage.SET_NEW_PASSWORD} className="ResetPasswordPage">
        <SEOTitle title={messages.setNewPasswordTitle} />
        <CanonicalLink path={routeService.getPath(Paths.SetNewPassword)} />
        <Container className="ResetPasswordPage__container">
          <GenericLayout
            content={
              <Fragment>
                <HelperNavigation breadcrumbsPath={this.getBreadcrumbsPath()} />
                <PageTitle>
                  <FormattedMessage {...messages.setNewPasswordTitle} />
                </PageTitle>
                <Row>
                  <Col xs="12" md="8" lg="6">
                    <SetNewPasswordForm passwordResetToken={token} />
                  </Col>
                </Row>
              </Fragment>
            }
          />
        </Container>
      </Page>
    );
  }
}

ResetPasswordPage.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  intl: intlShape.isRequired,
  match: RouterPropTypes.match.isRequired
};

export default injectIntl(
  withRouter(inject('accountStore', 'routeService')(ResetPasswordPage))
);
