import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import ProductRotatingImageModal from '../ProductRotatingImageModal';
import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import ConfigStore from '../../../store/ConfigStore';
import { FormattedMessage } from 'react-intl';
import globalTranslations from '../../../i18n/globalTranslations';

@observer
class ProductRotatingImage extends Component {
  state = {
    modal: false,
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const { product, filePath, configStore } = this.props;

    const style = {
      paddingBottom: configStore.product.imageAspectRatio + '%',
    };

    return (
      <div style={style} className="ProductRotatingImage">
        <ProductRotatingImageModal
          product={product}
          filePath={filePath}
          isOpen={this.state.modal}
          toggle={this.toggleModal}
        />
        <div className="ProductRotatingImage__logo" onClick={this.toggleModal}>
          <div className="ProductRotatingImage__logo-degree">360&#176;</div>
          <div className="ProductRotatingImage__logo-description">
            <FormattedMessage {...globalTranslations.imageTitle} />
          </div>
        </div>
      </div>
    );
  }
}

ProductRotatingImage.propTypes = {
  product: modelOf(Product),
  filePath: PropTypes.string.isRequired,
  configStore: modelOf(ConfigStore),
};

export default inject('configStore')(ProductRotatingImage);
