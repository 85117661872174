import React from 'react';
import classNames from 'classnames';

/* TODO: make toggleable with a configuration setting? */
const PageTitle = ({ children, className, ...rest }) => {
  return (
    <h1 className={classNames('PageTitle', className)} {...rest}>
      {children}
    </h1>
  );
};

export default PageTitle;
