import { types } from 'mobx-state-tree';

const AccountPersonalInfo = types.model('AccountPersonalInfo', {
  firstname: types.string,
  lastname: types.string,
  email: types.string,
  telephone: types.optional(types.string, ''),
  company: types.optional(types.string, '')
});

export default AccountPersonalInfo;
