import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import CategoryBadges from '../../common/CategoryBadges';
import UIStore from '../../../store/UIStore';
import RouteService from '../../../services/RouteService';
import CategoryStore from '../../../store/CategoryStore';
import CustomNavigationLinkStore from '../../../store/CustomNavigationLinkStore';
import NavigationMegaMenu from '../../navigation/NavigationMegaMenu';
import ConfigStore from '../../../store/ConfigStore';
import CustomNavigationLinkParentType from '../../../types/CustomNavigationLinkParentType';
import LinkType from '../../../types/LinkType';
import { transformInternalLink } from '../../../util/url';

@observer
class NavigationTopLevelMegaMenu extends Component {
  getMainLevelItems = () => {
    const { uiStore, routeService, customNavigationLinkStore, configStore } =
      this.props;
    const { customNavigationLinks } = customNavigationLinkStore;

    let items = uiStore.megaMenu.visibleCategories.map(
      this.convertCategoryToNavigationEntity
    );

    const { rootCategory, activeSection } = uiStore.megaMenu;

    const twoLevelMegaMenuEnabled =
      configStore.navigation.megaMenu.twoLayerMegaMenuEnabled;

    // Don't show categoryOrSectionFields for custom navigation links
    if (
      (rootCategory && rootCategory.link_type) ||
      (activeSection && activeSection.link_type)
    ) {
      return items;
    }

    let categoryOrSectionFields = {};
    if (rootCategory && !twoLevelMegaMenuEnabled) {
      categoryOrSectionFields = {
        label: (
          <FormattedMessage
            id="navigation.shopAllCategory"
            defaultMessage="Shop all {category}"
            values={{
              category: uiStore.megaMenu.rootCategory.navigationName,
            }}
          />
        ),
        path: routeService.getPath(uiStore.megaMenu.rootCategory.path),
      };
    } else if (activeSection && !twoLevelMegaMenuEnabled) {
      categoryOrSectionFields = {
        label: (
          <FormattedMessage
            id="navigation.shopAllSection"
            defaultMessage="Shop all {section}"
            values={{
              section: uiStore.megaMenu.activeSection.navigationName,
            }}
          />
        ),
        path: routeService.getPath(uiStore.megaMenu.activeSection.path),
      };
    }

    const topLevelCustomNavigations = this.filterTopLevelCustomNavigations(
      customNavigationLinks
    );

    items = topLevelCustomNavigations.concat(items);

    if (!twoLevelMegaMenuEnabled) {
      items.unshift({
        ...categoryOrSectionFields,
        id: '_top',
      });
    }

    return items;
  };

  convertCategoryToNavigationEntity = (category) => {
    const { uiStore, routeService, categoryStore } = this.props;

    return {
      id: category.id,
      label: (
        <span>
          <span>{category.navigationName}</span>
          <CategoryBadges category={category} />
        </span>
      ),
      active: categoryStore.isInActiveCategoryHierarchy(category),
      path: routeService.getPath(category.path, uiStore.megaMenu.activeSection),
      children: this.getCategoryNonBlockedChildren(category).map(
        this.convertCategoryToNavigationEntity
      ),
      type: category.link_type,
      customNavLinks: this.getCustomNavigationLinks(category),
    };
  };

  getCategoryNonBlockedChildren(category) {
    const { uiStore } = this.props;

    if (!category) {
      return [];
    }

    return uiStore.megaMenu.activeSection
      ? category.getNonBlockedChildren(
          uiStore.megaMenu.activeSection.blockedCategoriesDict
        )
      : category.children;
  }

  getCustomNavigationLinks = (category) => {
    const { customNavigationLinkStore } = this.props;

    const customNavItems = customNavigationLinkStore.filterByCategoryId(
      category.id
    );

    return this.convertCustomNavigationLinksToEntityItems(customNavItems);
  };

  convertCustomNavigationLinksToEntityItems = (
    topLevelCustomNavigationLinks
  ) => {
    const { customNavigationLinkStore } = this.props;
    return topLevelCustomNavigationLinks.map((topLevelCustomNavigationLink) => {
      return {
        id: topLevelCustomNavigationLink.id,
        label: this.getCustomNavigationLinkTitle(
          topLevelCustomNavigationLink.name
        ),
        path: this.getCustomCategoryListPath(topLevelCustomNavigationLink),
        link_type: topLevelCustomNavigationLink.link_type,
        location: topLevelCustomNavigationLink.location,
        parent_sibling_id: topLevelCustomNavigationLink.parent_sibling_id,
        customNavLinks: this.convertCustomNavigationLinksToEntityItems(
          customNavigationLinkStore.findChildLinks(
            topLevelCustomNavigationLink.id
          )
        ),
      };
    });
  };

  getCustomNavigationLinkTitle = (name) => {
    const { configStore } = this.props;
    const twoLevelMegaMenuEnabled =
      configStore.navigation.megaMenu.twoLayerMegaMenuEnabled;

    if (twoLevelMegaMenuEnabled) {
      return (
        <span>
          <span>{name}</span>
        </span>
      );
    }

    return name;
  };

  getCustomCategoryListPath = (customNavLink) => {
    const { uiStore, routeService } = this.props;

    if (customNavLink.link_type === LinkType.EXTERNAL_LINK) {
      return customNavLink.link;
    }

    if (customNavLink.link_type === LinkType.INTERNAL_LINK) {
      return routeService.getPath(transformInternalLink(customNavLink.link));
    }

    return routeService.getPath(
      customNavLink.link,
      uiStore.megaMenu.activeSection
    );
  };

  filterTopLevelCustomNavigations = (customNavigationLinks) => {
    if (!customNavigationLinks) {
      return null;
    }

    const topLevelCustomNavigationLinks = customNavigationLinks.filter(
      (customNavigationLinkSibling) =>
        !customNavigationLinkSibling.parent_sibling_id &&
        customNavigationLinkSibling.tree_type ===
          CustomNavigationLinkParentType.CATEGORY
    );

    return this.convertCustomNavigationLinksToEntityItems(
      topLevelCustomNavigationLinks
    );
  };

  render() {
    const { uiStore, configStore, debouncer } = this.props;
    const { megaMenu } = uiStore;
    const centered = configStore.topNavigation.center;

    if (!megaMenu.isOpen) {
      return null;
    }

    const rootItem = megaMenu.activeSection || megaMenu.rootCategory;
    const items = this.getMainLevelItems();

    return (
      <NavigationMegaMenu
        // This onMouseOver prevents the onMouseOver that would close the MegaMenu
        onMouseOver={() => debouncer(() => null)}
        onMouseOut={() => debouncer(() => uiStore.megaMenu.close())}
        entities={items}
        close={megaMenu.close}
        key={rootItem.id}
        centered={centered}
      />
    );
  }
}

NavigationTopLevelMegaMenu.propTypes = {
  categoryStore: modelOf(CategoryStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  customNavigationLinkStore: modelOf(CustomNavigationLinkStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  // This is a hack that we need in order to support onMouseOver and onMouseOut
  // when this megamenu and the elements toggling it are separate.
  // In normal (non-react) world this would be handled by rendering the
  // megamenu as a child of the menu item. This refactoring is however too big
  // right now, so we settle for having a global debouncer that only excutes the
  // last function call.
  debouncer: PropTypes.func,
};

export default inject(
  'categoryStore',
  'configStore',
  'customNavigationLinkStore',
  'routeService',
  'uiStore'
)(NavigationTopLevelMegaMenu);
