import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import ProductEnquiryModal from '../ProductEnquiryModal';
import globalTranslations from '../../../i18n/globalTranslations';
import ProductEnquiryType from '../../../types/ProductEnquiryType';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import ButtonLink from '../../common/ButtonLink';

const ProductEnquiry = ({ configStore, id, type, price }) => {
  const [open, setOpen] = useState(false);

  const getTitle = () => {
    switch (type) {
      case ProductEnquiryType.PROPOSAL_REQUEST:
        return globalTranslations.productEnquiryProposalTitle;
      case ProductEnquiryType.MESSAGE:
      default:
        return globalTranslations.productEnquiryTitle;
    }
  };

  const renderMessage = () => {
    return <FormattedMessage {...getTitle()} />;
  };

  if (!configStore.product.enableEnquiry) {
    return null;
  }

  return (
    <div className="ProductEnquiry">
      <ProductEnquiryModal
        id={id}
        isOpen={open}
        toggle={() => setOpen(!open)}
        enquiry={{
          title: getTitle(),
          type: type,
        }}
        price={price}
      />
      <ButtonLink
        className="ProductEnquiry__modal-button"
        onClick={() => setOpen(!open)}
      >
        {renderMessage()}
      </ButtonLink>
    </div>
  );
};

ProductEnquiry.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(ProductEnquiryType)),
  price: PropTypes.number,
};

export default inject('configStore')(observer(ProductEnquiry));
