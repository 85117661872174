import _ from 'lodash';

import ProductClass from '../../types/ProductClass';
import OrderTotalClass from '../../types/OrderTotalClass';
import {
  CustomEventNames,
  UniversalAnalyticsEventNames,
} from '../AnalyticsEventNames';

const IMPRESSION_MAX_BATCH_SIZE = 35;

export default class GoogleUniversalAnalyticsEventHandler {
  constructor(configStore, sectionStore, countryStore) {
    this.configStore = configStore;
    this.sectionStore = sectionStore;
    this.countryStore = countryStore;
    this.activeCountry = countryStore.activeCountry;
    // We use our own impressionDataQueue & promoViewDataQueue to hold the data in order to send it in batches
    this.impressionDataQueue = [];
    this.promoViewDataQueue = [];

    // We don't want to spam Google Analytics, so we will always wait for 100ms for new data before sending impressions
    // and promoViews data out. If we have a constant flow of data, we will send data every 500 ms (maxWait).
    const wait = 100; // ms
    const maxWait = 500; // ms
    this.requestImpressionPushToDataLayer = _.debounce(
      this.pushImpressionsToDataLayer,
      wait,
      {
        maxWait,
      }
    );
    this.requestPromoViewPushToDataLayer = _.debounce(
      this.pushPromoViewsToDataLayer,
      wait,
      {
        maxWait,
      }
    );
  }

  handleAnalyticsEvents = (event) => {
    switch (event.detail.name) {
      case UniversalAnalyticsEventNames.addToCart:
        this.addToCart(event.detail.payload);
        break;
      case UniversalAnalyticsEventNames.productClick:
        this.productClick(event.detail.payload);
        break;
      case UniversalAnalyticsEventNames.productImpressions:
        this.productImpressions(event.detail.payload);
        break;
      case UniversalAnalyticsEventNames.productDetail:
        this.productDetail(event.detail.payload);
        break;
      case UniversalAnalyticsEventNames.promotionClick:
        this.promotionClick(event.detail.payload);
        break;
      case UniversalAnalyticsEventNames.promoView:
        this.promoView(event.detail.payload);
        break;
      case UniversalAnalyticsEventNames.purchase:
        this.purchase(event.detail.payload);
        break;
      case UniversalAnalyticsEventNames.removeFromCart:
        this.removeFromCart(event.detail.payload);
        break;
      case UniversalAnalyticsEventNames.reserveAndCollect:
        this.sendReserveAndCollect(event.detail.payload);
        break;
      case CustomEventNames.updateDimension:
        this.updateDimension(event.detail.payload);
        break;
      case UniversalAnalyticsEventNames.checkout:
        this.checkout(event.detail.payload);
        break;
      case CustomEventNames.pageNotFound:
        this.sendPageNotFoundError(event.detail.payload);
        break;
      case CustomEventNames.sendGdprData:
        this.sendGdprData(event.detail.payload);
        break;
      default:
    }
  };

  /**
   * @param {string} currencyCode
   * @param {Array} productList An array with objects in the format { quantity, product, price, activeProductId }.
   */
  addToCart = ({ currencyCode, productList }) => {
    const data = {
      ecommerce: {
        currencyCode,
        add: {
          products: productList.map((item) =>
            this.productListObjectToProductFieldObject(item)
          ),
        },
      },
    };
    this.pushEventToDataLayer(data, UniversalAnalyticsEventNames.addToCart);
  };

  createCartProductFieldObject = (product) => {
    const id = product.product_id;
    const category = product.category_name;
    const price = product.total_price;
    const quantity = product.quantity;
    const name = product.name;

    const cartProductFieldObject = {
      id,
      quantity,
      name,
    };

    if (category) {
      cartProductFieldObject.category = category;
    }

    if (price) {
      cartProductFieldObject.price = price;
    }

    return cartProductFieldObject;
  };

  cartDataToProductFieldObjectList = (product) => {
    const productFieldObject = this.createCartProductFieldObject(product);

    if (product.variations && product.variations.length > 0) {
      productFieldObject.variant = product.variations.join(',');
    }

    return productFieldObject;
  };

  checkout = ({ currencyCode, productList, actionField }) => {
    const data = {
      ecommerce: {
        currencyCode,
        checkout: {
          actionField,
          products: productList.map((product) =>
            this.cartDataToProductFieldObjectList(product)
          ),
        },
      },
    };
    this.pushEventToDataLayer(data, UniversalAnalyticsEventNames.checkout);
  };

  sendPageNotFoundError = ({ location, referrer }) => {
    const data = {
      ecommerce: {
        pageNotFound: {
          address: location,
          referrer,
        },
      },
    };
    this.pushEventToDataLayer(data, CustomEventNames.pageNotFound);
  };

  /**
   * @param {Object} gdprInfo
   * @param {Object.<string>} firstName
   * @param {Object.<string>} lastName
   * @param {Object.<string>} email
   * @param {Object.<string>} international_number
   * @param {Object.<string>} street_address
   * @param {Object.<boolean>} marketing_ban
   */
  sendGdprData = ({
    first_name,
    last_name,
    email,
    street_address,
    international_number,
    marketing_ban,
  }) => {
    const data = {
      gdpr_data: {
        first_name,
        last_name,
        email,
        street_address,
        international_number,
        marketing_ban,
      },
    };

    this.pushEventToDataLayer(data, CustomEventNames.sendGdprData);
  };

  /**
   * @param {string} currencyCode
   * @param {Array} productList An array with objects in the format { quantity, product, price, activeProductId }.
   */
  removeFromCart = ({ currencyCode, productList }) => {
    const data = {
      ecommerce: {
        currencyCode,
        remove: {
          products: productList.map((item) => {
            const transformedItem = {
              ...item,
              id: item.product_id,
            };

            const productFieldProduct = {
              product: transformedItem,
              quantity: transformedItem.quantity,
              price: transformedItem.total_price,
            };

            return this.productListObjectToProductFieldObject(
              productFieldProduct,
              true
            );
          }),
        },
      },
    };
    this.pushEventToDataLayer(
      data,
      UniversalAnalyticsEventNames.removeFromCart
    );
  };

  /**
   * @param {string} currencyCode
   * @param {Array} productList An array with objects in the format { position, product }.
   * @param {string} listName
   */
  productClick = ({ currencyCode, productList, listName }) => {
    const data = {
      ecommerce: {
        currencyCode,
        click: {
          products: productList.map((item) =>
            this.productListObjectToProductFieldObject(item)
          ),
        },
      },
    };
    if (listName) {
      data.ecommerce.click.actionField = { list: listName };
    }
    this.pushEventToDataLayer(data, UniversalAnalyticsEventNames.productClick);
  };

  /**
   * @param {string} currencyCode
   * @param {Array} productList An array with objects in the format { position, product }.
   * @param {string} listName
   */
  productImpressions = ({ currencyCode, productList, listName }) => {
    const impressions = productList.map((item) =>
      this.productListObjectToProductFieldObject(item)
    );
    if (listName) {
      impressions.forEach((productFieldObject) => {
        productFieldObject.list = listName;
      });
    }
    this.pushToImpressionDataQueue(impressions, currencyCode);
  };

  /**
   * @param {string} currencyCode
   * @param {Array} productList An array with objects in the format { product }.
   */
  productDetail = ({ currencyCode, productList }) => {
    const data = {
      ecommerce: {
        currencyCode,
        detail: {
          products: productList.map((item) =>
            this.productListObjectToProductFieldObject(item)
          ),
        },
      },
    };
    this.pushEventToDataLayer(data, UniversalAnalyticsEventNames.productDetail);
  };

  /**
   * @param {Array} bannerList An array with objects in the format { banner, bannerZone }.
   */
  promotionClick = ({ bannerList }) => {
    const data = {
      ecommerce: {
        promoClick: {
          promotions: bannerList.map((item) =>
            this.bannerListobjectToPromoFieldObject(item)
          ),
        },
      },
    };
    this.pushEventToDataLayer(
      data,
      UniversalAnalyticsEventNames.promotionClick
    );
  };

  /**
   * @param {Array} bannerList An array with objects in the format { banner, bannerZone }.
   */
  promoView = ({ bannerList }) => {
    const promotions = bannerList.map((item) =>
      this.bannerListobjectToPromoFieldObject(item)
    );
    this.pushToPromoViewDataQueue(promotions);
  };

  bannerListobjectToPromoFieldObject = ({ bannerZone, banner }) => {
    // TODO Check name & creative fields with marketing
    return {
      id: banner.id,
      name: banner.title || banner.image,
      creative: banner.image,
      position: bannerZone,
    };
  };

  /**
   * Promo views come from multiple components, so we queue them instead of sending them right away.
   * @param promoViews
   */
  pushToPromoViewDataQueue = (promoViews) => {
    this.promoViewDataQueue = this.promoViewDataQueue.concat(promoViews);
    this.requestPromoViewPushToDataLayer();
  };

  /**
   * @param {Object} purchase
   * @param {CurrentOrder} purchase.currentOrder Current order model
   */
  purchase = ({ currentOrder }) => {
    const orderTotalsByClass = _.keyBy(currentOrder.totals, 'class');

    const getTotalByClass = (totalClass) => {
      const total = orderTotalsByClass[totalClass];

      // The totals we use should always be there, but...
      return total ? total.value : null;
    };

    const orderProducts = currentOrder.products.map((product) => {
      const isWholeNumber = !!(
        product.package_size && product.package_size % 1 === 0
      );

      return this.productListObjectToProductFieldObject(
        {
          product,
          quantity: isWholeNumber
            ? product.package_size * product.quantity
            : product.quantity,
        },
        true
      );
    });

    const { postalcode, city } = currentOrder.customerInfo;

    const purchase = {
      actionField: {
        id: currentOrder.id_for_analytics,
        revenue: getTotalByClass(OrderTotalClass.TOTAL),
        tax: getTotalByClass(OrderTotalClass.TAX_TOTAL),
        shipping: getTotalByClass(OrderTotalClass.EXTRA_TOTAL),
        postal_code: postalcode,
        city,
      },
      products: orderProducts,
    };

    if (currentOrder.campaign_code) {
      purchase.actionField.coupon = currentOrder.campaign_code;
    }

    if (this.activeCountry) {
      purchase.actionField.country = this.activeCountry.iso_code_2;
    }

    const data = {
      ecommerce: {
        currencyCode: currentOrder.currency,
        purchase,
      },
    };

    this.pushEventToDataLayer(data, UniversalAnalyticsEventNames.purchase);
  };

  sendReserveAndCollect = (data) => {
    const analyticsData = {
      ecommerce: {
        ...data,
      },
    };

    this.pushEventToDataLayer(
      analyticsData,
      UniversalAnalyticsEventNames.reserveAndCollect
    );
  };

  productListObjectToProductFieldObject = (
    { product, quantity, position, price, newQuantity, activeProductId },
    knownProduct = false
  ) => {
    let productFieldObject = {};

    if (!knownProduct) {
      if (product.class === ProductClass.MULTI && activeProductId) {
        product = product.multi.findChild(activeProductId) || product;
      }

      productFieldObject = this.createProductFieldObject(
        product,
        activeProductId
      );
    }

    // Product is known when it is in checkout or later stage
    const productName = knownProduct
      ? product.name
      : product.multiproduct_title || product.name;

    if (product.id) {
      productFieldObject.id = product.id;
    }

    if (productName) {
      productFieldObject.name = productName;
    }

    // If no numeric price is given, default to price with tax
    if (Number(price) !== price) {
      price = product.price_info ? product.price_info.getPrice(true) : 0.0;
    }
    productFieldObject.price = price.toFixed(2);

    if (product.manufacturer?.name || product.manufacturers_name) {
      const brand = product.manufacturer?.name || product.manufacturers_name;
      productFieldObject.brand = brand;
    }

    if (product.canonical_path?.length > 0) {
      const categoryPath = this.getProductFieldObjectCategoryPath(
        product.canonical_path
      );
      productFieldObject = {
        ...productFieldObject,
        category: categoryPath,
      };

      if (product.variations?.length > 0) {
        productFieldObject.variant = product.variations.join(',');
      }
    }

    // Product may have newQuantity or quantity field.
    const realQuantity = newQuantity || quantity;

    if (realQuantity) {
      productFieldObject.quantity = realQuantity;
    }
    if (position) {
      productFieldObject.position = position;
    }

    return productFieldObject;
  };

  createProductFieldObject = (product, activeProductId) => {
    let productFieldObject = {};

    if (product.class === ProductClass.COLLECTION && activeProductId) {
      productFieldObject.variant = this.formatCollectionVariant(
        product.collection,
        activeProductId
      );
    }

    /**
     * TODO: Remove product.hierarchy when wishlist has been reworked.
     *
     * Wishlist product has a special model that has been manually built
     * and we cannot create Product-model with the data.
     * If computed property uses root store data eg. product.mainCategory,
     * root is replaced when a new model is created.
     */
    const hierarchy = product.mainCategory?.hierarchy || product.hierarchy;
    const mainItemCategory = this.getMainItemCategory(product, hierarchy);
    if (mainItemCategory) {
      productFieldObject = {
        ...productFieldObject,
        category: [mainItemCategory]
          .concat(hierarchy.map((category) => category.name))
          .join('/'),
      };
    }

    return productFieldObject;
  };

  /**
   * @param {Product} product
   * @param {Array.<Category>} hierarchy
   * @returns {string} category name
   */
  getMainItemCategory = (product, hierarchy) => {
    const ifSectionsActive = this.configStore.activateSections;

    if (!hierarchy) {
      return;
    }

    if (ifSectionsActive && product.main_section_id) {
      return this.sectionStore.findSectionById(product.main_section_id)
        ?.display_name;
    }

    return hierarchy[0]?.name;
  };

  /**
   *
   * @param {Array.<string>} canonicalPath
   * @returns {Array}
   */
  getProductFieldObjectCategoryPath = (canonicalPath) => {
    return canonicalPath.map((category) => category).join('/');
  };

  formatCollectionVariant = (collection, activeProductId) => {
    const item = collection.getItemWithProductId(activeProductId);
    const columnElement = collection.column.getElementWithId(item.column_id);
    const columnElementFormatted = this.formatPropertyElement(columnElement);
    const rowElement = collection.row.getElementWithId(item.row_id);
    const rowElementFormatted = this.formatPropertyElement(rowElement);
    return `${columnElementFormatted} - ${rowElementFormatted}`;
  };

  formatPropertyElement = (element) => {
    return `${element.name} (ID: ${element.id})`;
  };

  /**
   * Impressions come from multiple components, so we queue them instead of sending them right away.
   *
   * @param impressions
   * @param currencyCode
   */
  pushToImpressionDataQueue(impressions, currencyCode) {
    this.impressionCurrencyCode = currencyCode;
    this.impressionDataQueue = this.impressionDataQueue.concat(impressions);
    this.requestImpressionPushToDataLayer();
  }

  pushImpressionsToDataLayer = () => {
    // GA has a maximum payload size of 8KB. If we have too many impressions we need to split them into chunks.
    const impressionChunks = _.chunk(
      this.impressionDataQueue,
      IMPRESSION_MAX_BATCH_SIZE
    );
    impressionChunks.forEach((impressionChunk) =>
      this.pushEventToDataLayer(
        {
          ecommerce: {
            currencyCode: this.impressionCurrencyCode,
            impressions: impressionChunk,
          },
        },
        UniversalAnalyticsEventNames.productImpressions
      )
    );
    this.impressionDataQueue = [];
  };

  pushPromoViewsToDataLayer = () => {
    // GA has a maximum payload size of 8KB. If we have too many impressions we need to split them into chunks.
    const chunks = _.chunk(this.promoViewDataQueue, IMPRESSION_MAX_BATCH_SIZE);
    chunks.forEach((chunk) =>
      this.pushEventToDataLayer(
        {
          ecommerce: {
            promoView: {
              promotions: chunk,
            },
          },
        },
        UniversalAnalyticsEventNames.promoView
      )
    );
    this.impressionDataQueue = [];
  };

  updateDimension = ({ dimensionKey, dimensionValue }) => {
    window.dataLayer[0][dimensionKey] = dimensionValue;
  };

  pushEventToDataLayer = (data, action) => {
    window.dataLayer.push({
      // Send all data under a generic "ecommerce" -event. -- But why?
      // Event name here should be what we use in the eventAction, https://developers.google.com/tag-manager/enhanced-ecommerce
      // Potentially disrupts the analytics of many clients, if name/structure is changed without proper planning.
      // It seems to work but creates confusion why it's different of the documentation.
      event: 'ecommerce',
      eventAction: action,
      ...data,
    });

    // Reset the ecommerce data after pushes.
    window.dataLayer.push({ eventAction: undefined, ecommerce: undefined });
  };
}
