import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import InfoPageModal from '../../info-page/InfoPageModal';
import ButtonLink from '../../common/ButtonLink';

@observer
export class PrivacyStatementLink extends Component {
  state = {
    show: false,
  };

  toggle = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    const { configStore, children } = this.props;
    return (
      <>
        <ButtonLink className="PrivacyStatementLink" onClick={this.toggle}>
          {children || (
            <FormattedMessage
              id="privacyStatement.readLinkSentence"
              defaultMessage="Privacy statement here"
            />
          )}
        </ButtonLink>
        {this.state.show && (
          <InfoPageModal
            isOpen={this.state.show}
            toggle={this.toggle}
            pageId={configStore.privacyStatement.infoPageId}
          />
        )}
      </>
    );
  }
}

PrivacyStatementLink.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
};

export default inject('configStore')(PrivacyStatementLink);
