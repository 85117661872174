import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { createForm } from '../../../models/Form';
import globalTranslations from '../../../i18n/globalTranslations';
import FormGroupField from '../../form/FormGroupField';
import {
  isEmail,
  minLength,
  notBlank,
  numberBetween,
} from '../../../util/formValidators';
import ConfigStore from '../../../store/ConfigStore';
import { modelOf } from '../../../prop-types';
import { createFormField } from '../../../models/FormField';
import LanguageStore from '../../../store/LanguageStore';
import AccountStore from '../../../store/AccountStore';
import RequestState from '../../../types/RequestState';
import ErrorHandler from '../../loader/ErrorHandler';
import { createErrorModel } from '../../../util/error';
import InteractiveStars from '../InteractiveStars';
import FieldError from '../../form/FieldError';
import RecaptchaLegalBanner from '../../legal/RecaptchaLegalBanner';
import UIStore from '../../../store/UIStore';
import { get } from 'lodash';

const labels = {
  nickname: <FormattedMessage {...globalTranslations.nicknameTitle} />,
  email: <FormattedMessage {...globalTranslations.emailTitle} />,
  review: <FormattedMessage {...globalTranslations.reviewTitle} />,
  reviewRating: (
    <FormattedMessage {...globalTranslations.reviewProductRatingTitle} />
  ),
  serviceRating: (
    <FormattedMessage {...globalTranslations.reviewServiceRatingTitle} />
  ),
};

const ReviewStatus = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
};

@observer
class ReviewForm extends Component {
  constructor(props) {
    super(props);
    const { configStore, accountStore, couponsEnabled } = this.props;

    this.form = null;
    this.formName = 'ReviewForm';

    this.state = {
      successStatus: null,
      error: null,
      requestState: RequestState.LOADED,
      // This is used to know, if we've made a new request so we can re-animate errors
      requestId: 0,
    };

    this.form = createForm({
      nickname: createFormField(
        {},
        [notBlank(labels.nickname)],
        () => !accountStore.loggedIn || configStore.reviews.anonymous
      ),
      email: createFormField(
        {},
        [
          minLength(labels.email, configStore.formValidation.email.minLength),
          isEmail(labels.email),
        ],
        () =>
          !accountStore.loggedIn &&
          (configStore.reviews.approvalRequired || couponsEnabled)
      ),
      text: createFormField({}, []),
      reviewRating: createFormField({}, [
        notBlank(labels.reviewRating),
        numberBetween(labels.reviewRating, 1, 5),
      ]),
      serviceRating: createFormField({}, [
        notBlank(labels.serviceRating),
        numberBetween(labels.serviceRating, 1, 5),
      ]),
    });
  }

  handleSubmit = async (e) => {
    const { product, accountStore, uiStore, configStore } = this.props;

    e.preventDefault();
    this.form.validate();

    if (this.form.valid) {
      this.setState({ requestState: RequestState.LOADING });
      await uiStore.recaptchaValidation.getCaptchaToken();

      if (uiStore.recaptchaValidation.isValid) {
        const values = this.form.getFieldValues();
        values.recaptcha = uiStore.recaptchaValidation.token;

        product
          .submitReview(values)
          .then((response) => {
            let successStatus;
            if (response.status === 202) {
              successStatus = ReviewStatus.PENDING;
            } else {
              accountStore.refreshCoupons();
              successStatus = ReviewStatus.APPROVED;
            }
            this.setState({
              requestState: RequestState.LOADED,
              successStatus,
            });
          })
          .catch((error) => {
            this.setState({
              requestState: RequestState.ERROR,
              requestId: this.state.requestId + 1,
              error: error,
            });
          });
      }

      configStore.recaptcha.enabled && uiStore.recaptchaValidation.resetCaptchaToken();
    }
  };

  field = (fieldName, props) => {
    const field = this.form.fields.get(fieldName);
    return (
      field.active && (
        <FormGroupField
          field={field}
          formName={this.formName}
          fieldName={fieldName}
          label={labels[fieldName]}
          {...props}
        />
      )
    );
  };

  textarea = (fieldName, props) => {
    const field = this.form.fields.get(fieldName);
    return (
      field.active && (
        <FormGroupField
          field={field}
          formName={this.formName}
          fieldName={fieldName}
          label={labels[fieldName]}
          type="textarea"
          {...props}
        />
      )
    );
  };

  starsControl = (fieldName, props) => {
    const field = this.form.fields.get(fieldName);
    return (
      field.active && (
        <div>
          <InteractiveStars
            amount={field.value || 0}
            onClick={field.setValue}
            {...props}
          />
          <FieldError field={field} />
        </div>
      )
    );
  };

  getErrorMessages = () => {
    const { error } = this.state;
    return get(error, 'response.data.messages');
  };

  renderForm = () => {
    const { accountStore, configStore, uiStore, couponsEnabled, intl } =
      this.props;
    const { requestState, error, requestId } = this.state;

    const leftColumnVisible =
      this.form.fields.get('nickname').active ||
      this.form.fields.get('email').active;

    const isLoading = requestState === RequestState.LOADING;

    return (
      <Form name={this.formName} noValidate onSubmit={this.handleSubmit}>
        <table className="ReviewForm__rating-controls">
          <tbody>
            <tr>
              <th>
                <FormattedMessage
                  {...globalTranslations.reviewProductRatingTitle}
                />
              </th>
              <td>{this.starsControl('reviewRating', {})}</td>
            </tr>
            <tr>
              <th>
                <FormattedMessage
                  {...globalTranslations.reviewServiceRatingTitle}
                />
              </th>
              <td>{this.starsControl('serviceRating', {})}</td>
            </tr>
          </tbody>
        </table>
        <Row>
          {leftColumnVisible && (
            <Col xs="12" md="4" xl="3">
              {this.field(
                'nickname',
                { autoComplete: 'nickname' },
                () => configStore.reviews.anonymous
              )}
              {this.form.fields.get('nickname').active && (
                <FormGroup className="ReviewForm__field-hint">
                  <FormattedMessage
                    id="review.nicknameFieldHint"
                    defaultMessage="A nickname of your choice that we'll show next to your review."
                  />
                </FormGroup>
              )}
              {this.field('email', { type: 'email', autoComplete: 'email' })}
              {this.form.fields.get('email').active && (
                <FormGroup className="ReviewForm__field-hint">
                  {couponsEnabled ? (
                    <FormattedMessage
                      id="review.emailAddressForCouponHint"
                      defaultMessage="The coupon details will be sent to this address. We will remove the email address from our registry after the coupon has been sent."
                    />
                  ) : (
                    <FormattedMessage
                      id="review.emailAddressForApprovalHint"
                      defaultMessage="We will notify you via this address after your review has been approved. We will remove the email address from our registry right after."
                    />
                  )}
                </FormGroup>
              )}
            </Col>
          )}
          <Col
            xs="12"
            md={leftColumnVisible ? 8 : 12}
            xl={{
              size: leftColumnVisible ? 7 : 12,
              offset: leftColumnVisible ? 1 : 0,
            }}
          >
            {this.textarea('text', {
              label: (
                <FormattedMessage
                  id="review.typeReviewHereSentence"
                  defaultMessage="Type your review here"
                />
              ),
            })}
            <FormGroup className="ReviewForm__field-hint">
              <FormattedMessage
                id="review.reviewHint"
                defaultMessage="By submitting your review, you agree to give us the permission to publish it on this website as well as on other sites and media. {storeName} reserves the right to not publish the review. By submitting, you agree to these terms."
                values={{
                  storeName: configStore.store.name,
                }}
              />
            </FormGroup>
            {error && get(error, 'response.status') !== 422 && (
              <ErrorHandler error={createErrorModel(error)} />
            )}
            {this.getErrorMessages() && (
              <Alert color="danger" key={requestId}>
                {this.getErrorMessages().map((message) => (
                  <div key={message}>{message}</div>
                ))}
              </Alert>
            )}
            <Button
              color="primary"
              disabled={
                this.form.valid === false ||
                uiStore.recaptchaValidation.isValid === false ||
                isLoading
              }
              type="submit"
              aria-label={intl.formatMessage({
                id: 'review.sendTitle',
                defaultMessage: 'Submit review',
              })}
            >
              <FormattedMessage
                id="review.sendTitle"
                defaultMessage="Submit review"
              />
            </Button>
            {configStore.recaptcha.enabled && (
              <RecaptchaLegalBanner />
            )}
          </Col>
        </Row>
      </Form>
    );
  };

  getSuccessMessage = () => {
    const { successStatus } = this.state;

    let message;
    if (successStatus === ReviewStatus.PENDING) {
      message = (
        <FormattedMessage
          id="review.successPending"
          defaultMessage="We have received your review. We check all reviews and you will get a message when yours is published."
        />
      );
    } else {
      message = (
        <FormattedMessage
          id="review.success"
          defaultMessage="Your product review was added successfully!"
        />
      );
    }

    return <Alert color="success">{message}</Alert>;
  };

  render() {
    const { successStatus } = this.state;

    return (
      <div className={this.formName}>
        {successStatus ? this.getSuccessMessage() : this.renderForm()}
      </div>
    );
  }
}

ReviewForm.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  languageStore: modelOf(LanguageStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  intl: intlShape.isRequired,
  couponsEnabled: PropTypes.bool.isRequired,
};

export default injectIntl(
  inject('accountStore', 'configStore', 'languageStore', 'uiStore')(ReviewForm)
);
