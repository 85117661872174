import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import classNames from 'classnames';

class DropdownNavLink extends Component {
  render() {
    const { className, ...rest } = this.props;
    return (
      <NavLink
        className={classNames('DropdownNavLink', className)}
        href="#"
        role="button"
        {...rest}
      />
    );
  }
}

export default DropdownNavLink;
