import { autorun } from 'mobx';
import {
  applySnapshot,
  flow,
  getEnv,
  resolveIdentifier,
  types,
} from 'mobx-state-tree';

import Section from '../models/Section';
import StatefulStore from '../models/StatefulStore';
import RequestState, { RequestStateType } from '../types/RequestState';
import SectionStatusType from '../types/SectionStatusType';
import { createErrorModel } from '../util/error';

const SectionStore = StatefulStore.named('SectionStore')
  .props({
    sections: types.optional(types.array(Section), []),
    sectionStates: types.optional(types.map(RequestStateType), {}),
    activeSection: types.maybeNull(types.reference(Section)),
  })
  .views((self) => ({
    get activeSections() {
      return self.sections.filter(
        (section) =>
          section.status !== SectionStatusType.TEST_MODE &&
          section.slug.length > 0
      );
    },
    get sectionIds() {
      return self.sections.map((section) => section.id);
    },
  }))
  .actions((self) => {
    return {
      afterAttach() {
        // Update API wrapper sectionId every time it changes
        autorun(() => {
          const sectionId = self.activeSection ? self.activeSection.id : null;
          getEnv(self).apiWrapper.setSectionId(sectionId);
        });
      },
      setup(sections) {
        self.sections = sections;
        self.setLoading(false);
      },
      loadSection: flow(function* loadSection(id) {
        self.sectionStates.set(id, RequestState.LOADING);

        try {
          const section = yield getEnv(self).apiWrapper.request(
            `sections/${id}`
          );
          const sectionModel = self.findSectionById(section.id);
          applySnapshot(sectionModel, section);
        } catch (e) {
          self.sectionStates.set(id, RequestState.ERROR);
          self.lastError = createErrorModel(e);
          throw e;
        }
        self.sectionStates.set(id, RequestState.LOADED);
      }),
      loadSections: flow(function* loadSections() {
        self.setLoading(true);

        try {
          self.sections = yield getEnv(self).apiWrapper.request(`sections`);
        } catch (e) {
          self.setError(e);
          throw e;
        }

        self.setLoading(false);
      }),
      setActiveSection(section) {
        if (self.activeSection !== section) {
          self.activeSection = section;
        }
      },
      getSectionDataForLanguage: flow(function* getSectionDataForLanguage(
        id,
        lang
      ) {
        let sectionData = null;
        try {
          sectionData = yield getEnv(self).apiWrapper.request(
            `sections/${id}`,
            {
              params: {
                lang,
              },
            }
          );
        } catch (e) {
          throw e;
        }

        return sectionData;
      }),
      findSectionById(sectionId) {
        return resolveIdentifier(Section, self.sections, sectionId);
      },
    };
  });

export default SectionStore;
