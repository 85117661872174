import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet-async';

import ConfigStore from '../../store/ConfigStore';
import { modelOf } from '../../prop-types';
import GoogleAnalyticsGTM from '../GoogleAnalyticsGTM';
import AccountStore from '../../store/AccountStore';
import CampaignCodeStore from '../../store/CampaignCodeStore';
import SectionStore from '../../store/SectionStore';
import CurrencyStore from '../../store/CurrencyStore';

@observer
export class GoogleTagManager extends Component {
  constructor(props) {
    super(props);
    const {
      accountStore,
      campaignCodeStore,
      configStore,
      currencyStore,
      sectionStore,
      countryStore,
    } = props;

    const gtm = new GoogleAnalyticsGTM(
      accountStore,
      campaignCodeStore,
      configStore,
      currencyStore,
      sectionStore,
      countryStore
    );

    gtm.setup();
  }

  render() {
    const { configStore } = this.props;

    return (
      <div>
        <Helmet>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              originalLocation: document.location.protocol + '//' +
              document.location.hostname +
              document.location.pathname +
              document.location.search
            });
            `}
          </script>
          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[1],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${configStore.gtm.containerId}');`}
          </script>
        </Helmet>
        <noscript>
          <iframe
            title="GTM"
            src={`https://www.googletagmanager.com/ns.html?id=${configStore.gtm.containerId}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      </div>
    );
  }
}

GoogleTagManager.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  campaignCodeStore: modelOf(CampaignCodeStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
};

export default inject(
  'accountStore',
  'campaignCodeStore',
  'configStore',
  'currencyStore',
  'productStore',
  'sectionStore',
  'countryStore'
)(GoogleTagManager);
