import { types } from 'mobx-state-tree';

const CampaignCodeType = {
  NOT_IN_USE: 'NOT_IN_USE',
  PRODUCT: 'PRODUCT',
  CUSTOMER_GROUP: 'CUSTOMER_GROUP',
  COUPON: 'COUPON',
  GIFT_VOUCHER: 'GIFT_VOUCHER'
};

export default CampaignCodeType;

export const CampaignCodeTypeType = types.enumeration(
  'CampaignCodeType',
  Object.values(CampaignCodeType)
);
