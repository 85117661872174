import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import AccountModalTab from '../../../types/AccountModalTab';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';

@observer
export class ClientLoginLink extends Component {
  toggle = () => {
    this.props.accountStore.accountModal.setTab(AccountModalTab.CHANGE_CLIENT);
    this.props.accountStore.accountModal.toggle();
  };

  render() {
    return (
      <span className="ClientLoginLink" onClick={this.toggle}>
        <FormattedMessage {...globalTranslations.accountChangeClientSentence} />
      </span>
    );
  }
}

ClientLoginLink.propTypes = {
  accountStore: modelOf(AccountStore).isRequired
};

export default inject('accountStore')(ClientLoginLink);
