import React, { Component } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import NavigationEntity from '../../../types/NavigationEntity';
import NavigationEntityItem from '../../navigation/NavigationEntityItem';
import NavigationDropdownMenu from '../../common/NavigationDropdownMenu';
import NavigationMenuRowDropdownItem from '../NavigationMenuRowDropdownItem';

@observer
class NavigationMenuRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMenuId: null,
    };
  }

  setActiveMenuId = (id) => {
    this.setState({
      activeMenuId: id,
    });
  };

  debouncedHoverMenu = debounce((callback) => {
    if (callback) {
      callback();
    }
  }, 200);

  render() {
    return (
      <Nav navbar className="NavigationMenuRow">
        {this.props.items.map((item) =>
          item.children && item.children.length > 0 ? (
            <NavigationDropdownMenu
              debouncer={this.debouncedHoverMenu}
              isOpen={this.state.activeMenuId === item.id}
              setActiveId={this.setActiveMenuId}
              entity={item}
              label={item.label}
              key={item.id}
              ariaLabel={item.accessible_text}
            >
              {item.children.map((subItem) => (
                <NavigationMenuRowDropdownItem
                  item={subItem}
                  key={subItem.id}
                />
              ))}
            </NavigationDropdownMenu>
          ) : (
            <NavItem key={item.id}>
              <NavigationEntityItem entity={item} />
            </NavItem>
          )
        )}
      </Nav>
    );
  }
}

NavigationMenuRow.propTypes = {
  items: PropTypes.arrayOf(NavigationEntity).isRequired,
};

export default NavigationMenuRow;
