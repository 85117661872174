import React from 'react'
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { inject } from 'mobx-react';

import { modelOf } from '../../../../prop-types';
import ProposalStore from '../../../../store/ProposalStore';
import AccountStore from '../../../../store/AccountStore';
import Price from '../../../product/Price';
import globalTranslations from '../../../../i18n/globalTranslations';
import Icon from '../../../common/Icon';
import RequestState from '../../../../types/RequestState';

const NavigationProposalText = ({ accountStore, proposalStore }) => {
  const withTax = accountStore.showPricesWithTax;

  const getTotalPrice = () => {
    const { cart } = proposalStore;

    if (!cart) {
      return null;
    }

    const priceTotal = cart?.total && cart.total.getPrice(withTax);

    return priceTotal || null;
  };

  const renderPrice = () => {
    const price = getTotalPrice();
    return price !== null ? <Price price={price} /> : null;
  };

  const renderProposalText = () => {
    const productCount =
      (proposalStore.cart && proposalStore.cart.number_of_products) || 0;
    let text;

    switch (proposalStore.state) {
      case RequestState.LOADED:
        if (productCount === 0) {
          text = (
            <FormattedMessage {...globalTranslations.proposalEmptySentence} />
          );
        } else {
          text = (
            <span>
              <FormattedMessage
                {...globalTranslations.itemCount}
                values={{
                  count: productCount,
                }}
              />{' '}
              {renderPrice()}
            </span>
          );
        }
        break;
      case RequestState.LOADING:
      default:
        text = <FormattedMessage {...globalTranslations.loading} />;
        break;
    }

    return text;
  };

    return (
      <div className={classNames('NavigationProposal', 'NavigationProposalText')}>
        <Icon name="list" />
        <span className={classNames('NavigationProposal__proposal-text')}>
          <span className="NavigationProposal__proposal-text-desktop">
            {renderProposalText()}
          </span>
          <span className="NavigationProposal__proposal-text-mobile">
            {renderPrice()}
          </span>
        </span>
      </div>
    );
}

NavigationProposalText.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  proposalStore: modelOf(ProposalStore).isRequired,
}

export default inject('accountStore', 'proposalStore')(NavigationProposalText)
