import { types } from 'mobx-state-tree';

const NavigationEntityPosition = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export default NavigationEntityPosition;

export const NavigationEntityPositionType = types.enumeration(
  'NavigationEntityPosition',
  Object.values(NavigationEntityPosition)
);
