import { get } from 'lodash';

import {
  GiosgEventNames,
  UniversalAnalyticsEventNames,
} from '../AnalyticsEventNames';
import UserTokenType from '../../types/UserTokenType';

export default class GiosgEventHandler {
  constructor(accountStore) {
    this.accountStore = accountStore;
    this.registerAnalyticsEventListener();
  }

  registerAnalyticsEventListener = () => {
    // Bail if we are in SSR.
    if (typeof document === 'undefined') {
      return null;
    }

    document.addEventListener('analytics', this.handleEvents, true);
  };

  maybePushVisitorInfo = (accountStore) => {
    if (!accountStore || !accountStore.account) {
      return;
    }
    accountStore.account.tokens.forEach((token) => {
      if (token.type === UserTokenType.GIOSG && token.value.length > 0) {
        this.pushEvent('visitor', 'submit', token.value, token.algorithm, true);
      }
    });
  };

  handleEvents = (event) => {
    switch (event.detail.name) {
      case GiosgEventNames.cartStatus:
        this.cartStatus(event.detail.payload);
        break;
      case GiosgEventNames.clearCart:
        this.clearCart(event.detail.payload);
        break;
      case UniversalAnalyticsEventNames.purchase:
        this.freeze(event.detail.payload);
        break;
      default:
    }
  };

  /**
   * @param {Object} cart Cart content data.
   */
  cartStatus({ cart }) {
    const cartProducts = get(cart.cart, 'products', []);
    const products = cartProducts
      .filter(
        (product) =>
          product.name && product.final_unit_price && product.quantity
      )
      .map((product) => {
        const price = product.final_unit_price
          ? product.final_unit_price.toFixed(2)
          : 0;
        return {
          name: product.name,
          price: price,
          quantity: product.totalQuantity,
          description: product.short_description,
          category: product.category_name,
          product_number: product.product_id,
        };
      });
    this.maybePushVisitorInfo(this.accountStore);
    this.pushEvent('shoppingCart', 'submit', products);
  }

  /**
   * Freeze shopping cart information.
   */
  freeze() {
    this.pushEvent('shoppingCart', 'freeze');
    setTimeout(() => {
      this.pushEvent('shoppingCart', 'submit', []);
    }, 1000);
  }

  /**
   * Clear all shopping cart information.
   */
  clearCart() {
    this.pushEvent('shoppingCart', 'clearCart');
  }

  /**
   * @param {string} event Event name.
   * @param {string} method Method name.
   * @param {object} data Object containing data for the event.
   * @param rest
   */
  pushEvent = (event, method, data = {}, ...rest) => {
    if (window._giosg && typeof window._giosg === 'function') {
      window._giosg(event, method, data, ...rest);
    }
  };

  /**
   * @param {number} priority
   * Visitor priority controls displayed bubble color in Giosg console.
   */

  setVisitorPriority = (priority) => {
    const giosgClient = window.GiosgClient;
    if (giosgClient) {
      giosgClient.setVisitorPriority(priority);
    }
  };
}
