import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Discount from '../Discount';

const DiscountSign = ({
  percentage,
  withLabel,
  withUpTo,
  inverted,
  className,
}) => {
  if (!percentage) {
    return null;
  }

  return (
    <div
      className={classNames('DiscountSign', className, {
        'DiscountSign--inverted': inverted,
      })}
    >
      <Discount
        percentage={percentage}
        withLabel={withLabel}
        withUpTo={withUpTo}
      />
    </div>
  );
};

DiscountSign.propTypes = {
  percentage: PropTypes.number,
  withLabel: PropTypes.bool,
  withUpTo: PropTypes.bool,
  inverted: PropTypes.bool,
  className: PropTypes.string,
};

export default DiscountSign;
