import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import ProductSlider from '../ProductSlider';
import Product from '../../../models/Product';
import RequestState from '../../../types/RequestState';
import { ViewBreakpointMinWidth } from '../../../types/ViewBreakpoint';
import ConfigStore from '../../../store/ConfigStore';
import Analytics from '../../../analytics/Analytics';
import MissingProductLoader from '../../product/MissingProductLoader';

const AlsoPurchasedProductsSlider = ({
  configStore,
  analytics,
  product,
  variant,
  overrideSettings,
}) => {
  const SLIDER_SETTINGS = {
    cartModal: {
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: ViewBreakpointMinWidth.MD,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: ViewBreakpointMinWidth.SM,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    },
    default: {},
  };

  const CARD_SETTINGS = {
    cartModal: {
      hideDescription: true,
      hideProperties: true,
      requireCanonicalLink: true,
      productImageSettings: {
        overrideConfigStoreAspectRatio: true,
        customAspectRatio: '100',
      },
    },
    default: {},
  };

  const listId = 'also_purchased_products';
  const listName = 'Also Purchased Products';

  useEffect(() => {
    loadProductIds();
  }, []);

  const loadProductIds = () => {
    if (product.alsoPurchasedState === RequestState.NONE) {
      product.loadAlsoPurchased(product.id).catch((e) => {
        if (e.response && e.response.status !== 404) {
          console.error(e);
        }
      });
    }
  };

  const getSliderSettings = () => {
    if (SLIDER_SETTINGS[variant]) {
      return { ...SLIDER_SETTINGS[variant], ...overrideSettings };
    }

    return { ...SLIDER_SETTINGS['default'], ...overrideSettings };
  };

  const getCardSettings = () => {
    if (CARD_SETTINGS[variant]) {
      return { ...CARD_SETTINGS[variant], ...overrideSettings };
    }

    return { ...CARD_SETTINGS['default'], ...overrideSettings };
  };

  const renderContent = (products) => {
    if (products.length === 0) {
      return null;
    }

    return (
      <div className="AlsoPurchasedProductsSlider">
        <h3 className="AlsoPurchasedProductSlider__title">
          <FormattedMessage
            id="product.alsoPurchasedProducts"
            defaultMessage="Customers who purchased this item, also purchased"
          />
        </h3>
        <ProductSlider
          settings={getSliderSettings()}
          cardSettings={getCardSettings()}
          listId={listId}
          name={listName}
          products={products}
          disableImpressions={configStore.analytics.ga4.enabled}
        />
      </div>
    );
  };

  const sendProductImpressions = (alsoPurchasedProducts) => {
    const productList = alsoPurchasedProducts.map(
      (alsoPurchasedProduct, index) => ({
        product: alsoPurchasedProduct,
        position: index + 1,
      })
    );

    analytics.productImpressions(listName, productList, listId);
  };

  const onLoadHandler = (products) => {
    configStore.analytics.ga4.enabled && sendProductImpressions(products);
  };

  return (
    <MissingProductLoader
      ids={product.alsoPurchasedIds.slice()}
      onLoadSuccess={onLoadHandler}
    >
      {(products) => renderContent(products)}
    </MissingProductLoader>
  );
};

AlsoPurchasedProductsSlider.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  product: modelOf(Product).isRequired,
  variant: PropTypes.string.isRequired,
  overrideSettings: PropTypes.object,
};

AlsoPurchasedProductsSlider.defaultProps = {
  variant: 'default',
  overrideSettings: {},
};

export default inject(
  'configStore',
  'analytics'
)(observer(AlsoPurchasedProductsSlider));
