import React, { Fragment } from 'react';
import { observer, PropTypes as MobxPropTypes, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { uniqueId } from 'lodash';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import ProductCard from '../../product/ProductCard';
import ProductListStyle from '../../../types/ProductListStyle';
import ProductImpressions from '../../product/ProductImpressions';
import CategoryHeader from '../CategoryHeader';
import ConfigStore from '../../../store/ConfigStore';

const CardWrapper = ({ children }) => (
  <Col xs={6} sm={4} md={3} lg={3} xl={'1-5'}>
    {children}
  </Col>
);

const LargeCardWrapper = ({ children }) => (
  <Col xs={6} sm={6} md={4} lg={4} xl={3}>
    {children}
  </Col>
);

const CategoryNameWrapper = ({ children }) => <Col xs={12}>{children}</Col>;

const ProductList = ({
  configStore,
  products,
  name,
  listId,
  completeListingLink,
  productParams,
  cardSettings,
  showCategoryHeaders,
}) => {
  let Wrapper;
  let CategoryWrapper = CategoryNameWrapper;
  let lazyLoadLimit;
  let productCardClassName;
  let productMainCategoryId = null;

  const style = configStore.productList.style;

  switch (style) {
    case ProductListStyle.CARD_LARGE:
      Wrapper = LargeCardWrapper;
      lazyLoadLimit = 4;
      productCardClassName = 'ProductCard--large';
      break;
    case ProductListStyle.CARD:
    default:
      Wrapper = CardWrapper;
      lazyLoadLimit = 5;
      productCardClassName = 'ProductCard--normal';
  }

  const productCols = products.map((product, index) => {
    const position = index + 1;
    const mergedCardSettings = {
      productListView: true,
      product,
      position,
      lazyLoading: position > lazyLoadLimit,
      listId,
      listName: name,
      productCardClassName,
      modelMargin: true,
      productParams,
      ...cardSettings,
    };

    const productCardComponent = (
      <Wrapper key={product.id}>
        <ProductCard {...mergedCardSettings} />
      </Wrapper>
    );

    if (
      productMainCategoryId !== product.main_category_id &&
      showCategoryHeaders &&
      product.mainCategory
    ) {
      productMainCategoryId = product.main_category_id;

      return (
        <Fragment key={uniqueId(product.main_category_id)}>
          <CategoryWrapper key={uniqueId(product.main_category_id)}>
            <CategoryHeader breadcrumb={product.mainCategory.breadcrumbs} />
          </CategoryWrapper>
          {productCardComponent}
        </Fragment>
      );
    }

    return productCardComponent;
  });

  const productsArray = products.slice();
  if (completeListingLink && productCols.length > 0) {
    productCols[productCols.length - 1] = (
      <Wrapper key="_completeListingLink">{completeListingLink}</Wrapper>
    );
    // Drop the last product impression because it is not shown.
    productsArray.pop();
  }

  return (
    <div className="ProductList">
      <ProductImpressions
        listId={listId}
        listName={name}
        products={productsArray}
      />
      <Row>{productCols}</Row>
    </div>
  );
};

ProductList.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  products: MobxPropTypes.observableArrayOf(modelOf(Product)),
  listId: PropTypes.string,
  name: PropTypes.string,
  completeListingLink: PropTypes.node,
  showCategoryHeaders: PropTypes.bool,
};

export default inject('configStore')(observer(ProductList));
