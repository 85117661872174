import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import ProductStore from '../../../store/ProductStore';
import ProductList from '../ProductList';
import { paramsToQueryIdentifier } from '../../../util/query';
import ProductListTools from '../ProductListTools';
import ConfigStore from '../../../store/ConfigStore';
import SimpleProductList from '../SimpleProductList';
import UIStore from '../../../store/UIStore';

const TabProductList = ({
  configStore,
  uiStore,
  productStore,
  searchParams,
  listId,
  name,
  listComponent,
  completeListingLink,
  preloadOnly,
  singleRowMode,
  requireCanonicalLink,
  ...rest
}) => {
  const getQueryIdentifier = () => paramsToQueryIdentifier(searchParams);

  const getCurrentSearchResult = () =>
    productStore.productQueryResults.get(getQueryIdentifier());

  if (!productStore || !searchParams) {
    return null;
  }

  const result = getCurrentSearchResult();
  return (
    <div className="TabProductList">
      {result && !preloadOnly && (
        <div className="TabProductList__active-filter-row">
          <ProductListTools showSort={false} countOfProducts={result.total} />
          {completeListingLink}
        </div>
      )}
      <SimpleProductList
        searchParams={searchParams}
        listId={listId}
        name={name}
        listComponent={listComponent}
        completeListingLink={
          result && result.total > result.data.length
            ? completeListingLink
            : null
        }
        preloadOnly={preloadOnly}
        singleRowMode={singleRowMode}
        requireCanonicalLink={requireCanonicalLink}
        {...rest}
      />
    </div>
  );
};

TabProductList.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  searchParams: PropTypes.object.isRequired,
  listId: PropTypes.string,
  name: PropTypes.string,
  listComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  completeListingLink: PropTypes.node,
  preloadOnly: PropTypes.bool,
  singleRowMode: PropTypes.bool,
  requireCanonicalLink: PropTypes.bool,
};

TabProductList.defaultProps = {
  listComponent: ProductList,
  preloadOnly: false,
  disableQuickSearch: false,
  singleRowMode: false,
  requireCanonicalLink: false,
};

export default inject(
  'productStore',
  'configStore',
  'uiStore'
)(observer(TabProductList));
