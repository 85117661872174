import React, { Fragment } from 'react';
import { NavItem } from 'reactstrap';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';

import NavigationEntityItem from '../../navigation/NavigationEntityItem';
import SectionStore from '../../../store/SectionStore';
import RouteService from '../../../services/RouteService';
import { modelOf } from '../../../prop-types';
import LinkType from '../../../types/LinkType';
import CustomNavigationLinkLocationType from '../../../types/CustomNavigationLinkLocation';
import CustomNavigationLinkStore from '../../../store/CustomNavigationLinkStore';

const NavigationCustomEntityItem = ({
  customNavLink,
  sectionStore,
  routeService,
  customNavigationLinkStore,
  index,
}) => {
  const getCustomNavItems = (navLink, index) => {
    const linkClassName = 'NavigationCustomEntityItem__link';
    const subLinks = customNavigationLinkStore.findChildLinks(navLink.id);
    return (
      <Fragment key={index}>
        {subLinks &&
          subLinks.map((subNavLink, subIndex) => {
            return (
              subNavLink.location === CustomNavigationLinkLocationType.ABOVE &&
              getCustomNavItems(subNavLink, subIndex)
            );
          })}
        <NavItem key={index} className="NavigationCustomEntityItem__item">
          <NavigationEntityItem
            entity={convertCustomNavigationLinkToEntityItem(navLink)}
            type={navLink.link_type}
            className={linkClassName}
          />
        </NavItem>
        {subLinks &&
          subLinks.map(
            (subNavLink, subIndex) =>
              subNavLink.location === CustomNavigationLinkLocationType.BELOW &&
              getCustomNavItems(subNavLink, subIndex)
          )}
      </Fragment>
    );
  };

  const convertCustomNavigationLinkToEntityItem = (customNavLink) => {
    return {
      id: customNavLink.id,
      label: <span>{customNavLink.name}</span>,
      path: getCategoryListPath(customNavLink),
      type: customNavLink.link_type,
    };
  };

  const getCategoryListPath = (customNavLink) => {
    if (customNavLink.link_type === LinkType.EXTERNAL_LINK) {
      return customNavLink.link;
    }

    if (customNavLink.link_type === LinkType.INTERNAL_LINK) {
      return routeService.getPath(customNavLink.link);
    }

    return routeService.getPath(customNavLink.link, sectionStore.activeSection);
  };

  return getCustomNavItems(customNavLink, index);
};

NavigationCustomEntityItem.propTypes = {
  customNavLink: PropTypes.object.isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  customNavigationLinkStore: modelOf(CustomNavigationLinkStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  index: PropTypes.number.isRequired,
};

export default inject(
  'routeService',
  'sectionStore',
  'customNavigationLinkStore'
)(NavigationCustomEntityItem);
