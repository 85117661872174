import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import Category from '../../../models/Category';
import RouteService from '../../../services/RouteService';

@observer
export class CategoryLink extends Component {
  render() {
    const { routeService, category, className, children } = this.props;
    return (
      <Link
        className={classNames('CategoryLink', className)}
        to={routeService.getPath(category.path)}
      >
        {children || category.name}
      </Link>
    );
  }
}

CategoryLink.propTypes = {
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  category: modelOf(Category).isRequired,
  className: PropTypes.string
};

export default inject('routeService')(CategoryLink);
