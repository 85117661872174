import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes, observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import Category from '../../../models/Category';
import RouteService from '../../../services/RouteService';
import NavigationCategoryButton from '../NavigationCategoryButton';

const NavigationSimpleCategoryList = ({ categories }) => {
  const getCategories = () => {
    return categories.map((category) => {
      return <NavigationCategoryButton key={category.id} category={category} />;
    });
  };

  return (
    <div className="NavigationSimpleCategoryList">
      <div className="NavigationSimpleCategoryList__header">
        <FormattedMessage
          id="navigation.browseCategories"
          defaultMessage="Browse categories"
        />
      </div>
      <div className="NavigationSimpleCategoryList__categories">
        {getCategories()}
      </div>
    </div>
  );
};

NavigationSimpleCategoryList.propTypes = {
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  categories: PropTypes.oneOfType([
    MobxPropTypes.observableArrayOf(modelOf(Category)).isRequired,
    PropTypes.arrayOf(modelOf(Category)).isRequired,
  ]),
};

export default inject('routeService')(observer(NavigationSimpleCategoryList));
