import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import Icon from '../../common/Icon';

class ActiveFilterItem extends Component {
  onChange = () => {
    const { filter, isSelected, onAccept } = this.props;
    this.filterContainer.toggleOpen();
    onAccept(filter, !isSelected);
  };

  render() {
    const { iconMessage, value, removeFilter } = this.props;
    return (
      <Button
        className="ActiveFilterItem"
        size="sm"
        color="link"
        onClick={removeFilter}
        title={iconMessage}
      >
        {value}
        <Icon name="times" />
      </Button>
    );
  }
}

ActiveFilterItem.propTypes = {
  value: PropTypes.string.isRequired,
  iconMessage: PropTypes.string,
  removeFilter: PropTypes.func.isRequired
};

export default ActiveFilterItem;
