const AdPages = {
  CAMPAIGN_PRODUCTS: 'CAMPAIGN_PRODUCTS',
  CATEGORY_PAGE: 'CATEGORY_PAGE',
  CUSTOMER_PRODUCTS: 'CUSTOMER_PRODUCTS',
  FRONT_PAGE: 'FRONT_PAGE',
  MANUFACTURER_PAGE: 'MANUFACTURER_PAGE',
  NEW_PRODUCTS: 'NEW_PRODUCTS',
  NOT_FOUND_PAGE: 'NOT_FOUND_PAGE',
  POPULAR_PRODUCTS: 'POPULAR_PRODUCTS',
  PRODUCT_PAGE: 'PRODUCT_PAGE',
  RECOMMENDED_PRODUCTS: 'RECOMMENDED_PRODUCTS',
  SECTION_FRONT_PAGE: 'SECTION_FRONT_PAGE',
  SOLD_PRODUCTS: 'SOLD_PRODUCTS',
};

export default AdPages;
