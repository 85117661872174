import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';
import { inject, observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import Stars from '../Stars';
import ReviewModel from '../../../models/Review';
import globalTranslations from '../../../i18n/globalTranslations';
import ConfigStore from '../../../store/ConfigStore';
import WysiwygContent from '../../common/WysiwygContent';

@observer
export class Review extends Component {
  render() {
    const { review, configStore } = this.props;

    const reviewAddDate = DateTime.fromISO(review.date_added);

    return (
      <div className="Review">
        <div className="Review__content">
          <div className="Review__title">
            <span>
              {review.customers_name},{' '}
              <span>{reviewAddDate.toLocaleString()}</span>
            </span>
          </div>
          <div className="Review__text">
            <WysiwygContent html={review.description.reviews_text} />
          </div>

          <table className="Review__ratings">
            <tbody>
              <tr>
                <th>
                  <FormattedMessage
                    {...globalTranslations.reviewProductRatingTitle}
                  />
                </th>
                <td>
                  <Stars amount={review.reviews_rating} />
                </td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage
                    {...globalTranslations.reviewServiceRatingTitle}
                  />
                </th>
                <td>
                  <Stars amount={review.service_rating} />
                </td>
              </tr>
            </tbody>
          </table>
          {review.description.admin_reply && (
            <div className="Review__admin-reply">
              <div className="Review__admin-reply-title">
                <FormattedMessage
                  id="review.staffReplySentence"
                  defaultMessage="{name} responds:"
                  values={{ name: configStore.store.name }}
                />
              </div>
              <div className="Review__admin-reply-content">
                <WysiwygContent html={review.description.admin_reply} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Review.propTypes = {
  review: modelOf(ReviewModel).isRequired,
  configStore: modelOf(ConfigStore).isRequired
};

export default inject('configStore')(Review);
