import { types } from 'mobx-state-tree';

import FieldType, { FieldTypeType } from '../types/FieldType';

/*
 * A FormField
 */
const FormField = types
  .model('FormField', {
    // The value of the field
    value: types.maybeNull(
      types.union(
        types.string,
        types.number,
        types.array(types.string),
        types.array(types.number),
        types.boolean
      )
    ),
    type: types.optional(FieldTypeType, FieldType.NORMAL),
    // If true, the "valid" and "error" properties will change every time the
    // value changes.
    shouldValidate: types.optional(types.boolean, false),
    // External index to map the field event to certain source data.
    externalIndex: types.maybeNull(types.number),
  })
  .extend((self) => {
    let validators, isActive;
    return {
      views: {
        get errors() {
          const errors = [];
          if (self.shouldValidate && validators) {
            validators.forEach((validator) => {
              const error = validator(self.value);
              if (error) {
                errors.push(error);
              }
            });
          }
          return errors;
        },
        get valid() {
          return self.shouldValidate ? self.errors.length === 0 : null;
        },
        get active() {
          return self.isActive();
        },
        isActive() {
          return isActive ? isActive() : true;
        },
      },
      actions: {
        setValue(value) {
          self.value = value;
        },
        setShouldValidate(value) {
          self.shouldValidate = value;
        },
        setActive(value) {
          self.active = value;
        },
        /**
         * @param {function[]} newValidators
         */
        setValidators(newValidators) {
          validators = newValidators;
        },
        hasValidators() {
          return Array.isArray(validators) && validators.length > 0;
        },
        /**
         * @param {function} newIsActive
         */
        setIsActive(newIsActive) {
          isActive = newIsActive;
        },
      },
    };
  });
export default FormField;

export function createFormField(field = {}, validators, isActive) {
  const fieldModel = FormField.create(field);
  if (validators) {
    fieldModel.setValidators(validators);
  }
  if (isActive) {
    fieldModel.setIsActive(isActive);
  }
  return fieldModel;
}
