import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import usePaginator from '../../../hooks/common/usePaginator';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import generatePath from '../../../util/generatePath';
import Icon from '../Icon';

const PaginationNavLink = (props) => {
  return <PaginationLink tag={Link} {...props} />;
};

const Paginator = ({
  configStore,
  pathTemplate,
  currentPage,
  onClick,
  lastPage,
  pagesToShow,
  label,
  intl,
}) => {
  const pagesToHide = 2;

  const {
    previousPage,
    nextPage,
    leftMorePages,
    rightMorePages,
    middlePages,
    lastMiddlePage,
  } = usePaginator({
    lastPage,
    pagesToShow,
    currentPage,
    pagesToHide,
  });

  const { isRounded, isBoxed } = configStore.layout.corners;

  const getPagePath = (page) => {
    return generatePath(pathTemplate, { page });
  };

  const getPaginationItem = (page) => {
    return (
      <PaginationItem active={currentPage === page} key={page}>
        <PaginationNavLink to={getPagePath(page)} onClick={onClick}>
          {page}
        </PaginationNavLink>
      </PaginationItem>
    );
  };

  const getMoreItem = () => {
    return (
      <PaginationItem disabled>
        <PaginationLink>...</PaginationLink>
      </PaginationItem>
    );
  };

  const getLeftMoreItems = () => {
    if (!middlePages.length) {
      return null;
    }

    const firstMiddlePage = middlePages[0] ?? 0;

    if (leftMorePages) {
      return getMoreItem();
    } else if (firstMiddlePage <= pagesToHide) {
      return null;
    } else {
      return getPaginationItem(2);
    }
  };

  const getMiddlePages = () => {
    if (!middlePages.length) {
      return;
    }

    return middlePages.map((page) => getPaginationItem(page));
  };

  const getRightMoreItems = () => {
    if (!middlePages.length) {
      return null;
    }

    if (rightMorePages) {
      return getMoreItem();
    } else if (lastMiddlePage < pagesToHide) {
      return null;
    } else {
      return getPaginationItem(lastPage - 1);
    }
  };

  const firstPaginationItem = getPaginationItem(1);
  const leftMoreItems = getLeftMoreItems();
  const middleItems = getMiddlePages();
  const rightMoreItems = getRightMoreItems();
  const lastPaginationItem = getPaginationItem(lastPage);

  return (
    <Pagination
      aria-label={label || 'Pagination'}
      className="Paginator"
      size="lg"
    >
      <PaginationItem
        disabled={currentPage === 1}
        className={classNames('Paginator__nav', 'Paginator__prev-nav')}
      >
        <PaginationNavLink
          first
          aria-label={intl.formatMessage(globalTranslations.previousTitle)}
          to={getPagePath(previousPage)}
          onClick={onClick}
          className={classNames({
            'Paginator__prev-nav--rounded': isRounded,
            'Paginator__prev-nav--box': isBoxed,
          })}
        >
          <Icon name="chevron-left" />
          <span
            className={classNames(
              'Paginator__nav-text',
              'Paginator__prev-nav-text'
            )}
          >
            <FormattedMessage {...globalTranslations.previousTitle} />
          </span>
        </PaginationNavLink>
      </PaginationItem>
      {firstPaginationItem}
      {leftMoreItems}
      {middleItems}
      {rightMoreItems}
      {lastPaginationItem}
      <PaginationItem
        disabled={currentPage >= lastPage}
        className={classNames('Paginator__nav', 'Paginator__next-nav')}
      >
        <PaginationNavLink
          last
          aria-label={intl.formatMessage(globalTranslations.nextTitle)}
          to={getPagePath(nextPage)}
          onClick={onClick}
          className={classNames({
            'Paginator__next-nav--rounded': isRounded,
            'Paginator__next-nav--box': isBoxed,
          })}
        >
          <span
            className={classNames(
              'Paginator__nav-text',
              'Paginator__next-nav-text'
            )}
          >
            <FormattedMessage {...globalTranslations.nextTitle} />
          </span>
          <Icon name="chevron-right" />
        </PaginationNavLink>
      </PaginationItem>
    </Pagination>
  );
};

Paginator.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  intl: intlShape.isRequired,
  pathTemplate: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  pagesToShow: PropTypes.number.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

Paginator.defaultProps = {
  pagesToShow: 5,
};

export default injectIntl(inject('configStore')(observer(Paginator)));
