import { types } from 'mobx-state-tree';

const InstantShoppingPickupOption = types.model('InstantShoppingPickupOption', {
  address: types.maybeNull(types.string),
  distance: types.maybeNull(types.number),
  external_info: types.optional(
    types.array(
      types.model({
        key: types.maybeNull(types.string),
        value: types.maybeNull(types.string)
      })
    ),
    []
  ),
  location_info: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  opening_hours: types.maybeNull(types.string),
  unique_id: types.maybeNull(types.identifier)
});

export default InstantShoppingPickupOption;
