import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import usePaginator from '../../../hooks/common/usePaginator';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import generatePath from '../../../util/generatePath';
import Icon from '../Icon';

const MobilePaginator = ({
  configStore,
  intl,
  pathTemplate,
  currentPage,
  label,
  onClick,
  lastPage,
}) => {
  const { previousPage, nextPage } = usePaginator({
    lastPage,
    currentPage,
  });

  const { isRounded, isBoxed } = configStore.layout.corners;
  const prevButtonDisabled = currentPage === 1;
  const nextButtonDisabled = currentPage >= lastPage;

  const getPagePath = (page) => {
    return generatePath(pathTemplate, { page });
  };

  return (
    <div className="MobilePaginator" aria-label={label || 'MobilePaginator'}>
      <span className="MobilePaginator__pages">
        <FormattedMessage
          id="mobilePaginator.pages"
          defaultMessage="{currentPage} of {pages, plural, one {# page} other {# pages}}"
          values={{
            currentPage: (
              <span
                className={classNames('MobilePaginator__current-page', {
                  'MobilePaginator__current-page--rounded': isRounded,
                  'MobilePaginator__current-page--box': isBoxed,
                })}
              >
                {currentPage}
              </span>
            ),
            pages: lastPage,
          }}
        />
      </span>
      <span
        className={classNames('MobilePaginator__nav-buttons', {
          'MobilePaginator__nav-buttons--rounded': isRounded,
          'MobilePaginator__nav-buttons--box': isBoxed,
        })}
      >
        <Link
          className={classNames(
            'MobilePaginator__nav',
            'MobilePaginator__prev-nav'
          )}
          aria-label={intl.formatMessage(globalTranslations.previousTitle)}
          to={getPagePath(previousPage)}
          onClick={onClick}
        >
          <Button color="secondary" disabled={prevButtonDisabled}>
            <Icon name="chevron-left" />
          </Button>
        </Link>
        <Link
          className={classNames(
            'MobilePaginator__nav',
            'MobilePaginator__next-nav'
          )}
          aria-label={intl.formatMessage(globalTranslations.nextTitle)}
          to={getPagePath(nextPage)}
          onClick={onClick}
        >
          <Button color="secondary" disabled={nextButtonDisabled}>
            <Icon name="chevron-right" />
          </Button>
        </Link>
      </span>
    </div>
  );
};

MobilePaginator.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  intl: intlShape.isRequired,
  pathTemplate: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default injectIntl(inject('configStore')(observer(MobilePaginator)));
