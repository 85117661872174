import { autorun } from 'mobx';
import _ from 'lodash';

import {
  UniversalAnalyticsEventNames,
  CommonEventNames,
  GoogleAnalytics4EventNames,
} from '../AnalyticsEventNames';
import ProductClass from '../../types/ProductClass';
import CommonPage from '../../types/CommonPage';

const VIEWLIST_IGNORED_PAGES = ['HomePage', 'ProductPage'];

export default class CriteoEventHandler {
  constructor(merchantId, accountStore) {
    if (!window.criteo_q) {
      window.criteo_q = [];
    }
    const deviceType = /iPad/.test(navigator.userAgent)
      ? 't'
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
          navigator.userAgent
        )
      ? 'm'
      : 'd';

    this.pushEvent('setAccount', { account: merchantId });
    this.pushEvent('setSiteType', { type: deviceType });

    // Push new setEmail event whenever customer email changes.
    autorun(() => {
      const customerEmail = _.get(
        accountStore,
        'account.personal_info.email',
        ''
      );
      this.pushEvent('setEmail', { email: customerEmail });
    });

    document.addEventListener('analytics', this.handleEvents, true);
  }

  handleEvents = (event) => {
    switch (event.detail.name) {
      case CommonEventNames.setPage:
        this.setPage(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.viewItemList:
      case UniversalAnalyticsEventNames.productImpressions:
        this.productImpressions(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.viewItem:
      case UniversalAnalyticsEventNames.productDetail:
        this.productDetail(event.detail.payload);
        break;
      case GoogleAnalytics4EventNames.purchase:
      case UniversalAnalyticsEventNames.purchase:
        this.purchase(event.detail.payload);
        break;
      default:
    }
  };

  setPage({ pageName }) {
    if (pageName === CommonPage.HOME) {
      this.pushEvent('viewHome');
    }
  }

  /**
   * @param {Array} productList An array with objects in the format { position, product }.
   * @param {string} listName
   */
  productImpressions({ productList, pageName }) {
    if (VIEWLIST_IGNORED_PAGES.includes(pageName)) {
      return;
    }

    if (productList.length > 0) {
      // Criteo is only interested in first three products.
      const productsIds = productList
        .slice(0, 3)
        .map(({ product }) => product.id);
      this.pushEvent('viewList', { item: productsIds });
    }
  }

  /**
   * @param {Array} productList An array with objects in the format { product, activeProductId }.
   */
  productDetail({ productList }) {
    const { product, activeProductId } = productList[0];
    let data = { item: product.id };
    if (product.class === ProductClass.MULTI) {
      data.item = activeProductId || product.id;
      data.item_group_id = product.id;
    }
    this.pushEvent('viewItem', data);
  }

  /**
   * @param {CurrentOrder} order An order.
   */
  purchase({ currentOrder }) {
    const orderId = currentOrder.id;
    const orderProducts = currentOrder.products
      .filter((product) => product.isPhysicalProduct)
      .map((product) => ({
        id: product.product_id,
        price: product.final_unit_price,
        quantity: product.quantity,
      }));
    this.pushEvent('trackTransaction', { id: orderId, item: orderProducts });
  }

  /**
   * @param {string} event Event name.
   * @param {object} data Object containing data for the event.
   */
  pushEvent(event, data = {}) {
    window.criteo_q.push({
      event,
      ...data,
    });
  }
}
