import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Button, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Page from '../../components/layout/Page';
import GenericLayout from '../../components/layout/GenericLayout';
import SEOTitle from '../../components/head/SEOTitle';
import ScrollReset from '../../components/common/ScrollReset';
import Icon from '../../components/common/Icon';
import Paths from '../../types/Paths';
import RouteService from '../../services/RouteService';
import RobotsMeta from '../../components/head/RobotsMeta';
import AdZones from '../../types/AdZones';
import { modelOf } from '../../prop-types';
import AdStore from '../../store/AdStore';
import WysiwygContent from '../../components/common/WysiwygContent';
import Analytics from '../../analytics/Analytics';
import CommonPage from '../../types/CommonPage';

const messages = defineMessages({
  notFoundTitle: {
    id: 'error.notFoundTitle',
    defaultMessage: 'Page not found',
  },
});

const NotFoundPage = ({ adStore, analytics, routeService, staticContext }) => {
  useEffect(() => {
    sendAnalyticsEvent();
  }, []);

  if (staticContext) {
    staticContext.is404 = true;
  }

  const sendAnalyticsEvent = () => {
    analytics.pageNotFound({
      location: window.location.href,
      referrer: document.referrer,
    });
  };

  const ads = adStore.getGlobalAdsByAdZone(AdZones.NOT_FOUND_CONTENT);

  const renderDefaultPageContent = () => {
    if (ads && ads.length > 0) {
      return null;
    }

    return (
      <p className="NotFoundPage__help-text">
        <FormattedMessage
          id="error.notFoundHelpText"
          defaultMessage="If it still exists, its address might have changed. You can continue browsing the web store or attempt to find what you were looking by using the search function."
        />
      </p>
    );
  };

  const getAdsContent = () => {
    if (!ads || ads.length === 0) {
      return null;
    }

    return ads.map((ad) => <WysiwygContent key={ad.id} html={ad.content} />);
  };

  const renderNavigationButtons = () => (
    <>
      <Button
        className="NotFoundPage__back-to-home-button"
        tag={Link}
        to={routeService.getPath(Paths.FrontPage)}
        color="primary"
      >
        <Icon name="home" />{' '}
        <FormattedMessage
          id="error.notFoundBackToHome"
          defaultMessage="Return to the front page"
        />
      </Button>
      <FormattedMessage
        id="error.notFoundGoToSearch"
        defaultMessage="or use the product search."
      />
    </>
  );

  return (
    <Page name={CommonPage.NOT_FOUND} className="NotFoundPage">
      <ScrollReset />
      <SEOTitle
        title={{
          ...messages.notFoundTitle,
        }}
      />
      <RobotsMeta noindex />
      <Container className="NotFoundPage__container">
        <GenericLayout
          content={
            <Row className="NotFoundPage__content">
              <Col
                xs="12"
                md={{ size: 10, offset: 1 }}
                xl={{ size: 8, offset: 2 }}
              >
                <h1>
                  <FormattedMessage
                    id="error.notFoundHeader"
                    defaultMessage="Unfortunately the page you requested could not be found anymore."
                  />
                </h1>
                <div className="NotFoundPage__text-container">
                  {getAdsContent()}
                  {renderDefaultPageContent()}
                </div>
                {renderNavigationButtons()}
              </Col>
            </Row>
          }
        />
      </Container>
    </Page>
  );
};

NotFoundPage.propTypes = {
  adStore: modelOf(AdStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  staticContext: PropTypes.object,
};

export default inject(
  'adStore',
  'analytics',
  'routeService'
)(withRouter(observer(NotFoundPage)));
