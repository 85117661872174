import { types } from 'mobx-state-tree';
import { RecurringOrderCycleTypeType } from '../types/RecurringOrderCycleType';
import ProductRecurringOrder from './ProductRecurringOrder';

const RecurringOrders = types.model('RecurringOrders', {
  cycles: types.array(RecurringOrderCycleTypeType),
  orders: types.array(ProductRecurringOrder),
  min_custom_cycle: types.maybeNull(types.number)
});

export default RecurringOrders;
