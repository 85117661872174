import { types } from 'mobx-state-tree';

import { CampaignCodeTypeType } from '../types/CampaignCodeType';
import { CouponDiscountTypeType } from '../types/CouponDiscountType';

const Discount = types.model('Discount', {
  allow_sale_products: types.boolean,
  amount: types.maybeNull(types.number),
  excluded_from_categories: types.maybeNull(types.array(types.number)),
  limited_to_categories: types.maybeNull(types.array(types.number)),
  limited_to_manufacturers: types.maybeNull(types.array(types.string)),
  limited_to_products: types.maybeNull(types.array(types.string)),
  percentage: types.maybeNull(types.number),
  type: CouponDiscountTypeType,
});

const CampaignCode = types
  .model('CampaignCode', {
    code: types.string,
    discounts: types.optional(types.array(Discount), []),
    type: CampaignCodeTypeType,
  })
  .views((self) => ({
    hasProductLimitations() {
      return (
        self.discounts.length &&
        self.discounts.some((discount) => discount.limited_to_products?.length)
      );
    },
    isLimitedToProduct(productId) {
      return (
        self.discounts.length &&
        self.discounts.find((discount) =>
          discount.limited_to_products?.some((id) => id === productId)
        )
      );
    },
  }));

export default CampaignCode;
