import axios from 'axios';
import { RedisStore, setup } from 'axios-cache-adapter';
import { createClient } from 'redis';

import { getSessionId } from '../util/session';

class ApiWrapper {
  axiosInstance;

  constructor(baseURL, { hostName, userAgent, gclid, cuad } = {}) {
    this.languageCode = null;
    this.sectionId = null;
    this.maintenanceCallBack = null;
    this.baseURL = baseURL;
    this.hostName = hostName;
    this.userAgent = userAgent;
    this.gclid = gclid;
    this.cuad = cuad;
  }

  getConfig = () => {
    const config = {
      baseURL: this.baseURL,
      headers: {},
    };
    if (this.hostName) {
      config.headers.Host = this.hostName;
    }
    // We need to change user agent for SSR
    if (this.userAgent) {
      config.headers['User-Agent'] = this.userAgent;
    }

    const token = getSessionId();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  };

  handleError = (error) => {
    // Catch all requests with maintenance mode set
    if (
      error.response &&
      error.response.status === 503 &&
      this.maintenanceCallBack
    ) {
      this.maintenanceCallBack(true, error.response.data.message);
    }
    return Promise.reject(error);
  };

  cacheKey = (request, appVersion) => {
    const url = request.url
      ? request.baseURL + '/' + request.url
      : request.baseURL;
    const lang = request.params.lang ? '-' + request.params.lang : '';

    return url + lang + '-' + appVersion;
  };

  apiAxios = () => {
    if (!this.axiosInstance) {
      const config = this.getConfig();

      this.axiosInstance = axios.create(config);
      this.axiosInstance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          return this.handleError(error);
        }
      );
    }
    return this.axiosInstance;
  };

  apiAxiosCached = (infraConfig) => {
    if (!this.axiosInstance) {
      const hour = 60 * 60 * 1000;
      const config = this.getConfig();

      const client = createClient({
        host: infraConfig.host,
        port: infraConfig.port,
      });

      // https://github.com/RasCarlito/axios-cache-adapter#setupcacheoptions
      config.cache = {
        maxAge: 24 * hour,
        exclude: {
          query: false,
          filter: (config) => config.url.startsWith('bootstrap'),
        },
        store: new RedisStore(client),
        key: (req) => {
          return this.cacheKey(req, infraConfig.version);
        },
      };

      this.axiosInstance = setup(config);
      this.axiosInstance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          return this.handleError(error);
        }
      );
    }
    return this.axiosInstance;
  };

  request(url, options, overrides = null) {
    let { params, ...other } = options || {};
    params = Object.assign(
      {
        lang: this.languageCode,
        active_section: overrides ? overrides.active_section : this.sectionId,
        gclid: this.gclid,
        cuad: this.cuad,
      },
      params
    );

    const config = {
      url,
      params,
      ...other,
    };
    return this.apiAxios()(config).then((response) => {
      return response.data;
    });
  }

  registerMaintenanceCallback = (callback) => {
    this.maintenanceCallBack = callback;
  };

  setLanguageCode = (languageCode) => {
    if (languageCode) {
      this.languageCode = languageCode;
    }
  };

  hasLanguageCode = () => {
    return !!this.languageCode;
  };

  setSectionId = (sectionId) => {
    // Set the section ID unconditionally, as some pages (namely, NewProductsPage, OnSalePage and CustomerProductsPage) are implicitly inside
    // no section and on them this is set to null. Having a non-null section ID on those pages causes unnecessary
    // and misbehaving filtering of results.
    this.sectionId = sectionId;
  };
}

export default ApiWrapper;
