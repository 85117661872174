import React, { Component } from 'react';
import PropTypes from 'prop-types/';
import WysiwygContent from '../WysiwygContent/';
import ContactUsForm from '../../contact-us/ContactUsForm';

export const BlockTypes = {
  CONTACT_FORM: 'contactUs',
  WYSIWYG: 'ckeditor'
};

class BlockContent extends Component {
  render() {
    const { block } = this.props;

    let content;
    switch (block.type) {
      case BlockTypes.CONTACT_FORM:
        content = <ContactUsForm />;
        break;
      case BlockTypes.WYSIWYG:
        content = <WysiwygContent html={block.content} />;
        break;
      default:
        content = null;
    }
    return (
      <div className={`BlockContent BlockContent--${block.type}`}>
        {content}
      </div>
    );
  }
}

BlockContent.propTypes = {
  block: PropTypes.shape({
    type: PropTypes.string.isRequired
  })
};

export default BlockContent;
