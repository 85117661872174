import { types } from 'mobx-state-tree';
import AdPages from '../../types/AdPages';

const BannerPage = types.model('BannerPage', {
  zones: types.array(types.optional(types.string, '')),
  has_non_mobile: types.boolean,
  has_mobile: types.boolean,
});

const BannerPages = types.model('BannerPages', {
  [AdPages.CAMPAIGN_PRODUCTS]: BannerPage,
  [AdPages.CATEGORY_PAGE]: BannerPage,
  [AdPages.CUSTOMER_PRODUCTS]: BannerPage,
  [AdPages.FRONT_PAGE]: BannerPage,
  [AdPages.MANUFACTURER_PAGE]: BannerPage,
  [AdPages.NEW_PRODUCTS]: BannerPage,
  [AdPages.POPULAR_PRODUCTS]: BannerPage,
  [AdPages.PRODUCT_PAGE]: BannerPage,
  [AdPages.RECOMMENDED_PRODUCTS]: BannerPage,
  [AdPages.SECTION_FRONT_PAGE]: BannerPage,
  [AdPages.SOLD_PRODUCTS]: BannerPage,
});

export default BannerPages;
