import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ScrollableAnchor from '../../anchor/ScrollableAnchor';

export const createHashTab = (id, title, getContent) => {
  return {
    id,
    title,
    getContent
  };
};

export class HashTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visitedTabs: []
    };

    if (props.activeTabId || props.tabs.length > 0) {
      this.state.visitedTabs = new Set([props.activeTabId || props.tabs[0].id]);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      visitedTabs: new Set([...prevState.visitedTabs, nextProps.activeTabId])
    };
  }

  toggle = (tabId) => {
    const { activeTabId, toggle } = this.props;
    // Only toggle if we have a new tab, because hash tabs cannot be closed
    if (tabId && activeTabId !== tabId) {
      toggle(tabId);
    }
  };

  renderNavItems = (activeTabId) =>
    this.props.tabs.map((tab) => (
      <NavItem key={tab.id} name={tab.id}>
        <ScrollableAnchor id={tab.id} scrollIfInHash={false} offset={100} />
        <NavLink
          href="#"
          className={classNames({
            'HashTabs__nav-inactive-tab': activeTabId !== tab.id,
            active: activeTabId === tab.id
          })}
          onClick={() => {
            this.toggle(tab.id);
          }}
        >
          {tab.title}
        </NavLink>
      </NavItem>
    ));

  renderTabPanes = (activeTabId) =>
    this.props.tabs.map((tab) => (
      <TabPane tabId={tab.id} key={tab.id}>
        {tab.getContent({
          preloadOnly: !(
            this.state.visitedTabs.has(tab.id) || activeTabId === tab.id
          )
        })}
      </TabPane>
    ));

  render() {
    const { activeTabId, tabs } = this.props;

    if (!tabs || !tabs.length) {
      return null;
    }

    const defaultTabId = activeTabId || tabs[0].id;

    return (
      <div className="HashTabs">
        <Nav tabs className="HashTabs__nav HashTabs__nav--top">
          {this.renderNavItems(defaultTabId)}
        </Nav>
        <TabContent activeTab={defaultTabId}>
          {this.renderTabPanes(defaultTabId)}
        </TabContent>
      </div>
    );
  }
}

HashTabs.propTypes = {
  activeTabId: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.node.isRequired,
      getContent: PropTypes.func.isRequired
    })
  ).isRequired
};

export default HashTabs;
