import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Duration } from 'luxon';

import { FormattedMessage } from 'react-intl';
import globalTranslations from '../../../i18n/globalTranslations';

class ProductWarranty extends Component {
  render() {
    const { warranty } = this.props;
    const duration = Duration.fromObject({ months: warranty }).shiftTo(
      'years',
      'months'
    );
    const formattedYears = duration.years ? (
      <FormattedMessage
        {...globalTranslations.formattedYears}
        values={{
          years: duration.years
        }}
      />
    ) : (
      ''
    );
    const formattedMonths = duration.months ? (
      <FormattedMessage
        {...globalTranslations.formattedMonths}
        values={{
          months: duration.months
        }}
      />
    ) : (
      ''
    );
    const formattedWarranty = (
      <span className="ProductWarranty__warranty">
        {formattedYears} {formattedMonths}
      </span>
    );
    return (
      <span className="ProductWarranty">
        <FormattedMessage
          {...globalTranslations.warrantyLabel}
          values={{ warranty: formattedWarranty }}
        />
      </span>
    );
  }
}

ProductWarranty.propTypes = {
  warranty: PropTypes.number.isRequired
};

export default ProductWarranty;
