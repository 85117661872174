export const convertLocaleToFinlandIfAland = (locale) => {
  return locale === 'AX' ? 'FI' : locale;
};

// https://datatracker.ietf.org/doc/html/rfc5646
export function parseRFC5646Tag(tag) {
  if (!tag) {
    return null;
  }

  const subTags = tag.split('-');
  return {
    language: subTags[0],
    region: subTags[1]
  };
}
