import { types, getEnv, flow } from 'mobx-state-tree';
import { keyBy } from 'lodash';

import StatefulStore from '../models/StatefulStore';
import { RequestStateType } from '../types/RequestState';
import RequestState from '../types/RequestState';
import CustomerUser from '../models/CustomerUser';
import { stringify } from '../util/queryString';
import { paramsToQueryIdentifier } from '../util/query';
import CustomerUserPurchases from '../models/CustomerUserPurchases';
import PurchaseProduct from '../models/PurchaseProduct';
import CreditAccount from '../models/CreditAccount';
import BalanceUser from '../models/BalanceUser';
import BalancePaymentWidget from '../models/BalancePaymentWidget';
import { createErrorModel } from '../util/error';
import Error from '../models/Error';
import BalancePaymentHistory from '../models/BalancePaymentHistory';

const CustomerUserStore = StatefulStore.named('CustomerUserStore')
  .props({
    users: types.optional(types.map(CustomerUser), {}),
    userStates: types.optional(types.map(RequestStateType), {}),
    balancePayment: types.maybeNull(BalancePaymentWidget),
    balancePaymentState: types.optional(RequestStateType, RequestState.NONE),
    balanceUsers: types.optional(types.map(BalanceUser), {}),
    balanceUserStates: types.optional(types.map(RequestStateType), {}),
    balancePaymentHistory: types.maybeNull(BalancePaymentHistory, {}),
    balancePaymentHistoryState: types.optional(
      RequestStateType,
      RequestState.NONE
    ),
    paymentSuccess: types.maybeNull(BalancePaymentWidget),
    paymentSuccessState: types.optional(RequestStateType, RequestState.NONE),
    paymentSuccessError: types.maybeNull(Error),
    purchaseQueryResults: types.optional(types.map(CustomerUserPurchases), {}),
    purchaseQueryStates: types.optional(types.map(RequestStateType), {}),
    productQueryResults: types.optional(
      types.map(types.array(PurchaseProduct)),
      {}
    ),
    productQueryStates: types.optional(types.map(RequestStateType), {}),
    creditAccount: types.optional(CreditAccount, {}),
    creditAccountState: types.optional(RequestStateType, RequestState.LOADED),
  })
  .views((self) => {
    return {
      get userArray() {
        return Array.from(self.users, ([key, value]) => value);
      },
      get balanceUserArray() {
        return Array.from(self.balanceUsers, ([key, value]) => value);
      },
      get balancePaymentArray() {
        return Array.from(self.balanceUsers, ([key, value]) => value);
      },
    };
  })
  .actions((self) => {
    return {
      loadUser: flow(function* loadUser(id) {
        self.userStates.set(id, RequestState.LOADING);

        try {
          const user = yield getEnv(self).apiWrapper.request(
            `customer-users/${id}`
          );
          self.users.set(id, user);
        } catch (e) {
          self.setError(e);
          self.userStates.set(id, RequestState.ERROR);
          throw e;
        }

        self.userStates.set(id, RequestState.LOADED);
      }),
      loadUsers: flow(function* loadUsers() {
        self.setLoading(true);

        try {
          const users = yield getEnv(self).apiWrapper.request(`customer-users`);
          self.users = keyBy(users, 'id');
          self.userArray.forEach((user) => {
            self.userStates.set(user.id, RequestState.LOADED);
          });
        } catch (e) {
          self.setError(e);
          throw e;
        }

        self.setLoading(false);
      }),
      loadBalanceUser: flow(function* loadBalanceUsers(id) {
        self.setLoading(true);

        try {
          const user = yield getEnv(self).apiWrapper.request(
            `money-transfer/user/${id}`
          );

          self.balanceUsers.set(id, user);
          self.balanceUserStates.set(user.id, RequestState.LOADED);
        } catch (e) {
          self.setError(e);
          throw e;
        }

        self.setLoading(false);
      }),
      loadBalanceUsers: flow(function* loadBalanceUsers() {
        self.setLoading(true);

        try {
          const users = yield getEnv(self).apiWrapper.request(
            `money-transfer/user`
          );

          self.balanceUsers = keyBy(users, 'id');
          self.balanceUserArray.forEach((user) => {
            self.balanceUserStates.set(user.id, RequestState.LOADED);
          });
        } catch (e) {
          self.setError(e);
          throw e;
        }

        self.setLoading(false);
      }),
      loadPurchases: flow(function* loadPurchases(searchParameters) {
        const queryIdentifier = paramsToQueryIdentifier(searchParameters);
        // Skip the API call if we already have the data
        if (self.purchaseQueryStates.get(queryIdentifier)) {
          return;
        }

        self.setLoading(true);
        self.purchaseQueryStates.set(queryIdentifier, RequestState.LOADING);

        try {
          const purchases = yield getEnv(self).apiWrapper.request(
            `customer-user-purchases?${stringify(searchParameters)}`
          );
          self.setLoading(false);
          self.purchaseQueryResults.set(queryIdentifier, purchases);
        } catch (e) {
          self.setError(e);
          self.purchaseQueryStates.set(queryIdentifier, RequestState.ERROR);
          throw e;
        }

        self.setLoading(false);
        self.purchaseQueryStates.set(queryIdentifier, RequestState.LOADED);
      }),
      loadPurchaseProducts: flow(function* loadPurchaseProducts(
        searchParameters
      ) {
        const queryIdentifier = paramsToQueryIdentifier(searchParameters);
        // Skip the API call if we already have the data
        if (self.productQueryStates.get(queryIdentifier)) {
          return;
        }

        self.setLoading(true);
        self.productQueryStates.set(queryIdentifier, RequestState.LOADING);
        try {
          const purchaseProducts = yield getEnv(self).apiWrapper.request(
            `customer-user-purchases/products?${stringify(searchParameters)}`
          );
          self.setLoading(false);
          self.productQueryResults.set(queryIdentifier, purchaseProducts);
        } catch (e) {
          self.setError(e);
          self.productQueryStates.set(queryIdentifier, RequestState.ERROR);
          throw e;
        }
        self.setLoading(false);
        self.productQueryStates.set(queryIdentifier, RequestState.LOADED);
      }),
      removeUser: (id) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .delete(`customer-users/${id}`);
      },
      saveUser: (user) => {
        return getEnv(self).apiWrapper.apiAxios().post(`customer-users/`, user);
      },
      updateUser: (id, user) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .put(`customer-users/${id}`, user);
      },
      removeBalanceUser: (id) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .delete(`money-transfer/user/${id}`);
      },
      saveBalanceUser: (user) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .post(`money-transfer/user`, user);
      },
      updateBalanceUser: (id, user) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .put(`money-transfer/user/${id}`, user);
      },
      makePayment: flow(function* balancePayment(userPayments, lang) {
        self.balancePaymentState = RequestState.LOADING;

        try {
          const response = yield getEnv(self)
            .apiWrapper.apiAxios()
            .post(`money-transfer/payment?lang=${lang}`, {
              userPayments,
            });

          self.balancePayment = response.data;
          self.balancePaymentState = RequestState.LOADED;
        } catch (e) {
          self.balancePaymentState = RequestState.ERROR;
          throw e;
        }

        self.balancePaymentState = RequestState.LOADED;
      }),
      convertPaymentAmountsIntoUserPayments: (paymentAmounts) => {
        const userPayments = [];
        if (paymentAmounts.length > 0) {
          paymentAmounts.forEach((amount) => {
            userPayments.push({
              id: amount.id,
              amount_with_tax: amount.amount,
            });
          });
        }

        return userPayments;
      },
      loadBalancePostPayment: flow(function* loadBalancePostPayment() {
        self.balancePaymentState = RequestState.LOADING;

        try {
          self.paymentSuccess = yield getEnv(self).apiWrapper.request(
            `money-transfer/payment/success`
          );

          self.paymentSuccessState = RequestState.LOADED;
        } catch (error) {
          self.paymentSuccessError = createErrorModel(error);
          self.paymentSuccessState = RequestState.ERROR;
          throw error;
        }

        self.balancePaymentState = RequestState.LOADED;
      }),
      loadBalancePaymentHistory: flow(function* loadBalancePaymentHistory(
        page
      ) {
        self.balancePaymentHistoryState = RequestState.LOADING;
        try {
          const response = yield getEnv(self).apiWrapper.request(
            `money-transfer/payment/history${page}`
          );
          self.balancePaymentHistory = response;
        } catch (e) {
          self.setError(e);
          self.balancePaymentHistoryState = RequestState.ERROR;
          throw e;
        }

        self.balancePaymentHistoryState = RequestState.LOADED;
      }),
      loadCreditAccount: flow(function* loadCreditAccount() {
        self.creditAccountState = RequestState.LOADING;

        try {
          self.creditAccount = yield getEnv(self).apiWrapper.request(
            `my-account/credit-account`
          );
        } catch (e) {
          self.creditAccountState = RequestState.ERROR;
          throw e;
        }

        self.creditAccountState = RequestState.LOADED;
      }),
    };
  });

export default CustomerUserStore;
