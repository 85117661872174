import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Redirect, Route } from 'react-router-dom';

export class Redirect302 extends Component {
  render() {
    return (
      <Route
        render={({ staticContext }) => {
          // There is no `staticContext` on the client, so
          // We need to guard against that here
          if (staticContext) staticContext.status = 302;
          return <Redirect to={this.props.to} />;
        }}
      />
    );
  }
}

Redirect302.propTypes = {
  to: PropTypes.string.isRequired
};

export default Redirect302;
