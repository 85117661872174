import { types } from 'mobx-state-tree';

const TextBanner = types.model('TextBanner', {
  content: types.maybeNull(types.string),
  id: types.identifier,
  is_external: types.optional(types.boolean, true),
  link_url: types.maybeNull(types.string),
});

export default TextBanner;
