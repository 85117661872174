import { types } from 'mobx-state-tree';

const PaymentWidgetType = {
  KLARNA: 'KLARNA'
};

export default PaymentWidgetType;

export const PaymentWidgetTypeType = types.enumeration(
  'PaymentWidgetType',
  Object.values(PaymentWidgetType)
);
