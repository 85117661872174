import React from 'react';
import classNames from 'classnames';

const MarkerBadge = ({ className, children, ...rest }) => (
  <span className={classNames('MarkerBadge', className)} {...rest}>
    {children}
  </span>
);

export default MarkerBadge;
