import { types } from 'mobx-state-tree';

import ActivatedCoupon from './ActivatedCoupon';

const Session = types
  .model('Session', {
    activated_coupons: types.array(ActivatedCoupon)
  })
  .actions((self) => ({
    removeCoupon(coupon) {
      return self.activated_coupons.remove(coupon);
    },
    addCoupons(coupons) {
      self.activated_coupons = [...self.activated_coupons, ...coupons];
    }
  }));

export default Session;
