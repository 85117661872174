import { useCallback, useState } from 'react';

const useImageMagnifier = () => {
  const [imageOrigin, setImageOrigin] = useState({ x: 50, y: 50 });

  const countMousePosition = useCallback(
    (event) => {
      const { left, top, width, height } = event.target.getBoundingClientRect();
      const mouseX = event.clientX - left;
      const mouseY = event.clientY - top;
      const x = (mouseX / width) * 100;
      const y = (mouseY / height) * 100;

      return { x, y };
    },
    [imageOrigin]
  );

  const updateImagePosition = useCallback(
    (event) => {
      const { x, y } = countMousePosition(event);
      setImageOrigin({ x, y });
    },
    [imageOrigin]
  );

  const resetImagePosition = useCallback(() => {
    setImageOrigin({ x: 50, y: 50 });
  }, [imageOrigin]);

  return [updateImagePosition, resetImagePosition, imageOrigin];
};

export default useImageMagnifier;
