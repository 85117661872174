import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ProductCode = ({ code }) => {
  return (
    <FormattedMessage
      id="product.idLabel"
      defaultMessage="Product Code: {code}"
      values={{
        code: <span>{code}</span>,
      }}
    />
  );
};

ProductCode.propTypes = {
  code: PropTypes.string.isRequired,
};

export default ProductCode;
