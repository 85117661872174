import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { NavItem } from 'reactstrap';
import PropTypes from 'prop-types';

import globalTranslations from '../../../i18n/globalTranslations';
import Icon from '../../common/Icon';
import NavigationEntityItem from '../../navigation/NavigationEntityItem';

@observer
export class TopNavigationSearchButton extends Component {
  item = {
    id: 'topNavigationSearch',
    label: <FormattedMessage {...globalTranslations.search} />
  };
  render() {
    return (
      <NavItem
        className="TopNavigationSearchButton"
        onClick={this.props.onShowSearchOnTopNavigation}
      >
        <Icon name="search" />
        <NavigationEntityItem entity={this.item} />
        <Icon name="angle-down" />
      </NavItem>
    );
  }
}

TopNavigationSearchButton.propTypes = {
  onShowSearchOnTopNavigation: PropTypes.func.isRequired
};

export default TopNavigationSearchButton;
