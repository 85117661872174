import { flow, getEnv, types } from 'mobx-state-tree';

import AccountStore from './AccountStore';
import AddressStore from './AddressStore';
import AdStore from './AdStore';
import CampaignCodeStore from './CampaignCodeStore';
import CartStore from './CartStore';
import CategoryStore from './CategoryStore';
import ConfigStore from './ConfigStore';
import CountryStore from './CountryStore';
import CouponStore from './CouponStore';
import CurrencyStore from './CurrencyStore';
import CustomerUserStore from './CustomerUserStore';
import CustomNavigationLinkStore from './CustomNavigationLinkStore';
import InfoPageStore from './InfoPageStore';
import InstantShoppingCartStore from './InstantShoppingCartStore';
import LanguageStore from './LanguageStore';
import LayoutBoxStore from './LayoutBoxStore';
import LocalizationStore from './LocalizationStore';
import ManufacturerStore from './ManufacturerStore';
import MenuStore from './MenuStore';
import OrderStore from './OrderStore';
import PaymentModuleStore from './PaymentModuleStore';
import ProductStore from './ProductStore';
import RecurringOrderStore from './RecurringOrderStore';
import RequestState from '../types/RequestState';
import ReserveInStoreStore from './ReserveInStoreStore';
import SearchRedirectionStore from './SearchRedirectionStore';
import SectionStore from './SectionStore';
import StatefulStore from '../models/StatefulStore';
import StorageStore from './StorageStore';
import SuggestionStore from './SuggestionStore';
import SurveyStore from './SurveyStore';
import TrackingStore from './TrackingStore';
import TranslationStore from './TranslationStore';
import UIStore from './UIStore';
import { getStorageAndParse } from '../util/storage';
import LocalStorageKey from '../types/LocalStorageKey';
import PaymentTokenStore from './PaymentTokenStore';
import ScriptStore from './ScriptStore';
import ProposalStore from './ProposalStore';

const RootStore = StatefulStore.named('RootStore')
  .props({
    accountStore: types.optional(AccountStore, {}),
    addressStore: types.optional(AddressStore, {}),
    adStore: types.optional(AdStore, {}),
    campaignCodeStore: types.optional(CampaignCodeStore, {}),
    cartStore: types.optional(CartStore, {}),
    categoryStore: types.optional(CategoryStore, {}),
    configStore: types.optional(ConfigStore, {}),
    countryStore: types.optional(CountryStore, {}),
    couponStore: types.optional(CouponStore, {}),
    currencyStore: types.optional(CurrencyStore, {}),
    customerUserStore: types.optional(CustomerUserStore, {}),
    customNavigationLinkStore: types.optional(CustomNavigationLinkStore, {}),
    infoPageStore: types.optional(InfoPageStore, {}),
    instantShoppingCartStore: types.optional(InstantShoppingCartStore, {}),
    languageStore: types.optional(LanguageStore, {}),
    layoutBoxStore: types.optional(LayoutBoxStore, {}),
    localizationStore: types.optional(LocalizationStore, {}),
    manufacturerStore: types.optional(ManufacturerStore, {}),
    menuStore: types.optional(MenuStore, {}),
    orderStore: types.optional(OrderStore, {}),
    paymentModuleStore: types.optional(PaymentModuleStore, {}),
    paymentTokenStore: types.optional(PaymentTokenStore, {}),
    productStore: types.optional(ProductStore, {}),
    proposalStore: types.optional(ProposalStore, {}),
    recurringOrderStore: types.optional(RecurringOrderStore, {}),
    reserveInStoreStore: types.optional(ReserveInStoreStore, {}),
    scriptStore: types.optional(ScriptStore, {}),
    searchRedirectionStore: types.optional(SearchRedirectionStore, {}),
    sectionStore: types.optional(SectionStore, {}),
    storageStore: types.optional(StorageStore, {}),
    suggestionStore: types.optional(SuggestionStore, {}),
    surveyStore: types.optional(SurveyStore, {}),
    trackingStore: types.optional(TrackingStore, {}),
    translationStore: types.optional(TranslationStore, {}),
    uiStore: types.optional(UIStore, {}),
  })
  .actions((self) => {
    const urlLanguageIsValid = () => {
      return !(
        self.languageStore.locale !== self.languageStore.activeLocale ||
        self.languageStore.languages
          .map((language) => language.code)
          .indexOf(self.languageStore.locale) === -1
      );
    };

    const setupLanguageStoreFromAccount = (languageCode) => {
      /* If url language is not found from the active languages default should be set. */
      if (self.languageStore.hasBeenSetup && !urlLanguageIsValid()) {
        self.languageStore.getRedirectedUrl(languageCode).then((url) => {
          window.location = url;
        });
      }

      if (!self.languageStore.hasBeenSetup) {
        self.languageStore.setupLocale(languageCode);
        // Set up the API wrapper language once the bootstrap has finished. If we had a
        // language from the URL before, this should do nothing, but if we received the
        // language from the bootstrap response and configured the language store in
        // the bootstrapper, the API language is not yet set at this point.
        // Update: Setting up the apiWrapper language also to speed up the data availability.
        // bootstrap.then -> here.
        if (!getEnv(self).apiWrapper.hasLanguageCode()) {
          getEnv(self).apiWrapper.setLanguageCode(languageCode);
        }
      }
    };

    const setup = (bootstrapData) => {
      self.accountStore.setup(bootstrapData.account);
      self.adStore.setup({
        bannerZones: bootstrapData.bannerZones,
        globalBanners: bootstrapData.globalBanners,
        textBanners: bootstrapData.textBanners,
      });
      self.categoryStore.setup(bootstrapData.categories);
      self.configStore.setup(bootstrapData.globalConfig, bootstrapData.site);
      self.countryStore.setup(
        bootstrapData.countries,
        bootstrapData.account && bootstrapData.account.user
          ? bootstrapData.account.user.country_id
          : bootstrapData.countries[0].id
      );
      self.couponStore.setup(bootstrapData.coupons);
      self.currencyStore.setup(
        bootstrapData.currencies,
        bootstrapData.account && bootstrapData.account.user
          ? bootstrapData.account.user.currency_code
          : bootstrapData.currencies[0].code
      );
      self.customNavigationLinkStore.setup(bootstrapData.customNavigationLinks);
      self.languageStore.setup(bootstrapData.languages);
      self.layoutBoxStore.setup(bootstrapData.layoutBoxes);
      self.localizationStore.setup(bootstrapData.localization);
      self.menuStore.setup(bootstrapData.menus);
      self.paymentModuleStore.setup(bootstrapData.paymentInfo);
      self.scriptStore.setup(bootstrapData.scripts);
      self.searchRedirectionStore.setup(bootstrapData.searchRedirections);
      self.sectionStore.setup(bootstrapData.sections);
      self.surveyStore.setup(bootstrapData.surveys);
      self.translationStore.setup(
        bootstrapData.translations,
        bootstrapData.translatedImages
      );

      setupLanguageStoreFromAccount(
        bootstrapData.account.default_language_code
      );
    };

    const bootstrap = flow(function* bootstrap(
      sellerID,
      campaignCode,
      allowTestModeScripts,
      processLogout
    ) {
      // Don't bootstrap twice (in the usual case, this should only be run on the first cycle of the SSR render process)
      if (self.state === RequestState.LOADED) {
        return;
      }

      self.setLoading(true);
      const params = {};

      try {
        if (!window.isSSR) {
          params.landingPageUrl = window.location.href;

          if (!!getStorageAndParse(LocalStorageKey.SITE_CHOSEN)) {
            params.siteChosen = 1;
          }
        }

        if (sellerID) {
          params.sellerID = sellerID;
        }

        if (campaignCode) {
          params.campaignCode = campaignCode;
        }

        if (localStorage.getItem('surveys_seen')) {
          params.surveys_seen = localStorage.getItem('surveys_seen');
        }

        if (allowTestModeScripts) {
          params.allowTestModeScripts = allowTestModeScripts;
        }

        if (processLogout) {
          params.processLogout = processLogout;
        }

        const result = yield getEnv(self).apiWrapper.request(`bootstrap`, {
          params,
        });
        self.setup(result);
      } catch (e) {
        self.setError(e);
        throw e;
      }

      self.setLoading(false);
    });

    return {
      setup,
      bootstrap,
    };
  });

export default RootStore;
