import { types } from 'mobx-state-tree';

import RecurringOrder from './RecurringOrder';

const RecurringOrdersPaginator = types.model('OrdersPaginator', {
  current_page: types.number,
  data: types.optional(types.array(types.reference(RecurringOrder)), []),
  last_page: types.number,
  total: types.number
});

export default RecurringOrdersPaginator;
