import React, { Component } from 'react';
import { DropdownItem } from 'reactstrap';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import Currency from '../../../models/Currency';
import CurrencyLabel from '../CurrencyLabel';
import DropdownSelect from '../../common/DropdownSelect';
import globalTranslations from '../../../i18n/globalTranslations';

@observer
class RegionalSettingsCurrencyDropdown extends Component {
  render() {
    const { currencies } = this.props;

    let disabled = this.props.disabled;
    if (currencies.length < 2) {
      disabled = true;
    }

    return (
      <DropdownSelect
        label={
          <FormattedMessage
            {...globalTranslations.currencyLabel}
            values={{
              currency: this.props.currency.code
            }}
          />
        }
        disabled={disabled}
      >
        {currencies.map((currency) => (
          <DropdownItem
            onClick={(e) => this.props.onSelect(currency)}
            key={currency.code}
          >
            <CurrencyLabel currency={currency} />
          </DropdownItem>
        ))}
      </DropdownSelect>
    );
  }
}

RegionalSettingsCurrencyDropdown.propTypes = {
  currency: modelOf(Currency).isRequired,
  currencies: MobxPropTypes.observableArrayOf(modelOf(Currency)).isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

RegionalSettingsCurrencyDropdown.defaultProps = {
  disabled: false
};

export default RegionalSettingsCurrencyDropdown;
