import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import RouteService from '../../../services/RouteService';
import CampaignCodeStore from '../../../store/CampaignCodeStore';
import CategoryStore from '../../../store/CategoryStore';
import CurrencyStore from '../../../store/CurrencyStore';
import ManufacturerStore from '../../../store/ManufacturerStore';
import CampaignCodeDiscountType from '../../../types/CampaignCodeDiscountType';
import { formatPrice } from '../../../util/number';
import ManufacturerLink from '../../manufacturer/ManufacturerLink';
import CampaignCodeProducts from '../CampaignCodeProducts';

const CampaignCodeDiscount = ({
  campaignCodeStore,
  manufacturerStore,
  categoryStore,
  currencyStore,
  routeService,
}) => {
  const getCategoryLimitDescription = () => {
    return (
      <div className="CampaignCodeDiscount__description">
        <FormattedMessage
          id="campaign.categoryLimitation"
          defaultMessage="Using this discount coupon, you will receive a discount for products belonging to the following product categories:"
        />
      </div>
    );
  };

  const getCategoryExcludedDescription = () => {
    return (
      <div className="CampaignCodeDiscount__description">
        <FormattedMessage
          id="campaign.categoryExclution"
          defaultMessage="Products in the following product categories are not included in the discount:"
        />
      </div>
    );
  };

  const getManufacturerLimitDescription = () => {
    return (
      <div className="CampaignCodeDiscount__description">
        <FormattedMessage
          id="campaign.manufacturerLimitation"
          defaultMessage="Using this discount coupon, you will receive a discount for products from the following brands:"
        />
      </div>
    );
  };

  const getTypeDescription = (discount) => {
    let typeDescription = null;

    switch (discount.type) {
      case CampaignCodeDiscountType.AMOUNT:
        if (discount.amount) {
          typeDescription = (
            <FormattedMessage
              id="global.discountAmountSentence"
              defaultMessage="Discount: {discount}"
              values={{
                discount: formatPrice(
                  discount.amount,
                  currencyStore.activeCurrency
                ),
              }}
            />
          );
        }
        break;
      case CampaignCodeDiscountType.PERCENTAGE:
        if (discount.percentage) {
          typeDescription = (
            <FormattedMessage
              id="global.discountPercentageSentence"
              defaultMessage="Discount: {discount} %"
              values={{ discount: discount.percentage }}
            />
          );
        }
        break;
      case CampaignCodeDiscountType.FREE_SHIPPING:
        typeDescription = (
          <FormattedMessage {...globalTranslations.freeDeliverySentence} />
        );
        break;
      default:
        break;
    }
    return typeDescription ? (
      <div className="CampaignCodeDiscount__type">{typeDescription}</div>
    ) : null;
  };

  const getIncludedProducts = (discount) => {
    if (!discount.limited_to_products) {
      return null;
    }

    return (
      <CampaignCodeProducts productIds={discount.limited_to_products.slice()} />
    );
  };

  const getCategories = (categories) => {
    return categories.map((limitation) => {
      const category = categoryStore.findCategoryById(limitation);
      if (!category) {
        return (
          <div key={'limitation-' + limitation}>
            <FormattedMessage
              id="campaign.categoryNotFound"
              defaultMessage="Given category {category} not found"
              values={{
                category: limitation,
              }}
            />
          </div>
        );
      }

      return (
        <li key={category.id}>
          <Link
            className="CategoryLink"
            to={routeService.getPath(category.path)}
          >
            {category.name}
          </Link>
        </li>
      );
    });
  };

  const getIncludedCategories = (categories) => {
    if (!categories) {
      return null;
    }

    const categoryItems = getCategories(categories);

    return (
      categoryItems && (
        <div className="CampaignCodeDiscount__category">
          <div className="CampaignCodeDiscount__category-desription">
            {getCategoryLimitDescription()}
          </div>
          <div className="CampaignCodeDiscount__category-list">
            <ul>{categoryItems}</ul>
          </div>
        </div>
      )
    );
  };

  const getExcludedCategories = (categories) => {
    if (!categories) {
      return null;
    }

    const categoryItems = getCategories(categories);

    return (
      categoryItems && (
        <div className="CampaignCodeDiscount__category">
          <div className="CampaignCodeDiscount__category-desription">
            {getCategoryExcludedDescription()}
          </div>
          <div className="CampaignCodeDiscount__category-list">
            <ul>{categoryItems}</ul>
          </div>
        </div>
      )
    );
  };

  const getIncludedManufacturers = (discount) => {
    if (!discount.limited_to_manufacturers) {
      return null;
    }

    const manufacturerItems = discount.limited_to_manufacturers.map(
      (manufacturerId) => {
        const manufacturer =
          manufacturerStore.manufacturers.get(manufacturerId);

        return (
          manufacturer &&
          manufacturer.has_products && (
            <li key={manufacturer.id}>
              <ManufacturerLink manufacturer={manufacturer} />
            </li>
          )
        );
      }
    );

    return manufacturerItems && manufacturerItems.length > 0 ? (
      <div className="CampaignCodeDiscount__manufacturer">
        <div className="CampaignCodeDiscount__manufacturer-desription">
          {getManufacturerLimitDescription()}
        </div>
        <div className="CampaignCodeDiscount__manufacturer-list">
          <ul>{manufacturerItems}</ul>
        </div>
      </div>
    ) : (
      <FormattedMessage
        id="campaign.manufacturerMultiLimitation"
        defaultMessage="Allowed for limited manufacturers"
      />
    );
  };

  const renderDiscounts = () => {
    if (campaignCodeStore.activeCodes.length > 0) {
      const activeCode = campaignCodeStore.activeCodes[0];
      const discounts = activeCode.discounts.map((discount) => {
        return (
          <div
            key={activeCode.code}
            className="CampaignCodeDiscount__campaign-code"
          >
            {getTypeDescription(discount)}
            {getIncludedProducts(discount)}
            {getIncludedCategories(discount.limited_to_categories)}
            {getExcludedCategories(discount.excluded_from_categories)}
            {getIncludedManufacturers(discount)}
          </div>
        );
      });
      return <div key={activeCode.code}>{discounts}</div>;
    }
  };

  return <div className="CampaignCodeDiscount">{renderDiscounts()}</div>;
};

CampaignCodeDiscount.propTypes = {
  campaignCodeStore: modelOf(CampaignCodeStore).isRequired,
  manufacturerStore: modelOf(ManufacturerStore).isRequired,
  categoryStore: modelOf(CategoryStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
};

export default inject(
  'campaignCodeStore',
  'manufacturerStore',
  'categoryStore',
  'currencyStore',
  'routeService'
)(CampaignCodeDiscount);
