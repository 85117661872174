import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import InfoPageStore from '../../../store/InfoPageStore';
import UIStore from '../../../store/UIStore';
import ContentForState from '../../loader/ContentForState';
import Icon from '../../common/Icon';
import classNames from 'classnames';

export const NavigationPopover = ({
  infoPageStore,
  uiStore,
  navigationIsSticky,
  children,
}) => {
  const pageId = uiStore.navigationPopover.contentId;

  useEffect(() => {
    navigationIsSticky && uiStore.navigationPopover.isOpen && closePopover();
  }, [navigationIsSticky]);

  const closePopover = () => uiStore.navigationPopover.toggle();

  const getInfoPage = () => infoPageStore.pages.get(pageId);

  const getInfoPageContent = () => {
    const infoPage = getInfoPage();

    return (
      <ContentForState
        state={infoPageStore.pageStates.get(pageId)}
        error={infoPageStore.lastError}
        forLoaded={() =>
          infoPage &&
          infoPage.content && (
            <div
              dangerouslySetInnerHTML={{
                __html: infoPage.content,
              }}
            />
          )
        }
      />
    );
  };

  const getContent = () => {
    if (pageId && !children) {
      return getInfoPageContent();
    }

    return children;
  };

  return (
    <div
      className={classNames('NavigationPopover', {
        'NavigationPopover--hidden':
          navigationIsSticky || !uiStore.navigationPopover.isOpen,
      })}
    >
      <Container className="NavigationPopover__container">
        <Row>
          <Col xl={12}>
            <div className="NavigationPopover__container-close-button">
              <Button color="primary" outline onClick={closePopover}>
                <Icon name="close" />
              </Button>
            </div>
          </Col>
          <Col xl={12}>
            <div className="NavigationPopover__container-content">
              {getContent()}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

NavigationPopover.propTypes = {
  infoPageStore: modelOf(InfoPageStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  navigationIsSticky: PropTypes.bool.isRequired,
};

export default inject('infoPageStore', 'uiStore')(observer(NavigationPopover));
