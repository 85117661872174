import { types } from 'mobx-state-tree';
import { CartNewProductTypeType } from '../../types/CartNewProductType';
import Price from '../Price';

const CartNewProduct = types
  .model('CartNewProduct', {
    actual_code: types.maybeNull(types.string),
    image: types.maybeNull(types.string),
    manufacturer: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    price: types.maybeNull(Price),
    quantity: types.number,
    stock_unit: types.maybeNull(types.string),
    type: CartNewProductTypeType,
    variations: types.maybeNull(types.array(types.string)),
  })
  .views((self) => ({
    get productCode() {
      return self.actual_code;
    },
  }));

export default CartNewProduct;
