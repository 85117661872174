import { types } from 'mobx-state-tree';

const Error = types.model('Error', {
  httpStatusCode: types.maybeNull(types.number),
  publicMessage: types.maybeNull(
    types.union(
      types.string,
      types.model({
        id: types.string,
        defaultMessage: types.string,
      })
    )
  ),
  fallbackCategoryId: types.maybeNull(types.number),
});

export default Error;
