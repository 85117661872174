import React, { Component } from 'react';
import { Badge, Button, Collapse, FormGroup, Input, Label } from 'reactstrap';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape
} from 'react-intl';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import ConfigStore from '../../../store/ConfigStore';
import RecurringOrderStore from '../../../store/RecurringOrderStore';
import Price from '../Price';
import AccountStore from '../../../store/AccountStore';
import RequestState from '../../../types/RequestState';
import RecurringOrderCycleType from '../../../types/RecurringOrderCycleType';
import ContentForState from '../../loader/ContentForState';
import globalTranslations from '../../../i18n/globalTranslations';
import RecurringInfoLink from '../RecurringInfoLink';
import RecurringCycleDropdown from '../../recurring/RecurringCycleDropdown';
import RecurringDaysDropdown from '../../recurring/RecurringDaysDropdown';

const messages = defineMessages({
  placeholderOrder: {
    id: 'recurringOrder.subscriptionPlaceholder',
    defaultMessage: 'Choose your subscription'
  }
});

function createNewRecurringOrder(cycle, days) {
  return {
    cycle,
    days
  };
}

const INITIAL_MIN_RECURRING_DAYS = 7;

@observer
export class ProductRecurringOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      renderDays: false,
      selectedCycle: null,
      selectedOrder: null,
      selectedDays: INITIAL_MIN_RECURRING_DAYS
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isOpen !== prevState.isOpen && this.state.isOpen) {
      this.maybeLoadRecurringOrders();
    }
  }

  maybeLoadRecurringOrders = () => {
    const { accountStore, product } = this.props;

    if (
      accountStore.loggedIn &&
      product.recurringOrdersState === RequestState.NONE
    ) {
      product.loadRecurringOrders().catch((e) => {
        console.error(e);
      });
    }
  };

  toggleContent = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  handleAddToCart = () => {
    const { onAddToCartClick } = this.props;
    const { selectedCycle, selectedDays } = this.state;

    onAddToCartClick(createNewRecurringOrder(selectedCycle, selectedDays));
  };

  handleAddToOrder = () => {
    const { onAddToOrderClick } = this.props;
    const { selectedCycle, selectedDays, selectedOrder } = this.state;

    const payload = {
      cycleType: selectedCycle
    };

    if (selectedDays && selectedCycle === RecurringOrderCycleType.CUSTOM) {
      payload.customCycle = selectedDays;
    }

    onAddToOrderClick(selectedOrder, payload);
  };

  handleCycleChange = (event) => {
    const { renderDays } = this.state;
    const value = event.target.value;
    let newRenderDays = renderDays;

    if (value === RecurringOrderCycleType.CUSTOM) {
      newRenderDays = true;
    }
    if (value !== RecurringOrderCycleType.CUSTOM && renderDays === true) {
      newRenderDays = false;
    }

    this.setState({
      selectedCycle: value,
      renderDays: newRenderDays
    });
  };

  handleDaysChange = (event) => {
    const value = event.target.value;
    this.setState({
      selectedDays: value
    });
  };

  handleOrderChange = (event) => {
    this.setState({
      selectedOrder: event.target.value
    });
  };

  renderBadge = () => {
    const { product, withTax } = this.props;

    return (
      <Badge color="danger" className="ProductRecurringOrder__button-badge">
        <Price price={product.recurring_order_price.getPrice(withTax)} />
      </Badge>
    );
  };

  renderLoggedIn = () => {
    const { accountStore } = this.props;

    if (!accountStore.loggedIn) {
      return (
        <FormattedMessage
          id="recurringOrder.onlyForLoggedIn"
          defaultMessage="Subscriptions are available for registered users."
        />
      );
    }
    return null;
  };

  renderContentDescription = () => {
    return (
      <div className="ProductRecurringOrder__content-description">
        <RecurringInfoLink>
          <FormattedMessage {...globalTranslations.readAboutRecurring} />
        </RecurringInfoLink>
      </div>
    );
  };

  renderOrdersDropdown = () => {
    const { product, intl } = this.props;

    const options = product.recurringOrders.orders.map((order) => {
      return (
        <option key={order.id} value={order.id}>
          {order.title || order.autogenerated_title}
        </option>
      );
    });

    return (
      <FormGroup>
        <Label for="ProductRecurringOrder__orders-label">
          <div>
            <FormattedMessage
              id="recurringOrder.selectOrder"
              defaultMessage="Choose on which subscription product should be added"
            />
          </div>
          <div>
            <FormattedMessage {...globalTranslations.modifyOrderNotify} />
          </div>
        </Label>
        <Input
          type="select"
          name="select"
          className="ProductRecurringOrder__orders-select"
          id="ProductRecurringOrder__orders-select"
          onChange={this.handleOrderChange}
        >
          <option value="">
            {intl.formatMessage(messages.placeholderOrder)}
          </option>
          {options}
        </Input>
      </FormGroup>
    );
  };

  isValidRecurring = () => {
    const { product } = this.props;
    const { selectedCycle, selectedDays } = this.state;

    if (!selectedCycle) {
      return false;
    }

    return !(
      selectedCycle === RecurringOrderCycleType.CUSTOM &&
      (!selectedDays ||
        Number(selectedDays) < product.recurringOrders.min_custom_cycle)
    );
  };

  renderExistingOrders = () => {
    const { product, loading } = this.props;

    if (product.recurringOrders.orders.length === 0) {
      return null;
    }

    return (
      <div className="ProductRecurringOrder__existing-orders">
        {this.renderOrdersDropdown()}
        <Button
          color="primary"
          onClick={this.handleAddToOrder}
          className="ProductRecurringOrder__button-to-order"
          disabled={loading || !this.isValidRecurring()}
        >
          <FormattedMessage
            id="recurringOrder.addToOrder"
            defaultMessage="Add subscription to order"
          />
        </Button>
      </div>
    );
  };

  renderRecurringOrder = () => {
    const { product, loading } = this.props;
    const { renderDays, selectedDays } = this.state;

    return (
      <div className="ProductRecurringOrder__recurring-order">
        <RecurringCycleDropdown
          cycles={product.recurringOrders.cycles}
          onChange={this.handleCycleChange}
        />
        {renderDays && (
          <RecurringDaysDropdown
            onChange={this.handleDaysChange}
            selectedValue={selectedDays}
            minDays={product.recurringOrders.min_custom_cycle}
          />
        )}
        <Button
          color="primary"
          onClick={this.handleAddToCart}
          className="ProductRecurringOrder__button-to-cart"
          disabled={loading || !this.isValidRecurring()}
        >
          <FormattedMessage
            id="recurringOrder.addToCart"
            defaultMessage="Add new subscription to cart"
          />
        </Button>
        {this.renderExistingOrders()}
      </div>
    );
  };

  renderContent = () => {
    const { product } = this.props;
    return (
      <div className="ProductRecurringOrder__content">
        {this.renderLoggedIn()}
        {this.renderContentDescription()}
        <ContentForState
          state={product.recurringOrdersState}
          forLoaded={() => this.renderRecurringOrder()}
        />
      </div>
    );
  };

  render() {
    const { isOpen } = this.state;
    const { disabled, product, accountStore } = this.props;

    const hasLowerPrice = !!product && !!product.recurring_order_price;

    return (
      <div
        className={classNames('ProductRecurringOrder', {
          'ProductRecurringOrder--solo':
            accountStore.loggedIn && product.hasOnlyRecurringOrder()
        })}
      >
        <Button
          className="ProductRecurringOrder__button"
          onClick={this.toggleContent}
          disabled={disabled}
          color="primary"
        >
          {hasLowerPrice && this.renderBadge()}
          {hasLowerPrice ? (
            <FormattedMessage
              id="recurringOrder.subscriptionButton"
              defaultMessage="Save money by subscribing"
            />
          ) : (
            <FormattedMessage
              id="recurringOrder.subscribe"
              defaultMessage="Subscribe"
            />
          )}
        </Button>
        <Collapse isOpen={isOpen && !disabled}>{this.renderContent()}</Collapse>
      </div>
    );
  }
}

ProductRecurringOrder.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  recurringOrderStore: modelOf(RecurringOrderStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  intl: intlShape.isRequired,
  product: modelOf(Product).isRequired,
  onAddToCartClick: PropTypes.func.isRequired,
  onAddToOrderClick: PropTypes.func.isRequired,
  activeProductId: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  withTax: PropTypes.bool
};

export default inject('accountStore', 'recurringOrderStore', 'configStore')(
  injectIntl(ProductRecurringOrder)
);
