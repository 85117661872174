import AdPages from '../../types/AdPages';

const useShouldShowBanner = ({ adStore, page, zone }) => {
  switch (page) {
    case AdPages.CAMPAIGN_PRODUCTS:
      if (!zone) {
        return adStore.campaignProductsAds.length > 0;
      }
      return adStore.campaignProductsAds.indexOf(zone) !== -1;
    case AdPages.CUSTOMER_PRODUCTS:
      if (!zone) {
        return adStore.customerProductsAds.length > 0;
      }
      return adStore.customerProductsAds.indexOf(zone) !== -1;
    case AdPages.NEW_PRODUCTS:
      if (!zone) {
        return adStore.newProductsAds.length > 0;
      }
      return adStore.newProductsAds.indexOf(zone) !== -1;
    case AdPages.POPULAR_PRODUCTS:
      if (!zone) {
        return adStore.popularProductsAds.length > 0;
      }
      return adStore.popularProductsAds.indexOf(zone) !== -1;
    case AdPages.RECOMMENDED_PRODUCTS:
      if (!zone) {
        return adStore.recommendedProductsAds.length > 0;
      }
      return adStore.recommendedProductsAds.indexOf(zone) !== -1;
    case AdPages.SOLD_PRODUCTS:
      if (!zone) {
        return adStore.soldProductsAds.length > 0;
      }
      return adStore.soldProductsAds.indexOf(zone) !== -1;
    default:
      break;
  }
  return false;
};

export default useShouldShowBanner;
