import { isStateTreeNode } from 'mobx-state-tree';
import createChainableTypeChecker from './createChainableTypeChecker';

function createModelOfTypeChecker(type) {
  function validate(props, propName, componentName, location, propFullName) {
    // Check based on https://github.com/mobxjs/mobx-state-tree/issues/365#issuecomment-327385021
    if (
      !isStateTreeNode(props[propName]) ||
      !type.is ||
      !type.is(props[propName])
    ) {
      return new Error(
        `Invalid ${location} "${propFullName}" of type "${type}" supplied to "${componentName}", expected MST model of type "${
          type.name
        }".`
      );
    }
    return null;
  }
  return createChainableTypeChecker(validate);
}

export const modelOf = createModelOfTypeChecker;
