const ProductSortOrderBy = {
  BRAND: 'MANUFACTURER',
  CAMPAIGN_DATE_ADDED: 'CAMPAIGN_DATE_ADDED',
  CATEGORY: 'CATEGORY',
  DATE_ADDED: 'DATE_ADDED',
  DISCOUNT: 'DISCOUNT',
  ID: 'ID',
  NAME: 'NAME',
  PRICE: 'PRICE',
  RECOMMENDED_SORT_ORDER: 'RECOMMENDED_SORT_ORDER',
  RELEVANCE: 'RELEVANCE',
  REVIEWS_AVERAGE: 'REVIEWS_AVERAGE',
  SALES: 'SALES',
  IN_STOCK_QUANTITY: 'IN_STOCK_QUANTITY',
};

export default ProductSortOrderBy;
