import { types } from 'mobx-state-tree';
import Question from './Question';

const Survey = types.model('Survey', {
  id: types.identifierNumber,
  has_pre_question: types.boolean,
  styles: types.maybeNull(types.string),
  embed_code: types.maybeNull(types.string),
  type: types.string,
  title: types.string,
  header_text: types.maybeNull(types.string),
  body: types.maybeNull(types.string),
  footer_text: types.maybeNull(types.string),
  pre_question_description: types.maybeNull(types.string),
  questions: types.array(Question),
  popup_time: types.maybeNull(types.number),
  questions_placement: types.string,
  survey_modal_width: types.maybeNull(types.number),
  body_column_width: types.maybeNull(types.number),
  answers_column_width: types.maybeNull(types.number)
});

export default Survey;
