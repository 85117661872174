import { types } from 'mobx-state-tree';

const RequestState = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  NONE: 'NONE',
  ERROR: 'ERROR'
};

export default RequestState;

export const RequestStateType = types.enumeration(
  'State',
  Object.values(RequestState)
);
