import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';

import AdStore from '../../../store/AdStore';
import { modelOf } from '../../../prop-types';
import MaybeExternalLink from '../../common/MaybeExternalLink';
import CommonSlider from '../../common/Slider';
import LinkType from '../../../types/LinkType';

const NavigationLinkCarousel = ({ adStore }) => {
  const SLIDER_SETTINGS = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const [currentAd, setCurrentAd] = useState(0);

  const ads = adStore.textBanners;

  if (
    !ads ||
    ads.length === 0 ||
    ads.filter((ad) => !!ad.content).length === 0
  ) {
    return null;
  }

  const adToLink = (ad, index) => {
    const type = ad.is_external
      ? LinkType.EXTERNAL_LINK
      : LinkType.INTERNAL_LINK;
    const Wrapper = ({ children }) => (
      <div className="NavigationLinkCarousel__link">
        {ad.link_url ? (
          <MaybeExternalLink
            accessible={index === currentAd}
            to={ad.link_url}
            type={type}
          >
            {children}
          </MaybeExternalLink>
        ) : (
          children
        )}
      </div>
    );
    return (
      <Wrapper key={ad.id}>
        <div
          className="NavigationLinkCarousel__content"
          dangerouslySetInnerHTML={{ __html: ad.content }}
          draggable={false}
        />
      </Wrapper>
    );
  };

  const links = ads.filter((ad) => !!ad.content).map(adToLink);

  const onBannerAfterChange = (slides, currentSlide) => {
    setCurrentAd(currentSlide);
  };

  const getSliderEvents = (slides) => {
    return {
      afterChange: (currentSlide) => onBannerAfterChange(slides, currentSlide),
    };
  };

  const sliderEvents = getSliderEvents(links);

  const renderAds = () => {
    return (
      <div className="NavigationLinkCarousel">
        <CommonSlider
          key={ads.map((ad) => ad.id).join(',')}
          {...SLIDER_SETTINGS}
          {...sliderEvents}
        >
          {links}
        </CommonSlider>
      </div>
    );
  };

  return renderAds();
};

NavigationLinkCarousel.propTypes = {
  adStore: modelOf(AdStore).isRequired,
};

export default inject('adStore')(observer(NavigationLinkCarousel));
