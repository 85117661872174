import { types } from 'mobx-state-tree';
import { CommonPageType } from '../types/CommonPage';

const Script = types.model('Script', {
  code: types.string,
  execute_on_page_view: types.boolean,
  id: types.identifierNumber,
  location: types.string,
  pages: types.array(CommonPageType),
  status: types.string
});

export default Script;
