import React, { Component } from 'react';

const Raven = window.Raven;

class SentryErrorBoundary extends Component {
  state = { error: null };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    if (Raven) {
      Raven.captureException(error, {
        extra: {
          site: window && window.location ? window.location.hostname : '',
          errorInfo
        }
      });
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <div className="SentryErrorBoundary">
            <img
              src={process.env.PUBLIC_URL + '/img/maintenance.png'}
              alt="Error"
            />
            <p>
              <input
                type="button"
                className="SentryErrorBoundary__reload"
                value="Päivitä / Refresh / Uppdatera"
                onClick={() => {
                  window.location.reload();
                }}
              />
            </p>
            <h3>Pahoittelut — jotakin meni pieleen</h3>
            <p>Päivitä sivu uudelleen. Jos ongelma jatkuu jätä virhekuvaus.</p>

            <h3>We're sorry — something's gone wrong</h3>
            <p>
              Please refresh the page. If the problem continues please try again
              later.
            </p>

            <h3>Vi beklagar att något gick fel</h3>
            <p>Uppdatera sidan. Om problemet kvarstår, försök igen senare.</p>
            <p>
              <input
                type="button"
                className="SentryErrorBoundary__secondary"
                value="Kuvaus / Description / Beskrivning"
                onClick={() => {
                  Raven.lastEventId() && Raven.showReportDialog();
                }}
              />
            </p>
          </div>
        </div>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default SentryErrorBoundary;
