import { types } from 'mobx-state-tree';

import FilterOption from './FilterOption';
import { FilterTypeType } from '../types/FilterType';

const Filter = types.model('Filter', {
  name: types.string,
  key: types.identifier,
  type: FilterTypeType,
  options: types.maybeNull(types.array(FilterOption)),
  step: types.maybeNull(types.number),
  unit: types.maybeNull(types.string),
  min: types.maybeNull(types.number),
  max: types.maybeNull(types.number)
});

export default Filter;
