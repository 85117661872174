import { isNil } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import globalTranslations from '../../../i18n/globalTranslations';
import ProductPriceInfo from '../../../models/ProductPriceInfo';
import QuantityDiscount from '../../../models/QuantityDiscount';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';
import Price from '../Price';

const PriceRRP = ({
  product,
  withTax,
  activeProductId,
  priceInfo,
  showTaxExcludedInfo,
  showTaxIncludedInfo,
  isSecondaryPrice,
  isMultiProductPicker,
  className,
  productBundle,
  hideDiscount,
  isDiscount,
}) => {
  if (hideDiscount || !priceInfo || !isDiscount || isSecondaryPrice) {
    return null;
  }

  let productPrice =
    productBundle && product && product.package_size
      ? product.getPackagePrice(withTax, activeProductId)
      : priceInfo.getNormalPrice(withTax);

  return (
    <Price
      price={productPrice}
      muted
      showTaxExcludedInfo={showTaxExcludedInfo}
      showTaxIncludedInfo={showTaxIncludedInfo}
      fontSize={isSecondaryPrice}
      isMultiProductPicker={isMultiProductPicker}
      prefix={
        // Space instead of margin so css line-through goes through the whole text
        <>
          <FormattedMessage {...globalTranslations.RRP} />{' '}
        </>
      }
      className={className}
    />
  );
};

const PriceWithDiscount = ({
  priceInfo,
  withTax,
  isDiscount,
  isSecondaryPrice,
  isMultiProductPicker,
  showTaxExcludedInfo,
  showTaxIncludedInfo,
  product,
  showUnit,
  className,
  hidePrice,
}) => {
  if (hidePrice || !priceInfo) {
    return null;
  }

  const productPrice = priceInfo.getPrice(withTax);

  return (
    <Price
      price={productPrice}
      discount={isDiscount && !isSecondaryPrice}
      showTaxExcludedInfo={showTaxExcludedInfo}
      showTaxIncludedInfo={showTaxIncludedInfo}
      fontSize={isSecondaryPrice}
      isMultiProductPicker={isMultiProductPicker}
      suffix={
        product &&
        showUnit &&
        !isSecondaryPrice && (
          <span className="ProductPrice__stock-unit">
            {' '}
            / {product.stock_unit}
          </span>
        )
      }
      className={className}
    />
  );
};

const ProductPrice = (props) => {
  const {
    priceInfo,
    withTax,
    forRetailer,
    alwaysShowUnit,
    product,
    hideTaxExcludedInfo,
    showTaxIncludedInfo,
    hidePrice,
    className,
    isSecondaryPrice,
    isMultiProductPicker,
    activeProductId,
    productBundle,
    hideDiscount,
    onOverrideContent,
  } = props;

  if (!priceInfo) {
    return null;
  }

  const isDiscount = priceInfo && priceInfo.is_discount;
  const showUnit =
    (product && product.sellInPackage) || forRetailer || alwaysShowUnit;

  const showTaxExcludedInfo = !withTax && !hideTaxExcludedInfo;

  return onOverrideContent ? (
    onOverrideContent({
      className,
      isMultiProductPicker,
      isSecondaryPrice,
      priceInfo,
      product,
      isDiscount,
      showUnit,
      showTaxExcludedInfo,
      showTaxIncludedInfo,
      withTax,
      productBundle,
      activeProductId,
      hidePrice,
      hideDiscount,
      isDiscount,
    })
  ) : (
    <div className="ProductPrice">
      <PriceWithDiscount
        className={className}
        hidePrice={hidePrice}
        isDiscount={isDiscount}
        isMultiProductPicker={isMultiProductPicker}
        isSecondaryPrice={isSecondaryPrice}
        priceInfo={priceInfo}
        product={product}
        showTaxExcludedInfo={showTaxExcludedInfo}
        showTaxIncludedInfo={showTaxIncludedInfo}
        showUnit={showUnit}
        withTax={withTax}
      />
      <PriceRRP
        activeProductId={activeProductId}
        className={className}
        hideDiscount={hideDiscount}
        isDiscount={isDiscount}
        isMultiProductPicker={isMultiProductPicker}
        isSecondaryPrice={isSecondaryPrice}
        priceInfo={priceInfo}
        product={product}
        productBundle={productBundle}
        showTaxExcludedInfo={showTaxExcludedInfo}
        showTaxIncludedInfo={showTaxIncludedInfo}
        withTax={withTax}
      />
    </div>
  );
};

ProductPrice.WithDiscount = PriceWithDiscount;
ProductPrice.PriceRRP = PriceRRP;

ProductPrice.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  alwaysShowUnit: PropTypes.bool.isRequired,
  forRetailer: PropTypes.bool.isRequired,
  withTax: PropTypes.bool.isRequired,
  activeProductId: PropTypes.string,
  className: PropTypes.string,
  hideDiscount: PropTypes.bool,
  hidePrice: PropTypes.bool,
  hideTaxExcludedInfo: PropTypes.bool,
  isMultiProductPicker: PropTypes.bool,
  isSecondaryPrice: PropTypes.bool,
  priceInfo: PropTypes.oneOfType([
    modelOf(ProductPriceInfo),
    modelOf(QuantityDiscount),
  ]),
  // Both Products and ChildProducts are allowed.
  product: PropTypes.object,
  productBundle: PropTypes.bool,
  showTaxIncludedInfo: PropTypes.bool,
};

ProductPrice.defaultProps = {
  hideTaxExcludedInfo: false,
  productBundle: false,
};

export default inject((stores, props) => ({
  configStore: stores.configStore,
  accountStore: stores.accountStore,
  withTax: isNil(props.withTax)
    ? stores.accountStore.showPricesWithTax
    : props.withTax,
  forRetailer: stores.accountStore.isRetailer,
  alwaysShowUnit: stores.configStore.product.alwaysShowUnit,
}))(observer(ProductPrice));
