import { types } from 'mobx-state-tree';

const ProductExtraProperty = types.model('ProductExtraProperty', {
  id: types.number,
  name: types.string,
  value_id: types.number,
  value_name: types.string
});

export default ProductExtraProperty;
