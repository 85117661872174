import { types } from 'mobx-state-tree';

const ViewBreakpoint = {
  XS: 'XS',
  SM: 'SM',
  MD: 'MD',
  LG: 'LG',
  XL: 'XL',
  XXL: 'XXL',
};

export default ViewBreakpoint;

export const ViewBreakpointType = types.enumeration(
  'ViewBreakpoint',
  Object.values(ViewBreakpoint)
);

export const ViewBreakpointMinWidth = {
  [ViewBreakpoint.XS]: 0,
  [ViewBreakpoint.SM]: 576,
  [ViewBreakpoint.MD]: 768,
  [ViewBreakpoint.LG]: 992,
  [ViewBreakpoint.XL]: 1200,
  [ViewBreakpoint.XXL]: 1400,
};
