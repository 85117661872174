import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import classNames from 'classnames';
import { keyBy, some } from 'lodash';

import { modelOf } from '../../../prop-types';
import ProductProperty from '../../../models/product/ProductProperty';
import ProductPropertyElement from '../../../models/ProductPropertyElement';
import ProductImage from '../ProductImage';
import globalTranslations from '../../../i18n/globalTranslations';
import Product from '../../../models/Product';
import ProductAvailabilityType from '../../../types/ProductAvailabilityType';
import ConfigStore from '../../../store/ConfigStore';

const messages = defineMessages({
  soldOut: {
    id: 'stock.soldOut',
    defaultMessage: 'Sold out',
  },
  notAvailable: {
    id: 'stock.notAvailable',
    defaultMessage: 'Not available',
  },
  temporarilyOutOfStock: {
    id: 'stock.temporarilyOutOfStock',
    defaultMessage: 'Temporarily out of stock',
  },
});

export const ProductCollectionPropertySelect = ({
  activeElementId,
  disabled,
  elementsWithAvailability,
  hidden,
  intl,
  multiproductAsDropdown,
  onPropertySelect,
  product,
  productImages,
  property,
  showButtons,
  showDropdown,
  showImages,
}) => {
  const onSelectChange = (event) => {
    const id = Number(event.target.value);
    onPropertySelect(property.getElementWithId(id));
  };

  const onButtonClick = (id) => {
    let newElement = null;
    if (!activeElementId || activeElementId !== id) {
      newElement = property.getElementWithId(id);
    }

    onPropertySelect(newElement);
  };

  const elementIsActive = (id) => {
    return id === activeElementId;
  };

  const getProductCollectionImages = () => {
    const productImageDict = keyBy(productImages, 'for_color_id');
    const someElementHasImage = some(
      elementsWithAvailability,
      ({ element }) => !!productImageDict[element.id]
    );

    if (!someElementHasImage) {
      return null;
    }

    const images = elementsWithAvailability.map(({ element, available }) => {
      const isActive = elementIsActive(element.id);
      const productImageForElement = productImageDict[element.id];
      return (
        <div
          key={element.id}
          className={classNames('ProductCollectionPropertySelect__image', {
            'ProductCollectionPropertySelect__image--active': isActive,
            'ProductCollectionPropertySelect__image--not-available': !available,
            'ProductCollectionPropertySelect__image--empty-image':
              !productImageForElement,
          })}
          onClick={() => onButtonClick(element.id)}
        >
          {productImageForElement ? (
            <ProductImage
              product={product}
              productImage={productImageForElement}
              size={'small'}
              lazyLoading={false}
            />
          ) : (
            <span>{element.name}</span>
          )}
        </div>
      );
    });
    return (
      <div className="ProductCollectionPropertySelect__images">{images}</div>
    );
  };

  const getDropdown = () => {
    const inputId = `property-${property.id}`;
    const value = activeElementId ? activeElementId : '';
    const options = elementsWithAvailability.map(
      ({ element, available, disabled }) => {
        let statusMessage = '';
        if (disabled) {
          statusMessage = intl.formatMessage(messages.notAvailable);
        } else if (!available) {
          if (product.availability_type === ProductAvailabilityType.CLOSEOUT) {
            statusMessage = intl.formatMessage(messages.soldOut);
          } else if (product.can_be_ordered_out_of_stock) {
            statusMessage = '';
          } else {
            statusMessage = intl.formatMessage(messages.temporarilyOutOfStock);
          }
        }

        return (
          <option key={element.id} value={element.id}>
            {element.name} {statusMessage && `(${statusMessage})`}
          </option>
        );
      }
    );

    return (
      <FormGroup>
        <Label for={inputId} hidden>
          <FormattedMessage
            {...globalTranslations.propertyChooseTitle}
            values={{ property: property.name.toLowerCase() }}
          />
        </Label>
        <Input
          type="select"
          name={inputId}
          id={inputId}
          onChange={onSelectChange}
          value={value}
          disabled={disabled}
          hidden={hidden}
        >
          <option value={null}>
            {intl.formatMessage(globalTranslations.propertyChooseTitle, {
              property: property.placeholder,
            })}
          </option>
          {options}
        </Input>
      </FormGroup>
    );
  };

  const getButtons = () => {
    const buttons = elementsWithAvailability.map(
      ({ element, available, disabled }) => {
        const isActive = elementIsActive(element.id);
        const color = isActive ? 'primary' : 'default';
        return (
          <Col xs="3" key={element.id}>
            <Button
              color={color}
              block
              className={classNames('ProductCollectionPropertySelect__button', {
                /* When not available, the button should look disabled, but still be clickable. */
                disabled: !available,
                'border-primary': isActive,
                'border-dark': !isActive,
              })}
              disabled={disabled}
              onClick={() => onButtonClick(element.id)}
            >
              {element.name}
            </Button>
          </Col>
        );
      }
    );
    return (
      <Row className="ProductCollectionPropertySelect__buttons">{buttons}</Row>
    );
  };

  if (hidden) {
    return null;
  }

  return (
    <div className="ProductCollectionPropertySelect">
      {showImages && !multiproductAsDropdown && getProductCollectionImages()}
      {showDropdown && getDropdown()}
      {showButtons && getButtons()}
    </div>
  );
};

ProductCollectionPropertySelect.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  product: modelOf(Product).isRequired,
  productImages: MobxPropTypes.observableArray.isRequired,
  property: modelOf(ProductProperty).isRequired,
  elementsWithAvailability: PropTypes.arrayOf(
    PropTypes.shape({
      element: modelOf(ProductPropertyElement).isRequired,
      available: PropTypes.bool.isRequired,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  onPropertySelect: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  activeElementId: PropTypes.number,
  showImages: PropTypes.bool,
  showDropdown: PropTypes.bool,
  showButtons: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  multiproductAsDropdown: PropTypes.bool,
  onProductChange: PropTypes.func,
  activeProductId: PropTypes.string,
};

export default inject('configStore')(
  injectIntl(observer(ProductCollectionPropertySelect))
);
