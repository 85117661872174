import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import RouteService from '../../../services/RouteService';
import globalTranslations from '../../../i18n/globalTranslations';
import Paths from '../../../types/Paths';
import NavigationEntityItem from '../../navigation/NavigationEntityItem';

@observer
export class NavigationWishListButton extends Component {
  render() {
    const item = {
      id: 'wishlist',
      path: this.props.routeService.getPath(Paths.WishList),
      label: <FormattedMessage {...globalTranslations.wishListTitle} />
    };
    return <NavigationEntityItem entity={item} />;
  }
}

NavigationWishListButton.propTypes = {
  routeService: PropTypes.instanceOf(RouteService).isRequired
};

export default inject('configStore', 'routeService')(NavigationWishListButton);
