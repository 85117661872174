import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ConfigStore from '../../../store/ConfigStore';
import { modelOf } from '../../../prop-types';
import YotpoReviewWidget from '../../../integrations/yotpo/YotpoReviewWidget';

@observer
class YotpoReviewWrapper extends Component {
  render() {
    const { configStore } = this.props;

    // Bail if we are in SSR.
    if (
      typeof document === 'undefined' ||
      !configStore.integrations.yotpo.enabled
    ) {
      return null;
    }

    return <YotpoReviewWidget />;
  }
}

YotpoReviewWrapper.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
};

export default inject('configStore')(YotpoReviewWrapper);
