import { types } from 'mobx-state-tree';

const ProductLabelType = {
  IMAGE: 'image',
  LABEL: 'label',
  IMAGE_AND_LABEL: 'image_and_label'
};

export default ProductLabelType;

export const ProductLabelTypeType = types.enumeration(
  'ProductLabelType',
  Object.values(ProductLabelType)
);
