import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import CartStore from '../../../store/CartStore';
import { unblockScroll, blockScroll } from '../../../util/dom';
import InfoPageStore from '../../../store/InfoPageStore';
import RequestState from '../../../types/RequestState';
import ContentForState from '../../loader/ContentForState';
import Icon from '../../common/Icon';
import globalTranslations from '../../../i18n/globalTranslations';

@observer
export class CartErrorModal extends Component {
  constructor(props) {
    super(props);

    const { cartStore, configStore } = props;

    if (
      cartStore.cartErrorModal.isOpen &&
      configStore.infoPages.cartErroModal
    ) {
      this.maybeLoadCartErrorInfoPage();
    }
  }

  componentDidMount() {
    blockScroll();
  }

  componentDidUpdate() {
    const { cartStore, configStore, infoPageStore } = this.props;
    const infoPageId = configStore.infoPages.cartErroModal;

    if (
      cartStore.cartErrorModal.isOpen &&
      infoPageId &&
      infoPageStore.pageStates.get(infoPageId) !== RequestState.LOADING &&
      infoPageStore.pageStates.get(infoPageId) !== RequestState.LOADED &&
      infoPageStore.pageStates.get(infoPageId) !== RequestState.ERROR
    ) {
      this.maybeLoadCartErrorInfoPage();
    }
  }

  componentWillUnmount() {
    unblockScroll();
  }

  maybeLoadCartErrorInfoPage = () => {
    const { cartStore, configStore, infoPageStore } = this.props;
    const infoPageId = configStore.infoPages.cartErroModal;
    if (
      cartStore.cartErrorModal.isOpen &&
      infoPageId &&
      infoPageStore.pageStates.get(infoPageId) !== RequestState.LOADED &&
      infoPageStore.pageStates.get(infoPageId) !== RequestState.ERROR
    ) {
      infoPageStore.loadPage(infoPageId).catch((e) => {
        if (e.responce && e.responce.status !== 404) {
          console.error(e);
        }
      });
    }
  };

  getContentState = () => {
    const { infoPageStore, configStore } = this.props;
    const infoPageId = configStore.infoPages.cartErroModal;

    if (!infoPageId) {
      return RequestState.LOADED;
    }
    if (infoPageStore.pageStates.get(infoPageId) !== RequestState.LOADING) {
      return RequestState.LOADED;
    }
    return infoPageStore.pageStates.get(infoPageId);
  };

  getContent = () => {
    const infoPage = this.getInfoPage();
    return infoPage && infoPage.content ? (
      <div
        className="CartErrorModal__body-content"
        dangerouslySetInnerHTML={{
          __html: infoPage.content,
        }}
      />
    ) : (
      this.getDefaultContent()
    );
  };

  getInfoPage = () => {
    const { configStore, infoPageStore } = this.props;
    const infoPageId = configStore.infoPages.cartErroModal;

    return infoPageId &&
      infoPageStore.pageStates.get(infoPageId) === RequestState.LOADED
      ? infoPageStore.pages.get(infoPageId)
      : null;
  };

  getDefaultContent = () => {
    const { errorContent } = this.props;

    return <div className="CartErrorModal__body-content">{errorContent}</div>;
  };

  render() {
    const { errorTitle, cartStore, intl } = this.props;
    return (
      <Modal
        className="CartErrorModal"
        isOpen={cartStore.cartErrorModal.isOpen}
        toggle={cartStore.cartErrorModal.toggle}
      >
        <ModalHeader
          className="CartErrorModal__header"
          toggle={cartStore.cartErrorModal.toggle}
        >
          <div className="CartErrorModal__header-error-title">
            <div className="CartErrorModal__header-error-sign">
              <Icon name="close" />
            </div>
            {errorTitle}
          </div>
        </ModalHeader>
        <ModalBody className="CartErrorModal__body">
          <ContentForState
            state={this.getContentState()}
            forLoaded={this.getContent}
          />
        </ModalBody>
        <ModalFooter className="CartErrorModal__footer">
          <Button
            className="CartErrorModal__close-button"
            aria-label={intl.formatMessage(globalTranslations.closeTitle)}
            color="secondary"
            onClick={cartStore.cartErrorModal.toggle}
          >
            <FormattedMessage {...globalTranslations.closeTitle} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

CartErrorModal.propTypes = {
  cartStore: modelOf(CartStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  infoPageStore: modelOf(InfoPageStore).isRequired,
  intl: intlShape.isRequired,
  errorTitle: PropTypes.node.isRequired,
  errorContent: PropTypes.node.isRequired,
};

CartErrorModal.defaultProps = {
  errorTitle: (
    <FormattedMessage
      id="errorModal.title"
      defaultMessage="Unexpected error has occurred"
    />
  ),
  errorContent: (
    <FormattedMessage
      id="errorModal.content"
      defaultMessage="Something went wrong. Please check the provided information and try again."
    />
  ),
};

export default inject(
  'cartStore',
  'infoPageStore',
  'configStore'
)(injectIntl(CartErrorModal));
