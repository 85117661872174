import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';

const RobotsMeta = ({ noindex, nofollow }) => {
  const tags = [];
  let inputProps = {};

  if (noindex) {
    tags.push('noindex');
  }

  if (nofollow) {
    tags.push('nofollow');
  }

  return (
    <Helmet>
      <meta name="robots" content={tags.join(', ')} />
    </Helmet>
  );
};

RobotsMeta.propTypes = {
  noindex: PropTypes.bool,
  nofollow: PropTypes.bool,
};

export default observer(RobotsMeta);
