import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { observer } from 'mobx-react';

import globalTranslations from '../../../i18n/globalTranslations';

@observer
export class FatalError extends Component {
  render() {
    const { intl } = this.props;

    return (
      <div className="FatalError">
        <img
          src={process.env.PUBLIC_URL + '/img/maintenance.png'}
          alt="Error"
        />
        <p>
          <input
            type="button"
            className="FatalError__reload"
            value={intl.formatMessage(globalTranslations.errorRefresh)}
            onClick={() => {
              window.location.reload();
            }}
          />
        </p>
        <h3>
          <FormattedMessage {...globalTranslations.errorHeader} />
        </h3>
        <p>
          <FormattedMessage {...globalTranslations.errorContentFront} />
        </p>
        <p>
          <input
            type="button"
            className="FatalError__secondary"
            value={intl.formatMessage(globalTranslations.errorFrontpage)}
            onClick={() => {
              window.location.assign(
                window.location.protocol + '//' + window.location.host
              );
            }}
          />
        </p>
      </div>
    );
  }
}

FatalError.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(FatalError);
