import { isFunction } from 'lodash';
import PropTypes from 'prop-types';

class LocationContext {
  constructor(protocol, host, href) {
    this._protocol = protocol;
    this._host = host;
    this._href = href;
  }

  callOrReturn(value) {
    return isFunction(value) ? value() : value;
  }

  get protocol() {
    return this.callOrReturn(this._protocol);
  }
  get host() {
    return this.callOrReturn(this._host);
  }
  get href() {
    return this.callOrReturn(this._href);
  }
}

export const LocationContextPropType = PropTypes.shape({
  protocol: PropTypes.string.isRequired,
  host: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
});

export default LocationContext;
