import React, { Component } from 'react';
import { DropdownItem } from 'reactstrap';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import Country from '../../../models/Country';
import DropdownSelect from '../../common/DropdownSelect';
import globalTranslations from '../../../i18n/globalTranslations';
import LocaleFlagIcon from '../LocaleFlagIcon';

const DEFAULT_LARGE_MENU_LENGTH = 16;

@observer
class RegionalSettingsDeliveryCountryDropdown extends Component {
  render() {
    const { country } = this.props;
    return (
      <DropdownSelect
        label={
          <FormattedMessage
            {...globalTranslations.deliveryCountryLabel}
            values={{
              country: (
                <span>
                  <LocaleFlagIcon locale={country.iso_code_2.toLowerCase()} />{' '}
                  {country.name}
                </span>
              )
            }}
          />
        }
        isLarge={this.props.countries.length > DEFAULT_LARGE_MENU_LENGTH}
      >
        {this.props.countries.map((country) => (
          <DropdownItem
            onClick={(e) => this.props.onSelect(country)}
            key={country.id}
          >
            <span>
              <LocaleFlagIcon locale={country.iso_code_2.toLowerCase()} />{' '}
              {country.name}
            </span>
          </DropdownItem>
        ))}
      </DropdownSelect>
    );
  }
}

RegionalSettingsDeliveryCountryDropdown.propTypes = {
  country: modelOf(Country).isRequired,
  countries: MobxPropTypes.observableArrayOf(modelOf(Country)).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default RegionalSettingsDeliveryCountryDropdown;
