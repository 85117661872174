import React, { Component } from 'react';
import PropTypes from 'prop-types/';
import WysiwygContent from '../WysiwygContent/';
import BlockContent from '../BlockContent';

class WysiwygOrBlockContent extends Component {
  render() {
    const { content } = this.props;

    let json;
    try {
      json = JSON.parse(content);
    } catch (e) {}

    let output;
    if (json && Array.isArray(json)) {
      output = json.map((block, index) => (
        <BlockContent key={index} block={block} />
      ));
    } else {
      output = <WysiwygContent html={content} />;
    }
    return <div className="WysiwygOrBlockContent">{output}</div>;
  }
}

WysiwygContent.propTypes = {
  content: PropTypes.string
};

export default WysiwygOrBlockContent;
