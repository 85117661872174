import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import ProductSlider from '../ProductSlider';
import SimpleProductList from '../../product-list/SimpleProductList';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class GenericProductsSlider extends Component {
  listId = 'related_products';
  listName = 'Related Products';

  render() {
    const {
      mainComponentClassName,
      formattedMessage,
      ids,
      allSections,
      configStore,
    } = this.props;

    const searchParams = {
      ids,
      includeFilters: 0,
      excludeContent: 1,
      includeChildProducts: '1',
      inStock: configStore.inStockProductsOnly ? '1' : '0',
    };
    return (
      <div className={classNames(mainComponentClassName)}>
        <h3>{formattedMessage}</h3>
        <SimpleProductList
          listId={this.listId}
          name={this.listName}
          searchParams={searchParams}
          listComponent={ProductSlider}
          allSections={allSections}
        />
      </div>
    );
  }
}

GenericProductsSlider.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  allSections: PropTypes.bool.isRequired,
  mainComponentClassName: PropTypes.string.isRequired,
  listId: PropTypes.string.isRequired,
  listName: PropTypes.string.isRequired,
  formattedMessage: PropTypes.object.isRequired,
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default inject('configStore')(GenericProductsSlider);
