import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import globalTranslations from '../../../i18n/globalTranslations';
import ProductMerchantInfo from '../../../models/ProductMerchantInfo';
import { modelOf } from '../../../prop-types';
import Price from '../../product/Price';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class ShoppingCenterProductMerchantInfo extends Component {
  renderMerchantInfo = () => {
    const { merchantInfo } = this.props;
    const siteName = merchantInfo.site_name;
    return (
      siteName && (
        <Col
          xs={12}
          className="ShoppingCenterProductMerchantInfo__merchant-info--merchant-name"
        >
          <FormattedMessage
            id="shoppingCenterProductMerchantInfo.sellerTitle"
            defaultMessage="Seller"
          />
          <span>
            {': '}
            {siteName}
          </span>
        </Col>
      )
    );
  };

  renderMerchantPrice = () => {
    const { merchantInfo } = this.props;

    const price =
      merchantInfo.shipping_starting_price &&
      merchantInfo.shipping_starting_price.with_tax;

    const ifBuyingAllowed = merchantInfo && merchantInfo.buyingAllowed;

    if (!ifBuyingAllowed) {
      return null;
    }

    return (
      price != null && (
        <Col
          xs={12}
          className="ShoppingCenterProductMerchantInfo__merchant-info--shipping-costs"
        >
          <FormattedMessage {...globalTranslations.deliveryStartingAbridged} />{' '}
          <Price price={price} />
        </Col>
      )
    );
  };

  render() {
    const { configStore, merchantInfo } = this.props;
    const ifWebStore = configStore.siteConfig.isWebStore;

    if (ifWebStore || !merchantInfo) {
      return null;
    }

    return (
      <div className="ShoppingCenterProductMerchantInfo">
        <div className="ShoppingCenterProductMerchantInfo__merchant-info">
          <Row>
            {this.renderMerchantInfo()}
            {this.renderMerchantPrice()}
          </Row>
        </div>
      </div>
    );
  }
}

ShoppingCenterProductMerchantInfo.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  merchantInfo: PropTypes.oneOfType([
    modelOf(ProductMerchantInfo),
    PropTypes.object
  ]),
  withTax: PropTypes.bool
};

ShoppingCenterProductMerchantInfo.defaultProps = {
  withTax: true
};

export default inject('configStore')(ShoppingCenterProductMerchantInfo);
