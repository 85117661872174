import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { inject, observer } from 'mobx-react';

import ConfigStore from '../../../store/ConfigStore';
import { modelOf } from '../../../prop-types';

@observer
class SpotmoreWidget extends Component {
  render() {
    const { configStore } = this.props;

    // Bail if we are in SSR.
    if (
      typeof document === 'undefined' ||
      !configStore.integrations.spotmore.enabled
    ) {
      return null;
    }

    return (
      <Helmet>
        <script type="text/javascript">
          {`
          function spotmore_0(){
          var a=document.createElement('script');
          var m= document.getElementsByTagName('script')[1];
          a.async=1;
          a.src='https://client.spotmore.fi/campaigns/crmjs';
          m.parentNode.insertBefore(a,m)
          } setTimeout('spotmore_0()', 500);
        `}
        </script>
      </Helmet>
    );
  }
}

SpotmoreWidget.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
};

export default inject('configStore')(SpotmoreWidget);
