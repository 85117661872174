import cookies from 'js-cookie';

const SESSION_KEY = 'jsh_id';

export const storeSessionId = (sessionId) => {
  // TODO: Fetch session expiration from backend configuration
  const dayInMS = 24 * 60 * 60 * 1000;
  const expiration = new Date(new Date().getTime() + dayInMS);
  return cookies.set(SESSION_KEY, sessionId, { expires: expiration });
};

export const getSessionId = () => {
  return cookies.get(SESSION_KEY);
};
