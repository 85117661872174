import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import Icon from '../../common/Icon/';
import Stars from '../Stars';

class InteractiveStars extends Component {
  state = {
    hoveredStar: 0
  };

  setHoverStarCount = (count) => {
    this.setState({ hoveredStar: count });
  };

  render() {
    const { amount, onClick, ...rest } = this.props;

    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <Button
          className="InteractiveStars__star-container"
          color="link"
          key={i}
          onMouseOver={() => this.setHoverStarCount(i)}
          onClick={() => onClick(i)}
          {...rest}
        >
          <span className="sr-only">
            <FormattedMessage
              id="review.interactiveStarsLabel"
              defaultMessage="{count, plural, one {# star} other {# stars}} out of 5"
              values={{ count: i }}
            />
          </span>
          <Icon
            name="star"
            className={
              this.state.hoveredStar >= i ? 'InteractiveStars__star--hover' : ''
            }
          />
        </Button>
      );
    }

    return (
      <div
        className="InteractiveStars"
        onMouseLeave={() => this.setHoverStarCount(0)}
      >
        <Stars amount={amount} />
        <div className="InteractiveStars__control">{stars}</div>
      </div>
    );
  }
}

InteractiveStars.propTypes = {
  amount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default InteractiveStars;
