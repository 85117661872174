import { types } from 'mobx-state-tree';

const FilterType = {
  BOOLEAN: 'BOOLEAN',
  RANGE: 'RANGE',
  SINGLETERM: 'SINGLETERM',
  TERM: 'TERM',
};

export default FilterType;

export const FilterTypeType = types.enumeration(
  'FilterTypeType',
  Object.values(FilterType)
);
