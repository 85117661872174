import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

class Discount extends Component {
  render() {
    const { percentage, withLabel, withUpTo } = this.props;
    const discount = `-${Math.round(percentage)}%`;
    return (
      <span className="Discount">
        {withLabel && (
          <FormattedMessage id="discount.sale" defaultMessage="Sale" />
        )}
        {withUpTo && (
          <FormattedMessage id="discount.upTo" defaultMessage="Up to" />
        )}{' '}
        {discount}
      </span>
    );
  }
}

Discount.propTypes = {
  percentage: PropTypes.number,
  withLabel: PropTypes.bool,
  withUpTo: PropTypes.bool
};

export default Discount;
