import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import UIStore from '../../../store/UIStore';
import { modelOf } from '../../../prop-types';
import MobileNavigationTab from '../../../types/MobileNavigationTab';

const MobileNavigationButton = ({
  ariaLabel,
  children,
  className,
  forceClose,
  tab,
  uiStore,
  withBorder,
}) => {
  const toggleMenu = () => {
    const navigation = uiStore.mobileNavigation;
    const isTabChange = tab !== navigation.activeTab;

    if (forceClose) {
      navigation.close();
      uiStore.navigationPopover.isOpen && uiStore.navigationPopover.toggle();
    } else if (isTabChange) {
      navigation.selectTab(tab);
      navigation.open();
    } else {
      navigation.toggle();
      uiStore.navigationPopover.isOpen && uiStore.navigationPopover.toggle();
    }
  };

  return (
    <Button
      className={classNames('MobileNavigationButton', className, {
        'MobileNavigationButton--with-border': withBorder,
      })}
      color="plain"
      onClick={toggleMenu}
      aria-label={ariaLabel}
    >
      {children}
    </Button>
  );
};

MobileNavigationButton.propTypes = {
  uiStore: modelOf(UIStore).isRequired,
  tab: PropTypes.oneOf(Object.values(MobileNavigationTab)),
  className: PropTypes.string,
  withBorder: PropTypes.bool,
  forceClose: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

MobileNavigationButton.defaultProps = {
  className: '',
  withBorder: false,
  forceClose: false,
};

export default inject('uiStore')(observer(MobileNavigationButton));
