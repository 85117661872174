import { types } from 'mobx-state-tree';

const ShippingCampaignType = {
  FIXED_PRICE: 'FIXED_PRICE',
  FREE_SHIPPING: 'FREE_SHIPPING',
  TOTAL_SUM_TABLE: 'TOTAL_SUM_TABLE',
  TOTAL_WEIGHT_TABLE: 'TOTAL_WEIGHT_TABLE',
  PRODUCT_QUANTITY_TABLE: 'PRODUCT_QUANTITY_TABLE',
  TOTAL_VOLUME_TABLE: 'TOTAL_VOLUME_TABLE'
};

export default ShippingCampaignType;

export const ShippingCampaignTypeType = types.enumeration(
  'ShippingCampaignType',
  Object.values(ShippingCampaignType)
);
