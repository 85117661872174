import { types } from 'mobx-state-tree';

const BannerStyle = {
  INFINITE: 'INFINITE',
  CENTERED: 'CENTERED',
  BOTH: 'BOTH',
};

export default BannerStyle;

export const BannerStyleType = types.enumeration(
  'BannerStyleType',
  Object.values(BannerStyle)
);
